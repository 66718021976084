import React, { useContext, useEffect, useState } from "react";
import VideocamIcon from "@mui/icons-material/Videocam";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { AuthContext, SharedContext, VideoContext } from "../../context";
import CopyToClipboard from "react-copy-to-clipboard";
import { GrFormPrevious, GrFormNext } from "react-icons/gr";
import Modal from "react-modal";
import {
  VideoUploadModal,
  VideoEditModal,
  VideoDeleteModal,
} from "../../components";

Modal.setAppElement("#root");

export default function VideosPage() {
  const { dealerships } = useContext(SharedContext);
  const { user } = useContext(AuthContext);
  const { videos, getVideos, searchVideos } = useContext(VideoContext);
  const [videoType, setVideoType] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [selectedDealershipId, setSelectedDealershipId] = useState("All");
  const [searchKey, setSearchKey] = useState("");
  const [clipboardState, setClipboardState] = useState({
    value: "",
    copied: false,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const perPage = 9;
  const [isOpenUploadModal, setIsOpenUploadModal] = useState(false);
  const [editModalVideo, setEditModalVideo] = useState(null);
  const [deleteModalVideo, setDeleteModalVideo] = useState(null);

  useEffect(() => {
    getVideos({
      userId: user?.Id,
      selectedDealershipId: videoType === 2 ? selectedDealershipId : "",
      category: selectedCategory,
      type: videoType,
    });
    setCurrentPage(1);
  }, [user, videoType, selectedCategory]);

  const openUploadModal = () => {
    setIsOpenUploadModal(true);
  };
  function afterOpenUploadModal() {
    // references are now sync'd and can be accessed.
  }

  function closeUploadModal() {
    setIsOpenUploadModal(false);
  }

  function closeEditModal() {
    setEditModalVideo(null);
    searchVideos({
      userId: user?.Id,
      searchKey: searchKey,
      category: selectedCategory,
      type: videoType,
    });
  }

  const orderByLatest = (a, b) => {
    if (a.Create_date > b.Create_date) {
      return -1;
    }
    if (a.Create_date < b.Create_date) {
      return 1;
    }
    return 0;
  };

  const reloadData = () => {
    if (searchKey) {
      searchVideos({
        userId: user?.Id,
        searchKey: searchKey,
        category: selectedCategory,
        type: videoType,
      });
    } else {
      getVideos({
        userId: user?.Id,
        selectedDealershipId: videoType === 2 ? selectedDealershipId : "",
        category: selectedCategory,
        type: videoType,
      });
    }
  };

  return (
    <main className="container py-4">
      <div className="d-flex align-items-center">
        <div className="section-title">
          <VideocamIcon
            color="white"
            fontSize="small"
            sx={{ marginRight: "5px" }}
          />
          Videos
        </div>
      </div>
      <div className="card-tabs mt-4 d-flex align-items-center justify-content-between mx-1">
        <div className="">
          <button
            className={`btn-tab ${videoType === 1 ? "active" : ""}`}
            onClick={() => setVideoType(1)}
          >
            My Videos
          </button>
          <button
            className={`btn-tab ${videoType === 2 ? "active" : ""}`}
            onClick={() => setVideoType(2)}
          >
            Shared Videos
          </button>
        </div>
        <button className="btn-upload" onClick={() => openUploadModal()}>
          <VideocamIcon
            color="white"
            fontSize="small"
            sx={{ marginRight: "5px" }}
          />
          Upload
        </button>
      </div>
      <div className="card">
        <div className="card-header">
          <div className="d-flex align-items-center justify-content-between py-2">
            <div className="form-control-wrapper form-control-fav">
              <input
                type="text"
                className="form-control pe-5"
                placeholder="Search"
                value={searchKey}
                onChange={(e) => setSearchKey(e.target.value)}
              />
              <button
                className="btn right-button"
                onClick={() =>
                  searchVideos({
                    userId: user?.Id,
                    searchKey: searchKey,
                    category: selectedCategory,
                    type: videoType,
                  })
                }
              >
                <SearchOutlinedIcon fontSize="small" />
              </button>
            </div>
            <select
              className="form-select form-control-fav"
              onChange={(e) => setSelectedCategory(e.target.value)}
            >
              <option value="0">All</option>
              {/* <option value="0">No Category</option> */}
              <option value="1">Personal Videos</option>
              <option value="2">Sales</option>
              <option value="3">Service</option>
              <option value="4">Marketing</option>
              <option value="5">Finance</option>
            </select>
          </div>
          <div className="d-flex align-items-center justify-content-between py-2">
            {dealerships && videoType === 2 && (
              <select
                className="form-select form-control-fav ms-auto"
                onChange={(e) => setSelectedDealershipId(e.target.value)}
              >
                <option value={"All"}>All Dealerships</option>
                {dealerships.map((item, index) => (
                  <option value={item.Id} key={index}>
                    {item.Name}
                  </option>
                ))}
              </select>
            )}
          </div>
        </div>
        <div className="card-body">
          {
            <div className="row">
              {videos &&
                videos.sort(orderByLatest).map((item, index) =>
                  index >= (currentPage - 1) * perPage &&
                  index < currentPage * perPage ? (
                    <div className="col col-sm-4" key={index}>
                      <div className="video-card d-flex flex-column align-items-center">
                        <div className="video-title">{item?.Title}</div>
                        <div className="date-created">{item?.Create_date}</div>
                        <small className="">Sales</small>
                        <video
                          className="video"
                          poster={item.Thumnail_url}
                          controls
                        >
                          <source src={item.Video_url} type="video/mp4" />
                        </video>
                        <div className="actions mt-2">
                          <CopyToClipboard
                            text={item?.Video_url}
                            onCopy={() =>
                              setClipboardState({
                                value: item?.Video_url,
                                copied: true,
                              })
                            }
                          >
                            <button className="btn btn-outline-secondary m-1">
                              {clipboardState.copied &&
                              clipboardState.value === item?.Video_url
                                ? "Copied"
                                : "Copy Link"}
                            </button>
                          </CopyToClipboard>
                          <button
                            className="btn btn-outline-secondary m-1"
                            onClick={() => setEditModalVideo(item)}
                          >
                            Edit
                          </button>
                          <button
                            className="btn btn-danger m-1"
                            onClick={() => setDeleteModalVideo(item)}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : null,
                )}
            </div>
          }
        </div>
        <div className="card-footer py-4 bg-white">
          {videos && (
            <div className="table-pagination">
              <div className="per-page">
                Page {currentPage} of {Math.ceil(videos.length / perPage)} |{" "}
                {videos.length} Campaigns
              </div>
              <div className="pagination">
                <button
                  className={`btn-page ${currentPage === 1 ? "disabled" : ""}`}
                  onClick={() => {
                    if (currentPage > 1) setCurrentPage(currentPage - 1);
                  }}
                >
                  <GrFormPrevious />
                </button>
                {currentPage > 2 ? (
                  <button className={`btn-page`}>...</button>
                ) : null}
                {[...Array(Math.ceil(videos.length / perPage))].map((e, i) =>
                  i + 1 > currentPage - 2 && i + 1 < currentPage + 2 ? (
                    <button
                      className={`btn-page ${
                        currentPage === i + 1 ? "active" : ""
                      }`}
                      key={i}
                      onClick={() => setCurrentPage(i + 1)}
                    >
                      {i + 1}
                    </button>
                  ) : null,
                )}
                {currentPage < Math.ceil(videos.length / perPage) - 1 ? (
                  <button className={`btn-page`}>...</button>
                ) : null}
                <button
                  className={`btn-page ${
                    currentPage === Math.ceil(videos.length / perPage)
                      ? "disabled"
                      : ""
                  }`}
                  onClick={() => {
                    if (currentPage < Math.ceil(videos.length / perPage))
                      setCurrentPage(currentPage + 1);
                  }}
                >
                  <GrFormNext />
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* Modal */}
      <VideoUploadModal
        dealerships={dealerships}
        isOpenModal={isOpenUploadModal}
        closeModal={closeUploadModal}
        afterOpenModal={afterOpenUploadModal}
        reloadData={() => {
          reloadData();
          closeUploadModal();
        }}
      />
      <VideoEditModal
        data={editModalVideo}
        isOpenModal={!!editModalVideo}
        closeModal={closeEditModal}
        afterOpenModal={() => {}}
      />
      <VideoDeleteModal
        data={deleteModalVideo}
        isOpenModal={!!deleteModalVideo}
        closeModal={() => setDeleteModalVideo(null)}
        afterOpenModal={() => {}}
        reloadData={() => {
          reloadData();
          setDeleteModalVideo(null);
        }}
      />
    </main>
  );
}
