import { Switch } from "@mui/material";
import { DropdownMultiSelect, PlFileUploader } from "../../../components";
import config from "../../../config/config";
import React, { useRef, useState } from "react";
import { TagsInput } from "react-tag-input-component";
import helper from "../../../utils/helper";
import Autocomplete from "react-google-autocomplete";

export default function ClientInfoForm({
  isDealerfunnelUser,
  isagentchain,
  agent,
  dealer,
  usertype,
  state,
  analystics,
  manager,
  executive,
  postData,
  setPostData,
  onSave,
}) {
  const formRef = useRef(undefined);
  const [manualMatch, setManualMatch] = useState(false);
  const [privateLabel, setPrivateLabel] = useState(false);

  const onClickSave = () => {
    if (!formRef.current.reportValidity()) {
      return;
    }
    onSave();
  };

  const getattr = (place, attr) => {
    let i;
    for (i = 0; i < place.address_components.length; i++) {
      if (place.address_components[i].types[0] == attr) {
        if (attr == "administrative_area_level_1") {
          return place.address_components[i].short_name;
        } else {
          return place.address_components[i].long_name;
        }
      }
    }
    return "";
  };

  const fillInAddress = (place) => {
    console.log("===", postData);
    let str = "";
    let street_number = getattr(place, "street_number");
    if (street_number != "") {
      str += street_number + " ";
    }
    str += getattr(place, "route");
    let _postData = { ...postData };
    _postData.Address = str;
    _postData.City = getattr(place, "locality");
    _postData.Zip = getattr(place, "postal_code");
    _postData.State = getattr(place, "administrative_area_level_1");
    _postData.Name = place.formatted_address.split(",")[0];
    console.log(">>>", _postData);
    setPostData(_postData);
  };

  return (
    <form ref={formRef}>
      <h6 className="tab-tile">Client Info</h6>
      <div className="row mt-2">
        <div className="col col-sm-6">
          <div className="form-group mb-2">
            <label className="mb-2">Name</label>
            {/* <input type="text" className="form-control w-100" 
              value={postData?.Name}
              onChange={(e) => setPostData({...postData, Name: e.target.value})} 
              required={true} 
            /> */}
            <Autocomplete
              className="form-control"
              apiKey={config.googleMapKey}
              onPlaceSelected={(place) => fillInAddress(place)}
              defaultValue={postData.Name}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col col-sm-3">
          <div className="form-group mb-2">
            <label className="mb-2">Client Types</label>
            <select
              name="Ctype"
              id="usertype"
              className="form-select"
              value={postData.Ctype}
              onChange={(e) =>
                setPostData({ ...postData, Ctype: e.target.value })
              }
            >
              <option value="1">Agency</option>
              <option value="2">Dealer Group</option>
              <option value="3">Dealership</option>
            </select>
          </div>
        </div>
        {Number(isDealerfunnelUser) === 0 && (
          <>
            <div className="col col-sm-3">
              <div
                id="agency"
                className={`form-group mb-2${
                  Number(postData.Ctype) === 1 ? " d-none" : ""
                }`}
              >
                <label className="mb-2">Agency</label>
                {Number(isagentchain) === 1 ? (
                  <select
                    name="Agency1"
                    className="form-select"
                    disabled={true}
                    value={postData.Agency1}
                    onChange={(e) =>
                      setPostData({ ...postData, Agency1: e.target.value })
                    }
                  >
                    <option value="">Select Agency</option>
                    {agent.map((item, index) => (
                      <option value={item.Id} key={index}>
                        {item.Name}
                      </option>
                    ))}
                  </select>
                ) : (
                  <select
                    name="Agency"
                    className="form-select"
                    value={postData.Agency}
                    onChange={(e) =>
                      setPostData({ ...postData, Agency: e.target.value })
                    }
                  >
                    <option value="">Select Agency</option>
                    {agent.map((item, index) => (
                      <option value={item.Id} key={index}>
                        {item.Name}
                      </option>
                    ))}
                  </select>
                )}
              </div>
            </div>
            <div className="col col-sm-3">
              <div
                id="group"
                className={`form-group mb-2${
                  Number(postData.Ctype) === 1 || Number(postData.Ctype) === 2
                    ? " d-none"
                    : ""
                }`}
              >
                <label className="mb-2">Dealer Group</label>
                <select name="Group" className="form-select">
                  <option value="">Select Group</option>
                  {dealer.map((item, index) => (
                    <option value={item.Id} key={index}>
                      {item.Name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </>
        )}
      </div>
      <div className="row mt-3">
        <div className="col col-sm-3">
          <div className="form-group mb-2">
            {postData?.Logo && (
              <div className="logo-img w-100">
                <img
                  src={`${config.domain}/${postData?.Logo}`}
                  width="80"
                  alt=""
                />
              </div>
            )}
            <label className="w-100 mb-2">Dealership Logo</label>
            <PlFileUploader
              url={`${config.ajaxPath}/imageupload/1`}
              browseButtonId={`Logo_logo`}
              extensions={"jpg,gif,png"}
              onSuccess={(up, file, response) =>
                setPostData({ ...postData, Logo: response.filename })
              }
              onError={() => {}}
            />
          </div>
        </div>
        <div className="col col-sm-3">
          <div className="form-group mb-2">
            {postData?.Mlogo && (
              <div className="logo-img w-100">
                <img
                  src={`${config.domain}/${postData?.Mlogo}`}
                  width="80"
                  alt=""
                />
              </div>
            )}
            <label className="w-100 mb-2">Manufacturer Logo</label>
            <PlFileUploader
              url={`${config.ajaxPath}/imageupload/1`}
              browseButtonId={`manufacturer_logo`}
              extensions={"jpg,gif,png"}
              onSuccess={(up, file, response) =>
                setPostData({ ...postData, Mlogo: response.filename })
              }
              onError={() => {}}
            />
          </div>
        </div>
        <div className="col col-sm-3">
          <div className="form-group mb-2">
            <label className="w-100 mb-2">Primary Color</label>
            <div className="colorpicker">
              <input
                type="color"
                className="me-1"
                value={postData?.color}
                onChange={(e) =>
                  setPostData({ ...postData, color: e.target.value })
                }
              />
              {postData?.color}
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col col-sm-6">
          <div className="form-group mb-2">
            <label className="mb-2">Dealership Contact</label>
            <input
              type="text"
              className="form-control w-100"
              value={postData?.DealershipContact}
              onChange={(e) =>
                setPostData({ ...postData, DealershipContact: e.target.value })
              }
            />
          </div>
        </div>
        <div className="col col-sm-6">
          <div className="form-group mb-2">
            <label className="mb-2">Dealership Website</label>
            <input
              type="text"
              className="form-control w-100"
              value={postData?.DealershipWebsite}
              onChange={(e) =>
                setPostData({ ...postData, DealershipWebsite: e.target.value })
              }
              required={true}
            />
          </div>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col col-sm-4">
          <div className="form-group mb-2">
            <label className="mb-2">Dealership Phone</label>
            <input
              type="text"
              className="form-control w-100"
              value={postData?.Phone}
              onChange={(e) =>
                setPostData({ ...postData, Phone: e.target.value })
              }
            />
          </div>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col col-sm-6">
          <div className="form-group mb-2">
            <label className="mb-2">Inventory URL</label>
            <input
              type="text"
              className="form-control w-100"
              value={postData?.ClientInventoryURL}
              onChange={(e) =>
                setPostData({ ...postData, ClientInventoryURL: e.target.value })
              }
            />
          </div>
        </div>
        <div className="col col-sm-6">
          <div className="form-group mb-2">
            <label className="mb-2">Credit App URL</label>
            <input
              type="text"
              className="form-control w-100"
              value={postData?.CreditAppURL}
              onChange={(e) =>
                setPostData({ ...postData, CreditAppURL: e.target.value })
              }
            />
          </div>
        </div>
      </div>
      <hr />
      <h6 className="tab-tile">Location</h6>
      <div className="row mt-2">
        <div className="col-12">
          <div className="form-group mb-2">
            <label className="mb-2">Address</label>
            <input
              type="text"
              className="form-control w-100"
              value={postData?.Address}
              onChange={(e) =>
                setPostData({ ...postData, Address: e.target.value })
              }
              required={true}
            />
          </div>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col col-sm-2">
          <div className="form-group mb-2">
            <label className="mb-2">City</label>
            <input
              type="text"
              className="form-control"
              value={postData?.City}
              onChange={(e) =>
                setPostData({ ...postData, City: e.target.value })
              }
            />
          </div>
        </div>
        <div className="col col-sm-2">
          <div className="form-group mb-2">
            <label className="mb-2">State</label>
            <select
              className="form-select"
              value={postData?.State}
              onChange={(e) =>
                setPostData({ ...postData, State: e.target.value })
              }
            >
              {state &&
                state.map((item, index) => (
                  <option value={item} key={index}>
                    {item}
                  </option>
                ))}
            </select>
          </div>
        </div>
        <div className="col col-sm-2">
          <div className="form-group mb-2">
            <label className="mb-2">Zip</label>
            <input
              type="text"
              className="form-control"
              value={postData?.Zip}
              onChange={(e) =>
                setPostData({ ...postData, Zip: e.target.value })
              }
              required={true}
            />
          </div>
        </div>
      </div>
      <hr />
      {Number(isDealerfunnelUser) === 0 && (
        <>
          <h6 className="tab-tile">Internal</h6>
          <div className="row mt-2">
            {Number(usertype) < 2 && (
              <div className="col-sm-3">
                <div className="form-group mb-2">
                  <label className="mb-2">Google Analytics ID</label>
                  <select
                    className="form-select"
                    value={postData?.Viewid}
                    onChange={(e) =>
                      setPostData({ ...postData, Viewid: e.target.value })
                    }
                  >
                    <option value="">Select</option>
                    {analystics &&
                      analystics.map((item, index) => (
                        <option
                          value={item.Id}
                          key={index}
                        >{`${item.Name}- ${item.Id}`}</option>
                      ))}
                  </select>
                </div>
              </div>
            )}
            <div className="col-sm-3">
              <div className="form-group mb-2">
                <label className="mb-2">DMS Feed ID</label>
                <input
                  type="text"
                  name="Fcid"
                  className="form-control"
                  disabled={true}
                  value={postData?.Fcid}
                  onChange={(e) =>
                    setPostData({ ...postData, Fcid: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="col-sm-3">
              <div className="form-group mb-2">
                <label className="mb-2">ROI Sales Date Field</label>
                <select
                  name="sales_field"
                  className="form-select"
                  disabled={true}
                  value={postData?.sales_field}
                  onChange={(e) =>
                    setPostData({ ...postData, sales_field: e.target.value })
                  }
                >
                  <option value="DealBookDate">Deal Book Date</option>
                  <option value="EntryDate">Entry Date</option>
                </select>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="form-group mb-2">
                <label className="mb-2">Manual Match</label>
                <div className="w-100">
                  <Switch
                    value={manualMatch}
                    onChange={(e) => setManualMatch(e.target.checked)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-sm-3">
              <div className="form-group mb-2">
                <label className="mb-2">Account Manager</label>
                <select
                  className="form-select"
                  value={postData?.AccountManager}
                  onChange={(e) =>
                    setPostData({ ...postData, AccountManager: e.target.value })
                  }
                  required={true}
                >
                  <option value="">Please select</option>
                  {manager &&
                    manager.map((item, index) => (
                      <option value={item.Id} key={index}>
                        {item.Name}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="form-group mb-2">
                <label className="mb-2">Account Executive</label>
                <select
                  className="form-select"
                  value={postData?.AccountExecutive}
                  onChange={(e) =>
                    setPostData({
                      ...postData,
                      AccountExecutive: e.target.value,
                    })
                  }
                  required={true}
                >
                  <option value="">Please select</option>
                  {executive &&
                    executive.map((item, index) => (
                      <option value={item.Id} key={index}>
                        {item.Name}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="form-group mb-2">
                <label className="mb-2">Area Code</label>
                <input
                  type="text"
                  maxLength="3"
                  className="form-control"
                  value={postData?.AreaCode}
                  onChange={(e) =>
                    setPostData({ ...postData, AreaCode: e.target.value })
                  }
                />
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-sm-3">
              <div className="form-group mb-2">
                <label className="mb-2">Account Manager 2</label>
                <select
                  name="AccountManager2"
                  className="form-select"
                  value={postData?.AccountManager2}
                  onChange={(e) =>
                    setPostData({
                      ...postData,
                      AccountManager2: e.target.value,
                    })
                  }
                >
                  <option value="">Please select</option>
                  {manager &&
                    manager.map((item, index) => (
                      <option value={item.Id} key={index}>
                        {item.Name}
                      </option>
                    ))}
                </select>
              </div>
            </div>
          </div>
          {Number(usertype) !== 5 && (
            <>
              <div className="row mt-2">
                <div className="col-sm-12">
                  <div className="form-group mb-2">
                    <label className="mb-2">Private Label</label>
                    <div className="w-100">
                      <Switch
                        value={privateLabel}
                        onChange={(e) => setPrivateLabel(e.target.checked)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-sm-3">
                  <div className="form-group mb-2">
                    <label className="mb-2 w-100">Logo</label>
                    <PlFileUploader
                      url={`${config.ajaxPath}/imageupload/1`}
                      browseButtonId={`p_logo`}
                      extensions={"jpg,gif,png"}
                      onSuccess={(up, file, response) =>
                        setPostData({ ...postData, pllogo: response.filename })
                      }
                      onError={() => {}}
                    />
                    {postData?.pllogo && (
                      <div className="logo-img w-100">
                        <img
                          src={`${config.domain}/${postData?.pllogo}`}
                          width="80"
                          alt=""
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="form-group mb-2">
                    <label className="mb-2 w-100">Color</label>
                    <div className="colorpicker">
                      <input
                        type="color"
                        value={postData?.pcolor}
                        onChange={(e) =>
                          setPostData({ ...postData, pcolor: e.target.value })
                        }
                      />
                      {postData?.pcolor}
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-group mb-2">
                    <label className="mb-2">Login URL</label>
                    <input
                      type="text"
                      className="form-control"
                      name="loginurl"
                      value={postData?.loginurl}
                      onChange={(e) =>
                        setPostData({ ...postData, loginurl: e.target.value })
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-sm-4">
                  <div className="form-group mb-2">
                    <label className="mb-2 w-100">Copyright</label>
                    <input
                      name="pcopyright"
                      type="text"
                      className="form-control"
                      value={postData?.pcopyright}
                      onChange={(e) =>
                        setPostData({ ...postData, pcopyright: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-group mb-2">
                    <label className="mb-2 w-100">ADF Provider</label>
                    <input
                      name="padf"
                      type="text"
                      className="form-control"
                      value={postData?.padf}
                      onChange={(e) =>
                        setPostData({ ...postData, padf: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-group mb-2">
                    <label className="mb-2 w-100">Send From Email</label>
                    <input
                      name="pemail"
                      type="text"
                      className="form-control"
                      value={postData?.pemail}
                      onChange={(e) =>
                        setPostData({ ...postData, pemail: e.target.value })
                      }
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
      <h6 className="tab-tile">Alerts</h6>
      <div className="row">
        <div className="col-sm-6">
          <div className="form-group mb-2">
            <label className="mb-2">Campaign Email</label>
            <TagsInput
              style={{ minHeight: "100px" }}
              classNames="form-textarea"
              value={postData.campaign_email}
              onChange={(tags) =>
                setPostData({ ...postData, campaign_email: tags })
              }
              beforeAddValidate={(tag, tags) =>
                !tags.includes(tag) && helper.validateEmail(tag)
              }
              name="campaign_email"
              placeHolder="add email"
              required={true}
            />
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group mb-2">
            <label className="mb-2">ADF Email</label>
            <TagsInput
              style={{ minHeight: "100px" }}
              classNames="form-textarea"
              value={postData.adf_email}
              onChange={(tags) => setPostData({ ...postData, adf_email: tags })}
              beforeAddValidate={(tag, tags) =>
                !tags.includes(tag) && helper.validateEmail(tag)
              }
              name="adf_email"
              placeHolder="add email"
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6">
          <div className="form-group mb-2">
            <label className="mb-2">Provider Name</label>
            <input
              id="provider_name"
              name="provider_name"
              type="text"
              className="form-control"
              value={postData?.provider_name}
              onChange={(e) =>
                setPostData({ ...postData, provider_name: e.target.value })
              }
            />
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group mb-2">
            <label className="mb-2">Provider Email</label>
            <input
              id="provider_email"
              name="provider_email"
              type="email"
              className="form-control"
              value={postData?.provider_email}
              onChange={(e) =>
                setPostData({ ...postData, provider_email: e.target.value })
              }
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-4">
          <div className="form-group mb-2">
            <label className="mb-2">When to push Conversation</label>
            <DropdownMultiSelect
              className=""
              options={[
                { value: 1, label: "Appointments" },
                { value: 2, label: "Manual" },
              ]}
              placeholder={"Please Select"}
              labelField="label"
              valueField="value"
              values={postData?.crm_convo_arr}
              onChange={(values) =>
                setPostData({
                  ...postData,
                  crm_convo_arr: values,
                  crm_convo: values.map((item) => item.value),
                })
              }
              onDropdownClose={(values) => {
                console.log(values);
              }}
            />
          </div>
        </div>
      </div>
      {/*  */}
      <hr className="mt-4" />
      <div className="d-flex align-items-center justify-content-center">
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => onClickSave()}
        >
          Save
        </button>
        <button type="button" className="btn btn-outline-secondary ms-1">
          Cancel
        </button>
      </div>
    </form>
  );
}
