import React, { useContext, useEffect, useState } from "react";
import { SlUser } from "react-icons/sl";
import { Link, useParams } from "react-router-dom";
import { AuthContext, UserContext } from "../../../context";
import {
  RxChevronDown,
  RxChevronLeft,
  RxChevronRight,
  RxChevronUp,
  RxDoubleArrowDown,
  RxDoubleArrowLeft,
  RxDoubleArrowRight,
  RxDoubleArrowUp,
} from "react-icons/rx";
import DualListBox from "react-dual-listbox";
import { useNavigate } from "react-router-dom";
import { BsPlus } from "react-icons/bs";

export default function UserGroupEditPage() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { user } = useContext(AuthContext);
  const { getEditUserGroupDataApi, ajaxusergroupApi, updateUserGroupApi } =
    useContext(UserContext);
  //
  const [postData, setPostData] = useState({
    userId: user.Id,
    Name: "",
    private: "",
    groupid: "",
  });
  //
  const [userList, setUserList] = useState([]);
  const [clientList, setClientList] = useState([]);
  //
  const [userlistbox, setUserlistbox] = useState([]);
  const [clientlistbox, setClientlistbox] = useState([]);

  useEffect(() => {
    getEditUserGroupData();
  }, []);

  const getEditUserGroupData = async () => {
    const data = await getEditUserGroupDataApi({ userId: user.Id, id: id });
    if (data.status === "ok") {
      const res = data.response;
      let _postData = { ...postData };
      _postData.userId = user.Id;
      _postData.Name = res?.usergroup.Name;
      _postData.private = res?.usergroup.Agency;
      _postData.groupid = res?.usergroup.Id;
      _postData.usergroup = res?.usergroup.Id;
      setPostData(_postData);
      //
      selectagent({
        userId: user.Id,
        agentid: res?.usergroup.Agency,
        usergroup: res?.usergroup.Id,
        isedit: 1,
      });
    }
  };

  const selectagent = async (params) => {
    const data = await ajaxusergroupApi(params);
    if (data.status === "ok") {
      const res = data.response;
      console.log("====>>>>", res);
      setUserList(res?.user);
      setClientList(res?.client);
      let _userlistbox = res?.user
        .filter((item) => !!item.Sel)
        .map((item) => {
          return { label: item.Name, value: item.Id };
        });
      setUserlistbox(_userlistbox);
      let _clientlistbox = res?.client
        .filter((item) => !!item.Sel)
        .map((item) => {
          return { label: item.Name, value: item.Id };
        });
      setClientlistbox(_clientlistbox);
    }
  };

  const onSave = async () => {
    let params = { ...postData };
    params.userlistbox = userlistbox.map((item) => item.value);
    params.clientlistbox = clientlistbox.map((item) => item.value);
    const data = await updateUserGroupApi(params);
    if (data.status === "ok") {
      navigate(`/users`);
    }
  };

  return (
    <main className="container py-4 position-relative">
      <div className="d-flex align-items-center">
        <div className="section-title">
          <SlUser className="icon me-2" />
          Edit User Group
        </div>
        <div className="ms-auto">
          <Link
            to="/users/newuser"
            className="btn btn-primary d-inline-flex align-items-center"
          >
            <BsPlus />
            New User
          </Link>
          <Link
            to="/users/newgroup"
            className="btn btn-secondary d-inline-flex align-items-center ms-2"
          >
            <BsPlus />
            New User Group
          </Link>
        </div>
      </div>
      <div className="card w-100 mt-4">
        <div className="card-body">
          <div className="row">
            <div className="col col-sm-2 pe-0 border-end border-2">
              <ul className="nav nav-tabs-vertical">
                <li className={`nav-item active`}>User Group Info</li>
              </ul>
            </div>
            <div className="col col-sm-10">
              <div className="tab-content">
                <div className="tab-pane active">
                  <h6 className="tab-tile">User Group Info</h6>
                  <div className="row">
                    <div className="col col-sm-4">
                      <div className="form-group mb-2">
                        <label className="mb-2">Name</label>
                        <input
                          type="text"
                          className="form-control"
                          value={postData?.Name}
                          onChange={(e) =>
                            setPostData({ ...postData, Name: e.target.value })
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <hr className="mt-4" />
                  <div className="user-list mt-3" id="user-list">
                    <DualListBox
                      canFilter
                      simpleValue={false}
                      options={
                        userList.map((item) => {
                          return { label: item.Name, value: item.Id };
                        }) || []
                      }
                      selected={userlistbox}
                      onChange={(value) => setUserlistbox(value)}
                      icons={{
                        moveLeft: <RxChevronLeft />,
                        moveAllLeft: [
                          <RxDoubleArrowLeft key={0} />,
                          // <RxDoubleArrowLeft key={1} />,
                        ],
                        moveRight: <RxChevronRight />,
                        moveAllRight: [
                          <RxDoubleArrowRight key={0} />,
                          // <RxDoubleArrowRight key={1} />,
                        ],
                        moveDown: <RxChevronDown />,
                        moveUp: <RxChevronUp />,
                        moveTop: <RxDoubleArrowUp />,
                        moveBottom: <RxDoubleArrowDown />,
                      }}
                    />
                  </div>
                  <hr className="mt-4" />
                  <div className="user-list mt-3" id="client-list">
                    <DualListBox
                      canFilter
                      simpleValue={false}
                      options={
                        clientList.map((item) => {
                          return { label: item.Name, value: item.Id };
                        }) || []
                      }
                      selected={clientlistbox}
                      onChange={(value) => setClientlistbox(value)}
                      icons={{
                        moveLeft: <RxChevronLeft />,
                        moveAllLeft: [
                          <RxDoubleArrowLeft key={0} />,
                          // <RxDoubleArrowLeft key={1} />,
                        ],
                        moveRight: <RxChevronRight />,
                        moveAllRight: [
                          <RxDoubleArrowRight key={0} />,
                          // <RxDoubleArrowRight key={1} />,
                        ],
                        moveDown: <RxChevronDown />,
                        moveUp: <RxChevronUp />,
                        moveTop: <RxDoubleArrowUp />,
                        moveBottom: <RxDoubleArrowDown />,
                      }}
                    />
                  </div>
                  <hr className="mt-4" />
                  <div className="row">
                    <div className="col-12 d-flex align-items-center">
                      <button
                        className="btn btn-primary ms-auto me-1"
                        onClick={() => onSave()}
                      >
                        Save
                      </button>
                      <button className="btn btn-outline-secondary ms-1 me-auto">
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
