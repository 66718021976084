import React from "react";
import { Box } from "@mui/material";

export default function ProjectCircle(props) {
  const { color = "red", width = 30 } = props;

  return (
    <Box
      sx={{ width: width, height: width, bgcolor: color, borderRadius: "50%" }}
    ></Box>
  );
}
