import React, { useContext, useState } from "react";
import {
  Autocomplete,
  Avatar,
  Box,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import CachedOutlinedIcon from "@mui/icons-material/CachedOutlined";
import { useNavigate } from "react-router-dom";
import { OtherContext } from "../../context";
import { ColorsConfig } from "../../config";

export default function AutoCompleteWithAutocompleteApi() {
  const navigate = useNavigate();
  const { getAutocomplete } = useContext(OtherContext);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const onInputChange = async (event, value, reason) => {
    console.log(event);
    if (reason === "input") {
      if (value && value.length > 3) {
        setLoading(true);
        const result = await getAutocomplete(value);
        setLoading(false);
        setOptions(result.items);
      } else {
        setOptions([]);
      }
    }
  };

  const onChange = (event, value, reason) => {
    console.log(event, reason);
    setOptions([]);
    navigate(value?.link);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Autocomplete
        id="auto-complete-with-autocomplete-api"
        options={options}
        style={{ width: "100%" }}
        onInputChange={onInputChange}
        onChange={onChange}
        includeInputInList
        filterSelectedOptions
        autoComplete
        filterOptions={(x) => x}
        getOptionLabel={(option) => option.name}
        noOptionsText={""}
        clearIcon={true}
        renderOption={(props, option) => {
          return (
            <Box
              component={"li"}
              key={option.id}
              {...props}
              sx={{
                borderBottom: `1px solid ${ColorsConfig.light.primaryBorderColor}`,
              }}
            >
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"center"}
                spacing={2}
              >
                <Box>
                  <Avatar src={option.avatar} sx={{ width: 40, height: 40 }} />
                </Box>
                <Box>
                  <Typography
                    variant="body1"
                    sx={{ color: ColorsConfig.light.info }}
                  >
                    {option.name}
                  </Typography>
                  <Typography variant="caption">{option.email}</Typography>
                </Box>
              </Stack>
            </Box>
          );
        }}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              fullWidth
              placeholder={"Search..."}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <InputAdornment position="end">
                    {loading && <CachedOutlinedIcon />}
                  </InputAdornment>
                ),
              }}
            />
          );
        }}
        fullWidth
        size="small"
      />
    </Box>
  );
}
