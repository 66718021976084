import React, { useContext, useEffect, useState } from "react";
import { BsTelephone, BsTelephonePlus } from "react-icons/bs";
import { BiCalendarStar, BiMessageRoundedError } from "react-icons/bi";
import { HiOutlineUserCircle } from "react-icons/hi2";
import { SlBubble, SlClock, SlChart } from "react-icons/sl";
import { AuthContext, DashboardContext } from "../../context";
import DateFilterPicker from "../DateFilterPicker/DateFilterPicker";
import moment from "moment";
import { Box, Card, Grid, Skeleton, Stack, Typography } from "@mui/material";
import { ColorsConfig } from "../../config";
import { FormatHelper } from "../../utils";

export default function AgentPerformanceSection({
  selectedDealershipIds = "",
}) {
  const [selectedDays, setSelectedDays] = useState(7);
  const [customDateRage, setCustomDateRange] = useState({
    start: new Date(moment().subtract(1, "months")),
    end: new Date(),
  });
  const [showLoading, setShowLoading] = useState(false);
  const { user } = useContext(AuthContext);
  const { getAgentPerformance, agentPerformance } =
    useContext(DashboardContext);

  const items = [
    {
      component: (
        <HiOutlineUserCircle style={{ fontSize: 28, color: "blue" }} />
      ),
      index: "lead_touched",
      title: "LEAD TOUCHED",
      flex: {
        xs: 6,
        md: 4,
      },
    },
    {
      component: <SlBubble style={{ fontSize: 28, color: "purple" }} />,
      index: "message_sent",
      title: "MESSAGES SENT",
      flex: {
        xs: 6,
        md: 4,
      },
    },
    {
      component: (
        <BsTelephone
          style={{
            fontSize: 22,
            color: ColorsConfig.light.primaryLightColor,
          }}
        />
      ),
      index: "calls",
      title: "CALLS",
      flex: {
        xs: 6,
        md: 4,
      },
    },
    {
      component: <SlClock style={{ fontSize: 24, color: "green" }} />,
      index: "appointments",
      title: "APPOINTMENTS",
      flex: {
        xs: 6,
        md: 4,
      },
    },
    {
      component: <BiCalendarStar style={{ fontSize: 28, color: "orange" }} />,
      index: "appointment_rate",
      title: "APP RATE",
      flex: {
        xs: 6,
        md: 4,
      },
    },
    {
      component: (
        <BsTelephonePlus
          style={{ fontSize: 22, color: ColorsConfig.light.primaryLightColor }}
        />
      ),
      index: "callduration",
      title: "CALL DURATION",
      flex: {
        xs: 6,
        md: 4,
      },
    },
    {
      component: (
        <BiMessageRoundedError
          style={{ fontSize: 34, color: ColorsConfig.light.error }}
        />
      ),
      index: "awaitingresponse",
      title: "AWAITING RESPONSE",
      flex: {
        xs: 12,
        md: 12,
      },
    },
  ];

  useEffect(() => {
    if (selectedDays !== "custom") {
      getAgentPerformanceData();
      console.log(showLoading);
    }
  }, [selectedDays]);

  useEffect(() => {
    getAgentPerformanceData();
  }, [customDateRage]);

  const getAgentPerformanceData = async () => {
    setShowLoading(true);
    const res = await getAgentPerformance({
      userId: user.Id,
      days: selectedDays,
      startDate: customDateRage.start,
      endDate: customDateRage.end,
      dealershipIds: selectedDealershipIds,
    });
    if (res) {
      setShowLoading(false);
    }
  };

  return (
    <Card
      sx={{
        p: 2,
        pt: 3,
        height: "100%",
      }}
    >
      <Stack
        direction={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        spacing={2}
      >
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"center"}
          spacing={1}
        >
          <SlChart style={{ fontSize: 20, color: "inherit" }} />
          <Typography variant="body1">Agent Performance</Typography>
        </Stack>
        <DateFilterPicker
          selectedDays={selectedDays}
          setSelectedDays={setSelectedDays}
          customDateRage={customDateRage}
          setCustomDateRange={setCustomDateRange}
        />
      </Stack>
      <Box mt={2}>
        <Grid container spacing={2}>
          {items.map((item, index) => {
            if (showLoading || agentPerformance?.lead_touched == null) {
              return (
                <Grid key={index} item xs={item.flex.xs} md={item.flex.md}>
                  <Stack
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    spacing={1}
                    sx={{
                      border: `1px solid ${ColorsConfig.light.primaryBorderColor}`,
                      borderRadius: 1,
                      height: 80,
                    }}
                  >
                    <Skeleton
                      animation={"wave"}
                      variant={"circular"}
                      width={35}
                      height={35}
                    />
                    <Stack direction={"column"}>
                      <Skeleton animation="wave" variant="text" width={60} />
                      <Skeleton animation="wave" variant="text" width={60} />
                    </Stack>
                  </Stack>
                </Grid>
              );
            } else {
              return (
                <Grid key={index} item xs={item.flex.xs} md={item.flex.md}>
                  <Stack
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    spacing={1}
                    sx={{
                      border: `1px solid ${ColorsConfig.light.primaryBorderColor}`,
                      borderRadius: 1,
                      height: 80,
                    }}
                  >
                    {item.component}
                    <Stack direction={"column"}>
                      <Typography variant="h6">
                        {FormatHelper.numberEN_USFormat(
                          agentPerformance?.[item.index],
                        )}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 10,
                          color: ColorsConfig.light.primaryLightColor,
                        }}
                      >
                        {item.title}
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
              );
            }
          })}
        </Grid>
      </Box>
    </Card>
  );
}
