import Modal from "react-modal";
import React, { useContext, useState, useEffect } from "react";
import { AppointmentContext, AuthContext } from "../../context";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "560px",
    padding: "15px",
    marginTop: "20px",
    marginBottom: "20px",
  },
};

Modal.setAppElement("#root");

export default function AppointmentAssignOwnerModal({
  openModal = "",
  closeModal,
  reloadData,
}) {
  const { user } = useContext(AuthContext);
  const { getApptAssignOwnerModal, assignOwner } =
    useContext(AppointmentContext);
  const [salesman, setSalesman] = useState([]);
  const [postData, setPostData] = useState({
    userId: user.Id,
    responseId: "",
    saleman: "",
  });

  useEffect(() => {
    if (openModal) {
      const responseId = openModal;
      _getApptAssignOwnerModal(responseId);
    }
  }, [openModal]);

  const _getApptAssignOwnerModal = async (responseId) => {
    const data = await getApptAssignOwnerModal({ responseId: responseId });
    if (data.status === "ok") {
      const res = data.response;
      console.log(">>> assignowner modal >>>", res);
      setPostData({
        ...postData,
        userId: user.Id,
        responseId: res.responseId,
      });
      setSalesman(res.salesman);
    }
  };

  const onAssign = async () => {
    console.log(">>> post data >>>", postData);
    const data = await assignOwner(postData);
    if (data.status === "ok") {
      reloadData();
    }
  };

  const afterOpenModal = () => {};

  return (
    <Modal
      isOpen={!!openModal}
      onAfterOpen={() => afterOpenModal()}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Edit Modal"
    >
      <div className="modal-header">
        <h4>Assign Salesman</h4>
      </div>
      <div className="modal-body">
        <form>
          <div className="row">
            <div className="col-12">
              <div className="form-group mt-3">
                {/* <label className="mb-1">Salesman:</label> */}
                <select
                  className="form-select"
                  value={postData.saleman}
                  onChange={(e) =>
                    setPostData({ ...postData, saleman: e.target.value })
                  }
                >
                  <option value="">Select Salesman</option>
                  {!!salesman &&
                    salesman.map((item, index) => (
                      <option value={item.Id} key={index}>
                        {item.Name}
                      </option>
                    ))}
                </select>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="modal-footer mt-3">
        <button className="btn btn-primary me-2" onClick={() => onAssign()}>
          Assign
        </button>
        <button className="btn btn-outline-secondary" onClick={closeModal}>
          Cancel
        </button>
      </div>
    </Modal>
  );
}
