import { useContext } from "react";
import Modal from "react-modal";
import { AuthContext, ClientContext } from "../../context";
import React, { useEffect } from "react";
import { Switch } from "@mui/material";
import { useState } from "react";
import ContentEditable from "react-contenteditable";
import EmojiPickerInput from "../ChatInputElements/EmojiPickerInput";
import GifPickerInput from "../ChatInputElements/GifPickerInput";
import SmartFieldsInput from "../ChatInputElements/SmartFieldsInput";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "560px",
    padding: "15px",
    marginTop: "20px",
    marginBottom: "20px",
  },
};

Modal.setAppElement("#root");

export default function ClientLeadsourceModal({
  cid = "",
  id = "",
  isOpenModal = false,
  afterOpenModal,
  closeModal,
  reloadData,
}) {
  const { user } = useContext(AuthContext);
  const { getLeadsourceModalDataApi, saveLeadSourceApi } =
    useContext(ClientContext);
  const [leadsources, setLeadsources] = useState([]);
  const [funnels, setFunnels] = useState([]);
  //
  const [postData, setPostData] = useState({
    userId: user.Id,
    //
    lead: "",
    vinsource: "",
    funnel: "",
    response: "",
    affirmative: "",
    //
    id: "",
    newlead: 0,
    cid: "",
    crmpush: 0,
    double_optin: 0,
  });
  //
  const [vinsources, setVinsources] = useState([]);
  // const [openSavedReplies, setOpenSavedReplies] = useState(false);

  useEffect(() => {
    if (cid) {
      loadModalData();
      console.log(vinsources);
    }
  }, [cid, id]);

  const loadModalData = async (cid) => {
    const data = await getLeadsourceModalDataApi({
      userId: user.Id,
      cid: cid,
      id: id,
    });
    if (data.status === "ok") {
      const res = data.response;
      setLeadsources(res?.leadsources);
      setFunnels(res?.funnels);
      //
      const _postData = postData;
      _postData.lead = res?.leadsource ? res?.leadsource?.LeadSourceId : "";
      _postData.vinsource = res?.leadsource
        ? res?.leadsource?.vinLeadSourceId
        : "";
      _postData.funnel = res?.leadsource ? res?.leadsource?.Funnelid : "";
      _postData.response = res?.leadsource ? res?.leadsource?.ResponseSMS : "";
      _postData.affirmative = res?.leadsource
        ? res?.leadsource?.AffirmativeSMS
        : "";
      _postData.id = res?.leadsource ? res?.leadsource?.Id : "";
      _postData.double_optin = 0;
      _postData.crmpush = 0;
      _postData.newleads = 0;
      _postData.cid = cid;
      setPostData(_postData);
      //
      if (res?.vin_sources) {
        setVinsources(res?.vin_sources);
      }
    }
  };

  const onSave = async () => {
    if (!cid) {
      return;
    }
    const data = await saveLeadSourceApi(postData);
    if (data.status === "ok") {
      reloadData();
    }
  };

  return (
    <Modal
      isOpen={isOpenModal}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel={"New Lead Source"}
    >
      <div className="modal-header">
        <h4>New Lead Source</h4>
      </div>
      <div className="modal-body">
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <label className="mb-2">Select Lead Source</label>
              <select
                className="form-control"
                value={postData.lead}
                onChange={(e) =>
                  setPostData({ ...postData, lead: e.target.value })
                }
              >
                {leadsources.length > 0 &&
                  leadsources.map((ls, index) => (
                    <option value={ls.Id} key={index}>
                      {ls.Name}
                    </option>
                  ))}
              </select>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12">
            <div className="form-group d-flex align-items-center">
              <label className="">Call Required</label>
              <Switch />
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12">
            <div className="form-group d-flex align-items-center">
              <label className="">CRM Push</label>
              <Switch />
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12">
            <div className="form-group d-flex align-items-center">
              <label className="">Double Opt-In</label>
              <Switch />
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12">
            <div className="form-group">
              <label className="mb-2">Auto Assign Funnel</label>
              <select
                className="form-control"
                value={postData.funnel}
                onChange={(e) =>
                  setPostData({ ...postData, funnel: e.target.value })
                }
              >
                {funnels.length > 0 &&
                  funnels.map((funnel, index) => (
                    <option key={index} value={funnel.Id}>
                      {funnel.Name}
                    </option>
                  ))}
              </select>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12">
            <div className="form-group">
              <label className="mb-2">Response SMS</label>
              <ContentEditable
                className="form-textarea msg-editor"
                tagName="div"
                // placeholder={messageInput ? '' : placeholder}
                html={postData.response}
                onChange={(e) =>
                  setPostData({ ...postData, response: e.target.value })
                }
              />
              <div className="d-flex align-items-center me-auto p-1">
                <EmojiPickerInput
                  onSelect={(emoji) =>
                    setPostData({
                      ...postData,
                      response: postData.response + emoji,
                    })
                  }
                />
                <GifPickerInput
                  onSelect={(imgEle, _giphykey, _giphysize) => {
                    console.log(_giphykey, _giphysize);
                    setPostData({
                      ...postData,
                      response: postData.response + imgEle,
                    });
                    // setGiphykey(_giphykey);
                    // setGiphysize(_giphysize);
                  }}
                />
                <SmartFieldsInput
                  onSelect={(field) =>
                    setPostData({
                      ...postData,
                      response: postData.response + field,
                    })
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12">
            <div className="form-group">
              <label className="mb-2">Affirmative SMS</label>
              <ContentEditable
                className="form-textarea msg-editor"
                tagName="div"
                // placeholder={messageInput ? '' : placeholder}
                html={postData.affirmative}
                onChange={(e) =>
                  setPostData({ ...postData, affirmative: e.target.value })
                }
              />
              <div className="d-flex align-items-center me-auto p-1">
                <EmojiPickerInput
                  onSelect={(emoji) =>
                    setPostData({
                      ...postData,
                      affirmative: postData.affirmative + emoji,
                    })
                  }
                />
                <GifPickerInput
                  onSelect={(imgEle, _giphykey, _giphysize) => {
                    console.log(_giphykey, _giphysize);
                    setPostData({
                      ...postData,
                      affirmative: postData.affirmative + imgEle,
                    });
                    // setGiphykey(_giphykey);
                    // setGiphysize(_giphysize);
                  }}
                />
                <SmartFieldsInput
                  onSelect={(field) =>
                    setPostData({
                      ...postData,
                      affirmative: postData.affirmative + field,
                    })
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer mt-3">
        <button className="btn btn-outline-secondary me-2" onClick={closeModal}>
          Cancel
        </button>
        <button className="btn btn-primary" onClick={() => onSave()}>
          Save
        </button>
      </div>
    </Modal>
  );
}
