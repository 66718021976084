import React, { createContext, useContext } from "react";
import { AxiosContext } from "./axios_context";
import config from "../config/config";

const LeadContext = createContext(undefined);

const LeadContextProvider = (props) => {
  const { axiosInstance } = useContext(AxiosContext);

  const mergeLead = (data) => {
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/actionlead/mergelead`,
      data,
    })
      .then((response) => {
        const data = response.data;
        const res = data.response;
        return res;
      })
      .catch((error) => {
        console.log("err: ", error);
        return null;
      });
  };

  const providerValue = {
    mergeLead,
  };

  return (
    <LeadContext.Provider value={providerValue}>
      {props.children}
    </LeadContext.Provider>
  );
};

export { LeadContext, LeadContextProvider };
