import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  Divider,
  LinearProgress,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import { PrimaryInput } from "..";
import { helper } from "../../utils";
import { AuthContext } from "../../context";
import { ColorsConfig } from "../../config";

export default function AuthForgotPasswordModal(props) {
  const { open, handleCloseModal } = props;
  const style = {
    position: "absolute",
    top: "30%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: {
      xs: "90%",
      md: "35%",
    },
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 2,
    p: 2,
  };
  const { forgotPassword } = useContext(AuthContext);
  const [value, setValue] = useState("");
  const [showError, setShowError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [successText, setSuccessText] = useState("");

  useEffect(() => {
    setValue("");
  }, []);

  const handleChange = (e) => {
    const v = e.target.value;
    setValue(v);
    if (v) {
      setShowError(false);
    } else {
      setShowError(true);
    }
  };

  const submit = async () => {
    if (helper.validateEmail(value)) {
      const data = {
        email: value,
      };
      setIsLoading(true);
      const result = await forgotPassword(data);
      setIsLoading(false);
      if (result?.result == 1) {
        setSuccessText("Success, Please check your email for instructions.");
      } else {
        setErrorText("Sorry, system did not find this Email address.");
      }
    } else {
      setShowError(true);
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="auth_forgot_password_modal"
    >
      <Box sx={style}>
        <Stack
          direction={"row"}
          spacing={1}
          justifyContent={"start"}
          alignItems={"center"}
        >
          <Typography variant="h6">Forgot Password</Typography>
        </Stack>
        <Divider />
        <Box
          sx={{
            p: 3,
          }}
        >
          <PrimaryInput
            type={"email"}
            value={value}
            handleChange={handleChange}
            placeholder="Input Email Address"
          />
          {showError && (
            <Typography
              variant="caption"
              color={ColorsConfig.light.error}
              sx={{ ml: 1 }}
            >
              Required Email Address
            </Typography>
          )}
          {isLoading && (
            <Box sx={{ width: "100%", mt: 1 }}>
              <LinearProgress />
            </Box>
          )}
          {!isLoading && errorText && (
            <Typography
              variant="body2"
              color={ColorsConfig.light.error}
              sx={{ mt: 1 }}
            >
              {errorText}
            </Typography>
          )}
          {!isLoading && successText && (
            <Typography
              variant="body2"
              color={ColorsConfig.light.success}
              sx={{ mt: 1 }}
            >
              {successText}
            </Typography>
          )}
        </Box>
        <Divider />
        <Stack
          direction={"row"}
          justifyContent={"end"}
          alignItems={"center"}
          spacing={2}
          mt={4}
        >
          <Button
            variant="contained"
            color="info"
            onClick={submit}
            disabled={isLoading}
          >
            Continue
          </Button>
          <Button color="inherit" onClick={handleCloseModal}>
            Cancel
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
}
