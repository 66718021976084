import FacebookLogin from "@greatsumini/react-facebook-login";
import React, { useEffect } from "react";
import { useState } from "react";
import { GrFacebook } from "react-icons/gr";

export default function ClientFacebookConnectionTab({ clientid, facebook }) {
  const [postData, setPostData] = useState({
    cid: "",
    access_token: "",
    page_id: "",
    ad_account: "",
    user: "",
    user_token: "",
  });

  useEffect(() => {
    setPostData({
      ...postData,
      cid: clientid,
      page_id: facebook?.PageId ? facebook?.PageId : "",
      ad_account: facebook?.AdAccountId ? facebook?.AdAccountId : "",
    });
  }, [clientid, facebook]);

  return (
    <>
      <h6 className="tab-tile">Facebook Connection</h6>
      <hr />
      {facebook?.PageName ? (
        <div className="" id="existing_connection">
          <p id="ec_pagename">Connected Page: {facebook?.PageName}</p>
          <p id="ec_adaccount">Connected Ad Account: {facebook?.AdAccount}</p>
          <p id="ec_cmp">Connected Campaign: {facebook?.Campaign}</p>
          <p>
            <i id="ec_user">Connected by {facebook?.User}</i>
          </p>
          <div className="col-sm-3" style="margin-top: 30px;">
            <div className="form-row">
              <input
                type="button"
                id="reconnectPage"
                className="btn btn-primary btn-sm"
                value="Reconnect Facebook Page"
              />
              <input
                type="button"
                id="disconnectPage"
                className="btn btn-danger btn-sm"
                style="margin-top: 10px;"
                value="Disconnect Facebook Page"
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col col-sm-3">
            <FacebookLogin
              appId="370182303765902"
              scope="public_profile,ads_management,ads_read,leads_retrieval,
              pages_manage_ads,pages_manage_metadata,
              pages_messaging,pages_read_engagement,
              pages_manage_engagement,pages_manage_posts,
              pages_show_list,pages_read_user_content,catalog_management,read_insights"
              onSuccess={(response) => {
                console.log("Login Success!", response);
              }}
              onFail={(error) => {
                console.log("Login Failed!", error);
              }}
              onProfileSuccess={(response) => {
                console.log("Get Profile Success!", response);
              }}
              render={({ onClick, logout }) => (
                <button
                  className="btn btn-sm btn-primary d-flex align-items-center"
                  onClick={onClick}
                  // eslint-disable-next-line react/no-unknown-property
                  onLogoutClick={logout}
                >
                  <GrFacebook /> &nbsp; Log In
                </button>
              )}
            />
            <small className="text-danger">Please log into this webpage.</small>
          </div>
        </div>
      )}
    </>
  );
}
