import React, { useContext } from "react";
import Modal from "react-modal";
import { AuthContext, UserContext } from "../../context";
import { useState } from "react";
import ContentEditable from "react-contenteditable";
import EmojiPickerInput from "../ChatInputElements/EmojiPickerInput";
import SmartFieldsInput from "../ChatInputElements/SmartFieldsInput";
import { useEffect } from "react";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "560px",
    padding: "15px",
    marginTop: "20px",
    marginBottom: "20px",
    overflow: "visible",
  },
};

Modal.setAppElement("#root");

export default function ClientSavedRepliesEditModal({
  reply,
  isOpenModal = false,
  afterOpenModal,
  closeModal,
  reloadData,
}) {
  const { user } = useContext(AuthContext);
  const { updatetemplateApi } = useContext(UserContext);
  const [postData, setPostData] = useState({
    replyid: "",
    title: "",
    des: "",
  });

  useEffect(() => {
    console.log(user);
    setPostData({
      ...postData,
      replyid: reply?.Id,
      title: reply?.Title ? reply?.Title : "",
      des: reply?.Desciption ? reply?.Desciption : "",
    });
  }, [reply]);

  const onUpdateTemplate = async () => {
    const data = await updatetemplateApi(postData);
    if (data.status === "ok") {
      reloadData();
    }
  };

  return (
    <Modal
      isOpen={isOpenModal}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel={"Saved Replies"}
    >
      <div className="modal-header">
        <h4>Saved Replies</h4>
      </div>
      <div className="modal-body">
        <div className="row mt-3">
          <div className="col-12 col-sm-6">
            <div className="form-group">
              <label className="mb-2">Reply name</label>
              <input
                type="text"
                className="form-control"
                value={postData?.title}
                onChange={(e) =>
                  setPostData({ ...postData, title: e.target.value })
                }
              />
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12 col-sm-12">
            <div className="form-group">
              <label className="mb-2">Reply text</label>
              <ContentEditable
                style={{ minHeight: "100px" }}
                className="form-textarea msg-editor"
                tagName="div"
                // placeholder={messageInput ? '' : placeholder}
                html={postData?.des}
                onChange={(e) =>
                  setPostData({ ...postData, des: e.target.value })
                }
              />
              <div className="d-flex align-items-center me-auto p-1">
                <EmojiPickerInput
                  onSelect={(emoji) =>
                    setPostData({ ...postData, des: postData.des + emoji })
                  }
                />
                <SmartFieldsInput
                  onSelect={(field) =>
                    setPostData({ ...postData, des: postData.des + field })
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer mt-3">
        <button className="btn btn-outline-secondary me-2" onClick={closeModal}>
          Cancel
        </button>
        <button className="btn btn-primary" onClick={() => onUpdateTemplate()}>
          Add template
        </button>
      </div>
    </Modal>
  );
}
