import React, { createContext, useContext } from "react";
import { AxiosContext } from "./axios_context";
import config from "../config/config";
import { AuthContext } from "./auth_context";

const OtherContext = createContext(undefined);

const OtherContextProvider = (props) => {
  const { axiosInstance } = useContext(AxiosContext);
  const { user } = useContext(AuthContext);

  const getPhoneLookInfo = (phone) => {
    return axiosInstance({
      method: "GET",
      url: `${config.reactApi}/ajax/get_phone_look_info?userId=${user?.Id}&phone=${phone}`,
    })
      .then((response) => {
        const data = response.data;
        const res = data.response;
        return res;
      })
      .catch((error) => {
        console.log("err: ", error);
        return null;
      });
  };

  const searchAllLeads = (data) => {
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/modal/all_leads`,
      data,
    })
      .then((response) => {
        const data = response.data;
        const res = data.response;
        return res;
      })
      .catch((error) => {
        console.log("err: ", error);
        return null;
      });
  };

  const getLead = (data) => {
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/modal/getlead`,
      data,
    })
      .then((response) => {
        const data = response.data;
        const res = data.response;
        return res;
      })
      .catch((error) => {
        console.log("err: ", error);
        return null;
      });
  };

  const imageUpload = (file, name, dir) => {
    var data = new FormData();
    data.append("name", name);
    data.append("file", file);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/ajax/image_upload/${dir}`,
      data,
    })
      .then((response) => {
        const data = response.data;
        const res = data;
        return res;
      })
      .catch((error) => {
        console.log("err: ", error);
        return null;
      });
  };

  const getAutocomplete = (term) => {
    return axiosInstance({
      method: "GET",
      url: `${config.reactApi}/ajax/get_autocomplete?userId=${user?.Id}&term=${term}`,
    })
      .then((response) => {
        const data = response.data;
        const res = data.response;
        return res;
      })
      .catch((error) => {
        console.log("err: ", error);
        return null;
      });
  };

  const providerValue = {
    getPhoneLookInfo,
    searchAllLeads,
    getLead,
    imageUpload,
    getAutocomplete,
  };

  return (
    <OtherContext.Provider value={providerValue}>
      {props.children}
    </OtherContext.Provider>
  );
};

export { OtherContext, OtherContextProvider };
