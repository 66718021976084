import React, { createContext, useContext } from "react";
import { AxiosContext } from "./axios_context";
import config from "../config/config";

const FunnelContext = createContext(undefined);

const FunnelContextProvider = (props) => {
  const { axiosInstance, setLoading } = useContext(AxiosContext);

  const getFunnelsPageDataApi = (params) => {
    const data = params;
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/funnels/funnels_data`,
      data,
    })
      .then((response) => {
        const data = response.data;
        console.log("== funnels_data ==", data);
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
      });
  };

  const getFunnelDetailsApi = (params) => {
    const data = params;
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/funnels/funnels_details`,
      data,
    })
      .then((response) => {
        const data = response.data;
        console.log("== funnels_details ==", data);
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
      });
  };

  const filterFunnelByClientApi = (params) => {
    const data = params;
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/funnels/funnels_filterfunnelbyclient`,
      data,
    })
      .then((response) => {
        const data = response.data;
        console.log("== funnels_filterfunnelbyclient ==", data);
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
      });
  };

  const getAjaxLeadsApi = (params) => {
    const data = params;
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/funnels/funnels_ajaxleads`,
      data,
    })
      .then((response) => {
        const data = response.data;
        console.log("== funnels_ajaxleads ==", data);
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
      });
  };

  const sortAjaxLeadsApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/funnels/funnels_sortajaxleads`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("== funnels_sortajaxleads ==", data);
        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
      });
  };

  const deletemessageApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/funnels/funnels_deletemessage`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("== funnels_deletemessage ==", data);
        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
      });
  };

  const editMessageModalDataApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/funnels/funnels_editmessage`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("== funnels_editmessage ==", data);
        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
      });
  };

  const updateMessageApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/funnels/funnels_updatemessage`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("== funnels_updatemessage ==", data);
        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
      });
  };

  const editLabelModalDataApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/funnels/funnels_editlabelmodal`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("== funnels_editlabelmodal ==", data);
        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
      });
  };

  const updateLabelsApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/funnels/funnels_updatelabel`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("== funnels_updatelabel ==", data);
        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
      });
  };

  const editStatusModalDataApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/funnels/funnels_editstatusmodal`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("== funnels_editstatusmodal ==", data);
        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
      });
  };

  const updateStatusApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/funnels/funnels_updatestatus`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("== funnels_updatestatus ==", data);
        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
      });
  };

  const changeexcludesundayApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/funnels/funnels_changeexcludesunday`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("== funnels_changeexcludesunday ==", data);
        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
      });
  };

  const createNewMessageApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/funnels/funnels_createnewmessage`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("== funnels_createnewmessage ==", data);
        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
      });
  };

  const changeFunnelNameModalApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/funnels/funnels_changefunnelname`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("== funnels_changefunnelname ==", data);
        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
      });
  };

  const updateFunnelNameApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/funnels/funnels_updatefunnelname`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("== funnels_updatefunnelname ==", data);
        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
      });
  };

  const editDelayModalDataApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/funnels/funnels_editdelay`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("== funnels_editdelay ==", data);
        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
      });
  };

  const updateDelayApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/funnels/funnels_updatedelay`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("== funnels_updatedelay ==", data);
        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
      });
  };

  const filterleadstatusApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/funnels/funnels_filterlead`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("== funnels_filterlead ==", data);
        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
      });
  };

  const activatedFromFunnelLeadApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/funnels/funnels_activatedfromfunnellead`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("== funnels_activatedfromfunnellead ==", data);
        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
      });
  };

  const pausedFromFunnelLeadApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/funnels/funnels_pausedfromfunnellead`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("== funnels_pausedfromfunnellead ==", data);
        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
      });
  };

  const reassignModalDataApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/funnels/funnels_reassignmodal`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("== funnels_reassignmodal ==", data);
        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
      });
  };

  const reassignFunnelConfirmModalApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/funnels/funnels_reassignfunnelconfirmmodal`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("== funnels_reassignfunnelconfirmmodal ==", data);
        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
      });
  };

  const reassignFunnelApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/funnels/funnels_reassignfunnel`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("== funnels_reassignfunnel ==", data);
        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
      });
  };

  const newFunnelPageDataApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/funnels/funnels_new`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("== funnels_new ==", data);
        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
      });
  };

  const createNewFunnelApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/funnels/funnels_create`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("== funnels_create ==", data);
        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
      });
  };

  const copyFunnelPageDataApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/funnels/funnels_copyfunnel`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("== funnels_copyfunnel ==", data);
        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
      });
  };

  const providerValue = {
    getFunnelsPageDataApi,
    getFunnelDetailsApi,
    filterFunnelByClientApi,
    getAjaxLeadsApi,
    sortAjaxLeadsApi,
    deletemessageApi,
    editMessageModalDataApi,
    updateMessageApi,
    editLabelModalDataApi,
    updateLabelsApi,
    editStatusModalDataApi,
    updateStatusApi,
    changeexcludesundayApi,
    createNewMessageApi,
    changeFunnelNameModalApi,
    updateFunnelNameApi,
    editDelayModalDataApi,
    updateDelayApi,
    filterleadstatusApi,
    activatedFromFunnelLeadApi,
    pausedFromFunnelLeadApi,
    reassignModalDataApi,
    reassignFunnelConfirmModalApi,
    reassignFunnelApi,
    newFunnelPageDataApi,
    createNewFunnelApi,
    copyFunnelPageDataApi,
  };

  return (
    <FunnelContext.Provider value={providerValue}>
      {props.children}
    </FunnelContext.Provider>
  );
};

export { FunnelContext, FunnelContextProvider };
