import React, { useContext, useState } from "react";
import Modal from "react-modal";
import { AuthContext, FunnelContext } from "../../context";
import { useEffect } from "react";
import DropdownMultiSelect from "../select/dropdown_multi_select";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "560px",
    padding: "15px",
    marginTop: "20px",
    marginBottom: "20px",
    overflow: "visible",
  },
};

Modal.setAppElement("#root");

export default function FunnelEditNameModal({
  data = null,
  isOpenModal = false,
  afterOpenModal,
  closeModal,
  reloadData,
}) {
  const { user } = useContext(AuthContext);
  const { updateFunnelNameApi } = useContext(FunnelContext);
  const [postData, setPostData] = useState({
    userId: user.Id,
    name: "",
    fid: "",
    cltArr: [],
    clt: [],
  });
  const [client, setClient] = useState([]);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (data) {
      console.log(showError);
      setClient(data?.client);
      let _postData = { ...postData };
      _postData.name = data?.name;
      _postData.fid = data?.funnelid;
      if (data?.fid.includes("0")) {
        _postData.cltArr = [
          ..._postData.cltArr,
          ...[{ value: "0", label: "Global Funnel" }],
        ];
        _postData.clt = [..._postData.clt, ...["0"]];
      }
      for (let i = 0; i < data?.client.length; i++) {
        if (data?.fid.includes(data?.client[i].Id)) {
          _postData.cltArr = [
            ..._postData.cltArr,
            ...[{ value: data?.client[i].Id, label: data?.client[i].Name }],
          ];
          _postData.clt = [..._postData.clt, ...[data?.client[i].Id]];
        }
      }
      setPostData(_postData);
    }
  }, [data]);

  const onSave = async () => {
    if (!postData.Delaycategory || !postData.Delaycount) {
      setShowError(true);
      return;
    }
    const data = await updateFunnelNameApi(postData);
    if (data.status === "ok") {
      reloadData();
    }
  };

  return (
    <Modal
      isOpen={isOpenModal}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Edit Modal"
    >
      <div className="modal-header">
        <h4 className="d-flex align-items-center">Edit Funnel Name</h4>
      </div>
      <div className="modal-body">
        <div className="row mt-4">
          <div className="col-12">
            <div className="form-group mb-2">
              <label className="mb-2">Funnel Name</label>
              <input type="text" className="form-control" />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="form-group mb-2">
              <label className="mb-2">Client</label>
              {/* <select className="form-select">
                <option value="0">Global Funnel</option>
                {
                  client.map((item, index) =>
                    <option value={item.Id} key={index}>{item.Name}</option>
                  )
                }
              </select> */}
              <DropdownMultiSelect
                className=""
                options={[
                  ...[{ value: "0", label: "Global Funnel" }],
                  ...client
                    .filter((item) => !!item.Name)
                    .map((item) => {
                      return { value: item.Id, label: item.Name };
                    }),
                ]}
                placeholder={"Please Select"}
                labelField="label"
                valueField="value"
                values={postData.cltArr}
                onChange={(values) =>
                  setPostData({
                    ...postData,
                    cltArr: values,
                    clt: values.map((item) => item.value),
                  })
                }
                onDropdownClose={(values) => {
                  console.log(values);
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer mt-3">
        <button className="btn btn-outline-secondary me-2" onClick={closeModal}>
          Cancel
        </button>
        <button className="btn btn-primary" onClick={() => onSave()}>
          Save
        </button>
      </div>
    </Modal>
  );
}
