import React, { useContext } from "react";
import { SlUser } from "react-icons/sl";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../context";
import { BsPlus } from "react-icons/bs";
import UserNewFormAgency from "./UserNewFormAgency";
import UserNewFormDFManager from "./UserNewFormDFManager";
import UserNewForm from "./UserNewForm";

export default function UserNewPage() {
  const { user } = useContext(AuthContext);

  return (
    <main className="container py-4 position-relative">
      <div className="d-flex align-items-center">
        <div className="section-title">
          <SlUser className="icon me-2" />
          Create User
        </div>
        <div className="ms-auto">
          <Link
            to="/users/newuser"
            className="btn btn-primary d-inline-flex align-items-center"
          >
            <BsPlus />
            New User
          </Link>
          <Link
            to="/users/newgroup"
            className="btn btn-secondary d-inline-flex align-items-center ms-2"
          >
            <BsPlus />
            New User Group
          </Link>
        </div>
      </div>
      <div className="card w-100 mt-4">
        <div className="card-body">
          <div className="row">
            <div className="col col-sm-2 pe-0 border-end border-2">
              <ul className="nav nav-tabs-vertical">
                <li className={`nav-item active`}>User Info</li>
              </ul>
            </div>
            <div className="col col-sm-10">
              <div className="tab-content">
                <div className="tab-pane active">
                  {Number(user.Type) === 5 ? (
                    <UserNewFormAgency />
                  ) : Number(user.Type) === 8 ? (
                    <UserNewFormDFManager />
                  ) : (
                    <UserNewForm />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
