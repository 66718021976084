import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  FormControlLabel,
  Modal,
  Radio,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import TableViewOutlinedIcon from "@mui/icons-material/TableViewOutlined";
import {
  AutoCompleteWithLeadApi,
  MultiSelect,
  PartLoader,
} from "..";
import { SharedContext, OtherContext, LeadContext } from "../../context";
import { toast } from "react-toastify";

export default function ActionsMergeLeadInfoModal(props) {
  const { open, handleCloseModal } = props;
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: {
      xs: "90%",
      md: "70%",
    },
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 2,
    p: 2,
  };
  const items = [
    {
      value: "name",
      name: "Name",
    },
    {
      value: "Email",
      name: "Email",
    },
    {
      value: "Phone",
      name: "Phone",
    },
    {
      value: "Note",
      name: "Notes",
    },
    {
      value: "Label",
      name: "Labels",
    },
    {
      value: "tyear",
      name: "Trade In Year",
    },
    {
      value: "ymake",
      name: "Trade In Make",
    },
    {
      value: "tmodel",
      name: "Trade In Model",
    },
    {
      value: "iyear",
      name: "Vehicle Interest Year",
    },
    {
      value: "imake",
      name: "Vehicle Interest Make",
    },
    {
      value: "imodel",
      name: "Vehicle Interest Model",
    },
  ];
  const { dealerships } = useContext(SharedContext);
  const { searchAllLeads, getLead } = useContext(OtherContext);
  const { mergeLead } = useContext(LeadContext);
  const [clients, setClients] = useState([]);
  const [oneClients, setOneClients] = useState([]);
  const [twoClients, setTwoClients] = useState([]);
  const [oneLeads, setOneLeads] = useState([]);
  const [twoLeads, setTwoLeads] = useState([]);
  const [oneLead, setOneLead] = useState(null);
  const [twoLead, setTwoLead] = useState(null);
  const [master, setMaster] = useState("0");
  const [loading, setLoading] = useState(false);
  const [mergeLeadInfo, setMergeLeadInfo] = useState({
    name: "0",
    Email: "0",
    Phone: "0",
    Note: "0",
    Label: "0",
    tyear: "0",
    ymake: "0",
    tmodel: "0",
    iyear: "0",
    imake: "0",
    imodel: "0",
  });

  useEffect(() => {
    initValues();
  }, [dealerships]);

  useEffect(() => {
    setOneLead(null);
    setTwoLead(null);
    setMaster("0");
    setMergeLeadInfo({
      name: "0",
      Email: "0",
      Phone: "0",
      Note: "0",
      Label: "0",
      tyear: "0",
      ymake: "0",
      tmodel: "0",
      iyear: "0",
      imake: "0",
      imodel: "0",
    });
  }, [open]);

  const initValues = () => {
    const items = [];
    dealerships?.map((item) => {
      items.push({ value: item["Id"], name: item["Name"] });
    });
    setClients(items);
  };

  const clientsChange = (e, column) => {
    if (typeof e !== "string") {
      if (column === 1) setOneClients(e);
      if (column === 2) setTwoClients(e);
    }
  };

  const leadsFilter = async (e, column) => {
    const items = [];
    if (e) {
      if (e.length > 2) {
        const data = {
          q: e,
          client: oneClients.map((c) => c.value).join(","),
        };
        const result = await searchAllLeads(data);
        result?.leads.map((lead, index) => {
          if (items.filter((i) => i.value === lead["Id"]).length === 0) {
            items.push({
              value: lead["Id"],
              name:
                (index + 1).toString() +
                ": " +
                lead["pname"] +
                " / " +
                lead["ClientName"],
              pname: lead["pname"],
              clientName: lead["ClientName"],
            });
          }
        });
      }
    }
    if (column === 1) setOneLeads(items);
    if (column === 2) setTwoLeads(items);
  };

  const leadsSelect = async (v, column) => {
    setLoading(true);
    const result = await getLead({ lead: v.value });
    setLoading(false);
    if (column === 1) setOneLead(result.lead);
    if (column === 2) setTwoLead(result.lead);
  };

  const selectAll = (v) => {
    let m = mergeLeadInfo;
    if (m) {
      Object.keys(m).forEach((key) => {
        m[key] = v;
      });
      setMergeLeadInfo({ ...m });
    }
  };

  const submit = async () => {
    if (oneLead == null || twoLead == null) {
      toast.warning("Please select leads to merge");
      return false;
    }
    const data = {
      masterlead: parseInt(master),
      lead1: oneLead["Id"],
      lead2: twoLead["Id"],
      fullname: mergeLeadInfo.name != "0" ? "1" : null,
      Phone: mergeLeadInfo.Phone != "0" ? "1" : null,
      Email: mergeLeadInfo.Email != "0" ? "1" : null,
      Label: mergeLeadInfo.Label != "0" ? "1" : null,
      Note: mergeLeadInfo.Note != "0" ? "1" : null,
      tyear: mergeLeadInfo.tyear != "0" ? "1" : null,
      ymake: mergeLeadInfo.ymake != "0" ? "1" : null,
      tmodel: mergeLeadInfo.tmodel != "0" ? "1" : null,
      iyear: mergeLeadInfo.iyear != "0" ? "1" : null,
      imake: mergeLeadInfo.imake != "0" ? "1" : null,
      imodel: mergeLeadInfo.imodel != "0" ? "1" : null,
    };
    setLoading(true);
    await mergeLead(data);
    setLoading(false);
    toast.success("Successfully merged!");
    handleCloseModal();
  };

  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="actions_merge_lead_modal"
    >
      <Box sx={style}>
        {loading && <PartLoader />}
        <Stack
          direction={"row"}
          spacing={1}
          justifyContent={"start"}
          alignItems={"center"}
        >
          <TableViewOutlinedIcon fontSize="medium" color="inherit" />
          <Typography variant="h6">Merge Lead</Typography>
        </Stack>
        <Typography variant="body2">
          Select relevant information from two leads and merge theme as one.
        </Typography>
        <Box
          sx={{
            p: 3,
          }}
        >
          <Table>
            <TableHead>
              <TableRow
                sx={{
                  "& th, & td": { pl: 0.5, pt: 1, pb: 1, textAlign: "start" },
                }}
              >
                <TableCell></TableCell>
                <TableCell>
                  <MultiSelect
                    options={clients}
                    values={oneClients}
                    handleChange={(e) => clientsChange(e, 1)}
                    placeholder={"Select Clients"}
                    isSearch={false}
                  />
                  <Box mt={1}>
                    <AutoCompleteWithLeadApi
                      options={oneLeads}
                      handleChange={(e) => leadsFilter(e, 1)}
                      handleSelect={(e) => leadsSelect(e, 1)}
                      placeholder={"Select and Begin Typing"}
                    />
                  </Box>
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    mt={0.5}
                  >
                    <FormControlLabel
                      label="master"
                      control={
                        <Radio
                          checked={master === "1"}
                          onChange={(e) => {
                            setMaster(e.target.value);
                          }}
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: 18,
                            },
                          }}
                          value={"1"}
                        />
                      }
                    />
                    <Button
                      variant="text"
                      sx={{ textTransform: "none" }}
                      onClick={() => selectAll("1")}
                    >
                      Select All
                    </Button>
                  </Stack>
                </TableCell>
                <TableCell>
                  <MultiSelect
                    options={clients}
                    values={twoClients}
                    handleChange={(e) => clientsChange(e, 2)}
                    placeholder={"Select Clients"}
                    isSearch={false}
                  />
                  <Box mt={1}>
                    <AutoCompleteWithLeadApi
                      options={twoLeads}
                      handleChange={(e) => leadsFilter(e, 2)}
                      handleSelect={(e) => leadsSelect(e, 2)}
                      placeholder={"Select and Begin Typing"}
                    />
                  </Box>
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    mt={0.5}
                  >
                    <FormControlLabel
                      label="master"
                      control={
                        <Radio
                          checked={master === "2"}
                          onChange={(e) => {
                            setMaster(e.target.value);
                          }}
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: 18,
                            },
                          }}
                          value={"2"}
                        />
                      }
                    />
                    <Button
                      variant="text"
                      sx={{ textTransform: "none" }}
                      onClick={() => selectAll("2")}
                    >
                      Select All
                    </Button>
                  </Stack>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item, index) => {
                return (
                  <TableRow
                    key={index}
                    sx={{
                      "& th, & td": {
                        pl: 0.5,
                        pt: 1,
                        pb: 1,
                        textAlign: "start",
                      },
                    }}
                  >
                    <TableCell>{item.name}</TableCell>
                    <TableCell>
                      {oneLead && (
                        <FormControlLabel
                          label={oneLead?.[item.value]}
                          control={
                            <Radio
                              checked={mergeLeadInfo?.[item.value] == "1"}
                              onChange={(e) => {
                                let m = mergeLeadInfo;
                                m[item.value] = e.target.value;
                                setMergeLeadInfo({ ...m });
                              }}
                              sx={{
                                "& .MuiSvgIcon-root": {
                                  fontSize: 18,
                                },
                                p: 0,
                                m: 0,
                                mr: 1,
                              }}
                              value={"1"}
                              disabled={master == 1}
                            />
                          }
                          sx={{
                            p: 0,
                            m: 0,
                          }}
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      {twoLead && (
                        <FormControlLabel
                          label={twoLead?.[item.value]}
                          control={
                            <Radio
                              checked={mergeLeadInfo?.[item.value] == "2"}
                              onChange={(e) => {
                                let m = mergeLeadInfo;
                                m[item.value] = e.target.value;
                                setMergeLeadInfo({ ...m });
                              }}
                              sx={{
                                "& .MuiSvgIcon-root": {
                                  fontSize: 18,
                                },
                                p: 0,
                                m: 0,
                                mr: 1,
                              }}
                              value={"2"}
                              disabled={master == 2}
                            />
                          }
                          sx={{
                            p: 0,
                            m: 0,
                          }}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
        <Stack
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          spacing={2}
          mt={1}
        >
          <Button variant="contained" color="info" onClick={submit}>
            Merge
          </Button>
          <Button color="inherit" onClick={handleCloseModal}>
            Cancel
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
}
