import { Link, useParams } from "react-router-dom";
import { SlBriefcase } from "react-icons/sl";
import React, { useContext, useEffect, useState } from "react";
import {
  ClientAISetupTab,
  ClientAlertsTab,
  ClientCTASetupTab,
  ClientCampaignTab,
  ClientDMSFeedsTab,
  ClientDefaultMessageTab,
  ClientFacebookConnectionTab,
  ClientHoursTab,
  ClientInfoTab,
  ClientLeadSourcesTab,
  ClientPhoneRegistrationTab,
  ClientSMSWidgetTab,
  ClientSavedRepliesTab,
  ClientUsersTab,
} from "../../components";
import helper from "../../utils/helper";
import { AuthContext, ClientContext } from "../../context";
import { BsPlus } from "react-icons/bs";

export default function ClientEditPage() {
  const { keyid } = useParams();
  const [activeTab, setActiveTab] = useState("Client Info");
  const { user } = useContext(AuthContext);
  const { getClientDetailApi } = useContext(ClientContext);
  //
  const [isDealerfunnel, setIsDealerfunnel] = useState(undefined);
  const [isagentchain, setIsagentchain] = useState(undefined);
  //
  const [ccount, setCcount] = useState(undefined);
  const [lscount, setLscount] = useState(undefined);
  const [scount, setScount] = useState(undefined);
  const [usertype, setUsertype] = useState(undefined);
  const [facebook, setFacebook] = useState(undefined);
  const [Fcid, setFcid] = useState(undefined);
  //
  const [client, setClient] = useState(undefined);
  const [agent, setAgent] = useState(undefined);
  const [dealer, setDealer] = useState(undefined);
  const [state, setState] = useState(undefined);
  const [analystics, setAnalystics] = useState(undefined);
  const [manager, setManager] = useState(undefined);
  const [executive, setExecutive] = useState(undefined);
  // post data
  const [postData, setPostData] = useState(undefined);
  // campagin
  const [cmpboard, setCmpboard] = useState({
    cmp_count: 0,
    qty: 0,
    response: 0,
    leads: 0,
  });
  const [Campaign, setCampaign] = useState([]);

  useEffect(() => {
    console.log("=ADMIN=", keyid, helper.loginTypecheck("ADMIN"));
    console.log("=EXICUTIVE=", keyid, helper.loginTypecheck("EXICUTIVE"));
    console.log("=MANAGER=", keyid, helper.loginTypecheck("MANAGER"));
    console.log("=MANUAL=", keyid, helper.loginTypecheck("MANUAL"));
    console.log(Fcid);
    if (keyid) {
      getClientDetailData(keyid);
    }
  }, [keyid]);

  const getClientDetailData = async (keyid) => {
    const data = await getClientDetailApi({ userId: user.Id, keyid: keyid });
    if (data.status === "ok") {
      const res = data.response;
      setIsDealerfunnel(res?.is_dealerfunnel);
      setIsagentchain(res?.isagentchain);
      setCcount(res?.ccount);
      setLscount(res?.lscount);
      setScount(res?.scount);
      setUsertype(res?.usertype);
      setFacebook(res?.facebook);
      setFcid(res?.Fcid);
      //
      setClient(res?.client);
      setAgent(res?.agent);
      setDealer(res?.dealer);
      setState(res?.state);
      setAnalystics(res?.analystics);
      setManager(res?.manager);
      setExecutive(res?.executive);
      //
      setPostData({
        // === user info
        userId: user.Id,
        // === main data
        plogo: res?.client?.Logo,
        mlogo: res?.client?.Mlogo,
        clientid: res?.client?.Id,
        plIssmsogo: 0,
        isprivate: Number(res?.usertype) === 5 ? 0 : res?.client?.isprivate,
        ManualMatch: 0,
        DealerFunnel: 0,
        adf_flag: 0,
        cmpoverview_flag: 0,
        sendweblead_flag: 0,
        Isafterhour: 0,
        Ishotbutton: 0,
        // client info
        Name: res?.client?.Name,
        Ctype: res?.client?.Ctype,
        Agent: res?.client?.Agent,
        Agency: res?.client?.Agent,
        Agency1: res?.client?.Agent,
        Group: res?.client?.Group,
        Logo: res?.client?.Logo,
        Mlogo: res?.client?.Mlogo,
        color: res?.client?.Color,
        DealershipContact: res?.client?.DealershipContact,
        DealershipWebsite: res?.client?.DealershipWebsite,
        Phone: res?.client?.Phone.type3,
        ClientInventoryURL: res?.client?.ClientInventoryURL,
        CreditAppURL: res?.client?.CreditAppURL,
        Notes: res?.client?.Notes,
        Hotemail: res?.client?.Hotemail,
        Address: res?.client?.Address,
        City: res?.client?.City,
        State: res?.client?.State,
        Zip: res?.client?.Zip,
        //
        Viewid: res?.client?.Viewid,
        sales_field: res?.client?.sales_field,
        AccountManager: res?.client?.AccountManager,
        AccountExecutive: res?.client?.AccountExecutive,
        AreaCode: res?.client?.AreaCode,
        AccountManager2: res?.client?.AccountManager2,
        //
        pllogo: res?.client?.pllogo,
        pfavicon: res?.client?.pfavicon,
        pcolor: res?.client?.pcolor,
        darklogo: res?.client?.darklogo,
        pbackgroundimage: res?.client?.pbackgroundimage,
        pbackgroundcolor: res?.client?.pbackgroundcolor,
        pcopyright: res?.client?.pcopyright,
        padf: res?.client?.padf,
        pemail: res?.client?.pemail,
        pdomain: res?.client?.pdomain,
        pterms: res?.client?.pterms,
        pprivacy: res?.client?.pprivacy,
        //
        Fcid: res?.Fcid,
        // hours
        time_zone: res?.client?.time_zone,
        Isopen_d1: res?.client?.Isopen_d1,
        Isopen_d2: res?.client?.Isopen_d2,
        Isopen_d3: res?.client?.Isopen_d3,
        Isopen_d4: res?.client?.Isopen_d4,
        Isopen_d5: res?.client?.Isopen_d5,
        Isopen_d6: res?.client?.Isopen_d6,
        Isopen_d7: res?.client?.Isopen_d7,
        From_d1: res?.client?.From_d1,
        To_d1: res?.client?.To_d1,
        From_d2: res?.client?.From_d2,
        To_d2: res?.client?.To_d2,
        From_d3: res?.client?.From_d3,
        To_d3: res?.client?.To_d3,
        From_d4: res?.client?.From_d4,
        To_d4: res?.client?.To_d4,
        From_d5: res?.client?.From_d5,
        To_d5: res?.client?.To_d5,
        From_d6: res?.client?.From_d6,
        To_d6: res?.client?.To_d6,
        From_d7: res?.client?.From_d7,
        To_d7: res?.client?.To_d7,
        hours: res?.client?.Houreafterclosing,
        Houreafterclosing: res?.client?.Houreafterclosing,
        new_lead_message: res?.client?.Newleadmessage,
      });
      //
      setCmpboard({
        cmp_count: res?.cmp_count,
        qty: res?.qty,
        response: res?.response,
        leads: res?.leads,
      });
      setCampaign(res?.Campaign);
    }
  };

  return (
    <main className="container py-4 position-relative">
      <div className="d-flex align-items-center">
        <div className="section-title">
          <SlBriefcase className="icon me-2" />
          Edit Client
        </div>
        <div className="ms-auto">
          <Link
            to="/clients/newclient"
            className="btn btn-primary d-inline-flex align-items-center"
          >
            <BsPlus />
            New Client
          </Link>
        </div>
      </div>
      <div className="card w-100 mt-4 overflow-visible">
        <div className="card-body">
          <div className="row">
            <div className="col col-sm-2 pe-0 border-end border-2">
              <ul className="nav nav-tabs-vertical">
                <li
                  className={`nav-item${
                    activeTab === "Client Info" ? " active" : ""
                  }`}
                  onClick={() => setActiveTab("Client Info")}
                >
                  Client Info
                </li>
                <li
                  className={`nav-item${
                    activeTab === "Hours" ? " active" : ""
                  }`}
                  onClick={() => setActiveTab("Hours")}
                >
                  Hours
                </li>
                <li
                  className={`nav-item${
                    activeTab === "Campaign" ? " active" : ""
                  }`}
                  onClick={() => setActiveTab("Campaign")}
                >
                  Campaign{" "}
                  <span className="badge rounded-pill bg-primary ms-1">
                    {ccount?.cmp ? ccount?.cmp : 0}
                  </span>
                </li>
                {Number(isDealerfunnel) === 0 && (
                  <li
                    className={`nav-item${
                      activeTab === "Hours" ? " active" : ""
                    }`}
                    onClick={() => setActiveTab("Seeds")}
                  >
                    Seeds{" "}
                    <span className="badge rounded-pill bg-primary ms-1">
                      {scount ? scount : 0}
                    </span>
                  </li>
                )}
                <li
                  className={`nav-item${
                    activeTab === "Users" ? " active" : ""
                  }`}
                  onClick={() => setActiveTab("Users")}
                >
                  Users{" "}
                  <span className="badge rounded-pill bg-primary ms-1">
                    {ccount?.user ? ccount?.user : 0}
                  </span>
                </li>
                <li
                  className={`nav-item${
                    activeTab === "Lead Sources" ? " active" : ""
                  }`}
                  onClick={() => setActiveTab("Lead Sources")}
                >
                  Lead Sources{" "}
                  <span className="badge rounded-pill bg-primary ms-1">
                    {lscount ? lscount : 0}
                  </span>
                </li>
                <li
                  className={`nav-item${
                    activeTab === "Alerts" ? " active" : ""
                  }`}
                  onClick={() => setActiveTab("Alerts")}
                >
                  Alerts
                </li>
                <li
                  className={`nav-item${
                    activeTab === "Saved Replies" ? " active" : ""
                  }`}
                  onClick={() => setActiveTab("Saved Replies")}
                >
                  Saved Replies
                </li>
                {(Number(isDealerfunnel) === 1 || Number(usertype) === 1) && (
                  <li
                    className={`nav-item${
                      activeTab === "SMS Widget" ? " active" : ""
                    }`}
                    onClick={() => setActiveTab("SMS Widget")}
                  >
                    SMS Widget
                  </li>
                )}
                {facebook?.access === "yes" && (
                  <li
                    className={`nav-item${
                      activeTab === "Facebook Connection" ? " active" : ""
                    }`}
                    onClick={() => setActiveTab("Facebook Connection")}
                  >
                    Facebook Connection
                  </li>
                )}
                <li
                  className={`nav-item${
                    activeTab === "AI Setup" ? " active" : ""
                  }`}
                  onClick={() => setActiveTab("AI Setup")}
                >
                  AI Setup
                </li>
                <li
                  className={`nav-item${
                    activeTab === "CTA Setup" ? " active" : ""
                  }`}
                  onClick={() => setActiveTab("CTA Setup")}
                >
                  CTA Setup
                </li>
                <li
                  className={`nav-item${
                    activeTab === "Default Message" ? " active" : ""
                  }`}
                  onClick={() => setActiveTab("Default Message")}
                >
                  Default Message
                </li>
                <li
                  className={`nav-item${
                    activeTab === "Phone Registration" ? " active" : ""
                  }`}
                  onClick={() => setActiveTab("Phone Registration")}
                >
                  Phone Registration
                </li>
                <li
                  className={`nav-item${
                    activeTab === "DMS Feeds" ? " active" : ""
                  }`}
                  onClick={() => setActiveTab("DMS Feeds")}
                >
                  DMS Feeds
                </li>
              </ul>
            </div>
            <div className="col col-sm-10">
              <div className="tab-content">
                <div className="tab-pane active">
                  {activeTab === "Client Info" && (
                    <ClientInfoTab
                      isDealerfunnel={isDealerfunnel}
                      isagentchain={isagentchain}
                      agent={agent}
                      dealer={dealer}
                      state={state}
                      usertype={usertype}
                      analystics={analystics}
                      manager={manager}
                      executive={executive}
                      postData={postData}
                      setPostData={setPostData}
                      reloadData={() => getClientDetailData(keyid)}
                    />
                  )}
                  {activeTab === "Hours" && (
                    <ClientHoursTab
                      postData={postData}
                      setPostData={setPostData}
                      reloadData={() => getClientDetailData(keyid)}
                    />
                  )}
                  {activeTab === "Campaign" && (
                    <ClientCampaignTab
                      isDealerfunnel={isDealerfunnel}
                      cmpboard={cmpboard}
                      Campaign={Campaign}
                      client={client}
                      postData={postData}
                      setPostData={setPostData}
                      reloadData={() => getClientDetailData(keyid)}
                    />
                  )}
                  {activeTab === "Users" && <ClientUsersTab client={client} />}
                  {activeTab === "Lead Sources" && (
                    <ClientLeadSourcesTab clientid={client.Id} />
                  )}
                  {activeTab === "Alerts" && (
                    <ClientAlertsTab clientid={client.Id} />
                  )}
                  {activeTab === "Saved Replies" && (
                    <ClientSavedRepliesTab clientid={client.Id} />
                  )}
                  {activeTab === "SMS Widget" && (
                    <ClientSMSWidgetTab clientid={client.Id} />
                  )}
                  {activeTab === "Facebook Connection" &&
                    facebook?.access === "yes" && (
                      <ClientFacebookConnectionTab
                        clientid={client.Id}
                        facebook={facebook}
                      />
                    )}
                  {activeTab === "AI Setup" && (
                    <ClientAISetupTab clientid={client.Id} />
                  )}
                  {activeTab === "CTA Setup" && (
                    <ClientCTASetupTab clientid={client.Id} />
                  )}
                  {activeTab === "Default Message" && (
                    <ClientDefaultMessageTab clientid={client.Id} />
                  )}
                  {activeTab === "Phone Registration" && (
                    <ClientPhoneRegistrationTab />
                  )}
                  {activeTab === "DMS Feeds" && <ClientDMSFeedsTab />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
