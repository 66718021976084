import React from "react";
import moment from "moment";
import helper from "../../utils/helper";

export default function NoteBox({ item }) {
  return (
    <div className="note-box">
      <span className="note_sender_name">
        <span>{Number(item.Messagetype) === 2 ? item.aname : "Robot"}</span>
      </span>
      <span className="note_time">
        &nbsp;on&nbsp;
        {moment(new Date(item.kstime * 1000)).format("MMMM DD, h:mm A")}
      </span>
      {Number(item.Messagetype) === 2 ? (
        <div
          className="note_text"
          dangerouslySetInnerHTML={{ __html: helper.nl2br(item.Body) }}
        ></div>
      ) : (
        <div className="note_text">
          <p>{item.summary}</p>
          {item.rating !== "" ? <p>{item.rating}</p> : null}
        </div>
      )}
    </div>
  );
}
