import { BsEmojiSmile } from "react-icons/bs";
import EmojiPicker from "emoji-picker-react";
import React, { useState, useRef, useEffect } from "react";

export default function EmojiPickerInput({ onSelect }) {
  const wrapperRef = useRef(null);
  const [openEmojiPicker, setOpenEmojiPicker] = useState(false);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        // alert("You clicked outside of me!");
        setOpenEmojiPicker(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef, setOpenEmojiPicker]);

  const onClick = (emojiData, event) => {
    console.log(">>> emoji >>>", emojiData, event);
    onSelect(emojiData.emoji);
  };

  return (
    <div className="emojipicker" ref={wrapperRef}>
      <BsEmojiSmile
        size={18}
        className="me-1"
        onClick={() => setOpenEmojiPicker(!openEmojiPicker)}
      />
      {openEmojiPicker && (
        <div className="emojipicker-dropdown">
          <EmojiPicker onEmojiClick={onClick} />
        </div>
      )}
    </div>
  );
}
