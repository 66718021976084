import React, { createContext, useContext, useEffect, useState } from "react";
import config from "../config/config";
import { AxiosContext } from "./axios_context";

const AuthContext = createContext(undefined);

const AuthContextProvider = (props) => {
  const { axiosInstance, setLoading } = useContext(AxiosContext);
  const [token, setToken] = useState("");
  const [user, setUser] = useState(null);
  const [userTimeOffset, setUserTimeOffset] = useState(0);

  useEffect(() => {
    setToken(JSON.parse(localStorage.getItem("token")));
    setUser(JSON.parse(localStorage.getItem("user")));
    setUserTimeOffset(new Date().getTimezoneOffset());
  }, [token]);

  const login = (data) => {
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/auth/login`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        const res = data.response;
        if (data.status === "ok") {
          saveToken(res.Info.Key);
          saveUser(res.Info);
        }
        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
      });
  };

  const saveToken = (token) => {
    setToken(token);
    localStorage.setItem("token", JSON.stringify(token));
  };

  const saveUser = (data) => {
    setUser(data);
    var tomorrow = new Date();
    var expires = tomorrow.setDate(tomorrow.getDate() + 10);
    var _user = data;
    _user.expires = expires;
    localStorage.setItem("user", JSON.stringify(_user));
  };

  const getToken = () => {
    return JSON.parse(localStorage.getItem("token"));
  };

  const getUser = () => {
    return JSON.parse(localStorage.getItem("user"));
  };

  const forgotPassword = (data) => {
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/auth/sendforgotkey`,
      data,
    })
      .then((response) => {
        const data = response.data;
        const res = data.response;
        return res;
      })
      .catch((error) => {
        console.log("err: ", error);
      });
  };

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    setToken("");
    setUser(null);
    return !token ? true : false;
  };

  const providerValue = {
    token,
    user,
    userTimeOffset,
    login,
    getToken,
    getUser,
    forgotPassword,
    logout,
  };

  return (
    <AuthContext.Provider value={providerValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthContextProvider };
