import React, { useContext, useState } from "react";
import Modal from "react-modal";
import { AuthContext } from "../../context";
import { useEffect } from "react";
import ContentEditable from "react-contenteditable";
import SmartFieldsInput from "../ChatInputElements/SmartFieldsInput";
import GifPickerInput from "../ChatInputElements/GifPickerInput";
import ScheduleTimeSelect from "../select/schedule_time_select";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "560px",
    padding: "15px",
    marginTop: "20px",
    marginBottom: "20px",
    overflow: "visible",
  },
};

Modal.setAppElement("#root");

export default function FunnelNewMessageModal({
  initialData = undefined,
  isOpenModal = false,
  afterOpenModal,
  closeModal,
  onSubmit,
  hideDelayInput = false,
}) {
  const { user } = useContext(AuthContext);
  const [postData, setPostData] = useState({
    userId: user.Id,
    Name: "",
    Message: "",
    Attached: "",
    Gif: "",
    Delaycategory: "",
    DelaycategoryObj: { value: "", label: "- : -" },
    Delaycount: "",
    Messagetype: 1,
    index: "",
  });
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (initialData) {
      let _postData = { ...postData };
      _postData.Name = initialData?.Name;
      _postData.Message = initialData?.Message;
      _postData.Attached = initialData?.Attached;
      _postData.Gif = initialData?.Gif;
      if (initialData?.Delaycategory) {
        _postData.Delaycategory = initialData?.Delaycategory;
      }
      if (initialData?.DelaycategoryObj) {
        _postData.DelaycategoryObj = initialData?.DelaycategoryObj;
      }
      if (initialData?.Delaycount) {
        _postData.Delaycount = initialData?.Delaycount;
      }
      _postData.Messagetype = initialData?.Messagetype;
      _postData.index = initialData?.index;
      setPostData(_postData);
    }
  }, [initialData]);

  const onSave = () => {
    if (!postData.Name || !postData.Message) {
      setShowError(true);
      return;
    }
    if (!hideDelayInput && (!postData.Delaycategory || !postData.Delaycount)) {
      setShowError(true);
      return;
    }
    let params = { ...postData };
    setPostData({
      userId: user.Id,
      Name: "",
      Message: "",
      Attached: "",
      Gif: "",
      Delaycategory: "",
      DelaycategoryObj: { value: "", label: "- : -" },
      Delaycount: "",
      Messagetype: 1,
      index: "",
    });
    setShowError(false);
    onSubmit(params);
  };

  return (
    <Modal
      isOpen={isOpenModal}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="New Modal"
    >
      <div className="modal-header">
        <h4 className="d-flex align-items-center">
          {initialData ? "New" : "Edit"} Message
        </h4>
      </div>
      <div className="modal-body">
        <div className="row mt-4">
          <div className="col col-sm-12">
            <div className="form-group mb-2">
              <label className="mb-2">Message Name</label>
              <input
                type="text"
                className="form-control"
                name="message-name"
                value={postData.Name}
                onChange={(e) =>
                  setPostData({ ...postData, Name: e.target.value })
                }
              />
              {showError && !postData.Name && (
                <span className="text-danger">Message name is required.</span>
              )}
            </div>
          </div>
        </div>
        {!hideDelayInput && (
          <div className="row">
            <div className="col-12">
              <label className="mb-2">Delay in Days</label>
            </div>
            <div className="col-6">
              <div className="form-group mb-2">
                <input
                  type="number"
                  className="form-control"
                  value={postData.Delaycount}
                  onChange={(e) =>
                    setPostData({ ...postData, Delaycount: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group mb-2">
                <ScheduleTimeSelect
                  value={postData?.DelaycategoryObj}
                  onChange={(e) => {
                    setPostData({
                      ...postData,
                      DelaycategoryObj: e,
                      Delaycategory: e.value,
                    });
                  }}
                />
              </div>
            </div>
            {showError && (!postData.Delaycount || !postData.Delaycategory) && (
              <div className="col-12">
                <span className="text-danger">Please add delay.</span>
              </div>
            )}
          </div>
        )}
        <div className="row">
          <div className="col-12">
            <div className="form-group mb-2">
              <label className="mb-2">Message</label>
              <ContentEditable
                style={{ minHeight: "100px" }}
                className="form-textarea msg-editor"
                tagName="div"
                // placeholder={messageInput ? '' : placeholder}
                html={postData?.Message}
                onChange={(e) =>
                  setPostData({ ...postData, Message: e.target.value })
                }
              />
              <div className="d-flex align-items-center me-auto p-1">
                <GifPickerInput
                  onSelect={(imgEle, giphykey, giphysize) => {
                    console.log(giphysize);
                    setPostData({
                      ...postData,
                      Gif: giphykey,
                      Message: postData.Message + imgEle,
                    });
                  }}
                />
                <SmartFieldsInput
                  onSelect={(field) =>
                    setPostData({
                      ...postData,
                      Message: postData.Message + field,
                    })
                  }
                />
              </div>
              {showError && !postData.Message && (
                <span className="text-danger">Message is required.</span>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer mt-3">
        <button className="btn btn-outline-secondary me-2" onClick={closeModal}>
          Cancel
        </button>
        <button className="btn btn-primary" onClick={() => onSave()}>
          Save
        </button>
      </div>
    </Modal>
  );
}
