import { Switch } from "@mui/material";
import React, { useState, useContext } from "react";
import config from "../../config/config";
import PlFileUploader from "../Uploader/PlFileUploader";
import { ClientContext } from "../../context";

export default function ClientInfoTab({
  isDealerfunnel,
  isagentchain,
  agent,
  dealer,
  state,
  usertype,
  analystics,
  manager,
  executive,
  postData,
  setPostData,
  reloadData,
}) {
  const { updateClientInfoApi } = useContext(ClientContext);
  const [hotLead, setHotLead] = useState(false);
  const [manualMatch, setManualMatch] = useState(false);
  const [privateLabel, setPrivateLabel] = useState(false);

  const onSave = async () => {
    const data = await updateClientInfoApi(postData);
    if (data.status === "ok") {
      reloadData();
    }
  };

  if (!postData) {
    return <></>;
  }

  return (
    <>
      <h6 className="tab-tile">Client Info</h6>
      <div className="row mt-2">
        <div className="col col-sm-6">
          <div className="form-group">
            <label className="mb-2">Name</label>
            <input
              type="text"
              className="form-control w-100"
              value={postData?.Name}
              onChange={(e) =>
                setPostData({ ...postData, Name: e.target.value })
              }
            />
          </div>
        </div>
        <div className="col col-sm-4"></div>
      </div>
      <div className="row mt-3">
        <div className="col col-sm-3">
          <div className="form-group">
            <label className="mb-2">Client Type</label>
            <select
              className="form-select"
              value={postData?.Ctype}
              onChange={(e) =>
                setPostData({ ...postData, Ctype: e.target.value })
              }
            >
              <option value="1">Agency</option>
              <option value="2">Dealer Group</option>
              <option value="3">Dealership</option>
              <option value="4">Agency Group</option>
            </select>
          </div>
        </div>
        {Number(isDealerfunnel) === 0 && (
          <>
            <div className="col-sm-3">
              <div
                className={
                  Number(postData?.Ctype) === 1 ? "d-none" : "form-group"
                }
              >
                <label className="mb-2">Agency</label>
                {Number(isagentchain) === 1 ? (
                  <select
                    className="form-select"
                    name={"Agency1"}
                    disabled={Number(isagentchain) === 1}
                    value={postData?.Agency1}
                    onChange={(e) =>
                      setPostData({ ...postData, Agency1: e.target.value })
                    }
                  >
                    <option value="">Select Agency</option>
                    {!!agent &&
                      agent.map((item, index) => (
                        <option value={item?.Id} key={index}>
                          {item?.Name}
                        </option>
                      ))}
                  </select>
                ) : (
                  <select
                    className="form-select"
                    name={"Agency"}
                    disabled={Number(isagentchain) === 1}
                    value={postData?.Agency}
                    onChange={(e) =>
                      setPostData({ ...postData, Agency: e.target.value })
                    }
                  >
                    <option value="">Select Agency</option>
                    {!!agent &&
                      agent.map((item, index) => (
                        <option value={item?.Id} key={index}>
                          {item?.Name}
                        </option>
                      ))}
                  </select>
                )}
              </div>
            </div>
            <div className="col-sm-3">
              <div
                className={
                  Number(postData?.Ctype) === 1 || Number(postData?.Ctype) === 2
                    ? "d-none"
                    : "form-group"
                }
              >
                <label className="mb-2">Dealer Group</label>
                <select
                  className="form-select"
                  name={Number(isagentchain) === 1 ? "Agency1" : "Agency"}
                  disabled={Number(isagentchain) === 1}
                  value={postData?.Group}
                  onChange={(e) =>
                    setPostData({ ...postData, Group: e.target.value })
                  }
                >
                  <option value="">Select Group</option>
                  {!!dealer &&
                    dealer.map((item, index) => (
                      <option value={item?.Id} key={index}>
                        {item?.Name}
                      </option>
                    ))}
                </select>
              </div>
            </div>
          </>
        )}
      </div>
      <div className="row mt-3">
        <div className="col col-sm-3">
          <div className="form-group">
            {postData?.Logo && (
              <div className="logo-img w-100">
                <img
                  src={`${config.domain}/${postData?.Logo}`}
                  width="80"
                  alt=""
                />
              </div>
            )}
            <label className="w-100 mb-2">Dealership Logo</label>
            <PlFileUploader
              url={`${config.ajaxPath}/imageupload/1`}
              browseButtonId={`Logo_logo`}
              extensions={"jpg,gif,png"}
              onSuccess={(up, file, response) =>
                setPostData({ ...postData, Logo: response.filename })
              }
              onError={() => {}}
            />
          </div>
        </div>
        <div className="col col-sm-3">
          <div className="form-group">
            {postData?.Mlogo && (
              <div className="logo-img w-100">
                <img
                  src={`${config.domain}/${postData?.Mlogo}`}
                  width="80"
                  alt=""
                />
              </div>
            )}
            <label className="w-100 mb-2">Manufacturer Logo</label>
            <PlFileUploader
              url={`${config.ajaxPath}/imageupload/1`}
              browseButtonId={`manufacturer_logo`}
              extensions={"jpg,gif,png"}
              onSuccess={(up, file, response) =>
                setPostData({ ...postData, Mlogo: response.filename })
              }
              onError={() => {}}
            />
          </div>
        </div>
        <div className="col col-sm-3">
          <div className="form-group">
            <label className="w-100 mb-2">Primary Color</label>
            <div className="colorpicker">
              <input
                type="color"
                className="me-1"
                value={postData?.color}
                onChange={(e) =>
                  setPostData({ ...postData, color: e.target.value })
                }
              />
              {postData?.color}
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col col-sm-6">
          <div className="form-group">
            <label className="mb-2">Dealership Contact</label>
            <input
              type="text"
              className="form-control w-100"
              value={postData?.DealershipContact}
              onChange={(e) =>
                setPostData({ ...postData, DealershipContact: e.target.value })
              }
            />
          </div>
        </div>
        <div className="col col-sm-6">
          <div className="form-group">
            <label className="mb-2">Dealership Website</label>
            <input
              type="text"
              className="form-control w-100"
              value={postData?.DealershipWebsite}
              onChange={(e) =>
                setPostData({ ...postData, DealershipWebsite: e.target.value })
              }
            />
          </div>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col col-sm-4">
          <div className="form-group">
            <label className="mb-2">Dealership Phone</label>
            <input
              type="text"
              className="form-control w-100"
              value={postData?.Phone}
              onChange={(e) =>
                setPostData({ ...postData, Phone: e.target.value })
              }
            />
          </div>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col col-sm-6">
          <div className="form-group">
            <label className="mb-2">Inventory URL</label>
            <input
              type="text"
              className="form-control w-100"
              value={postData?.ClientInventoryURL}
              onChange={(e) =>
                setPostData({ ...postData, ClientInventoryURL: e.target.value })
              }
            />
          </div>
        </div>
        <div className="col col-sm-6">
          <div className="form-group">
            <label className="mb-2">Credit App URL</label>
            <input
              type="text"
              className="form-control w-100"
              value={postData?.CreditAppURL}
              onChange={(e) =>
                setPostData({ ...postData, CreditAppURL: e.target.value })
              }
            />
          </div>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col col-sm-6">
          <div className="form-group">
            <label className="mb-2">Notes for Agents</label>
            <textarea
              className="form-textarea w-100"
              rows={5}
              value={postData?.Notes}
              onChange={(e) =>
                setPostData({ ...postData, Notes: e.target.value })
              }
            ></textarea>
          </div>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col col-sm-6">
          <div className="form-group">
            <label className="mb-2">Hot Leads</label>
            <div className="w-100">
              <Switch
                value={hotLead}
                onChange={(e) => setHotLead(e.target.checked)}
              />
            </div>
          </div>
        </div>
      </div>
      {hotLead && (
        <div className="row mt-2">
          <div className="col col-sm-6">
            <div className="form-group">
              <label className="mb-2">Email</label>
              <div className="w-100">
                <input
                  className="form-control"
                  type="text"
                  value={postData?.Hotemail}
                  onChange={(e) =>
                    setPostData({ ...postData, Hotemail: e.target.value })
                  }
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <hr />
      <h6 className="tab-tile">Location</h6>
      <div className="row mt-2">
        <div className="col-12">
          <div className="form-group">
            <label className="mb-2">Address</label>
            <input
              type="text"
              className="form-control w-100"
              value={postData?.Address}
              onChange={(e) =>
                setPostData({ ...postData, Address: e.target.value })
              }
            />
          </div>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col col-sm-2">
          <div className="form-group">
            <label className="mb-2">City</label>
            <input
              type="text"
              className="form-control"
              value={postData?.City}
              onChange={(e) =>
                setPostData({ ...postData, City: e.target.value })
              }
            />
          </div>
        </div>
        <div className="col col-sm-2">
          <div className="form-group">
            <label className="mb-2">State</label>
            <select
              className="form-select"
              value={postData?.State}
              onChange={(e) =>
                setPostData({ ...postData, State: e.target.value })
              }
            >
              {state &&
                state.map((item, index) => (
                  <option value={item} key={index}>
                    {item}
                  </option>
                ))}
            </select>
          </div>
        </div>
        <div className="col col-sm-2">
          <div className="form-group">
            <label className="mb-2">Zip</label>
            <input
              type="text"
              className="form-control"
              value={postData?.Zip}
              onChange={(e) =>
                setPostData({ ...postData, Zip: e.target.value })
              }
            />
          </div>
        </div>
      </div>
      <hr />
      {Number(isDealerfunnel) === 0 && (
        <>
          <h6 className="tab-tile">Internal</h6>
          <div className="row mt-2">
            {Number(usertype) < 2 && (
              <div className="col-sm-3">
                <div className="form-group">
                  <label className="mb-2">Google Analytics ID</label>
                  <select
                    className="form-select"
                    value={postData?.Viewid}
                    onChange={(e) =>
                      setPostData({ ...postData, Viewid: e.target.value })
                    }
                  >
                    <option value="">Select</option>
                    {analystics &&
                      analystics.map((item, index) => (
                        <option
                          value={item.Id}
                          key={index}
                        >{`${item.Name}- ${item.Id}`}</option>
                      ))}
                  </select>
                </div>
              </div>
            )}
            <div className="col-sm-3">
              <div className="form-group">
                <label className="mb-2">DMS Feed ID</label>
                <input
                  value={postData?.Fcid}
                  type="text"
                  name="Fcid"
                  disabled={true}
                  onChange={(e) =>
                    setPostData({ ...postData, Fcid: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="col-sm-3">
              <div className="form-group">
                <label className="mb-2">ROI Sales Date Field</label>
                <select
                  name="sales_field"
                  disabled={true}
                  value={postData?.sales_field}
                  onChange={(e) =>
                    setPostData({ ...postData, sales_field: e.target.value })
                  }
                >
                  <option value="DealBookDate">Deal Book Date</option>
                  <option value="EntryDate">Entry Date</option>
                </select>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="form-group">
                <label className="mb-2">Manual Match</label>
                <div className="w-100">
                  <Switch
                    value={manualMatch}
                    onChange={(e) => setManualMatch(e.target.checked)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-sm-3">
              <div className="form-group">
                <label className="mb-2">Account Manager</label>
                <select
                  required
                  className="form-select"
                  value={postData?.AccountManager}
                  onChange={(e) =>
                    setPostData({ ...postData, AccountManager: e.target.value })
                  }
                >
                  <option value="">Please select</option>
                  {manager &&
                    manager.map((item, index) => (
                      <option value={item.Id} key={index}>
                        {item.Name}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="form-group">
                <label className="mb-2">Account Executive</label>
                <select
                  required
                  className="form-select"
                  value={postData?.AccountExecutive}
                  onChange={(e) =>
                    setPostData({
                      ...postData,
                      AccountExecutive: e.target.value,
                    })
                  }
                >
                  <option value="">Please select</option>
                  {executive &&
                    executive.map((item, index) => (
                      <option value={item.Id} key={index}>
                        {item.Name}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="form-group">
                <label className="mb-2">Area Code</label>
                <input
                  type="text"
                  maxLength="3"
                  value={postData?.AreaCode}
                  onChange={(e) =>
                    setPostData({ ...postData, AreaCode: e.target.value })
                  }
                />
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-sm-3">
              <div className="form-group">
                <label className="mb-2">Account Manager 2</label>
                <select
                  name="AccountManager2"
                  className="field-block"
                  value={postData?.AccountManager2}
                  onChange={(e) =>
                    setPostData({
                      ...postData,
                      AccountManager2: e.target.value,
                    })
                  }
                >
                  <option value="">Please select</option>
                  {manager &&
                    manager.map((item, index) => (
                      <option value={item.Id} key={index}>
                        {item.Name}
                      </option>
                    ))}
                </select>
              </div>
            </div>
          </div>
          {Number(usertype) !== 5 && (
            <>
              <div className="row mt-2">
                <div className="col-sm-12">
                  <div className="form-group">
                    <label className="mb-2">Private Label</label>
                    <div className="w-100">
                      <Switch
                        value={privateLabel}
                        onChange={(e) => setPrivateLabel(e.target.checked)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {privateLabel && (
                <>
                  <div className="row mt-2">
                    <div className="col-sm-3">
                      <div className="form-group">
                        <label className="mb-2 w-100">Logo</label>
                        <PlFileUploader
                          url={`${config.ajaxPath}/imageupload/1`}
                          browseButtonId={`p_logo`}
                          extensions={"jpg,gif,png"}
                          onSuccess={(up, file, response) =>
                            setPostData({
                              ...postData,
                              pllogo: response.filename,
                            })
                          }
                          onError={() => {}}
                        />
                        {postData?.pllogo && (
                          <div className="logo-img w-100">
                            <img
                              src={`${config.domain}/${postData?.pllogo}`}
                              width="80"
                              alt=""
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <label className="mb-2 w-100">Fav Icon</label>
                        <PlFileUploader
                          url={`${config.ajaxPath}/imageupload/1`}
                          browseButtonId={`pfavicon`}
                          extensions={"jpg,gif,png"}
                          onSuccess={(up, file, response) =>
                            setPostData({
                              ...postData,
                              pfavicon: response.filename,
                            })
                          }
                          onError={() => {}}
                        />
                        {postData?.pfavicon && (
                          <div className="logo-img w-100">
                            <img
                              src={`${config.domain}/${postData?.pfavicon}`}
                              width="80"
                              alt=""
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <label className="mb-2 w-100">Color</label>
                        <div className="colorpicker">
                          <input
                            type="color"
                            value={postData?.pcolor}
                            onChange={(e) =>
                              setPostData({
                                ...postData,
                                pcolor: e.target.value,
                              })
                            }
                          />
                          {postData?.pcolor}
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <label className="mb-2 w-100">Dark Mode Logo</label>
                        <PlFileUploader
                          url={`${config.ajaxPath}/imageupload/1`}
                          browseButtonId={`dark_logo`}
                          extensions={"jpg,gif,png"}
                          onSuccess={(up, file, response) =>
                            setPostData({
                              ...postData,
                              darklogo: response.filename,
                            })
                          }
                          onError={() => {}}
                        />
                        {!postData?.pfavicon && postData?.darklogo && (
                          <div className="logo-img w-100">
                            <img
                              src={`${config.domain}/${postData?.darklogo}`}
                              width="80"
                              alt=""
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-sm-3">
                      <div className="form-group">
                        <label className="mb-2 w-100">Background Image</label>
                        <a id="pbackgroundimage">upload</a>
                        <PlFileUploader
                          url={`${config.ajaxPath}/imageupload/1`}
                          browseButtonId={`pbackgroundimage`}
                          extensions={"jpg,gif,png"}
                          onSuccess={(up, file, response) =>
                            setPostData({
                              ...postData,
                              pbackgroundimage: response.filename,
                            })
                          }
                          onError={() => {}}
                        />
                        {postData?.pbackgroundimage && (
                          <div className="logo-img w-100">
                            <img
                              src={`${config.domain}/${postData?.pbackgroundimage}`}
                              width="80"
                              alt=""
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <label className="mb-2 w-100">Background Color</label>
                        <div className="colorpicker">
                          <input
                            type="color"
                            className="me-1"
                            value={postData?.pbackgroundcolor}
                            onChange={(e) =>
                              setPostData({
                                ...postData,
                                pbackgroundcolor: e.target.value,
                              })
                            }
                          />
                          {postData?.pbackgroundcolor}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-sm-4">
                      <div className="form-row">
                        <label className="mb-2 w-100">Legal Name</label>
                        <input
                          value={postData?.pcopyright}
                          name="pcopyright"
                          type="text"
                          onChange={(e) =>
                            setPostData({
                              ...postData,
                              pcopyright: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-row">
                        <label className="mb-2 w-100">ADF Provider</label>
                        <input
                          value={postData?.padf}
                          name="padf"
                          type="text"
                          onChange={(e) =>
                            setPostData({ ...postData, padf: e.target.value })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-row">
                        <label className="mb-2 w-100">Send From Email</label>
                        <input
                          value={postData?.pemail}
                          name="pemail"
                          type="text"
                          onChange={(e) =>
                            setPostData({ ...postData, pemail: e.target.value })
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-sm-4">
                      <div className="form-row">
                        <label>Login Domain</label>
                        <input
                          value={postData?.pdomain}
                          type="text"
                          name="pdomain"
                          onChange={(e) =>
                            setPostData({
                              ...postData,
                              pdomain: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-row">
                        <label>Terms & Conditions URL</label>
                        <input
                          value={postData?.pterms}
                          type="text"
                          name="pterms"
                          onChange={(e) =>
                            setPostData({ ...postData, pterms: e.target.value })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-row">
                        <label>Privacy Policy URL</label>
                        <input
                          value={postData?.pprivacy}
                          type="text"
                          name="pprivacy"
                          onChange={(e) =>
                            setPostData({
                              ...postData,
                              pprivacy: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </>
      )}
      <hr />
      <div className="row">
        <div className="col-12">
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => onSave()}
          >
            Save
          </button>
          <button type="button" className="btn btn-outline-secondary ms-1">
            Cancel
          </button>
        </div>
      </div>
    </>
  );
}
