import React, { useContext, useEffect } from "react";
import { Box } from "@mui/material";
import {
  AuthContext,
  ChatContext,
  ConversationContext,
} from "../../../context";
import moment from "moment";
import {
  ConversationsInputAndDateFilter,
  ConversationsInboxFilter,
  ConversationsMainFilter,
} from "../../../components";

export default function ConversationsFilters(props) {
  const { handleSelect } = props;
  const { user, userTimeOffset } = useContext(AuthContext);
  const {
    loadfrom,
    ajaxInfo,
    inboxAnalysis,
    ajaxFilter,
    resetFilterApi,
    getConversationPreDataApi,
    saveFilterApi,
    sClient,
    clients,
    assignusers,
    usertags,
    campaigns,
    leadsources,
    saveFilterParams,
    filterParams,
    setFilterParams,
    filterOption,
    setFilterOption,
    conversationsLoading,
    setConversationsLoading,
  } = useContext(ConversationContext);
  const { setActivatedLeadId } = useContext(ChatContext);

  useEffect(() => {
    getDefaultInfo();
  }, []);

  const getDefaultInfo = async () => {
    setConversationsLoading(true);
    await getConversationPreDataApi({ userId: user.Id });
  };

  useEffect(() => {
    if (saveFilterParams) {
      rememberFilterParams();
    }
  }, [saveFilterParams]);

  const rememberFilterParams = async () => {
    if (saveFilterParams.Sfilter) {
      setFilterParams({ ...filterParams, sfilter: saveFilterParams.Sfilter });
    }
    if (saveFilterParams.client.length > 0 && clients) {
      setFilterParams({
        ...filterParams,
        clientArr: clients.filter((el) =>
          saveFilterParams.client.includes(el.Id),
        ),
        client: saveFilterParams.client.toString(),
      });
    }
    if (saveFilterParams.Campaign.length > 0 && campaigns) {
      setFilterParams({
        ...filterParams,
        campaignArr: campaigns.filter((el) =>
          saveFilterParams.Campaign.includes(el.Id),
        ),
        campaign: saveFilterParams.Campaign.toString(),
      });
    }
    if (saveFilterParams.Label.length > 0 && usertags) {
      setFilterParams({
        ...filterParams,
        tagArr: usertags.filter((el) => saveFilterParams.Label.includes(el.Id)),
        tag: saveFilterParams.Label.toString(),
      });
    }
    if (saveFilterParams.Assignment.length > 0 && assignusers) {
      setFilterParams({
        ...filterParams,
        assigneduserArr: assignusers.filter((el) =>
          saveFilterParams.Assignment.includes(el.Id),
        ),
        assigneduser: saveFilterParams.Assignment.toString(),
      });
    }
    if (saveFilterParams.Leadsource.length > 0 && leadsources) {
      setFilterParams({
        ...filterParams,
        leadsourceArr: leadsources.filter((el) =>
          saveFilterParams.Leadsource.includes(el.Id),
        ),
        leadsource: saveFilterParams.Leadsource.toString(),
      });
    }
    let fparams = getParam(saveFilterParams.Sfilter);
    fparams.sfilter = saveFilterParams.Sfilter;
    fparams.client = saveFilterParams.client.toString();
    fparams.campaign = saveFilterParams.Campaign.toString();
    fparams.tag = saveFilterParams.Label.toString();
    fparams.assigneduser = saveFilterParams.Assignment.toString();
    fparams.leadsource = saveFilterParams.Leadsource.toString();
    fparams.sclient = sClient;
    setConversationsLoading(true);
    await ajaxFilter(fparams);
    setConversationsLoading(false);
  };

  const getParam = (label = null) => {
    let fparams = filterParams;
    fparams.iswaiting = label === "L0" ? 1 : 0;
    fparams.label = 0;
    if (label === "L1") {
      fparams.label = 1;
    }
    if (label === "L2") {
      fparams.label = 2;
    }
    if (label === "L3") {
      fparams.label = 3;
    }
    if (label === "L4") {
      fparams.label = 4;
    }
    if (label === "L5") {
      fparams.label = 5;
    }
    if (label === "L6") {
      fparams.label = 6;
    }
    if (label === "L12") {
      fparams.newleadsourcelead = 1;
    }
    fparams.isDone = label === "D" ? 1 : 0;
    fparams.isApp = label === "A" ? 1 : 0;
    fparams.isApps = label === "As" ? 1 : 0;
    fparams.isSchedule = label === "Sch" ? 1 : 0;
    fparams.loadfrom = loadfrom;
    fparams.sclient = sClient;

    return fparams;
  };

  const cleanFilter = () => {
    setFilterOption({
      ...filterOption,
      filter_label: "L8",
      filter_client: "0",
      filter_cmp: 0,
      filter_response: 0,
      filter_tag: 0,
      filter_assign: 0,
      filter_lead: 0,
      filter_iswaiting: 0,
    });
  };

  const handleCallback = async (start, end) => {
    const startDate = moment(start).format("YYYY-MM-DD");
    const endDate = moment(end).format("YYYY-MM-DD");
    setFilterParams({
      ...filterParams,
      startdate: moment(startDate).format("YYYY-MM-DD"),
    });
    setFilterParams({
      ...filterParams,
      enddate: moment(endDate).format("YYYY-MM-DD"),
    });
    setFilterParams({
      ...filterParams,
      dateLabel: `${moment(start).format("MM/DD/YYYY")} - ${moment(end).format(
        "MM/DD/YYYY",
      )}`,
    });
    //
    let fparams = getParam();
    fparams.startdate = startDate;
    fparams.enddate = endDate;
    setConversationsLoading(true);
    await ajaxFilter(fparams);
    setConversationsLoading(false);
  };

  const handleInboxClick = async (item) => {
    console.log("🔎🔎🔎: handleInboxClick", item);
    cleanFilter();
    const label = item.filterParams[0];
    const value = item.filterParams[1];
    console.log(value);
    setFilterOption({
      ...filterOption,
      filter_label: label,
      sfilter: label,
      filter_iswaiting: label === "L0" ? 1 : 0,
    });
    setFilterParams({
      ...filterParams,
      sfilter: label,
    });
    let fparams = getParam(label);
    fparams.userId = user.Id;
    fparams.isanalysis = 0;
    fparams.isDone = label === "D" ? 1 : fparams.isDone;
    setConversationsLoading(true);
    await ajaxFilter(fparams);
    setConversationsLoading(false);
  };

  const handleMainFilterClick = async (item) => {
    console.log(item);
    if (item) {
      if (item.saveFilter) {
        // save filter
        let _saveFilterParams = {
          userId: user.Id,
          Sfilter: filterParams.sfilter,
          Daterange: 1,
          Tags: filterParams.tag,
          Assignment: filterParams.assigneduser,
          Client: filterParams.client,
          Campaign: filterParams.campaign,
          Leadsource: filterParams.leadsource,
          Flag: 1,
        };
        const res = await saveFilterApi(_saveFilterParams);
        console.log("📣📣📣: save filter: ", res);
      } else {
        // conversations api
        setFilterParams({
          ...filterParams,
          clientArr: item.clientArr,
          client: item.clientArr,
          campaignArr: item.campaignArr,
          campaign: item.campaignArr,
          tagArr: item.tagArr,
          tag: item.tagArr,
          assigneduserArr: item.assigneduserArr,
          assigneduser: item.assigneduserArr,
          leadsourceArr: item.leadsourceArr,
          leadsource: item.leadsourceArr,
        });
        setFilterOption({
          ...filterOption,
          filter_client: item.clientArr,
          filtertype: 1,
          filter_cmp: item.campaignArr,
          filter_tag: item.tagArr,
          filter_assign: item.assigneduserArr,
          filter_leadsource: item.leadsourceArr,
        });
        let fparams = getParam();
        fparams.client = item.clientArr;
        fparams.campaign = item.campaignArr;
        fparams.tag = item.tagArr;
        fparams.assigneduser = item.assigneduserArr;
        fparams.leadsource = item.leadsourceArr;
        console.log(fparams);
        setConversationsLoading(true);
        await ajaxFilter(fparams);
        setConversationsLoading(false);
      }
    } else {
      // reset filter
      const params = {
        userId: user.Id,
        startdate: moment().subtract(30, "days").format("YYYY-MM-DD"),
        enddate: moment().format("YYYY-MM-DD"),
        datelabel: "Last 30 Days",
        sfilter: "L8",
        assigneduserArr: [], //
        tagArr: [],
        clientArr: [],
        leadsourceArr: [],
        campaignArr: [],
        assigneduser: "", //
        tag: "",
        sclient: "",
        client: "",
        leadsource: "",
        campaign: "", //
        isnew: 0,
        fromcampaign: 1,
        isanalysis: 1,
        ismultipleleadsend: 0,
        newleadsourcelead: 0,
        iswaiting: 0,
        label: 0, // complex
        isDone: 0,
        isApp: 0,
        isApps: 0,
        isSchedule: 0,
        isMove: 0,
        loadfrom: "",
        timeOffset: userTimeOffset,
      };
      setFilterParams({ ...params });
      setConversationsLoading(true);
      const res = await resetFilterApi({ userId: user.Id });
      console.log("📣📣📣: reset filter: ", res);
      await ajaxFilter(params);
      setConversationsLoading(false);
    }
  };

  const renderMain = () => {
    return (
      <Box>
        <ConversationsInputAndDateFilter
          filterParams={filterParams}
          setFilterParams={setFilterParams}
          handleCallback={handleCallback}
          handleSelect={handleSelect}
        />
        <Box sx={{ height: 10 }}></Box>
        <ConversationsInboxFilter
          ajaxInfo={ajaxInfo}
          inboxAnalysis={inboxAnalysis}
          loading={conversationsLoading}
          handleClick={handleInboxClick}
        />
        <Box sx={{ height: 10 }}></Box>
        <ConversationsMainFilter
          filterParams={filterParams}
          loading={conversationsLoading}
          handleCallBack={handleMainFilterClick}
        />
      </Box>
    );
  };

  return (
    <Box
      sx={{
        height: "calc(100vh - 150px)",
        overflowY: "scroll",
      }}
    >
      {renderMain()}
    </Box>
  );
}
