import Modal from "react-modal";
import React, { useContext, useState } from "react";
import { AuthContext, ConversationContext } from "../../context";
import { toast } from "react-toastify";
import AccessAlarmOutlinedIcon from "@mui/icons-material/AccessAlarmOutlined";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "560px",
    padding: "15px",
    marginTop: "20px",
    marginBottom: "20px",
  },
};

Modal.setAppElement("#root");

export default function ConversationSetReminderModal({
  isOpenModal = false,
  afterOpenModal,
  closeModal,
  leadId,
}) {
  const { user } = useContext(AuthContext);
  const { setReminderApi } = useContext(ConversationContext);
  const [postData, setPostData] = useState({
    userId: user.Id,
    date: "",
    time: "",
    rnote: "",
    leadid: leadId,
  });
  const [validationCheck, setValidationCheck] = useState(false);

  const onSave = async () => {
    if (!leadId) {
      return;
    }
    setPostData({ ...postData, leadid: leadId });
    let _postData = postData;
    _postData.userId = user.Id;
    _postData.leadid = leadId;
    if (!_postData.date || !_postData.time || !_postData.rnote) {
      setValidationCheck(true);
      return;
    }
    setValidationCheck(false);
    const data = await setReminderApi(_postData);
    if (data.status === "ok") {
      toast.info("Reminder saved successfully!");
      closeModal();
    }
  };

  return (
    <Modal
      isOpen={isOpenModal}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Set Reminder"
    >
      <div className="modal-header">
        <h4 className="d-flex align-items-center">
          <AccessAlarmOutlinedIcon className="me-1" />
          Set Reminder
        </h4>
      </div>
      <div className="modal-body">
        <form>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6">
              <div className="form-group mt-3">
                <label className="mb-1">Select Date</label>
                <input
                  type="date"
                  className="form-control form-control-fav"
                  value={postData?.date}
                  onChange={(e) =>
                    setPostData({ ...postData, date: e.target.value })
                  }
                />
                {validationCheck && !postData.date && (
                  <small className="text-danger">This field is required</small>
                )}
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6">
              <div className="form-group mt-3">
                <label className="mb-1">Select Time</label>
                <input
                  type="time"
                  className="form-control form-control-fav"
                  value={postData?.time}
                  onChange={(e) =>
                    setPostData({ ...postData, time: e.target.value })
                  }
                />
                {validationCheck && !postData.time && (
                  <small className="text-danger">This field is required</small>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="form-group mt-3">
                <label className="mb-1">Note</label>
                <textarea
                  className="form-textarea w-100"
                  rows={6}
                  value={postData?.rnote}
                  onChange={(e) =>
                    setPostData({ ...postData, rnote: e.target.value })
                  }
                ></textarea>
                {validationCheck && !postData.rnote && (
                  <small className="text-danger">This field is required</small>
                )}
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="modal-footer mt-3">
        <button className="btn btn-outline-secondary" onClick={closeModal}>
          Cancel
        </button>
        <button className="btn btn-primary ms-2" onClick={() => onSave()}>
          Save
        </button>
      </div>
    </Modal>
  );
}
