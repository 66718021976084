import React, { useState } from "react";
import { Box, MenuItem, Popover, Stack, Typography } from "@mui/material";
import FlashOnOutlinedIcon from "@mui/icons-material/FlashOnOutlined";
import { Constants } from "../../config";

export default function ChatSmartFieldsInput(props) {
  const { color = "inherit", handleChange } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "chat-smart-fields-input-popover" : undefined;
  const [activeLItem, setActiveLItem] = useState("");
  const [activeRItem, setActiveRItem] = useState("");
  const [lItems, setLItems] = useState([]);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMouseEnter = (item, index) => {
    if (index === 1) {
      setActiveLItem(item.name);
      setLItems(item.items);
    } else {
      setActiveRItem(item.name);
    }
  };

  const handleSelect = (item) => {
    handleChange(item.value);
    handleClose();
  };

  return (
    <Box>
      <FlashOnOutlinedIcon
        size={18}
        title="Smart Fields"
        onClick={openMenu}
        style={{
          color: color,
          cursor: "pointer",
        }}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box sx={{ p: 1 }}>
          <Stack direction={"row"}>
            <Box>
              {Constants.smartList.map((item, index) => {
                const isActive = item.name === activeLItem;
                return (
                  <MenuItem
                    key={index}
                    sx={{ m: 0 }}
                    onMouseEnter={() => handleMouseEnter(item, 1)}
                  >
                    <Typography
                      sx={{
                        m: 0,
                        fontSize: 14,
                        fontWeight: isActive ? "700" : "300",
                      }}
                    >
                      {item.name}
                    </Typography>
                  </MenuItem>
                );
              })}
            </Box>
            <Box>
              {lItems.map((item, index) => {
                const isActive = item.name === activeRItem;
                return (
                  <MenuItem
                    key={index}
                    sx={{ m: 0 }}
                    onMouseEnter={() => handleMouseEnter(item, 2)}
                    onClick={() => handleSelect(item)}
                  >
                    <Typography
                      sx={{
                        m: 0,
                        fontSize: 14,
                        fontWeight: isActive ? "700" : "300",
                      }}
                    >
                      {item.name}
                    </Typography>
                  </MenuItem>
                );
              })}
            </Box>
          </Stack>
        </Box>
      </Popover>
    </Box>
  );
}
