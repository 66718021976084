import EmojiPicker from "emoji-picker-react";
import React, { useState } from "react";
import { Box, Popover } from "@mui/material";
import TextsmsOutlinedIcon from "@mui/icons-material/TextsmsOutlined";

export default function ChatEmojiPickerInput(props) {
  const { color = "inherit", handleChange } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "chat-emoji-picker-input-popover" : undefined;

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClick = (emojiData, event) => {
    console.log(event);
    handleChange(emojiData.emoji);
  };

  return (
    <Box>
      <TextsmsOutlinedIcon
        size={18}
        sx={{
          color: color,
          cursor: "pointer",
        }}
        onClick={openMenu}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <EmojiPicker onEmojiClick={onClick} />
      </Popover>
    </Box>
  );
}
