import Modal from "react-modal";
import ScheduleTimeSelect from "../select/schedule_time_select";
import React, { useState } from "react";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "560px",
    padding: "15px",
    marginTop: "20px",
    marginBottom: "20px",
  },
};

Modal.setAppElement("#root");

export default function ConversationScheduleMessageModal({
  isOpenModal = false,
  afterOpenModal,
  closeModal,
  onSchedule,
}) {
  const [postData, setPostData] = useState({
    date: new Date(),
    time: "",
    timeObj: { value: "", label: "- : -" },
  });

  const scheduleMessage = () => {
    onSchedule(postData);
  };

  return (
    <Modal
      isOpen={isOpenModal}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Schedule Message"
    >
      <div className="modal-header">
        <h4>Schedule Message</h4>
      </div>
      <div className="modal-body">
        <form>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 mt-3">
              <p>
                Your message will be sent according to US/Eastern Time Zone.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 mt-3">
              <input type="date" className="form-control" />
            </div>
            <div className="col-12 col-sm-12 col-md-6 mt-3">
              <ScheduleTimeSelect
                value={postData?.timeObj}
                onChange={(e) => {
                  setPostData({
                    ...postData,
                    timeObj: e,
                    time: e.value,
                  });
                }}
              />
            </div>
          </div>
        </form>
      </div>
      <div className="modal-footer mt-3">
        <button className="btn btn-outline-secondary me-2" onClick={closeModal}>
          Cancel
        </button>
        <button className="btn btn-primary" onClick={() => scheduleMessage()}>
          Schedule Messgae
        </button>
      </div>
    </Modal>
  );
}
