import { GrFormPrevious, GrFormNext } from "react-icons/gr";
import React from "react";

export default function APITablePagination({ pagination = {}, onChangePage }) {
  return (
    <div className="table-pagination">
      <div className="per-page">
        Page {pagination?.cpage || 0} of {pagination?.tpage || 0} |{" "}
        {pagination?.appointmentcount || 0}
      </div>
      {pagination && (
        <div className="pagination">
          <button
            className={`btn-page ${pagination?.cpage === 1 ? "disabled" : ""}`}
            onClick={() => {
              if (pagination?.cpage > 1) onChangePage(pagination?.cpage - 1);
            }}
          >
            <GrFormPrevious />
          </button>
          {pagination?.cpage > 2 ? (
            <button className={`btn-page`}>...</button>
          ) : null}
          {[...Array(pagination.tpage)].map((e, i) =>
            i + 1 > pagination?.cpage - 2 && i + 1 < pagination?.cpage + 2 ? (
              <button
                className={`btn-page ${
                  pagination?.cpage === i + 1 ? "active" : ""
                }`}
                key={i}
                onClick={() => onChangePage(i + 1)}
              >
                {i + 1}
              </button>
            ) : null,
          )}
          {pagination?.cpage < pagination?.tpage - 1 ? (
            <button className={`btn-page`}>...</button>
          ) : null}
          <button
            className={`btn-page ${
              pagination?.cpage === pagination?.tpage ? "disabled" : ""
            }`}
            onClick={() => {
              if (pagination?.cpage < pagination?.tpage)
                onChangePage(pagination?.cpage + 1);
            }}
          >
            <GrFormNext />
          </button>
        </div>
      )}
    </div>
  );
}
