const moment = require("moment");

function getLeadData(data, timeOffset) {
  const newLead = {
    ...data,
    Assigneduser: data?.assignment,
    Assignusername: data?.assignedusername,
    CampaignId: data?.campaign_id,
    Email: "",
    Isapp: data?.isapps,
    Isawaiting: data?.waiting,
    Ismediaimage: "",
    Isschedule: data?.isschedule,
    Label: data?.label,
    LastResponseId: data?.response_id,
    LastResponse_id: data?.response_id,
    Lastsms: "",
    Lead_avatar: data?.leadpic,
    Mediaimage: "",
    Mediapath: "",
    Messanger: "",
    Notes: data?.notes,
    campaign_key: data?.campaign_key,
    client: data?.cmptitle,
    client_id: data?.client_id,
    cmp: data?.cmptitle,
    direction: data?.direction,
    fr: data?.fr,
    isSchedule: data?.isschedule,
    isnew: data?.isnew,
    isnewsms: data?.isnewsms,
    istyping: data?.istyping,
    leadId: data?.lead_id,
    lead_barcode: data?.lead_pin,
    leadresponse: moment(data?.createtime * 1000)
      .utcOffset(-timeOffset)
      .format("YYYY-MM-DD HH:mm:ss"),
    leadsource_id: data?.leadsourceid,
    mdate: moment(data?.createtime * 1000)
      .utcOffset(-timeOffset)
      .format("hh:mm A"),
    message: data?.mbody,
    messagetype: data?.messagetype,
    optedout: data?.optout,
    pname: data?.leadname,
    stime: data?.createtime,
    type: data?.cmptype,
  };
  return newLead;
}

function isNotClientAuthorized(clientId, currentUser, sClient) {
  let result = true;
  if (!currentUser || Number(currentUser.Type) <= 1) {
    // console.log("isNotClientAuthorized currenty user type");
    result = false;
  }
  if (sClient?.includes(clientId)) {
    // console.log("isNotClientAuthorized sClient includes");
    result = false;
  }
  return result;
}

function isNotAuthorized(campaignId, clientId, sClient, cmpCSV) {
  let result = true;
  const cmpId = Number(campaignId);
  if (cmpId == 0) {
    if (sClient?.includes(clientId.toString())) {
      result = false;
    }
  } else {
    if (cmpCSV?.includes(cmpId.toString())) {
      result = false;
    }
  }
  return result;
}

function getFilterLabel(filterLabel) {
  let result = -100;
  switch (filterLabel) {
    case "L8":
      result = -1;
      break;
    case "L0":
      result = 0;
      break;
    case "L1":
      result = 1;
      break;
    case "L2":
      result = 2;
      break;
    case "L3":
      result = 3;
      break;
    case "L4":
      result = 4;
      break;
    case "L5":
      result = 5;
      break;
    case "L6":
      result = 6;
      break;
    case "A":
      result = 7;
      break;
    case "As":
      result = 8;
      break;
    case "Sch":
      result = 9;
      break;
    case "D":
      result = 10;
      break;
    case "L12":
      result = 12;
      break;

    default:
      break;
  }
  return result;
}

function checkHideUnassigned(lead, preData) {
  if (Number(preData?.issuperagent) === 1) {
    return false;
  }
  if (Number(lead?.Hideunassigned) === 0) {
    return false;
  }
  if (Number(preData?.usertype) != 7) {
    return false;
  }
  if (Number(preData?.agentid) === Number(lead?.assignment)) {
    return false;
  }
  return true;
}

function filterTag(selectedTags, leadTag) {
  const tags = leadTag.split(",");
  if (!selectedTags || selectedTags == 0 || selectedTags.length == 0) {
    return false;
  }
  if (tags.length == 0) {
    return true;
  }
  for (let i = 0; i < selectedTags?.length; i++) {
    const a = selectedTags[i];
    for (let j = 0; j < tags.length; j++) {
      const b = tags[j];
      if (Number(a) === Number(b)) {
        return false;
      }
    }
  }
  return true;
}

function filterAssignUser(selectedAssignUser, assignUser) {
  if (
    !selectedAssignUser ||
    selectedAssignUser == 0 ||
    selectedAssignUser?.length === 0
  ) {
    return false;
  }
  const a = parseInt(assignUser);
  if (a === 0) {
    return true;
  }
  for (let i = 0; i < selectedAssignUser?.length; i++) {
    const b = selectedAssignUser[i];
    if (a === parseInt(b)) {
      return false;
    }
  }
  return true;
}

function filterWaiting(info, filterOption) {
  var isWaiting = parseInt(info["waiting"]);
  var filterLabel = getFilterLabel(filterOption?.filter_label);
  if (filterLabel != 0) {
    return false;
  }
  if (isWaiting == 1) {
    return false;
  }
  // var lead = parseInt(info["lead_id"]);
  // var lead_status  = parseInt($("#waiting_" + lead).val());
  // if(lead_status == 1) { return false; }
  return true;
}

function filterSMSLiveLead(
  lead,
  filterParams,
  filterOption,
  currentUser,
  preData,
) {
  const issms = Number(lead?.issms);
  if (issms === 0) return false;
  const filterLead = Number(filterOption?.filter_lead);
  if (filterLead > 0) {
    // console.log("filterSMSLiveLead: filterLead > 0");
    return Number(lead?.lead_id) == filterLead;
  }
  if (isNotClientAuthorized(lead?.client_id, currentUser, preData?.cids)) {
    // console.log("filterSMSLiveLead: isNotClientAuthorized");
    return false;
  }
  if (checkHideUnassigned(lead, preData)) {
    // console.log("filterSMSLiveLead: checkHideUnassigned");
    return false;
  }
  if (
    preData?.client?.filter((i) => Number(i?.Id) == Number(lead?.client_id))
      ?.length < 1
  ) {
    // console.log("filterSMSLiveLead: preData?.client?.filter((i)");
    return false;
  }
  if (
    filterOption?.filter_cmp?.length > 0 &&
    !filterOption?.filter_cmp?.includes(lead?.campaign_id)
  ) {
    // console.log("filterSMSLiveLead: !filterOption?.filter_cmp?.inc");
    return false;
  }
  if (
    filterOption?.filter_leadsource?.length > 0 &&
    !filterOption?.filter_leadsource?.includes(lead?.leadsourceid)
  ) {
    // console.log("filterSMSLiveLead: !filterOption?.filter_leadsource?.");
    return false;
  }
  if (preData?.unseenlead?.includes(lead?.lead_id)) {
    // console.log("filterSMSLiveLead: preData?.unseenlead?.includes");
    return false;
  }
  const filterLabel = getFilterLabel(filterOption.filter_label);
  if (filterLabel === 0) {
    // console.log("filterSMSLiveLead: filterLabel === 0");
    return false;
  }
  if (filterLabel === 7 && Number(filterParams?.isApps) === 0) {
    // console.log("filterSMSLiveLead: filterLabel === 7 && ");
    return false;
  }
  if (filterLabel === 8 && Number(filterParams?.isApp) === 0) {
    // console.log("filterSMSLiveLead: filterLabel === 8 && ");
    /**
     * original isappshow need to dig up the web code
     */
    return false;
  }
  if (filterLabel === 9 && Number(filterParams?.isSchedule) === 0) {
    // console.log("filterSMSLiveLead: filterLabel === 9 && ");
    return false;
  }
  if (filterLabel === 12 && Number(filterParams?.newleadsourcelead) === 0) {
    // console.log("filterSMSLiveLead: filterLabel === 12 &&");
    return false;
  }
  if (
    filterLabel > 0 &&
    filterLabel <= 6 &&
    Number(filterParams?.label) != filterLabel
  ) {
    // console.log("filterSMSLiveLead: filterLabel > 0 &&  filterLabel <= 6 &&");
    return false;
  }
  if (filterTag(filterOption?.filter_lead, lead?.leadtag)) {
    // console.log("filterSMSLiveLead: filterTag");
    return false;
  }
  if (filterAssignUser(filterOption?.filter_assign, lead?.assignment)) {
    // console.log("filterSMSLiveLead: filterAssignUser");
    return false;
  }
  if (filterWaiting(lead, filterOption)) {
    // console.log("filterSMSLiveLead: filterWaiting");
    return false;
  }
  return true;
}

export default {
  getLeadData,
  isNotClientAuthorized,
  isNotAuthorized,
  getFilterLabel,
  checkHideUnassigned,
  filterSMSLiveLead,
  filterTag,

  filterAssignUser,

  filterWaiting,
};
