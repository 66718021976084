import React, { useState } from "react";
import {
  Box,
  Checkbox,
  ListItemText,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import Select from "react-dropdown-select";

export default function MultiSelect(props) {
  const {
    options,
    values,
    handleChange,
    placeholder,
    isSearch,
    fullWidth = false,
  } = props;
  const [selectedOptions, setSelectedOptions] = useState(values);

  const onChange = (_values) => {
    setSelectedOptions(_values);
  };

  const onDropdownClose = () => {
    let changed = false;
    if (values?.length !== selectedOptions.length) {
      changed = true;
    } else {
      selectedOptions.map((i) => {
        if (values.filter((item) => item.value == i.value).length > 0) {
          changed = true;
        }
      });
    }
    if (changed) {
      handleChange(selectedOptions);
    }
  };

  const dropdownRenderer = ({ props, state, methods }) => {
    const regexp = new RegExp(state.search, "i");
    return (
      <Box>
        {isSearch ? (
          <Box sx={{ p: 1 }}>
            <TextField
              id="multiple-select-label-search"
              variant="outlined"
              placeholder="Search..."
              fullWidth
              size="small"
              value={state.search}
              onChange={methods.setSearch}
            />
          </Box>
        ) : (
          <Box>
            {methods.areAllSelected() ? (
              <MenuItem key={"all-controller"} onClick={methods.clearAll}>
                <ListItemText primary={"unselect all"} />
              </MenuItem>
            ) : (
              <MenuItem key={"all-controller"} onClick={methods.selectAll}>
                <ListItemText primary={"select all"} />
              </MenuItem>
            )}
          </Box>
        )}
        <Box>
          {props.options
            .filter((item) =>
              regexp.test(item[props.searchBy] || item[props.labelField]),
            )
            .map((option) => (
              <MenuItem
                key={option[props.valueField]}
                onClick={option.disabled ? null : () => methods.addItem(option)}
                sx={{
                  p: 0,
                }}
              >
                <Checkbox checked={selectedOptions.indexOf(option) !== -1} />
                <ListItemText primary={option[props.labelField]} />
              </MenuItem>
            ))}
        </Box>
      </Box>
    );
  };

  const contentRenderer = ({ props, state }) => {
    let renderText = placeholder;
    if (state.values.length > 0) {
      if (state.values.length > 3) {
        renderText = `${state.values.length} of ${props.options.length} Selected`;
      } else {
        renderText = state.values.map((e) => e.name).join(", ");
      }
    }
    return (
      <Typography
        sx={{
          width: "100%",
        }}
        textOverflow={"ellipsis"}
      >
        {renderText}
      </Typography>
    );
  };

  return (
    <Box
      sx={{
        width: fullWidth ? "100%" : "auto",
      }}
    >
      <Select
        placeholder={placeholder}
        options={options}
        labelField="name"
        valueField="value"
        multi
        dropdownRenderer={dropdownRenderer}
        contentRenderer={contentRenderer}
        onChange={(v) => onChange(v)}
        onDropdownClose={onDropdownClose}
        searchable={true}
        searchBy="name"
      />
    </Box>
  );
}
