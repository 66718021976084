/* eslint-disable no-unsafe-optional-chaining */
import React, { useContext, useEffect, useState } from "react";
import {
  BsCheckLg,
  BsPlusLg,
  BsReplyAll,
  BsTag,
  BsTelephone,
  BsTrash,
} from "react-icons/bs";
import {
  LiaBanSolid,
  LiaFileExportSolid,
  LiaFunnelDollarSolid,
} from "react-icons/lia";
import { AuthContext, FunnelContext } from "../../context";
import {
  ConversationManageLabelsModal,
  DropdownMultiSelect,
  FunnelCallRequiredModal,
  FunnelChangeStatusModal,
  FunnelDeleteMessageModal,
  FunnelEditDelayModal,
  FunnelEditLabelModal,
  FunnelEditMessageModal,
  FunnelEditNameModal,
  FunnelEditStatusModal,
  FunnelNewLabelModal,
  FunnelNewMessageModal,
  FunnlsLeadDataTable,
} from "../../components";
import {
  BiBoltCircle,
  BiCheckCircle,
  BiEdit,
  BiMessageRounded,
  BiPauseCircle,
  BiSolidEdit,
  BiUserCircle,
} from "react-icons/bi";
import { IoCopyOutline } from "react-icons/io5";
import { Switch } from "@mui/material";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import helper from "../../utils/helper";
import config from "../../config/config";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

export default function FunnelsPage() {
  const { user } = useContext(AuthContext);
  const {
    getFunnelsPageDataApi,
    getFunnelDetailsApi,
    filterFunnelByClientApi,
    editMessageModalDataApi,
    editLabelModalDataApi,
    editStatusModalDataApi,
    changeexcludesundayApi,
    changeFunnelNameModalApi,
    editDelayModalDataApi,
    createNewMessageApi,
    deletemessageApi,
    updateDelayApi,
  } = useContext(FunnelContext);
  //
  const [usertype, setUsertype] = useState("");
  const [clientlist, setClientlist] = useState([]);
  const [info, setInfo] = useState([]);
  const [selectedfunnel, setSelectedfunnel] = useState("");
  const [clt, setClt] = useState("");
  //
  const [selectedClients, setSelectedClients] = useState([]);
  //
  const [detailTab, setDetailTab] = useState("Funnel Details");
  // details
  const [detailRawData, setDetailRawData] = useState(undefined);
  const [leadinfo, setLeadinfo] = useState(undefined);
  const [fannel, setFannel] = useState(undefined);
  const [message, setMessage] = useState([]);
  const [login, setLogin] = useState("");
  const [customColor, setCustomColor] = useState("");
  const [Excludesunday, setExcludesunday] = useState(true);
  const [funnelid, setFunnelid] = useState("");
  // actions
  const [deleteMsgModal, setDeleteMsgModal] = useState(""); // message Id
  const [editMsgModal, setEditMsgModal] = useState(undefined); // modal data
  const [editLabelModal, setEditLabelModal] = useState(undefined); // modal data
  const [openManageLabelsModal, setOpenManageLabelsModal] = useState(false);
  const [editStatusModal, setEditStatusModal] = useState(undefined); // modal data
  // new actions
  const [newMsgModal, setNewMsgModal] = useState(false);
  const [newLabelModal, setNewLabelModal] = useState(false);
  const [changeStatusModal, setChangeStatusModal] = useState(false);
  const [callRequiredModal, setCallRequiredModal] = useState(false);
  //
  const [changeFunnelNameModal, setChangeFunnelNameModal] = useState(undefined);
  const [editDelayModal, setEditDelayModal] = useState(undefined);

  useEffect(() => {
    getFunnelsPageData();
  }, []);

  const getFunnelsPageData = async () => {
    const data = await getFunnelsPageDataApi({ userId: user.Id });
    if (data.status === "ok") {
      const res = data.response;
      setUsertype(res?.usertype);
      if (Number(usertype) <= 1 || Number(usertype) === 5) {
        setClientlist([
          ...[{ Id: "0", Name: "Global Funnel" }],
          ...res?.clientlist,
        ]);
      } else {
        setClientlist(res?.clientlist);
      }
      setInfo(res?.info);
      setClt(res?.clt);
      if (res?.info.length > 0) {
        setSelectedfunnel(res?.info[0].Id);
        //
        getFunnelDetails(res?.info[0].Id, res?.clt);
      }
    }
  };

  // useEffect(() => {
  //   getFunnelDetails(selectedfunnel, clt);
  // }, [selectedfunnel, clt]);

  const getFunnelDetails = async (id, clt) => {
    const data = await getFunnelDetailsApi({
      userId: user.Id,
      id: id,
      clt: clt,
    });
    if (data.status === "ok") {
      const res = data.response;
      setDetailRawData(res);
      //
      setLeadinfo(res?.leadinfo);
      setFannel(res?.fannel);
      setExcludesunday(Number(res?.fannel.Excludesunday) === 1 ? true : false);
      setMessage(res?.message);
      setLogin(res?.login);
      setCustomColor(res?.custom_color);
      setFunnelid(res?.funnelid);
      //
    }
  };

  const funnelfilter = async () => {
    let params = {};
    params.userId = user.Id;
    params.clt = selectedClients.map((item) => item.Id);
    const data = await filterFunnelByClientApi(params);
    if (data.status === "ok") {
      const res = data.response;
      setUsertype(res?.usertype);
      setInfo(res?.info);
      setClt(params.clt.toString());
      if (res?.info.length > 0) {
        setSelectedfunnel(res?.info[0].Id);
        //
        getFunnelDetails(res?.info[0].Id, params.clt.toString());
      } else {
        setDetailRawData(undefined);
      }
    }
  };

  const onClickFunnelItem = (funnelId) => {
    setSelectedfunnel(funnelId);
    getFunnelDetails(funnelId, clt);
  };

  const getlabelList = (msg) => {
    let lst = msg.split(",");
    let str = "";
    for (let i = 0; i < lst.length; i++) {
      let sp = lst[i].split("#");
      str += `<label class="status"><span><span class="${sp[1]}">
      <i class="fa-light fa-xmark" aria-hidden="true"></i></span>${sp[0]}</span></label>`;
    }
    return str;
  };

  const onToggleExcludesunday = async (e) => {
    console.log(e);
    let params = {
      userId: user.Id,
      funnelid: funnelid,
      flag: !Excludesunday === true ? 1 : 0,
    };
    const data = await changeexcludesundayApi(params);
    if (data.status === "ok") {
      const res = data.response;
      setExcludesunday(Number(res.Excludesunday) === 1 ? true : false);
    }
  };

  const editmessage = async (msgId) => {
    const data = await editMessageModalDataApi({ id: msgId });
    if (data.status === "ok") {
      const res = data.response;
      setEditMsgModal(res);
    }
  };

  const editlabel = async (msgId) => {
    const data = await editLabelModalDataApi({ mid: msgId });
    if (data.status === "ok") {
      const res = data.response;
      setEditLabelModal(res);
    }
  };

  const editstatus = async (msgId) => {
    const data = await editStatusModalDataApi({ mid: msgId });
    if (data.status === "ok") {
      const res = data.response;
      setEditStatusModal(res);
    }
  };

  const changefunnelname = async () => {
    if (!funnelid) {
      return;
    }
    const data = await changeFunnelNameModalApi({
      userId: user.Id,
      funnelid: funnelid,
    });
    if (data.status === "ok") {
      const res = data.response;
      setChangeFunnelNameModal(res);
    }
  };

  const editdelay = async (msgId) => {
    const data = await editDelayModalDataApi({ userId: user.Id, id: msgId });
    if (data.status === "ok") {
      const res = data.response;
      setEditDelayModal(res);
    }
  };

  const createNewMsg = async (params) => {
    const data = await createNewMessageApi(params);
    if (data.status === "ok") {
      setNewMsgModal(false);
      getFunnelDetails(selectedfunnel, clt);
    }
  };

  const deleteMsgById = async (msgId) => {
    const res = await deletemessageApi({ userId: user.Id, messageid: msgId });
    if (res.status === "ok") {
      setDeleteMsgModal("");
      getFunnelDetails(selectedfunnel, clt);
    }
  };

  const changeDelay = async (params) => {
    const data = await updateDelayApi(params);
    if (data.status === "ok") {
      toast.info(`${params.delaytime} days after ${params.delaycategory}`);
      setEditDelayModal(undefined);
      getFunnelDetails(selectedfunnel, clt);
    }
  };

  const createNewLabel = async (params) => {
    const data = await createNewMessageApi(params);
    if (data.status === "ok") {
      setNewLabelModal(false);
      getFunnelDetails(selectedfunnel, clt);
    }
  };

  const saveChangeStatus = async (params) => {
    const data = await createNewMessageApi(params);
    if (data.status === "ok") {
      setNewMsgModal(false);
      getFunnelDetails(selectedfunnel, clt);
    }
  };

  const onCreateCallRequired = async (params) => {
    const data = await createNewMessageApi(params);
    if (data.status === "ok") {
      setCallRequiredModal(false);
      getFunnelDetails(selectedfunnel, clt);
    }
  };

  return (
    <main id="funnels-page" className="container py-4 position-relative">
      <div className="d-flex align-items-center">
        <div className="section-title">
          <LiaFunnelDollarSolid className="icon me-2" />
          Funnels
        </div>
      </div>
      {/*  */}
      <div className="row mt-4">
        <div className="col col-sm-4">
          <div className="col-wrap bg-white">
            <div className="px-3 pt-3">
              <Link
                className="btn btn-sm btn-primary d-inline-flex align-items-center"
                to={`/funnels/create`}
              >
                <BsPlusLg className="me-1" />
                New
              </Link>
            </div>
            <hr />
            <div className="filter px-3">
              <DropdownMultiSelect
                className="w-100"
                options={clientlist.filter((item) => !!item.Name)}
                placeholder={"Select Clients"}
                labelField="Name"
                valueField="Id"
                values={selectedClients}
                onChange={(values) => setSelectedClients(values)}
                onDropdownClose={(values) => {
                  console.log(values);
                  funnelfilter();
                }}
              />
            </div>
            <div className="py-2">
              <ul className="funnels-list">
                {info.map((item, index) => (
                  <li
                    className={`funnel-item${
                      selectedfunnel === item.Id ? " active" : ""
                    }`}
                    key={index}
                    onClick={() => onClickFunnelItem(item.Id)}
                  >
                    <div className="funnel-title">
                      <span id={`funnelname_${item.Id}`}>{item.Name}</span>
                    </div>
                    <div className="clearfix"></div>
                    <div className="funnel-analytic">
                      <span>Leads Assigned: {item.Leads_count}</span> |
                      <span>
                        Actions:
                        <span id={`funnel_action_container1_${item.Id}`}>
                          {item.Funnel}
                        </span>
                      </span>{" "}
                      |<span>Total Replied: {item.Leads_replies}</span> |
                      <span>Completed: {item.Leads_complete}</span>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="col col-sm-8">
          <div className="funnel-details bg-white">
            <ul className="nav-tabs">
              <li
                className={`nav-tab${
                  detailTab === "Funnel Details" ? " active" : ""
                }`}
                onClick={() => setDetailTab("Funnel Details")}
              >
                Funnel Details
              </li>
              <li
                className={`nav-tab${
                  detailTab === "Leads Assigned" ? " active" : ""
                }`}
                onClick={() => setDetailTab("Leads Assigned")}
              >
                Leads Assigned
              </li>
            </ul>
            <div className={`tab-content${!detailRawData ? " d-none" : ""}`}>
              <div
                className={`tab-pane${
                  detailTab === "Funnel Details" ? " active" : ""
                }`}
              >
                <div className="d-flex align-items-center justify-content-end action p-3">
                  <a
                    className="action-button me-2"
                    onClick={() => changefunnelname()}
                  >
                    <BiEdit className="action-icon" />
                  </a>
                  <Link
                    className="action-button me-2"
                    to={`/funnels/copyfunnel/${funnelid}`}
                  >
                    <IoCopyOutline className="action-icon" />
                  </Link>
                  <a
                    className="action-button"
                    href={`${config.exportPath}/funnels/${funnelid}`}
                    title="Export"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <LiaFileExportSolid className="action-icon" />
                  </a>
                </div>
                <div className="clearfix"></div>
                <div className="funnels px-4">
                  <div className="row analytic">
                    <div className="col-sm-12">
                      <h4>Analytics</h4>
                      <div className="row analytic-table">
                        <div className="col-sm-2 px-1">
                          <div className="box box-border">
                            <BiUserCircle
                              className="box-icon"
                              style={{
                                color: customColor ? customColor : "#0051ff",
                              }}
                            />
                            <div className="box-texts">
                              <h6>{leadinfo?.Count}</h6>
                              <span>Leads Assigned</span>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-2 px-1">
                          <div className="box box-border">
                            <BiBoltCircle
                              className="box-icon"
                              style={{
                                color: customColor ? customColor : "#0051ff",
                              }}
                            />
                            <div className="box-texts">
                              <h6>{fannel?.Funnel}</h6>
                              <span>Actions</span>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-2 px-1">
                          <div className="box box-border">
                            <BiPauseCircle
                              className="box-icon"
                              style={{
                                color: customColor ? customColor : "#0051ff",
                              }}
                            />
                            <div className="box-texts">
                              <h6>{leadinfo?.Paused}</h6>
                              <span>Paused</span>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-2 px-1">
                          <div className="box box-border">
                            <LiaBanSolid
                              className="box-icon"
                              style={{
                                color: customColor ? customColor : "#0051ff",
                              }}
                            />
                            <div className="box-texts">
                              <h6>{leadinfo?.Unsubscribes}</h6>
                              <span>Unsubscribes</span>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-2 px-1">
                          <div className="box box-border">
                            <BsReplyAll
                              className="box-icon"
                              style={{
                                color: customColor ? customColor : "#0051ff",
                              }}
                            />
                            <div className="box-texts">
                              <h6>{leadinfo?.Replies}</h6>
                              <span>Total Replied</span>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-2 px-1">
                          <div className="box box-border">
                            <BiCheckCircle
                              className="box-icon"
                              style={{
                                color: customColor ? customColor : "#0051ff",
                              }}
                            />
                            <div className="box-texts">
                              <h6>{leadinfo?.Complete}</h6>
                              <span>Completed</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="timelinecontainer" className="campaign-list px-3 mt-2">
                  <div className="d-flex align-items-center">
                    <label className="me-2">Exclude Sunday</label>
                    <Switch
                      checked={Excludesunday}
                      onChange={(e) => onToggleExcludesunday(e)}
                    />
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <h6>Timeline</h6>
                    <DropdownButton
                      variant="btn btn-sm btn-primary ms-auto"
                      title="New Action"
                    >
                      <Dropdown.Item
                        eventKey="1"
                        onClick={() => setNewMsgModal(true)}
                      >
                        <BiMessageRounded />
                        New Message
                      </Dropdown.Item>
                      <Dropdown.Item
                        eventKey="2"
                        onClick={() => setNewLabelModal(true)}
                      >
                        <BsTag />
                        New Label
                      </Dropdown.Item>
                      <Dropdown.Item
                        eventKey="3"
                        onClick={() => setChangeStatusModal(true)}
                      >
                        <BsCheckLg />
                        Change Status
                      </Dropdown.Item>
                      <Dropdown.Item
                        eventKey="4"
                        onClick={() => setCallRequiredModal(true)}
                      >
                        <BsTelephone />
                        Call Required
                      </Dropdown.Item>
                    </DropdownButton>
                  </div>
                </div>
                <div className="px-3">
                  {message?.map((item, index) => (
                    <div className="campaign-item" key={index}>
                      {index === 0 ? (
                        <p>
                          <span id={`change_delay_${item.Id}`}>
                            Scheduled by user when assigning lead to funnel
                          </span>
                        </p>
                      ) : (
                        <p>
                          <span id={`change_delay_${item.Id}`}>
                            {item.Delaycount} days after lead submitted at{" "}
                            {item.Delaycategory}
                          </span>
                          <a
                            className="text-primary text-decoration-none cursor-pointer ms-1"
                            onClick={() => editdelay(item.Id)}
                          >
                            Change Delay
                          </a>
                        </p>
                      )}
                      <div
                        id={`message_container_${item.Id}`}
                        className="campaign-inner"
                      >
                        <div className="campaign-head d-flex">
                          <div className="user-detail me-auto">
                            <h6 className="campaign-title">
                              {Number(item.Messagetype) === 1 ? (
                                <BiMessageRounded />
                              ) : Number(item.Messagetype) === 2 ? (
                                <BsCheckLg />
                              ) : Number(item.Messagetype) === 3 ? (
                                <BsTag />
                              ) : Number(item.Messagetype) === 4 ? (
                                <>
                                  <BsTelephone /> Call Required
                                </>
                              ) : (
                                <></>
                              )}
                              {item.Name}
                            </h6>
                            {Number(item.Messagetype) === 1 ? (
                              <div className="message">
                                {item.Message}
                                {item.Gif !== "" ? (
                                  <img
                                    src={`https://media1.giphy.com/media/${item.Gif}/100_s.gif`}
                                    width="100px"
                                    height="100px"
                                    alt=""
                                  />
                                ) : item.Attached ? (
                                  <img
                                    src={item.Attached}
                                    width="100px"
                                    height="100px"
                                    alt=""
                                  />
                                ) : (
                                  <></>
                                )}
                              </div>
                            ) : Number(item.Messagetype) === 2 ? (
                              <div
                                id={`message_status_container_${item.Id}`}
                                className="message_status"
                              >
                                <span
                                  className={`label ${helper.getLabelIconClass(
                                    item.Message,
                                  )}`}
                                >
                                  {helper.getLabelText(item.Message)}
                                </span>
                              </div>
                            ) : Number(item.Messagetype) === 3 ? (
                              <div
                                className="message_labels"
                                id={`message_label_container_${item.Id}`}
                              >
                                <div
                                  id="labels_list1"
                                  dangerouslySetInnerHTML={{
                                    __html: getlabelList(item.Message),
                                  }}
                                ></div>
                              </div>
                            ) : (
                              <></>
                            )}
                            <div className="clearfix"></div>
                            <div className="campaign-meta">
                              <div className="meta">
                                <strong>Edited</strong>
                                {` on ${item.Editdate} by ${
                                  Number(login) === Number(item.Edituser)
                                    ? "you"
                                    : item.Edituser
                                }`}
                              </div>
                            </div>
                          </div>
                          <div className="ms-auto">
                            <div className="btn-group">
                              {Number(item.Messagetype) === 1 ? (
                                <a
                                  onClick={() => editmessage(item.Id)}
                                  className="btn btn-mini"
                                >
                                  <BiSolidEdit />
                                </a>
                              ) : Number(item.Messagetype) === 2 ? (
                                <a
                                  onClick={() => editstatus(item.Id)}
                                  className="btn btn-mini"
                                >
                                  <BiSolidEdit />
                                </a>
                              ) : (
                                <a
                                  onClick={() => editlabel(item.Id)}
                                  className="btn btn-mini"
                                >
                                  <BiSolidEdit />
                                </a>
                              )}
                              <a
                                onClick={() => setDeleteMsgModal(item.Id)}
                                className="btn btn-mini"
                              >
                                <BsTrash />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="d-flex align-items-center justify-content-end px-3 pb-5">
                  <DropdownButton
                    variant="btn btn-sm btn-primary ms-auto"
                    title="New Action"
                  >
                    <Dropdown.Item
                      eventKey="1"
                      onClick={() => setNewMsgModal(true)}
                    >
                      <BiMessageRounded />
                      New Message
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey="2"
                      onClick={() => setNewLabelModal(true)}
                    >
                      <BsTag />
                      New Label
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey="3"
                      onClick={() => setChangeStatusModal(true)}
                    >
                      <BsCheckLg />
                      Change Status
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey="4"
                      onClick={() => setCallRequiredModal(true)}
                    >
                      <BsTelephone />
                      Call Required
                    </Dropdown.Item>
                  </DropdownButton>
                </div>
              </div>
              {/*  */}
              <div
                className={`tab-pane${
                  detailTab === "Leads Assigned" ? " active" : ""
                }`}
              >
                <div className="p-3">
                  <FunnlsLeadDataTable
                    selectedfunnel={selectedfunnel}
                    data={detailRawData}
                    reloadData={() => getFunnelDetails(selectedfunnel, clt)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Modals */}
      <FunnelDeleteMessageModal
        msgId={deleteMsgModal}
        isOpenModal={!!deleteMsgModal}
        afterOpenModal={() => {}}
        closeModal={() => setDeleteMsgModal("")}
        onDelete={(msgId) => deleteMsgById(msgId)}
      />
      <FunnelEditMessageModal
        data={editMsgModal}
        isOpenModal={!!editMsgModal}
        afterOpenModal={() => {}}
        closeModal={() => setEditMsgModal(undefined)}
        reloadData={() => {
          setEditMsgModal(undefined);
          getFunnelDetails(selectedfunnel, clt);
        }}
      />
      <FunnelEditLabelModal
        data={editLabelModal}
        isOpenModal={!!editLabelModal}
        afterOpenModal={() => {}}
        closeModal={() => setEditLabelModal(undefined)}
        reloadData={() => {
          setEditLabelModal(undefined);
          getFunnelDetails(selectedfunnel, clt);
        }}
        onManageLabels={() => {
          setEditLabelModal(undefined);
          setOpenManageLabelsModal(true);
        }}
      />
      <ConversationManageLabelsModal
        isOpenModal={openManageLabelsModal}
        afterOpenModal={() => {}}
        closeModal={() => setOpenManageLabelsModal(false)}
      />
      <FunnelEditStatusModal
        data={editStatusModal}
        isOpenModal={!!editStatusModal}
        afterOpenModal={() => {}}
        closeModal={() => setEditStatusModal(undefined)}
        reloadData={() => {
          setEditStatusModal(undefined);
          getFunnelDetails(selectedfunnel, clt);
        }}
      />
      {/* New Actions */}
      <FunnelNewMessageModal
        isOpenModal={!!newMsgModal}
        afterOpenModal={() => {}}
        closeModal={() => setNewMsgModal(false)}
        onSubmit={(params) => createNewMsg(params)}
      />
      <FunnelNewLabelModal
        isOpenModal={!!newLabelModal}
        afterOpenModal={() => {}}
        closeModal={() => setNewLabelModal(false)}
        onSubmit={(params) => createNewLabel(params)}
      />
      <FunnelChangeStatusModal
        isOpenModal={!!changeStatusModal}
        afterOpenModal={() => {}}
        closeModal={() => setChangeStatusModal(false)}
        onSubmit={(params) => saveChangeStatus(params)}
      />
      <FunnelCallRequiredModal
        isOpenModal={!!callRequiredModal}
        afterOpenModal={() => {}}
        closeModal={() => setCallRequiredModal(false)}
        onSubmit={(params) => onCreateCallRequired(params)}
      />
      <FunnelEditNameModal
        data={changeFunnelNameModal}
        isOpenModal={!!changeFunnelNameModal}
        afterOpenModal={() => {}}
        closeModal={() => setChangeFunnelNameModal(undefined)}
        reloadData={() => {
          setChangeFunnelNameModal(undefined);
          getFunnelDetails(selectedfunnel, clt);
        }}
      />
      {/*  */}
      <FunnelEditDelayModal
        data={editDelayModal}
        isOpenModal={!!editDelayModal}
        afterOpenModal={() => {}}
        closeModal={() => setEditDelayModal(undefined)}
        onSubmit={(params) => changeDelay(params)}
      />
    </main>
  );
}
