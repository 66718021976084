import React, { useContext, useEffect, useState } from "react";
import CampaignClientDetails from "../CampaignClientDetails/CampaignClientDetails";
import CampaignUserBox from "./CampaignUserBox";
import ConversationsWebleadInfo from "./ConversationsWebleadInfo";
import { AuthContext, ConversationContext } from "../../context";

export default function ConversationsMessage2Box({
  leadId = "",
  filterParams,
}) {
  const { user } = useContext(AuthContext);
  const { getLeadSummaryByLeadIdApi } = useContext(ConversationContext);
  //
  const [showPage, setShowPage] = useState(false);
  const [summaryInfo, setSummaryInfo] = useState(null);
  const [optinfo, setOptinfo] = useState(null);
  const [profilelink, setProfilelink] = useState("");
  const [mediapicture, setMediapicture] = useState("");
  const [profilename, setProfilename] = useState("");
  const [optout, setOptout] = useState("");
  const [isleadsource, setIsleadsource] = useState("");
  const [leadsourceLogo, setLeadsourceLogo] = useState();
  const [ismedialinks, setIsmedialinks] = useState("");
  const [iswinbig, setIswinbig] = useState("");
  const [wprize, setWprize] = useState("");
  const [isfunnel, setIsfunnel] = useState(null);
  const [webleadinfo, setWebleadinfo] = useState(null);
  const [todayStart, setTodayStart] = useState("");
  const [todayEnd, setTodayEnd] = useState("");

  useEffect(() => {
    if (leadId) {
      getLeadSummaryByLeadId(leadId);
    }
  }, [leadId]);

  const getLeadSummaryByLeadId = async (lead) => {
    console.log("<><>", lead);
    const data = await getLeadSummaryByLeadIdApi({
      userId: user.Id,
      lead: lead,
    }); // setajaxmessage
    if (data.status === "ok") {
      const res = data.response;
      setSummaryInfo(res?.info);
      setOptinfo(res?.optinfo);
      setProfilelink(res?.profilelink);
      setMediapicture(res?.mediapicture);
      setProfilename(res?.profilename);
      setOptout(res?.optout);
      setIsleadsource(res?.isleadsource);
      setLeadsourceLogo(res?.leadsource_logo);
      setIsmedialinks(res?.ismedialinks);
      setIswinbig(res?.iswinbig);
      setWprize(res?.wprize);
      setIsfunnel(res?.isfunnel);
      //
      setWebleadinfo(res?.webleadinfo);
      setTodayStart(res?.today_start);
      setTodayEnd(res?.today_end);
      //
      setShowPage(true);
    }
  };

  return (
    <div className={`message2-box${showPage ? "" : " d-none"}`}>
      <CampaignUserBox
        leadId={leadId}
        filterParams={filterParams}
        summaryInfo={summaryInfo}
        optinfo={optinfo}
        profilelink={profilelink}
        mediapicture={mediapicture}
        profilename={profilename}
        setProfilename={setProfilename}
        optout={optout}
        isleadsource={isleadsource}
        leadsourceLogo={leadsourceLogo}
        ismedialinks={ismedialinks}
        iswinbig={iswinbig}
        wprize={wprize}
        isfunnel={isfunnel}
        reloadData={() => getLeadSummaryByLeadId(leadId)}
      />
      <div className="mt-2">
        <ConversationsWebleadInfo leadId={leadId} data={webleadinfo} />
      </div>
      <div className="mt-2">
        <CampaignClientDetails
          leadId={leadId}
          summaryInfo={summaryInfo}
          todayStart={todayStart}
          todayEnd={todayEnd}
        />
      </div>
    </div>
  );
}
