import React from "react";
import { TextField, Box, InputLabel, Typography, Stack } from "@mui/material";
import { ColorsConfig } from "../../config";

export default function PrimaryInput(props) {
  const {
    label,
    placeholder,
    type,
    name,
    autoComplete,
    autoFocus = false,
    handleChange,
    value,
    required = false,
    sx,
    error = false,
  } = props;

  return (
    <Box>
      <Stack direction={"column"}>
        {label && (
          <InputLabel
            sx={{ fontSize: "16px", color: ColorsConfig.light.primaryColor }}
          >
            {label}
            {required && (
              <span style={{ color: ColorsConfig.light.error }}>*</span>
            )}
          </InputLabel>
        )}
        <TextField
          margin="none"
          fullWidth
          name={name}
          type={type}
          size="small"
          placeholder={placeholder}
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          onChange={handleChange}
          value={value}
          required={required}
          minLength={type === "password" ? 6 : 1}
          sx={{
            ...sx,
            "& fieldset": {
              borderRadius: "4px",
              borderColor:
                error && !value
                  ? ColorsConfig.light.error
                  : ColorsConfig.light.primaryBorderColor,
            },
            mt: 0.5,
          }}
        />
        {error && !value && (
          <Typography
            variant="caption"
            color={ColorsConfig.light.error}
            sx={{ mt: 0.5 }}
          >
            Required the field
          </Typography>
        )}
      </Stack>
    </Box>
  );
}
