function getUserType(userType = 100) {
  let result = "Salesman";
  switch (userType) {
    case 2:
      result = "Manager";
      break;
    case 5:
      result = "Agent Admin";
      break;
    case 7:
      result = "Agent";
      break;
    default:
      break;
  }
  if (userType < 2) {
    result = "Admin";
  }
  return result;
}

export default {
  getUserType,
};
