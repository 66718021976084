import React, { createContext, useContext, useEffect, useState } from "react";
import moment from "moment";
import { AxiosContext } from "./axios_context";
import config from "../config/config";
import { AuthContext } from "./auth_context";

const ConversationContext = createContext(undefined);

const ConversationContextProvider = (props) => {
  const { axiosInstance } = useContext(AxiosContext);
  const { user, userTimeOffset } = useContext(AuthContext);

  const [conversationsLoading, setConversationsLoading] = useState(false);
  const [ajaxInfo, setAjaxInfo] = useState(null);
  const [inboxAnalysis, setInboxAnalysis] = useState(null);
  const [preConversationData, setPreConversationData] = useState(null);
  const [sClient, setSClient] = useState(null);
  const [cmpCSV, setCmpCSV] = useState(null);
  const [clients, setClients] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [usertags, setUsertags] = useState([]);
  const [assignusers, setAssignusers] = useState([]);
  const [leadsources, setLeadsources] = useState([]);
  const [saveFilterParams, setSaveFilterParams] = useState(null);
  const [info, setInfo] = useState(null);
  const [loadfrom, setLoadfrom] = useState(null); // number
  const [filterParams, setFilterParams] = useState({
    userId: user?.Id,
    startdate: moment().subtract(30, "days").format("YYYY-MM-DD"),
    enddate: moment().format("YYYY-MM-DD"),
    dateLabel: `${moment()
      .subtract(30, "days")
      .format("YYYY-MM-DD")} - ${moment().format("YYYY-MM-DD")}`,
    sfilter: "",
    assigneduserArr: [], //
    tagArr: [],
    clientArr: [],
    leadsourceArr: [],
    campaignArr: [],
    assigneduser: "", //
    tag: "",
    sclient: sClient,
    client: "",
    leadsource: "",
    campaign: "", //
    isnew: 0,
    fromcampaign: 1,
    isanalysis: 1,
    ismultipleleadsend: 0,
    newleadsourcelead: 0,
    iswaiting: 0,
    label: 0, // complex
    isDone: 0,
    isApp: 0,
    isApps: 0,
    isSchedule: 0,
    isMove: 0,
    loadfrom: loadfrom,
    timeOffset: userTimeOffset,
  });
  const [filterOption, setFilterOption] = useState({
    filtertype: "",
    filter_label: "L8",
    sfilter: "L8",
    filter_client: "", // client id
    filter_cmp: "", // campaign id
    filter_tag: "", // label id
    filter_assign: "", // assignuser id
    filter_leadsource: "", // leadsource id
    filter_response: 0,
    filter_lead: 0,
    filter_iswaiting: 0,
    //
    excludeleads: "",
    isMove: 0,
    MoveFlag: 0,
    MoveLead: 0,
    startdate: "",
    enddate: "",
  });
  const [lockedLead, setLockedLead] = useState(null);

  // filtering
  const [mention, setMention] = useState({
    ismention: "",
    mentionlead: "",
  });

  const [scheduleId, setScheduleId] = useState("");
  const [scheduleCsv, setScheduleCsv] = useState("");

  useEffect(() => {
    if (user && user?.Id) {
      setFilterParams({
        ...filterParams,
        userId: user?.Id,
        timeOffset: userTimeOffset,
      });
    }
  }, [user]);

  useEffect(() => {
    setFilterParams({
      ...filterParams,
      sclient: sClient,
    });
  }, [sClient]);

  const ajaxFilter = (data) => {
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/lead_sms/dev_ajax_filter`,
      data,
    })
      .then((response) => {
        const result = response.data;
        const res = result.response;
        if (result.status === "ok") {
          setAjaxInfo(res);
          if (data.isanalysis == 1) {
            setInboxAnalysis(res.analysis);
          }
          if (res?.info) {
            setInfo(res.info);
          }
        }
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const getConversationPreDataApi = ({ userId }) => {
    const data = {
      userId: userId,
      userid: userId,
    };
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/conversations/conversation_predata`,
      data,
    })
      .then((response) => {
        const data = response.data;
        const res = data.response;
        console.log("== conversation_predata ==", data);
        if (data.status === "ok") {
          setPreConversationData(res);
          setSClient(res?.cids);
          setCmpCSV(res?.cmpcsv);
          setClients(res?.client);
          setCampaigns(res?.campaign);
          setUsertags(res?.usertag);
          setAssignusers(res?.assignuserlist);
          setLeadsources(res?.leadsource);
          setSaveFilterParams(res?.savefilter);
          // setInfo(res?.info);
          setMention({
            ...mention,
            ismention: res?.ismention,
            mentionlead: res?.mentionlead,
          });
          setLoadfrom(res?.loadfrom);
          setScheduleId(res?.scheduleid);
        }
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const saveFilterApi = (params) => {
    const data = params;

    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/conversations/conversation_savefilter`,
      data,
    })
      .then((response) => {
        const data = response.data;
        console.log("== conversation_savefilter ==", data);
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const resetFilterApi = ({ userId }) => {
    const data = {
      userId: userId,
      userid: userId,
    };

    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/conversations/conversation_resetfilter`,
      data,
    })
      .then((response) => {
        const data = response.data;
        console.log("== conversation_resetfilter ==", data);
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const smsAutoCompleteApi = ({ userId, term = "" }) => {
    const data = {
      userId: userId,
      userid: userId,
      term: term,
    };
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/conversations/conversation_smsautocomplete`,
      data,
    })
      .then((response) => {
        const data = response.data;
        console.log("== conversation_smsautocomplete ==", data);
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const filterByLeadApi = ({ userId, lead }) => {
    const data = {
      userId: userId,
      userid: userId,
      lead: lead,
    };

    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/conversations/conversation_filterbylead`,
      data,
    })
      .then((response) => {
        const data = response.data;
        const res = data.response;
        console.log("== conversation_filterbylead ==", data);
        if (data.status === "ok") {
          setInfo(res?.info);
        }
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const filterByCampaignApi = ({ userId, campaign, flag }) => {
    const data = {
      userId: userId,
      userid: userId,
      campaign: campaign,
      flag: flag,
    };

    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/conversations/conversation_filterbycampaign`,
      data,
    })
      .then((response) => {
        const data = response.data;
        const res = data.response;
        console.log("== conversation_filterbycampaign ==", data);
        if (data.status === "ok") {
          setInfo(res?.info);
        }
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const unsetnewleadApi = ({ userId, lead }) => {
    const data = {
      userid: userId,
      lead: lead,
    };

    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/conversations/conversation_unsetnewlead`,
      data,
    })
      .then((response) => {
        const data = response.data;
        console.log("== conversation_unsetnewlead ==", data);
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const markasreadApi = ({ userId, lead, flag = 0 }) => {
    const data = {
      userid: userId,
      lead: lead,
      flag: flag,
    };

    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/conversations/conversation_markasread`,
      data,
    })
      .then((response) => {
        const data = response.data;
        console.log("== conversation_markasread ==", data);
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const getLeadSummaryByLeadIdApi = ({ userId, lead }) => {
    const data = {
      userId: userId,
      userid: userId,
      lead: lead,
    };

    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/conversations/conversation_leadsummarybyleadid`,
      data,
    })
      .then((response) => {
        const data = response.data;
        console.log("== conversation_leadsummarybyleadid ==", data);
        if (data.status === "ok") {
          // setWebleadinfo(res?.webleadinfo);
        }
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const setReminderApi = (params) => {
    const data = params;

    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/conversations/conversation_setreminder`,
      data,
    })
      .then((response) => {
        const data = response.data;
        console.log("== conversation_setreminder ==", data);
        if (data.status === "ok") {
          // setWebleadinfo(res?.webleadinfo);
        }
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const emailReportApi = (params) => {
    const data = params;

    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/conversations/conversation_emailreport`,
      data,
    })
      .then((response) => {
        const data = response.data;
        console.log("== conversation_emailreport ==", data);
        if (data.status === "ok") {
          // setWebleadinfo(res?.webleadinfo);
        }
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const loadSMSOptinModalDataApi = ({ userId, infoId }) => {
    const data = {
      userId: userId,
      infoId: infoId,
    };

    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/conversations/conversation_smsoptinmodal`,
      data,
    })
      .then((response) => {
        const data = response.data;
        console.log("== conversation_smsoptinmodal ==", data);
        if (data.status === "ok") {
          // setWebleadinfo(res?.webleadinfo);
        }
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const updateUserInfoApi = (params) => {
    const data = params;

    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/conversations/conversation_userinfoupdate`,
      data,
    })
      .then((response) => {
        const data = response.data;
        console.log("== conversation_userinfoupdate ==", data);
        if (data.status === "ok") {
          // setWebleadinfo(res?.webleadinfo);
        }
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const updateDefaultLanguageApi = (params) => {
    const data = params;

    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/conversations/conversation_defaultlanguageset`,
      data,
    })
      .then((response) => {
        const data = response.data;
        console.log("== conversation_defaultlanguageset ==", data);
        if (data.status === "ok") {
          // setWebleadinfo(res?.webleadinfo);
        }
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const loadChangeLabelModalDataApi = (params) => {
    const data = params;

    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/conversations/conversation_changelabelmodal`,
      data,
    })
      .then((response) => {
        const data = response.data;
        console.log("== conversation_changelabelmodal ==", data);
        if (data.status === "ok") {
          // setWebleadinfo(res?.webleadinfo);
        }
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const saveLabelChangeApi = (params) => {
    const data = params;

    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/conversations/conversation_labelchangesave`,
      data,
    })
      .then((response) => {
        const data = response.data;
        console.log("== conversation_labelchangesave ==", data);
        if (data.status === "ok") {
          // setWebleadinfo(res?.webleadinfo);
        }
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const loadLabelsApi = (params) => {
    const data = params;

    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/conversations/conversation_loadlabels`,
      data,
    })
      .then((response) => {
        const data = response.data;
        console.log("== conversation_loadlabels ==", data);
        if (data.status === "ok") {
          // setWebleadinfo(res?.webleadinfo);
        }
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const deleteTagApi = (params) => {
    const data = params;

    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/conversations/conversation_leadtagdelete`,
      data,
    })
      .then((response) => {
        const data = response.data;
        console.log("== conversation_leadtagdelete ==", data);
        if (data.status === "ok") {
          // setWebleadinfo(res?.webleadinfo);
        }
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const leadTagAutoCompleteApi = (params) => {
    const data = params;

    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/conversations/conversation_leadtagautocomplete`,
      data,
    })
      .then((response) => {
        const data = response.data;

        console.log("== conversation_leadtagautocomplete ==", data);
        if (data.status === "ok") {
          // setWebleadinfo(res?.webleadinfo);
        }
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const addLeadTagApi = (params) => {
    const data = params;

    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/conversations/conversation_leadtagadd`,
      data,
    })
      .then((response) => {
        const data = response.data;

        console.log("== conversation_leadtagadd ==", data);
        if (data.status === "ok") {
          // setWebleadinfo(res?.webleadinfo);
        }
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const loadManageLabelsModalDataApi = (params) => {
    const data = params;

    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/conversations/conversation_managelabelsmodal`,
      data,
    })
      .then((response) => {
        const data = response.data;

        console.log("== conversation_managelabelsmodal ==", data);

        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const deleteLeadTagApi = (params) => {
    const data = params;

    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/conversations/conversation_managelabels_leadtagdelete`,
      data,
    })
      .then((response) => {
        const data = response.data;

        console.log("== conversation_managelabels_leadtagdelete ==", data);

        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const addNewLeadTagApi = (params) => {
    const data = params;

    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/conversations/conversation_managelabels_leadtagadd`,
      data,
    })
      .then((response) => {
        const data = response.data;

        console.log("== conversation_managelabels_leadtagadd ==", data);

        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const loadAssignFunnelModalDataApi = (params) => {
    const data = params;

    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/conversations/conversation_assignfunnelmodal`,
      data,
    })
      .then((response) => {
        const data = response.data;

        console.log("== conversation_assignfunnelmodal ==", data);

        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const loadFunnelConfirmModalDataApi = (params) => {
    const data = params;

    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/conversations/conversation_funnelconfirmmodal`,
      data,
    })
      .then((response) => {
        const data = response.data;

        console.log("== conversation_funnelconfirmmodal ==", data);

        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const assignFunnelApi = (params) => {
    const data = params;

    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/conversations/conversation_assignfunnel`,
      data,
    })
      .then((response) => {
        const data = response.data;

        console.log("== conversation_assignfunnel ==", data);

        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const reAssignFunnelApi = (params) => {
    const data = params;

    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/conversations/conversation_reassignfunnel`,
      data,
    })
      .then((response) => {
        const data = response.data;

        console.log("== conversation_reassignfunnel ==", data);

        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const cancelFunnelApi = (params) => {
    const data = params;

    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/conversations/conversation_cancelfunnel`,
      data,
    })
      .then((response) => {
        const data = response.data;

        console.log("== conversation_cancelfunnel ==", data);

        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const pauseFunnelApi = (params) => {
    const data = params;

    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/conversations/conversation_pausefunnel`,
      data,
    })
      .then((response) => {
        const data = response.data;

        console.log("== conversation_pausefunnel ==", data);

        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const loadResumeFunnelConfirmModalDataApi = (params) => {
    const data = params;

    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/conversations/conversation_resumefunnelconfirmmodal`,
      data,
    })
      .then((response) => {
        const data = response.data;

        console.log("== conversation_resumefunnelconfirmmodal ==", data);

        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const resumeFunnelApi = (params) => {
    const data = params;

    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/conversations/conversation_resumefunnel`,
      data,
    })
      .then((response) => {
        const data = response.data;

        console.log("== conversation_resumefunnel ==", data);

        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const setActivityMessageApi = (params) => {
    const data = params;

    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/conversations/conversation_setactivitymessage`,
      data,
    })
      .then((response) => {
        const data = response.data;

        console.log("== conversation_setactivitymessage ==", data);

        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const loadCallPopupModalApi = (params) => {
    const data = params;

    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/conversations/conversation_callpopupmodal`,
      data,
    })
      .then((response) => {
        const data = response.data;

        console.log("== conversation_callpopupmodal ==", data);

        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const loadSetAppointmentModalDataApi = (params) => {
    const data = params;

    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/conversations/conversation_setappointmentmodal`,
      data,
    })
      .then((response) => {
        const data = response.data;

        console.log("== conversation_setappointmentmodal ==", data);

        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const setappforsmsApi = (params) => {
    const data = params;
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/conversations/conversation_setappforsms`,
      data,
    })
      .then((response) => {
        const data = response.data;
        console.log("== conversation_setappforsms ==", data);
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const sendingconfirmationmessageApi = (params) => {
    const data = params;
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/conversations/conversation_sendingconfirmationmessage`,
      data,
    })
      .then((response) => {
        const data = response.data;
        console.log("== conversation_sendingconfirmationmessage ==", data);
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const schedulingReminderMessageApi = (params) => {
    const data = params;
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/conversations/conversation_schedulingremindermessage`,
      data,
    })
      .then((response) => {
        const data = response.data;
        console.log("== conversation_schedulingremindermessage ==", data);
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const schedulingReminderMessage2Api = (params) => {
    const data = params;
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/conversations/conversation_schedulingremindermessage2`,
      data,
    })
      .then((response) => {
        const data = response.data;
        console.log("== conversation_schedulingremindermessage2 ==", data);
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const schedulingFollowupMessageApi = (params) => {
    const data = params;
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/conversations/conversation_schedulingfollowupmessage`,
      data,
    })
      .then((response) => {
        const data = response.data;
        console.log("== conversation_schedulingfollowupmessage ==", data);
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const addingnotesforappointmentApi = (params) => {
    const data = params;
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/conversations/conversation_addingnotesforappointment`,
      data,
    })
      .then((response) => {
        const data = response.data;
        console.log("== conversation_addingnotesforappointment ==", data);
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const sendappmailApi = (params) => {
    const data = params;
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/conversations/conversation_sendappmail`,
      data,
    })
      .then((response) => {
        const data = response.data;
        console.log("== conversation_sendappmail ==", data);
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const finishappsetApi = (params) => {
    const data = params;
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/conversations/conversation_finishappset`,
      data,
    })
      .then((response) => {
        const data = response.data;
        console.log("== conversation_finishappset ==", data);
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const appuserlogApi = (params) => {
    const data = params;

    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/conversations/conversation_appuserlog`,
      data,
    })
      .then((response) => {
        const data = response.data;
        console.log("== conversation_appuserlog ==", data);
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const loadSmsEditApptsModalDataApi = (params) => {
    const data = params;

    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/conversations/conversation_smseditapptsmodal`,
      data,
    })
      .then((response) => {
        const data = response.data;
        console.log("== conversation_smseditapptsmodal ==", data);
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const editApptsApi = (params) => {
    const data = params;

    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/conversations/conversation_editappts`,
      data,
    })
      .then((response) => {
        const data = response.data;
        console.log("== conversation_editappts ==", data);
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const sendfbafterapprescheduleApi = (params) => {
    const data = params;

    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/conversations/conversation_sendfbafterappreschedule`,
      data,
    })
      .then((response) => {
        const data = response.data;
        console.log("== conversation_sendfbafterappreschedule ==", data);
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const sendsmsafterapprescheduleApi = (params) => {
    const data = params;

    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/conversations/conversation_sendsmsafterappreschedule`,
      data,
    })
      .then((response) => {
        const data = response.data;
        console.log("== conversation_sendsmsafterappreschedule ==", data);
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const setApptsApi = (params) => {
    const data = params;

    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/conversations/conversation_setappts`,
      data,
    })
      .then((response) => {
        const data = response.data;
        console.log("== conversation_setappts ==", data);
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const loadEscalateModalDataApi = (params) => {
    const data = params;

    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/conversations/conversation_escalatemodal`,
      data,
    })
      .then((response) => {
        const data = response.data;
        console.log("== conversation_escalatemodal ==", data);
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const escalteApi = (params) => {
    const data = params;

    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/conversations/conversation_escalate`,
      data,
    })
      .then((response) => {
        const data = response.data;
        console.log("== conversation_escalate ==", data);
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const setoptoutApi = (params) => {
    const data = params;

    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/conversations/conversation_setoptout`,
      data,
    })
      .then((response) => {
        const data = response.data;
        console.log("== conversation_setoptout ==", data);
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const loadAdfSmsEmailReportModalData = (params) => {
    const data = params;

    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/conversations/conversation_adfsmsemailreportmodal`,
      data,
    })
      .then((response) => {
        const data = response.data;
        console.log("== conversation_adfsmsemailreportmodal ==", data);
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const afdemailreportApi = (params) => {
    const data = params;

    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/conversations/conversation_afdemailreport`,
      data,
    })
      .then((response) => {
        const data = response.data;
        console.log("== conversation_afdemailreport ==", data);
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const setcrmApi = (params) => {
    const data = params;

    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/conversations/conversation_setcrm`,
      data,
    })
      .then((response) => {
        const data = response.data;
        console.log("== conversation_setcrm ==", data);
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const getMakeValueApi = (params) => {
    const data = params;

    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/conversations/conversation_getmakevalue`,
      data,
    })
      .then((response) => {
        const data = response.data;
        console.log("== conversation_getmakevalue ==", data);
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const getModelValueApi = (params) => {
    const data = params;

    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/conversations/conversation_getmodelvalue`,
      data,
    })
      .then((response) => {
        const data = response.data;
        console.log("== conversation_getmodelvalue ==", data);
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const sendModelApi = (params) => {
    const data = params;

    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/conversations/conversation_sendmodel`,
      data,
    })
      .then((response) => {
        const data = response.data;
        console.log("== conversation_sendmodel ==", data);
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const saveVehicleApi = (params) => {
    const data = params;

    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/conversations/conversation_savevehicle`,
      data,
    })
      .then((response) => {
        const data = response.data;
        console.log("== conversation_savevehicle ==", data);
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const getSmsMesssageApi = (params) => {
    const data = params;
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/conversations/conversation_getsmsmessage`,
      data,
    })
      .then((response) => {
        const data = response.data;
        console.log("== conversation_getsmsmessage ==", data);
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const manualOptinApi = (params) => {
    const data = params;

    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/conversations/conversation_manualoptin`,
      data,
    })
      .then((response) => {
        const data = response.data;
        console.log("== conversation_manualoptin ==", data);
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const assignuserApi = (params) => {
    const data = params;

    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/conversations/conversation_assignuser`,
      data,
    })
      .then((response) => {
        const data = response.data;
        console.log("== conversation_assignuser ==", data);
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const sendfbreplyApi = (params) => {
    const data = params;

    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/conversations/conversation_sendfbreply`,
      data,
    })
      .then((response) => {
        const data = response.data;
        console.log("== conversation_sendfbreply ==", data);
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const sendScheduleNowApi = (params) => {
    const data = params;

    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/conversations/conversation_sendschedulenow`,
      data,
    })
      .then((response) => {
        const data = response.data;
        console.log("== conversation_sendschedulenow ==", data);
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const scheduledeleteApi = (params) => {
    const data = params;

    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/conversations/conversation_scheduledelete`,
      data,
    })
      .then((response) => {
        const data = response.data;
        console.log("== conversation_scheduledelete ==", data);
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const getSavedRepliesApi = (params) => {
    const data = params;

    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/conversations/conversation_savedreplies`,
      data,
    })
      .then((response) => {
        const data = response.data;
        console.log("== conversation_savedreplies ==", data);
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const deleteReplyTemplateApi = (params) => {
    const data = params;

    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/conversations/conversation_replytemplatedelete`,
      data,
    })
      .then((response) => {
        const data = response.data;
        console.log("== conversation_replytemplatedelete ==", data);
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const saveEditReplyTemplateApi = (params) => {
    const data = params;

    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/conversations/conversation_replytemplateupdate`,
      data,
    })
      .then((response) => {
        const data = response.data;
        console.log("== conversation_replytemplateupdate ==", data);
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const addReplyTemplateApi = (params) => {
    const data = params;

    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/conversations/conversation_replytemplateadd`,
      data,
    })
      .then((response) => {
        const data = response.data;
        console.log("== conversation_replytemplateadd ==", data);
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const generatingAiResponseApi = (params) => {
    const data = params;

    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/conversations/conversation_generatingairesponse`,
      data,
    })
      .then((response) => {
        const data = response.data;
        console.log("== conversation_generatingairesponse ==", data);
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const sendsmsApi = (params) => {
    const data = params;

    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/conversations/conversation_sendsms`,
      data,
    })
      .then((response) => {
        const data = response.data;
        console.log("== conversation_sendsms ==", data);
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const postnoteApi = (params) => {
    const data = params;

    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/conversations/conversation_postnote`,
      data,
    })
      .then((response) => {
        const data = response.data;
        console.log("== conversation_postnote ==", data);
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const sendfbmessageApi = (params) => {
    const data = params;

    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/conversations/conversation_sendfbmessage`,
      data,
    })
      .then((response) => {
        const data = response.data;
        console.log("== conversation_sendfbmessage ==", data);
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const setcarlysetingforleadApi = (params) => {
    const data = params;

    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/conversations/conversation_setcarlysetingforlead`,
      data,
    })
      .then((response) => {
        const data = response.data;
        console.log("== conversation_setcarlysetingforlead ==", data);
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const checkvaliddatetimeApi = (params) => {
    const data = params;

    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/conversations/conversation_checkvaliddatetime`,
      data,
    })
      .then((response) => {
        const data = response.data;
        console.log("== conversation_checkvaliddatetime ==", data);
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const setscheduleApi = (params) => {
    const data = params;

    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/conversations/conversation_setschedule`,
      data,
    })
      .then((response) => {
        const data = response.data;
        console.log("== conversation_setschedule ==", data);
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const providerValue = {
    conversationsLoading,
    ajaxInfo,
    inboxAnalysis,
    preConversationData,
    cmpCSV,
    sClient,
    clients,
    campaigns,
    usertags,
    assignusers,
    leadsources,
    loadfrom,
    saveFilterParams,
    filterParams,
    filterOption,
    lockedLead,
    setLockedLead,
    setFilterParams,
    setFilterOption,
    setConversationsLoading,
    ajaxFilter,
    getConversationPreDataApi,

    // filtering

    info,
    mention,

    scheduleId,
    setScheduleId,
    scheduleCsv,
    setScheduleCsv,
    saveFilterApi,
    resetFilterApi,
    smsAutoCompleteApi,
    filterByLeadApi,
    filterByCampaignApi,
    unsetnewleadApi,
    markasreadApi,
    getLeadSummaryByLeadIdApi,
    setReminderApi,
    emailReportApi,
    loadSMSOptinModalDataApi,
    updateUserInfoApi,
    updateDefaultLanguageApi,
    loadChangeLabelModalDataApi,
    saveLabelChangeApi,
    loadLabelsApi,
    deleteTagApi,
    leadTagAutoCompleteApi,
    addLeadTagApi,
    loadManageLabelsModalDataApi,
    deleteLeadTagApi,
    addNewLeadTagApi,
    loadAssignFunnelModalDataApi,
    loadFunnelConfirmModalDataApi,
    assignFunnelApi,
    reAssignFunnelApi,
    cancelFunnelApi,
    pauseFunnelApi,
    loadResumeFunnelConfirmModalDataApi,
    resumeFunnelApi,
    setActivityMessageApi,
    loadCallPopupModalApi,
    loadSetAppointmentModalDataApi,
    setappforsmsApi,
    sendingconfirmationmessageApi,
    schedulingReminderMessageApi,
    schedulingReminderMessage2Api,
    schedulingFollowupMessageApi,
    addingnotesforappointmentApi,
    sendappmailApi,
    finishappsetApi,
    appuserlogApi,
    loadSmsEditApptsModalDataApi,
    editApptsApi,
    sendfbafterapprescheduleApi,
    sendsmsafterapprescheduleApi,
    setApptsApi,
    loadEscalateModalDataApi,
    escalteApi,
    setoptoutApi,
    loadAdfSmsEmailReportModalData,
    afdemailreportApi,
    setcrmApi,
    getMakeValueApi,
    getModelValueApi,
    sendModelApi,
    saveVehicleApi,
    //
    getSmsMesssageApi,
    manualOptinApi,
    assignuserApi,
    sendfbreplyApi,
    sendScheduleNowApi,
    scheduledeleteApi,
    getSavedRepliesApi,
    deleteReplyTemplateApi,
    saveEditReplyTemplateApi,
    addReplyTemplateApi,
    generatingAiResponseApi,
    sendsmsApi,
    postnoteApi,
    sendfbmessageApi,
    setcarlysetingforleadApi,
    checkvaliddatetimeApi,
    setscheduleApi,
  };

  return (
    <ConversationContext.Provider value={providerValue}>
      {props.children}
    </ConversationContext.Provider>
  );
};

export { ConversationContext, ConversationContextProvider };
