import Modal from "react-modal";
import React, { useContext, useState, useEffect } from "react";
import { AuthContext, ConversationContext } from "../../context";
import helper from "../../utils/helper";
import { TagsInput } from "react-tag-input-component";
import { BiPaperPlane } from "react-icons/bi";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "560px",
    padding: "15px",
    marginTop: "20px",
    marginBottom: "20px",
  },
};

Modal.setAppElement("#root");

export default function ConversationAdfSmsEmailReportModal({
  data,
  isOpenModal = false,
  afterOpenModal,
  closeModal,
}) {
  const { user } = useContext(AuthContext);
  const { afdemailreportApi, setcrmApi } = useContext(ConversationContext);

  const [emails, setEmails] = useState([]);

  const [mainData, setMainData] = useState(null);
  const [submitFlag, setSubmitFlag] = useState(false);

  useEffect(() => {
    if (data) {
      setMainData({
        userId: user.Id,
        responseId: data?.responseId,
        email: data?.email,
      });
      //
      setEmails(data?.email ? data?.email.split(",") : []);
      setSubmitFlag(false);
    }
  }, [data]);

  const onSend = async () => {
    setSubmitFlag(true);
    if (emails.length) {
      let params = {
        userId: user.Id,
        emailaddress: emails.toString(),
        responseId: mainData.responseId,
      };
      const data = await afdemailreportApi(params);
      if (data.status === "ok") {
        const data2 = await setcrmApi();
        if (data2.status === "ok") {
          closeModal();
        }
      }
    }
  };

  return (
    <Modal
      isOpen={isOpenModal}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Status"
    >
      <div className="modal-header">
        <h4 className="d-flex align-items-center">
          <BiPaperPlane className="me-1" />
          Send to CRM
        </h4>
      </div>
      <div className="modal-body py-3">
        <div className="row">
          <div className="col-12">
            <label className="my-2">Send To</label>
            <TagsInput
              classNames="form-textarea"
              value={emails}
              onChange={setEmails}
              beforeAddValidate={(tag, tags) =>
                !tags.includes(tag) && helper.validateEmail(tag)
              }
              name="emails"
              placeHolder="add email"
            />
            {submitFlag && emails.length === 0 && (
              <small className="text-danger">This Field is required</small>
            )}
          </div>
        </div>
      </div>
      <div className="modal-footer mt-3">
        <button className="btn btn-primary me-2" onClick={() => onSend()}>
          Send
        </button>
        <button className="btn btn-outline-secondary" onClick={closeModal}>
          Cancel
        </button>
      </div>
    </Modal>
  );
}
