import React, { useState } from "react";
import { Box, Card, Stack, Typography } from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { ColorsConfig } from "../../config";
import UserTemplateEditModal from "../modals/user_template_edit_modal";

export default function UserTemplate(props) {
  const { template } = props;
  const [open, setOpen] = useState(false);

  const handleUpdate = () => {};

  return (
    <Box>
      <Card
        sx={{
          p: 1,
          cursor: "pointer",
        }}
        onClick={() => setOpen(true)}
      >
        <Stack
          direction={"row"}
          spacing={2}
          alignItems={"start"}
          justifyContent={"space-between"}
        >
          <Typography variant={"body2"}>{template.Title}</Typography>
          <DeleteOutlineOutlinedIcon sx={{ fontSize: 26, cursor: "pointer" }} />
        </Stack>
        <Box
          sx={{
            p: 1,
            borderRadius: 2,
            bgcolor: ColorsConfig.light.info,
            color: "white",
            mt: 1,
          }}
        >
          <Typography sx={{ fontSize: 14 }}>{template.Desciption}</Typography>
        </Box>
      </Card>
      <UserTemplateEditModal
        open={open}
        handleCloseModal={() => setOpen(false)}
        template={template}
        handleUpdate={handleUpdate}
      />
    </Box>
  );
}
