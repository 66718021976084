import React, { useContext, useState } from "react";
import Modal from "react-modal";
import { AuthContext, FunnelContext, SharedContext } from "../../context";
import { useEffect } from "react";
import { IoClose } from "react-icons/io5";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "560px",
    padding: "15px",
    marginTop: "20px",
    marginBottom: "20px",
    overflow: "visible",
  },
};

Modal.setAppElement("#root");

export default function FunnelEditLabelModal({
  data = null,
  isOpenModal = false,
  afterOpenModal,
  closeModal,
  reloadData,
  onManageLabels,
}) {
  const { user } = useContext(AuthContext);
  const { updateLabelsApi } = useContext(FunnelContext);
  const { tagAutoCompleteApi } = useContext(SharedContext);
  const [postData, setPostData] = useState({
    userId: user.Id,
    message: "",
    name: "",
    messageid: "",
  });
  const [label, setLabel] = useState([]);
  //
  const [searchKey, setSearchKey] = useState("");
  const [autocompleteLabels, setAutocompleteLabels] = useState([]);

  useEffect(() => {
    if (data) {
      let _postData = { ...postData };
      _postData.userId = user.Id;
      _postData.message = data?.message;
      _postData.name = data?.name;
      _postData.messageid = data?.mid;
      setPostData(_postData);
      //
      setLabel(data?.label);
    }
  }, [data]);

  const onSave = async () => {
    let params = { ...postData };
    params.message = label
      .map((item) => {
        return `${item.name}#${item.class}#${item.id}`;
      })
      .join(",");
    const data = await updateLabelsApi(params);
    if (data.status === "ok") {
      reloadData();
    }
  };

  const deleteTag = (tagId) => {
    setLabel((current) =>
      current.filter((el) => Number(el.id) !== Number(tagId)),
    );
  };

  const onSearch = async () => {
    const data = await tagAutoCompleteApi({ userId: user.Id, term: searchKey });
    if (data.status === "ok") {
      const res = Object.values(data.response);
      setAutocompleteLabels(res);
    }
  };

  const addNewLabelTag = (item) => {
    let _label = [...label];
    _label.push({
      name: item.label,
      class: item.value,
      id: item.id,
    });
    setLabel(_label);
    setSearchKey("");
    setAutocompleteLabels([]);
  };

  return (
    <Modal
      isOpen={isOpenModal}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Edit Modal"
    >
      <div className="modal-header">
        <h4 className="d-flex align-items-center">Edit Message</h4>
      </div>
      <div className="modal-body">
        <div className="row">
          <div className="col col-sm-12">
            <div className="form-group mb-2">
              <label className="mb-2">Action Name</label>
              <input
                type="text"
                className="form-control"
                name="message-name"
                value={postData.name}
                onChange={(e) =>
                  setPostData({ ...postData, name: e.target.value })
                }
              />
            </div>
          </div>
        </div>
        <div id="edit_labels_list" className="mb-2">
          {label.map((item, index) => (
            <label className="status" key={index}>
              <span>
                <span className={item.class}>
                  <IoClose
                    className="icon-close"
                    onClick={() => deleteTag(item.id)}
                  />
                </span>
                {item.name}
              </span>
            </label>
          ))}
        </div>
        <div className="row">
          <div className="col-12">
            <div className="form-group mb-2">
              <div className="d-flex align-items-center justify-content-between mb-2">
                <label className="">Label</label>
                <a
                  className="text-primary text-decoration-none cursor-pointer ms-auto"
                  onClick={() => onManageLabels()}
                >
                  Manage Labels
                </a>
              </div>
              <div className={`autocomplete-label-form`}>
                <input
                  type="text"
                  className="form-control w-100"
                  placeholder="Add label..."
                  value={searchKey}
                  onChange={(e) => setSearchKey(e.target.value)}
                  onKeyUp={(e) => {
                    if (e.key === "Enter" && searchKey) {
                      onSearch();
                    }
                  }}
                />
                {autocompleteLabels.length > 0 && (
                  <ul className="autocomplete-labels">
                    {autocompleteLabels.map((item, index) => (
                      <li
                        className="autocomplete-label"
                        key={index}
                        onClick={() => addNewLabelTag(item)}
                      >
                        <span className={`bubble ${item?.value}`}></span>
                        {item?.label}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer mt-3">
        <button className="btn btn-outline-secondary me-2" onClick={closeModal}>
          Cancel
        </button>
        <button className="btn btn-primary" onClick={() => onSave()}>
          Save
        </button>
      </div>
    </Modal>
  );
}
