import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  InputLabel,
  LinearProgress,
  Skeleton,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { AuthContext, OtherContext, SettingContext } from "../../../context";
import { ColorsConfig, Config, Constants } from "../../../config";
import { PrimaryInput, PrimarySelect } from "../../../components";
import { toast } from "react-toastify";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function UserAgencyDetails() {
  const { getAgencyDetails, updateAgencyDetails } = useContext(SettingContext);
  const { user } = useContext(AuthContext);
  const { imageUpload } = useContext(OtherContext);
  const [loading, setLoading] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [info, setInfo] = useState(null);

  useEffect(() => {
    if (user && user.Id) {
      getInfo();
    }
  }, [user]);

  const getInfo = async () => {
    setLoading(true);
    const result = await getAgencyDetails();
    setInfo(result.info);
    setLoading(false);
  };

  const handleUploadFile = async (files, field) => {
    if (files.length > 0) {
      const file = files[0];
      if (file.size > 10 * 1024) {
        toast.warn("File size exceeds 10mb");
        return false;
      }
    }
    setUploadLoading(true);
    const result = await imageUpload(files[0], files[0].name, 1);
    if (result && result.filename) {
      setInfo({
        ...info,
        [field]: result.filename,
      });
    }
    setUploadLoading(false);
  };

  const submit = async () => {
    await updateAgencyDetails(info);
    toast.success("Successfully agency details updated!");
  };

  const skeleton = () => {
    return (
      <Box>
        <Typography variant="h6">My Agency</Typography>
        <Skeleton
          variant="rounded"
          height={150}
          width={"100%"}
          sx={{ mt: 1 }}
        />
        <Divider
          sx={{
            marginY: 2,
            borderBottomWidth: 2,
            borderColor: ColorsConfig.light.primaryBorderColor,
          }}
        />
        <Typography variant="h6">Private Label</Typography>
        <Skeleton
          variant="rounded"
          height={250}
          width={"100%"}
          sx={{ mt: 1 }}
        />
        <Divider
          sx={{
            marginY: 2,
            borderBottomWidth: 2,
            borderColor: ColorsConfig.light.primaryBorderColor,
          }}
        />
        <Skeleton variant="rounded" height={70} width={"100%"} />
      </Box>
    );
  };

  const mainView = () => {
    return (
      <Box>
        <Typography variant="h6">My Agency</Typography>
        <Stack direction={"column"} spacing={1} mt={1}>
          <PrimaryInput
            label="Name"
            text="text"
            value={info?.Name}
            handleChange={(e) => {
              setInfo({
                ...info,
                Name: e.target.value,
              });
            }}
            sx={{
              width: 300,
            }}
          />
          <PrimaryInput
            label="Address"
            text="text"
            value={info?.Address}
            handleChange={(e) => {
              setInfo({
                ...info,
                Address: e.target.value,
              });
            }}
            sx={{
              width: 300,
            }}
          />
          <Stack direction={"row"} spacing={3} alignItems={"center"}>
            <PrimaryInput
              label="City"
              text="text"
              value={info?.City}
              handleChange={(e) => {
                setInfo({
                  ...info,
                  City: e.target.value,
                });
              }}
            />
            <PrimarySelect
              label="State"
              options={Constants.states}
              value={info?.State}
              handleChange={(e) => {
                setInfo({
                  ...info,
                  State: e.target.value,
                });
              }}
            />
            <PrimaryInput
              label="Zip"
              text="text"
              value={info?.Zip}
              handleChange={(e) => {
                setInfo({
                  ...info,
                  Zip: e.target.value,
                });
              }}
            />
          </Stack>
        </Stack>
        <Divider
          sx={{
            marginY: 2,
            borderBottomWidth: 2,
            borderColor: ColorsConfig.light.primaryBorderColor,
          }}
        />
        <Typography variant="h6">Private Label</Typography>
        <Stack direction={"column"} spacing={3} mt={1}>
          {uploadLoading && (
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          )}
          <Stack
            direction={{
              xs: "column",
              md: "row",
            }}
            spacing={2}
            alignItems={"start"}
            justifyContent={"space-between"}
          >
            <Stack spacing={1}>
              <InputLabel
                sx={{
                  fontSize: "14px",
                  color: ColorsConfig.light.primaryColor,
                }}
              >
                Logo
              </InputLabel>
              <Button
                component="label"
                variant="contained"
                startIcon={<CloudUploadIcon />}
                size="small"
                sx={{
                  width: 100,
                }}
                disabled={uploadLoading}
              >
                Upload
                <VisuallyHiddenInput
                  type="file"
                  onChange={(e) => {
                    handleUploadFile(e.target.files, "Logo");
                  }}
                  accept=".png, .jpg, .jpeg, .git"
                />
              </Button>
              {info?.Logo && (
                <img src={Config.resourcesPath + info.Logo} width={100} />
              )}
            </Stack>
            <Stack spacing={1}>
              <InputLabel
                sx={{
                  fontSize: "14px",
                  color: ColorsConfig.light.primaryColor,
                }}
              >
                Fav Icon
              </InputLabel>
              <Button
                component="label"
                variant="contained"
                startIcon={<CloudUploadIcon />}
                size="small"
                sx={{
                  width: 100,
                }}
                disabled={uploadLoading}
              >
                Upload
                <VisuallyHiddenInput
                  type="file"
                  onChange={(e) => {
                    handleUploadFile(e.target.files, "Favicon");
                  }}
                  accept=".png, .jpg, .jpeg, .git"
                />
              </Button>
              {info?.Favicon && (
                <img src={Config.resourcesPath + info.Favicon} width={100} />
              )}
            </Stack>
            <Stack spacing={1} sx={{ minWidth: 70 }}>
              <InputLabel
                sx={{
                  fontSize: "14px",
                  color: ColorsConfig.light.primaryColor,
                }}
              >
                Color
              </InputLabel>
              <PrimaryInput
                type="color"
                value={info?.Color}
                handleChange={(e) => {
                  setInfo({
                    ...info,
                    Color: e.target.value,
                  });
                }}
              />
            </Stack>
            <Stack spacing={1}>
              <InputLabel
                sx={{
                  fontSize: "14px",
                  color: ColorsConfig.light.primaryColor,
                }}
              >
                Dark Mode Logo
              </InputLabel>
              <Button
                component="label"
                variant="contained"
                startIcon={<CloudUploadIcon />}
                size="small"
                sx={{
                  width: 100,
                }}
                disabled={uploadLoading}
              >
                Upload
                <VisuallyHiddenInput
                  type="file"
                  onChange={(e) => {
                    handleUploadFile(e.target.files, "DarkLogo");
                  }}
                  accept=".png, .jpg, .jpeg, .git"
                />
              </Button>
              {info?.DarkLogo && (
                <img src={Config.resourcesPath + info.DarkLogo} width={100} />
              )}
            </Stack>
          </Stack>
          <Stack
            direction={{
              xs: "column",
              md: "row",
            }}
            spacing={2}
            alignItems={"start"}
            justifyContent={"start"}
          >
            <Stack spacing={1}>
              <InputLabel
                sx={{
                  fontSize: "14px",
                  color: ColorsConfig.light.primaryColor,
                }}
              >
                Background Image
              </InputLabel>
              <Button
                component="label"
                variant="contained"
                startIcon={<CloudUploadIcon />}
                size="small"
                sx={{
                  width: 100,
                }}
                disabled={uploadLoading}
              >
                Upload
                <VisuallyHiddenInput
                  type="file"
                  onChange={(e) => {
                    handleUploadFile(e.target.files, "pbackgorundimage");
                  }}
                  accept=".png, .jpg, .jpeg, .git"
                />
              </Button>
              {info?.pbackgorundimage && (
                <img
                  src={Config.resourcesPath + info.pbackgorundimage}
                  width={100}
                />
              )}
            </Stack>
            <Stack spacing={1} sx={{ pl: 2 }}>
              <InputLabel
                sx={{
                  fontSize: "14px",
                  color: ColorsConfig.light.primaryColor,
                }}
              >
                Background Color
              </InputLabel>
              <PrimaryInput
                type="color"
                value={info?.pbackgroundcolor}
                handleChange={(e) => {
                  setInfo({
                    ...info,
                    pbackgroundcolor: e.target.value,
                  });
                }}
              />
            </Stack>
          </Stack>
          <Stack
            direction={{
              xs: "column",
              md: "row",
            }}
            spacing={2}
            alignItems={"start"}
            justifyContent={"space-between"}
          >
            <PrimaryInput
              label="Legal Name"
              text="text"
              value={info?.Legalname}
              handleChange={(e) => {
                setInfo({
                  ...info,
                  Legalname: e.target.value,
                });
              }}
            />
            <PrimaryInput
              label="ADF Provider"
              text="text"
              value={info?.Adf}
              handleChange={(e) => {
                setInfo({
                  ...info,
                  Adf: e.target.value,
                });
              }}
            />
            <PrimaryInput
              label="Send From Email"
              text="email"
              value={info?.Fromsend}
              handleChange={(e) => {
                setInfo({
                  ...info,
                  Fromsend: e.target.value,
                });
              }}
            />
          </Stack>
          <Stack
            direction={{
              xs: "column",
              md: "row",
            }}
            spacing={2}
            alignItems={"start"}
            justifyContent={"space-between"}
          >
            <PrimaryInput
              label="Login Domain"
              text="text"
              value={info?.pdomain}
              handleChange={(e) => {
                setInfo({
                  ...info,
                  pdomain: e.target.value,
                });
              }}
            />
            <PrimaryInput
              label="Terms & Conditions URL"
              text="text"
              value={info?.pterms}
              handleChange={(e) => {
                setInfo({
                  ...info,
                  pterms: e.target.value,
                });
              }}
            />
            <PrimaryInput
              label="Privacy Policy URL"
              text="text"
              value={info?.pprivacy}
              handleChange={(e) => {
                setInfo({
                  ...info,
                  pprivacy: e.target.value,
                });
              }}
            />
          </Stack>
        </Stack>
        <Divider
          sx={{
            marginY: 2,
            borderBottomWidth: 2,
            borderColor: ColorsConfig.light.primaryBorderColor,
          }}
        />
        <Stack direction={"column"} spacing={3} mt={1}>
          <PrimarySelect
            label={"Email Branding"}
            options={Constants.branding}
            value={info?.EmailBranding}
            handleChange={(e) => {
              setInfo({
                ...info,
                EmailBranding: e.target.value,
              });
            }}
            sx={{
              width: 300,
            }}
          />
        </Stack>
        <Stack
          direction={"row"}
          spacing={3}
          mt={2}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Button variant="contained" disabled={uploadLoading} onClick={submit}>
            Save
          </Button>
          <Button variant="outlined" color="inherit">
            Cancel
          </Button>
        </Stack>
      </Box>
    );
  };

  return (
    <Box>
      {loading && skeleton()}
      {!loading && mainView()}
    </Box>
  );
}
