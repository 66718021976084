import React from "react";
import Chart from "react-apexcharts";

export default function ApexLineChart({ labels, series }) {
  const options = ({ categories }) => {
    return {
      chart: {
        id: "basic-bar",
        toolbar: {
          show: false,
        },
      },
      xaxis: {
        categories: categories ? categories : [],
      },
      stroke: {
        curve: "smooth",
        width: 2,
      },
    };
  };

  return (
    <Chart
      options={options({ categories: labels })}
      series={series ? series : []}
      type="line"
      width="100%"
      height="250"
    />
  );
}
