import React, { createContext, useContext, useState } from "react";
import { AxiosContext } from "./axios_context";
import config from "../config/config";

const VideoContext = createContext(undefined);

const VideoContextProvider = (props) => {
  const { axiosInstance } = useContext(AxiosContext);
  const [videos, setVideos] = useState([]);

  const getVideos = ({ userId, dealershipId = "", category, type }) => {
    const data = {
      userid: userId,
      dealershipid: dealershipId,
      category: category,
      videotype: type,
    };

    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/videos/get_video`,
      data,
    })
      .then((response) => {
        const data = response.data;
        const res = data.response;
        console.log("== getvideo ==", data);
        if (data.status === "ok") {
          setVideos(res.video);
        }
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
      });
  };

  const searchVideos = ({ userId, searchKey, category, type }) => {
    const data = {
      userid: userId,
      title: searchKey,
      category: category,
      videotype: type,
    };

    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/videos/search_video`,
      data,
    })
      .then((response) => {
        const data = response.data;
        const res = data.response;
        console.log("== searchvideo ==", data);
        if (data.status === "ok") {
          setVideos(res.video);
        }
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
      });
  };

  const updateVideo = ({
    videoId,
    title,
    duration,
    thumbnailURL,
    category,
    type,
    dealershipId,
  }) => {
    const data = {
      video_id: videoId,
      Title: title,
      Duration: duration,
      Category: category,
      Videotype: type,
      Dealership: dealershipId,
    };
    if (thumbnailURL) {
      data.Thumnail_url = thumbnailURL;
    }

    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/videos/update_video`,
      data,
    })
      .then((response) => {
        const data = response.data;
        const res = data.response;
        console.log("== updateVideo ==", data);
        if (data.status === "ok") {
          setVideos(res.video);
        }
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
      });
  };

  const deleteVideo = ({ userId, videoId }) => {
    const data = {
      userId: userId,
      videoId: videoId,
    };

    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/videos/video_delete`,
      data,
    })
      .then((response) => {
        const data = response.data;
        console.log("== video_delete ==", data);
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const saveVideo = (data) => {
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/videos/video_save`,
      data,
    })
      .then((response) => {
        const data = response.data;
        console.log("== video_save ==", data);
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const providerValue = {
    videos,
    getVideos,
    searchVideos,
    updateVideo,
    deleteVideo,
    saveVideo,
  };

  return (
    <VideoContext.Provider value={providerValue}>
      {props.children}
    </VideoContext.Provider>
  );
};

export { VideoContext, VideoContextProvider };
