import React from "react";
import Select from "react-dropdown-select";

export default function DropdownMultiSelect({
  options = [],
  values = [],
  onChange,
  onDropdownClose,
  placeholder = "Select",
  className = "",
  labelField = "Name",
  valueField = "Id",
}) {
  const handleDropdownClose = () => {
    const stringValues = values.map((item) => item.Id).toString();
    onDropdownClose(stringValues);
  };

  const dropdownRenderer = ({ props, state, methods }) => {
    console.log("===", props, state, methods);
    return (
      <div className="w-100">
        <div className="">
          {methods.areAllSelected() ? (
            <button className="toggle-select-all" onClick={methods.clearAll}>
              unselect all
            </button>
          ) : (
            <button className="toggle-select-all" onClick={methods.selectAll}>
              select all
            </button>
          )}
        </div>
        <div className="dropdown-select-items">
          {props.options.map((option) => (
            <div
              className="dropdown-select-item"
              disabled={option.disabled}
              key={option[props.valueField]}
              onClick={option.disabled ? null : () => methods.addItem(option)}
            >
              <input
                type="checkbox"
                onChange={() => methods.addItem(option)}
                checked={values.indexOf(option) !== -1}
              />
              {props.labelField.split(",").length > 1
                ? `${option[props.labelField.split(",")[0]]}-${
                    option[props.labelField.split(",")[1]]
                  }`
                : `${option[props.labelField]}`}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const contentRenderer = ({ props, state }) => {
    return (
      <div>
        {state.values.length > 1
          ? `${state.values.length} of ${props.options.length} Selected`
          : state.values.length === 1
          ? props.labelField.split(",").length > 1
            ? `${state.values[0][props.labelField.split(",")[0]]}-${
                state.values[0][props.labelField.split(",")[1]]
              }`
            : `${state.values[0][props.labelField]}`
          : placeholder}
      </div>
    );
  };

  return (
    <div className="">
      {options && (
        <Select
          className={`form-control w-100 ${className}`}
          placeholder={placeholder}
          values={values}
          options={options}
          labelField={labelField}
          valueField={valueField}
          multi={true}
          dropdownRenderer={dropdownRenderer}
          contentRenderer={contentRenderer}
          onChange={(values) => onChange(values)}
          onDropdownClose={() => handleDropdownClose()}
        />
      )}
    </div>
  );
}
