import React, { useEffect, useState } from "react";
import { LiaBullhornSolid, LiaCopy } from "react-icons/lia";
import CopyToClipboard from "react-copy-to-clipboard";
import { RxExternalLink } from "react-icons/rx";
import config from "../../config/config";
import { useContext } from "react";
import { SharedContext } from "../../context";
import moment from "moment";

export default function CampaignClientDetails({
  leadId,
  summaryInfo,
  todayStart,
  todayEnd,
}) {
  const { getLocaltimeApi, getWeatherConditionApi } = useContext(SharedContext);
  const [clipboardState, setClipboardState] = useState({
    value: "",
    copied: false,
  });
  const [activeStatus, setActiveStatus] = useState("active"); // inactive
  const [localtime, setLocaltime] = useState("");
  const [weather, setWeather] = useState(null);
  const [mainData, setMainData] = useState(null);

  useEffect(() => {
    console.log(leadId);
    console.log(clipboardState);
    console.log(mainData);
    if (summaryInfo) {
      let _timezone =
        Number(summaryInfo?.CampaignId) > 0
          ? summaryInfo?.Timezone
          : summaryInfo?.client_timezone;
      getLocaltime(_timezone);
      let _zipcode =
        Number(summaryInfo?.CampaignId) > 0
          ? summaryInfo?.campaign_zip
          : summaryInfo?.client_zip;
      //
      getWeatherCondition(_zipcode);
      setMainData({
        timezone: _timezone,
        zip: _zipcode,
      });
    }
  }, [summaryInfo]);

  const getLocaltime = async (timezone) => {
    const data = await getLocaltimeApi({ timezone: timezone });
    if (data.status === "ok") {
      const res = data.response;
      setLocaltime(res?.time);
      isactive(res?.time);
    }
  };

  const isactive = (currentitme) => {
    let flag = true;
    let start = todayStart;
    let end = todayEnd;
    if (start == "") {
      flag = false;
    }
    if (end == "") {
      flag = false;
    }
    if (flag) {
      let n = currentitme?.split(" ");
      if (n[1] == "AM") {
        let s = start?.split(" ");
        flag = comparetime(s[0], n[0], 1);
      } else {
        let s = end?.split(" ");
        flag = comparetime(s[0], n[0], 2);
      }
    }
    if (flag) {
      setActiveStatus("active");
    } else {
      setActiveStatus("inactive");
    }
  };

  const comparetime = (s1, s2, flag) => {
    var n1 = s1?.split(":");
    var h1 = parseInt(n1[0]) * 60 + parseInt(n1[1]);
    var n2 = s2?.split(":");
    if (parseInt(n2[0]) == 12) {
      n2[0] = 0;
    }
    var h2 = parseInt(n2[0]) * 60 + parseInt(n2[1]);
    if (flag == 1) {
      if (h2 >= h1) {
        return true;
      } else {
        return false;
      }
    } else {
      if (h2 <= h1) {
        return true;
      } else {
        return false;
      }
    }
  };

  const getWeatherCondition = async (zipcode) => {
    const data = await getWeatherConditionApi({ zip: zipcode });
    if (data.status === "ok") {
      const res = data.response;
      setWeather(res);
    }
  };

  const getWeatherIcon = (weather, currenttime) => {
    const w_code = {
      113: {
        day_icon: "&#33;",
        night_icon: "&#40;",
      },
      116: {
        day_icon: "&#34;",
        night_icon: "&#73;",
      },
      119: {
        day_icon: "&#35;",
        night_icon: "&#76;",
      },
      122: {
        day_icon: "&#36;",
        night_icon: "&#74;",
      },
      143: {
        day_icon: "&#38;",
        night_icon: "&#95;",
      },
      176: {
        day_icon: "&#41;",
        night_icon: "&#57;",
      },
      179: {
        day_icon: "&#43;",
        night_icon: "&#59;",
      },
      182: {
        day_icon: "&#45;",
        night_icon: "&#61;",
      },
      185: {
        day_icon: "&#81;",
        night_icon: "&#99;",
      },
      200: {
        day_icon: "&#48;",
        night_icon: "&#64;",
      },
      227: {
        day_icon: "&#85;",
        night_icon: "&#103;",
      },
      230: {
        day_icon: "&#86;",
        night_icon: "&#104;",
      },
      248: {
        day_icon: "&#39;",
        night_icon: "&#96;",
      },
      260: {
        day_icon: "&#79;",
        night_icon: "&#97;",
      },
      263: {
        day_icon: "&#80;",
        night_icon: "&#98;",
      },
      266: {
        day_icon: "&#80;",
        night_icon: "&#98;",
      },
      281: {
        day_icon: "&#81;",
        night_icon: "&#99;",
      },
      284: {
        day_icon: "&#115;",
        night_icon: "&#116;",
      },
      293: {
        day_icon: "&#41;",
        night_icon: "&#57;",
      },
      296: {
        day_icon: "&#41;",
        night_icon: "&#65;",
      },
      299: {
        day_icon: "&#41;",
        night_icon: "&#57;",
      },
      302: {
        day_icon: "&#49;",
        night_icon: "&#65;",
      },
      305: {
        day_icon: "&#42;",
        night_icon: "&#58;",
      },
      308: {
        day_icon: "&#50;",
        night_icon: "&#66;",
      },
      311: {
        day_icon: "&#82;",
        night_icon: "&#100;",
      },
      314: {
        day_icon: "&#82;",
        night_icon: "&#100;",
      },
      317: {
        day_icon: "&#53;",
        night_icon: "&#69;",
      },
      320: {
        day_icon: "&#53;",
        night_icon: "&#69;",
      },
      323: {
        day_icon: "&#43;",
        night_icon: "&#59;",
      },
      326: {
        day_icon: "&#51;",
        night_icon: "&#67;",
      },
      329: {
        day_icon: "&#43;",
        night_icon: "&#59;",
      },
      332: {
        day_icon: "&#51;",
        night_icon: "&#67;",
      },
      335: {
        day_icon: "&#44;",
        night_icon: "&#59;",
      },
      338: {
        day_icon: "&#52;",
        night_icon: "&#68;",
      },
      350: {
        day_icon: "&#54;",
        night_icon: "&#70;",
      },
      353: {
        day_icon: "&#41;",
        night_icon: "&#57;",
      },
      356: {
        day_icon: "&#42;",
        night_icon: "&#58;",
      },
      359: {
        day_icon: "&#117;",
        night_icon: "&#118;",
      },
      362: {
        day_icon: "&#45;",
        night_icon: "&#61;",
      },
      365: {
        day_icon: "&#119;",
        night_icon: "&#120;",
      },
      368: {
        day_icon: "&#43;",
        night_icon: "&#59;",
      },
      371: {
        day_icon: "&#44;",
        night_icon: "&#60;",
      },
      374: {
        day_icon: "&#46;",
        night_icon: "&#62;",
      },
      377: {
        day_icon: "&#47;",
        night_icon: "&#63;",
      },
      386: {
        day_icon: "&#48;",
        night_icon: "&#64;",
      },
      389: {
        day_icon: "&#56;",
        night_icon: "&#72;",
      },
      392: {
        day_icon: "&#89;",
        night_icon: "&#107;",
      },
      395: {
        day_icon: "&#90;",
        night_icon: "&#108;",
      },
    };
    let wIcon = w_code[weather?.icon];
    let wwIcon = ``;
    if (
      moment(currenttime, "HH:mm a") > moment(weather?.sunrise, "HH:mm a") &&
      moment(currenttime, "HH:mm a") < moment(weather?.sunset, "HH:mm a")
    ) {
      wwIcon = `<span class="weathericon glyph">${wIcon?.day_icon}</span>`;
    } else {
      let night_clear = "";
      if (wIcon?.night_icon === "&#40;") {
        night_clear = " night_clear";
      }
      wwIcon = `<span class="weathericon glyph${night_clear}">${wIcon?.night_icon}</span>`;
    }
    console.log(">>> icon >>>", wwIcon);
    return wwIcon;
  };

  const fbpopup = (url) => {
    console.log(url);
  };

  const sendmailforhotleads = (client, lead) => {
    console.log(client, lead);
  };

  return (
    <div className="bg-white">
      <h3 className="box-header py-1">
        <LiaBullhornSolid className="me-2" />
        {Number(summaryInfo?.CampaignId) > 0
          ? "Campaign Details"
          : "Client Details"}
      </h3>
      <div className="box operating-hours">
        <h5 className="d-flex align-items-center">
          <img
            width="20"
            height="auto"
            src={summaryInfo?.clogo}
            alt=""
            className="me-2"
          />
          {summaryInfo?.ClientName}
        </h5>
        <div className="row">
          <div className="col-12 col-sm-6">
            {Number(summaryInfo?.CampaignId) > 0 ? (
              <>
                <div className="">
                  <span className="ds-address">
                    {summaryInfo?.campaign_address} <br />
                    {summaryInfo?.campaign_city}, {summaryInfo?.campaign_state}{" "}
                    {summaryInfo?.campaign_zip}
                  </span>
                  <CopyToClipboard
                    text={`${summaryInfo?.campaign_address} \n
                    ${summaryInfo?.campaign_city}, 
                    ${summaryInfo?.campaign_state} ${summaryInfo?.campaign_zip}`}
                    onCopy={() =>
                      setClipboardState({
                        value: `${summaryInfo?.campaign_address} \n
                        ${summaryInfo?.campaign_city}, 
                        ${summaryInfo?.campaign_state} ${summaryInfo?.campaign_zip}`,
                        copied: true,
                      })
                    }
                  >
                    <LiaCopy color="#0051ff" size={14} />
                  </CopyToClipboard>
                </div>
                {!!summaryInfo?.fbcontact && (
                  <div>
                    <strong>Contact:</strong>
                    <span className="ds-contact">{summaryInfo?.fbcontact}</span>
                    <CopyToClipboard
                      text={summaryInfo?.fbcontact}
                      onCopy={() =>
                        setClipboardState({
                          value: summaryInfo?.fbcontact,
                          copied: true,
                        })
                      }
                    >
                      <LiaCopy color="#0051ff" size={14} />
                    </CopyToClipboard>
                  </div>
                )}
                {!!summaryInfo?.DealershipContact && (
                  <div>
                    <strong>Contact:</strong>
                    <span className="ds-contact">
                      {summaryInfo?.DealershipContact}
                    </span>
                    <CopyToClipboard
                      text={summaryInfo?.DealershipContact}
                      onCopy={() =>
                        setClipboardState({
                          value: summaryInfo?.DealershipContact,
                          copied: true,
                        })
                      }
                    >
                      <LiaCopy color="#0051ff" size={14} />
                    </CopyToClipboard>
                  </div>
                )}
                {!!summaryInfo?.DealershipWebsite && (
                  <div>
                    <strong>Website: </strong>
                    <span className="ds-contact">
                      {summaryInfo?.DealershipWebsite}
                    </span>
                    <a
                      target="_blank"
                      href={
                        summaryInfo?.DealershipWebsite.indexOf("http") === 0
                          ? summaryInfo?.DealershipWebsite
                          : `//${summaryInfo?.DealershipWebsite}`
                      }
                      rel="noreferrer"
                    >
                      <RxExternalLink color="#0051ff" size={14} />
                    </a>
                    <CopyToClipboard
                      text={summaryInfo?.DealershipWebsite}
                      onCopy={() =>
                        setClipboardState({
                          value: summaryInfo?.DealershipWebsite,
                          copied: true,
                        })
                      }
                    >
                      <LiaCopy color="#0051ff" size={14} />
                    </CopyToClipboard>
                  </div>
                )}
                {!!summaryInfo?.phone && (
                  <div>
                    <strong>Phone: </strong>
                    <span className="ds-contact">{summaryInfo?.phone}</span>
                    <CopyToClipboard
                      text={summaryInfo?.phone}
                      onCopy={() =>
                        setClipboardState({
                          value: summaryInfo?.phone,
                          copied: true,
                        })
                      }
                    >
                      <LiaCopy color="#0051ff" size={14} />
                    </CopyToClipboard>
                  </div>
                )}
                {!!summaryInfo?.campaign_InventoryUrl && (
                  <div>
                    <strong>Inventory Link: </strong>
                    <a
                      target="_blank"
                      href={summaryInfo?.campaign_InventoryUrl}
                      rel="noreferrer"
                    >
                      <RxExternalLink color="#0051ff" size={14} />
                    </a>
                    <CopyToClipboard
                      text={summaryInfo?.campaign_InventoryUrl}
                      onCopy={() =>
                        setClipboardState({
                          value: summaryInfo?.campaign_InventoryUrl,
                          copied: true,
                        })
                      }
                    >
                      <LiaCopy color="#0051ff" size={14} />
                    </CopyToClipboard>
                  </div>
                )}
                {!!summaryInfo?.cmptextin &&
                Number(summaryInfo?.cmptextin) === 1 ? (
                  <div>
                    <strong>Mail Piece: </strong>
                    <a
                      target="_blank"
                      href={summaryInfo?.campaign_InventoryUrl}
                      rel="noreferrer"
                    >
                      <RxExternalLink color="#0051ff" size={14} />
                    </a>
                  </div>
                ) : (
                  <div>
                    <strong>Facebook Ad: </strong>
                    <a
                      onClick={() =>
                        fbpopup(
                          `${config.domain}/index.php/campaigns
                          /loadfacebookad/${summaryInfo?.cmpkey}`,
                        )
                      }
                    >
                      <RxExternalLink color="#0051ff" size={14} />
                    </a>
                  </div>
                )}
                <div>
                  <strong>Sale Dates: </strong>
                  {`${summaryInfo?.StartDate} - ${summaryInfo?.EndDate}`}
                </div>
              </>
            ) : (
              <>
                <div className="">
                  <span className="ds-address">
                    {summaryInfo?.client_address} <br />
                    {summaryInfo?.client_city}, {summaryInfo?.client_state}{" "}
                    {summaryInfo?.client_zip}
                  </span>
                  <CopyToClipboard
                    text={`${summaryInfo?.client_address} \n
                    ${summaryInfo?.client_city}, 
                    ${summaryInfo?.client_state} ${summaryInfo?.client_zip}`}
                    onCopy={() =>
                      setClipboardState({
                        value: `${summaryInfo?.client_address} \n
                        ${summaryInfo?.client_city}, 
                        ${summaryInfo?.client_state} ${summaryInfo?.client_zip}`,
                        copied: true,
                      })
                    }
                  >
                    <LiaCopy color="#0051ff" size={14} />
                  </CopyToClipboard>
                </div>
                {!!summaryInfo?.DealershipContact && (
                  <div>
                    <strong>Contact:</strong>
                    <span className="ds-contact">
                      {summaryInfo?.DealershipContact}
                    </span>
                    <CopyToClipboard
                      text={summaryInfo?.DealershipContact}
                      onCopy={() =>
                        setClipboardState({
                          value: summaryInfo?.DealershipContact,
                          copied: true,
                        })
                      }
                    >
                      <LiaCopy color="#0051ff" size={14} />
                    </CopyToClipboard>
                  </div>
                )}
                {!!summaryInfo?.DealershipWebsite && (
                  <div>
                    <strong>Website: </strong>
                    <span className="ds-contact">
                      {summaryInfo?.DealershipWebsite}
                    </span>
                    <a
                      target="_blank"
                      href={
                        summaryInfo?.DealershipWebsite.indexOf("http") === 0
                          ? summaryInfo?.DealershipWebsite
                          : `//${summaryInfo?.DealershipWebsite}`
                      }
                      rel="noreferrer"
                    >
                      <RxExternalLink color="#0051ff" size={14} />
                    </a>
                    <CopyToClipboard
                      text={summaryInfo?.DealershipWebsite}
                      onCopy={() =>
                        setClipboardState({
                          value: summaryInfo?.DealershipWebsite,
                          copied: true,
                        })
                      }
                    >
                      <LiaCopy color="#0051ff" size={14} />
                    </CopyToClipboard>
                  </div>
                )}
                {!!summaryInfo?.phone && (
                  <div>
                    <strong>Phone: </strong>
                    <span className="ds-contact">{summaryInfo?.phone}</span>
                    <CopyToClipboard
                      text={summaryInfo?.phone}
                      onCopy={() =>
                        setClipboardState({
                          value: summaryInfo?.phone,
                          copied: true,
                        })
                      }
                    >
                      <LiaCopy color="#0051ff" size={14} />
                    </CopyToClipboard>
                  </div>
                )}
                {!!summaryInfo?.ClientInventoryURL && (
                  <div>
                    <strong>Inventory Link: </strong>
                    <a
                      target="_blank"
                      href={summaryInfo?.ClientInventoryURL}
                      rel="noreferrer"
                    >
                      <RxExternalLink color="#0051ff" size={14} />
                    </a>
                    <CopyToClipboard
                      text={summaryInfo?.ClientInventoryURL}
                      onCopy={() =>
                        setClipboardState({
                          value: summaryInfo?.ClientInventoryURL,
                          copied: true,
                        })
                      }
                    >
                      <LiaCopy color="#0051ff" size={14} />
                    </CopyToClipboard>
                  </div>
                )}
                {Number(summaryInfo?.Ishotbutton) === 1 && (
                  <div>
                    <span
                      onClick={() =>
                        sendmailforhotleads(
                          summaryInfo?.cltid,
                          summaryInfo?.lid,
                        )
                      }
                      className="hotlead"
                    >
                      Hot Lead
                    </span>
                  </div>
                )}
              </>
            )}
          </div>
          <div className="col-12 col-sm-6">
            <div className="d-flex align-items-center">
              <span
                className={`status-dot mx-1 ${
                  activeStatus === "active" ? "bg-success" : "bg-danger"
                }`}
              ></span>{" "}
              {localtime ? `${localtime} |` : ""}
              {/* <IoSunnyOutline className="mx-1" />  */}
              <span
                dangerouslySetInnerHTML={{
                  __html: getWeatherIcon(weather, localtime),
                }}
              ></span>{" "}
              {`${weather?.temp}°F`} {weather?.condition}
            </div>
            <h5 className="d-flex align-items-center mt-1">Operating Hours</h5>
            <div className="row">
              <div className="col-5">Monday</div>
              <div className="col-7 ps-0 text-end">
                {summaryInfo?.From_d1} - {summaryInfo?.To_d1}
              </div>
            </div>
            <div className="row">
              <div className="col-5">Tuesday</div>
              <div className="col-7 ps-0 text-end">
                {summaryInfo?.From_d2} - {summaryInfo?.From_d2}
              </div>
            </div>
            <div className="row">
              <div className="col-5">Wednesday</div>
              <div className="col-7 ps-0 text-end">
                {summaryInfo?.From_d3} - {summaryInfo?.From_d3}
              </div>
            </div>
            <div className="row">
              <div className="col-5">Thursday</div>
              <div className="col-7 ps-0 text-end">
                {summaryInfo?.From_d4} - {summaryInfo?.From_d4}
              </div>
            </div>
            <div className="row">
              <div className="col-5">Friday</div>
              <div className="col-7 ps-0 text-end">
                {summaryInfo?.From_d5} - {summaryInfo?.From_d5}
              </div>
            </div>
            <div className="row">
              <div className="col-5">Saturday</div>
              <div className="col-7 ps-0 text-end">
                {summaryInfo?.From_d6
                  ? `${summaryInfo?.From_d6} - ${summaryInfo?.From_d6}`
                  : "Closed"}
              </div>
            </div>
            <div className="row">
              <div className="col-5">Sunday</div>
              <div className="col-7 ps-0 text-end">
                {summaryInfo?.From_d7
                  ? `${summaryInfo?.From_d7} - ${summaryInfo?.From_d7}`
                  : "Closed"}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
