/* eslint-disable max-len */
import Modal from "react-modal";
import React, { useContext, useState, useEffect } from "react";
import { AuthContext, AxiosContext, ConversationContext } from "../../context";
import { BsCalendar2, BsCheckCircle } from "react-icons/bs";
import { Switch } from "@mui/material";
import ScheduleTimeSelect from "../select/schedule_time_select";
import moment from "moment";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "560px",
    padding: "15px",
    marginTop: "20px",
    marginBottom: "20px",
  },
};

Modal.setAppElement("#root");

export default function ConversationSetAppointmentBarcodeModal({
  data,
  isOpenModal = false,
  afterOpenModal,
  closeModal,
}) {
  const { user } = useContext(AuthContext);
  const { setLoadingText } = useContext(AxiosContext);
  const {
    scheduleId,
    getConversationPreDataApi,
    setappforsmsApi,
    sendingconfirmationmessageApi,
    schedulingReminderMessageApi,
    schedulingReminderMessage2Api,
    schedulingFollowupMessageApi,
    addingnotesforappointmentApi,
    sendappmailApi,
    finishappsetApi,
    appuserlogApi,
  } = useContext(ConversationContext);

  const [showScheduleReminder, setShowScheduleReminder] = useState(false);
  const [showScheduleReminder2, setShowScheduleReminder2] = useState(false);
  const [showAutomaticFollowUp, setShowAutomaticFollowUp] = useState(false);

  const [schedulelock, setSchedulelock] = useState(false);
  const [schedulelock2, setSchedulelock2] = useState(false);

  const [mainData, setMainData] = useState(null);
  const [appData, setAppData] = useState(null);
  const [smsData, setSmsData] = useState(null);

  useEffect(() => {
    if (!!data && isOpenModal) {
      setMainData({
        last_messagetype: data?.last_messagetype,
        lead: data?.leadid,
        pin: data?.code.Barcode,
        res: data?.response.Response.Id,
      });
      setAppData({
        userId: user.Id,
        // app data
        Client: data?.clientid,
        appleadid: data?.leadid,
        cmpname: data?.code.Campaign.Name,
        CampaignId: data?.code.Campaign.Id,
        Barcode: data?.code.Barcode,
        Note: "",
        // Mileage:
        Stime: "",
        StimeObj: { value: "", label: "- : -" },
        Sdate: data?.ccdate,
        Hphone: data?.HomePhone,
        Mphone: data?.Phone,
        Email: data?.code.person.Email,
        Zip: data?.code.person.Zip,
        City: data?.code.person.City,
        Address: data?.code.person.Address,
        State: data?.code.person.State,
        Lname: data?.code.person.Lname,
        Fname: data?.code.person.Fname,
        Status: 1, //
        // Year:
        // Make:
        // Model:
        isajax: 1, //
        apptype: 1,
        last_messagetype: data?.last_messagetype,
        is_app: data?.isapp,
        //
      });
      setSmsData({
        userId: user.Id,
        sms: data?.agentmessage.Automatic_confirmation
          ? data?.agentmessage.Automatic_confirmation
          : "Your appointment has been set for you to come into the dealership [AppDate] @ [AppTime]  with [ApptContactName] at [DealershipName] [DealershipAddress].",
        lead: data?.leadid,
        date: data?.ccdate, // = appData.date
        timeObj: { value: "", label: "- : -" }, // = appData.StimeObj
        time: "", // = appData.Stime
        app_Note: "", // = appData.Note
        //
        scheduleflag: 0,
        scheduledate: data?.ccdate,
        scheduletimeobj: { value: "", label: "- : -" },
        scheduletime: "",
        schedulesms: data?.agentmessage.Scheduled_reminder
          ? data?.agentmessage.Scheduled_reminder
          : "Appointment Reminder for [AppDate] @ [AppTime] tomorrow for you to come into the dealership. If you need to reschedule just shoot me a text back. Thanks!",
        //
        scheduleflag2: 0,
        scheduledate2: data?.ccdate, // = data?.cdate | formatted
        scheduletime2obj: { value: "", label: "- : -" },
        scheduletime2: "",
        schedulesms2: data?.agentmessage.Scheduled_reminder2
          ? data?.agentmessage.Scheduled_reminder2
          : "Appointment Reminder for [AppDate] @ [AppTime] tomorrow for you to come into the dealership. If you need to reschedule just shoot me a text back. Thanks!",
        //
        sms_appt: 1,
        automaticfollowupflag: 0,
        automaticfollowup_sms: data?.agentmessage.Automatic_follow_up
          ? data?.agentmessage.Automatic_follow_up
          : "Hey [fname], how did your appointment go? Were you able to find your vehicle?",
        automaticfollowup_date: data?.ffdate, // = data?.fdate | formatted
        automaticfollowup_time_obj: { value: "", label: "- : -" },
        automaticfollowup_time: "",
        apptype: 1, // = appData.apptype
        last_messagetype: data?.last_messagetype,
        Schedule_reminder_minute: data?.agentmessage.Schedule_reminder_minute,
        app_date: data?.ccdate, // = appData.Sdate
        app_time_obj: { value: "", label: "- : -" }, // = appData.StimeObj
        app_time: "", // = appData.Stime
      });
      setTimeout(function () {
        set_schedulelock();
      }, 1000);
    }
  }, [data, isOpenModal]);

  const onChangeAppType = (e) => {
    //
    let persone_m1 =
      "Your appointment has been set for you to come into the dealership [AppDate] @ [AppTime]  with [ApptContactName] at [DealershipName] [DealershipAddress].";
    let phone_m1 =
      "Your appointment has been set for you to come into the dealership [AppDate] @ [AppTime]  with [ApptContactName] at [DealershipName] [DealershipAddress].";

    let persone_m2 =
      "Appointment Reminder for [AppDate] @ [AppTime] tomorrow for you to come into the dealership. If you need to reschedule just shoot me a text back. Thanks!";
    let phone_m2 =
      "Appointment Reminder for [AppDate] @ [AppTime] tomorrow for you to come into the dealership. If you need to reschedule just shoot me a text back. Thanks!";

    let persone_m3 =
      "Hey [fname], how did your appointment go? Were you able to find your vehicle?";
    let phone_m3 =
      "Hey [fname], how did your appointment go? Were you able to find your vehicle?";

    let schedulereminder2 =
      "Appointment Reminder for [AppDate] @ [AppTime] tomorrow for you to come into the dealership. If you need to reschedule just shoot me a text back. Thanks!";
    let schedulereminder2_phone =
      "Appointment Reminder for [AppDate] @ [AppTime] tomorrow for you to come into the dealership. If you need to reschedule just shoot me a text back. Thanks!";
    //
    if (data?.agentmessage.Automatic_confirmation) {
      persone_m1 = data?.agentmessage.Automatic_confirmation;
    }
    if (data?.agentmessage.Scheduled_reminder) {
      persone_m2 = data?.agentmessage.Scheduled_reminder;
    }
    if (data?.agentmessage.Automatic_follow_up) {
      persone_m3 = data?.agentmessage.Automatic_follow_up;
    }
    if (data?.agentmessage.Automatic_confirmation_phone) {
      phone_m1 = data?.agentmessage.Automatic_confirmation_phone;
    }
    if (data?.agentmessage.Scheduled_reminder_phone) {
      phone_m2 = data?.agentmessage.Scheduled_reminder_phone;
    }
    if (data?.agentmessage.Automatic_follow_up_phone) {
      phone_m3 = data?.agentmessage.Automatic_follow_up_phone;
    }
    if (data?.agentmessage.Schedule_reminder2) {
      schedulereminder2 = data?.agentmessage.Schedule_reminder2;
    }
    if (data?.agentmessage.Schedule_reminder2_phone) {
      schedulereminder2_phone = data?.agentmessage.Schedule_reminder2_phone;
    }
    //
    // setSmsData({...smsData, apptype: e.target.value});
    setAppData({ ...appData, apptype: e.target.value });
    let _smsData = {};
    if (Number(e.target.value) === 2) {
      _smsData.apptype = e.target.value;
      _smsData.sms = phone_m1;
      _smsData.schedulesms = phone_m2;
      _smsData.schedulesms2 = schedulereminder2_phone;
      _smsData.automaticfollowup_sms = phone_m3;
    } else {
      _smsData.apptype = e.target.value;
      _smsData.sms = persone_m1;
      _smsData.schedulesms = persone_m2;
      _smsData.schedulesms2 = schedulereminder2;
      _smsData.automaticfollowup_sms = persone_m3;
    }
    //
    setscheduletime(_smsData);
    set_schedulelock();
  };

  const setscheduletime = (updateObj) => {
    let _smsData = smsData;
    if (updateObj) {
      Object.keys(updateObj).forEach((key) => {
        _smsData[key] = updateObj[key];
      });
    }
    //
    let setting = get_schedule_setting();
    let s = setting["minute"];
    if (s === 1440 || s === 0) {
      _smsData.scheduledate = moment(smsData.date)
        .subtract(1, "days")
        .format("YYYY-MM-DD");
      _smsData.scheduletime = smsData.time;
      _smsData.scheduletimeobj = smsData.timeObj;
    } else {
      s = adjusttime(s);
      _smsData.scheduledate = moment(smsData.date)
        .subtract(0, "days")
        .format("YYYY-MM-DD");
      _smsData.scheduletime = s;
      _smsData.scheduletimeobj = { value: s, label: s };
    }

    let s2 = setting["minute2"];
    if (s2 === 1440 || s2 === 0) {
      _smsData.scheduledate2 = moment(smsData.date)
        .subtract(1, "days")
        .format("YYYY-MM-DD");
      _smsData.scheduletime2 = smsData.time;
      _smsData.scheduletime2obj = smsData.timeObj;
    } else {
      s2 = adjusttime(s2);
      _smsData.scheduledate2 = moment(smsData.date)
        .subtract(0, "days")
        .format("YYYY-MM-DD");
      _smsData.scheduletime2 = s2;
      _smsData.scheduletime2obj = { value: s2, label: s2 };
    }
    _smsData.automaticfollowup_date = moment(smsData.date)
      .add(1, "days")
      .format("YYYY-MM-DD");
    _smsData.automaticfollowup_time = smsData.time;
    _smsData.automaticfollowup_time_obj = smsData.timeObj;
    console.log("=== 2 >>>", _smsData);
    //adjusttime();
    setSmsData(_smsData);
  };

  const get_schedule_setting = () => {
    let type = parseInt(smsData?.apptype ? smsData?.apptype : 1);
    let param = {};
    if (type === 1) {
      param["minute"] = parseInt(
        smsData?.Schedule_reminder_minute
          ? smsData?.Schedule_reminder_minute
          : data?.agentmessage.Schedule_reminder_minute,
      );
      param["lock"] = parseInt(data?.agentmessage.Schedule_reminder_lock);
      param["minute2"] = parseInt(data?.agentmessage.Schedule_reminder2_minute);
      param["lock2"] = parseInt(data?.agentmessage.Schedule_reminder2_lock);
    } else {
      param["minute"] = parseInt(
        data?.agentmessage.Schedule_reminder_phone_minute,
      );
      param["lock"] = parseInt(data?.agentmessage.Schedule_reminder_phone_lock);
      param["minute2"] = parseInt(
        data?.agentmessage.Schedule_reminder2_phone_minute,
      );
      param["lock2"] = parseInt(
        data?.agentmessage.Schedule_reminder2_phone_lock,
      );
    }
    return param;
  };

  const adjusttime = (Schedule_reminder_minute) => {
    let s = smsData.time;
    let lst = s.split(" ");
    let time = 0;
    if (lst[1] == "PM") {
      time = 720;
    } else {
      time = 0;
    }

    let tm = lst[0].split(":");
    let hour = parseInt(tm[0]);
    let minute = parseInt(tm[1]);
    if (hour == 12) {
      hour = 0;
    }
    let total = hour * 60 + minute + time;

    s = Schedule_reminder_minute;
    total = total - s;

    let a = "";
    if (total < 720) {
      a = "AM";
    } else {
      a = "PM";
    }
    hour = parseInt(total / 60);
    minute = total - hour * 60;
    if (hour < 10) {
      hour = "0" + hour;
    }
    if (minute < 10) {
      minute = "0" + minute;
    }
    if (hour > 12) {
      hour = hour - 12;
    }
    let res = hour + ":" + minute + " " + a;
    return res;
  };

  const set_schedulelock = () => {
    var setting = get_schedule_setting();
    if (Number(setting["lock"]) === 1) {
      setSchedulelock(true);
    } else {
      setSchedulelock(false);
    }

    if (Number(setting["lock2"]) === 1) {
      setSchedulelock2(true);
    } else {
      setSchedulelock2(false);
    }
  };

  const onSave = async () => {
    let dic = appData;

    let param = smsData;
    console.log(">>> mainData >>>", mainData);
    console.log(">>> appData >>>", appData);
    console.log(">>> smsdata >>>", smsData);
    // setappforsms | Creating Appointment...
    setLoadingText("Creating Appointment...");
    const data = await setappforsmsApi(dic);
    if (data.status === "ok") {
      const res = data.response;
      const responseId = res?.responseId;
      console.log("App Set completed");
      //
      console.log("start confirmationmessage");
      setLoadingText("Sending Confirmation Message...");
      const data2 = await sendingconfirmationmessageApi(param);
      if (data2.status === "ok") {
        console.log("end confirmationmessage");
        //
        console.log("start SchedulingReminderMessage");
        setLoadingText("Scheduling Reminder Message...");
        const data3 = await schedulingReminderMessageApi(param);
        if (data3.status === "ok") {
          console.log("end SchedulingReminderMessage");
          //
          console.log("start SchedulingFollowupMessage");
          setLoadingText("Scheduling Reminder Message 2...");
          const data4 = await schedulingReminderMessage2Api(param);
          if (data4.status === "ok") {
            console.log("end SchedulingReminderMessage 2");
            //
            console.log("start SchedulingFollowupMessage");
            setLoadingText("Scheduling Follow-up Message...");
            const data5 = await schedulingFollowupMessageApi(param);
            if (data5.status === "ok") {
              console.log("end SchedulingFollowupMessage");
              //
              console.log("start add Note");
              setLoadingText("Adding Note...");
              const data6 = await addingnotesforappointmentApi(param);
              if (data6.status === "ok") {
                console.log("end addingnotesforappointment");
                //
                console.log("start Confirm mail");
                setLoadingText("Sending Confirmation Email...");
                let pr = {};
                pr["userId"] = user.Id;
                pr["responseId"] = responseId;
                pr["apptype"] = smsData.apptype;
                const data7 = await sendappmailApi(pr);
                if (data7.status === "ok") {
                  console.log("end addingnotesforappointment");
                  //
                  setLoadingText("Completed");
                  let pr = {};
                  pr["userId"] = user.Id;
                  pr["scheduleid"] = scheduleId;
                  pr["lead"] = smsData.lead;
                  const data8 = await finishappsetApi(pr);
                  if (data8.status === "ok") {
                    console.log("finishappset");
                    finish(data8, responseId);
                    setLoadingText("");
                  }
                }
              }
            }
          }
        }
      }
    }
  };

  const finish = async (_data, _responseid) => {
    await getConversationPreDataApi({ userId: user.Id });
    var param = {};
    param["lead"] = appData.appleadid;
    param["responseId"] = _responseid;
    const data = await appuserlogApi(param);
    if (data.status === "ok") {
      closeModal();
    }
  };

  {
    if (!smsData) {
      return <></>;
    }
  }

  return (
    <Modal
      isOpen={isOpenModal}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Status"
    >
      <div className="modal-header">
        <h4 className="d-flex align-items-center">
          <BsCalendar2 className="me-1" />
          Set Appointment
        </h4>
      </div>
      <div className="modal-body">
        <div className="row mt-2">
          <div className="col-5">
            <div className="form-group">
              <label className="mb-2">Type</label>
              <select
                className="form-select"
                value={smsData?.apptype}
                onChange={(e) => {
                  onChangeAppType(e);
                }}
              >
                <option value="1">In Person</option>
                <option value="2">Phone Call</option>
              </select>
            </div>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-6">
            <div className="form-group">
              <label className="mb-2">Select Date</label>
              <input
                type="date"
                className="form-control"
                value={smsData?.date}
                onChange={(e) => {
                  setSmsData({ ...smsData, date: e.target.value });
                  setAppData({ ...appData, Sdate: e.target.value });
                }}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label className="mb-2">Select Time</label>
              {/* <input type="time" className="form-control" /> */}
              <ScheduleTimeSelect
                value={smsData?.timeObj}
                onChange={(e) => {
                  setSmsData({ ...smsData, timeObj: e, time: e.value });
                  setAppData({ ...appData, StimeObj: e, Stime: e.value });
                }}
              />
            </div>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-12">
            <div className="form-group">
              <label className="mb-2">Automatic Confirmation</label>
              <textarea
                className="form-textarea"
                value={smsData?.sms}
                onChange={(e) =>
                  setSmsData({ ...smsData, sms: e.target.value })
                }
              ></textarea>
            </div>
          </div>
        </div>
        <hr />
        <div className="">
          <h6 className="d-flex align-items-center">
            <BsCheckCircle className="me-1" />
            Scheduled Reminder
            <Switch
              className="ms-auto"
              checked={showScheduleReminder}
              onChange={(e) => setShowScheduleReminder(e.target.checked)}
            />
          </h6>
          {showScheduleReminder && (
            <>
              <div className="row mt-2">
                <div className="col-6">
                  <div className="form-group">
                    <label className="mb-2">Select Date</label>
                    <input
                      type="date"
                      className="form-control"
                      disabled={schedulelock}
                      value={smsData?.scheduledate}
                      onChange={(e) =>
                        setSmsData({ ...smsData, scheduledate: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label className="mb-2">Select Time</label>
                    <ScheduleTimeSelect
                      disabled={schedulelock}
                      value={smsData?.scheduletimeobj}
                      onChange={(e) => {
                        setSmsData({
                          ...smsData,
                          scheduletimeobj: e,
                          scheduletime: e.value,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-12">
                  <div className="form-group">
                    <textarea
                      className="form-textarea"
                      value={smsData.schedulesms}
                      onChange={(e) =>
                        setSmsData({ ...smsData, schedulesms: e.target.value })
                      }
                    ></textarea>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <hr />
        <div className="">
          <h6 className="d-flex align-items-center">
            <BsCheckCircle className="me-1" />
            Scheduled Reminder 2
            <Switch
              className="ms-auto"
              checked={showScheduleReminder2}
              onChange={(e) => setShowScheduleReminder2(e.target.checked)}
            />
          </h6>
          {showScheduleReminder2 && (
            <>
              <div className="row mt-2">
                <div className="col-6">
                  <div className="form-group">
                    <label className="mb-2">Select Date</label>
                    <input
                      type="date"
                      className="form-control"
                      disabled={schedulelock2}
                      value={smsData?.scheduledate2}
                      onChange={(e) =>
                        setSmsData({
                          ...smsData,
                          scheduledate2: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label className="mb-2">Select Time</label>
                    <ScheduleTimeSelect
                      disabled={schedulelock2}
                      value={smsData.scheduletime2obj}
                      onChange={(e) => {
                        setSmsData({
                          ...smsData,
                          scheduletime2obj: e,
                          scheduletime2: e.value,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-12">
                  <div className="form-group">
                    <textarea
                      className="form-textarea"
                      value={smsData.schedulesms2}
                      onChange={(e) =>
                        setSmsData({ ...smsData, schedulesms2: e.target.value })
                      }
                    ></textarea>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <hr />
        <div className="">
          <h6 className="d-flex align-items-center">
            <BsCheckCircle className="me-1" />
            Automatic Follow-up
            <Switch
              className="ms-auto"
              checked={showAutomaticFollowUp}
              onChange={(e) => setShowAutomaticFollowUp(e.target.checked)}
            />
          </h6>
          {showAutomaticFollowUp && (
            <>
              <div className="row mt-2">
                <div className="col-6">
                  <div className="form-group">
                    <label className="mb-2">Select Date</label>
                    <input
                      type="date"
                      className="form-control"
                      value={smsData?.automaticfollowup_date}
                      onChange={(e) =>
                        setSmsData({
                          ...smsData,
                          automaticfollowup_date: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label className="mb-2">Select Time</label>
                    <ScheduleTimeSelect
                      value={smsData?.automaticfollowup_time_obj}
                      onChange={(e) => {
                        setSmsData({
                          ...smsData,
                          automaticfollowup_time_obj: e,
                          automaticfollowup_time: e.value,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-12">
                  <div className="form-group">
                    <textarea
                      className="form-textarea"
                      value={smsData?.automaticfollowup_sms}
                      onChange={(e) =>
                        setSmsData({
                          ...smsData,
                          automaticfollowup_sms: e.target.value,
                        })
                      }
                    ></textarea>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <hr />
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <label className="mb-2">Note</label>
              <textarea
                className="form-textarea"
                value={smsData?.app_Note}
                onChange={(e) => {
                  setSmsData({ ...smsData, app_Note: e.target.value });
                  setAppData({ ...appData, Note: e.target.value });
                }}
              ></textarea>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer mt-3">
        <button className="btn btn-primary me-2" onClick={() => onSave()}>
          Save
        </button>
        <button className="btn btn-outline-secondary" onClick={closeModal}>
          Cancel
        </button>
      </div>
    </Modal>
  );
}
