import { Switch } from "@mui/material";
import { BsCloudArrowUp, BsDot } from "react-icons/bs";
import { ButtonGroup } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { useEffect, useContext } from "react";
import { AuthContext, ClientContext } from "../../context";
import React, { useState } from "react";
import { GrClose } from "react-icons/gr";
import config from "../../config/config";
import moment from "moment";
import helper from "../../utils/helper";
import ClientLeadsourceModal from "../modals/client_leadsource_modal";
import { useParams } from "react-router-dom";
import ClientImportLeadsModal from "../modals/client_import_leads_modal";
import ClientImportLeadsFinalModal from "../modals/client_import_leads_final_modal";
import ClientSearchSmsPhoneModal from "../modals/client_search_smsphone_modal";
import ClientSelectPhoneNumberModal from "../modals/client_select_phone_number_modal";
import ClientRemoveLeadsourceModal from "../modals/client_remove_leadsource_modal";

export default function ClientLeadSourcesTab({ clientid }) {
  const { keyid } = useParams();
  const { user } = useContext(AuthContext);
  const {
    getleadsourcesApi,
    toggleIncomingadfApi,
    toggleFunnelsApi,
    toggleDoubleOptInApi,
    updateSmsForwardApi,
    getClientDetailData,
    deletesmsnumberApi,
  } = useContext(ClientContext);
  const [client, setClient] = useState(undefined);
  const [leadsources, setLeadsources] = useState([]);
  const [AdfEmail, setAdfEmail] = useState("");
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [incomingadf, setIncomingadf] = useState(false);
  const [funnels, setFunnels] = useState(false);
  const [smsforwardnumber, setSmsforwardnumber] = useState("");
  const [doubleOptIn, setDoubleOptIn] = useState(false);
  const [smsforwardnumberErr, setSmsforwardnumberErr] = useState(false);
  //
  const [leadsourceModal, setLeadsourceModal] = useState({
    lid: 0,
    open: false,
  }); //leadsource id
  const [importLeadsModal, setImportLeadsModal] = useState(""); //clientid
  const [openImportLeadsFinalModal, setOpenImportLeadsFinalModal] =
    useState(false);
  const [opensearchsmsphoneModal, setOpensearchsmsphoneModal] = useState(false);
  const [selectPhoneNumberModal, setSelectPhoneNumberModal] = useState(null);
  const [leadsourceRemoveModal, setleadsourceRemoveModal] = useState("");

  useEffect(() => {
    if (clientid) {
      getleadsources({ userId: user.Id, cid: clientid });
    }
  }, [clientid]);

  const getleadsources = async (params) => {
    const data = await getleadsourcesApi(params);
    if (data.status === "ok") {
      const res = data.response;
      setClient(res?.client);
      setLeadsources(res?.leadsources);
      setAdfEmail(res?.adf_email);
      setIsSuperAdmin(res?.isSuperAdmin);
      setIncomingadf(res?.client?.incomingadf);
      setFunnels(res?.client?.Funnels);
      setSmsforwardnumber(res?.client?.forwardnumber?.type3);
      setDoubleOptIn(res?.client?.DoubleOptIn);
    }
  };

  const onIncomingadfChange = async (e) => {
    console.log(e);
    const data = await toggleIncomingadfApi({
      userId: user?.Id,
      cid: clientid,
      flag: incomingadf,
    });
    if (data.status === "ok") {
      const res = data.response;
      setIncomingadf(res?.IncomingADF);
    }
  };

  const onFunnelsChange = async (e) => {
    console.log(e);
    const data = await toggleFunnelsApi({
      userId: user?.Id,
      cid: clientid,
      flag: funnels,
    });
    if (data.status === "ok") {
      const res = data.response;
      setFunnels(res?.Funnels);
    }
  };

  const onDoubleOptInChange = async (e) => {
    console.log(e);
    const data = await toggleDoubleOptInApi({
      userId: user?.Id,
      cid: clientid,
      flag: doubleOptIn,
    });
    if (data.status === "ok") {
      const res = data.response;
      setDoubleOptIn(res?.DoubleOptIn);
    }
  };

  const onPhoneKeyUp = async (e) => {
    if (e.keyCode === 13) {
      if (smsforwardnumber && helper.validatePhone(smsforwardnumber)) {
        const data = await updateSmsForwardApi({
          userId: user?.Id,
          cid: clientid,
          phone: smsforwardnumber,
        });
        if (data.status === "ok") {
          getleadsources({ userId: user.Id, cid: clientid });
        }
      } else {
        setSmsforwardnumberErr(true);
        setTimeout(() => {
          setSmsforwardnumberErr(false);
        }, 5000);
      }
    }
  };

  const deletenumber = async (cid) => {
    const data = await deletesmsnumberApi({ userId: user.Id, cid: cid });
    if (data.status === "ok") {
      getleadsources({ userId: user.Id, cid: clientid });
    }
  };

  return (
    <>
      <div className="d-flex align-items-center">
        <h6 className="tab-tile">Lead Sources</h6>
        {!client?.Twilionumber || !client?.forwardnumber ? (
          <div className="text-danger ms-auto">
            Please add an SMS & Forward Number
          </div>
        ) : (
          <>
            <button
              className="btn btn-sm btn-outline-secondary ms-auto"
              onClick={() => setLeadsourceModal({ lid: 0, open: true })}
            >
              + Lead Source
            </button>
            <button
              className="btn btn-sm btn-outline-secondary ms-2"
              onClick={() => setImportLeadsModal(clientid)}
            >
              <BsCloudArrowUp /> Import
            </button>
          </>
        )}
      </div>
      <hr />
      <div className="row">
        <div className="col col-sm-2">
          <label className="mt-2 fw-bold text-secondary">ADF Email</label>
        </div>
        <div className="col col-sm-2">
          <Switch
            defaultValue={incomingadf}
            onChange={(e) => onIncomingadfChange(e)}
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col col-sm-2">
          <label className="mt-2 fw-bold text-secondary">Funnels</label>
        </div>
        <div className="col col-sm-2">
          <Switch defaultValue={funnels} onChange={(e) => onFunnelsChange(e)} />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-12">
          <p>{AdfEmail}</p>
        </div>
      </div>
      <label className="mt-2 fw-bold text-secondary">Phone</label>
      <div className="row mt-2">
        <div className="col col-sm-2">
          <div className="form-group">
            <label className="mb-2">SMS</label>
            {client?.Twilionumber ? (
              <div className="d-flex align-items-center">
                {client?.Twilionumber}
                {isSuperAdmin ? (
                  <a
                    className="ms-2 cursor-pointer"
                    onClick={() => deletenumber(clientid)}
                  >
                    <GrClose color="#0D6EFD" />
                  </a>
                ) : null}
              </div>
            ) : (
              <div className="">
                <a
                  className="text-primary text-decoration-none cursor-pointer"
                  onClick={() => setOpensearchsmsphoneModal(true)}
                >
                  Add Number
                </a>
              </div>
            )}
          </div>
        </div>
        <div className="col col-sm-4">
          <div className="form-group">
            <label className="mb-2">SMS Forward Number</label>
            <input
              type="text"
              className="form-control w-100"
              placeholder="Enter phone..."
              value={smsforwardnumber}
              onChange={(e) => setSmsforwardnumber(e.target.value)}
              onKeyUp={(e) => onPhoneKeyUp(e)}
            />
            {smsforwardnumberErr && (
              <small className="text-danger">Phone is not valid!</small>
            )}
          </div>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col col-sm-4">
          <div className="d-flex align-items-center">
            <label>Double Opt-In Required for Campaigns</label>
            <Switch
              defaultValue={doubleOptIn}
              onChange={(e) => onDoubleOptInChange(e)}
            />
          </div>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-12">
          <table className="table table-hover">
            <thead>
              <tr>
                <th></th>
                <th>Name</th>
                <th>Leads</th>
                <th>Engagement Rate</th>
                <th>Appts</th>
                <th>Sold</th>
                <th>Funnel</th>
                <th>CRM Push</th>
                <th>Double Opt-In</th>
                <th>Last Lead</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {leadsources &&
                leadsources.map((ls, index) => (
                  <tr key={index}>
                    <td>
                      <img
                        className="rounded-circle"
                        src={ls?.Logo}
                        width="40"
                        alt=""
                      />
                    </td>
                    <td>{ls?.Name}</td>
                    <td>
                      <a
                        className="text-decoration-none"
                        target="_blank"
                        href={`${config.clientPath}/
                        leadsourcereport_client/${ls.ClientId}/${ls.Id}/${ls.Name}`}
                        rel="noreferrer"
                      >
                        {ls?.analysis?.Lead}
                      </a>
                    </td>
                    <td>
                      {ls?.analysis?.Engagment && ls?.analysis?.Lead
                        ? ((Number(ls?.analysis?.Engagment) /
                            Number(ls?.analysis?.Lead)) *
                            100 *
                            10) /
                          10
                        : 0}
                      %
                    </td>
                    <td>{ls?.analysis?.Appointment}</td>
                    <td>{ls?.analysis?.Sold}</td>
                    <td>{ls?.Funnel}</td>
                    <td>
                      <BsDot
                        color={
                          ls?.CRMPush?.toLowerCase() === "yes"
                            ? "#00ff00"
                            : "#ff0000"
                        }
                        size={32}
                      />
                    </td>
                    <td>
                      <BsDot
                        color={
                          ls?.DoubleOptIn?.toLowerCase() === "yes"
                            ? "#00ff00"
                            : "#ff0000"
                        }
                        size={32}
                      />
                    </td>
                    <td>
                      <abbr
                        className="timeago"
                        title={ls?.analysis?.Lastactivity}
                      >
                        {moment(ls?.analysis?.Lastactivity).fromNow()}
                      </abbr>
                    </td>
                    <td>
                      {ls?.Name === "SMS Widget" ? (
                        <DropdownButton
                          as={ButtonGroup}
                          align={{ lg: "end" }}
                          variant="default"
                          title={`Actions`}
                        >
                          <Dropdown.Item
                            eventKey="1"
                            href={`${config.exportPath}/exportleadsource/${ls.Id}`}
                          >
                            Export
                          </Dropdown.Item>
                        </DropdownButton>
                      ) : (
                        <DropdownButton
                          as={ButtonGroup}
                          align={{ lg: "end" }}
                          variant="default"
                          title={`Actions`}
                        >
                          <Dropdown.Item
                            eventKey="1"
                            onClick={() =>
                              setLeadsourceModal({ lid: ls?.Id, open: true })
                            }
                          >
                            Edit
                          </Dropdown.Item>
                          <Dropdown.Item
                            eventKey="2"
                            onClick={() => setleadsourceRemoveModal(ls?.Id)}
                          >
                            Remove
                          </Dropdown.Item>
                          <Dropdown.Item
                            eventKey="3"
                            href={`${config.exportPath}/exportleadsource/${ls.Id}`}
                          >
                            Export
                          </Dropdown.Item>
                        </DropdownButton>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      {/*  */}
      <ClientLeadsourceModal
        cid={clientid}
        id={leadsourceModal.lid}
        isOpenModal={leadsourceModal.open}
        afterOpenModal={() => {}}
        closeModal={() => setLeadsourceModal({ lid: 0, open: false })}
        reloadData={() => {
          setLeadsourceModal({ lid: 0, open: false });
          getClientDetailData(keyid);
          getleadsources({ userId: user.Id, cid: clientid });
        }}
      />
      <ClientImportLeadsModal
        cid={importLeadsModal}
        isOpenModal={!!importLeadsModal}
        afterOpenModal={() => {}}
        closeModal={() => setImportLeadsModal("")}
        onOpenFinalModal={(params) => {
          console.log(params);
          setImportLeadsModal("");
          setOpenImportLeadsFinalModal(true);
        }}
      />
      <ClientImportLeadsFinalModal
        isOpenModal={openImportLeadsFinalModal}
        afterOpenModal={() => {}}
        closeModal={() => setOpenImportLeadsFinalModal(false)}
        reloadData={() => {
          setOpenImportLeadsFinalModal(false);
        }}
      />
      <ClientSearchSmsPhoneModal
        isOpenModal={opensearchsmsphoneModal}
        afterOpenModal={() => {}}
        closeModal={() => setOpensearchsmsphoneModal(false)}
        onSearch={(params) => {
          setOpensearchsmsphoneModal(false);
          let _params = params;
          _params.client = clientid;
          setSelectPhoneNumberModal(_params);
        }}
      />
      <ClientSelectPhoneNumberModal
        cid={clientid}
        params={selectPhoneNumberModal}
        isOpenModal={!!selectPhoneNumberModal}
        afterOpenModal={() => {}}
        closeModal={() => setSelectPhoneNumberModal(null)}
        reloadData={() => {
          setSelectPhoneNumberModal(null);
          getleadsources({ userId: user.Id, cid: clientid });
        }}
      />
      <ClientRemoveLeadsourceModal
        id={leadsourceRemoveModal}
        isOpenModal={!!leadsourceRemoveModal}
        afterOpenModal={() => {}}
        closeModal={() => setleadsourceRemoveModal("")}
        reloadData={() => {
          setleadsourceRemoveModal("");
          getleadsources({ userId: user.Id, cid: clientid });
        }}
      />
    </>
  );
}
