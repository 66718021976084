import React, { useState, useEffect } from "react";
import TablePagination from "../../TablePagination/TablePagination";
import config from "../../../config/config";
import ClientDeleteModal from "../../modals/client_delete_modal";
import {
  Avatar,
  Box,
  Card,
  Chip,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { PrimaryInput } from "../../../components";
import { ColorsConfig } from "../../../config";
import { TimeHelper } from "../../../utils";

export default function ClientManagerDataTable({
  clients,
  isexecutive,
  isDealerfunnelUser,
}) {
  const [searchKey, setSearchKey] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const perPage = 25;
  const [clientDeleteModal, setClientDeleteModal] = useState("");

  const tableHeaderCells = [
    { index: 0, title: "Logo" },
    { index: 0, title: "Name" },
    { index: 0, title: "Type" },
    { index: 0, title: "City" },
    { index: 0, title: "State" },
    { index: 0, title: "Campaign" },
    { index: 0, title: "Last Campaign" },
  ];

  const dataTableFilter = (data) => {
    return data.filter((el) =>
      el.Name.toLowerCase().includes(searchKey.toLowerCase()),
    );
  };

  useEffect(() => {
    console.log(isexecutive);
  }, []);

  return (
    <Card
      sx={{
        p: 2,
        height: "100%",
      }}
    >
      <Stack
        direction={{ xs: "column", md: "row" }}
        alignItems={"center"}
        justifyContent={"space-between"}
        spacing={1}
      >
        <PrimaryInput
          type={"text"}
          placeholder={"Search"}
          value={searchKey}
          handleChange={(e) => {
            setSearchKey(e.target.value);
            setCurrentPage(1);
          }}
          sx={{
            width: { xs: "80%", md: 200 },
            fontSize: 12,
          }}
        />
      </Stack>
      <Box mt={1}>
        <Table>
          <TableHead>
            <TableRow
              sx={{
                "& th, & td": { pl: 0.5, pt: 1, pb: 1, textAlign: "start" },
              }}
            >
              {tableHeaderCells.map((item, index) => {
                if (
                  item.index === 0 ||
                  item.index === Number(isDealerfunnelUser)
                ) {
                  return <TableCell key={index}>{item.title}</TableCell>;
                }
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {clients && dataTableFilter(clients).length > 0
              ? dataTableFilter(clients).map((item, index) => {
                  if (
                    index >= (currentPage - 1) * perPage &&
                    index < currentPage * perPage
                  ) {
                    return (
                      <TableRow
                        key={index}
                        sx={{
                          "& th, & td": { pl: 0.5, pt: 1, pb: 1 },
                        }}
                      >
                        <TableCell>
                          <a
                            href={`${
                              Number(item.ClientType) === 1
                                ? config.dealersocket
                                : config.clientPath
                            }/edit/${item.Keyid}`}
                          >
                            <Avatar
                              sx={{ width: 24, height: 24 }}
                              src={
                                item?.Logo
                                  ? `${config.domain}/${item?.Logo}`
                                  : `${config.domain}/assets/images/avatar.png`
                              }
                            />
                          </a>
                        </TableCell>
                        <TableCell>{item.Name}</TableCell>
                        <TableCell>
                          {Number(item.Ctype) === 1 ? (
                            <Chip label="Agency" color="primary" size="small" />
                          ) : Number(item.Ctype) === 2 ? (
                            <Chip
                              label="Dealer Group"
                              size="small"
                              sx={{ color: ColorsConfig.light.secondaryInfo }}
                            />
                          ) : Number(item.Ctype) === 3 ? (
                            <Chip
                              label="Dealership"
                              size="small"
                              sx={{
                                color: ColorsConfig.light.primaryTextColor,
                                backgroundColor:
                                  ColorsConfig.light.secondaryBgColor,
                              }}
                            />
                          ) : null}
                        </TableCell>
                        <TableCell>{item.City}</TableCell>
                        <TableCell>{item.State}</TableCell>
                        <TableCell>{item.Campaignnumber}</TableCell>
                        <TableCell>
                          {item.Lastcampaigndate === "0" ||
                          item.Lastcampaigndate === null ? (
                            "N/A"
                          ) : item.Lastcampaigndate === "1" ? (
                            <Chip
                              label="In progress"
                              size="small"
                              color="success"
                              sx={{
                                color: ColorsConfig.light.primaryTextColor,
                              }}
                            />
                          ) : (
                            `${TimeHelper.timeAgo(`${item.Lastcampaigndatearr[0]}-
                            ${item.Lastcampaigndatearr[1]}-${item.Lastcampaigndatearr[2]}`)}`
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  }
                })
              : null}
          </TableBody>
        </Table>
        <Box mt={2}>
          <TablePagination
            data={dataTableFilter(clients)}
            perPage={perPage}
            page={currentPage}
            onChangePage={setCurrentPage}
          />
        </Box>
      </Box>
      <ClientDeleteModal
        isOpenModal={!!clientDeleteModal}
        afterOpenModal={() => {}}
        closeModal={() => setClientDeleteModal("")}
        clientId={clientDeleteModal}
      />
    </Card>
  );
}
