import React, { useContext } from "react";
import { BsQuestionCircle } from "react-icons/bs";
import Modal from "react-modal";
import { AuthContext, VideoContext } from "../../context";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "560px",
    padding: "15px",
    marginTop: "20px",
    marginBottom: "20px",
  },
};

Modal.setAppElement("#root");

export default function VideoDeleteModal({
  isOpenModal = false,
  afterOpenModal,
  closeModal,
  data,
  reloadData,
}) {
  const { user } = useContext(AuthContext);
  const { deleteVideo } = useContext(VideoContext);

  const onDelete = async () => {
    console.log(">>> ddd >>>", data);
    if (!data) {
      return;
    }
    const res = await deleteVideo({ userId: user.Id, videoId: data.Id });
    if (res.status === "ok") {
      reloadData();
    }
  };

  return (
    <Modal
      isOpen={isOpenModal}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Delete Modal"
    >
      <div className="modal-header"></div>
      <div className="modal-body">
        <div className="d-flex flex-column align-items-center justify-content-center">
          <BsQuestionCircle size={52} color="#e57373" className="mt-3" />
          <h5 className="mt-3">Are you sure?</h5>
          <p>Are you sure you want to delete this video?</p>
        </div>
      </div>
      <div className="modal-footer mt-3">
        <button className="btn btn-outline-secondary me-2" onClick={closeModal}>
          Cancel
        </button>
        <button className="btn btn-primary" onClick={() => onDelete()}>
          OK
        </button>
      </div>
    </Modal>
  );
}
