/* eslint-disable react/no-unknown-property */
import ContentEditable from "react-contenteditable";
import { LiaCopy } from "react-icons/lia";
import SmartFieldsInput from "../ChatInputElements/SmartFieldsInput";
import { Switch } from "@mui/material";
import React, { useEffect, useContext, useState } from "react";
import { AuthContext, ClientContext } from "../../context";
import DropdownMultiSelect from "../select/dropdown_multi_select";

export default function ClientAISetupTab({ clientid }) {
  const { user } = useContext(AuthContext);
  const { getAiSetupApi, setAiApi } = useContext(ClientContext);
  const [postData, setPostData] = useState({
    prompt: "",
    cid: "",
    call_summarization: 0,
    call_rating: 0,
    AIautomation: 0,
    LeadsourceId: [],
    Leadsource: [],
    Dfmanageronly: 0,
  });
  const [leadsource, setLeadsource] = useState([]);

  useEffect(() => {
    getAiSetup(clientid);
  }, [clientid]);

  const getAiSetup = async (cid) => {
    const data = await getAiSetupApi({ userId: user.Id, cid: cid });
    if (data.status === "ok") {
      const res = data.response;
      setPostData({
        ...postData,
        cid: res?.cid,
        prompt: res?.AI_prompt,
        call_summarization: res?.Callsummarization ? res?.Callsummarization : 0,
        call_rating: res?.Callrating ? res?.Callrating : 0,
        AIautomation: res?.AIautomation ? res?.AIautomation : 0,
        LeadsourceId:
          Number(res?.AIautomation) === 0
            ? ""
            : res?.leadsource
                .filter((el) => Number(el.Isaiautomation) === 1)
                .map((item) => item.Id),
        Leadsource:
          Number(res?.AIautomation) === 0
            ? []
            : res?.leadsource.filter((el) => Number(el.Isaiautomation) === 1),
        Dfmanageronly: res?.Dfmanageronly ? res?.Dfmanageronly : 0,
      });
      setLeadsource(res?.leadsource);
    }
  };

  const onSave = async () => {
    const data = await setAiApi(postData);
    if (data.status === "ok") {
      getAiSetup(clientid);
    }
  };

  return (
    <>
      <h6 className="tab-tile">AI Setup</h6>
      <hr />
      <div className="row">
        <div className="col col-sm-12">
          <div className="form-group">
            <label className="mb-2">Prompt</label>
            <div className="form-contenteditable-wrapper">
              <button
                type="button"
                className="btn btn-sm btn-outline-secondary ms-auto btn-copy"
              >
                <LiaCopy />
              </button>
              <ContentEditable
                style={{ minHeight: "100px" }}
                className="form-textarea msg-editor"
                tagName="div"
                // placeholder={messageInput ? '' : placeholder}
                html={postData?.prompt}
                onChange={(e) =>
                  setPostData({ ...postData, prompt: e.target.value })
                }
              />
              <div className="d-flex align-items-center me-auto p-1">
                <SmartFieldsInput
                  onSelect={(field) =>
                    setPostData({
                      ...postData,
                      prompt: postData.prompt + field,
                    })
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col col-sm-4">
          <div className="form-group">
            <label className="mb-2 w-100">Call Summarization</label>
            <Switch
              value={postData?.call_summarization}
              onChange={(e) => {
                console.log(e);
                setPostData({
                  ...postData,
                  call_summarization: !postData.call_summarization,
                });
              }}
            />
          </div>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col col-sm-4">
          <div className="form-group">
            <label className="mb-2 w-100">Call Rating</label>
            <select
              className="form-select"
              value={postData?.call_rating}
              onChange={(e) =>
                setPostData({ ...postData, call_rating: e.target.value })
              }
            >
              <option value="0">No</option>
              <option value="1">Yes</option>
              <option value="2">DF Manager Only</option>
            </select>
          </div>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col col-sm-4">
          <div className="form-group">
            <label className="mb-2 w-100">Full AI (Carly AI)</label>
            <select
              className="form-select"
              value={postData?.AIautomation}
              onChange={(e) =>
                setPostData({ ...postData, AIautomation: e.target.value })
              }
            >
              <option value="0">No</option>
              <option value="1">Yes</option>
              <option value="2">After Hours Only</option>
            </select>
          </div>
          {Number(postData?.AIautomation) !== 0 && (
            <div className="form-group mt-2">
              <label className="mb-2">Lead Source</label>
              <DropdownMultiSelect
                className="w-100"
                options={leadsource}
                placeholder={"Select Leadsource"}
                labelField="Name"
                valueField="Id"
                values={postData.Leadsource}
                onChange={(values) =>
                  setPostData({
                    ...postData,
                    Leadsource: values,
                    LeadsourceId: values.map((item) => item.Id),
                  })
                }
                onDropdownClose={(values) => {
                  console.log(values);
                }}
              />
            </div>
          )}
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-12 d-flex">
          <button className="btn btn-primary mx-auto" onSave={() => onSave()}>
            Save
          </button>
        </div>
      </div>
    </>
  );
}
