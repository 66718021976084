import React from "react";
import "react-calendar/dist/Calendar.css";
import { SlBubbles, SlClock, SlFolder, SlUser } from "react-icons/sl";
import { IoCarSportOutline } from "react-icons/io5";

export default function CampaignPage() {
  return (
    <main className="container py-4 position-relative">
      <div className="d-flex align-items-center">
        <div className="section-title">
          <SlFolder className="icon me-2" />
          Campaigns
        </div>
      </div>
      <div className="row mt-4">
        <div className="col col-12 col-sm-12 col-md">
          <div className="h-stats card mb-2">
            <SlFolder
              className="icon mx-2"
              style={{ color: "#0051ff", fontSize: "28px" }}
            />
            <div className="d-flex flex-column justify-content-center ms-2">
              <h3>3153</h3>
              <span>Campaigns</span>
            </div>
          </div>
        </div>
        <div className="col col-12 col-sm-12 col-md">
          <div className="h-stats card mb-2">
            <SlUser
              className="icon mx-2"
              style={{ color: "#0051ff", fontSize: "28px" }}
            />
            <div className="d-flex flex-column justify-content-center ms-2">
              <h3>3153</h3>
              <span>Leads</span>
            </div>
          </div>
        </div>
        <div className="col col-12 col-sm-12 col-md">
          <div className="h-stats card mb-2">
            <SlBubbles
              className="icon mx-2"
              style={{ color: "#0051ff", fontSize: "28px" }}
            />
            <div className="d-flex flex-column justify-content-center ms-2">
              <h3>46%</h3>
              <span>Engagement</span>
            </div>
          </div>
        </div>
        <div className="col col-12 col-sm-12 col-md">
          <div className="h-stats card mb-2">
            <SlClock
              className="icon mx-2"
              style={{ color: "#0051ff", fontSize: "28px" }}
            />
            <div className="d-flex flex-column justify-content-center ms-2">
              <h3>3153</h3>
              <span>Appointments</span>
            </div>
          </div>
        </div>
        <div className="col col-12 col-sm-12 col-md">
          <div className="h-stats card mb-2">
            <IoCarSportOutline
              className="icon mx-2"
              style={{ color: "#0051ff", fontSize: "28px" }}
            />
            <div className="d-flex flex-column justify-content-center ms-2">
              <h3>315</h3>
              <span>Sold</span>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12">{/* <CampaignsActionDataTable /> */}</div>
      </div>
    </main>
  );
}
