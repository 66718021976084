import React, { useContext, useState, useRef } from "react";
import { BiMessageRoundedDetail } from "react-icons/bi";
import { AuthContext, ReportContext } from "../../context";
import { useEffect } from "react";
import { DateFilterMonthPicker } from "../../components";
import moment from "moment";
import { BsArrowDownLeft, BsArrowDownRight, BsDownload } from "react-icons/bs";
import { DownloadTableExcel } from "react-export-table-to-excel";

export default function MessagesReportPage() {
  const { user } = useContext(AuthContext);
  const { adminsmsreportApi, ajaxsmsreportApi } = useContext(ReportContext);
  const tableRef = useRef(null);
  //
  const [customColor, setCustomColor] = useState();
  //
  const [selectedDate, setSelectedDate] = useState("this_month");
  const [customDateRage, setCustomDateRange] = useState({
    start: new Date(moment().subtract(1, "months")),
    end: new Date(),
  });
  //
  const [tableData, setTableData] = useState(undefined); // object
  const [footerData, setFooterData] = useState({
    total_leads: 0,
    total_appts: 0,
    total_incoming_sms: 0,
    total_incoming_sms_segments: 0,
    total_incoming_sms_images: 0,
    total_incoming_sms_cost: 0,
    total_outgoing_sms: 0,
    total_outgoing_sms_segments: 0,
    total_outgoing_sms_images: 0,
    total_outgoing_sms_cost: 0,
    total_sms: 0,
    total_sms_segments: 0,
    total_sms_images: 0,
    total_sms_cost: 0,
    total_call_time: 0,
    total_call_cost: 0,
    total_facebook: 0,
  });

  useEffect(() => {
    getAdminSmsReportData();
    console.log(customColor);
  }, []);

  const getAdminSmsReportData = async () => {
    const data = await adminsmsreportApi({ userId: user.Id });
    if (data?.status === "ok") {
      const res = data.response;
      setCustomColor(res?.custom_color);
    }
  };

  useEffect(() => {
    if (selectedDate !== "custom") {
      ajaxSmsReportData();
    }
  }, [selectedDate]);

  useEffect(() => {
    if (selectedDate === "custom") {
      ajaxSmsReportData();
    }
  }, [customDateRage]);

  const ajaxSmsReportData = async () => {
    let _params = {
      userId: user.Id,
      filter: selectedDate,
    };
    if (selectedDate === "custom") {
      _params.start = customDateRage.start;
      _params.end = customDateRage.end;
    }
    const data = await ajaxsmsreportApi(_params);
    if (data?.status === "ok") {
      const res = data.response;
      setTableData(res?.data);
      setFooterData({
        total_leads: res?.total_leads,
        total_appts: res?.total_appts,
        total_incoming_sms: res?.total_incoming_sms,
        total_incoming_sms_segments: res?.total_incoming_sms_segments,
        total_incoming_sms_images: res?.total_incoming_sms_images,
        total_incoming_sms_cost: res?.total_incoming_sms_cost,
        total_outgoing_sms: res?.total_outgoing_sms,
        total_outgoing_sms_segments: res?.total_outgoing_sms_segments,
        total_outgoing_sms_images: res?.total_outgoing_sms_images,
        total_outgoing_sms_cost: res?.total_outgoing_sms_cost,
        total_sms: res?.total_sms,
        total_sms_segments: res?.total_sms_segments,
        total_sms_images: res?.total_sms_images,
        total_sms_cost: res?.total_sms_cost,
        total_call_time: res?.total_call_time,
        total_call_cost: res?.total_call_cost,
        total_facebook: res?.total_facebook,
      });
    }
  };

  return (
    <main className="container-fluid py-4">
      <div className="d-flex align-items-center">
        <div className="section-title">
          <BiMessageRoundedDetail className="icon me-2" />
          Messages Report
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between mt-3">
        <DateFilterMonthPicker
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          customDateRage={customDateRage}
          setCustomDateRange={setCustomDateRange}
        />
        <DownloadTableExcel
          filename="MessagesReport"
          sheet="messages"
          currentTableRef={tableRef.current}
        >
          <button className="btn btn-sm btn-primary">
            <div className="d-flex align-items-center">
              <BsDownload className="me-1" />
              Export
            </div>
          </button>
        </DownloadTableExcel>
      </div>
      <div id="dashboard-tbl-data" className="mt-2">
        <table
          id="messages_report"
          className="table table-hover"
          ref={tableRef}
        >
          <thead>
            <tr>
              <th>Agency</th>
              <th>Client</th>
              <th>Leads</th>
              <th>Appts</th>
              <th>
                <BsArrowDownLeft className="me-1" /> SMS
              </th>
              <th>
                <BsArrowDownLeft className="me-1" /> Segments
              </th>
              <th>
                <BsArrowDownLeft className="me-1" /> MMS
              </th>
              <th>
                <BsArrowDownLeft className="me-1" /> Cost
              </th>
              <th>
                <BsArrowDownRight className="me-1" /> SMS
              </th>
              <th>
                <BsArrowDownRight className="me-1" /> Segments
              </th>
              <th>
                <BsArrowDownRight className="me-1" /> MMS
              </th>
              <th>
                <BsArrowDownRight className="me-1" /> Cost
              </th>
              <th>SMS</th>
              <th>Segments</th>
              <th>MMS</th>
              <th>SMS Cost</th>
              <th>Call Time</th>
              <th>Call Cost</th>
              <th>FB</th>
            </tr>
          </thead>
          <tbody>
            {!!tableData &&
              Object.keys(tableData).map((key) =>
                Object.values(tableData[key]).map((value) => (
                  <tr key={key}>
                    <td>{key}</td>
                    <td>{value?.Client}</td>
                    <td>{value?.Leads}</td>
                    <td>{value?.Appts}</td>
                    <td data-order={value?.SMS?.Incoming}>
                      {value?.SMS?.Incoming}
                    </td>
                    <td data-order={value?.SMS?.IncomingSegments}>
                      {value?.SMS?.IncomingSegments}
                    </td>
                    <td data-order={value?.SMS?.IncomingImages}>
                      {value?.SMS?.IncomingImages}
                    </td>
                    <td data-order={value?.SMS?.IncomingCost}>
                      ${value?.SMS?.IncomingCost}
                    </td>
                    <td data-order={value?.SMS?.Outgoing}>
                      {value?.SMS?.Outgoing}
                    </td>
                    <td data-order={value?.SMS?.OutgoingSegments}>
                      {value?.SMS?.OutgoingSegments}
                    </td>
                    <td data-order={value?.SMS?.OutgoingImages}>
                      {value?.SMS?.OutgoingImages}
                    </td>
                    <td data-order={value?.SMS?.OutgoingCost}>
                      ${value?.SMS?.OutgoingCost}
                    </td>
                    <td
                      data-order={
                        Number(value?.SMS?.Incoming) +
                        Number(value?.SMS?.Outgoing)
                      }
                    >
                      {Number(value?.SMS?.Incoming) +
                        Number(value?.SMS?.Outgoing)}
                    </td>
                    <td
                      data-order={
                        Number(value?.SMS?.IncomingSegments) +
                        Number(value?.SMS?.OutgoingSegments)
                      }
                    >
                      {Number(value?.SMS?.IncomingSegments) +
                        Number(value?.SMS?.OutgoingSegments)}
                    </td>
                    <td
                      data-order={
                        Number(value?.SMS?.IncomingImages) +
                        Number(value?.SMS?.OutgoingImages)
                      }
                    >
                      {Number(value?.SMS?.IncomingImages) +
                        Number(value?.SMS?.OutgoingImages)}
                    </td>
                    <td
                      data-order={
                        Number(value?.SMS?.IncomingCost) +
                        Number(value?.SMS?.OutgoingCost)
                      }
                    >
                      $
                      {Number(value?.SMS?.IncomingCost) +
                        Number(value?.SMS?.OutgoingCost)}
                    </td>
                    <td>{value?.SMS?.CallTime}</td>
                    <td>${value?.SMS?.CallTimeCost}</td>
                    <td>
                      {Number(value?.Facebook?.Incoming || 0) +
                        Number(value?.Facebook?.Outgoing || 0)}
                    </td>
                  </tr>
                )),
              )}
          </tbody>
          <tfoot>
            <tr>
              <td></td>
              <td></td>
              <td>{footerData.total_leads}</td>
              <td>{footerData.total_appts}</td>
              <td>{footerData.total_incoming_sms}</td>
              <td>{footerData.total_incoming_sms_segments}</td>
              <td>{footerData.total_incoming_sms_images}</td>
              <td>${footerData.total_incoming_sms_cost}</td>
              <td>{footerData.total_outgoing_sms}</td>
              <td>{footerData.total_outgoing_sms_segments}</td>
              <td>{footerData.total_outgoing_sms_images}</td>
              <td>${footerData.total_outgoing_sms_cost}</td>
              <td>{footerData.total_sms}</td>
              <td>{footerData.total_sms_segments}</td>
              <td>{footerData.total_sms_images}</td>
              <td>${footerData.total_sms_cost}</td>
              <td>{footerData.total_call_time}</td>
              <td>${footerData.total_call_cost}</td>
              <td>{footerData.total_facebook}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </main>
  );
}
