import Modal from "react-modal";
import { FileUploader } from "react-drag-drop-files";
import React, { useEffect, useState } from "react";
import AWS from "aws-sdk";
import config from "../../config/config";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "560px",
    padding: "15px",
    marginTop: "20px",
    marginBottom: "20px",
  },
};

Modal.setAppElement("#root");

const fileTypes = ["JPG", "PNG", "GIF"];

const DragDropButton = ({ files }) => {
  return (
    <div className={`drag-wrapper${files.length > 0 ? " p-3" : ""}`}>
      {<p className="mt-4">Drop files here or click to upload.</p>}
    </div>
  );
};

export default function ConversationAttachImageModal({
  isOpenModal = false,
  afterOpenModal,
  closeModal,
  onAttached,
}) {
  const [files, setFiles] = useState([]);

  useEffect(() => {
    setFiles([]);
  }, [isOpenModal]);

  const handleChange = (newfiles) => {
    console.log(files, newfiles);
    setFiles((current) => [...current, ...Object.values(newfiles)]);
    // return;
  };

  const removeFile = (_file) => {
    let _files = files;
    _files = _files.filter((e) => e !== _file);
    setFiles(_files);
  };

  const onAttach = async () => {
    // set aws s3 bucket
    const s3BaseName = "https://dealerfunnel-videos.s3.amazonaws.com/";
    const bucket = new AWS.S3(config.awsConfig);
    if (files.length > 0) {
      console.log("attach start");
      let imageUrls = [];
      for (let i = 0; i < files.length; i++) {
        let file = files[i];
        let url = await new Promise((r) => {
          uploadFileToS3(file, bucket).on(
            "httpUploadProgress",
            function (progress) {
              let progressPercentage = Math.round(
                (progress.loaded / progress.total) * 100,
              );
              console.log("-- upload progress --", progressPercentage);
              if (progressPercentage >= 100) {
                // showDropzoneProgress(index, 100);
                // file.status = 'uploaded';
                // file.upload.progress = 100;
                // console.log('>>>>', `${s3BaseName}${progress.key}`);
                r(`${s3BaseName}${progress.key}`);
              } else {
                // showDropzoneProgress(index, progressPercentage);
              }
            },
          );
        });
        console.log("--- s3 urls ---", url);
        imageUrls.push(url);
      }
      console.log(">>> imageUrls >>>", imageUrls);
      onAttached(imageUrls);
    } else {
      alert("Please select image");
    }
  };

  const uploadFileToS3 = function (file, bucket) {
    let type = "";
    let name = "";
    let body = "";
    type = file.type;
    name = "web/" + Date.now().toString() + ".png";
    body = file;
    const params = {
      Bucket: "dealerfunnel-videos",
      Key: name,
      Body: body,
      ContentType: type,
      ACL: "public-read",
    };
    return bucket.upload(params, function (err, data) {
      return data.Location;
    });
  };

  return (
    <Modal
      isOpen={isOpenModal}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Select Image"
    >
      <div className="modal-header">
        <h4>Select Image</h4>
      </div>
      <div className="modal-body">
        <div
          className={`multiple-preview w-100${
            files.length > 0 ? "" : " d-none"
          }`}
        >
          <div className="row">
            {files.map((item, index) => (
              <div className="col-4 p-2" key={index}>
                <div className="preview">
                  <img
                    src={URL.createObjectURL(item)}
                    className="w-100"
                    alt=""
                  />
                  <a
                    className="preview-remove"
                    onClick={() => removeFile(item)}
                  >
                    Remove
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
        <FileUploader
          classes="w-100 mt-3"
          name="file"
          multiple={true}
          types={fileTypes}
          fileOrFiles={files}
          handleChange={handleChange}
          // eslint-disable-next-line react/no-children-prop
          children={<DragDropButton files={files} />}
        />
      </div>
      <div className="modal-footer mt-3">
        <button className="btn btn-outline-secondary me-2" onClick={closeModal}>
          Cancel
        </button>
        <button
          className="btn btn-primary"
          disabled={files.length === 0}
          onClick={() => onAttach()}
        >
          Confirm
        </button>
      </div>
    </Modal>
  );
}
