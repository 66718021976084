import React, { useEffect, useContext, useState } from "react";
import { ButtonGroup } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { IoBanOutline, IoImageOutline } from "react-icons/io5";
import {
  BsArrowRight,
  BsBookmark,
  BsCameraVideo,
  BsClock,
  BsShieldFillCheck,
  BsShieldFillX,
  BsTelephone,
  BsTelephonePlus,
} from "react-icons/bs";
import { BiPaperPlane, BiUserCircle } from "react-icons/bi";
import {
  HiOutlineClock,
  HiOutlineCpuChip,
  HiOutlineCurrencyDollar,
  HiOutlineRocketLaunch,
  HiOutlineUser,
} from "react-icons/hi2";
import {
  LiaDirectionsSolid,
  LiaEdit,
  LiaFunnelDollarSolid,
  LiaMoneyCheckAltSolid,
  LiaPhoneVolumeSolid,
  LiaTimesSolid,
} from "react-icons/lia";
import {
  ConversationAIErrorModal,
  ConversationAIRestrictedModal,
  ConversationAttachImageModal,
  ConversationSavedRepliesModal,
  ConversationScheduleMessageModal,
  ConversationSendVideoModal,
  EmojiPickerInput,
  FacebookComment,
  GifPickerInput,
  NoteBox,
  ScheduleMessage,
  SmartFieldsInput,
} from "../../components";
import { AuthContext, ConversationContext } from "../../context";
import moment from "moment";
import helper from "../../utils/helper";
import config from "../../config/config";
import { GrPause } from "react-icons/gr";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import ContentEditable from "react-contenteditable";
import axios from "axios";
import { toast } from "react-toastify";
import { useRef } from "react";

export default function ConversationsMessage1Box({
  leadId,
  filterParams,
  onNextLead,
}) {
  const { user } = useContext(AuthContext);
  const {
    getSmsMesssageApi,
    manualOptinApi,
    assignuserApi,
    generatingAiResponseApi,
    sendsmsApi,
    postnoteApi,
    sendfbmessageApi,
    setcarlysetingforleadApi,
    scheduleCsv,
    checkvaliddatetimeApi,
    setscheduleApi,
  } = useContext(ConversationContext);
  const msgEndRef = useRef(undefined);
  const [mainData, setMainData] = useState(null);
  const [canText, setCanText] = useState(0);
  const [isFbMessenger, setIsFbMessenger] = useState(0);
  //
  const [optinfo, setOptinfo] = useState(null);
  const [minfo, setMinfo] = useState(null);
  const [pinfo, setPinfo] = useState(null);
  const [leaddate, setLeaddate] = useState("");
  const [usertype, setUsertype] = useState("");
  const [assignedusername, setAssignedusername] = useState("");
  const [userlist, setUserlist] = useState([]);
  //
  const [comments, setComments] = useState([]);
  const [leadsource, setLeadsource] = useState(null);
  const [sms, setSms] = useState([]);
  const [inn, setInn] = useState(0);
  const [carlysetting, setCarlysetting] = useState(null);
  const [Ismobile, setIsmobile] = useState(null);
  const [carlyleadsetting, setCarlyleadsetting] = useState(null);
  //
  const [inputNote, setInputNote] = useState("");
  const [label, setLabel] = useState("");
  const [placeholder, setPlaceholder] = useState("");
  const [client, setClient] = useState(null);
  const [aisetting, setAisetting] = useState(null);
  //
  const [widgetTab, setWidgetTab] = useState("Note"); // Message, Facebook, Note
  const [messageInput, setMessageInput] = useState("");
  const [fbMessageInput, setFbMessageInput] = useState("");
  //
  const [openSendVideoModal, setOpenSendVideoModal] = useState(false);
  const [smsVideoImg, setSmsVideoImg] = useState("");
  const [videoGif, setVideoGif] = useState("");
  const [videoLinkData, setVideoLinkData] = useState("");
  const [fbVideoLinkData, setFbVideoLinkData] = useState("");
  //
  const [openAttachImageModal, setOpenAttachImageModal] = useState(false);
  const [smsImg, setSmsImg] = useState("");
  const [mpath, setMpath] = useState("");
  const [mpathMultiple, setMpathMultiple] = useState("");
  //
  const [carlyAi, setCarlyAi] = useState(true);
  const [giphykey, setGiphykey] = useState("");
  const [giphysize, setGiphysize] = useState("");
  //
  const [openSavedReplies, setOpenSavedReplies] = useState(false);
  const [openAiRestrictedModal, setOpenAiRestrictedModal] = useState(false);
  const [openAiErrorModal, setOpenAiErrorModal] = useState(false);
  const [openScheduleMessageModal, setOpenScheduleMessageModal] =
    useState(false);

  useEffect(() => {
    if (leadId) {
      console.log("leadId: ", leadId);
      getSmsMesssage(leadId);
      console.log(pinfo, inn, carlyleadsetting, filterParams);
      setLabel("");
    }
  }, [leadId]);

  const getSmsMesssage = async (_leadId) => {
    const data = await getSmsMesssageApi({
      userId: user.Id,
      lead: _leadId,
      sessionid: user.Sessionid,
    });
    if (data.status === "ok") {
      const res = data.response;
      setMainData({
        userId: user.Id,
        ajaxsleadId: res?.info.info.leadid,
        usermention: "",
        leadname: res?.leadname,
        clientAddress: res?.client.Address,
        clientCity: res?.client.City,
        clientState: res?.client.State,
        leadclientid: res?.clientid,
      });
      setCanText(res?.can_text);
      setIsFbMessenger(res?.is_fb_messenger);
      setOptinfo(res?.optinfo);
      setMinfo(res?.info.info);
      setPinfo(
        res?.info.info.tphone
          ? helper.filterphonenumber(res?.info.info.tphone)
          : {},
      );
      setLeaddate(res?.leaddate);
      setUsertype(res?.usertype);
      setAssignedusername(res?.assignedusername);
      setUserlist(res?.userlist);
      setClient(res?.client);
      setAisetting(res?.aisetting);
      //
      setComments(res?.comments.msg);
      setLeadsource(res?.leadsource);
      setSms(res?.info.msg);
      //
      setCarlysetting(res?.carlysetting);
      setIsmobile(res?.Ismobile);
      setCarlyleadsetting(res?.carlyleadsetting);
      if (res?.carlysetting && res?.Ismobile) {
        setCarlyAi(Number(res?.carlyleadsetting) === 1);
      } else {
        setCarlyAi(true);
      }
      setPlaceholder(res?.placeholder);
      // scroll to last message
      setTimeout(function () {
        scrollToBottom();
      }, 1000);
    }
  };

  useEffect(() => {
    if (canText && comments.length === 0) {
      setWidgetTab("Message");
    } else if (isFbMessenger) {
      setWidgetTab("Facebook");
    } else {
      setWidgetTab("Note");
    }
  }, [canText, comments, isFbMessenger]);

  const scrollToBottom = () => {
    msgEndRef?.current?.scrollIntoView({ behavior: "smooth" });
  };

  const ai = async () => {
    let body = messageInput;
    if (widgetTab === "Facebook") {
      body = fbMessageInput;
    }
    let lastmessage = Number(aisetting?.is_incomming_sms);
    //
    if (body?.length === 0 && lastmessage === 0) {
      setOpenAiRestrictedModal(true);
    } else {
      let params = {
        userId: user.Id,
        user_prompt: body,
        lead_id: mainData?.ajaxsleadId,
      };
      const data = await generatingAiResponseApi(params);
      if (data.status === "ok") {
        const res = data.response;
        if (Number(res.look) === 0) {
          setOpenAiErrorModal(true);
        } else {
          if (widgetTab === "Message") {
            setMessageInput(res.text);
          }
          if (widgetTab === "Facebook") {
            setFbMessageInput(res.text);
          }
        }
      }
    }
  };

  const manual_optin = async () => {
    const data = await manualOptinApi({
      userId: user.Id,
      leadid: mainData?.ajaxsleadId,
    });
    if (data.status === "ok") {
      getSmsMesssage(leadId);
    }
  };

  const conversationAssign = async (item) => {
    const data = await assignuserApi({
      loginUserId: user.Id,
      userid: item.Id,
      lead: leadId,
    });
    if (data.status === "ok") {
      getSmsMesssage(leadId);
    }
  };

  const downloadcallurl = (itemCallId) => {
    let url = `${config.exportPath}/downloadmedia/${itemCallId}`;
    window.location.href = url;
  };

  const senddirection = () => {
    let url = creategooglemap();
    if (widgetTab === "Facebook") {
      setFbMessageInput(url);
    } else {
      setMessageInput(url);
    }
  };

  const creategooglemap = () => {
    let address = mainData?.clientAddress.trim();
    let n = address?.split(" ");
    let nj = n.join("%2B");
    let city = mainData?.clientCity.trim();
    n = city?.split(" ");
    city = n.join("%2B");
    nj = nj + "%2B" + city + "%2B" + mainData?.clientState;
    let url =
      "Here are your turn by turn directions to our " +
      " dealership, [fname]: https://www.google.com/maps/dir//" +
      nj;
    return url;
  };

  const sendcreditapp = () => {
    if (client?.CreditAppURL) {
      setMessageInput(
        `[fname], here is a secure link to your credit application: ${client.CreditAppURL}`,
      );
    } else {
      setMessageInput(
        `[fname], here is a secure link to your credit application: [appid]`,
      );
    }
  };

  const videoCopyLink = async (url, gifUrl) => {
    setOpenSendVideoModal(false);
    setVideoGif(gifUrl);
    showGifImage(gifUrl);
    if (url.includes("amazonaws.com")) {
      let videoUrl = url;
      const result = await axios.get(
        `https://api-ssl.bitly.com/v4/shorten`,
        {
          long_url: url,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + config.bitlyAccessToken,
          },
        },
      );
      if (result.status === 200) {
        videoUrl = result.data.link;
      }
      processVideoLink(videoUrl);
    } else {
      processVideoLink(url);
    }
  };

  const showGifImage = async (gifUrl) => {
    let gifImageLink = gifUrl;
    if (gifUrl.includes("dfvid.com")) {
      gifUrl = gifUrl.replace("https://", "");

      const result = await axios.get(
        `https://api-ssl.bitly.com/v4/bitlinks/${gifUrl}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + config.bitlyAccessToken,
          },
        },
      );
      if (result.status === 200) {
        gifImageLink = result.data.long_url;
      }
      setSmsVideoImg(gifImageLink);
    } else {
      setSmsVideoImg(gifImageLink);
    }
  };

  const processVideoLink = (url) => {
    let videoHelloMessage = `${
      mainData.leadname?.split(" ")[0]
    }, please take a look at this video. `;
    let newVideoLinkData = `${videoHelloMessage}${url}`;

    if (widgetTab === "Message") {
      let htmlData = messageInput;
      if (htmlData && videoLinkData && htmlData.includes(videoLinkData)) {
        htmlData = htmlData.replace(videoLinkData, newVideoLinkData);
      } else {
        htmlData += `${newVideoLinkData}`;
      }
      setVideoLinkData(newVideoLinkData);
      setMessageInput(htmlData);
    } else {
      let htmlData = fbMessageInput;
      if (htmlData && fbVideoLinkData && htmlData.includes(fbVideoLinkData)) {
        htmlData = htmlData.replace(fbVideoLinkData, newVideoLinkData);
      } else {
        htmlData += `${newVideoLinkData}`;
      }
      setFbVideoLinkData(newVideoLinkData);
      setFbMessageInput(htmlData);
    }
  };

  const cancelVideoAttach = () => {
    setVideoGif("");
    setSmsVideoImg("");
    if (widgetTab === "Message") {
      let htmlData = messageInput;
      htmlData = htmlData.replace(videoLinkData, "");
      setVideoLinkData("");
      setMessageInput(htmlData);
    } else {
      let htmlData = fbMessageInput;
      htmlData = htmlData.replace(fbVideoLinkData, "");
      setFbVideoLinkData("");
      setFbMessageInput(htmlData);
    }
  };

  const onAttached = (imgUrls) => {
    console.log("== imgUrls ==", imgUrls);
    if (imgUrls.length > 0) {
      setSmsImg(imgUrls[0]);
      setMpath(imgUrls[0]);
      setMpathMultiple(imgUrls.toString());
      setOpenAttachImageModal(false);
    }
  };

  const cancelImageAttach = () => {
    setSmsImg("");
    setMpath("");
    setMpathMultiple("");
  };

  const sendmessage = async () => {
    // removeliveactivity();
    let params = {
      userId: user.Id,
      lead: mainData?.ajaxsleadId,
      body: messageInput,
      path: mpath,
      giphy: giphykey,
      size: giphysize,
      videoGif: videoGif,
      path_multiple: mpathMultiple,
    };
    setMessageInput("");
    setFbMessageInput("");
    setMpath("");
    setGiphykey("");
    setGiphysize("");
    setVideoGif("");
    setMpathMultiple("");
    if (
      params.body.length == 0 &&
      params.path.length == 0 &&
      params.giphy.length == 0
    ) {
      alert("Sms input Box is empty");
    } else {
      const data = await sendsmsApi(params);
      if (data.status === "ok") {
        const res = data.response;
        if (Number(res.lookup) === 0) {
          alert("Lead Phone has no ability to receive Sms");
        } else {
          if (Number(res.isassign) === 1) {
            getSmsMesssage(leadId);
          }
          toast.info("Sms sent");
        }
      }
    }
    // updateleadlock();
  };

  const sendpostnote = async () => {
    //setlocklead();
    let params = {
      userId: user.Id,
      note: inputNote,
      lead: mainData?.ajaxsleadId,
      mention: mainData?.usermention,
    };
    const data = await postnoteApi(params);
    if (data.status === "ok") {
      getSmsMesssage(leadId);
    }
    // updateleadlock();
  };

  const sendfbmessage = async () => {
    // removeliveactivity();
    let params = {
      userId: user.Id,
      lead: mainData?.ajaxsleadId,
      body: fbMessageInput,
      path: mpath,
      giphy: giphykey,
      // size:giphysize,
      // videoGif:videoGif,
      // path_multiple: mpathMultiple,
    };
    setMessageInput("");
    setFbMessageInput("");
    setMpath("");
    setGiphykey("");
    setGiphysize("");
    // setVideoGif('');
    // setMpathMultiple('');
    if (
      params.body.length == 0 &&
      params.path.length == 0 &&
      params.giphy.length == 0
    ) {
      alert("FB input box is empty");
    } else {
      const data = await sendfbmessageApi(params);
      if (data.status === "ok") {
        const res = data.response;
        if (Number(res.isassign) === 1) {
          getSmsMesssage(leadId);
        }
        toast.info("FB Message sent");
        if (Number(res.error) === 1) {
          getSmsMesssage(leadId);
        }
      }
    }
    // updateleadlock();
  };

  const toggleCarlyAi = async () => {
    let params = {
      userId: user.Id,
      lead: leadId,
      flag: !carlyAi,
    };
    setCarlyAi(!carlyAi);
    await setcarlysetingforleadApi(params);
  };

  const send_schedule_message = async (_params) => {
    let params = {};
    params.date = _params.date;
    params.time = moment(_params.time).format("MM/DD/YYYY");
    params.lead = mainData.ajaxsleadId;
    params.body = messageInput;
    params.fbbody = fbMessageInput;
    params.path = mpath;
    params.path_multiple = mpathMultiple;
    params.giphy = giphykey;
    params.schedule_csv = scheduleCsv;
    const data = await checkvaliddatetimeApi(params);
    if (data.status === "ok") {
      const res = data.response;
      if (res.valid) {
        setschedulemessage(params);
      } else {
        toast.info("Valid date/time required");
      }
    }
  };

  const setschedulemessage = async (_params) => {
    let params = _params;
    params.ismultiple = 0;
    params.userId = user.Id;
    params.loginName = user.Name;
    params.loginImage = user.Image;
    setMessageInput("");
    setFbMessageInput("");
    setMpath("");
    setMpathMultiple("");
    setGiphykey("");
    setGiphysize("");
    setSmsVideoImg("");
    setSmsImg("");
    const data = await setscheduleApi(params);
    if (data.status === "ok") {
      getSmsMesssage(leadId);
      setOpenScheduleMessageModal(false);
      // updateleadlock();
    }
  };

  {
    if (!mainData) {
      return <></>;
    }
  }

  return (
    // <>
    //   {/* <div dangerouslySetInnerHTML={{__html: output}}></div> */}
    // </>
    <div className="message1-box bg-white">
      <div className="sms-header">
        <div className="sms-detail-meta clearfix">
          {Number(optinfo?.look) === 0 ? (
            <small className="me-auto">
              <BsShieldFillCheck color="#94E864" className="me-1" />
              Opted-in on{" "}
              {moment(new Date(leaddate * 1000)).format("MM/DD/YYYY")}
            </small>
          ) : Number(optinfo?.look) === 1 ? (
            <small className="me-auto">
              <BsShieldFillX color="#ff0000" className="me-1" />
              Opted-out on{" "}
              {moment(new Date(optinfo?.optedout * 1000)).format("MM/DD/YYYY")}
            </small>
          ) : (
            <>
              <small className="me-auto">Awaiting Opt-In</small>
              {(Number(usertype) < 2 || Number(usertype) === 5) && (
                <button
                  onClick={() => manual_optin()}
                  className="btn btn-sm btn-primary btn-manual-optin px-2 py-0"
                >
                  Manual Opt-In
                </button>
              )}
            </>
          )}
          <DropdownButton
            className="ms-auto"
            as={ButtonGroup}
            align={{ lg: "end" }}
            variant="default"
            title={<>{assignedusername}</>}
          >
            {!!userlist &&
              userlist.map((item, index) => (
                <Dropdown.Item
                  eventKey={index}
                  key={index}
                  onClick={() => conversationAssign(item)}
                >
                  {item.Name}
                </Dropdown.Item>
              ))}
          </DropdownButton>
        </div>
      </div>
      <div className="widget-chat">
        <div className="sms-slimscroll">
          {comments.length > 0 && (
            <div className="message-activity">
              <div className="message-activity-content">
                <span className="leadsource_img">
                  <BiUserCircle />
                </span>
                Lead created from{" "}
                {Number(leadsource?.look) === 1
                  ? leadsource?.Name
                  : "Facebook Comment"}
              </div>
              <span className="time">
                {moment(new Date(comments[0].kstime)).format(
                  "MM/DD/YYYY @ hh:mm A",
                )}
              </span>
            </div>
          )}
          <FacebookComment
            comments={comments}
            sms={sms}
            lead={mainData?.ajaxsleadId}
            usermention={mainData?.usermention}
            leadname={mainData?.leadname}
          />
          {sms.length > 0 &&
            sms.map((item, index) => {
              if (Number(item.type) === 1) {
                if (
                  Number(item.Messagetype) === 1 ||
                  Number(item.Messagetype) === 3
                ) {
                  if (Number(item.Schedulestatus) !== 1) {
                    return (
                      <div
                        key={index}
                        className={`message${
                          Number(item.Direction) === 1
                            ? item.AuthorId === -2
                              ? " carlyai"
                              : ""
                            : " msg-left"
                        }`}
                      >
                        <div className="message-ct">
                          {Number(item.FormateType) === 1 ? (
                            <div className="sender-text">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: helper.nl2br(
                                    helper.makeUrltoLink(item.Body).msg,
                                  ),
                                }}
                              ></div>
                              {!!item.giphy && (
                                <div className="sender-img">
                                  <img
                                    src="https://i.giphy.com/
                                  media/<?=$sms[$i]['giphy']?>/giphy.gif"
                                  />
                                </div>
                              )}
                              {item.Medias
                                ? item.Medias?.split(",").map((md, mi) => (
                                    <div className="sender-img" key={mi}>
                                      <a data-fancybox="gallery" href={md}>
                                        <img
                                          width="100"
                                          height="100"
                                          src={md}
                                          className="mt-2"
                                        />
                                      </a>
                                    </div>
                                  ))
                                : item.media
                                ? item.media?.split(",").map((md, mi) => (
                                    <div className="sender-img" key={mi}>
                                      <a data-fancybox="gallery" href={md}>
                                        <img
                                          width="100"
                                          height="100"
                                          src={md}
                                        />
                                      </a>
                                    </div>
                                  ))
                                : null}
                              {item.Default_language !== "en" ? (
                                <div className="translated-text">
                                  {item.Direction === 2 ||
                                  item.AuthorId === -2 ? (
                                    <span>
                                      <img
                                        src={`${config.assetPath}/images/translate.png`}
                                      />{" "}
                                      Translated
                                    </span>
                                  ) : (
                                    <span>
                                      <img
                                        src={`${config.assetPath}/images/translate-w.png`}
                                      />{" "}
                                      Translated
                                    </span>
                                  )}
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: helper.nl2br(item.Orginal_body),
                                    }}
                                  ></div>
                                </div>
                              ) : null}
                            </div>
                          ) : (
                            <div className="sender-text">
                              {item.giphy ? (
                                <div className="sender-img">
                                  <img
                                    src={`https://i.giphy.com/media/${item.giphy}/giphy.gif`}
                                  />
                                </div>
                              ) : item.media ? (
                                item.media.split(",").map((md, mi) => (
                                  <div className="sender-img" key={mi}>
                                    <a data-fancybox="gallery" href={md}>
                                      <img width="100" height="100" src={md} />
                                    </a>
                                  </div>
                                ))
                              ) : null}
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: helper.nl2br(item.Body),
                                }}
                              ></div>
                              {item.Default_language !== "en" ? (
                                <div className="translated-text">
                                  {item.Direction === 2 ||
                                  item.AuthorId === -2 ? (
                                    <span>
                                      <img
                                        src={`${config.assetPath}/images/translate.png`}
                                      />{" "}
                                      Translated
                                    </span>
                                  ) : (
                                    <span>
                                      <img
                                        src={`${config.assetPath}/images/translate-w.png`}
                                      />{" "}
                                      Translated
                                    </span>
                                  )}
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: helper.nl2br(item.Orginal_body),
                                    }}
                                  ></div>
                                </div>
                              ) : null}
                            </div>
                          )}
                          <div className="sender-meta clearfix">
                            <span
                              className={`pimage${
                                item.AuthorId === 0 || item.AuthorId === -2
                                  ? " message-bot"
                                  : ""
                              }`}
                            >
                              {item.AuthorId === 0 ? (
                                <i className="fa-thin fa-message-bot"></i>
                              ) : item.AuthorId === -2 ? (
                                <i className="fa-light fa-microchip-ai"></i>
                              ) : (
                                <img src={item.pimage} alt="" />
                              )}
                            </span>
                            <a href="#" title="" className="sender-name">
                              {item.aname}
                            </a>
                            <span className="sender-time">
                              {item.Messagetype === 3 ? (
                                <img
                                  className="m-icon"
                                  src={`${config.assetPath}/images/messenger.webp`}
                                  alt=""
                                />
                              ) : (
                                <img
                                  className="m-icon"
                                  src={`${config.assetPath}/images/sms.svg`}
                                  alt=""
                                />
                              )}
                              <abbr className="timeago" title={item.Stime}>
                                {item.Stime}
                              </abbr>
                            </span>
                            <a href="javascript:void(0)" className="reacted">
                              <span className="reacted_icon"></span>
                              <span className="reacted_total"></span>
                            </a>
                          </div>
                          <a className="like-btn face-laugh-icon">
                            <i className="fa-regular fa-face-laugh"></i>
                            <div className="reaction-box">
                              <div className="reaction-icon">
                                <span className="reaction_icon">😀</span>
                                <label>Smile</label>
                              </div>
                              <div className="reaction-icon">
                                <span className="reaction_icon">😠</span>
                                <label>Angry</label>
                              </div>
                              <div className="reaction-icon">
                                <span className="reaction_icon">😢</span>
                                <label>Sad</label>
                              </div>
                              <div className="reaction-icon">
                                <span className="reaction_icon">😮</span>
                                <label>Wow</label>
                              </div>
                              <div className="reaction-icon">
                                <span className="reaction_icon">😍</span>
                                <label>Love</label>
                              </div>
                              <div className="reaction-icon">
                                <span className="reaction_icon">👍</span>
                                <label>Like</label>
                              </div>
                              <div className="reaction-icon">
                                <span className="reaction_icon">👎</span>
                                <label>Dislike</label>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                    );
                  } else if (Number(item.Schedulestatus) === 1) {
                    return (
                      <ScheduleMessage
                        key={index}
                        item={item}
                        reloadData={() => getSmsMesssage(leadId)}
                      />
                    );
                  }
                } else if (Number(item.Messagetype) === 2) {
                  return <NoteBox item={item} key={index} />;
                } else if (Number(item.Messagetype) === 4) {
                  return (
                    <div key={index} id="lead_escalated">
                      <span className="lead_escalated_sender_name">
                        <i
                          className="fa-solid fa-triangle-exclamation"
                          aria-hidden="true"
                        ></i>{" "}
                        Lead Escalated
                      </span>
                      <span className="lead_escalated_time">
                        &nbsp;on&nbsp;
                        {moment(new Date(item.kstime * 1000)).format(
                          "MMMM DD, h:mm A",
                        )}
                      </span>
                      <div className="lead_escalated_text">
                        This lead has been escalated to the following: &nbsp;
                        {item.Escalated_email?.split(",").join(" ")}
                      </div>
                    </div>
                  );
                } else if (Number(item.Messagetype) === 51) {
                  return (
                    <div key={index} className="message-activity">
                      <div className="message-activity-content">
                        <span>
                          <span>
                            <GrPause className="leadsource_img p-2" />
                          </span>
                        </span>
                        {item.Escalated_email} {item.Sourcename}
                      </div>
                      <span className="time">
                        {moment(new Date(item.kstime * 1000)).format(
                          "MM/DD/YYYY, h:mm A",
                        )}
                      </span>
                    </div>
                  );
                } else if (Number(item.Messagetype) === 5) {
                  return (
                    <div key={index} className="message-activity">
                      <div className="message-activity-content">
                        <span>
                          <span>
                            <LiaFunnelDollarSolid className="leadsource_img p-2" />
                          </span>
                        </span>
                        {item.Messagesource === 0 ? (
                          <>
                            {item.Escalated_email} {item.Sourcename}
                          </>
                        ) : item.Sourcename === "assigned" ? (
                          <>
                            {item.aname} assigned this lead to{" "}
                            {item.Escalated_email}.
                          </>
                        ) : item.Sourcename === "paused due to reply" ? (
                          <>
                            {item.Escalated_email} {item.Sourcename}.
                          </>
                        ) : (
                          <>
                            {item.aname} {item.Sourcename} this lead.
                          </>
                        )}
                      </div>
                      <span className="time">
                        {moment(new Date(item.kstime * 1000)).format(
                          "MM/DD/YYYY @ h:mm A",
                        )}
                      </span>
                    </div>
                  );
                } else if (Number(item.Messagetype) === 76) {
                  return (
                    <div key={index} className="message-activity">
                      <div className="message-activity-content">
                        <span>
                          <span>
                            <GrPause className="leadsource_img p-2" />
                          </span>
                        </span>
                        This number can not receive text messages
                      </div>
                      <span className="time">
                        {moment(new Date(item.kstime * 1000)).format(
                          "MM/DD/YYYY @ h:mm A",
                        )}
                      </span>
                    </div>
                  );
                } else if (Number(item.Messagetype) === 52) {
                  return (
                    <div key={index} className="message-activity">
                      <div className="message-activity-content">
                        <span>
                          <span>
                            <LiaFunnelDollarSolid className="leadsource_img p-2" />
                          </span>
                        </span>
                        {item.group.map((gp) => (
                          <>
                            <div dangerouslySetInnerHTML={{ __html: gp }}></div>
                            <hr />
                          </>
                        ))}
                      </div>
                      <span className="time">
                        {moment(new Date(item.kstime * 1000)).format(
                          "MM/DD/YYYY",
                        )}
                      </span>
                    </div>
                  );
                } else if (Number(item.Messagetype) === 6) {
                  return (
                    <div key={index} className="funnel-msg">
                      {Number(item.Escalated_email?.split(",")[1]) === 0 ? (
                        <div>
                          {item.Escalated_email?.split(",")[0] === user.Id
                            ? "You"
                            : ""}{" "}
                          unassigned this conversation on
                          <span className="assign-time">
                            {moment(new Date(item.kstime * 1000)).format(
                              "MM DD, h:mm A",
                            )}
                          </span>
                        </div>
                      ) : (
                        <div className="funnel-msg"></div>
                      )}
                    </div>
                  );
                } else if (Number(item.Messagetype) === 74) {
                  return <div key={index} className="funnel-msg"></div>;
                } else if (Number(item.Messagetype) === 7) {
                  return <></>;
                } else if (Number(item.Messagetype) === 61) {
                  return <></>;
                } else if (
                  Number(item.Messagetype) === 20 &&
                  comments.length === 0
                ) {
                  return (
                    <div key={index} className="message-activity">
                      <div className="message-activity-content">
                        {Number(leadsource.look) === 1 ? (
                          <span>
                            <span>
                              <img
                                className="leadsource_img p-2"
                                src={leadsource.Logo}
                                alt=""
                              />
                            </span>
                          </span>
                        ) : (
                          <span>
                            <span>
                              <img
                                className="leadsource_img p-2"
                                src="https://facebookbrand.com/wp-content
                                /uploads/2019/04/f_logo_RGB-Hex-Blue_512.png?w=512&amp;h=512"
                                alt=""
                              />
                            </span>
                          </span>
                        )}
                        {Number(leadsource.look) === 1
                          ? `Lead created from ${leadsource.Name}`
                          : `Lead created from Facebook`}
                      </div>
                      <span className="time">
                        {moment(new Date(item.kstime * 1000)).format(
                          "MM/DD/YYYY, h:mm A",
                        )}
                      </span>
                    </div>
                  );
                } else if (
                  Number(item.Messagetype) === 21 &&
                  comments.length === 0
                ) {
                  return (
                    <div key={index} className="message-activity">
                      <div className="message-activity-content">
                        <span>
                          <span>
                            <HiOutlineUser className="leadsource_img p-2" />
                          </span>
                        </span>
                        Lead created from Direct Mail
                      </div>
                      <span className="time">
                        {moment(new Date(item.kstime * 1000)).format(
                          "MM/DD/YYYY, h:mm A",
                        )}
                      </span>
                    </div>
                  );
                } else if (
                  Number(item.Messagetype) === 30 &&
                  comments.length === 0
                ) {
                  return (
                    <div key={index} className="message-activity">
                      <div className="message-activity-content">
                        <span>
                          <span>
                            <HiOutlineUser className="leadsource_img p-2" />
                          </span>
                        </span>
                        Lead created from Website
                      </div>
                      <span className="time">
                        {moment(new Date(item.kstime * 1000)).format(
                          "MM/DD/YYYY, h:mm A",
                        )}
                      </span>
                    </div>
                  );
                } else if (Number(item.Messagetype) === 22) {
                  return (
                    <div key={index} className="message-activity">
                      <div className="message-activity-content">
                        <span>
                          <span>
                            <IoBanOutline className="leadsource_img p-2 text-danger" />
                          </span>
                        </span>
                        {item.aname} has opted-out of messaging.
                      </div>
                      <span className="time">
                        {moment(new Date(item.kstime * 1000)).format(
                          "MM/DD/YYYY, h:mm A",
                        )}
                      </span>
                    </div>
                  );
                } else if (Number(item.Messagetype) === 26) {
                  return (
                    <div key={index} className="message-activity">
                      <div className="message-activity-content">
                        <span>
                          <span>
                            <RoomOutlinedIcon className="leadsource_img p-2 text-danger" />
                          </span>
                        </span>
                        {item.aname} visited the store.
                      </div>
                      <span className="time">
                        {moment(new Date(item.kstime * 1000)).format(
                          "MM/DD/YYYY, h:mm A",
                        )}
                      </span>
                    </div>
                  );
                } else if (Number(item.Messagetype) === 27) {
                  return (
                    <div key={index} className="message-activity">
                      <div className="message-activity-content">
                        <span>
                          <span>
                            <HiOutlineClock className="leadsource_img p-2" />
                          </span>
                        </span>
                        {item.aname} set an in person appointment.
                      </div>
                      <span className="time">
                        {moment(new Date(item.kstime * 1000)).format(
                          "MM/DD/YYYY, h:mm A",
                        )}
                      </span>
                    </div>
                  );
                } else if (Number(item.Messagetype) === 72) {
                  return (
                    <div key={index} className="message-activity">
                      <div className="message-activity-content">
                        <span>
                          <span>
                            <HiOutlineClock className="leadsource_img p-2" />
                          </span>
                        </span>
                        {item.aname} set a phone appointment.
                      </div>
                      <span className="time">
                        {moment(new Date(item.kstime * 1000)).format(
                          "MM/DD/YYYY, h:mm A",
                        )}
                      </span>
                    </div>
                  );
                } else if (Number(item.Messagetype) === 80) {
                  return (
                    <div key={index} className="funnel-msg">
                      <div>
                        Hot Lead sent by {item.Escalated_email} on
                        <span className="assign-time">
                          {moment(new Date(item.kstime * 1000)).format(
                            "MM DD, h:mm A",
                          )}
                        </span>
                      </div>
                    </div>
                  );
                } else if (Number(item.Messagetype) === 75) {
                  return (
                    <div key={index} className="message-activity">
                      <div className="funnel-msg">
                        <div>
                          <i className="fa-light fa-microchip-ai"></i> Carly AI
                          detected this message category is{" "}
                          {item.Escalated_email} and should be answered by a
                          human.
                        </div>
                      </div>
                      <span className="time">
                        {moment(new Date(item.kstime * 1000)).format(
                          "MM/DD/YYYY, h:mm A",
                        )}
                      </span>
                    </div>
                  );
                } else if (Number(item.Messagetype) === 25) {
                  return (
                    <div key={index} className="message-activity">
                      <div className="message-activity-content">
                        <span>
                          <span>
                            <LiaMoneyCheckAltSolid className="leadsource_img p-2" />
                          </span>
                        </span>
                        {item.aname} submitted a credit application.
                      </div>
                      <span className="time">
                        {moment(new Date(item.kstime * 1000)).format(
                          "MM/DD/YYYY, h:mm A",
                        )}
                      </span>
                    </div>
                  );
                } else if (Number(item.Messagetype) === 33) {
                  return (
                    <div key={index} className="message-activity">
                      <div className="message-activity-content">
                        <span>
                          <span>
                            <LiaMoneyCheckAltSolid className="leadsource_img p-2" />
                          </span>
                        </span>
                        {item.Body}
                      </div>
                      <span className="time">
                        {moment(new Date(item.kstime * 1000)).format(
                          "MM/DD/YYYY, h:mm A",
                        )}
                      </span>
                    </div>
                  );
                } else if (Number(item.Messagetype) === 28) {
                  return <></>;
                } else if (Number(item.Messagetype) === 23) {
                  setInn((current) => current + 1);
                  return (
                    <div key={index} className="message-activity">
                      <div className="message-activity-content">
                        <span>
                          <span>
                            <BsTelephonePlus className="leadsource_img p-2" />
                          </span>
                        </span>
                        {item.aname} called in.
                        <div className="wavesurfer-wrap">
                          <div className="wavesurfer-timer">
                            <i
                              className="fal fa-play 
                            wavesurfer-btn wavesurfer-btn<?=$in?>"
                            ></i>
                            <span id="waveform_time_<?=$in?>">00:00</span>
                            <div id="waveform<?=$in?>"></div>
                            <span id="waveform_time_<?=$in?>_end">00:00</span>
                            <a
                              onClick={() => downloadcallurl(item.callid)}
                              className="audio-download"
                              href="javascript:void(0)"
                            >
                              <i className="fal fa-arrow-alt-to-bottom"></i>
                            </a>
                          </div>
                        </div>
                        {item.summary !== "" ? <NoteBox item={item} /> : null}
                      </div>
                      <span className="time">
                        {moment(new Date(item.kstime * 1000)).format(
                          "MM/DD/YYYY, h:mm A",
                        )}
                      </span>
                    </div>
                  );
                } else if (Number(item.Messagetype) === 24) {
                  setInn((current) => current + 1);
                  return (
                    <div key={index} className="message-activity">
                      <div className="message-activity-content">
                        <span>
                          <span>
                            <LiaPhoneVolumeSolid className="leadsource_img p-2" />
                          </span>
                        </span>
                        {item.agent} called {item.aname}.
                        <div className="wavesurfer-wrap">
                          <div className="wavesurfer-timer">
                            <i
                              className="fal fa-play 
                            wavesurfer-btn wavesurfer-btn<?=$in?>"
                            ></i>
                            <span id="waveform_time_<?=$in?>">00:00</span>
                            <div id="waveform<?=$in?>"></div>
                            <span id="waveform_time_<?=$in?>_end">00:00</span>
                            <a
                              onClick={() => downloadcallurl(item.callid)}
                              className="audio-download"
                              href="javascript:void(0)"
                            >
                              <i className="fal fa-arrow-alt-to-bottom"></i>
                            </a>
                          </div>
                        </div>
                        {item.summary !== "" ? <NoteBox item={item} /> : null}
                      </div>
                      <span className="time">
                        {moment(new Date(item.kstime * 1000)).format(
                          "MM/DD/YYYY, h:mm A",
                        )}
                      </span>
                    </div>
                  );
                }
              } else {
                return (
                  <div key={index} className="time-divider">
                    <span>
                      {moment(new Date(item.kstimes)).format("dddd, MMMM DD")}
                    </span>
                  </div>
                );
              }
              //
              if (Number(item.Messagesource) === 1) {
                return (
                  <span key={index} className="blast_msg">
                    <HiOutlineRocketLaunch /> {item.Sourcename}
                  </span>
                );
              } else if (Number(item.Messagesource) === 2) {
                return (
                  <span key={index} className="blast_msg">
                    <HiOutlineRocketLaunch /> {item.Sourcename}
                  </span>
                );
              }
              //
              if (Number(item.Messagetype) === 29) {
                return (
                  <div key={index} className="message-activity">
                    <div className="message-activity-content">
                      <span>
                        <span>
                          <BiPaperPlane className="leadsource_img p-2" />
                        </span>
                      </span>
                      Lead sent to CRM via ADF ({item.Sourcename}) by{" "}
                      {item.aname}
                    </div>
                    <span className="time">
                      {moment(new Date(item.kstime * 1000)).format(
                        "MM/DD/YYYY @ hh:mm A",
                      )}
                    </span>
                  </div>
                );
              }
              //
              if (Number(item.Messagetype) === 31) {
                return (
                  <div key={index} className="message-activity">
                    <div className="message-activity-content">
                      <span>
                        <span>
                          <LiaEdit className="leadsource_img p-2" />
                        </span>
                      </span>
                      {item.Escalated_email} {item.Sourcename}
                    </div>
                    <span className="time">
                      {moment(new Date(item.kstime * 1000)).format(
                        "MM/DD/YYYY @ hh:mm A",
                      )}
                    </span>
                  </div>
                );
              }
              //
              if (Number(item.Messagetype) === 81) {
                return (
                  <div key={index} className="message-activity">
                    <div className="message-activity-content">
                      <span>
                        <span>
                          <BsTelephone className="leadsource_img p-2" />
                        </span>
                      </span>
                      Call Required
                    </div>
                    <span className="time">
                      {moment(new Date(item.kstime * 1000)).format(
                        "MM/DD/YYYY @ hh:mm A",
                      )}
                    </span>
                  </div>
                );
              }
              //
              if (Number(item.Messagetype) === 73) {
                return (
                  <div key={index} className="message-notice">
                    <span>{item.Escalated_email}</span>
                  </div>
                );
              }
              //
              if (Number(item.Schedulestatus) === 2) {
                return (
                  <span key={index} className="blast_msg">
                    <HiOutlineClock /> Sent by Scheduled Message
                  </span>
                );
              }
            })}
          <div className="conv-assign-message"></div>

          <div ref={msgEndRef}></div>
        </div>
      </div>
      <div className="widget-actions">
        <hr />
        {carlysetting && Ismobile ? (
          <div className="text-center">
            {carlyAi ? (
              <a className="carly_ai" onClick={() => toggleCarlyAi()}>
                <HiOutlineCpuChip size={11} className="me-1" />
                Carly AI is <span>ON</span>
              </a>
            ) : (
              <a className="carly_ai off" onClick={() => toggleCarlyAi()}>
                <HiOutlineCpuChip size={11} className="me-1" />
                Carly AI is <span>OFF</span>
              </a>
            )}
          </div>
        ) : null}
        <div className="sms-detail-meta sms-bottom-meta"></div>
        <ul className="nav nav-tabs">
          {canText && comments?.length === 0 ? (
            <li
              className={`nav-tab${widgetTab === "Message" ? " active" : ""}`}
              onClick={() => setWidgetTab("Message")}
            >
              Message
            </li>
          ) : null}
          {isFbMessenger ? (
            <li
              className={`nav-tab${widgetTab === "Facebook" ? " active" : ""}`}
              onClick={() => setWidgetTab("Facebook")}
            >
              Facebook
            </li>
          ) : null}
          <li
            className={`nav-tab${widgetTab === "Note" ? " active" : ""}`}
            onClick={() => setWidgetTab("Note")}
          >
            Note
          </li>
        </ul>
        <div className="sms-wrapper">
          {smsVideoImg && (
            <div className="sms-video-wrapper">
              <div
                className="sms-video-close"
                onClick={() => cancelVideoAttach()}
              >
                <LiaTimesSolid />
              </div>
              {/* <video className="lazy" controls src="" crossOrigin="anonymous"></video> */}
              <img className="lazy" src={smsVideoImg} crossOrigin="anonymous" />
            </div>
          )}
          {smsImg && (
            <div className="sms-image-wrapper">
              <div
                className="sms-image-close"
                onClick={() => cancelImageAttach()}
              >
                <LiaTimesSolid />
              </div>
              <img className="lazy" src={smsImg} crossOrigin="anonymous" />
            </div>
          )}
          <div className="tab-content">
            <div
              className={`tab-pane${widgetTab === "Message" ? " active" : ""}`}
            >
              <div className="d-flex align-items-center justify-content-between my-2">
                <small>Texting from {minfo.tphone}</small>
                <small>
                  <a
                    className="text-primary text-decoration-none
                     cursor-pointer d-flex align-items-center"
                    onClick={() => onNextLead()}
                  >
                    Next Lead
                    <BsArrowRight />
                  </a>
                </small>
              </div>
              <ContentEditable
                className="msg-editor input-block-level"
                tagName="div"
                disabled={carlyAi}
                placeholder={messageInput ? "" : placeholder}
                html={messageInput}
                onChange={(e) => setMessageInput(e.target.value)}
              />
              <div className="d-flex align-items-center justify-content-between mt-2">
                <div className="d-flex align-items-center me-auto">
                  <EmojiPickerInput
                    onSelect={(emoji) =>
                      setMessageInput((current) => current + emoji)
                    }
                  />
                  <GifPickerInput
                    onSelect={(imgEle, _giphykey, _giphysize) => {
                      setMessageInput((current) => current + imgEle);
                      setGiphykey(_giphykey);
                      setGiphysize(_giphysize);
                    }}
                  />
                  <IoImageOutline
                    size={18}
                    className="me-1"
                    title="Attach Image"
                    onClick={() => setOpenAttachImageModal(true)}
                  />
                  <BsBookmark
                    size={16}
                    className="me-1"
                    title="Saved Replies"
                    onClick={() => setOpenSavedReplies(true)}
                  />
                  <LiaDirectionsSolid
                    size={18}
                    className="me-1"
                    title="Send Directions"
                    onClick={() => senddirection()}
                  />
                  <BsCameraVideo
                    size={18}
                    className="me-1"
                    title="Send Video"
                    onClick={() => setOpenSendVideoModal(true)}
                  />
                  <HiOutlineCurrencyDollar
                    size={18}
                    className="me-1"
                    title="Send Credit App"
                    onClick={() => sendcreditapp()}
                  />
                  <SmartFieldsInput
                    onSelect={(field) =>
                      setMessageInput((current) => current + field)
                    }
                  />
                  {Number(aisetting?.is_client_setting) === 1 ? (
                    <HiOutlineCpuChip
                      size={18}
                      className="me-1"
                      title="AI"
                      onClick={() => ai()}
                    />
                  ) : null}
                </div>
                <div className="d-flex align-items-center ms-auto">
                  <BsClock
                    size={18}
                    onClick={() =>
                      messageInput ? setOpenScheduleMessageModal(true) : null
                    }
                    color={!messageInput ? "#6c757d" : "#0d6efd"}
                  />
                  <button
                    className={`btn btn-sm d-flex align-items-center py-0 ms-2${
                      messageInput ? " btn-primary" : " btn-secondary"
                    }`}
                    disabled={!messageInput}
                    onClick={() => sendmessage()}
                  >
                    <BiPaperPlane className="me-1" />
                    Send
                  </button>
                </div>
              </div>
            </div>
            <div
              className={`tab-pane${widgetTab === "Facebook" ? " active" : ""}`}
            >
              <div className="d-flex align-items-center justify-content-between my-2">
                <small className="ms-auto">
                  <a
                    className="text-primary text-decoration-none 
                    cursor-pointer d-flex align-items-center"
                    onClick={() => onNextLead()}
                  >
                    Next Lead
                    <BsArrowRight />
                  </a>
                </small>
              </div>
              <ContentEditable
                className="msg-editor input-block-level"
                tagName="div"
                disabled={
                  Number(label) === 6 || Number(optinfo?.look) === 2 || carlyAi
                }
                placeholder={fbMessageInput ? "" : placeholder}
                html={fbMessageInput}
                onChange={(e) => setFbMessageInput(e.target.value)}
              />
              <div className="d-flex align-items-center justify-content-between mt-2">
                <div className="d-flex align-items-center me-auto">
                  <EmojiPickerInput
                    onSelect={(emoji) =>
                      setFbMessageInput((current) => current + emoji)
                    }
                  />
                  <GifPickerInput
                    onSelect={(imgEle, _giphykey, _giphysize) => {
                      setFbMessageInput((current) => current + imgEle);
                      setGiphykey(_giphykey);
                      setGiphysize(_giphysize);
                    }}
                  />
                  <IoImageOutline
                    size={18}
                    className="me-1"
                    title="Attach Image"
                    onClick={() => setOpenAttachImageModal(true)}
                  />
                  <BsBookmark
                    size={16}
                    className="me-1"
                    title="Saved Replies"
                    onClick={() => setOpenSavedReplies(true)}
                  />
                  <LiaDirectionsSolid
                    size={18}
                    className="me-1"
                    title="Send Directions"
                    onClick={() => senddirection()}
                  />
                  <BsCameraVideo
                    size={18}
                    className="me-1"
                    title="Send Video"
                    onClick={() => setOpenSendVideoModal(true)}
                  />
                  <HiOutlineCurrencyDollar
                    size={18}
                    className="me-1"
                    title="Send Credit App"
                    onClick={() => sendcreditapp()}
                  />
                  <SmartFieldsInput
                    onSelect={(field) =>
                      setFbMessageInput((current) => current + field)
                    }
                  />
                  {Number(aisetting?.is_client_setting) === 1 ? (
                    <HiOutlineCpuChip
                      size={18}
                      className="me-1"
                      title="AI"
                      onClick={() => ai()}
                    />
                  ) : null}
                </div>
                <div className="d-flex align-items-center ms-auto">
                  <BsClock
                    size={18}
                    onClick={() =>
                      fbMessageInput ? setOpenScheduleMessageModal(true) : null
                    }
                    color={!fbMessageInput ? "#6c757d" : "#0d6efd"}
                  />
                  <button
                    className={`btn btn-sm d-flex align-items-center py-0 ms-2${
                      fbMessageInput ? " btn-primary" : " btn-secondary"
                    }`}
                    disabled={!fbMessageInput}
                    onClick={() => sendfbmessage()}
                  >
                    <BiPaperPlane className="me-1" />
                    Send
                  </button>
                </div>
              </div>
            </div>
            <div className={`tab-pane${widgetTab === "Note" ? " active" : ""}`}>
              <div
                className="msg-editor input-block-level bg-yellow mt-3"
                contentEditable={true}
                suppressContentEditableWarning={true}
                placeholder={
                  !inputNote ? "Type @username to mention a teammate" : ""
                }
                // dangerouslySetInnerHTML={{__html: inputNote}}
                onChange={(e) => setInputNote(e.target.value)}
              >
                {inputNote}
              </div>
              <div className="d-flex align-items-center justify-content-between mt-2">
                <div className="d-flex align-items-center ms-auto">
                  <button
                    className={`btn btn-sm d-flex align-items-center py-0 ms-2${
                      inputNote ? " btn-primary" : " btn-secondary"
                    }`}
                    disabled={!inputNote}
                    onClick={() => sendpostnote()}
                  >
                    Post Note
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Modals */}
      <ConversationAttachImageModal
        isOpenModal={openAttachImageModal}
        afterOpenModal={() => {}}
        closeModal={() => setOpenAttachImageModal(false)}
        onAttached={(imgUrls) => onAttached(imgUrls)}
      />
      <ConversationSendVideoModal
        isOpenModal={openSendVideoModal}
        afterOpenModal={() => {}}
        closeModal={() => setOpenSendVideoModal(false)}
        onCopyLink={(videoUrl, gifUrl) => videoCopyLink(videoUrl, gifUrl)}
      />
      <ConversationSavedRepliesModal
        clientid={mainData?.leadclientid}
        isOpenModal={openSavedReplies}
        afterOpenModal={() => {}}
        closeModal={() => setOpenSavedReplies(false)}
        onSelect={(reply) => {
          if (widgetTab === "Facebook") {
            setFbMessageInput((current) => current + reply);
          } else {
            setMessageInput((current) => current + reply);
          }
          setOpenSavedReplies(false);
        }}
      />
      <ConversationAIRestrictedModal
        isOpenModal={openAiRestrictedModal}
        afterOpenModal={() => {}}
        closeModal={() => setOpenAiRestrictedModal(false)}
      />
      <ConversationAIErrorModal
        isOpenModal={openAiErrorModal}
        afterOpenModal={() => {}}
        closeModal={() => setOpenAiErrorModal(false)}
      />
      <ConversationScheduleMessageModal
        isOpenModal={openScheduleMessageModal}
        afterOpenModal={() => {}}
        closeModal={() => setOpenScheduleMessageModal(false)}
        postData={(params) => send_schedule_message(params)}
      />
    </div>
  );
}
