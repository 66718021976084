import React, { createContext, useContext } from "react";
import { AxiosContext } from "./axios_context";
import config from "../config/config";

const BlastContext = createContext(undefined);

const BlastContextProvider = (props) => {
  const { axiosInstance, setLoading } = useContext(AxiosContext);

  const getBlastsPageDataApi = (params) => {
    const data = params;
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/blasts/blasts_data`,
      data,
    })
      .then((response) => {
        const data = response.data;
        console.log("== blasts_data ==", data);
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
      });
  };

  const blastFilterApi = (params) => {
    const data = params;
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/blasts/blasts_filter`,
      data,
    })
      .then((response) => {
        const data = response.data;
        console.log("== blasts_filter ==", data);
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
      });
  };

  const getBlastSummaryApi = (params) => {
    const data = params;
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/blasts/blasts_ajaxsummary`,
      data,
    })
      .then((response) => {
        const data = response.data;
        console.log("== blasts_ajaxsummary ==", data);
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
      });
  };

  const getBlastLeadsApi = (params) => {
    const data = params;
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/blasts/blasts_ajaxleads`,
      data,
    })
      .then((response) => {
        const data = response.data;
        console.log("== blasts_ajaxleads ==", data);
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
      });
  };

  const getBlastsCalendarDataApi = (params) => {
    const data = params;
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/blasts/blasts_ajaxcalendar`,
      data,
    })
      .then((response) => {
        const data = response.data;
        console.log("== blasts_ajaxcalendar ==", data);
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
      });
  };

  const cancelBlastApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/blasts/blasts_cancelblast`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("== blasts_cancelblast ==", data);
        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
      });
  };

  const updateScheduleDateApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/blasts/blasts_updatescheduledate`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("== blasts_updatescheduledate ==", data);
        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
      });
  };

  const getNewBlastPageDataApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/blasts/blasts_newblast`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("== blasts_newblast ==", data);
        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
      });
  };

  const ajaxfilterApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/blasts/blasts_ajaxfilter`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("== blasts_ajaxfilter ==", data);
        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
      });
  };

  const ajaxpaginationApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/blasts/blasts_ajaxpagin`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("== blasts_ajaxpagin ==", data);
        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
      });
  };

  const sendBlastSummaryApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/blasts/blasts_sendsummary`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("== blasts_sendsummary ==", data);
        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
      });
  };

  const checkvaliddatetimeApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/blasts/blasts_checkvaliddatetime`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("== blasts_checkvaliddatetime ==", data);
        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
      });
  };

  const getCopyBlastPageDataApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/blasts/blasts_copyblast`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("== blasts_copyblast ==", data);
        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
      });
  };

  const providerValue = {
    getBlastsPageDataApi,
    blastFilterApi,
    getBlastSummaryApi,
    getBlastLeadsApi,
    getBlastsCalendarDataApi,
    cancelBlastApi,
    updateScheduleDateApi,
    getNewBlastPageDataApi,
    ajaxfilterApi,
    ajaxpaginationApi,
    sendBlastSummaryApi,
    checkvaliddatetimeApi,
    getCopyBlastPageDataApi,
  };

  return (
    <BlastContext.Provider value={providerValue}>
      {props.children}
    </BlastContext.Provider>
  );
};

export { BlastContext, BlastContextProvider };
