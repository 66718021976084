import React, { useContext, useState } from "react";
import Modal from "react-modal";
import { AuthContext, FunnelContext } from "../../context";
import { useEffect } from "react";
import ContentEditable from "react-contenteditable";
import SmartFieldsInput from "../ChatInputElements/SmartFieldsInput";
import GifPickerInput from "../ChatInputElements/GifPickerInput";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "560px",
    padding: "15px",
    marginTop: "20px",
    marginBottom: "20px",
    overflow: "visible",
  },
};

Modal.setAppElement("#root");

export default function FunnelEditMessageModal({
  data = null,
  isOpenModal = false,
  afterOpenModal,
  closeModal,
  reloadData,
}) {
  const { user } = useContext(AuthContext);
  const { updateMessageApi } = useContext(FunnelContext);
  const [postData, setPostData] = useState({
    userId: user.Id,
    name: "",
    message: "",
    gif: "",
    attached: "",
    messageid: "",
    funnelid: "",
  });

  useEffect(() => {
    if (data) {
      let _postData = { ...postData };
      _postData.userId = user.Id;
      _postData.name = data?.info.Name;
      _postData.message = data?.message;
      _postData.gif = data?.info.Gif;
      _postData.attached = data?.info.Attached;
      _postData.messageid = data?.info.Id;
      _postData.funnelid = data?.info.Funnelid;
      setPostData(_postData);
    }
  }, [data]);

  const onSave = async () => {
    const data = await updateMessageApi(postData);
    if (data.status === "ok") {
      reloadData();
    }
  };

  return (
    <Modal
      isOpen={isOpenModal}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Edit Modal"
    >
      <div className="modal-header">
        <h4 className="d-flex align-items-center">Edit Message</h4>
      </div>
      <div className="modal-body">
        <div className="row">
          <div className="col col-sm-6">
            <div className="form-group mb-2">
              <label className="mb-2">Message Name</label>
              <input
                type="text"
                className="form-control"
                name="message-name"
                value={postData.name}
                onChange={(e) =>
                  setPostData({ ...postData, name: e.target.value })
                }
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="form-group mb-2">
              <label className="mb-2">Message</label>
              <ContentEditable
                style={{ minHeight: "100px" }}
                className="form-textarea msg-editor"
                tagName="div"
                // placeholder={messageInput ? '' : placeholder}
                html={postData?.message}
                onChange={(e) =>
                  setPostData({ ...postData, message: e.target.value })
                }
              />
              <div className="d-flex align-items-center me-auto p-1">
                <GifPickerInput
                  onSelect={(imgEle, giphykey, giphysize) => {
                    console.log(giphysize);
                    setPostData({
                      ...postData,
                      gif: giphykey,
                      message: postData.message + imgEle,
                    });
                  }}
                />
                <SmartFieldsInput
                  onSelect={(field) =>
                    setPostData({
                      ...postData,
                      message: postData.message + field,
                    })
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer mt-3">
        <button className="btn btn-outline-secondary me-2" onClick={closeModal}>
          Cancel
        </button>
        <button className="btn btn-primary" onClick={() => onSave()}>
          Save
        </button>
      </div>
    </Modal>
  );
}
