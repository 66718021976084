import React, { createContext, useContext, useEffect, useState } from "react";
import { AxiosContext } from "./axios_context";
import config from "../config/config";
import { AuthContext } from "./auth_context";

const SharedContext = createContext(undefined);

const SharedContextProvider = (props) => {
  const { axiosInstance, setLoading } = useContext(AxiosContext);
  const { user } = useContext(AuthContext);
  const [dealerships, setDealerships] = useState([]);

  useEffect(() => {
    if (user) {
      loadDealership(user.Id, 1);
    }
  }, [user]);

  const loadDealership = (userId = user.Id, leadsource = 1) => {
    const data = {
      userId: userId,
      leadsource: leadsource,
    };
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/dashboard/dealership`,
      data,
    })
      .then((response) => {
        const data = response.data;
        const res = data.response;
        if (data.status === "ok") {
          setDealerships(res.dealer);
        }
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const getServerTimeApi = () => {
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/utils/util_servertime`,
    })
      .then((response) => {
        const data = response.data;
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const firebasecallApi = () => {
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/utils/util_firebasecall`,
    })
      .then((response) => {
        const data = response.data;
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const labelchangeApi = () => {
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/utils/util_labelchange`,
    })
      .then((response) => {
        const data = response.data;
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const getLocaltimeApi = (params) => {
    const data = params;
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/utils/util_wtime`,
      data,
    })
      .then((response) => {
        const data = response.data;
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const getWeatherConditionApi = (params) => {
    const data = params;
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/utils/util_weathercondition`,
      data,
    })
      .then((response) => {
        const data = response.data;
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const uniquemailcheckApi = (params) => {
    const data = params;
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/utils/util_uniquemailcheck`,
      data,
    })
      .then((response) => {
        const data = response.data;
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const tagAutoCompleteApi = (params) => {
    const data = params;
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/utils/util_gettag`,
      data,
    })
      .then((response) => {
        const data = response.data;
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const getmultivehicleApi = (params) => {
    const data = params;
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/utils/util_getmultivehicle`,
      data,
    })
      .then((response) => {
        const data = response.data;
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const providerValue = {
    loadDealership,
    dealerships,
    getServerTimeApi,
    firebasecallApi,
    labelchangeApi,
    getLocaltimeApi,
    getWeatherConditionApi,
    uniquemailcheckApi,
    tagAutoCompleteApi,
    getmultivehicleApi,
  };

  return (
    <SharedContext.Provider value={providerValue}>
      {props.children}
    </SharedContext.Provider>
  );
};

export { SharedContext, SharedContextProvider };
