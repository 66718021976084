import Modal from "react-modal";
import React, { useContext, useState, useEffect } from "react";
import { AuthContext, ConversationContext } from "../../context";
import {
  LiaFunnelDollarSolid,
  LiaPauseSolid,
  LiaPlaySolid,
  LiaRetweetSolid,
  LiaWindowCloseSolid,
} from "react-icons/lia";
import { BiPlus } from "react-icons/bi";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "560px",
    padding: "15px",
    marginTop: "20px",
    marginBottom: "20px",
  },
};

Modal.setAppElement("#root");

export default function ConversationAssignFunnelModal({
  leadId,
  isOpenModal = false,
  afterOpenModal,
  closeModal,
}) {
  const { user } = useContext(AuthContext);
  const { loadAssignFunnelModalDataApi, cancelFunnelApi, pauseFunnelApi } =
    useContext(ConversationContext);
  const [funnel, setFunnel] = useState([]);
  const [global, setGlobal] = useState([]);
  const [flag, setFlag] = useState("");
  const [assign, setAssign] = useState(""); // old funnel id
  const [funnelId, setFunnelId] = useState("");
  const [lead, setLead] = useState(null); // lead id
  const [optout, setOptout] = useState(false);

  useEffect(() => {
    if (leadId) {
      console.log("leadId ===", leadId);
      loadAssignFunnelModalData();
    }
  }, [leadId]);

  const loadAssignFunnelModalData = async () => {
    const data = await loadAssignFunnelModalDataApi({ leadid: leadId });
    if (data.status === "ok") {
      const res = data.response;
      setFunnel(res?.funnel);
      setGlobal(res?.global);
      setFlag(res?.flag);
      setAssign(res?.assign);
      setFunnelId(res?.assign);
      setLead(res?.lead);
      setOptout(res?.optout);
    }
  };

  const assignfunnel = async () => {
    closeModal("assignfunnel", {
      userId: user.Id,
      lead: lead,
      funnelid: funnelId,
      oldfunnelid: 1,
      flag: 1,
    });
  };

  const reassignfunnel = async () => {
    closeModal("reassignfunnel", {
      userId: user.Id,
      lead: lead,
      funnelid: funnelId,
      oldfunnelid: assign,
      flag: 2,
    });
  };

  const cancelfunnel = async () => {
    let params = {
      userId: user.Id,
      lead: lead,
      funnel: assign,
    };
    const data = await cancelFunnelApi(params);
    if (data.status === "ok") {
      closeModal("cancelfunnel", {});
    }
  };

  const pausefunnel = async () => {
    let params = {
      userId: user.Id,
      lead: lead,
      funnel: assign,
    };
    const data = await pauseFunnelApi(params);
    if (data.status === "ok") {
      closeModal("pausefunnel", {});
    }
  };

  const resumefunnel = async () => {
    closeModal("resumefunnel", {
      userId: user.Id,
      lead: lead,
      funnelid: funnelId,
      oldfunnelid: 1,
      flag: 1,
    });
  };

  return (
    <Modal
      isOpen={isOpenModal}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Assign Funnel"
    >
      <div className="modal-header">
        <h4 className="d-flex align-items-center">
          <LiaFunnelDollarSolid className="me-1" />
          Assign Funnel
        </h4>
      </div>
      {optout ? (
        <>
          <div className="modal-body py-3">
            <div className="alert alert-warning">
              <strong>Warning!</strong> This lead has opted out of receiving
              messages.
            </div>
          </div>
          <div className="modal-footer mt-3">
            <button className="btn btn-outline-secondary" onClick={closeModal}>
              Cancel
            </button>
          </div>
        </>
      ) : (
        <>
          <div className="modal-body py-3">
            <select
              className="form-select"
              value={funnelId}
              onChange={(e) => setFunnelId(e.target.value)}
            >
              <option value="">Select Funnel</option>
              {funnel.length > 0 && (
                <optgroup label="Funnel">
                  {funnel.map((item, index) => (
                    <option value={item?.Id} key={index}>
                      {item?.Name}
                    </option>
                  ))}
                </optgroup>
              )}
              {global.length > 0 && (
                <optgroup label="Global">
                  {global.map((item, index) => (
                    <option value={item?.Id} key={index}>
                      {item?.Name}
                    </option>
                  ))}
                </optgroup>
              )}
            </select>
            <small className="text-danger mt-3 d-none">
              Please select a funnel!
            </small>
          </div>
          <div className="modal-footer mt-3">
            {!assign ? (
              <button
                className="btn d-flex align-items-center btn-primary"
                onClick={() => assignfunnel()}
              >
                <BiPlus className="me-1" />
                Assign
              </button>
            ) : (
              <>
                <button
                  className="btn d-flex align-items-center btn-primary ms-2"
                  onClick={() => reassignfunnel()}
                >
                  <LiaRetweetSolid className="me-1" />
                  Re-assign
                </button>
                <button
                  className="btn d-flex align-items-center btn-outline-secondary ms-2"
                  onClick={() => cancelfunnel()}
                >
                  <LiaWindowCloseSolid className="me-1" />
                  Cancel
                </button>
              </>
            )}
            {Number(flag) === 1 ? (
              <button
                className="btn d-flex align-items-center btn-primary ms-2"
                onClick={() => pausefunnel()}
              >
                <LiaPauseSolid className="me-1" color="#fff" />
                Pause this Funnel
              </button>
            ) : Number(flag) === 2 ? (
              <button
                className="btn d-flex align-items-center btn-primary ms-2"
                onClick={() => resumefunnel()}
              >
                <LiaPlaySolid className="me-1" color="#fff" />
                Resume
              </button>
            ) : null}
          </div>
        </>
      )}
    </Modal>
  );
}
