import Modal from "react-modal";
import React, { useContext, useState, useEffect } from "react";
import { AuthContext, ConversationContext } from "../../context";
import CachedIcon from "@mui/icons-material/Cached";
import helper from "../../utils/helper";
import { toast } from "react-toastify";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "560px",
    padding: "15px",
    marginTop: "20px",
    marginBottom: "20px",
  },
};

Modal.setAppElement("#root");

export default function ConversationChangeLabelModal({
  leadId,
  flag,
  isOpenModal = false,
  afterOpenModal,
  closeModal,
}) {
  const { user } = useContext(AuthContext);
  const { loadChangeLabelModalDataApi, saveLabelChangeApi } =
    useContext(ConversationContext);
  const [postData, setPostData] = useState({
    userId: user?.Id,
    lead: leadId,
    flag: flag,
    slabel: "",
    clabel: "",
    rid: "",
    Isactive: "",
  });

  useEffect(() => {
    if (leadId) {
      loadChangeLabelModalData(leadId, flag);
    }
  }, [leadId, flag]);

  const loadChangeLabelModalData = async (_leadId, _flag) => {
    console.log("<<<>>>>", _leadId, _flag);
    const data = await loadChangeLabelModalDataApi({
      userId: user.Id,
      leadId: _leadId,
      flag: _flag,
    });
    if (data.status === "ok") {
      const res = data.response;
      // setModalData(res);
      setPostData({
        ...postData,
        userId: user?.Id,
        lead: res?.rid,
        flag: res?.flag,
        slabel: res?.clabel,
        clabel: res?.clabel,
        rid: res?.responseId,
        Isactive: res?.Isactive,
      });
    }
  };

  const onSave = async () => {
    console.log("=== post data ===", postData);
    const res = await saveLabelChangeApi(postData);
    if (res.status === "ok") {
      toast.info("Status saved!");
      closeModal();
    }
  };

  return (
    <Modal
      isOpen={isOpenModal}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Status"
    >
      <div className="modal-header">
        <h4 className="d-flex align-items-center">
          <CachedIcon className="me-1" />
          Status
        </h4>
      </div>
      <div className="modal-body">
        <form>
          <div className="row">
            <div className="col-12">
              <div className="form-group mt-3">
                <label className="mb-1">Change Status</label>
                <select
                  className="form-select"
                  value={postData?.slabel}
                  onChange={(e) =>
                    setPostData({ ...postData, slabel: e.target.value })
                  }
                >
                  <option value="1">Hot</option>
                  <option value="4">Warm</option>
                  <option value="2">Cold</option>
                  <option value="3">Sold</option>
                  <option value="5">Follow Up</option>
                  <option value="6">Dead</option>
                  {Number(postData?.Isactive) === 3 && (
                    <option value="11">Other</option>
                  )}
                  {helper.loginTypecheck("ADMIN") && (
                    <option value="12">Test</option>
                  )}
                </select>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="modal-footer mt-3">
        <button className="btn btn-primary me-2" onClick={() => onSave()}>
          Save
        </button>
        <button className="btn btn-outline-secondary" onClick={closeModal}>
          Cancel
        </button>
      </div>
    </Modal>
  );
}
