import React from "react";
import { Button } from "@mui/material";

export default function DefaultButton(props) {
  const { label, handleClick } = props;

  return (
    <Button
      variant="contained"
      size="small"
      sx={{
        textTransform: "none",
      }}
      onClick={handleClick}
      color="inherit"
    >
      {label}
    </Button>
  );
}
