import React, { createContext, useState } from "react";
import axios from "axios";
import config from "../config/config";

const AxiosContext = createContext(undefined);

const AxiosContextProvider = (props) => {
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("");

  const axiosInstance = axios.create({
    baseURL: config.baseUrl,
    headers: {
      // "x-access-token": token,
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
      // "Access-Control-Allow-Origin": "*"
    },
  });

  const providerValue = {
    axiosInstance,
    loading,
    setLoading,
    loadingText,
    setLoadingText,
  };

  return (
    <AxiosContext.Provider value={providerValue}>
      {props.children}
    </AxiosContext.Provider>
  );
};

export { AxiosContext, AxiosContextProvider };
