import { IoCarSportOutline } from "react-icons/io5";
import { SlBubbles, SlClock, SlFolder, SlUser } from "react-icons/sl";
// eslint-disable-next-line max-len
import CampaignsActionDataTable from "../DataTables/CampaignsDataTable/CampaignsActionDataTable";
// eslint-disable-next-line max-len
import DFCampaignsActionDataTable from "../DataTables/CampaignsDataTable/DFCampaignsActionDataTable";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext, ClientContext } from "../../context";
import moment from "moment";

export default function ClientCampaignTab({
  isDealerfunnel,
  cmpboard,
  Campaign,
  client,
  postData,
  setPostData,
  reloadData,
}) {
  const { user } = useContext(AuthContext);
  const { getDealerfunnelByClientIdApi } = useContext(ClientContext);
  const [stats, setStats] = useState(undefined);
  const [auth, setAuth] = useState(undefined);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [usertype, setUsertype] = useState(undefined);
  const [campaigns, setCampaigns] = useState([]);
  const [selectedDays, setSelectedDays] = useState(7);
  const [customDateRage, setCustomDateRange] = useState({
    start: new Date(moment().subtract(1, "months")),
    end: new Date(),
  });

  useEffect(() => {
    console.log(postData, setPostData, reloadData);
    if (Number(isDealerfunnel) === 1 && selectedDays !== "custom") {
      loadDealerfunnelByClientId({
        userId: user.Id,
        clientId: client.Id,
        filter: selectedDays,
        start: customDateRage.start,
        end: customDateRage.end,
      });
    }
  }, [isDealerfunnel, client, selectedDays]);

  useEffect(() => {
    if (Number(isDealerfunnel) === 1 && selectedDays === "custom") {
      loadDealerfunnelByClientId({
        userId: user.Id,
        clientId: client.Id,
        filter: selectedDays,
        start: customDateRage.start,
        end: customDateRage.end,
      });
    }
  }, [customDateRage]);

  const loadDealerfunnelByClientId = async (params) => {
    const data = await getDealerfunnelByClientIdApi(params);
    if (data.status === "ok") {
      const res = data?.response;
      console.log(">>>>", res, res.stats);
      setStats(res?.stats);
      setCampaigns(res?.campaigns);
      setUsertype(res?.usertype);
      setAuth(res?.auth);
      setIsSuperAdmin(res?.isSuperAdmin);
    }
  };

  if (Number(isDealerfunnel) === 1) {
    return (
      <>
        <div className="row">
          <div className="col col-12 col-sm-12 col-md">
            <div className="h-stats card mb-2 p-2">
              <SlFolder
                className="icon mx-2"
                style={{ color: "#0051ff", fontSize: "28px" }}
              />
              <div className="d-flex flex-column justify-content-center ms-2">
                <h3>{stats?.campaigns || 0}</h3>
                <span>Campaigns</span>
              </div>
            </div>
          </div>
          <div className="col col-12 col-sm-12 col-md">
            <div className="h-stats card mb-2 p-2">
              <SlUser
                className="icon mx-2"
                style={{ color: "#0051ff", fontSize: "28px" }}
              />
              <div className="d-flex flex-column justify-content-center ms-2">
                <h3>{stats?.leads || 0}</h3>
                <span>Leads</span>
              </div>
            </div>
          </div>
          <div className="col col-12 col-sm-12 col-md">
            <div className="h-stats card mb-2 p-2">
              <SlBubbles
                className="icon mx-2"
                style={{ color: "#0051ff", fontSize: "28px" }}
              />
              <div className="d-flex flex-column justify-content-center ms-2">
                <h3>
                  {Number(stats?.leads) !== 0
                    ? Math.round(
                        (Number(stats?.engagement) / Number(stats?.leads)) *
                          100 *
                          10,
                      ) / 10
                    : 0}
                  %
                </h3>
                <span>Engagement</span>
              </div>
            </div>
          </div>
          <div className="col col-12 col-sm-12 col-md">
            <div className="h-stats card mb-2 p-2">
              <SlClock
                className="icon mx-2"
                style={{ color: "#0051ff", fontSize: "28px" }}
              />
              <div className="d-flex flex-column justify-content-center ms-2">
                <h3>{stats?.appts || 0}</h3>
                <span>Appointments</span>
              </div>
            </div>
          </div>
          <div className="col col-12 col-sm-12 col-md">
            <div className="h-stats card mb-2 p-2">
              <IoCarSportOutline
                className="icon mx-2"
                style={{ color: "#0051ff", fontSize: "28px" }}
              />
              <div className="d-flex flex-column justify-content-center ms-2">
                <h3>{stats?.sold || 0}</h3>
                <span>Sold</span>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-12">
            <DFCampaignsActionDataTable
              usertype={usertype}
              auth={auth}
              isSuperAdmin={isSuperAdmin}
              campaigns={campaigns}
              selectedDays={selectedDays}
              setSelectedDays={setSelectedDays}
              customDateRage={customDateRage}
              setCustomDateRange={setCustomDateRange}
              reloadData={() =>
                loadDealerfunnelByClientId({
                  userId: user.Id,
                  clientId: client.Id,
                  filter: selectedDays,
                  start: customDateRage.start,
                  end: customDateRage.end,
                })
              }
            />
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="row">
        <div className="col col-12 col-sm-12 col-md">
          <div className="h-stats card mb-2 p-2">
            <SlFolder
              className="icon mx-2"
              style={{ color: "#0051ff", fontSize: "28px" }}
            />
            <div className="d-flex flex-column justify-content-center ms-2">
              <h3>{cmpboard.cmp_count}</h3>
              <span>Campaigns</span>
            </div>
          </div>
        </div>
        <div className="col col-12 col-sm-12 col-md">
          <div className="h-stats card mb-2 p-2">
            <SlUser
              className="icon mx-2"
              style={{ color: "#0051ff", fontSize: "28px" }}
            />
            <div className="d-flex flex-column justify-content-center ms-2">
              <h3>{cmpboard.qty}</h3>
              <span>Pieces</span>
            </div>
          </div>
        </div>
        <div className="col col-12 col-sm-12 col-md">
          <div className="h-stats card mb-2 p-2">
            <SlBubbles
              className="icon mx-2"
              style={{ color: "#0051ff", fontSize: "28px" }}
            />
            <div className="d-flex flex-column justify-content-center ms-2">
              <h3>{cmpboard.response}</h3>
              <span>Responses</span>
            </div>
          </div>
        </div>
        <div className="col col-12 col-sm-12 col-md">
          <div className="h-stats card mb-2 p-2">
            <SlClock
              className="icon mx-2"
              style={{ color: "#0051ff", fontSize: "28px" }}
            />
            <div className="d-flex flex-column justify-content-center ms-2">
              <h3>{cmpboard.leads}</h3>
              <span>Leads</span>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-12">
          <CampaignsActionDataTable Campaign={Campaign} />
        </div>
      </div>
    </>
  );
}
