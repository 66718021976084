import React, { createContext, useContext } from "react";
import { AxiosContext } from "./axios_context";
import config from "../config/config";

const CampaignContext = createContext(undefined);

const CampaignContextProvider = (props) => {
  const { axiosInstance, setLoading } = useContext(AxiosContext);

  const deleteCampaignApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/campaigns/cmp_delete`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("=== cmp_delete ===", data);
        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
        return error;
      });
  };

  const providerValue = {
    deleteCampaignApi,
  };

  return (
    <CampaignContext.Provider value={providerValue}>
      {props.children}
    </CampaignContext.Provider>
  );
};

export { CampaignContext, CampaignContextProvider };
