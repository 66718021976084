import Modal from "react-modal";
import React, { useContext, useState, useEffect } from "react";
import { VideoContext } from "../../context";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "560px",
    padding: "15px",
    marginTop: "20px",
    marginBottom: "20px",
  },
};

Modal.setAppElement("#root");

export default function VideoEditModal({
  isOpenModal = false,
  afterOpenModal,
  closeModal,
  data,
}) {
  const { updateVideo } = useContext(VideoContext);
  const [postData, setPostData] = useState({
    videoId: "",
    title: "",
    duration: "",
    thumbnailURL: "",
    category: "",
    type: "",
    dealershipId: "",
  });

  useEffect(() => {
    if (data) {
      setPostData({
        videoId: data.Id,
        title: data.Title,
        duration: data.Duration,
        thumbnailURL: data.Thumnail_url,
        category: data.Category,
        type: data.Videotype,
        dealershipId: data.Dealership,
      });
    }
  }, [data]);

  const onUpdate = async () => {
    const res = await updateVideo(postData);
    if (res.status === "ok") {
      closeModal();
    }
  };

  return (
    <Modal
      isOpen={isOpenModal}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Edit Modal"
    >
      <div className="modal-header">
        <h4>Edit Video</h4>
      </div>
      <div className="modal-body">
        <form>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 mt-3">
              <div className="d-flex align-items-center justify-content-center">
                <img src={data?.Gif_url} height="150" alt="" />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 mt-3">
              <div className="d-flex align-items-center justify-content-center">
                <video
                  className="video"
                  height="150"
                  // poster={data?.Thumnail_url}
                  controls
                >
                  <source src={data?.Video_url} type="video/mp4" />
                </video>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="form-group mt-3">
                <label className="mb-1">Title</label>
                <input
                  type="text"
                  className="form-control"
                  value={postData?.title}
                  onChange={(e) =>
                    setPostData({ ...postData, title: e.target.value })
                  }
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6">
              <div className="form-group mt-3">
                <label className="mb-1">Category</label>
                <select
                  className="form-select form-control-fav"
                  value={data?.category}
                  onChange={(e) =>
                    setPostData({ ...postData, category: e.target.value })
                  }
                >
                  <option value="0">All</option>
                  {/* <option value="0">No Category</option> */}
                  <option value="1">Personal Videos</option>
                  <option value="2">Sales</option>
                  <option value="3">Service</option>
                  <option value="4">Marketing</option>
                  <option value="5">Finance</option>
                </select>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6">
              <div className="form-group mt-3">
                <label className="mb-1">Video Type</label>
                <select
                  className="form-select form-control-fav"
                  value={data?.type}
                  onChange={(e) =>
                    setPostData({ ...postData, type: e.target.value })
                  }
                >
                  <option value="1">My Videos</option>
                  <option value="2">Shared Videos</option>
                </select>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="modal-footer mt-3">
        <button className="btn btn-primary me-2" onClick={() => onUpdate()}>
          Update
        </button>
        <button className="btn btn-outline-secondary" onClick={closeModal}>
          Cancel
        </button>
      </div>
    </Modal>
  );
}
