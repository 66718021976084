import React, { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";
import { AuthContext, ConversationContext } from "../../context";
import helper from "../../utils/helper";
import config from "../../config/config";

export default function ConversationsWebleadInfo({ leadId, data }) {
  const { user } = useContext(AuthContext);
  const { getMakeValueApi, getModelValueApi, sendModelApi, saveVehicleApi } =
    useContext(ConversationContext);
  //
  const [activeTab, setActiveTab] = useState(2);
  //
  const [mainData, setMainData] = useState(undefined);
  //
  const [notecount, setNotecount] = useState(0);
  const [survey, setSurvey] = useState(undefined);
  const [FICO, setFICO] = useState(undefined);
  const [ModeledFICOScore, setModeledFICOScore] = useState(undefined);
  const [clientNotes, setClientNotes] = useState(undefined);
  const [fbnotes, setfbnotes] = useState(undefined);
  const [years, setYears] = useState([]);
  const [Year, setYear] = useState(undefined);
  const [Mileage, setMileage] = useState(undefined);
  const [makes, setMakes] = useState([]);
  const [Make, setMake] = useState(undefined);
  const [BookValue, setBookValue] = useState(undefined);
  const [models, setModels] = useState([]);
  const [Model, setModel] = useState(undefined);
  const [Payment, setPayment] = useState(undefined);
  const [trims, setTrims] = useState([]);
  const [Trim, setTrim] = useState(undefined);
  const [Apr, setApr] = useState(undefined);
  const [Payoff, setPayoff] = useState(undefined);
  const [InterestYear, setInterestYear] = useState(undefined);
  const [InterestVIN, setInterestVIN] = useState(undefined);
  const [InterestMake, setInterestMake] = useState(undefined);
  const [Stock, setStock] = useState(undefined);
  const [InterestModel, setInterestModel] = useState(undefined);
  const [itrims, setItrims] = useState([]);
  const [InterestTrim, setInterestTrim] = useState(undefined);
  const [Dateofbirth, setDateofbirth] = useState(undefined);
  const [Ssn, setSsn] = useState(undefined);
  const [MonthlyIncome, setMonthlyIncome] = useState(undefined);
  const [HousingCost, setHousingCost] = useState(undefined);
  const [Employer, setEmployer] = useState(undefined);
  const [HousingType, setHousingType] = useState("");
  const [Timeemployed, setTimeemployed] = useState("");
  const [Housinglength, setHousinglength] = useState("");
  const [Dpayment, setDpayment] = useState(undefined);
  const [Dob, setDob] = useState(undefined);
  const [Occupation, setOccupation] = useState(undefined);
  //
  const [note, setNote] = useState(undefined);
  const [sms, setSms] = useState(undefined);
  const [Address, setAddress] = useState(undefined);
  const [AutoType, setAutoType] = useState(undefined);
  const [HighCreditAmount, setHighCreditAmount] = useState(undefined);
  const [InterestRate, setInterestRate] = useState(undefined);
  const [Expiration, setExpiration] = useState(undefined);
  const [PPayment, setPPayment] = useState(undefined);
  const [PercentPaid, setPercentPaid] = useState(undefined);
  const [AbilityToFinance, setAbilityToFinance] = useState(undefined);
  const [EstimatedIncome, setEstimatedIncome] = useState(undefined);
  const [HomeownerRenter, setHomeownerRenter] = useState(undefined);
  const [LengthOfResidence, setLengthOfResidence] = useState(undefined);
  const [MaritalStatus, setMaritalStatus] = useState(undefined);
  const [CarOwnership, setCarOwnership] = useState(undefined);
  const [Ethnicity, setEthnicity] = useState(undefined);
  const [imakes, setImakes] = useState([]);
  const [imodels, setImodels] = useState([]);
  const [Financial, setFinancial] = useState(undefined);

  useEffect(() => {
    if (data) {
      setMainData({
        userId: user.Id,
        VehicleId: data?.VehicleId,
        FinancialId: data?.FinancialId,
      });
      //
      setNotecount(data?.notecount ? data?.notecount : 0);
      setSurvey(data?.survey);
      setFICO(data?.FICO);
      setModeledFICOScore(data?.ModeledFICOScore);
      setClientNotes(data?.client_notes);
      setfbnotes(data?.fbnotes);
      setYears(data?.years);
      setYear(data?.Year);
      setMileage(data?.Mileage);
      setMakes(data?.makes);
      setMake(data?.Make);
      setBookValue(data?.Book_value);
      setModels(data?.models);
      setModel(data?.Model);
      setPayment(data?.Payment);
      setTrims(data?.trims);
      setTrim(data?.Trim);
      setApr(data?.Apr);
      setPayoff(data?.Payoff);
      setInterestYear(data?.InterestYear);
      setInterestVIN(data?.InterestVIN);
      setInterestMake(data?.InterestMake);
      setStock(data?.Stock);
      setInterestModel(data?.InterestModel);
      setItrims(data?.itrims);
      setInterestTrim(data?.InterestTrim);
      setDateofbirth(data?.Dateofbirth);
      setSsn(data?.Ssn);
      setMonthlyIncome(data?.MonthlyIncome);
      setHousingCost(data?.HousingCost);
      setEmployer(data?.Employer);
      setHousingType(data?.HousingType);
      setTimeemployed(data?.Timeemployed);
      setHousinglength(data?.Housinglength);
      setDpayment(data?.Dpayment);
      setDob(data?.Dob);
      setOccupation(data?.Occupation);
      //
      setNote(data?.note);
      setSms(data?.sms);
      setAddress(data?.Address);
      setAutoType(data?.AutoType);
      setHighCreditAmount(data?.HighCreditAmount);
      setInterestRate(data?.InterestRate);
      setExpiration(data?.Expiration);
      setPPayment(data?.PPayment);
      setPercentPaid(data?.PercentPaid);
      setAbilityToFinance(data?.AbilityToFinance);
      setEstimatedIncome(data?.EstimatedIncome);
      setHomeownerRenter(data?.HomeownerRenter);
      setLengthOfResidence(data?.LengthOfResidence);
      setMaritalStatus(data?.MaritalStatus);
      setCarOwnership(data?.CarOwnership);
      setEthnicity(data?.Ethnicity);
      setImakes(data?.imakes);
      setImodels(data?.imodels);
      setFinancial(data?.Financial);
    }
  }, [data]);

  const onChangeTradeInYear = async (e) => {
    setYear(e.target.value);
    const data = await getMakeValueApi({
      userId: user?.Id,
      year: e.target.value,
    });
    if (data.status === "ok") {
      const res = data.response;
      if (res) {
        setMakes(Object.values(res));
      } else {
        setMakes([]);
      }
      setModels([]);
      setTrims([]);
    }
  };

  const onChangeTradeInMake = async (e) => {
    setMake(e.target.value);
    const data = await getModelValueApi({
      userId: user?.Id,
      year: Year,
      make: e.target.value,
    });
    if (data.status === "ok") {
      const res = data.response;
      if (res) {
        setModels(Object.values(res));
      } else {
        setModels([]);
      }
      setTrims([]);
    }
    onSaveVehicle(e);
  };

  const onChangeTradeInModel = async (e) => {
    setModel(e.target.value);
    const data = await sendModelApi({
      userId: user?.Id,
      year: Year,
      make: Make,
      model: e.target.value,
    });
    if (data.status === "ok") {
      const res = data.response;
      if (res) {
        setTrims(Object.values(res));
      } else {
        setTrims([]);
      }
    }
    onSaveVehicle(e);
  };

  const onChangeInterestYear = async (e) => {
    setInterestYear(e.target.value);
    const data = await getMakeValueApi({
      userId: user?.Id,
      year: e.target.value,
    });
    if (data.status === "ok") {
      const res = data.response;
      if (res) {
        setImakes(Object.values(res));
      } else {
        setImakes([]);
      }
      setImodels([]);
      setItrims([]);
    }
    onSaveVehicle(e);
  };

  const onChangeInterestMake = async (e) => {
    setInterestMake(e.target.value);
    const data = await getModelValueApi({
      userId: user?.Id,
      year: InterestYear,
      make: e.target.value,
    });
    if (data.status === "ok") {
      const res = data.response;
      if (res) {
        setImodels(Object.values(res));
      } else {
        setImodels([]);
      }
      setItrims([]);
    }
    onSaveVehicle(e);
  };

  const onChangeInterestModel = async (e) => {
    setInterestModel(e.target.value);
    const data = await sendModelApi({
      userId: user?.Id,
      year: InterestYear,
      make: InterestMake,
      model: e.target.value,
    });
    if (data.status === "ok") {
      const res = data.response;
      if (res) {
        setItrims(Object.values(res));
      } else {
        setItrims([]);
      }
    }
    onSaveVehicle(e);
  };

  const onSaveVehicle = async (e) => {
    console.log(
      ">>> e >>>",
      e,
      e.type,
      e.keyCode,
      e.target.name,
      e.target.value,
    );
    if (e.type === "keyup" && e.keyCode !== 13) {
      return;
    }
    let params = {
      userId: user.Id,
      field: e.target.name,
      value: e.target.value,
      VehicleId: mainData.VehicleId,
      FinancialId: mainData.FinancialId,
      LeadId: leadId,
    };
    if (params.field !== "") {
      const data = await saveVehicleApi(params);
      console.log(data);
    }
  };

  return (
    <div className="user-weblead-info bg-white">
      <ul className="nav-tabs">
        <li
          className={`nav-item ${activeTab === "Notes" ? "active" : ""}`}
          onClick={() => setActiveTab("Notes")}
        >
          Notes
          <span className="badge rounded-pill bg-primary ms-1">
            {notecount}
          </span>
        </li>
        <li
          className={`nav-item ${activeTab === "Vehicle" ? "active" : ""}`}
          onClick={() => setActiveTab("Vehicle")}
        >
          Vehicle
        </li>
        <li
          className={`nav-item ${activeTab === "Financial" ? "active" : ""}`}
          onClick={() => setActiveTab("Financial")}
        >
          Financial
        </li>
        <li
          className={`nav-item d-none ${activeTab === "SMS" ? "active" : ""}`}
          onClick={() => setActiveTab("SMS")}
        >
          SMS
        </li>
        {!!survey && !!survey?.look && (
          <li
            className={`nav-item ${activeTab === "Survey" ? "active" : ""}`}
            onClick={() => setActiveTab("Survey")}
          >
            Survey Q&A
          </li>
        )}
        {!!FICO && !!FICO[0] && (
          <li
            className={`nav-item ${activeTab === "Credit" ? "active" : ""}`}
            onClick={() => setActiveTab("Credit")}
          >
            Credit
          </li>
        )}
        {!!ModeledFICOScore && (
          <li
            className={`nav-item ${activeTab === "Modeled" ? "active" : ""}`}
            onClick={() => setActiveTab("Modeled")}
          >
            Modeled Credit
          </li>
        )}
        {!!clientNotes && (
          <li
            className={`nav-item ${activeTab === "Client" ? "active" : ""}`}
            onClick={() => setActiveTab("Client")}
          >
            Client Notes
          </li>
        )}
        {!!fbnotes && (
          <li
            className={`nav-item ${activeTab === "Campaign" ? "active" : ""}`}
            onClick={() => setActiveTab("Campaign")}
          >
            Campaign Notes
          </li>
        )}
      </ul>
      <div className="tab-content">
        <div className={`${activeTab === "Notes" ? "d-block" : "d-none"}`}>
          <div
            className="px-2"
            dangerouslySetInnerHTML={{ __html: note }}
          ></div>
        </div>
        <div className={`${activeTab === "Vehicle" ? "d-block" : "d-none"}`}>
          <h3 className="table-title">Trade-In</h3>
          <table className="table">
            <tbody>
              <tr>
                <th className="tbl-col-width-20">Year</th>
                <td className="tbl-col-width-30">
                  <select
                    className="editable w-100"
                    id="TradeInYear"
                    name="TradeInYear"
                    value={Year}
                    onChange={(e) => onChangeTradeInYear(e)}
                  >
                    <option value=""></option>
                    {!!years &&
                      years.map((yr, index) => (
                        <option value={yr} key={index}>
                          {yr}
                        </option>
                      ))}
                  </select>
                </td>
                <th className="tbl-col-width-20">Mileage</th>
                <td className="tbl-col-width-30">
                  <input
                    type="text"
                    className="editable mileage w-100"
                    value={Mileage}
                    name="Mileage"
                    onChange={(e) => setMileage(e.target.value)}
                    onBlur={(e) => onSaveVehicle(e)}
                    onKeyUp={(e) => onSaveVehicle(e)}
                  />
                </td>
              </tr>
              <tr>
                <th className="tbl-col-width-20">Make</th>
                <td className="tbl-col-width-30">
                  <select
                    className="editable w-100"
                    id="TradeInMake"
                    name="TradeInMake"
                    value={Make}
                    onChange={(e) => onChangeTradeInMake(e)}
                  >
                    <option value=""></option>
                    {!!makes && !makes.includes(Make) && (
                      <option value={Make}>{Make}</option>
                    )}
                    {!!makes &&
                      makes.map((mk, index) => (
                        <option value={mk} key={index}>
                          {mk}
                        </option>
                      ))}
                  </select>
                </td>
                <th className="tbl-col-width-20">Book Value</th>
                <td className="tbl-col-width-30">
                  <input
                    type="text"
                    className="editable currency w-100"
                    value={BookValue}
                    name="Book_value"
                    onChange={(e) => setBookValue(e.target.value)}
                    onBlur={(e) => onSaveVehicle(e)}
                    onKeyUp={(e) => onSaveVehicle(e)}
                  />
                </td>
              </tr>
              <tr>
                <th className="tbl-col-width-20">Model</th>
                <td className="tbl-col-width-30">
                  <select
                    className="editable w-100"
                    id="TradeInModel"
                    name="TradeInModel"
                    value={Model}
                    onChange={(e) => onChangeTradeInModel(e)}
                  >
                    <option value=""></option>
                    {!!models && !models.includes(Model) && (
                      <option value={Model}>{Model}</option>
                    )}
                    {!!models &&
                      models.map((md, index) => (
                        <option value={md} key={index}>
                          {md}
                        </option>
                      ))}
                  </select>
                </td>
                <th className="tbl-col-width-20">Payment</th>
                <td className="tbl-col-width-30">
                  <input
                    type="text"
                    className="editable currency w-100"
                    value={Payment}
                    name="Payment"
                    onChange={(e) => setPayment(e.target.value)}
                    onBlur={(e) => onSaveVehicle(e)}
                    onKeyUp={(e) => onSaveVehicle(e)}
                  />
                </td>
              </tr>
              <tr>
                <th className="tbl-col-width-20">Trim</th>
                <td className="tbl-col-width-30">
                  <select
                    className="editable w-100"
                    id="TradeInTrim"
                    name="TradeInTrim"
                    value={Trim}
                    onChange={(e) => setTrim(e.target.value)}
                  >
                    <option value=""></option>
                    {!!trims &&
                      trims.map((trm, index) => (
                        <option value={trm} key={index}>
                          {trm}
                        </option>
                      ))}
                  </select>
                </td>
                <th className="tbl-col-width-20">APR</th>
                <td className="tbl-col-width-30">
                  <input
                    type="text"
                    className="editable currency w-100"
                    value={Apr}
                    name="Apr"
                    onChange={(e) => setApr(e.target.value)}
                    onBlur={(e) => onSaveVehicle(e)}
                    onKeyUp={(e) => onSaveVehicle(e)}
                  />
                </td>
              </tr>
              <tr>
                <th className="tbl-col-width-20"></th>
                <td className="tbl-col-width-30"></td>
                <th className="tbl-col-width-20">Payoff</th>
                <td className="tbl-col-width-30">
                  <input
                    type="text"
                    className="editable currency w-100"
                    value={Payoff}
                    name="Payoff"
                    onChange={(e) => setPayoff(e.target.value)}
                    onBlur={(e) => onSaveVehicle(e)}
                    onKeyUp={(e) => onSaveVehicle(e)}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <h3 className="table-title mt-3">Vehicle Of Interest</h3>
          <table className="table">
            <tbody>
              <tr>
                <th className="tbl-col-width-20">Year</th>
                <td className="tbl-col-width-30">
                  <select
                    className="editable w-100"
                    id="InterestYear"
                    name="InterestYear"
                    value={InterestYear}
                    onChange={(e) => onChangeInterestYear(e)}
                  >
                    <option value=""></option>
                    {!!years &&
                      years.map((yr, index) => (
                        <option value={yr} key={index}>
                          {yr}
                        </option>
                      ))}
                  </select>
                </td>
                <th className="tbl-col-width-20">VIN</th>
                <td className="tbl-col-width-30">
                  <input
                    type="text"
                    className="editable w-100"
                    name="InterestVIN"
                    value={InterestVIN}
                    onChange={(e) => setInterestVIN(e.target.value)}
                    maxLength="17"
                    onBlur={(e) => onSaveVehicle(e)}
                    onKeyUp={(e) => onSaveVehicle(e)}
                  />
                </td>
              </tr>
              <tr>
                <th className="tbl-col-width-20">Make</th>
                <td className="tbl-col-width-30">
                  <select
                    className="editable w-100"
                    id="InterestMake"
                    name="InterestMake"
                    value={InterestMake}
                    onChange={(e) => onChangeInterestMake(e)}
                  >
                    <option value=""></option>
                    {!!imakes && !imakes.includes(InterestMake) && (
                      <option value={InterestMake}>{InterestMake}</option>
                    )}
                    {!!imakes &&
                      imakes.map((imk, index) => (
                        <option value={imk} key={index}>
                          {imk}
                        </option>
                      ))}
                  </select>
                </td>
                <th className="tbl-col-width-20">Stock #</th>
                <td className="tbl-col-width-30">
                  <input
                    type="text"
                    className="editable w-100"
                    name="Stock"
                    value={Stock}
                    onChange={(e) => setStock(e.target.value)}
                    onBlur={(e) => onSaveVehicle(e)}
                    onKeyUp={(e) => onSaveVehicle(e)}
                  />
                </td>
              </tr>
              <tr>
                <th className="tbl-col-width-20">Model</th>
                <td className="tbl-col-width-30">
                  <select
                    className="editable w-100"
                    id="InterestModel"
                    name="InterestModel"
                    value={InterestModel}
                    onChange={(e) => onChangeInterestModel(e)}
                  >
                    <option value=""></option>
                    {!!imodels && !imodels.includes(InterestModel) && (
                      <option value={InterestModel}>{InterestModel}</option>
                    )}
                    {!!imodels &&
                      imodels.map((imd, index) => (
                        <option value={imd} key={index}>
                          {imd}
                        </option>
                      ))}
                  </select>
                </td>
                <th className="tbl-col-width-20"></th>
                <td className="tbl-col-width-30"></td>
              </tr>
              <tr>
                <th className="tbl-col-width-20">Trim</th>
                <td className="tbl-col-width-30">
                  <select
                    className="editable w-100"
                    id="InterestTrim"
                    name="InterestTrim"
                    value={InterestTrim}
                    onChange={(e) => setInterestTrim(e.target.value)}
                  >
                    <option value=""></option>
                    {!!itrims &&
                      itrims.map((itrm, index) => (
                        <option value={itrm} key={index}>
                          {itrm}
                        </option>
                      ))}
                  </select>
                </td>
                <th className="tbl-col-width-20"></th>
                <td className="tbl-col-width-30"></td>
              </tr>
            </tbody>
          </table>
          {/* phone only */}
          <table className="table d-md-none d-lg-none d-xl-none">
            <tbody>
              <tr>
                <th>Vehicle</th>
                <td>
                  <input
                    type="text"
                    className="editable w-100"
                    defaultValue={`${Year} ${Make} ${Model}`}
                  />
                </td>
              </tr>
              <tr>
                <th>Mileage</th>
                <td>{Mileage}</td>
              </tr>
              <tr>
                <th>Book Value</th>
                <td className="currency-w">
                  <input
                    type="text"
                    className="editable currency"
                    value={BookValue}
                  />
                </td>
              </tr>
              <tr>
                <th>APR</th>
                <td>
                  <input type="text" className="editable w-100" value={Apr} />
                </td>
              </tr>
              <tr>
                <th>Payment</th>
                <td className="currency-w" value={Payment}></td>
              </tr>
              <tr>
                <th>Payoff</th>
                <td>
                  <input
                    type="text"
                    className="editable w-100"
                    value={Payoff}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className={`${activeTab === "Financial" ? "d-block" : "d-none"}`}>
          <table className="table">
            <tbody>
              <tr>
                <th className="tbl-col-width-20">DOB</th>
                <td className="tbl-col-width-30">
                  <input
                    type="text"
                    className="editable dob w-100"
                    name="Dob"
                    value={Dateofbirth}
                    onChange={(e) => setDateofbirth(e.target.value)}
                    onBlur={(e) => onSaveVehicle(e)}
                    onKeyUp={(e) => onSaveVehicle(e)}
                  />
                </td>
                <th className="tbl-col-width-20">SSN</th>
                <td className="tbl-col-width-30">
                  <input
                    type="text"
                    className="editable ssn w-100"
                    name="Ssn"
                    value={Ssn}
                    onChange={(e) => setSsn(e.target.value)}
                    onBlur={(e) => onSaveVehicle(e)}
                    onKeyUp={(e) => onSaveVehicle(e)}
                  />
                </td>
              </tr>
              <tr>
                <th className="tbl-col-width-20">Monthly Income</th>
                <td className="tbl-col-width-30">
                  <input
                    type="text"
                    className="editable currency w-100"
                    name="MonthlyIncome"
                    value={MonthlyIncome}
                    onChange={(e) => setMonthlyIncome(e.target.value)}
                    onBlur={(e) => onSaveVehicle(e)}
                    onKeyUp={(e) => onSaveVehicle(e)}
                  />
                </td>
                <th className="tbl-col-width-20">Housing Cost</th>
                <td className="tbl-col-width-30">
                  <input
                    type="text"
                    className="editable currency w-100"
                    name="HousingCost"
                    value={HousingCost}
                    onChange={(e) => setHousingCost(e.target.value)}
                    onBlur={(e) => onSaveVehicle(e)}
                    onKeyUp={(e) => onSaveVehicle(e)}
                  />
                </td>
              </tr>
              <tr>
                <th className="tbl-col-width-20">Employer</th>
                <td className="tbl-col-width-30">
                  <input
                    type="text"
                    className="editable w-100"
                    name="Employer"
                    value={Employer}
                    onChange={(e) => setEmployer(e.target.value)}
                    onBlur={(e) => onSaveVehicle(e)}
                    onKeyUp={(e) => onSaveVehicle(e)}
                  />
                </td>
                <th className="tbl-col-width-20">Housing Type</th>
                <td className="tbl-col-width-30">
                  <select
                    name="HousesingType"
                    id="HousesingType"
                    className="editable w-100"
                    value={HousingType}
                    onChange={(e) => setHousingType(e.target.value)}
                  >
                    <option value=""></option>
                    <option value="own">Own</option>
                    <option value="rent">Rent</option>
                  </select>
                </td>
              </tr>
              <tr>
                <th className="tbl-col-width-20">Time Employed</th>
                <td className="tbl-col-width-30">
                  <select
                    name="TimeEmployed"
                    id="TimeEmployed"
                    className="editable w-100"
                    value={Timeemployed}
                    onChange={(e) => setTimeemployed(e.target.value)}
                  >
                    <option value=""></option>
                    <option value="1">Less than 1 year</option>
                    <option value="2">1 - 3 years</option>
                    <option value="3">3 - 5 years</option>
                    <option value="4">5 - 10 years</option>
                    <option value="5">10+ years</option>
                  </select>
                </td>
                <th className="tbl-col-width-20">Housing Length</th>
                <td className="tbl-col-width-30">
                  <select
                    name="HousesingLength"
                    id="HousesingLength"
                    className="editable w-100"
                    value={Housinglength}
                    onChange={(e) => setHousinglength(e.target.value)}
                  >
                    <option value=""></option>
                    <option value="1">Less than 1 year</option>
                    <option value="2">1 - 3 years</option>
                    <option value="3">3 - 5 years</option>
                    <option value="4">5 - 10 years</option>
                    <option value="5">10+ years</option>
                  </select>
                </td>
              </tr>
            </tbody>
          </table>
          {/*  phone only  */}
          <table className="table d-md-none d-lg-none d-xl-none">
            <tbody>
              <tr>
                <th>Payment</th>
                <td>
                  <input
                    type="text"
                    className="editable w-100"
                    value={Dpayment}
                    onChange={(e) => setDpayment(e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <th>DOB</th>
                <td>
                  <input
                    type="text"
                    className="editable w-100"
                    defaultValue={
                      Dob?.type6.year !== "0000"
                        ? `${Dob?.type6.month[0]}/
                        ${Financial?.Dob.type6.day}/${Financial?.Dob.type6.year}`
                        : ""
                    }
                  />
                </td>
              </tr>
              <tr>
                <th>SSN</th>
                <td>
                  <input
                    type="text"
                    className="editable w-100"
                    defaultValue={Ssn !== "" ? Ssn : ""}
                  />
                </td>
              </tr>
              <tr>
                <th>Employer</th>
                <td>
                  <input
                    type="text"
                    className="editable w-100"
                    value={Employer}
                    onChange={(e) => setEmployer(e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <th>Occupation</th>
                <td>
                  <input
                    type="text"
                    className="editable w-100"
                    value={Occupation}
                    onChange={(e) => setOccupation(e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <th>Time Employed</th>
                <td>
                  <input
                    type="text"
                    className="editable w-100"
                    value={Timeemployed}
                    onChange={(e) => setTimeemployed(e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <th>Monthly Income</th>
                <td>
                  <input
                    type="text"
                    className="editable w-100"
                    value={MonthlyIncome}
                    onChange={(e) => setMonthlyIncome(e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <th>Housing Type</th>
                <td>
                  <input
                    type="text"
                    className="editable w-100"
                    value={HousingType}
                    onChange={(e) => setHousingType(e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <th>Housing Cost</th>
                <td>
                  <input
                    type="text"
                    className="editable w-100"
                    value={HousingCost}
                    onChange={(e) => setHousingCost(e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <th>Housing Length</th>
                <td>
                  <input
                    type="text"
                    className="editable w-100"
                    value={Housinglength}
                    onChange={(e) => setHousinglength(e.target.value)}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className={`${activeTab === "SMS" ? "d-block" : "d-none"}`}>
          <div className="px-2" dangerouslySetInnerHTML={{ __html: sms }} />
        </div>
        <div className={`${activeTab === "Credit" ? "d-block" : "d-none"}`}>
          <table className="table">
            <tbody>
              <tr>
                <td className="tbl-col-width-30">
                  <h5 className="mb-0">
                    Credit Score {Address?.FICO ? Address?.FICO[0] : ""}
                  </h5>
                </td>
                <td align="center">
                  <div className="img-wrap">
                    <img
                      style={{ maxWidth: "50px", height: "auto" }}
                      src={`${config.baseUrl}/assets/images/car1-50x25.png`}
                    />
                  </div>
                </td>
                <td align="center">
                  <div className="img-wrap">
                    <img
                      style={{ maxWidth: "50px", height: "auto" }}
                      src={`${config.baseUrl}/assets/images/car2-50x25.png"`}
                    />
                  </div>
                </td>
                <td align="center">
                  <div className="img-wrap">
                    <img
                      style={{ maxWidth: "50px", height: "auto" }}
                      src={`${config.baseUrl}/assets/images/car3-50x25.png`}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>Type{AutoType}</th>
                <td align="center">{AutoType ? AutoType[0] : ""}</td>
                <td align="center">{AutoType ? AutoType[1] : ""}</td>
                <td align="center">{AutoType ? AutoType[2] : ""}</td>
              </tr>
              <tr>
                <th>Finance</th>
                <td align="center">
                  {!!HighCreditAmount && HighCreditAmount[0] !== ""
                    ? `$${HighCreditAmount[0]},000`
                    : ""}
                </td>
                <td align="center">
                  {!!HighCreditAmount && HighCreditAmount[1] !== ""
                    ? `$${HighCreditAmount[1]},000`
                    : ""}
                </td>
                <td align="center">
                  {!!HighCreditAmount && HighCreditAmount[2] !== ""
                    ? `$${HighCreditAmount[2]},000`
                    : ""}
                </td>
              </tr>
              <tr>
                <th>Interest Rate</th>
                <td align="center">{InterestRate ? InterestRate[0] : ""}</td>
                <td align="center">{InterestRate ? InterestRate[1] : ""}</td>
                <td align="center">{InterestRate ? InterestRate[2] : ""}</td>
              </tr>
              <tr>
                <th>Months to Expire</th>
                <td align="center">{Expiration ? Expiration[0] : ""}</td>
                <td align="center">{Expiration ? Expiration[1] : ""}</td>
                <td align="center">{Expiration ? Expiration[2] : ""}</td>
              </tr>
              <tr>
                <th>Payment</th>
                <td align="center">{PPayment ? PPayment[0] : ""}</td>
                <td align="center">{PPayment ? PPayment[1] : ""}</td>
                <td align="center">{PPayment ? PPayment[2] : ""}</td>
              </tr>
              <tr>
                <th>Percent Paid</th>
                <td align="center">{PercentPaid ? PercentPaid[0] : ""}</td>
                <td align="center">{PercentPaid ? PercentPaid[1] : ""}</td>
                <td align="center">{PercentPaid ? PercentPaid[2] : ""}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className={`${activeTab === "Modeled" ? "d-block" : "d-none"}`}>
          <table className="table">
            <tbody>
              <tr>
                <th>Credit Score</th>
                <td align="center">{ModeledFICOScore}</td>
              </tr>
              <tr>
                <th>Ability to Buy Score</th>
                <td align="center">{AbilityToFinance}</td>
              </tr>
              <tr>
                <th>Estimated Income</th>
                <td align="center">{EstimatedIncome}</td>
              </tr>
              <tr>
                <th>Home Type</th>
                <td align="center">{HomeownerRenter}</td>
              </tr>
              <tr>
                <th>Length of Residency</th>
                <td align="center">{LengthOfResidence}</td>
              </tr>
              <tr>
                <th>Marital Status</th>
                <td align="center">{MaritalStatus}</td>
              </tr>
              <tr>
                <th>Car Ownership</th>
                <td align="center">{CarOwnership}</td>
              </tr>
              <tr>
                <th>Ethnicity</th>
                <td align="center">{Ethnicity}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          className={`${
            activeTab === "Client" || activeTab === "Campaign"
              ? "d-block"
              : "d-none"
          }`}
        >
          {!!clientNotes && (
            <div
              className="px-2"
              dangerouslySetInnerHTML={{ __html: helper.nl2br(clientNotes) }}
            ></div>
          )}
          {!!fbnotes && (
            <div
              className="px-2"
              dangerouslySetInnerHTML={{ __html: helper.nl2br(fbnotes) }}
            ></div>
          )}
        </div>
      </div>
    </div>
  );
}
