import React from "react";
import { Box, InputLabel, MenuItem, Select } from "@mui/material";
import { ColorsConfig } from "../../config";

export default function PrimarySelect(props) {
  const { options, value, handleChange, label, required, sx } = props;

  return (
    <Box sx={{ minWidth: 120 }}>
      {label && (
        <InputLabel
          sx={{ fontSize: "16px", color: ColorsConfig.light.primaryColor }}
        >
          {label}
          {required && (
            <span style={{ color: ColorsConfig.light.error }}>*</span>
          )}
        </InputLabel>
      )}
      <Select
        margin="none"
        value={value}
        onChange={handleChange}
        size={"small"}
        fullWidth
        sx={{
          ...sx,
          "& fieldset": {
            borderRadius: "4px",
          },
          mt: 0.5,
        }}
      >
        {options.map((item, index) => {
          return (
            <MenuItem value={item.value} key={index}>
              {item.name}
            </MenuItem>
          );
        })}
      </Select>
    </Box>
  );
}
