import React, { useContext, useState, useEffect } from "react";
import {
  BiCalendar,
  BiCalendarCheck,
  BiCalendarStar,
  BiMessageRoundedDots,
} from "react-icons/bi";
import {
  BsActivity,
  BsArrowDown,
  BsArrowUp,
  BsClock,
  BsPersonSlash,
  BsRobot,
  BsStar,
} from "react-icons/bs";
import { HiOutlineCpuChip, HiOutlineCurrencyDollar } from "react-icons/hi2";
import { IoCarSportOutline } from "react-icons/io5";
import { LiaHandshake, LiaTachometerAltSolid } from "react-icons/lia";
import { DateFilterTypePicker, DropdownMultiSelect } from "../../components";
import moment from "moment";
import { AuthContext, ReportContext } from "../../context";
import { useNavigate } from "react-router-dom";

export default function ActivityReportPage() {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const {
    getSmsReportApi,
    getleadbyclientApi,
    getleadbydealerApi,
    smsajaxrequestApi,
  } = useContext(ReportContext);
  //
  const [selectedDate, setSelectedDate] = useState(1);
  const [customDateRage, setCustomDateRange] = useState({
    start: new Date(moment().subtract(1, "months")),
    end: new Date(),
  });
  //
  const [clientList, setClientList] = useState([]);
  const [dealerList, setDealerList] = useState([]);
  const [customColor, setCustomColor] = useState("");
  const [baseData, setBaseData] = useState({
    csvclient: "",
    csvdealer: "",
    leadlist: "",
    usertype: "",
    csvlead: "",
  });
  //
  const [filterClient, setFilterClient] = useState([]);
  const [filterDealer, setFilterDealer] = useState([]);
  //
  const [ajaxData, setAjaxData] = useState(undefined);
  const [globalTodayLeads, setGlobalTodayLeads] = useState([]);
  const [globalAppLeads, setGlobalAppLeads] = useState([]);
  const [globalEngagementLeads, setGlobalEngagementLeads] = useState([]);
  const [globalAppsShow, setGlobalAppsShow] = useState([]);
  const [globalVouchersCount, setGlobalVouchersCount] = useState([]);
  const [globalSoldLeads, setGlobalSoldLeads] = useState([]);
  const [globalOptedoutLeads, setGlobalOptedoutLeads] = useState([]);
  // --
  const [globalAuthorizedUser, setglobalAuthorizedUser] = useState([]);
  // --
  const [globalTextCount, setGlobalTextCount] = useState(0);
  const [globalTextInboundCount, setGlobalTextInboundCount] = useState(0);
  const [globalTextOutboundCount, setGlobalTextOutboundCount] = useState(0);
  const [globalRobotCount, setGlobalRobotCount] = useState(0);
  const [globalPhoneInboundCount, setGlobalPhoneInboundCount] = useState(0);
  const [globalPhoneOutboundCount, setGlobalPhoneOutboundCount] = useState(0);
  const [globalOutboundWaitingTime, setGlobalOutboundWaitingTime] = useState(0);
  const [globalOutboundWaitingLead, setGlobalOutboundWaitingLead] = useState(0);
  const [globalPhoneRecordingduration, setGlobalPhoneRecordingduration] =
    useState(0);
  const [carlyai, setCarlyai] = useState("");
  const [carlyaibutton, setCarlyaibutton] = useState("");
  const [users, setUsers] = useState([]);
  const [userSearch, setUserSearch] = useState("");

  useEffect(() => {
    getSmsReportData();
    console.log(customColor);
    console.log(carlyaibutton);
    console.log(carlyai);
    console.log(ajaxData);
    console.log(globalVouchersCount);
    console.log(globalAuthorizedUser);
  }, []);

  const getSmsReportData = async () => {
    const data = await getSmsReportApi({ userId: user.Id });
    if (data.status === "ok") {
      const res = data.response;
      if (res?.clientdetails) {
        setClientList(res?.clientdetails);
      }
      if (res?.dealerdetails) {
        setDealerList(res?.dealerdetails);
      }
      setCustomColor(res?.custom_color);
      //
      let _baseData = { ...baseData };
      _baseData.csvclient = res?.clientid;
      _baseData.csvdealer = res?.dealerid;
      _baseData.usertype = res?.usertype;
      _baseData.csvlead = res?.leadscsv;
      setBaseData(_baseData);
      //
      ajaxrequest(_baseData);
    }
  };

  useEffect(() => {
    if (selectedDate !== 5) {
      ajaxrequest(baseData);
    }
  }, [selectedDate]);

  useEffect(() => {
    if (selectedDate === 5) {
      ajaxrequest(baseData);
    }
  }, [customDateRage]);

  const filterByClient = async (values) => {
    let _params = {
      userId: user.Id,
      client: values,
    };
    const data = await getleadbyclientApi(_params);
    if (data.status === "ok") {
      const res = data.response;
      let _baseData = {
        ...baseData,
        csvclient: values,
        csvlead: res?.lead,
      };
      setBaseData(_baseData);
      // setCsvlead(res.lead);
      ajaxrequest(_baseData);
    }
  };

  const filterByDealer = async (values) => {
    let _params = {
      userId: user.Id,
      dealer: values,
    };
    const data = await getleadbydealerApi(_params);
    if (data.status === "ok") {
      const res = data.response;
      let _baseData = {
        ...baseData,
        csvclient: res?.client,
        csvlead: res?.lead,
      };
      setBaseData(_baseData);
      ajaxrequest(_baseData);
    }
  };

  const ajaxrequest = async (_baseData) => {
    let _params = {};
    _params.userId = user.Id;
    _params.datetype = selectedDate;
    _params.clientcsv = _baseData.csvclient;
    _params.leadcsv = _baseData.csvlead;
    if (_params.datetype === 5) {
      _params.start = customDateRage.start;
      _params.end = customDateRage.end;
    }

    const data = await smsajaxrequestApi(_params);
    if (data.status === "ok") {
      const res = data.response;
      setAjaxData(res);
      setGlobalTodayLeads(split_leads(res?.info.lead));
      setGlobalAppLeads(split_leads(res?.info.app));
      setGlobalEngagementLeads(split_leads(res?.info.engagement));
      setGlobalAppsShow(split_leads(res?.info.apps));
      setGlobalVouchersCount(split_leads(res?.info.vouchers));
      setGlobalSoldLeads(split_leads(res?.info.sold));
      setGlobalOptedoutLeads(split_leads(res?.info.optedout));
      //
      if (parseInt(res?.usertype) > 1) {
        setglobalAuthorizedUser(split_leads(res?.authorized_user));
      }
      //
      setGlobalTextCount(0);
      setGlobalTextInboundCount(parseInt(res?.text.Inbound_sms));
      setGlobalTextOutboundCount(parseInt(res?.text.Outbound_sms));
      setGlobalRobotCount(parseInt(res?.text.Robot));
      setGlobalPhoneInboundCount(parseInt(res?.text.Inbound_phone));
      setGlobalPhoneOutboundCount(parseInt(res?.text.Outbound_phone));
      setGlobalOutboundWaitingTime(parseInt(res?.waiting.waiting_duration));
      setGlobalOutboundWaitingLead(parseInt(res?.waiting.waitinglead));
      setGlobalPhoneRecordingduration(parseInt(res?.text.Duration));
      // =
      setCarlyai(res?.carlyai);
      setCarlyaibutton(res?.carlyaibutton);
      setUsers(JSON.parse(addslashes(res?.userjson)));
    }
  };

  const addslashes = (json) => {
    return json.replace(/'/g, "\\'");
  };

  const split_leads = (leadscsv) => {
    let temp = [];
    let leads = leadscsv?.split(",");
    if (leads.length === 1) {
      if (!leads[0]) {
        return temp;
      } else {
        temp[0] = parseInt(leads[0]);
        return temp;
      }
    }
    for (let i = 0; i < leads.length; i++) {
      temp.push(parseInt(leads[i]));
    }
    return temp;
  };

  const getapp_per = (lead, app) => {
    if (lead == 0) {
      return 0;
    }
    if (app == 0) {
      return 0;
    }
    return parseInt((app * 100) / lead);
  };

  const getwaitingtime = (lead, time) => {
    if (time == 0) {
      return "N/A";
    }
    let s = parseInt(time / lead / 60);
    if (s == 0) {
      return "N/A";
    } else {
      return callduration(s);
    }
  };

  const callduration = (val) => {
    let pen = 0;
    let hour = parseInt(val / 3600);
    pen = val % 3600;
    let min = parseInt(pen / 60);
    pen = pen % 60;
    let sec = Math.round(pen);
    if (hour == 0) {
      hour = "00";
    } else if (hour < 10) {
      hour = "0" + hour;
    }
    if (min == 0) {
      min = "00";
    } else if (min < 10) {
      min = "0" + min;
    }
    if (sec == 0) {
      sec = "00";
    } else if (sec < 10) {
      sec = "0" + sec;
    }
    return hour + ":" + min + ":" + sec;
  };

  const showleads = (from) => {
    let leadstr = "";
    if (from == 1) {
      leadstr = globalTodayLeads.join(",");
    } else if (from == 2) {
      leadstr = globalAppLeads.join(",");
    } else if (from == 3) {
      leadstr = globalAppsShow.join(",");
    } else if (from == 4) {
      leadstr = globalSoldLeads.join(",");
    } else if (from == 5) {
      leadstr = globalOptedoutLeads.join(",");
    }
    let param = {};
    param["lead"] = leadstr;
    param["from"] = from;
    //
    window.sessionStorage.setItem("reports_lead", leadstr);
    window.sessionStorage.setItem("reports_from", from);
    navigate(`/reports/leads`);
  };

  return (
    <main className="container py-4">
      <div className="d-flex align-items-center">
        <div className="section-title">
          <BsActivity className="icon me-2" />
          Activity Report
        </div>
      </div>
      <div className="row mt-2">
        <div className="col col-sm-8 py-1">
          <DateFilterTypePicker
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            customDateRage={customDateRage}
            setCustomDateRange={setCustomDateRange}
          />
        </div>
        <div className="col col-sm-2">
          <DropdownMultiSelect
            id="clients"
            className="w-100 mb-2"
            options={clientList.map((item) => {
              return { Id: item.Id, Name: item.Name };
            })}
            placeholder={"Select Clients"}
            labelField="Name"
            valueField="Id"
            values={filterClient}
            onChange={(values) => setFilterClient(values)}
            onDropdownClose={(values) => {
              filterByClient(values);
            }}
          />
        </div>
        <div className="col col-sm-2">
          <DropdownMultiSelect
            id="agent_group"
            className="w-100 mb-2"
            options={dealerList.map((item) => {
              return { Id: item.Id, Name: item.Name };
            })}
            placeholder={"Select Agent Group"}
            labelField="Name"
            valueField="Id"
            values={filterDealer}
            onChange={(values) => setFilterDealer(values)}
            onDropdownClose={(values) => {
              filterByDealer(values);
            }}
          />
        </div>
      </div>
      <div className="card mt-2">
        <div className="card-header">Leads</div>
        <div className="card-body">
          {
            <table className="table">
              <tbody>
                <tr>
                  <td scope="row">
                    <BsStar className="me-2" /> Leads
                  </td>
                  <td className="text-end">
                    <a onClick={() => showleads(1)} href="javascript:void(0)">
                      <span id="leads_count_container">
                        {globalTodayLeads.length}
                      </span>
                    </a>
                  </td>
                </tr>
                <tr>
                  <td scope="row">
                    <LiaHandshake className="me-2" /> Engagement Rate
                  </td>
                  <td className="text-end">
                    <span id="engagment_count_container">
                      {getapp_per(
                        globalTodayLeads.length,
                        globalEngagementLeads.length,
                      )}
                    </span>
                    %
                  </td>
                </tr>
                <tr>
                  <td scope="row">
                    <BiCalendar className="me-2" /> Appointments
                  </td>
                  <td className="text-end">
                    <a onClick={() => showleads(2)} href="javascript:void(0)">
                      <span id="appointments_count_container">
                        {globalAppLeads.length}
                      </span>
                    </a>
                  </td>
                </tr>
                <tr>
                  <td scope="row">
                    <BiCalendarStar className="me-2" /> Appointment Rate
                  </td>
                  <td className="text-end">
                    <span id="appointmentsrate_count_container">
                      {getapp_per(
                        globalTodayLeads.length,
                        globalAppLeads.length,
                      )}
                    </span>
                    %
                  </td>
                </tr>
                <tr>
                  <td scope="row">
                    <BiCalendarCheck className="me-2" /> Appointment Show
                  </td>
                  <td className="text-end">
                    <a onClick={() => showleads(3)} href="javascript:void(0)">
                      <span id="appointment_show_container">
                        {globalAppsShow.length}
                      </span>
                    </a>
                  </td>
                </tr>
                <tr>
                  <td scope="row">
                    <IoCarSportOutline className="me-2" /> Sold
                  </td>
                  <td className="text-end">
                    <a onClick={() => showleads(4)} href="javascript:void(0)">
                      <span id="sold_container">{globalSoldLeads.length}</span>
                    </a>
                  </td>
                </tr>
                <tr>
                  <td scope="row">
                    <BsPersonSlash className="me-2" /> Opt-out
                  </td>
                  <td className="text-end">
                    <a onClick={() => showleads(5)} href="javascript:void(0)">
                      <span id="optout_container">
                        {globalOptedoutLeads.length}
                      </span>
                    </a>
                  </td>
                </tr>
                <tr>
                  <td scope="row">
                    <HiOutlineCurrencyDollar className="me-2" /> Cost Per Lead
                  </td>
                  <td className="text-end">N/A</td>
                </tr>
              </tbody>
            </table>
          }
        </div>
      </div>
      <div className="card mt-3">
        <div className="card-header">Messages</div>
        <div className="card-body">
          {
            <table className="table">
              <tbody>
                <tr>
                  <td scope="row">
                    <LiaTachometerAltSolid className="me-2" /> Avg. Response
                    Time
                  </td>
                  <td className="text-end">
                    <span id="waiting_container">
                      {getwaitingtime(
                        globalOutboundWaitingLead,
                        globalOutboundWaitingTime,
                      )}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td scope="row">
                    <BiMessageRoundedDots className="me-2" /> Total
                  </td>
                  <td className="text-end">
                    <span id="total_send_sms_container">{globalTextCount}</span>
                  </td>
                </tr>
                <tr>
                  <td scope="row">
                    <BsArrowDown className="me-2" /> Inbound
                  </td>
                  <td className="text-end">
                    <span id="inbound_send_sms_container">
                      {globalTextInboundCount}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td scope="row">
                    <BsArrowUp className="me-2" /> Outbound
                  </td>
                  <td className="text-end">
                    <span id="outbound_send_sms_container">
                      {globalTextOutboundCount}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td scope="row">
                    <BsRobot className="me-2" /> Robot
                  </td>
                  <td className="text-end">
                    <span id="robot_send_sms_container">
                      {globalRobotCount}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td scope="row">
                    <HiOutlineCpuChip className="me-2" /> Carly AI
                  </td>
                  <td className="text-end">
                    <span id="carly_ai_sent_msg_count">0</span>
                  </td>
                </tr>
                <tr>
                  <td scope="row">
                    <HiOutlineCpuChip className="me-2" /> AI button click
                  </td>
                  <td className="text-end">
                    <span id="ai_button_click_count">0</span>
                  </td>
                </tr>
              </tbody>
            </table>
          }
        </div>
      </div>
      <div className="card mt-3">
        <div className="card-header">Phone Calls</div>
        <div className="card-body">
          {
            <table className="table">
              <tbody>
                <tr>
                  <td scope="row">
                    <BsArrowDown className="me-2" /> Inbound
                  </td>
                  <td className="text-end">
                    <span id="inboundcall_call_container">
                      {globalPhoneInboundCount}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td scope="row">
                    <BsArrowUp className="me-2" /> Outbound
                  </td>
                  <td className="text-end">
                    <span id="outboundcall_call_container">
                      {globalPhoneOutboundCount}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td scope="row">
                    <BsClock className="me-2" /> Average Talk Time
                  </td>
                  <td className="text-end">
                    <span id="callduaration_container">
                      {globalPhoneRecordingduration}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          }
        </div>
      </div>
      <div className="card mt-3">
        <div className="card-header">
          <div className="d-flex w-100 align-items-center justify-content-between">
            User Activity
            <input
              type="text"
              className="form-control"
              style={{ maxWidth: "150px" }}
              value={userSearch}
              onChange={(e) => setUserSearch(e.target.value)}
            />
          </div>
        </div>
        <div className="card-body p-0">
          <table
            className="table table-hover m-0"
            data-filtering="true"
            data-sorting="true"
          >
            <thead>
              <tr>
                <th data-sortable="false" width="1"></th>
                <th
                  data-sortable="false"
                  width="50"
                  data-hide="phone"
                  data-breakpoints="xs"
                ></th>
                <th>User</th>
                <th className="text-center">Leads Touched</th>
                <th className="text-center">Messages Sent</th>
                <th className="text-center">AI Button</th>
                <th
                  data-hide="phone"
                  data-breakpoints="xs"
                  className="text-center"
                >
                  Appointment Set
                </th>
                <th
                  data-hide="phone"
                  data-breakpoints="xs"
                  className="text-center"
                >
                  App Rate
                </th>
                <th
                  data-hide="phone"
                  data-breakpoints="xs"
                  className="text-center"
                >
                  Calls
                </th>
                <th
                  data-hide="phone"
                  data-breakpoints="xs"
                  className="text-center"
                >
                  Call Duration
                </th>
                <th
                  data-hide="phone"
                  data-breakpoints="xs"
                  className="text-center"
                >
                  Last Activity
                </th>
              </tr>
            </thead>
            <tbody id="useractivity">
              {users
                .filter((item) =>
                  item?.Agentname?.toLowerCase().includes(
                    userSearch?.toLowerCase(),
                  ),
                )
                .map((item, index) => (
                  <tr id={`row_${item.AgentId}`} key={index}>
                    <td></td>
                    <td scope="row" className="text-center">
                      <span className="img">
                        <img
                          src={item.profilepic}
                          className="rounded-circle thumbnail"
                          width={30}
                          height={30}
                          alt=""
                        />
                      </span>
                    </td>
                    <td>{item.Agentname}</td>
                    <td className="text-center">
                      <span id={`touched_${item.AgentId}`}>{item.touched}</span>
                    </td>
                    <td className="text-center">
                      <span id={`message_sent_${item.AgentId}`}>
                        {item.message_sent}
                      </span>
                    </td>
                    <td>
                      <span>{item.AI}</span>
                    </td>
                    <td className="text-center">
                      <span id={`app_${item.AgentId}`}>{item.app}</span>
                    </td>
                    <td className="text-center">
                      <span id={`appp_${item.AgentId}`}>{item.appp}%</span>
                    </td>
                    <td className="text-center">
                      <span id={`call_${item.AgentId}`}>{item.call}</span>
                    </td>
                    <td className="text-center">
                      <span id={`calldurationstr_${item.AgentId}`}>
                        {callduration(item.callduration)}
                      </span>
                    </td>
                    <td
                      id={`lastactivity_${item.AgentId}`}
                      className="text-center"
                    >
                      <abbr
                        style={{ fontSize: "11px" }}
                        className="timeago"
                        title={item.lastactivity}
                      >
                        {moment(item.lastactivity).fromNow()}
                      </abbr>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="card mt-3">
        <div className="card-header">Real-time Activity</div>
        <div className="card-body"></div>
      </div>
    </main>
  );
}
