import { LiaFunnelDollarSolid } from "react-icons/lia";
import {
  BsPerson,
  BsReplyAll,
  BsArrowRight,
  BsArrowLeft,
} from "react-icons/bs";
import React, { useContext, useEffect } from "react";
import { AuthContext, DashboardContext } from "../../context";
import { useState } from "react";
import { Box, Card, Skeleton, Stack, Typography } from "@mui/material";
import { FormatHelper } from "../../utils";
import { ColorsConfig } from "../../config";

export default function FunnelsMetricsBoard({ selectedDealershipIds = "" }) {
  const { user } = useContext(AuthContext);
  const { getAgentFunnel, agentFunnel } = useContext(DashboardContext);
  const [showLoading, setShowLoading] = useState(false);

  const itemStyle = {
    fontSize: 28,
    color: ColorsConfig.light.info,
    border: `1px solid ${ColorsConfig.light.info}`,
    borderRadius: "50%",
    padding: 3,
  };

  const items = [
    {
      component: (
        <BsPerson
          style={{
            ...itemStyle,
          }}
        />
      ),
      index: "lead",
      title: "Leads",
    },
    {
      component: (
        <BsArrowRight
          style={{
            ...itemStyle,
          }}
        />
      ),
      index: "sentmessage",
      title: "Messages Sent",
    },
    {
      component: (
        <BsArrowLeft
          style={{
            ...itemStyle,
          }}
        />
      ),
      index: "replies",
      title: "Replies",
    },
    {
      component: (
        <BsReplyAll
          style={{
            ...itemStyle,
          }}
        />
      ),
      index: "cal",
      title: "Response Rate",
    },
  ];

  useEffect(() => {
    getAgentFunnelData();
  }, []);

  const getAgentFunnelData = async () => {
    setShowLoading(true);
    const res = await getAgentFunnel({
      userId: user.Id,
      dealershipIds: selectedDealershipIds,
    });
    if (res) {
      setShowLoading(false);
    }
  };

  return (
    <Card
      sx={{
        p: 2,
        pt: 3,
        height: "100%",
      }}
    >
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"center"}
        spacing={1}
      >
        <LiaFunnelDollarSolid style={{ fontSize: 20, color: "inherit" }} />
        <Typography variant="body1">Funnels</Typography>
      </Stack>
      <Box mt={2}>
        {items.map((item, index) => {
          return (
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              key={index}
              sx={{
                borderBottom: `1px solid ${ColorsConfig.light.primaryBorderColor}`,
                paddingY: 1.5,
              }}
            >
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"start"}
                spacing={1}
              >
                {item.component}
                <Typography>{item.title}</Typography>
              </Stack>
              {showLoading ? (
                <Skeleton variant="rectangular" width={80} height={30} />
              ) : agentFunnel ? (
                <Typography>
                  {item.index === "cal"
                    ? agentFunnel?.replies && agentFunnel?.lead
                      ? `${Math.ceil(
                          (agentFunnel?.replies / agentFunnel?.lead) * 100,
                        )}%`
                      : "0%"
                    : FormatHelper.numberEN_USFormat(agentFunnel?.[item.index])}
                </Typography>
              ) : (
                <Typography>No Result</Typography>
              )}
            </Stack>
          );
        })}
      </Box>
    </Card>
  );
}
