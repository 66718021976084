import React, { Suspense, useContext, useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import "./App.scss";
import routes from "./routes";
import { Loader, MainLayout, TextLoader } from "./components";
import { AuthContext, AxiosContext } from "./context";

function App() {
  const { loading, loadingText } = useContext(AxiosContext);
  const { token, getToken } = useContext(AuthContext);
  const [mount, setMount] = useState(false);
  const [navs, setNavs] = useState([]);

  useEffect(() => {
    console.log("--- token ---", token, "--- getToken ---", getToken());
    const _navs = routes.map((route, index) =>
      route.component ? (
        <Route
          key={index}
          path={route.path}
          exact={route.exact}
          name={route.name}
          element={
            route.auth ? (
              getToken() ? (
                route.component
              ) : (
                <Navigate to="/auth/login" state={{ from: route.path }} />
              )
            ) : (
              route.component
            )
          }
        />
      ) : null,
    );
    setNavs(_navs);
    setMount(true);
    // navigate(`${state?.from ? state?.from : '/dashboard'}`);
  }, [token]);

  return (
    <Suspense fallback={<Loader />}>
      {loadingText ? (
        <TextLoader text={loadingText} />
      ) : (
        <Loader loading={loading} />
      )}
      <MainLayout>
        <Routes>{mount && navs}</Routes>
      </MainLayout>
    </Suspense>
  );
}

export default App;
