import Modal from "react-modal";
import React, { useContext, useState, useEffect } from "react";
import { AuthContext, ConversationContext } from "../../context";
import { toast } from "react-toastify";
import { LiaFunnelDollarSolid } from "react-icons/lia";
import { BiPaperPlane } from "react-icons/bi";
import Select from "react-select";
import { BsChat, BsTag, BsX } from "react-icons/bs";
import helper from "../../utils/helper";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "560px",
    padding: "15px",
    marginTop: "20px",
    marginBottom: "20px",
  },
};

Modal.setAppElement("#root");

const scheduleTimeOptions = [
  { value: "", label: "- : -" },
  { value: "8:00 AM", label: "8:00 AM" },
  { value: "8:15 AM", label: "8:15 AM" },
  { value: "8:30 AM", label: "8:30 AM" },
  { value: "8:45 AM", label: "8:45 AM" },
  { value: "9:00 AM", label: "9:00 AM" },
  { value: "9:15 AM", label: "9:15 AM" },
  { value: "9:30 AM", label: "9:30 AM" },
  { value: "9:45 AM", label: "9:45 AM" },
  { value: "10:00 AM", label: "10:00 AM" },
  { value: "10:15 AM", label: "10:15 AM" },
  { value: "10:30 AM", label: "10:30 AM" },
  { value: "10:45 AM", label: "10:45 AM" },
  { value: "11:00 AM", label: "11:00 AM" },
  { value: "11:15 AM", label: "11:15 AM" },
  { value: "11:30 AM", label: "11:30 AM" },
  { value: "11:45 AM", label: "11:45 AM" },
  { value: "12:00 PM", label: "12:00 PM" },
  { value: "12:15 PM", label: "12:15 PM" },
  { value: "12:30 PM", label: "12:30 PM" },
  { value: "12:45 PM", label: "12:45 PM" },
  { value: "1:00 PM", label: "1:00 PM" },
  { value: "1:15 PM", label: "1:15 PM" },
  { value: "1:30 PM", label: "1:30 PM" },
  { value: "1:45 PM", label: "1:45 PM" },
  { value: "2:00 PM", label: "2:00 PM" },
  { value: "2:15 PM", label: "2:15 PM" },
  { value: "2:30 PM", label: "2:30 PM" },
  { value: "2:45 PM", label: "2:45 PM" },
  { value: "3:00 PM", label: "3:00 PM" },
  { value: "3:15 PM", label: "3:15 PM" },
  { value: "3:30 PM", label: "3:30 PM" },
  { value: "3:45 PM", label: "3:45 PM" },
  { value: "4:00 PM", label: "4:00 PM" },
  { value: "4:15 PM", label: "4:15 PM" },
  { value: "4:30 PM", label: "4:30 PM" },
  { value: "4:45 PM", label: "4:45 PM" },
  { value: "5:00 PM", label: "5:00 PM" },
  { value: "5:15 PM", label: "5:15 PM" },
  { value: "5:30 PM", label: "5:30 PM" },
  { value: "5:45 PM", label: "5:45 PM" },
  { value: "6:00 PM", label: "6:00 PM" },
  { value: "6:15 PM", label: "6:15 PM" },
  { value: "6:30 PM", label: "6:30 PM" },
  { value: "6:45 PM", label: "6:45 PM" },
  { value: "7:00 PM", label: "7:00 PM" },
  { value: "7:15 PM", label: "7:15 PM" },
  { value: "7:30 PM", label: "7:30 PM" },
  { value: "7:45 PM", label: "7:45 PM" },
  { value: "8:00 PM", label: "8:00 PM" },
  { value: "8:15 PM", label: "8:15 PM" },
  { value: "8:30 PM", label: "8:30 PM" },
  { value: "8:45 PM", label: "8:45 PM" },
];

const FlashMessage = (html) => (
  <div dangerouslySetInnerHTML={{ __html: html }}></div>
);

export default function ConversationResumeFunnelConfirmModal({
  params,
  isOpenModal = false,
  afterOpenModal,
  closeModal,
}) {
  const { user } = useContext(AuthContext);
  const { loadResumeFunnelConfirmModalDataApi, resumeFunnelApi } =
    useContext(ConversationContext);
  const [message, setMessage] = useState(null);
  const [postData, setPostData] = useState({
    userId: user?.Id,
    lead: "",
    newfunnel: "",
    oldfunnel: "",
    sendtype: "1",
    sdate: "",
    etime: "",
    etimeObj: null,
    flag: "",
    delaycount: "",
  });
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (params) {
      console.log("params ===", params);
      loadResumeFunnelConfirmModalData();
    }
  }, [params]);

  const loadResumeFunnelConfirmModalData = async () => {
    const data = await loadResumeFunnelConfirmModalDataApi(params);
    if (data.status === "ok") {
      const res = data.response;
      setMessage(res?.message);
      setPostData({
        ...postData,
        userId: user?.Id,
        lead: res?.lead,
        newfunnel: res?.funnelid,
        oldfunnel: res?.oldfunnelid,
        sendtype: "1",
        flag: res?.flag,
        delaycount: res?.message.Delaycount,
      });
    }
  };

  const funnelconfirm_send = () => {
    console.log(">>>", postData);
    if (postData.sendtype === "2" && (!postData.sdate || !postData.etime)) {
      setShowError(true);
      return;
    }
    setShowError(false);
    resumefunnel(postData);
  };

  const resumefunnel = async (params) => {
    const data = await resumeFunnelApi(params);
    if (data.status === "ok") {
      const res = data.response;
      if (res?.html) {
        toast.info(<FlashMessage html={res?.html} />);
      }
      closeModal();
    }
  };

  return (
    <Modal
      isOpen={isOpenModal}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Resume Funnel"
    >
      <div className="modal-header">
        <h4 className="d-flex align-items-center">
          <LiaFunnelDollarSolid className="me-1" />
          Resume Funnel
        </h4>
      </div>
      <div className="modal-body py-3 funnelconfirm-form">
        {Number(message?.Messagetype) === 2 && (
          <>
            <h5 className="campaign-title">
              <BsChat />
              {message?.Name}
            </h5>
            <div className="message_status">
              <span
                className={`label ${helper.getLabelIconClass(
                  message?.Message,
                )}`}
              >
                {helper.getLabelText(message?.Message)}
              </span>
            </div>
          </>
        )}
        {Number(message?.Messagetype) === 3 && (
          <>
            <h5 className="campaign-title">
              <BsTag />
              {message?.Name}
            </h5>
            <div className="message_labels">
              <label className="status">
                {message?.Message.split(",").map((item) => {
                  item.split("#").map((iitem, iindex) => (
                    <label className="status" key={iindex}>
                      <span>
                        <span className={iitem[1]}>
                          <BsX />
                        </span>
                        {iitem[0]}
                      </span>
                    </label>
                  ));
                })}
              </label>
            </div>
          </>
        )}
        {Number(message?.Messagetype) === 1 && message?.Message && (
          <div className="alert alert-primary">
            <div dangerouslySetInnerHTML={{ __html: message?.Message }}></div>
            {message?.Gif !== "" ? (
              <img
                src={`https://media1.giphy.com/media/${message?.Gif}/100_s.gif`}
                alt=""
              />
            ) : message?.Attached ? (
              <img
                src={`${message?.Attached}`}
                width="100px"
                height="100px"
                alt=""
              />
            ) : null}
          </div>
        )}

        <div className="row my-2">
          <label className="d-flex align-items-center">
            <input
              type="radio"
              name="optradio"
              value="1"
              className="me-1 mt-1"
              checked={postData.sendtype === "1"}
              onChange={(e) =>
                setPostData({ ...postData, sendtype: e.target.value })
              }
            />
            Send now
          </label>
        </div>
        <div className="row my-2">
          <label className="d-flex align-items-center">
            <input
              type="radio"
              name="optradio"
              value="2"
              className="me-1 mt-1"
              checked={postData.sendtype === "2"}
              onChange={(e) =>
                setPostData({ ...postData, sendtype: e.target.value })
              }
            />
            Schedule
          </label>
        </div>
        {postData.sendtype === "2" && (
          <>
            <div className="row my-2">
              <div className="col-6 col-sm-6 col-md-5 col-lg-4 pe-1">
                <input
                  type="date"
                  className="form-control"
                  value={postData.sdate}
                  onChange={(e) =>
                    setPostData({ ...postData, sdate: e.target.value })
                  }
                />
              </div>
              <div className="col-6 col-sm-6 col-md-5 col-lg-4 ps-1">
                <Select
                  className="time-select"
                  options={scheduleTimeOptions}
                  value={postData.etimeObj}
                  onChange={(e) =>
                    setPostData({ ...postData, etimeobj: e, etime: e.value })
                  }
                />
              </div>
            </div>
            {showError && (
              <small className="text-danger">Valid date/time required</small>
            )}
          </>
        )}
      </div>
      <div className="modal-footer mt-3">
        <button
          className="btn d-flex align-items-center btn-primary me-auto"
          onClick={() => funnelconfirm_send()}
        >
          <BiPaperPlane className="me-1" />
          Send
        </button>
        <button
          className="btn d-flex align-items-center btn-outline-secondary ms-auto"
          onClick={() => closeModal()}
        >
          Cancel
        </button>
      </div>
    </Modal>
  );
}
