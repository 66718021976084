import React, { createContext, useContext } from "react";
import { AxiosContext } from "./axios_context";
import config from "../config/config";

const UserContext = createContext(undefined);

const UserContextProvider = (props) => {
  const { axiosInstance, setLoading } = useContext(AxiosContext);

  const getUsersDataApi = (params) => {
    const data = params;
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/users/users_data`,
      data,
    })
      .then((response) => {
        const data = response.data;
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const deleteUserApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/users/users_delete`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("=== users_delete ===", data);
        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
        return error;
      });
  };

  const editcategoryApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/users/users_editcategory`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("=== users_editcategory ===", data);
        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
        return error;
      });
  };

  const deletecategoryApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/users/users_deletecategory`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("=== users_deletecategory ===", data);
        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
        return error;
      });
  };

  const updatetemplateApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/users/users_edittemplate`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("=== users_edittemplate ===", data);
        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
        return error;
      });
  };

  const deleteUserGroupApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/users/users_deleteusergroup`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("=== users_deleteusergroup ===", data);
        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
        return error;
      });
  };

  const getEditUserDataApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/users/users_edituser`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("=== users_edituser ===", data);
        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
        return error;
      });
  };

  const updateUserApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/users/users_updateuser`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("=== users_updateuser ===", data);
        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
        return error;
      });
  };

  const sendnewuseremailApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/users/users_newusermail`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("=== users_newusermail ===", data);
        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
        return error;
      });
  };

  const getEditUserGroupDataApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/users/users_editgroup`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("=== users_editgroup ===", data);
        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
        return error;
      });
  };

  const ajaxusergroupApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/users/users_ajaxusergroup`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("=== users_ajaxusergroup ===", data);
        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
        return error;
      });
  };

  const updateUserGroupApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/users/users_updategroup`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("=== users_updategroup ===", data);
        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
        return error;
      });
  };

  const getNewUserPageDataApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/users/users_newuser`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("=== users_newuser ===", data);
        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
        return error;
      });
  };

  const createUserApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/users/users_create`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("=== users_create ===", data);
        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
        return error;
      });
  };

  const getNewUserGroupPageDataApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/users/users_newuser`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("=== users_newuser ===", data);
        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
        return error;
      });
  };

  const createUserGroupApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/users/users_creategroup`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("=== users_creategroup ===", data);
        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
        return error;
      });
  };

  const providerValue = {
    getUsersDataApi,
    deleteUserApi,
    editcategoryApi,
    deletecategoryApi,
    updatetemplateApi,
    deleteUserGroupApi,
    getEditUserDataApi,
    updateUserApi,
    sendnewuseremailApi,
    getEditUserGroupDataApi,
    ajaxusergroupApi,
    updateUserGroupApi,
    getNewUserPageDataApi,
    createUserApi,
    getNewUserGroupPageDataApi,
    createUserGroupApi,
  };

  return (
    <UserContext.Provider value={providerValue}>
      {props.children}
    </UserContext.Provider>
  );
};

export { UserContext, UserContextProvider };
