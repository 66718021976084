import Modal from "react-modal";
import React, { useContext, useState, useEffect } from "react";
import { AuthContext, ConversationContext } from "../../context";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { toast } from "react-toastify";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "560px",
    padding: "15px",
    marginTop: "20px",
    marginBottom: "20px",
  },
};

Modal.setAppElement("#root");

export default function ConversationEmailReportModal({
  isOpenModal = false,
  afterOpenModal,
  closeModal,
  responseId,
}) {
  const { user } = useContext(AuthContext);
  const { emailReportApi } = useContext(ConversationContext);
  const [postData, setPostData] = useState({
    userId: user?.Id,
    responseId: responseId,
    emailaddress: "",
  });

  useEffect(() => {
    if (responseId) {
      setPostData({
        userId: user?.Id,
        responseId: responseId,
        emailaddress: "",
      });
    }
  }, [responseId]);

  const onEmailReport = async () => {
    const res = await emailReportApi(postData);
    if (res.status === "ok") {
      toast.info("Email will process");
      closeModal();
    }
  };

  return (
    <Modal
      isOpen={isOpenModal}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Please Enter a Valid Mail Address"
    >
      <div className="modal-header">
        <h4 className="d-flex align-items-center">
          <EmailOutlinedIcon className="me-1" />
          Please Enter a Valid Mail Address
        </h4>
      </div>
      <div className="modal-body">
        <form>
          <div className="row">
            <div className="col-12">
              <div className="form-group mt-3">
                <label className="mb-1">Email address</label>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email address"
                  value={postData?.emailaddress}
                  onChange={(e) =>
                    setPostData({ ...postData, emailaddress: e.target.value })
                  }
                />
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="modal-footer mt-3">
        <button
          className="btn btn-primary me-2"
          onClick={() => onEmailReport()}
        >
          Ok
        </button>
        <button className="btn btn-outline-secondary" onClick={closeModal}>
          Cancel
        </button>
      </div>
    </Modal>
  );
}
