import { Switch } from "@mui/material";
import React from "react";

export default function ClientDMSFeedsTab() {
  return (
    <>
      <h6 className="tab-tile">DMS Feeds</h6>
      <hr />
      <div className="row">
        <div className="col col-sm-3">
          <div className="form-group">
            <label className="mb-2 w-100">Active</label>
            <Switch />
          </div>
        </div>
      </div>
      <hr />
      <div className="row mt-2">
        <div className="col-12 d-flex">
          <button className="btn btn-primary ms-auto">Save</button>
          <button className="btn btn-outline-secondary ms-2 me-auto">
            Cancel
          </button>
        </div>
      </div>
    </>
  );
}
