import Modal from "react-modal";
import React, { useContext, useState, useEffect } from "react";
import { AuthContext, ConversationContext } from "../../context";
import { BsShieldCheck } from "react-icons/bs";
import CancelIcon from "@mui/icons-material/Cancel";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "560px",
    padding: "15px",
    marginTop: "20px",
    marginBottom: "20px",
  },
};

Modal.setAppElement("#root");

export default function ConversationSMSOptinModal({
  isOpenModal = false,
  afterOpenModal,
  closeModal,
  infoId,
}) {
  const { user } = useContext(AuthContext);
  const { loadSMSOptinModalDataApi } = useContext(ConversationContext);
  const [modalType, setModalType] = useState(null);
  const [color, setColor] = useState(null);
  const [postData, setPostData] = useState({
    userId: user?.Id,
    infoId: infoId,
    emailaddress: "",
  });

  useEffect(() => {
    if (infoId) {
      loadSMSOptinModalData();
      console.log(color, postData, setPostData);
    }
  }, [infoId]);

  const loadSMSOptinModalData = async () => {
    const data = await loadSMSOptinModalDataApi({
      userId: user.Id,
      infoId: infoId,
    });
    if (data.status === "ok") {
      const res = data.response;
      console.log(">>>> data ??", data);
      setModalType(res?.info);
      setColor(res?.custom_color);
    }
  };

  const onSubmit = async () => {
    // const res = await emailReportApi(postData);
    // if (res.status === "ok") {
    //   toast.info('Email will process');
    //   closeModal();
    // }
  };

  return (
    <Modal
      isOpen={isOpenModal}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="SMS Opt-in"
    >
      <div className="modal-header">
        <h4 className="d-flex align-items-center">
          <BsShieldCheck className="me-1" />
          SMS Opt-in
        </h4>
      </div>
      <div className="modal-body">
        {Number(modalType) === 1 ? (
          <form>
            <div className="d-flex align-items-center justify-content-center mt-3">
              <CancelIcon sx={{ color: "#e57373", fontSize: 40 }} />
            </div>
            <p className="text-center mt-3">
              No SMS number available for this lead.
            </p>
          </form>
        ) : Number(modalType) === 2 ? (
          <form>
            <div className="d-flex align-items-center justify-content-center mt-3">
              <CancelIcon sx={{ color: "#e57373", fontSize: 40 }} />
            </div>
            <p className="text-center mt-3">
              No SMS number available for this lead.
            </p>
          </form>
        ) : (
          <form>
            <div className="d-flex align-items-center justify-content-center mt-3">
              <CancelIcon sx={{ color: "#e57373", fontSize: 40 }} />
            </div>
            <p className="text-center mt-3">
              This campaign does not have a phone number setup to send SMS.
            </p>
          </form>
        )}
        {/* <form>
          <div className="row">
            <div className="col-12">
              <div className="form-group mt-3">
                <label className="mb-1">Email address</label>
                <input type="email" className="form-control" placeholder="Email address"
                  value={postData?.emailaddress}
                  onChange={(e) => setPostData({...postData, emailaddress: e.target.value})}
                />
              </div>
            </div>
          </div>
        </form> */}
      </div>
      <div className="modal-footer mt-3">
        <button className="btn btn-outline-secondary" onClick={closeModal}>
          Cancel
        </button>
        {Number(modalType) === 1 && (
          <button className="btn btn-primary ms-2" onClick={() => onSubmit()}>
            OPT-IN
          </button>
        )}
      </div>
    </Modal>
  );
}
