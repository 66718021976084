import Modal from "react-modal";
import { AuthContext, ClientContext } from "../../context";
import React, { useEffect, useState, useContext } from "react";
import { BsTelephone } from "react-icons/bs";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "560px",
    padding: "15px",
    marginTop: "20px",
    marginBottom: "20px",
    overflow: "visible",
  },
};

Modal.setAppElement("#root");

export default function ClientSelectPhoneNumberModal({
  cid,
  params,
  isOpenModal = false,
  afterOpenModal,
  closeModal,
  reloadData,
}) {
  const { user } = useContext(AuthContext);
  const { searchSmsNumberApi, buysmsnumberApi } = useContext(ClientContext);
  const [phone, setPhone] = useState([]);

  useEffect(() => {
    if (params) {
      loadModalData(params);
      console.log(user);
    }
  }, [params]);

  const loadModalData = async (_params) => {
    const data = await searchSmsNumberApi(_params);
    if (data.status === "ok") {
      const res = data.response;
      setPhone(res?.phone);
    }
  };

  const buysmsnumber = async (item) => {
    let params = {
      number: item[0],
      numberf: item[1],
      cid: cid,
    };
    const data = await buysmsnumberApi(params);
    if (data.status === "ok") {
      reloadData();
    }
  };

  return (
    <Modal
      isOpen={isOpenModal}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel={"Import Leads"}
    >
      <div className="modal-header">
        <h4 className="d-flex align-items-center">
          <BsTelephone className="me-2" />
          Search Phone Number
        </h4>
      </div>
      <div className="modal-body">
        <table className="table table-hover">
          <thead>
            <tr>
              <th>Phone Number</th>
              <th>Buy</th>
            </tr>
          </thead>
          {phone.length > 0 ? (
            <tbody>
              {phone.map((item, index) => (
                <tr key={index}>
                  <td>{item[1]}</td>
                  <td>
                    <a
                      onClick={() => buysmsnumber(item)}
                      className="btn btn-sm btn-primary"
                    >
                      Buy
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td colSpan={2} className="text-center">
                  No results
                </td>
              </tr>
            </tbody>
          )}
        </table>
      </div>
      <div className="modal-footer mt-3">
        <button
          className="btn btn-outline-secondary ms-auto"
          onClick={closeModal}
        >
          Cancel
        </button>
      </div>
    </Modal>
  );
}
