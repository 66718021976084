import React, { useContext, useState } from "react";
import Modal from "react-modal";
import { AuthContext, SharedContext } from "../../context";
import { useEffect } from "react";
import { IoClose } from "react-icons/io5";
import ScheduleTimeSelect from "../select/schedule_time_select";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "560px",
    padding: "15px",
    marginTop: "20px",
    marginBottom: "20px",
    overflow: "visible",
  },
};

Modal.setAppElement("#root");

export default function FunnelNewLabelModal({
  initialData = undefined,
  isOpenModal = false,
  afterOpenModal,
  closeModal,
  onManageLabels,
  onSubmit,
}) {
  const { user } = useContext(AuthContext);
  const { tagAutoCompleteApi } = useContext(SharedContext);
  const [postData, setPostData] = useState({
    userId: user.Id,
    Name: "",
    Message: "",
    Delaycategory: "",
    DelaycategoryObj: { value: "", label: "- : -" },
    Delaycount: "",
    Messagetype: 3,
    index: "",
  });
  const [label, setLabel] = useState([]);
  //
  const [searchKey, setSearchKey] = useState("");
  const [autocompleteLabels, setAutocompleteLabels] = useState([]);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (initialData) {
      let _postData = { ...postData };
      _postData.userId = initialData.userId;
      _postData.Name = initialData.Name;
      _postData.Message = initialData.Message;
      _postData.Delaycategory = initialData.Delaycategory;
      _postData.DelaycategoryObj = initialData.DelaycategoryObj;
      _postData.Delaycount = initialData.Delaycount;
      _postData.Messagetype = initialData.Messagetype;
      _postData.index = initialData.index;
      setPostData(_postData);
      let msg = initialData.Message;
      if (msg) {
        let label = [];
        msg = msg.split(",");
        for (let i = 0; i < msg.length; i++) {
          let msgitem = msg[i].split("#");
          label.push({ name: msgitem[0], class: msgitem[1], id: msgitem[2] });
        }
        setLabel(label);
      }
    }
  }, [initialData]);

  const onSave = () => {
    let params = { ...postData };
    params.Message = label
      .map((item) => {
        return `${item.name}#${item.class}#${item.id}`;
      })
      .join(",");
    if (
      !params.Name ||
      !params.Message ||
      !params.Delaycategory ||
      !params.Delaycount
    ) {
      setShowError(true);
    }
    setPostData({
      userId: user.Id,
      Name: "",
      Message: "",
      Delaycategory: "",
      DelaycategoryObj: { value: "", label: "- : -" },
      Delaycount: "",
      Messagetype: 3,
      index: "",
    });
    setShowError(false);
    onSubmit(params);
  };

  const deleteTag = (tagId) => {
    setLabel((current) =>
      current.filter((el) => Number(el.id) !== Number(tagId)),
    );
  };

  const onSearch = async () => {
    const data = await tagAutoCompleteApi({ userId: user.Id, term: searchKey });
    if (data.status === "ok") {
      const res = Object.values(data.response);
      setAutocompleteLabels(res);
    }
  };

  const addNewLabelTag = (item) => {
    let _label = [...label];
    _label.push({
      name: item.label,
      class: item.value,
      id: item.id,
    });
    setLabel(_label);
    setSearchKey("");
    setAutocompleteLabels([]);
  };

  return (
    <Modal
      isOpen={isOpenModal}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="New Labels Modal"
    >
      <div className="modal-header">
        <h4 className="d-flex align-items-center">
          {initialData ? "Edit" : "New"} Labels
        </h4>
      </div>
      <div className="modal-body">
        <div className="row mt-4">
          <div className="col col-sm-12">
            <div className="form-group mb-2">
              <label className="mb-2">Action Name</label>
              <input
                type="text"
                className="form-control"
                name="message-name"
                value={postData.Name}
                onChange={(e) =>
                  setPostData({ ...postData, Name: e.target.value })
                }
              />
              {showError && !postData.Name && (
                <span className="text-danger">Action name is required.</span>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <label className="mb-2">Delay in Days</label>
          </div>
          <div className="col-6">
            <div className="form-group mb-2">
              <input
                type="number"
                className="form-control"
                value={postData.Delaycount}
                onChange={(e) =>
                  setPostData({ ...postData, Delaycount: e.target.value })
                }
              />
            </div>
          </div>
          <div className="col-6">
            <div className="form-group mb-2">
              <ScheduleTimeSelect
                value={postData?.DelaycategoryObj}
                onChange={(e) => {
                  setPostData({
                    ...postData,
                    DelaycategoryObj: e,
                    Delaycategory: e.value,
                  });
                }}
              />
            </div>
          </div>
          {showError && (!postData.Delaycount || !postData.Delaycategory) && (
            <div className="col-12">
              <span className="text-danger">Please add delay.</span>
            </div>
          )}
        </div>
        <div id="edit_labels_list" className="mb-2">
          {label.map((item, index) => (
            <label className="status" key={index}>
              <span>
                <span className={item.class}>
                  <IoClose
                    className="icon-close"
                    onClick={() => deleteTag(item.id)}
                  />
                </span>
                {item.name}
              </span>
            </label>
          ))}
        </div>
        <div className="row">
          <div className="col-12">
            <div className="form-group mb-2">
              <div className="d-flex align-items-center justify-content-between mb-2">
                <label className="">Label</label>
                <a
                  className="text-primary text-decoration-none cursor-pointer ms-auto"
                  onClick={() => onManageLabels()}
                >
                  Manage Labels
                </a>
              </div>
              <div className={`autocomplete-label-form`}>
                <input
                  type="text"
                  className="form-control w-100"
                  placeholder="Add label..."
                  value={searchKey}
                  onChange={(e) => setSearchKey(e.target.value)}
                  onKeyUp={(e) => {
                    if (e.key === "Enter" && searchKey) {
                      onSearch();
                    }
                  }}
                />
                {autocompleteLabels.length > 0 && (
                  <ul className="autocomplete-labels">
                    {autocompleteLabels.map((item, index) => (
                      <li
                        className="autocomplete-label"
                        key={index}
                        onClick={() => addNewLabelTag(item)}
                      >
                        <span className={`bubble ${item?.value}`}></span>
                        {item?.label}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              {showError && !label && (
                <span className="text-danger">Please add label</span>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer mt-3">
        <button className="btn btn-outline-secondary me-2" onClick={closeModal}>
          Cancel
        </button>
        <button className="btn btn-primary" onClick={() => onSave()}>
          Save
        </button>
      </div>
    </Modal>
  );
}
