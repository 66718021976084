import { AuthContext, FunnelContext } from "../../../context";
import React, { useContext, useState, useEffect } from "react";
import config from "../../../config/config";
import helper from "../../../utils/helper";
import moment from "moment";
import {
  BiCaretDown,
  BiCaretUp,
  BiChevronLeft,
  BiChevronRight,
  BiChevronsLeft,
  BiChevronsRight,
} from "react-icons/bi";
import DropdownMultiSelect from "../../select/dropdown_multi_select";
import FunnelReassignModal from "../../modals/funnel_reassign_modal";
import FunnelReassignConfirmModal from "../../modals/funnel_reassign_confirm_modal";

export default function FunnlsLeadDataTable({
  selectedfunnel,
  data,
  reloadData,
}) {
  const { user } = useContext(AuthContext);
  const {
    getAjaxLeadsApi,
    filterleadstatusApi,
    sortAjaxLeadsApi,
    activatedFromFunnelLeadApi,
    pausedFromFunnelLeadApi,
    reassignModalDataApi,
  } = useContext(FunnelContext);
  //
  const [funnelid, setFunnelid] = useState("");
  const [split, setSplit] = useState([]);
  const [leads, setLeads] = useState("");
  const [postData, setPostData] = useState({
    userId: user.Id,
    leads: "",
    total_leads: "",
    total_page: "",
    selectedlead: "",
    funnelid: "",
    page: "",
    sortcolumn: "",
    sorttype: "",
  });
  const [leadStatus, setLeadStatus] = useState({
    ids: [],
    objs: [],
  });
  const [searchNameKey, setSearchNameKey] = useState("");
  //
  const [info, setInfo] = useState([]);
  const [sortclass, setSortclass] = useState([]);
  const [pgConfig, setPgConfig] = useState({
    cpage: "",
    tpage: "",
    totalcount: "",
    v1page: "",
    v2page: "",
  });
  //
  const [allSelect, setAllSelect] = useState(false);
  const [selectedLeads, setSelectedLeads] = useState([]);
  //
  const [reassignModal, setReassignModal] = useState(undefined);
  const [reassignConfirmModal, setReassignConfirmModal] = useState(undefined);

  useEffect(() => {
    console.log(selectedfunnel);
    if (data) {
      setFunnelid(data?.funnelid);
      setSplit(data?.split);
      setLeads(data?.leads);
      let _postData = { ...postData };
      _postData.userId = user.Id;
      _postData.leads = data?.split[1];
      _postData.total_leads = data?.tlead;
      _postData.total_page = data?.split.length;
      _postData.selectedlead = "";
      _postData.funnelid = data?.funnelid;
      _postData.page = 1;
      _postData.sortcolumn = 3;
      _postData.sorttype = 1;
      setPostData(_postData);
      //
      getTableData(_postData);
    }
  }, [data]);

  const getTableData = async (params) => {
    const dataRes = await getAjaxLeadsApi(params);
    if (dataRes.status === "ok") {
      const res = dataRes.response;
      setInfo(res?.info);
      setSortclass(res?.sortclass);
      setPgConfig({
        ...pgConfig,
        cpage: Number(res?.cpage),
        tpage: Number(res?.tpage),
        totalcount: Number(res?.totalcount),
        v1page: Number(res?.v1page),
        v2page: Number(res?.v2page),
      });
    }
  };

  const onClickPagination = (page) => {
    let _postData = { ...postData, page: page, leads: split[page] };
    setPostData(_postData);
    getTableData(_postData);
  };

  const filterleadstatus = async () => {
    const dataRes = await filterleadstatusApi({
      userId: user.Id,
      status: leadStatus.ids,
      funnelid: funnelid,
    });
    if (dataRes.status === "ok") {
      const res = dataRes.response;
      setPgConfig({
        ...pgConfig,
        tpage: res?.tpage,
      });
      setSplit(res?.split);
      let _postData = {
        ...postData,
        leads: res?.split[1],
        total_leads: res?.tlead,
        total_page: res?.split.length,
      };
      setPostData(_postData);
      //
      getTableData(_postData);
    }
  };

  const sortleads = async (scolumn) => {
    let params = {};
    params.leads = leads;
    params.sortcolumn = scolumn;
    let sortcolumn = postData.sortcolumn;
    let sorttype = postData.sorttype;
    if (Number(sortcolumn) === Number(scolumn)) {
      if (sorttype === 1) {
        sorttype = 2;
      } else {
        sorttype = 1;
      }
    } else {
      sorttype = 1;
    }
    params.sorttype = sorttype;
    const data = await sortAjaxLeadsApi(params);
    if (data.status === "ok") {
      const res = data.response;
      setSplit(res?.split);
      let _postData = {
        ...postData,
        leads: res?.split[1],
        total_leads: res?.tlead,
        total_page: res?.split.length,
        sortcolumn: scolumn,
        sorttype: sorttype,
      };
      setPostData(_postData);
      //
      getTableData(_postData);
    }
  };

  const onToggleAllSelect = (e) => {
    if (e.target.checked) {
      setAllSelect(true);
      setSelectedLeads(info.map((item) => item.Id));
    } else {
      setAllSelect(false);
      setSelectedLeads([]);
    }
  };

  const toggleLeadCheckbox = (e, itemId) => {
    if (e.target.checked) {
      if ([...selectedLeads, itemId].length === info.length) {
        setAllSelect(true);
      }
      setSelectedLeads((current) => [...current, itemId]);
    } else {
      if (selectedLeads.filter((el) => el !== itemId).length === 0) {
        setAllSelect(false);
      }
      setSelectedLeads((current) => current.filter((el) => el !== itemId));
    }
  };

  const onChangeAction = async (e) => {
    let action = e.target.value;
    if (!action || selectedLeads.length === 0) {
      return;
    }
    let params = {};
    params.userId = user.Id;
    params.funnel = funnelid;
    params.lead = selectedLeads.toString();
    //
    if (Number(action) === 1) {
      const data = await activatedFromFunnelLeadApi(params);
      if (data.status === "ok") {
        reloadData();
      }
    } else if (Number(action) === 2) {
      const data = await pausedFromFunnelLeadApi(params);
      if (data.status === "ok") {
        reloadData();
      }
    } else if (action === "reassign") {
      params.id = 2;
      const data = await reassignModalDataApi(params);
      if (data.status === "ok") {
        const res = data.response;
        setReassignModal(res);
      }
    } else {
      return;
    }
  };

  return (
    <>
      <div className="row">
        <div className="col col-sm-3">
          <DropdownMultiSelect
            className=""
            options={[
              { value: 1, label: "Active" },
              { value: 2, label: "Pause" },
              { value: 3, label: "Completed" },
            ]}
            placeholder={"All"}
            labelField="label"
            valueField="value"
            values={leadStatus.obj}
            onChange={(values) =>
              setLeadStatus({
                ...leadStatus,
                objs: values,
                ids: values.map((item) => item.value),
              })
            }
            onDropdownClose={(values) => {
              console.log(values);
              filterleadstatus();
            }}
          />
        </div>
        <div className="col col-sm-3"></div>
        <div className="col col-sm-3">
          <select
            className="form-select"
            id="funnel-action"
            onChange={(e) => onChangeAction(e)}
          >
            <option value="">Action</option>
            <option value="2">Paused</option>
            <option value="1">Resume</option>
            <option value="reassign">Re-assign</option>
          </select>
        </div>
        <div className="col col-sm-3">
          <input
            id="search-leads"
            className="form-control ui-autocomplete-input"
            type="text"
            placeholder="Search..."
            value={searchNameKey}
            onChange={(e) => setSearchNameKey(e.target.value)}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-12">
          <table className="table table-hover">
            <thead>
              <tr>
                <th>
                  <input
                    type="checkbox"
                    checked={allSelect}
                    onChange={(e) => onToggleAllSelect(e)}
                  />
                </th>
                <th>Name</th>
                <th>Lead Source</th>
                <th className="cursor-pointer" onClick={() => sortleads(1)}>
                  <div className="d-flex align-items-center">
                    <span className={`sort-icon ${sortclass[1]}`}>
                      <BiCaretUp className="icon-up" />
                      <BiCaretDown className="icon-down" />
                    </span>
                    Last Incoming
                  </div>
                </th>
                <th className="cursor-pointer" onClick={() => sortleads(2)}>
                  <div className="d-flex align-items-center">
                    <span className={`sort-icon ${sortclass[2]}`}>
                      <BiCaretUp className="icon-up" />
                      <BiCaretDown className="icon-down" />
                    </span>
                    Last Outgoing
                  </div>
                </th>
                <th className="cursor-pointer" onClick={() => sortleads(3)}>
                  <div className="d-flex align-items-center">
                    <span className={`sort-icon ${sortclass[3]}`}>
                      <BiCaretUp className="icon-up" />
                      <BiCaretDown className="icon-down" />
                    </span>
                    Entered Funnel
                  </div>
                </th>
                <th className="cursor-pointer" onClick={() => sortleads(4)}>
                  <div className="d-flex align-items-center">
                    <span className={`sort-icon ${sortclass[4]}`}>
                      <BiCaretUp className="icon-up" />
                      <BiCaretDown className="icon-down" />
                    </span>
                    Next Message
                  </div>
                </th>
                <th className="cursor-pointer" onClick={() => sortleads(5)}>
                  <div className="d-flex align-items-center">
                    <span className={`sort-icon ${sortclass[5]}`}>
                      <BiCaretUp className="icon-up" />
                      <BiCaretDown className="icon-down" />
                    </span>
                    Status
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {info
                .filter(
                  (el) =>
                    !searchNameKey ||
                    el.pname
                      .toLowerCase()
                      .includes(searchNameKey.toLowerCase()),
                )
                .map((item, index) => (
                  <tr key={index}>
                    <td>
                      <input
                        type="checkbox"
                        checked={selectedLeads.includes(item.Id)}
                        onChange={(e) => toggleLeadCheckbox(e, item.Id)}
                      />
                    </td>
                    <td>
                      <div className="d-flex">
                        {Number(item.Ismediaimage) === 1 ? (
                          <a
                            className="p-1"
                            href={`${config.smsPath}/s/${item.Responsekey}`}
                          >
                            <img
                              className="rounded-circle"
                              src={item.Mediaimage}
                              width="40"
                              height="40"
                              alt=""
                            />
                          </a>
                        ) : (
                          <a
                            className="p-1"
                            href={`${config.smsPath}/s/${item.Responsekey}`}
                          >
                            <img
                              className="rounded-circle"
                              src={`${
                                config.domain
                              }/assets/images/material-letter-icons/png/${item.pname
                                .charAt(0)
                                .toUpperCase()}.png`}
                              width="40"
                              height="40"
                              alt=""
                            />
                          </a>
                        )}
                        <a
                          className="d-block text-decoration-none"
                          href={`${config.smsPath}/s/${item.Responsekey}`}
                        >
                          <div className="d-flex align-items-center">
                            <span className="text-primary me-1">
                              {item.pname}
                            </span>
                            <span
                              className={`badge pill-rounded ${helper.getLabelIconClass(
                                item.Label,
                              )}`}
                            >
                              {helper.getLabelText(item.Label)}
                            </span>
                          </div>
                          <small className="text-secondary">{item.Phone}</small>
                        </a>
                      </div>
                    </td>
                    <td>
                      <small className="text-gray">{item.LeadSource}</small>
                    </td>
                    <td>
                      {!!item.smsleadresponse && (
                        <abbr className="timeago" title={item.smsleadresponse}>
                          {moment(item.smsleadresponse).fromNow()}
                        </abbr>
                      )}
                    </td>
                    <td>
                      {!!item.smsoutgoing && (
                        <abbr className="timeago" title={item.smsoutgoing}>
                          {moment(item.smsoutgoing).fromNow()}
                        </abbr>
                      )}
                    </td>
                    <td>
                      {!!item.Funnel_assign && (
                        <abbr className="timeago" title={item.Funnel_assign}>
                          {moment(item.Funnel_assign).fromNow()}
                        </abbr>
                      )}
                    </td>
                    <td>{item.Next_message}</td>
                    <td>
                      <a
                        className="labelclass text-decoration-none"
                        onClick="
                        leadststuschanged(
                          '<?=$info[$i]['Id']?>','<?=$info[$i]['Funnel_status']?>')
                        "
                      >
                        {Number(item.Funnel_status) === 1 ? (
                          <span className="badge rounded-pill bg-success">
                            Active
                          </span>
                        ) : Number(item.Funnel_status) === 2 ? (
                          <span className="badge rounded-pill bg-dark">
                            Paused
                          </span>
                        ) : Number(item.Funnel_status) === 3 ? (
                          <span className="badge rounded-pill bg-gray">
                            Completed
                          </span>
                        ) : null}
                      </a>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="table-pagination">
            <div className="">
              Page {pgConfig.cpage} of {pgConfig.tpage}
            </div>
            <div className="pagination">
              <button
                className={`btn-page`}
                disabled={Number(pgConfig.cpage) <= 1}
                onClick={() => onClickPagination(1)}
              >
                <BiChevronsLeft />
              </button>
              <button
                className={`btn-page`}
                disabled={Number(pgConfig.cpage) <= 1}
                onClick={() => onClickPagination(Number(pgConfig.cpage) - 1)}
              >
                <BiChevronLeft />
              </button>
              {[...Array(Number(pgConfig.tpage))].map((item, index) =>
                index + 1 >= Number(pgConfig.v1page) &&
                index + 1 <= Number(pgConfig.v2page) ? (
                  <button
                    key={index}
                    className={`btn-page${
                      Number(Number(pgConfig.cpage)) === index + 1
                        ? " active"
                        : ""
                    }`}
                    onClick={() => onClickPagination(index + 1)}
                  >
                    {index + 1}
                  </button>
                ) : null,
              )}
              <button
                className={`btn-page`}
                disabled={Number(pgConfig.cpage) >= Number(pgConfig.tpage)}
                onClick={() => onClickPagination(Number(pgConfig.cpage) + 1)}
              >
                <BiChevronRight />
              </button>
              <button
                className={`btn-page`}
                disabled={Number(pgConfig.cpage) >= Number(pgConfig.tpage)}
                onClick={() => onClickPagination(Number(pgConfig.tpage))}
              >
                <BiChevronsRight />
              </button>
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      <FunnelReassignModal
        data={reassignModal}
        isOpenModal={!!reassignModal}
        afterOpenModal={() => {}}
        closeModal={() => setReassignModal(undefined)}
        onReassignConfirm={(res) => {
          setReassignModal(undefined);
          setReassignConfirmModal(res);
        }}
      />
      <FunnelReassignConfirmModal
        data={reassignConfirmModal}
        isOpenModal={!!reassignConfirmModal}
        afterOpenModal={() => {}}
        closeModal={() => setReassignConfirmModal(undefined)}
        reloadData={() => reloadData()}
      />
    </>
  );
}
