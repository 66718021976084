import React, { useContext, useEffect, useState } from "react";
import Modal from "react-modal";
import { AuthContext, ConversationContext } from "../../context";
import { BsTrash } from "react-icons/bs";
import Dropdown from "react-bootstrap/Dropdown";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "560px",
    padding: "15px",
    marginTop: "20px",
    marginBottom: "20px",
  },
};

Modal.setAppElement("#root");

export default function ConversationManageLabelsModal({
  isOpenModal = false,
  afterOpenModal,
  closeModal,
}) {
  const { user } = useContext(AuthContext);
  const { loadManageLabelsModalDataApi, deleteLeadTagApi, addNewLeadTagApi } =
    useContext(ConversationContext);
  const [labels, setLabels] = useState([]);
  const [newLabel, setNewLabel] = useState({
    class: "label label-red",
    name: "",
  });

  useEffect(() => {
    if (user) {
      loadManageLabelsModalData();
    }
  }, [user]);

  const loadManageLabelsModalData = async () => {
    const data = await loadManageLabelsModalDataApi({ userId: user.Id });
    if (data.status === "ok") {
      const res = data.response;
      setLabels(res?.info);
    }
  };

  const deleteLeadTag = async (tagId) => {
    const data = await deleteLeadTagApi({ userId: user.Id, id: tagId });
    if (data.status === "ok") {
      loadManageLabelsModalData();
    }
  };

  const addLabel = async () => {
    if (!newLabel.name) {
      return;
    }
    const data = await addNewLeadTagApi({
      userId: user.Id,
      name: newLabel.name,
      class: newLabel.class,
    });
    if (data.status === "ok") {
      setLabels({
        class: "label label-red",
        name: "",
      });
      loadManageLabelsModalData();
    }
  };

  return (
    <Modal
      isOpen={isOpenModal}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Manage Labels"
    >
      <div className="modal-header">
        <h4>Manage Labels</h4>
      </div>
      <div className="modal-body py-4">
        <form>
          <p>
            Use labels to help you describe and organize Lead status. Labels can
            be about anything.
          </p>
          <div className="shadow-sm p-3">
            <div className="new-label-form d-flex align-items-center w-100">
              <div className="inline-form w-100">
                <Dropdown className="label-dot-dropdown">
                  <Dropdown.Toggle variant="transparent py-0">
                    <span className={newLabel?.class}></span>
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="mt-1">
                    <Dropdown.Item
                      onClick={() =>
                        setNewLabel({ ...newLabel, class: "label label-red" })
                      }
                    >
                      <span className="label label-red"></span>
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() =>
                        setNewLabel({ ...newLabel, class: "label label-blue" })
                      }
                    >
                      <span className="label label-blue"></span>
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() =>
                        setNewLabel({
                          ...newLabel,
                          class: "label label-orange",
                        })
                      }
                    >
                      <span className="label label-orange"></span>
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() =>
                        setNewLabel({ ...newLabel, class: "label label-aqua" })
                      }
                    >
                      <span className="label label-aqua"></span>
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() =>
                        setNewLabel({ ...newLabel, class: "label label-black" })
                      }
                    >
                      <span className="label label-black"></span>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Name a label..."
                  value={newLabel?.name}
                  onChange={(e) =>
                    setNewLabel({ ...newLabel, name: e.target.value })
                  }
                />
                <button
                  type="button"
                  className="btn btn-sm btn-primary btn-add"
                  disabled={!newLabel.name}
                  onClick={() => addLabel()}
                >
                  Add Label
                </button>
              </div>
            </div>
          </div>
          <div className="shadow-sm p-3 mt-3">
            <ul className="all-labels-list">
              {labels.map((item, index) => (
                <li className="label-list-item" key={index}>
                  <div className="d-flex align-items-center justify-content-start me-auto">
                    <span className={`${item?.Class}`}></span>
                    {item?.Name}
                  </div>
                  <div className="d-flex align-items-center justify-content-end ms-auto">
                    <span>{item?.Count}</span>
                    {item?.Isallow && (
                      <a
                        className="px-1 cursor-pointer"
                        onClick={() => deleteLeadTag(item?.Id)}
                      >
                        <BsTrash />
                      </a>
                    )}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </form>
      </div>
    </Modal>
  );
}
