import { SlBubble } from "react-icons/sl";
import ApexAreaChart from "../ApexCharts/ApexAreaChart";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext, DashboardContext } from "../../context";
import {
  Box,
  Card,
  FormControl,
  MenuItem,
  Select,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";

export default function MessagesMetricsBoard({ selectedDealershipIds = "" }) {
  const { user } = useContext(AuthContext);
  const { getAgentMessageDetails, agentMessages } =
    useContext(DashboardContext);
  const [flag, setFlag] = useState(1);
  const [daterange, setDaterange] = useState(1);
  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    getAgentMessageDetailsData();
  }, [flag, daterange]);

  const getAgentMessageDetailsData = async () => {
    setShowLoading(true);
    const res = await getAgentMessageDetails({
      userId: user.Id,
      dealershipIds: selectedDealershipIds,
      flag: flag,
      daterange: daterange,
    });
    if (res) {
      setShowLoading(false);
    }
  };

  return (
    <Card
      sx={{
        p: 2,
        pt: 3,
        height: "100%",
      }}
    >
      <Stack direction={"column"} spacing={2}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"center"}
          spacing={1}
        >
          <SlBubble style={{ fontSize: 20, color: "inherit" }} />
          <Typography variant="body1">Messages</Typography>
        </Stack>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <FormControl sx={{ minWidth: 120, size: "small" }}>
            <Select
              labelId="message-select-label"
              id="message-select"
              value={flag}
              onChange={(e) => setFlag(e.target.value)}
              sx={{ height: 30, fontSize: 14 }}
            >
              <MenuItem value="1" sx={{ fontSize: 14 }}>
                Messages Sent
              </MenuItem>
              <MenuItem value="2" sx={{ fontSize: 14 }}>
                Messages Received
              </MenuItem>
            </Select>
          </FormControl>
          <div className="d-flex">
            <div className="date-filter ms-auto">
              <button
                className={`btn-filter ${daterange === 1 ? "active" : ""}`}
                onClick={() => setDaterange(1)}
              >
                This Year
              </button>
              <button
                className={`btn-filter ${daterange === 2 ? "active" : ""}`}
                onClick={() => setDaterange(2)}
              >
                Last Year
              </button>
            </div>
          </div>
        </Stack>
      </Stack>
      <Box mt={2}>
        {showLoading ? (
          <Skeleton variant="rounded" animation="wave" height={200} />
        ) : agentMessages ? (
          <ApexAreaChart
            labels={agentMessages?.date ? agentMessages?.date : []}
            series={[
              {
                name: "Messages",
                type: "area",
                data: agentMessages?.message ? agentMessages?.message : [],
              },
            ]}
          />
        ) : (
          <Typography variant="body2" width={"100%"} textAlign={"center"}>
            No result
          </Typography>
        )}
      </Box>
    </Card>
  );
}
