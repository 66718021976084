import React from "react";
import {
  UserProfile,
  HomePage,
  LoginPage,
  DashboardPage,
  CampaignsPage,
  ClientsPage,
  ClientEditPage,
  ClientNewPage,
  UsersPage,
  UserEditPage,
  UserGroupEditPage,
  UserNewPage,
  UserGroupNewPage,
  ConversationsPage,
  AppointmentsPage,
  VideosPage,
  FunnelsPage,
  FunnelNewPage,
  FunnelCopyPage,
  BlastsPage,
  BlastNewPage,
  BlastCopyPage,
  ActivityReportPage,
  ReportLeadsPage,
  MessagesReportPage,
  ReportLeadSourcesPage,
  UserStatsReportPage,
  HotLeadsReportPage,
  OnboardingReportPage,
  PrintResponsePage,
} from "../pages";

const routes = [
  // Public Routes
  {
    path: "/auth/login",
    exact: true,
    auth: false,
    name: "Login",
    component: <LoginPage />,
  },
  // Private Routes
  { path: "", exact: true, auth: true, name: "Home", component: <HomePage /> },
  {
    path: "/users/profile",
    exact: true,
    auth: true,
    name: "UserProfile",
    component: <UserProfile />,
  },
  {
    path: "/dashboard",
    exact: true,
    auth: true,
    name: "Dashboard",
    component: <DashboardPage />,
  },
  {
    path: "/campaigns",
    exact: true,
    auth: true,
    name: "Campaigns",
    component: <CampaignsPage />,
  },
  //
  {
    path: "/clients",
    exact: true,
    auth: true,
    name: "Clients",
    component: <ClientsPage />,
  },
  {
    path: "/clients/edit/:keyid",
    exact: true,
    auth: true,
    name: "ClientEdit",
    component: <ClientEditPage />,
  },
  {
    path: "/clients/newclient",
    exact: true,
    auth: true,
    name: "NewClient",
    component: <ClientNewPage />,
  },
  //
  {
    path: "/users",
    exact: true,
    auth: true,
    name: "Users",
    component: <UsersPage />,
  },
  {
    path: "/users/edituser/:keyid",
    exact: true,
    auth: true,
    name: "UserEdit",
    component: <UserEditPage />,
  },
  {
    path: "/users/editgroup/:id",
    exact: true,
    auth: true,
    name: "UserGroupEdit",
    component: <UserGroupEditPage />,
  },
  {
    path: "/users/newuser",
    exact: true,
    auth: true,
    name: "NewUser",
    component: <UserNewPage />,
  },
  {
    path: "/users/newgroup",
    exact: true,
    auth: true,
    name: "NewGroup",
    component: <UserGroupNewPage />,
  },
  //
  {
    path: "/conversations",
    exact: true,
    auth: true,
    name: "Conversations",
    component: <ConversationsPage />,
  },
  //
  {
    path: "/appointments",
    exact: true,
    auth: true,
    name: "Appointments",
    component: <AppointmentsPage />,
  },
  //
  {
    path: "/videos",
    exact: true,
    auth: true,
    name: "Videos",
    component: <VideosPage />,
  },
  //
  {
    path: "/funnels",
    exact: true,
    auth: true,
    name: "Funnels",
    component: <FunnelsPage />,
  },
  {
    path: "/funnels/create",
    exact: true,
    auth: true,
    name: "NewFunnel",
    component: <FunnelNewPage />,
  },
  {
    path: "/funnels/copyfunnel/:fid",
    exact: true,
    auth: true,
    name: "CopyFunnel",
    component: <FunnelCopyPage />,
  },
  //
  {
    path: "/blasts",
    exact: true,
    auth: true,
    name: "Blasts",
    component: <BlastsPage />,
  },
  {
    path: "/blasts/new",
    exact: true,
    auth: true,
    name: "NewBlast",
    component: <BlastNewPage />,
  },
  {
    path: "/blasts/copyblast/:bid",
    exact: true,
    auth: true,
    name: "CopyBlast",
    component: <BlastCopyPage />,
  },
  //
  {
    path: "/reports/smsreport",
    exact: true,
    auth: true,
    name: "ActivityReport",
    component: <ActivityReportPage />,
  },
  {
    path: "/reports/leads",
    exact: true,
    auth: true,
    name: "ReportLeads",
    component: <ReportLeadsPage />,
  },
  {
    path: "/reports/admin_smsreport",
    exact: true,
    auth: true,
    name: "MessagesReport",
    component: <MessagesReportPage />,
  },
  {
    path: "/reports/leadsource",
    exact: true,
    auth: true,
    name: "ReportLeadSources",
    component: <ReportLeadSourcesPage />,
  },
  {
    path: "/reports/user_stats",
    exact: true,
    auth: true,
    name: "UserStatsReport",
    component: <UserStatsReportPage />,
  },
  {
    path: "/reports/hot_leads",
    exact: true,
    auth: true,
    name: "HotLeadsReport",
    component: <HotLeadsReportPage />,
  },
  {
    path: "/reports/onboarding",
    exact: true,
    auth: true,
    name: "OnboardingReport",
    component: <OnboardingReportPage />,
  },
  //
  {
    path: "/tools/printresponse/:key",
    exact: true,
    auth: true,
    name: "PrintResponse",
    component: <PrintResponsePage />,
  },
  // actions
  {
    path: "/users/new-user",
    exact: true,
    auth: true,
    name: "NewUser",
    component: <UserNewPage />,
  },
  {
    path: "/users/new-user-group",
    exact: true,
    auth: true,
    name: "NewGroup",
    component: <UserGroupNewPage />,
  },
  {
    path: "/clients/new-client",
    exact: true,
    auth: true,
    name: "NewClient",
    component: <ClientNewPage />,
  },
];

export default routes;
