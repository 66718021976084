import React, { createContext, useContext } from "react";
import { AxiosContext } from "./axios_context";
import config from "../config/config";
import axios from "axios";

const UpdashTwilioContext = createContext(undefined);

const UpdashTwilioContextProvider = (props) => {
  const { setLoading } = useContext(AxiosContext);

  const axiosInstance = axios.create({
    baseURL: config.domain,
    headers: {
      // "x-access-token": token,
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
      // "Access-Control-Allow-Origin": "*"
    },
  });

  const makeOutboundCallApi = () => {
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.updashtwilioApi}/outboundcall/makecall.php`,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
        return error;
      });
  };

  const providerValue = {
    makeOutboundCallApi,
  };

  return (
    <UpdashTwilioContext.Provider value={providerValue}>
      {props.children}
    </UpdashTwilioContext.Provider>
  );
};

export { UpdashTwilioContext, UpdashTwilioContextProvider };
