import React, { useContext, useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Chip,
  LinearProgress,
  Skeleton,
  Stack,
  Switch,
  Typography,
  styled,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { AuthContext, SettingContext } from "../../../context";
import { PhoneInput, PrimarySelect } from "../../../components";
import { ColorsConfig, Config, Constants } from "../../../config";
import { toast } from "react-toastify";
import { UserHelper } from "../../../utils";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function UserSettings() {
  const { getProfile, updateProfile, pictureUpload } =
    useContext(SettingContext);
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [info, setInfo] = useState(null);
  const [pageData, setPageData] = useState({
    userId: user?.Id,
    mphone: "",
    ophone: "",
    username: "",
    password: "",
    img: "",
    salesman: "",
  });

  useEffect(() => {
    if (user && user.Id) {
      getInfo();
    }
  }, [user]);

  useEffect(() => {
    if (info) {
      setPageData({
        userId: info?.userinfo?.Id,
        mphone: info?.userinfo?.Mobile,
        ophone: info?.userinfo?.Id,
        username: info?.userinfo?.Id,
        password: "",
        img: info?.userinfo?.Image,
        salesman: info?.userinfo?.Issalesmen,
        webNotifications: info?.webpush?.look == 0 ? false : true,
        appointmentNotification: info?.userinfo?.Notificationtime
          ? true
          : false,
        Notificationtime: info?.userinfo?.Notificationtime,
      });
    }
  }, [info]);

  const getInfo = async () => {
    setLoading(true);
    const result = await getProfile();
    setInfo(result);
    setLoading(false);
  };

  const handleUploadFile = async (files) => {
    if (files.length > 0) {
      const file = files[0];
      if (file.size > 10 * 1024) {
        toast.warn("File size exceeds 10mb");
        return false;
      }
    }
    setUploadLoading(true);
    const result = await pictureUpload(files[0], files[0].name);
    if (result && result.filename) {
      setPageData({
        ...pageData,
        img: result.filename,
      });
    }
    setUploadLoading(false);
  };

  const submit = async () => {
    const data = {
      userId: user.Id,
      mphone: pageData.mphone,
      ophone: pageData.ophone,
      username: info?.userinfo?.Name,
      img: pageData.img,
      salesman: pageData.salesman,
      Id: info?.Id,
    };
    setConfirmLoading(true);
    await updateProfile(data);
    setConfirmLoading(false);
    toast.success("Successfully updated!");
  };

  const skeleton = () => {
    return (
      <Stack direction={"column"} spacing={2}>
        <Stack
          direction={{
            xs: "column",
            md: "row",
          }}
          alignItems={"center"}
          spacing={2}
        >
          <Skeleton variant="rounded" width={100} height={40} />
          <Skeleton variant="rounded" width={200} height={40} />
          <Skeleton variant="rounded" width={200} height={40} />
        </Stack>
        <Stack
          direction={{
            xs: "column",
            md: "row",
          }}
          alignItems={"center"}
          spacing={2}
        >
          <Skeleton variant="rounded" width={100} height={40} />
          <Skeleton variant="rounded" width={100} height={40} />
        </Stack>
        <Stack
          direction={{
            xs: "column",
            md: "row",
          }}
          alignItems={"center"}
          spacing={2}
        >
          <Skeleton variant="rounded" width={100} height={40} />
          <Skeleton variant="rounded" width={100} height={40} />
        </Stack>
        <Stack
          direction={{
            xs: "column",
            md: "row",
          }}
          alignItems={"center"}
          spacing={2}
        >
          <Skeleton variant="rounded" width={100} height={40} />
          <Skeleton variant="rounded" width={200} height={40} />
        </Stack>
      </Stack>
    );
  };

  const renderMain = () => {
    return (
      <Box>
        <Stack
          direction={{ xs: "column", md: "row" }}
          alignItems={"start"}
          justifyContent={"center"}
          spacing={3}
        >
          <Stack direction={"column"} spacing={2} alignItems={"center"}>
            {uploadLoading && (
              <Box sx={{ width: "100%" }}>
                <LinearProgress />
              </Box>
            )}
            <Avatar
              sx={{
                width: 150,
                height: 150,
              }}
              src={
                pageData?.img
                  ? `${Config.resourcesPath}/resource/profilepicture/${pageData.img}`
                  : `${Config.assetPath}/images/default.jpg`
              }
            />
            <Button
              component="label"
              variant="contained"
              startIcon={<CloudUploadIcon />}
              sx={{
                width: 100,
              }}
              disabled={uploadLoading}
            >
              Edit
              <VisuallyHiddenInput
                type="file"
                onChange={(e) => {
                  handleUploadFile(e.target.files);
                }}
                accept=".png, .jpg, .jpeg, .git"
              />
            </Button>
          </Stack>
          <Stack direction={"column"} spacing={2}>
            <Stack direction={"row"} alignItems={"center"} spacing={1}>
              <Typography>{info?.userinfo?.Name}</Typography>
              <Chip
                label={UserHelper.getUserType(info?.userinfo?.Type)}
                color="primary"
                size="small"
              />
            </Stack>
            <Stack direction={"column"} spacing={1.5}>
              <Typography
                variant="body2"
                sx={{ color: ColorsConfig.light.info }}
              >
                {info?.userinfo?.Email}
              </Typography>
              {/* <Button
                size="small"
                variant="contained"
                sx={{
                  textTransform: "none",
                  width: 160,
                }}
              >
                Change Password
              </Button> */}
            </Stack>
            <PhoneInput
              label={"Mobile Phone"}
              value={pageData.mphone}
              handleChange={(e) => {
                setPageData({
                  ...pageData,
                  mphone: e.target.value,
                });
              }}
              sx={{
                width: 400,
              }}
            />
            <Stack
              direction={{
                xs: "column",
              }}
              alignItems={"start"}
              spacing={0}
            >
              <Typography>Web Notification</Typography>
              <Switch
                checked={pageData.webNotifications}
                onChange={(e) => {
                  console.log(e.target.value);
                  setPageData({
                    ...pageData,
                    webNotifications: e.target.checked,
                  });
                }}
              />
            </Stack>
            <Stack
              direction={{
                xs: "column",
              }}
              alignItems={"start"}
              spacing={0}
            >
              <Typography>Appointment notification</Typography>
              <Switch
                checked={pageData.appointmentNotification}
                onChange={(e) =>
                  setPageData({
                    ...pageData,
                    appointmentNotification: e.target.checked,
                  })
                }
              />
            </Stack>
            {pageData.appointmentNotification && (
              <PrimarySelect
                options={Constants.notificationsTimes}
                value={pageData.Notificationtime}
                handleChange={(e) =>
                  setPageData({ ...pageData, Notificationtime: e.target.value })
                }
                sx={{
                  width: 400,
                }}
              />
            )}
          </Stack>
        </Stack>
        {confirmLoading && (
          <Box
            sx={{
              mt: 2,
            }}
          >
            <LinearProgress />
          </Box>
        )}
        <Stack
          direction={"row"}
          spacing={3}
          alignItems={"center"}
          justifyContent={"center"}
          mt={3}
        >
          <Button
            variant="contained"
            onClick={submit}
            disabled={confirmLoading}
          >
            Save
          </Button>
          <Button variant="outlined" color="inherit" disabled={confirmLoading}>
            Cancel
          </Button>
        </Stack>
      </Box>
    );
  };

  return <Box mt={2}>{loading ? skeleton() : renderMain()}</Box>;
}
