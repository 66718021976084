import React from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { BsTelephone } from "react-icons/bs";
import helper from "../../utils/helper";
import config from "../../config/config";

const localizer = momentLocalizer(moment);

export default function AppointmentsCalendarView({
  data = [],
  isdealerfunneluser = "",
}) {
  const formattedEvents = (data) => {
    let _events = [];
    for (let i = 0; i < data.length; i++) {
      _events.push({
        allDay: false,
        end: new Date(
          `${data[i].Sdate.year}-${data[i].Sdate.month[0]}-${
            data[i].Sdate.day
          }T${helper.convertTo24HoursFormat(data[i].Stime)}`,
        ),
        start: new Date(
          `${data[i].Sdate.year}-${data[i].Sdate.month[0]}-${
            data[i].Sdate.day
          }T${helper.convertTo24HoursFormat(data[i].Stime)}`,
        ),
        // title: data[i].Stime + ' ' + data[i].Name,
        title: (
          <a
            target="_blank"
            className="text-decoration-none text-white px-1 w-100 h-100 d-block"
            href={
              isdealerfunneluser === 1
                ? `${config.smsPath}/${data[i].Keyid}`
                : `${config.campaignsPath}/${data[i].CMPKey}
                &lead=${data[i].LeadId}&barcode=${data[i].Barcode}`
            }
            style={{
              backgroundColor: helper.getColorCodeForCalender(
                data[i].ApptsStatus,
              ),
            }}
            rel="noreferrer"
          >
            {`${data[i].Stime} ${data[i].Name}`}{" "}
            {data[i].Apptype === 2 ? <BsTelephone className="icon" /> : ""}
          </a>
        ),
      });
    }
    console.log(">> events >>", _events);
    return _events;
  };

  return (
    <div className="card">
      <div className="card-body">
        <Calendar
          localizer={localizer}
          events={formattedEvents(data)}
          startAccessor="start"
          endAccessor="end"
          showAllEvents={true}
          // style={{ minHeight: 600, height: 'auto' }}
        />
      </div>
    </div>
  );
}
