import React, { useState } from "react";
import { Box, Card, Container, Stack, Tab, Typography } from "@mui/material";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import { ColorsConfig } from "../../config";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  UserAgencyDetails,
  UserAgentSettings,
  UserDefaultMessages,
  UserLabels,
  UserSavedReplies,
  UserSettings,
  UserTwilio,
} from "..";

export default function UserProfile() {
  const tabItems = [
    {
      name: "Agency Settings",
      value: "tab-1",
    },
    {
      name: "My Settings",
      value: "tab-2",
    },
  ];
  const agencyTabItems = [
    {
      name: "Agency Details",
      value: "agency-tab-1",
      component: <UserAgencyDetails />,
    },
    {
      name: "Saved Replies",
      value: "agency-tab-2",
      component: <UserSavedReplies />,
    },
    {
      name: "Labels",
      value: "agency-tab-3",
      component: <UserLabels />,
    },
    {
      name: "Agent Settings",
      value: "agency-tab-4",
      component: <UserAgentSettings />,
    },
    {
      name: "Default Messages",
      value: "agency-tab-5",
      component: <UserDefaultMessages />,
    },
    {
      name: "Twilio",
      value: "agency-tab-6",
      component: <UserTwilio />,
    },
  ];
  const [activeTab, setActiveTab] = useState(tabItems[0].value);
  const [activeAgencyTab, setActiveAgencyTab] = useState(
    agencyTabItems[0].value,
  );

  const handleChangeTab = (event, value) => {
    console.log(event);
    setActiveTab(value);
  };
  const handleChangeAgencyTab = (event, value) => {
    console.log(event);
    setActiveAgencyTab(value);
  };

  return (
    <Container maxWidth="xl" sx={{ mt: 2 }}>
      <Stack
        direction="row"
        alignItems={"center"}
        justifyContent={"start"}
        spacing={1}
      >
        <TuneOutlinedIcon style={{ fontSize: 18 }} />
        <Typography>Settings</Typography>
      </Stack>
      <Box mt={2}>
        <Card
          sx={{
            p: 2,
            pt: 3,
            height: "100%",
          }}
        >
          <TabContext value={activeTab}>
            <Stack
              direction={{
                xs: "column",
                md: "row",
              }}
            >
              <TabList
                orientation="vertical"
                onChange={handleChangeTab}
                aria-label="users-profile-tabs"
                sx={{
                  borderRight: 1,
                  borderColor: ColorsConfig.light.primaryBorderColor,
                  minWidth: 200,
                }}
              >
                {tabItems.map((item, index) => {
                  return (
                    <Tab
                      label={item.name}
                      value={item.value}
                      key={`tab-${index}`}
                      sx={{
                        textAlign: "left",
                        textTransform: "none",
                        alignItems: "start",
                      }}
                    />
                  );
                })}
              </TabList>
              <Box sx={{ width: "100%" }}>
                <TabPanel value="tab-1" sx={{ mt: 0, pt: 0 }}>
                  <TabContext value={activeAgencyTab}>
                    <TabList
                      onChange={handleChangeAgencyTab}
                      aria-label="users-profile-agency-tabs"
                      sx={{
                        borderBottom: 1,
                        borderColor: ColorsConfig.light.primaryBorderColor,
                      }}
                    >
                      {agencyTabItems.map((item, index) => {
                        return (
                          <Tab
                            label={item.name}
                            sx={{ textTransform: "none" }}
                            value={item.value}
                            key={`agency-tab-${index}`}
                          />
                        );
                      })}
                    </TabList>
                    {agencyTabItems.map((item, index) => {
                      return (
                        <TabPanel
                          value={item.value}
                          key={`agency-tab-panel-${index}`}
                          sx={{ pt: 1 }}
                        >
                          {item.component}
                        </TabPanel>
                      );
                    })}
                  </TabContext>
                </TabPanel>
                <TabPanel value="tab-2">
                  <UserSettings />
                </TabPanel>
              </Box>
            </Stack>
          </TabContext>
        </Card>
      </Box>
    </Container>
  );
}
