import React from "react";
import { Box, InputLabel } from "@mui/material";
import { ColorsConfig } from "../../config";
import { TimePicker } from "@mui/x-date-pickers";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";

export default function PrimaryTimePicker(props) {
  const { value, handleChange, label, required } = props;

  return (
    <Box sx={{ minWidth: 120 }}>
      {label && (
        <InputLabel
          sx={{ fontSize: "16px", color: ColorsConfig.light.primaryColor }}
        >
          {label}
          {required && (
            <span style={{ color: ColorsConfig.light.error }}>*</span>
          )}
        </InputLabel>
      )}
      <TimePicker
        value={value}
        viewRenderers={{
          hours: renderTimeViewClock,
          minutes: renderTimeViewClock,
        }}
        onChange={(v) => handleChange(v)}
      />
    </Box>
  );
}
