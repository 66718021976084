function numberEN_USFormat(data) {
  if (!data) return "0";
  if (!Number(data)) return data;
  const result = Intl.NumberFormat("en-US").format(data);
  return result;
}

function getColorFromClass(item) {
  if (!item || item?.split("-").length < 2) return null;
  return item?.split("-")[1];
}

export default {
  numberEN_USFormat,
  getColorFromClass,
};
