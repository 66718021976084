import Modal from "react-modal";
import { AuthContext, ClientContext } from "../../context";
import React, { useEffect, useState, useContext } from "react";
import { BsTelephone } from "react-icons/bs";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "560px",
    padding: "15px",
    marginTop: "20px",
    marginBottom: "20px",
    overflow: "visible",
  },
};

Modal.setAppElement("#root");

export default function ClientSearchSmsPhoneModal({
  isOpenModal = false,
  afterOpenModal,
  closeModal,
  onSearch,
}) {
  const { user } = useContext(AuthContext);
  const { getSearchSmsPhoneModalDataApi } = useContext(ClientContext);
  const [cityList, setCityList] = useState([]);
  const [autocompletecity, setAutocompletecity] = useState([]);
  //
  const [searchParams, setSearchParams] = useState({
    type: "",
    city: "",
    contains: "",
  });

  useEffect(() => {
    loadModalData();
    console.log(user);
  }, []);

  const loadModalData = async () => {
    const data = await getSearchSmsPhoneModalDataApi();
    if (data.status === "ok") {
      const res = data.response;
      setCityList(res.city);
    }
  };

  const onKeyUpCity = (e) => {
    console.log(e);
    if (searchParams.city.length > 2) {
      setAutocompletecity(
        cityList.filter((el) =>
          el.toLowerCase().includes(searchParams.city.toLowerCase()),
        ),
      );
    } else {
      setAutocompletecity([]);
    }
  };

  return (
    <Modal
      isOpen={isOpenModal}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel={"Import Leads"}
    >
      <div className="modal-header">
        <h4 className="d-flex align-items-center">
          <BsTelephone className="me-2" />
          Search Phone Number
        </h4>
      </div>
      <div className="modal-body">
        <div className="row">
          <div className="col-sm-6">
            <div className="form-group mt-3">
              <label className="mb-2">Phone Number Type</label>
              <select
                id="ptype"
                className="form-select"
                value={searchParams.type}
                onChange={(e) =>
                  setSearchParams({ ...searchParams, type: e.target.value })
                }
              >
                <option value="Local">Local</option>
              </select>
            </div>
            <div className="form-group mt-3">
              <label className="mb-2">Matching This Pattern</label>
              <input
                className="form-control"
                id="contains"
                type="text"
                value={searchParams.contains}
                onChange={(e) =>
                  setSearchParams({ ...searchParams, contains: e.target.value })
                }
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group mt-3">
              <label className="mb-2">City</label>
              <div className="autocomplete-form">
                <input
                  type="text"
                  id="city"
                  className="form-control"
                  value={searchParams.city}
                  onChange={(e) =>
                    setSearchParams({ ...searchParams, city: e.target.value })
                  }
                  onKeyUp={(e) => onKeyUpCity(e)}
                />
                {autocompletecity.length > 0 && (
                  <ul
                    className="autocomplete-dropdown"
                    style={{ maxHeight: "200px" }}
                  >
                    {autocompletecity.map((city, index) => (
                      <li
                        className="autocomplete-item"
                        key={index}
                        onClick={() => {
                          setSearchParams({ ...searchParams, city: city });
                          setAutocompletecity([]);
                        }}
                      >
                        {city}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer mt-3">
        <button
          className="btn btn-primary"
          onClick={() => onSearch(searchParams)}
        >
          Search
        </button>
        <button className="btn btn-outline-secondary ms-2" onClick={closeModal}>
          Cancel
        </button>
      </div>
    </Modal>
  );
}
