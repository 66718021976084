import React, { useContext, useEffect, useState } from "react";
import { BsActivity, BsChevronRight } from "react-icons/bs";
import { AuthContext, ReportContext } from "../../context";
import config from "../../config/config";
import helper from "../../utils/helper";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";

export default function ReportLeadsPage() {
  const { user } = useContext(AuthContext);
  const { getReportLeadsApi } = useContext(ReportContext);
  //
  const [breadcrumb, setBreadcrumb] = useState([]);
  const [leads, setLeads] = useState([]);
  //
  const [currentPage, setCurrentPage] = useState(1);
  const perPage = 9;
  const [search, setSearch] = useState("");

  useEffect(() => {
    getReportLeadsData();
  }, []);

  const getReportLeadsData = async () => {
    let from = window.sessionStorage.getItem("reports_from");
    let lead = window.sessionStorage.getItem("reports_lead");
    if (!from) {
      return;
    }
    let _params = {
      userId: user.Id,
      from: from,
      lead: lead,
    };
    const data = await getReportLeadsApi(_params);
    if (data.status === "ok") {
      const res = data.response;
      if (res?.breadcrumb) {
        setBreadcrumb(
          Object.keys(res?.breadcrumb).map((key) => {
            return { key: key, value: res?.breadcrumb[key] };
          }),
        );
      }
      setLeads(res?.res);
    }
  };

  return (
    <main className="container py-4">
      <div className="d-flex align-items-center">
        <div className="section-title">
          <BsActivity className="icon me-2" />
          Activity Report
        </div>
      </div>
      <ul className="breadcrumb">
        {breadcrumb.map((item, index) =>
          item ? (
            <li key={index}>
              <a href={`${config.baseUrl}/${item.value}`}>{item.key}</a>
              <span className="divider">
                <BsChevronRight />
              </span>
            </li>
          ) : (
            <li className="active" key={item.key}>
              {item.key}
            </li>
          ),
        )}
      </ul>
      <div className="card">
        <div className="card-header">
          <div className="row">
            <div className="col-12 col-sm-3 col-md-2">
              <input
                className="form-control"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="card-body p-0">
          <table className="table table-hover">
            <thead>
              <tr>
                <th></th>
                <th>Name</th>
                <th>Status</th>
                <th>Client</th>
                <th>Lead Source</th>
                <th>Last Incoming</th>
                <th>Last Outgoing</th>
              </tr>
            </thead>
            <tbody>
              {leads
                .filter((item) =>
                  item.Name.toLowerCase().includes(search.toLowerCase()),
                )
                .map((item, index) => (
                  <tr key={index}>
                    <td>
                      <div className="avatar">
                        <a href={`${config.smsPath}/s/${item.Responsekey}`}>
                          <img
                            src={
                              item.Mediaimage
                                ? item.Mediaimage
                                : `${
                                    config.assetPath
                                  }/images/material-letter-icons/png/${item.Name.charAt(
                                    0,
                                  ).toUpperCase()}.png`
                            }
                            className="rounded-circle thumbnail"
                            width={40}
                            height={40}
                            alt=""
                          />
                        </a>
                        <span className="message-icon">
                          <img
                            src={
                              Number(item.Messagetype) === 1
                                ? `${config.assetPath}/images/sms.svg`
                                : `${config.assetPath}/images/messenger.webp`
                            }
                            width={10}
                            height={10}
                            alt=""
                          />
                        </span>
                      </div>
                    </td>
                    <td>
                      <a
                        className="d-flex flex-column text-decoration-none"
                        href={`${config.smsPath}/s/${item.Responsekey}`}
                      >
                        {item.Name}
                        <small className="text-secondary">
                          {helper.phoneformate(item.phone, 3)}
                        </small>
                      </a>
                    </td>
                    <td>
                      <span
                        className={`label${helper.getLabelIconClass(
                          item.Label,
                        )}`}
                      >
                        {helper.getLabelIconKeyword(item.Label)}
                      </span>
                    </td>
                    <td>{item.client_name}</td>
                    <td>Facebook</td>
                    <td>
                      <abbr
                        style={{ fontSize: "11px" }}
                        className="timeago"
                        title={item.smsleadresponse_date}
                      >
                        {item.smsleadresponse_date}
                      </abbr>
                    </td>
                    <td>
                      <abbr
                        style={{ fontSize: "11px" }}
                        className="timeago"
                        title={item.smsoutgoing_date}
                      >
                        {item.smsoutgoing_date}
                      </abbr>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className="card-footer py-4 bg-white">
          {leads && (
            <div className="table-pagination">
              <div className="per-page">
                Page {currentPage} of{" "}
                {Math.ceil(
                  leads.filter((item) =>
                    item.Name.toLowerCase().includes(search.toLowerCase()),
                  ).length / perPage,
                )}{" "}
                |{" "}
                {
                  leads.filter((item) =>
                    item.Name.toLowerCase().includes(search.toLowerCase()),
                  ).length
                }{" "}
                Campaigns
              </div>
              <div className="pagination">
                <button
                  className={`btn-page ${currentPage === 1 ? "disabled" : ""}`}
                  onClick={() => {
                    if (currentPage > 1) setCurrentPage(currentPage - 1);
                  }}
                >
                  <GrFormPrevious />
                </button>
                {currentPage > 2 ? (
                  <button className={`btn-page`}>...</button>
                ) : null}
                {[
                  ...Array(
                    Math.ceil(
                      leads.filter((item) =>
                        item.Name.toLowerCase().includes(search.toLowerCase()),
                      ).length / perPage,
                    ),
                  ),
                ].map((e, i) =>
                  i + 1 > currentPage - 2 && i + 1 < currentPage + 2 ? (
                    <button
                      className={`btn-page ${
                        currentPage === i + 1 ? "active" : ""
                      }`}
                      key={i}
                      onClick={() => setCurrentPage(i + 1)}
                    >
                      {i + 1}
                    </button>
                  ) : null,
                )}
                {currentPage <
                Math.ceil(
                  leads.filter((item) =>
                    item.Name.toLowerCase().includes(search.toLowerCase()),
                  ).length / perPage,
                ) -
                  1 ? (
                  <button className={`btn-page`}>...</button>
                ) : null}
                <button
                  className={`btn-page ${
                    currentPage ===
                    Math.ceil(
                      leads.filter((item) =>
                        item.Name.toLowerCase().includes(search.toLowerCase()),
                      ).length / perPage,
                    )
                      ? "disabled"
                      : ""
                  }`}
                  onClick={() => {
                    if (
                      currentPage <
                      Math.ceil(
                        leads.filter((item) =>
                          item.Name.toLowerCase().includes(
                            search.toLowerCase(),
                          ),
                        ).length / perPage,
                      )
                    )
                      setCurrentPage(currentPage + 1);
                  }}
                >
                  <GrFormNext />
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </main>
  );
}
