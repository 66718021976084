import React from "react";
import { Avatar, Box, Skeleton, Stack, Typography, Zoom } from "@mui/material";
import { ColorsConfig } from "../../config";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LockIcon from "@mui/icons-material/Lock";
import config from "../../config/config";
import moment from "moment";
import { helper } from "../../utils";
import TypingLoader from "../loaders/typing_loader";

export default function ConversationsListItem(props) {
  const {
    itemIndex,
    item,
    isSelected,
    isLock,
    isTyping,
    isActivated,
    loading,
    handleClick,
    handleSelect,
  } = props;

  const renderSkeleton = () => {
    return (
      <Box
        sx={{
          p: "10px 12px",
          my: "1px",
        }}
      >
        <Skeleton variant="rounded" width={"100%"} height={60} />
      </Box>
    );
  };

  const renderView = () => {
    return (
      <Zoom
        in
        style={{
          transitionDelay: itemIndex == 0 ? "500ms" : "0ms",
        }}
      >
        <Box
          sx={{
            cursor: "pointer",
            bgcolor: isActivated
              ? `${ColorsConfig.light.info}`
              : isSelected
              ? "rgba(33, 150, 243, 0.3)"
              : "transparent",
            p: "10px 12px",
            my: "1px",
          }}
        >
          <Stack spacing={1} direction={"row"} alignItems={"start"}>
            <Box onClick={() => handleSelect(item)}>
              {isSelected ? (
                <CheckCircleIcon
                  sx={{
                    color: "#2196F3",
                    fontSize: 44,
                  }}
                />
              ) : (
                <Box
                  sx={{
                    position: "relative",
                    width: 40,
                    height: 50,
                  }}
                >
                  <Avatar
                    src={
                      Number(item.Ismediaimage) === 1
                        ? item.Mediaimage
                        : `${
                            config.domain
                          }/assets/images/material-letter-icons/png/${item.pname
                            .charAt(0)
                            .toUpperCase()}.png`
                    }
                    sx={{
                      width: 40,
                      height: 40,
                    }}
                  />
                  {isTyping && (
                    <span
                      style={{
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        width: "100%",
                      }}
                    >
                      <TypingLoader />
                    </span>
                  )}
                  <span
                    style={{
                      position: "absolute",
                      bottom: 15,
                      right: 1,
                      backgroundColor: "white",
                      borderRadius: "50%",
                      width: "10px",
                      height: "10px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      width="8"
                      height="8"
                      alt=""
                      src={
                        Number(item.type) === 2 || Number(item.type) === 32
                          ? `${config.assetPath}/images/messenger.webp`
                          : `${config.assetPath}/images/sms.svg`
                      }
                    />
                  </span>
                </Box>
              )}
            </Box>
            <Box
              onClick={() => handleClick(item)}
              sx={{
                width: "100%",
              }}
            >
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Stack direction={"row"} alignItems={"center"} spacing={1}>
                  <Typography
                    sx={{
                      fontSize: 13,
                      fontWeight: 400,
                      color: "black[100]",
                    }}
                  >
                    {item.pname}
                  </Typography>
                  <span
                    className={`badge rounded-pill ms-1 ${helper.getLabelIconClass(
                      item.Label,
                    )}`}
                  >
                    {helper.getLabelText(item.Label)}
                  </span>
                </Stack>
                <small
                  style={{
                    color:
                      Number(item.Isawaiting) === 1
                        ? ColorsConfig.light.error
                        : ColorsConfig.light.primaryLightColor,
                  }}
                >
                  {Number(item.Isawaiting) === 1
                    ? `waiting ${moment(item.leadresponse).fromNow(true)}`
                    : item.mdate}
                </small>
              </Stack>
              <Stack direction="row" justifyContent={"space-between"}>
                <Stack direction={"row"} alignItems={"center"} spacing={1}>
                  <Typography
                    sx={{
                      fontSize: 11,
                      color: ColorsConfig.light.primaryLightColor,
                    }}
                  >
                    {item.cmp}
                  </Typography>
                  {Number(item.Assigneduser) > 0 && (
                    <span className="badge rounded-pill mx-1 bg-primary-outline">
                      {item.Assignusername}
                    </span>
                  )}
                </Stack>
                {isLock && (
                  <LockIcon
                    sx={{
                      fontSize: 12,
                      color: ColorsConfig.light.primaryLightColor,
                    }}
                  />
                )}
              </Stack>
              <Typography
                sx={{
                  fontSize: 12,
                  fontWeight: 400,
                  maxWidth: "300px",
                  color: "black[100]",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {item.message}
              </Typography>
              {Number(item.isSchedule) !== 0 && (
                <Stack direction={"row"} alignItems={"center"} spacing={0}>
                  <AccessTimeIcon
                    sx={{
                      fontSize: 14,
                      color: ColorsConfig.light.primaryLightColor,
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: 11,
                      color: ColorsConfig.light.primaryLightColor,
                    }}
                  >
                    {item.isSchedule} scheduled message
                  </Typography>
                </Stack>
              )}
            </Box>
          </Stack>
        </Box>
      </Zoom>
    );
  };

  return <Box>{loading ? renderSkeleton() : renderView()}</Box>;
}
