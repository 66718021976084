import { Slider, Switch } from "@mui/material";
import React, { useContext, useState, useEffect } from "react";
import { BiArrowBack } from "react-icons/bi";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  BlastConfirmSendModal,
  BlastScheduleMessageModal,
  ConversationSavedRepliesModal,
  DropdownMultiSelect,
  GifPickerInput,
  NewBlastLeadsTable,
  PlAttachFileUploader,
  SmartFieldsInput,
} from "../../components";
import { AuthContext, BlastContext, SharedContext } from "../../context";
import { HiArrowRight } from "react-icons/hi2";
import {
  BsBookmark,
  BsClock,
  BsHandThumbsDown,
  BsHandThumbsUp,
} from "react-icons/bs";
import ContentEditable from "react-contenteditable";
import config from "../../config/config";

function valuetext(value) {
  return `${value}°C`;
}

export default function BlastCopyPage() {
  const { bid } = useParams();
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const {
    getCopyBlastPageDataApi,
    ajaxfilterApi,
    ajaxpaginationApi,
    sendBlastSummaryApi,
  } = useContext(BlastContext);
  const { getmultivehicleApi } = useContext(SharedContext);
  //
  const [activeForm, setActiveForm] = useState(1); // 1 | 2
  //
  const [customColor, setCustomColor] = useState("");
  const [iscopy, setIscopy] = useState(0);
  const [splitlead, setSplitlead] = useState([]);
  const [selectedLead, setSelectedLead] = useState([]);
  const [totalLead, setTotalLead] = useState(0);
  const [totalSelected, setTotalSelected] = useState(0);
  const [client, setClient] = useState([]);
  const [selectedClients, setSelectedClients] = useState([]);
  const [campaign, setCampaign] = useState([]);
  const [selectedCampaigns, setSelectedCampaigns] = useState([]);
  const [leadsource, setLeadsource] = useState([]);
  const [selectedLeadsources, setSelectedLeadsources] = useState([]);
  const [usertag, setUsertag] = useState([]);
  const [selectedUsertags, setSelectedUsertags] = useState([]);
  const [status, setStatus] = useState([]);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [appt_status, setAppt_status] = useState([]);
  const [selectedApptStatuses, setSelectedApptStatuses] = useState([]);
  const [messagetype, setMessagetype] = useState([
    { Id: 1, Name: "SMS" },
    { Id: 2, Name: "Facebook Message" },
  ]);
  const [selectedMessagetypes, setSelectedMessagetypes] = useState([]);
  const [ls_listname, setLs_listname] = useState([]);
  const [selectedLsListnames, setSelectedLsListnames] = useState([]);
  const [assignuserlist, setAssignuserlist] = useState([]);
  const [selectedAssignuserlist, setSelectedAssignuserlist] = useState([]);
  // -
  const [vyears, setVyears] = useState([]);
  const [selectedVyears, setSelectedVyears] = useState([]);
  const [vmakes, setVmakes] = useState([]);
  const [selectedVmakes, setSelectedVmakes] = useState([]);
  const [vmodels, setVmodels] = useState([]);
  const [selectedVmodels, setSelectedVmodels] = useState([]);
  // -
  const [cyears, setCyears] = useState([]);
  const [selectedCyears, setSelectedCyears] = useState([]);
  const [cmakes, setCmakes] = useState([]);
  const [selectedCmakes, setSelectedCmakes] = useState([]);
  const [cmodels, setCmodels] = useState([]);
  const [selectedCmodels, setSelectedCmodels] = useState([]);
  const [template, setTemplate] = useState(undefined);
  //
  const [mainData, setMainData] = useState({
    blast_new_popgiphykey: "",
    blast_new_popgiphysize: "",
    blast_new_popimagepath: "",
    affirmative_response_popgiphykey: "",
    affirmative_response_popgiphysize: "",
    affirmative_response_popimagepath: "",
    negative_response_popgiphykey: "",
    negative_response_popgiphysize: "",
    negative_response_popimagepath: "",
    //
    blast_new_scmp: "",
    // blast_new_is_schedule: 0,
    blast_new_is_funnel: 0,
    blast_new_leads: "",
    blast_new_total_leads: 0,
    blast_new_total_page: 0,
    blast_new_is_select_all: 0,
    blast_new_is_focus: 0,
    isselect: 0,
    blast_clt: "",
    blast_new_select_lead_count: 0,
  });
  //
  const [subData, setSubData] = useState({
    tags: "",
    assignment: "",
    interest_year: [],
    interest_make: [],
    interest_model: [],
    tradein_year: [],
    tradein_make: [],
    tradein_model: [],
  });
  //
  const [tableData, setTableData] = useState(undefined);
  //
  const [postData, setPostData] = useState({
    form_blast_new_blastname: "",
    form_blast_new_message: "",
    form_blast_new_giphykey: "",
    form_blast_new_giphysize: "",
    form_blast_new_media: "",
    //
    form_blast_affirmative_response_message: "",
    form_blast_affirmative_response_giphykey: "",
    form_blast_affirmative_response_giphysize: "",
    form_blast_affirmative_response_media: "",
    //
    form_blast_negative_response_message: "",
    form_blast_negative_response_giphykey: "",
    form_blast_negative_response_giphysize: "",
    form_blast_negative_response_media: "",
    //
    form_blast_new_leads: "",
    form_blast_new_filter_client: "",
    form_blast_new_filter_campaign: "",
    form_blast_new_filter_status: "",
    form_blast_new_filter_appt_status: "",
    form_blast_new_filter_lastresponse: "",
    form_blast_new_filter_isschedule: 0, // blast_new_is_schedule
    form_blast_new_filter_send_status: 0,
    form_blast_new_filter_schedule_date: "",
    form_blast_new_filter_schedule_time: "",
    form_blast_new_filter_lastoutgoing: "",
    form_blast_new_filter_messagetype: "",
    form_blast_new_filter_leadsource: "",
    form_blast_listname: "",
    form_blast_issplit: 0,
    form_blast_perdayleads: 0,
    form_blast_segments: 0,
    form_blast_automatedresponse: 0,
    form_blast_excludesunday: 0,
  });
  const [showError, setShowError] = useState(false);
  const [openSavedReplies, setOpenSavedReplies] = useState(false);
  const [openARMSavedReplies, setOpenARMSavedReplies] = useState(false);
  const [openNRMSavedReplies, setOpenNRMSavedReplies] = useState(false);
  const [confirmSendModal, setConfirmSendModal] = useState(false);
  const [scheduleMessageModal, setScheduleMessageModal] = useState(false);
  //
  const [value, setValue] = useState([20, 37]);
  //

  useEffect(() => {
    if (bid) {
      getCopyBlastPageData();
      console.log(customColor);
      console.log(iscopy);
      console.log(template);
      setMessagetype([
        { Id: 1, Name: "SMS" },
        { Id: 2, Name: "Facebook Message" },
      ]);
    }
  }, [bid]);

  const onClickNext = () => {
    if (validationcheck()) {
      setActiveForm(2);
    }
  };

  const validationcheck = () => {
    let flag = true;
    if (!postData.form_blast_new_blastname) {
      flag = false;
    }
    if (
      !postData.form_blast_new_message &&
      !postData.form_blast_new_giphykey &&
      !postData.form_blast_new_media
    ) {
      flag = false;
    }
    //
    setShowError(!flag);
    return flag;
  };

  const getCopyBlastPageData = async () => {
    const data = await getCopyBlastPageDataApi({ userId: user.Id, id: bid });
    if (data?.status === "ok") {
      const res = data.response;
      setCustomColor(res?.custom_color);
      setIscopy(res?.iscopy);
      if (res?.client) {
        setClient(res?.client);
      }
      if (res?.campaign) {
        setCampaign(res?.campaign);
      }
      if (res?.leadsource) {
        setLeadsource(res?.leadsource);
      }
      if (res?.usertag) {
        setUsertag(res?.usertag);
      }
      if (res?.status) {
        setStatus(res?.status);
      }
      if (res?.appt_status) {
        setAppt_status(res?.appt_status);
      }
      if (res?.ls_listname) {
        setLs_listname(res?.ls_listname);
      }
      if (res?.assignuserlist) {
        setAssignuserlist(res?.assignuserlist);
      }
      if (res?.years) {
        setVyears(res?.years);
        setCyears(res?.years);
      }
      if (res?.splitlead) {
        let _splitlead = Object.values(res?.splitlead);
        setSplitlead(_splitlead);
        let _selectedLead = [];
        console.log(
          "=== tpage >>> splitlead length ===",
          res?.tpage,
          _splitlead.length,
        );
        for (let i = 0; i < _splitlead.length; i++) {
          _selectedLead.push("");
        }
        setSelectedLead(_selectedLead);
      }
      setTemplate(res?.template);
      //
      setTotalLead(res?.total_lead);
      let _mainData = { ...mainData };
      _mainData.blast_new_scmp = res?.blast_scmp;
      _mainData.blast_new_leads = res?.blast_leads;
      _mainData.blast_new_total_leads = res?.total_lead;
      _mainData.blast_new_total_page = res?.tpage;
      _mainData.blast_clt = res?.cltcsv;
      setMainData(_mainData);
      //
      let _postData = { ...postData };
      _postData.form_blast_new_blastname = res?.Name;
      _postData.form_blast_new_message = res?.body;
      _postData.form_blast_new_giphykey = res?.Gif;
      _postData.form_blast_affirmative_response_giphykey = res?.Gif;
      _postData.form_blast_negative_response_giphykey = res?.Gif;
      if (!res?.Gif) {
        _postData.form_blast_new_media = res?.Media;
        _postData.form_blast_affirmative_response_media = res?.Media;
        _postData.form_blast_negative_response_media = res?.Media;
      }
      setPostData(_postData);
    }
  };

  useEffect(() => {
    ajaxfilter();
  }, [mainData]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const ajaxfilter = async (key = "", value = "") => {
    let params = {};
    params.userId = user.Id;
    params.client = "";
    params.response = postData.form_blast_new_filter_status;
    params.appt_status = postData.form_blast_new_filter_appt_status;
    let _campaign = postData.form_blast_new_filter_campaign;
    let _scm = mainData.blast_new_scmp;
    let _client = postData.form_blast_new_filter_client;
    params.lastresponse = postData.form_blast_new_filter_lastresponse;
    params.lastoutgoing = postData.form_blast_new_filter_lastoutgoing;
    params.schedule = postData.form_blast_new_filter_isschedule;
    params.messagetype = postData.form_blast_new_filter_messagetype;
    params.tags = subData.tags;
    params.leadsource = postData.form_blast_new_filter_leadsource;
    params.listname = postData.form_blast_listname;
    params.funnele_exclude = mainData.blast_new_is_funnel;
    params.clt = mainData.blast_clt;
    params.campaign = _scm;
    params.assignment = subData.assignment;
    if (!_campaign) {
      if (!_client) {
        params.campaign = _scm;
        params.fromcmp = 1;
      } else {
        params.client = _client;
        params.fromcmp = 2;
      }
    } else {
      params.campaign = _campaign;
      params.fromcmp = 3;
    }
    if (!params.response) {
      params.response = "";
    }
    if (subData.interest_year) {
      params.interest_year = subData.interest_year;
    }
    if (subData.interest_make) {
      params.interest_make = subData.interest_make;
    }
    if (subData.interest_model) {
      params.interest_model = subData.interest_model;
    }
    if (subData.tradein_year) {
      params.tradein_year = subData.tradein_year;
    }
    if (subData.tradein_make) {
      params.tradein_make = subData.tradein_make;
    }
    if (subData.tradein_model) {
      params.tradein_model = subData.tradein_model;
    }
    if (key) {
      params[key] = value;
    }
    console.log("=== filter params ===", params);
    const data = await ajaxfilterApi(params);
    if (data.status === "ok") {
      const res = data.response;
      setTableData(res);
      //
      if (res?.splitlead) {
        let _splitlead = Object.values(res?.splitlead);
        setSplitlead(_splitlead);
        let _selectedLead = [];
        for (let i = 0; i < _splitlead.length; i++) {
          _selectedLead.push("");
        }
        setSelectedLead(_selectedLead);
      }
      setTotalLead(res?.te);
      // let _mainData = {...mainData};
      // _mainData.blast_new_total_leads = res?.te;
      // setMainData(_mainData);
    }
  };

  const get_int_make = async (values) => {
    setSubData({ ...subData, interest_year: values });
    let params = {
      userId: user.Id,
      year: values,
      type: 1,
    };

    const data = await getmultivehicleApi(params);
    if (data.status === "ok") {
      const res = data.response;
      setVmakes(
        res?.objs.map((item) => {
          return { Id: item, Name: item };
        }),
      );
      ajaxfilter("interest_year", values);
    }
  };

  const get_int_model = async (values) => {
    setSubData({ ...subData, interest_model: values });
    let params = {
      userId: user.Id,
      year: subData.interest_year,
      nake: values,
      type: 2,
    };

    const data = await getmultivehicleApi(params);
    if (data.status === "ok") {
      const res = data.response;
      setVmodels(
        res?.objs.map((item) => {
          return { Id: item, Name: item };
        }),
      );
      ajaxfilter("interest_model", values);
    }
  };

  const get_trade_make = async (values) => {
    setSubData({ ...subData, tradein_year: values });
    let params = {
      userId: user.Id,
      year: values,
      type: 3,
    };

    const data = await getmultivehicleApi(params);
    if (data.status === "ok") {
      const res = data.response;
      setCmakes(
        res?.objs.map((item) => {
          return { Id: item, Name: item };
        }),
      );
      ajaxfilter("tradein_year", values);
    }
  };

  const get_trade_model = async (values) => {
    setSubData({ ...subData, tradein_model: values });
    let params = {
      userId: user.Id,
      year: subData.tradein_year,
      nake: values,
      type: 4,
    };

    const data = await getmultivehicleApi(params);
    if (data.status === "ok") {
      const res = data.response;
      setCmodels(
        res?.objs.map((item) => {
          return { Id: item, Name: item };
        }),
      );
      ajaxfilter("tradein_model", values);
    }
  };

  const onClickPagination = (page) => {
    let params = {
      userId: user.Id,
      page: page,
      leads: splitlead[page],
      sleads: selectedLead[page],
      totallead: mainData.blast_new_total_leads,
    };
    ajaxleadrequest(params);
  };

  const ajaxleadrequest = async (params) => {
    const data = await ajaxpaginationApi(params);
    if (data.status === "ok") {
      const res = data.response;
      setTableData(res);
      //
      if (res?.splitlead) {
        let _splitlead = Object.values(res?.splitlead);
        setSplitlead(_splitlead);
        let _selectedLead = [];
        for (let i = 0; i < _splitlead.length; i++) {
          _selectedLead.push("");
        }
        setSelectedLead(_selectedLead);
      }
      setTotalLead(res?.te);
      // let _mainData = {...mainData};
      // _mainData.blast_new_total_leads = res?.te;
      // setMainData(_mainData);
    }
  };

  const selectAll = () => {
    setSelectedLead(splitlead);
    updateTotalSelected(splitlead);
  };

  const unSelectAll = () => {
    let _selectedLead = [];
    for (let i = 0; i < splitlead.length; i++) {
      _selectedLead.push("");
    }
    setSelectedLead(_selectedLead);
    updateTotalSelected(_selectedLead);
  };

  const toggleSelect = (index, leadId) => {
    let _selectedLead = [...selectedLead];
    if (_selectedLead[index].includes(leadId)) {
      let _sleadarr = _selectedLead[index].split(",");
      _selectedLead[index] = _sleadarr
        .filter((item) => item !== leadId)
        .toString();
    } else {
      _selectedLead[index] +=
        _selectedLead[index] === "" ? leadId : "," + leadId;
    }
    setSelectedLead(_selectedLead);
    updateTotalSelected(_selectedLead);
  };

  const onClickThisPageOnly = (index) => {
    let _selectedLead = [...selectedLead];
    _selectedLead[index] = splitlead[index];
    setSelectedLead(_selectedLead);
    updateTotalSelected(_selectedLead);
  };

  const updateTotalSelected = (sleads) => {
    let ts = 0;
    for (let i = 0; i < sleads.length; i++) {
      let subArr = sleads[i].split(",");
      for (let j = 0; j < subArr.length; j++) {
        if (subArr[j]) {
          ts += 1;
        }
      }
    }
    setTotalSelected(ts);
  };

  const scheduleMessage = async (params) => {
    let _postData = { ...postData };
    _postData.form_blast_new_filter_send_status = 2;
    _postData.form_blast_new_filter_schedule_date = params.date;
    _postData.form_blast_new_filter_schedule_time = params.time;
    _postData.form_blast_perdayleads = params.per_day;
    setPostData(_postData);
    const data = await sendBlastSummaryApi(_postData);
    if (data.status === "ok") {
      navigate(`/blasts`);
    }
  };

  const sendNow = async () => {
    const data = await sendBlastSummaryApi(postData);
    if (data.status === "ok") {
      navigate(`/blasts`);
    }
  };

  return (
    <main id="blasts-page" className="container py-4 position-relative">
      <div className={`bg-white p-3${activeForm === 1 ? "" : " d-none"}`}>
        <div className="section-title">
          <Link to={`/blasts`}>
            <BiArrowBack className="icon me-2" />
          </Link>
          New Blast
        </div>
        <div className="row mt-3">
          <div className="col col-sm-9">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group mb-2">
                      <label className="mb-2">Blast Name</label>
                      <input
                        type="text"
                        className="form-control"
                        value={postData.form_blast_new_blastname}
                        onChange={(e) =>
                          setPostData({
                            ...postData,
                            form_blast_new_blastname: e.target.value,
                          })
                        }
                      />
                      {showError && !postData.form_blast_new_blastname && (
                        <span className="text-danger">
                          Blast name is required.
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group mb-2">
                      <label className="mb-2">Message</label>
                      <ContentEditable
                        className="form-contentedit"
                        tagName="div"
                        placeholder=""
                        html={postData?.form_blast_new_message}
                        onChange={(e) =>
                          setPostData({
                            ...postData,
                            form_blast_new_message: e.target.value,
                          })
                        }
                      />
                      <div className="d-flex align-items-center justify-content-between mt-2">
                        <div className="d-flex align-items-center me-auto">
                          <GifPickerInput
                            onSelect={(imgEle, _giphykey, _giphysize) =>
                              setPostData({
                                ...postData,
                                form_blast_new_message:
                                  postData.form_blast_new_message + imgEle,
                                form_blast_new_giphykey: _giphykey,
                                form_blast_new_giphysize: _giphysize,
                              })
                            }
                          />
                          <PlAttachFileUploader
                            url={`${config.smsPath}/mmsupload`}
                            browseButtonId={`pickfiles`}
                            extensions={"jpg,gif,png"}
                            onSuccess={(up, file, response) =>
                              setPostData({
                                ...postData,
                                form_blast_new_media: response.imagepath,
                              })
                            }
                            onError={() => {}}
                          />
                          <BsBookmark
                            size={16}
                            className="me-1"
                            title="Saved Replies"
                            onClick={() => setOpenSavedReplies(true)}
                          />
                          {/* Modals */}
                          <ConversationSavedRepliesModal
                            clientid={""}
                            isOpenModal={openSavedReplies}
                            afterOpenModal={() => {}}
                            closeModal={() => setOpenSavedReplies(false)}
                            onSelect={(reply) => {
                              setPostData({
                                ...postData,
                                form_blast_new_message: reply,
                              });
                              setOpenSavedReplies(false);
                            }}
                          />
                          <SmartFieldsInput
                            onSelect={(field) =>
                              setPostData({
                                ...postData,
                                form_blast_new_message:
                                  postData.form_blast_new_message + field,
                              })
                            }
                          />
                        </div>
                      </div>
                      {showError && !postData.form_blast_new_message && (
                        <span className="text-danger">
                          Message is required.
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-sm-12">
                    <label className="mb-2">Send to</label>
                  </div>
                  <div className="col-sm-4">
                    <DropdownMultiSelect
                      id="blast_new_clientId"
                      className="w-100 mb-2"
                      options={client.map((item) => {
                        return { Id: item.Id, Name: item.Name };
                      })}
                      placeholder={"Select Clients"}
                      labelField="Name"
                      valueField="Id"
                      values={selectedClients}
                      onChange={(values) => setSelectedClients(values)}
                      onDropdownClose={(values) => {
                        setPostData({
                          ...postData,
                          form_blast_new_filter_client: values,
                        });
                      }}
                    />
                  </div>
                  <div className="col-sm-4">
                    <DropdownMultiSelect
                      id="blast_new_cmpId"
                      className="w-100 mb-2"
                      options={campaign.map((item) => {
                        return {
                          Id: item.Id,
                          Name: `${item.Job}- ${item.Name}`,
                        };
                      })}
                      placeholder={"Select Campaigns"}
                      labelField="Name"
                      valueField="Id"
                      values={selectedCampaigns}
                      onChange={(values) => setSelectedCampaigns(values)}
                      onDropdownClose={(values) => {
                        setPostData({
                          ...postData,
                          form_blast_new_filter_campaign: values,
                        });
                      }}
                    />
                  </div>
                  <div className="col-sm-4">
                    <DropdownMultiSelect
                      id="blast_new_leadsource"
                      className="w-100 mb-2"
                      options={leadsource.map((item) => {
                        return { Id: item.Id, Name: item.Name };
                      })}
                      placeholder={"Select Leadsource"}
                      labelField="Name"
                      valueField="Id"
                      values={selectedLeadsources}
                      onChange={(values) => setSelectedLeadsources(values)}
                      onDropdownClose={(values) => {
                        setPostData({
                          ...postData,
                          form_blast_new_filter_leadsource: values,
                        });
                      }}
                    />
                  </div>
                  <div className="col-sm-4">
                    <DropdownMultiSelect
                      id="blast_tag_status"
                      className="w-100 mb-2"
                      options={usertag.map((item) => {
                        return { Id: item.Id, Name: item.Name };
                      })}
                      placeholder={"Select Tags"}
                      labelField="Name"
                      valueField="Id"
                      values={selectedUsertags}
                      onChange={(values) => setSelectedUsertags(values)}
                      onDropdownClose={(values) => {
                        setPostData({ ...subData, tags: values });
                      }}
                    />
                  </div>
                  <div className="col-sm-4">
                    <DropdownMultiSelect
                      id="blast_new_status"
                      className="w-100 mb-2"
                      options={status.map((item) => {
                        return { Id: item.Id, Name: item.Name };
                      })}
                      placeholder={"Select Status"}
                      labelField="Name"
                      valueField="Id"
                      values={selectedStatuses}
                      onChange={(values) => setSelectedStatuses(values)}
                      onDropdownClose={(values) => {
                        setPostData({
                          ...postData,
                          form_blast_new_filter_status: values,
                        });
                      }}
                    />
                  </div>
                  <div className="col-sm-4">
                    <DropdownMultiSelect
                      id="blast_new_appt_status"
                      className="w-100 mb-2"
                      options={appt_status.map((item) => {
                        return { Id: item.Id, Name: item.Name };
                      })}
                      placeholder={"Select Appt Status"}
                      labelField="Name"
                      valueField="Id"
                      values={selectedApptStatuses}
                      onChange={(values) => setSelectedApptStatuses(values)}
                      onDropdownClose={(values) => {
                        setPostData({
                          ...postData,
                          form_blast_new_filter_appt_status: values,
                        });
                      }}
                    />
                  </div>
                  <div className="col-sm-4">
                    <input
                      type="number"
                      className="form-control mb-2"
                      placeholder="Last Incoming (hours)"
                      id="blast_new_lastincoming"
                      value={postData.form_blast_new_filter_lastresponse}
                      onChange={(e) =>
                        setPostData({
                          ...postData,
                          form_blast_new_filter_lastresponse: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="col-sm-4">
                    <input
                      type="number"
                      className="form-control mb-2"
                      placeholder="Last Outgoing (hours)"
                      id="blast_new_lastoutgoing"
                      value={postData.form_blast_new_filter_lastoutgoing}
                      onChange={(e) =>
                        setPostData({
                          ...postData,
                          form_blast_new_filter_lastoutgoing: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="col-sm-4">
                    <DropdownMultiSelect
                      id="blast_new_messagetype"
                      className="w-100 mb-2"
                      options={messagetype}
                      placeholder={"Select Message Type"}
                      labelField="Name"
                      valueField="Id"
                      values={selectedMessagetypes}
                      onChange={(values) => setSelectedMessagetypes(values)}
                      onDropdownClose={(values) => {
                        setPostData({
                          ...postData,
                          form_blast_new_filter_messagetype: values,
                        });
                      }}
                    />
                  </div>
                  <div className="col-sm-4">
                    <DropdownMultiSelect
                      id="blast_listname"
                      className="w-100 mb-2"
                      options={ls_listname.map((item) => {
                        return { Id: item.Id, Name: item.Listname };
                      })}
                      placeholder={"Select Lead Source List"}
                      labelField="Name"
                      valueField="Id"
                      values={selectedLsListnames}
                      onChange={(values) => setSelectedLsListnames(values)}
                      onDropdownClose={(values) => {
                        setPostData({
                          ...postData,
                          form_blast_listname: values,
                        });
                      }}
                    />
                  </div>
                  <div className="col-sm-4">
                    <DropdownMultiSelect
                      id="assignment"
                      className="w-100 mb-2"
                      options={assignuserlist.map((item) => {
                        return { Id: item.Id, Name: item.Name };
                      })}
                      placeholder={"Select Assignment"}
                      labelField="Name"
                      valueField="Id"
                      values={selectedAssignuserlist}
                      onChange={(values) => setSelectedAssignuserlist(values)}
                      onDropdownClose={(values) => {
                        setPostData({ ...subData, assignment: values });
                      }}
                    />
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-sm-12">
                    <label className="mb-2">Vehicle of Interest</label>
                  </div>
                  <div className="col-sm-4">
                    <DropdownMultiSelect
                      id="interest_year"
                      className="w-100 mb-2"
                      options={vyears.map((item) => {
                        return { Id: item, Name: item };
                      })}
                      placeholder={"Select Years"}
                      labelField="Name"
                      valueField="Id"
                      values={selectedVyears}
                      onChange={(values) => setSelectedVyears(values)}
                      onDropdownClose={(values) => {
                        get_int_make(values.split(","));
                      }}
                    />
                  </div>
                  <div className="col-sm-4">
                    <DropdownMultiSelect
                      id="interest_make"
                      className="w-100 mb-2"
                      options={vmakes.map((item) => {
                        return { Id: item.Id, Name: item.Name };
                      })}
                      placeholder={"Select Make"}
                      labelField="Name"
                      valueField="Id"
                      values={selectedVmakes}
                      onChange={(values) => setSelectedVmakes(values)}
                      onDropdownClose={(values) => {
                        get_int_model(values.split(","));
                      }}
                    />
                  </div>
                  <div className="col-sm-4">
                    <DropdownMultiSelect
                      id="interest_model"
                      className="w-100 mb-2"
                      options={vmodels.map((item) => {
                        return { Id: item.Id, Name: item.Name };
                      })}
                      placeholder={"Select Model"}
                      labelField="Name"
                      valueField="Id"
                      values={selectedVmodels}
                      onChange={(values) => setSelectedVmodels(values)}
                      onDropdownClose={(values) => {
                        setSubData({
                          ...subData,
                          interest_model: values.split(","),
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-sm-12">
                    <label className="mb-2">Current Vehicle</label>
                  </div>
                  <div className="col-sm-4">
                    <DropdownMultiSelect
                      id="tradein_year"
                      className="w-100 mb-2"
                      options={cyears.map((item) => {
                        return { Id: item, Name: item };
                      })}
                      placeholder={"Select Years"}
                      labelField="Name"
                      valueField="Id"
                      values={selectedCyears}
                      onChange={(values) => setSelectedCyears(values)}
                      onDropdownClose={(values) => {
                        get_trade_make(values.split(","));
                      }}
                    />
                  </div>
                  <div className="col-sm-4">
                    <DropdownMultiSelect
                      id="tradein_make"
                      className="w-100 mb-2"
                      options={cmakes.map((item) => {
                        return { Id: item.Id, Name: item.Name };
                      })}
                      placeholder={"Select Make"}
                      labelField="Name"
                      valueField="Id"
                      values={selectedCmakes}
                      onChange={(values) => setSelectedCmakes(values)}
                      onDropdownClose={(values) => {
                        get_trade_model(values.split(","));
                      }}
                    />
                  </div>
                  <div className="col-sm-4">
                    <DropdownMultiSelect
                      id="tradein_model"
                      className="w-100 mb-2"
                      options={cmodels.map((item) => {
                        return { Id: item.Id, Name: item.Name };
                      })}
                      placeholder={"Select Model"}
                      labelField="Name"
                      valueField="Id"
                      values={selectedCmodels}
                      onChange={(values) => setSelectedCmodels(values)}
                      onDropdownClose={(values) => {
                        setSubData({
                          ...subData,
                          tradein_model: values.split(","),
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-sm-3">
                    <div className="form-group mb-2">
                      <label className="mb-2">Purchase Date</label>
                      <input type="text" className="form-control" />
                    </div>
                  </div>
                  <div className="col-sm-9">
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group mb-2">
                          <label
                            className="mb-2 w-100 d-flex 
                          align-items-center justify-content-between"
                          >
                            <span>Interest Rate</span>
                            <span className="d-flex align-items-center">
                              <input type="checkbox" className="me-1" />
                              Interest Rate
                            </span>
                          </label>
                          <div className="d-flex align-items-center">
                            <input
                              type="text"
                              className="form-control text-center me-2 px-1"
                              style={{ width: "35px" }}
                            />
                            <Slider
                              getAriaLabel={() => "Temperature range"}
                              value={value}
                              onChange={handleChange}
                              valueLabelDisplay="auto"
                              getAriaValueText={valuetext}
                            />
                            <input
                              type="text"
                              className="form-control text-center ms-2 px-1"
                              style={{ width: "35px" }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group mb-2">
                          <label
                            className="mb-2 w-100 d-flex 
                          align-items-center justify-content-between"
                          >
                            <span>Interest Rate</span>
                            <span className="d-flex align-items-center">
                              <input type="checkbox" className="me-1" />
                              Interest Rate
                            </span>
                          </label>
                          <div className="d-flex align-items-center">
                            <input
                              type="text"
                              className="form-control text-center me-2 px-1"
                              style={{ width: "45px" }}
                            />
                            <Slider
                              getAriaLabel={() => "Temperature range"}
                              value={value}
                              onChange={handleChange}
                              valueLabelDisplay="auto"
                              getAriaValueText={valuetext}
                            />
                            <input
                              type="text"
                              className="form-control text-center ms-2 px-1"
                              style={{ width: "45px" }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group mb-2">
                      <label className="w-100 mb-2">
                        Exclude Leads with Scheduled Messages
                      </label>
                      <Switch
                        checked={
                          postData.form_blast_new_filter_isschedule === 1
                            ? true
                            : false
                        }
                        onChange={() => {
                          setPostData({
                            ...postData,
                            form_blast_new_filter_isschedule:
                              postData.form_blast_new_filter_isschedule === 1
                                ? 0
                                : 1,
                          });
                          ajaxfilter(
                            "schedule",
                            postData.form_blast_new_filter_isschedule === 1
                              ? 0
                              : 1,
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group mb-2">
                      <label className="w-100 mb-2">
                        Exclude Leads within an Active funnel
                      </label>
                      <Switch
                        checked={
                          postData.form_blast_new_filter_send_status === 1
                            ? true
                            : false
                        }
                        onChange={() =>
                          setPostData({
                            ...postData,
                            form_blast_new_filter_send_status:
                              postData.form_blast_new_filter_send_status === 1
                                ? 0
                                : 1,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group mb-2">
                      <label className="w-100 mb-2">Automated Responses</label>
                      <Switch
                        checked={
                          postData.form_blast_automatedresponse === 1
                            ? true
                            : false
                        }
                        onChange={(e) => {
                          console.log(e);
                          setPostData({
                            ...postData,
                            form_blast_automatedresponse:
                              postData.form_blast_automatedresponse === 1
                                ? 0
                                : 1,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
                {postData.form_blast_automatedresponse === 1 && (
                  <>
                    <div className="row">
                      <div className="col-12">
                        <div className="form-group mb-2">
                          <label className="mb-2 d-flex align-items-center">
                            <BsHandThumbsUp className="me-1" />
                            Affirmative Response
                          </label>
                          <ContentEditable
                            className="form-contentedit"
                            tagName="div"
                            placeholder=""
                            html={
                              postData?.form_blast_affirmative_response_message
                            }
                            onChange={(e) =>
                              setPostData({
                                ...postData,
                                form_blast_affirmative_response_message:
                                  e.target.value,
                              })
                            }
                          />
                          <div
                            className="d-flex 
                          align-items-center justify-content-between mt-2"
                          >
                            <div className="d-flex align-items-center me-auto">
                              <GifPickerInput
                                onSelect={(imgEle, _giphykey, _giphysize) =>
                                  setPostData({
                                    ...postData,
                                    form_blast_affirmative_response_message:
                                      postData.form_blast_affirmative_response_message +
                                      imgEle,
                                    form_blast_negative_response_giphykey:
                                      _giphykey,
                                    form_blast_negative_response_giphysize:
                                      _giphysize,
                                  })
                                }
                              />
                              <PlAttachFileUploader
                                url={`${config.smsPath}/mmsupload`}
                                browseButtonId={`pickfiles`}
                                extensions={"jpg,gif,png"}
                                onSuccess={(up, file, response) =>
                                  setPostData({
                                    ...postData,
                                    form_blast_negative_response_media:
                                      response.imagepath,
                                  })
                                }
                                onError={() => {}}
                              />
                              <BsBookmark
                                size={16}
                                className="me-1"
                                title="Saved Replies"
                                onClick={() => setOpenARMSavedReplies(true)}
                              />
                              {/* Modals */}
                              <ConversationSavedRepliesModal
                                clientid={""}
                                isOpenModal={openARMSavedReplies}
                                afterOpenModal={() => {}}
                                closeModal={() => setOpenARMSavedReplies(false)}
                                onSelect={(reply) => {
                                  setPostData({
                                    ...postData,
                                    form_blast_affirmative_response_message:
                                      reply,
                                  });
                                  setOpenARMSavedReplies(false);
                                }}
                              />
                              <SmartFieldsInput
                                onSelect={(field) =>
                                  setPostData({
                                    ...postData,
                                    form_blast_affirmative_response_message:
                                      postData.form_blast_affirmative_response_message +
                                      field,
                                  })
                                }
                              />
                            </div>
                          </div>
                          {showError &&
                            postData.form_blast_automatedresponse === 1 &&
                            !postData.form_blast_affirmative_response_message && (
                              <span className="text-danger">
                                Message is required.
                              </span>
                            )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <div className="form-group mb-2">
                          <label className="mb-2 d-flex align-items-center">
                            <BsHandThumbsDown className="me-1" />
                            Negative Response
                          </label>
                          <ContentEditable
                            className="form-contentedit"
                            tagName="div"
                            placeholder=""
                            html={
                              postData?.form_blast_negative_response_message
                            }
                            onChange={(e) =>
                              setPostData({
                                ...postData,
                                form_blast_negative_response_message:
                                  e.target.value,
                              })
                            }
                          />
                          <div
                            className="d-flex 
                          align-items-center justify-content-between mt-2"
                          >
                            <div className="d-flex align-items-center me-auto">
                              <GifPickerInput
                                onSelect={(imgEle, _giphykey, _giphysize) =>
                                  setPostData({
                                    ...postData,
                                    form_blast_negative_response_message:
                                      postData.form_blast_negative_response_message +
                                      imgEle,
                                    form_blast_negative_response_giphykey:
                                      _giphykey,
                                    form_blast_negative_response_giphysize:
                                      _giphysize,
                                  })
                                }
                              />
                              <PlAttachFileUploader
                                url={`${config.smsPath}/mmsupload`}
                                browseButtonId={`pickfiles`}
                                extensions={"jpg,gif,png"}
                                onSuccess={(up, file, response) =>
                                  setPostData({
                                    ...postData,
                                    form_blast_new_media: response.imagepath,
                                  })
                                }
                                onError={() => {}}
                              />
                              <BsBookmark
                                size={16}
                                className="me-1"
                                title="Saved Replies"
                                onClick={() => setOpenNRMSavedReplies(true)}
                              />
                              {/* Modals */}
                              <ConversationSavedRepliesModal
                                clientid={""}
                                isOpenModal={openNRMSavedReplies}
                                afterOpenModal={() => {}}
                                closeModal={() => setOpenNRMSavedReplies(false)}
                                onSelect={(reply) => {
                                  setPostData({
                                    ...postData,
                                    form_blast_negative_response_message: reply,
                                  });
                                  setOpenNRMSavedReplies(false);
                                }}
                              />
                              <SmartFieldsInput
                                onSelect={(field) =>
                                  setPostData({
                                    ...postData,
                                    form_blast_negative_response_message:
                                      postData.form_blast_negative_response_message +
                                      field,
                                  })
                                }
                              />
                            </div>
                          </div>
                          {showError &&
                            postData.form_blast_automatedresponse === 1 &&
                            !postData.form_blast_negative_response_message && (
                              <span className="text-danger">
                                Message is required.
                              </span>
                            )}
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <div className="p-3">
                  <div className="row">
                    <div className="col-sm-6">
                      <h6>Total Matches: {totalLead}</h6>
                    </div>
                    <div className="col-sm-6">
                      <h6>Total Selected: {totalSelected}</h6>
                    </div>
                  </div>
                </div>
                <div className="">
                  <NewBlastLeadsTable
                    rawData={tableData}
                    onClickPagination={onClickPagination}
                    splitlead={splitlead}
                    selectedLead={selectedLead}
                    selectAll={selectAll}
                    unSelectAll={unSelectAll}
                    toggleSelect={toggleSelect}
                    onClickThisPageOnly={onClickThisPageOnly}
                  />
                  {showError && postData.totalSelected === 0 && (
                    <span className="text-danger">Please select lead.</span>
                  )}
                </div>
                <div className="d-flex mt-3">
                  <button
                    className="btn btn-sm btn-primary d-inline-flex align-items-center ms-auto"
                    onClick={() => onClickNext()}
                  >
                    Next
                    <HiArrowRight className="ms-1" />
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/*  */}
          <div className="col col-sm-3">
            <div className="sms-preview">
              <div className="preview-content">
                <div className="preview-body">
                  <div
                    className="preview-text"
                    dangerouslySetInnerHTML={{
                      __html: postData.form_blast_new_message,
                    }}
                  ></div>
                </div>
              </div>
            </div>
            <div className="sms-preview-title">
              Characters: <span className="preview-characters">0</span> |
              Segments: <span className="preview-segments">0</span>
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      <div className={`p-3${activeForm === 2 ? "" : " d-none"}`}>
        <div className="section-title">
          <a className="text-decoration-none" onClick={() => setActiveForm(1)}>
            <BiArrowBack className="icon me-2" />
          </a>
          Blast Summary
        </div>
        <div className="row mt-3">
          <div className="col-sm-8 mx-auto bg-white p-3">
            <h4 className="">Message</h4>
            <div className="form-textarea">
              <div
                className=""
                dangerouslySetInnerHTML={{
                  __html: postData?.form_blast_new_message,
                }}
              ></div>
              {postData.form_blast_new_media && (
                <div className="">
                  <img
                    src={postData.form_blast_new_media}
                    width={100}
                    height={100}
                    alt=""
                  />
                </div>
              )}
              <div className="d-flex w-100">
                <a
                  className="text-decoration-none text-primary fw-bold"
                  onClick={() => setActiveForm(1)}
                >
                  Edit SMS
                </a>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-sm-6">
                Sending to <span id="blast_new_summay_leadcount">0</span> people
              </div>
              <div className="col-sm-6 d-flex">
                <button
                  className="btn btn-sm btn-light ms-auto bg-gray"
                  onClick={() => setScheduleMessageModal(true)}
                >
                  <BsClock color="primary" />
                </button>
                <button
                  className="btn btn-sm btn-primary ms-1"
                  onClick={() => setConfirmSendModal(true)}
                >
                  Send now
                </button>
              </div>
              <BlastScheduleMessageModal
                issplit={postData.form_blast_issplit}
                leadcount={mainData.blast_new_select_lead_count}
                openModal={scheduleMessageModal}
                closeModal={() => setScheduleMessageModal(false)}
                onScheduleMessage={(params) => scheduleMessage(params)}
              />
              <BlastConfirmSendModal
                openModal={confirmSendModal}
                closeModal={() => setConfirmSendModal(false)}
                onSend={() => sendNow()}
              />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
