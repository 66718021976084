export const constants = {
  states: [
    { name: "AK", value: "AK" },
    { name: "AL", value: "AL" },
    { name: "AS", value: "AS" },
    { name: "AZ", value: "AZ" },
    { name: "AR", value: "AR" },
    { name: "CA", value: "CA" },
    { name: "CO", value: "CO" },
    { name: "CT", value: "CT" },
    { name: "DE", value: "DE" },
    { name: "DC", value: "DC" },
    { name: "FM", value: "FM" },
    { name: "FL", value: "FL" },
    { name: "GA", value: "GA" },
    { name: "GU", value: "GU" },
    { name: "HI", value: "HI" },
    { name: "ID", value: "ID" },
    { name: "IL", value: "IL" },
    { name: "IN", value: "IN" },
    { name: "IA", value: "IA" },
    { name: "KS", value: "KS" },
    { name: "KY", value: "KY" },
    { name: "LA", value: "LA" },
    { name: "ME", value: "ME" },
    { name: "MH", value: "MH" },
    { name: "MD", value: "MD" },
    { name: "MA", value: "MA" },
    { name: "MI", value: "MI" },
    { name: "MN", value: "MN" },
    { name: "MS", value: "MS" },
    { name: "MO", value: "MO" },
    { name: "MT", value: "MT" },
    { name: "NE", value: "NE" },
    { name: "NV", value: "NV" },
    { name: "NH", value: "NH" },
    { name: "NJ", value: "NJ" },
    { name: "NM", value: "NM" },
    { name: "NY", value: "NY" },
    { name: "NC", value: "NC" },
    { name: "ND", value: "ND" },
    { name: "MP", value: "MP" },
    { name: "OH", value: "OH" },
    { name: "OK", value: "OK" },
    { name: "OR", value: "OR" },
    { name: "PW", value: "PW" },
    { name: "PA", value: "PA" },
    { name: "PR", value: "PR" },
    { name: "RI", value: "RI" },
    { name: "SC", value: "SC" },
    { name: "SD", value: "SD" },
    { name: "TN", value: "TN" },
    { name: "TX", value: "TX" },
    { name: "UT", value: "UT" },
    { name: "VT", value: "VT" },
    { name: "VI", value: "VI" },
    { name: "VA", value: "VA" },
    { name: "WA", value: "WA" },
    { name: "WV", value: "WV" },
    { name: "WI", value: "WI" },
    { name: "WY", value: "WY" },
    { name: "DC", value: "DC" },
  ],
  branding: [
    { name: "Agency", value: "0" },
    { name: "Dealership", value: "1" },
  ],
  labelTags: [
    {
      value: "label label-red",
      color: "red",
    },
    {
      value: "label label-blue",
      color: "blue",
    },
    {
      value: "label label-orange",
      color: "orange",
    },
    {
      value: "label label-aqua",
      color: "aqua",
    },
    {
      value: "label label-black",
      color: "black",
    },
  ],
  unassignLeadsTimes: [
    { name: "Never", value: "0" },
    { name: "1 minutes", value: "1" },
    { name: "2 minutes", value: "2" },
    { name: "3 minutes", value: "3" },
    { name: "5 minutes", value: "5" },
    { name: "1 hour", value: "60" },
  ],
  notificationsTimes: [
    { name: "5 minute", value: "5" },
    { name: "10 minute", value: "10" },
    { name: "30 minute", value: "30" },
    { name: "60 minute", value: "60" },
  ],
  defaultMessages: {
    AutomaticConfirmation:
      "Your appointment has been set for you to come into the dealership" +
      " [AppDate] @ [AppTime] with [ApptContactName] at [DealershipName] [DealershipAddress].",
    ScheduledReminder:
      "Appointment Reminder for [AppDate] @ [AppTime] for you to come " +
      "into the dealership. If you need to reschedule just shoot me a text back. Thanks!",
    AutomaticFollowUp:
      "Hi [fname]! Just wanted to follow up on your appointment and see " +
      " how your visit at [dealershipname] went and " +
      " if you were able to get into a newer vehicle?",
  },
  smartList: [
    {
      name: "Lead",
      items: [
        { name: "First Name", value: "[fname]" },
        { name: "Last Name", value: "[lname]" },
        { name: "Full Name", value: "[fullname]" },
        { name: "Lead Source", value: "[leadsource]" },
      ],
    },
    {
      name: "Dealership",
      items: [
        { name: "Name", value: "[dealershipname]" },
        { name: "Address", value: "[address]" },
        { name: "Hours", value: "[hours]" },
        { name: "Contact", value: "[appt_contact]" },
        { name: "Phone", value: "[phonenumber]" },
        { name: "Inventory", value: "[inventory]" },
        { name: "Website", value: "[website]" },
        { name: "Credit Application", value: "[creditapp]" },
      ],
    },
    {
      name: "Vehicle of Interest",
      items: [
        { name: "Year", value: "[interest_year]" },
        { name: "Make", value: "[interest_make]" },
        { name: "Model", value: "[interest_model]" },
      ],
    },
    {
      name: "Current Vehicle",
      items: [
        { name: "Year", value: "[current_year]" },
        { name: "Make", value: "[current_make]" },
        { name: "Model", value: "[current_model]" },
      ],
    },
  ],
  smartListShort: [
    {
      name: "Lead",
      items: [
        { name: "First Name", value: "[fname]" },
        { name: "Last Name", value: "[lname]" },
        { name: "Full Name", value: "[fullname]" },
        { name: "Lead Source", value: "[leadsource]" },
      ],
    },
    {
      name: "Dealership",
      items: [
        { name: "Name", value: "[dealershipname]" },
        { name: "Address", value: "[address]" },
        { name: "Hours", value: "[hours]" },
        { name: "Contact", value: "[appt_contact]" },
        { name: "Phone", value: "[phonenumber]" },
        { name: "Inventory", value: "[inventory]" },
        { name: "Website", value: "[website]" },
        { name: "Credit Application", value: "[creditapp]" },
      ],
    },
    {
      name: "Vehicle of Interest",
      items: [
        { name: "Year", value: "[interest_year]" },
        { name: "Make", value: "[interest_make]" },
        { name: "Model", value: "[interest_model]" },
      ],
    },
    {
      name: "Current Vehicle",
      items: [
        { name: "Year", value: "[current_year]" },
        { name: "Make", value: "[current_make]" },
        { name: "Model", value: "[current_model]" },
      ],
    },
  ],
};

export default constants;
