export const config = {
  // baseUrl: 'https://updash.com/index.php/',
  // SERVER_LINK: 'https://updash.com/index.php/mobileapi',
  // baseUrl:
  //   process.env.NODE_ENV === "development"
  //     ? "http://localhost:8000/index.php"
  //     : "https://updash.com/adam/index.php",
  // SERVER_LINK:
  //   process.env.NODE_ENV === "development"
  //     ? "http://localhost:8000/index.php/reactapi"
  //     : "https://updash.com/adam/index.php/reactapi",
  baseUrl: "https://api.dealerfunnel.com/index.php",
  SERVER_LINK: "https://api.dealerfunnel.com/index.php/reactapi",
  mobileApi: "mobileapi",
  reactApi: "react_api",
  updashtwilioApi: "updashtwilio",
  domain: "https://updash.com",
  assetPath: "https://api.dealerfunnel.com/assets",
  resourcesPath: "https://api.dealerfunnel.com/",
  reportPath: "https://updash.com/index.php/client",
  smsPath: "https://updash.com/index.php/leadsms",
  campaignsPath: "https://updash.com/index.php/campaigns",
  campaign_setting: "https://updash.com/index.php/campaign_setting",
  toolPath: "https://updash.com/index.php/tools",
  apptsReportPath: "https://updash.com/index.php/appointments",
  exportPath: "https://updash.com/index.php/export",
  dealersocket: "https://updash.com/index.php//dealer_socket_add_dealership",
  clientPath: "https://updash.com/index.php/client",
  usersPath: "https://updash.com/index.php/users",
  ajaxPath: "https://updash.com/adam/index.php/ajax", // - dev
  awsConfig: {
    accessKeyId: "AKIAUDKO2KHMCNZEMZW3", // old - "AKIAUDKO2KHMF2PPOKEA",
    secretAccessKey: "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",
    // old - "eIhVSNIdSP8stzmJY8RLsbGf9ozQdqEZfGWiXKWS",
    region: "us-east-1",
  },
  firebaseConfig: {
    apiKey: "AIzaSyA-haV2kSyDmLjEf5zLOzHRWZ27oqvoOjM",
    authDomain: "updash-messenger-e4de8.firebaseapp.com",
    databaseURL: "https://updash-messenger-e4de8.firebaseio.com",
    projectId: "updash-messenger-e4de8",
    storageBucket: "updash-messenger-e4de8.appspot.com",
    messagingSenderId: "171233655246",
    appId: "1:171233655246:web:eaa42947434943e50cd39f",
    measurementId: "G-CBXV2ENDK9",
  },
  googleMapKey: "AIzaSyBnd6gPy1yIkSII2qe5iAbq4XpzrEjsyLU",
  bitlyAccessToken: "15e93ecd3d04ede9a2db50e06b3b8f9c3c99cf83",
  urls: {},
};

export default config;
