import React, { useState, useContext, useEffect } from "react";
import { AuthContext, ClientContext } from "../../../context";
import TablePagination from "../../TablePagination/TablePagination";
import { ButtonGroup } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import config from "../../../config/config";
import UserRemoveModal from "../../modals/user_remove_modal";

export default function ClientUsersDataTable({ client }) {
  const { user } = useContext(AuthContext);
  const { getUsersByClientIdApi } = useContext(ClientContext);
  const [searchKey, setSearchKey] = useState("");
  const [filterAction, setFilterAction] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const perPage = 25;

  const [users, setUsers] = useState([]);
  const [isExecutive, setIsExecutive] = useState(undefined);
  const [removeUserModal, setRemoveUserModal] = useState("");

  useEffect(() => {
    if (client) {
      getUsersByClientId({ userId: user.Id, cid: client.Id });
      setCurrentPage(1);
    }
  }, [client]);

  const getUsersByClientId = async (params) => {
    const data = await getUsersByClientIdApi(params);
    if (data.status === "ok") {
      const res = data.response;
      setUsers(res?.user);
      setIsExecutive(res?.isExecutive);
    }
  };

  const removeUser = (cmpId) => {
    setRemoveUserModal(cmpId);
  };

  const datatable = (data) => {
    return data.length > 0
      ? data.filter(
          (el) =>
            el.Name?.toLowerCase().includes(searchKey?.toLowerCase()) &&
            (filterAction === 0 || Number(el.Type) === filterAction),
        )
      : [];
  };

  return (
    <div className="card overflow-visible">
      <div className="card-header">
        <div className="d-flex">
          <input
            type="text"
            className="form-control form-control-fav"
            placeholder="Search"
            onChange={(e) => {
              setSearchKey(e.target.value);
              setCurrentPage(1);
            }}
          />
          <div className="ms-auto">
            <DropdownButton
              as={ButtonGroup}
              align={{ lg: "end" }}
              variant="default"
              title={
                filterAction === 1
                  ? `Admin`
                  : filterAction === 2
                  ? `Manager`
                  : filterAction === 5
                  ? `Agency Admin`
                  : filterAction === 3
                  ? `Salesman`
                  : `All Users`
              }
            >
              <Dropdown.Item eventKey="1" onClick={() => setFilterAction(0)}>
                All Users
              </Dropdown.Item>
              <Dropdown.Item eventKey="2" onClick={() => setFilterAction(1)}>
                Admin
              </Dropdown.Item>
              <Dropdown.Item eventKey="3" onClick={() => setFilterAction(2)}>
                Manager
              </Dropdown.Item>
              <Dropdown.Item eventKey="4" onClick={() => setFilterAction(5)}>
                Agency Admin
              </Dropdown.Item>
              <Dropdown.Item eventKey="5" onClick={() => setFilterAction(3)}>
                Salesman
              </Dropdown.Item>
            </DropdownButton>
          </div>
        </div>
      </div>
      <div className="card-body p-0">
        <table className="table table-hover m-0">
          <thead>
            <tr>
              <th>Picture</th>
              <th>Name</th>
              <th>Email</th>
              <th>Type</th>
              <th>Pin</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {users &&
              datatable(users).map((item, index) =>
                index >= (currentPage - 1) * perPage &&
                index < currentPage * perPage ? (
                  <tr key={index}>
                    <td>
                      <img
                        width={24}
                        src={`${config.baseUrl}/resource/profilepicture/${item?.Image}`}
                        alt=""
                      />
                    </td>
                    <td className="text-primary">{item?.Name}</td>
                    <td>{item?.Email}</td>
                    <td>
                      {Number(item?.Type === 1) && Number(item?.Etype) === 1
                        ? "Account Executive"
                        : Number(item?.Type === 1)
                        ? "Admin"
                        : Number(item?.Type === 2)
                        ? "Manager"
                        : Number(item?.Type === 3)
                        ? "Salesman"
                        : Number(item?.Type === 5)
                        ? "Agency Admin"
                        : null}
                    </td>
                    <td>{item?.Pin}</td>
                    <td>
                      <DropdownButton
                        as={ButtonGroup}
                        align={{ lg: "end" }}
                        variant="default"
                        title={`Actions`}
                      >
                        <Dropdown.Item
                          eventKey="1"
                          href={`${
                            !isExecutive
                              ? `${config.usersPath}/edituser`
                              : "/users/edituser"
                          }/${item.Key}`}
                        >
                          Edit User
                        </Dropdown.Item>
                        {!isExecutive && (
                          <Dropdown.Item
                            eventKey="2"
                            onClick={() => removeUser(item.Id)}
                          >
                            Delete User
                          </Dropdown.Item>
                        )}
                        {Number(item?.Type) > 1 && (
                          <Dropdown.Item
                            eventKey="3"
                            href={`${config.usersPath}/userview/${item.Id}`}
                          >
                            User View
                          </Dropdown.Item>
                        )}
                      </DropdownButton>
                    </td>
                  </tr>
                ) : null,
              )}
          </tbody>
        </table>
      </div>
      <div className="card-footer py-4 bg-white">
        <TablePagination
          data={datatable(users)}
          perPage={perPage}
          page={currentPage}
          onChangePage={setCurrentPage}
        />
      </div>
      {/*  */}
      <UserRemoveModal
        cmpid={removeUserModal}
        isOpenModal={!!removeUserModal}
        afterOpenModal={() => {}}
        closeModal={() => setRemoveUserModal("")}
        reloadData={() => {
          setRemoveUserModal("");
          getUsersByClientId({ userId: user.Id, cid: client.Id });
        }}
      />
    </div>
  );
}
