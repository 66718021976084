import React from "react";
import Header from "./header";
import Footer from "./footer";
import { useLocation } from "react-router-dom";
import { Box } from "@mui/material";

// eslint-disable-next-line react/prop-types
export default function MainLayout({ children }) {
  const location = useLocation();
  return (
    <Box>
      {location.pathname.includes("/auth") ? null : <Header />}
      {children}
      {location.pathname.includes("/auth") ||
      location.pathname.includes("/conversations") ? null : (
        <Footer />
      )}
    </Box>
  );
}
