import Modal from "react-modal";
import React, { useContext, useState, useEffect } from "react";
import { AuthContext, BlastContext } from "../../context";
import ScheduleTimeSelect from "../select/schedule_time_select";
import { BsExclamationTriangleFill } from "react-icons/bs";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "560px",
    padding: "15px",
    marginTop: "20px",
    marginBottom: "20px",
  },
};

Modal.setAppElement("#root");

export default function BlastReScheduleMessageModal({
  summaryData = null,
  openModal = "",
  closeModal,
  reloadData,
}) {
  const { user } = useContext(AuthContext);
  const { updateScheduleDateApi } = useContext(BlastContext);
  const [postData, setPostData] = useState({
    userId: user.Id,
    blast_id: "",
    schedule_date: "",
    schedule_time_obj: { value: "", label: "- : -" },
    schedule_time: "",
  });

  useEffect(() => {
    if (summaryData) {
      let _postData = {};
      _postData.userId = user.Id;
      _postData.blast_id = summaryData?.info?.Id;
      if (summaryData?.info.Scheduledate) {
        _postData.schedule_date = summaryData?.info?.mkscheduledate_f;
      }
      setPostData(_postData);
    }
  }, [summaryData]);

  const onSubmit = async () => {
    const data = await updateScheduleDateApi(postData);
    if (data.status === "ok") {
      reloadData();
    }
  };

  const afterOpenModal = () => {};

  return (
    <Modal
      isOpen={!!openModal}
      onAfterOpen={() => afterOpenModal()}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Edit Modal"
    >
      <div className="modal-header">
        <h4>Re-Schedule Message</h4>
      </div>
      <div className="modal-body">
        <form>
          <div className="row mt-3">
            <div className="col-12">
              <div className="">
                <BsExclamationTriangleFill className="me-1" color="#ff9800" />
                Your message will be sent according to US/Eastern Time Zone.
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-6">
              <div className="form-group mt-3">
                <input
                  type="date"
                  className="form-control"
                  value={postData.schedule_date}
                  onChange={(e) =>
                    setPostData({ ...postData, schedule_date: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group mt-3">
                <ScheduleTimeSelect
                  value={postData?.schedule_time_obj}
                  onChange={(e) => {
                    setPostData({
                      ...postData,
                      schedule_time_obj: e,
                      schedule_time: e.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="modal-footer mt-4">
        <button className="btn btn-outline-secondary" onClick={closeModal}>
          Cancel
        </button>
        <button className="btn btn-primary ms-2" onClick={() => onSubmit()}>
          Re-Schedule Message
        </button>
      </div>
    </Modal>
  );
}
