import React, { useContext, useEffect, useRef, useState } from "react";
import { SlUser } from "react-icons/sl";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext, UserContext } from "../../context";
import { BsPlus } from "react-icons/bs";
import {
  RxChevronDown,
  RxChevronLeft,
  RxChevronRight,
  RxChevronUp,
  RxDoubleArrowDown,
  RxDoubleArrowLeft,
  RxDoubleArrowRight,
  RxDoubleArrowUp,
} from "react-icons/rx";
import DualListBox from "react-dual-listbox";

export default function UserGroupNewPage() {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const { getNewUserGroupPageDataApi, ajaxusergroupApi, createUserGroupApi } =
    useContext(UserContext);
  const formRef = useRef(undefined);
  //
  const [postData, setPostData] = useState({
    userId: user.Id,
    Name: "",
    private: "",
  });
  //
  const [userType, setUserType] = useState("");
  const [isagentchain, setIsagentchain] = useState("");
  const [agency, setAgency] = useState([]);
  //
  const [userList, setUserList] = useState([]);
  const [clientList, setClientList] = useState([]);
  //
  const [userlistbox, setUserlistbox] = useState([]);
  const [clientlistbox, setClientlistbox] = useState([]);

  useEffect(() => {
    getNewUserGroupPageData();
  }, []);

  const getNewUserGroupPageData = async () => {
    const data = await getNewUserGroupPageDataApi({ userId: user.Id });
    if (data.status === "ok") {
      const res = data.response;
      setUserType(res?.userType);
      setIsagentchain(res?.isagentchain);
      setAgency(res?.agency);
      //
      let _postData = { ...postData };
      _postData.userId = user.Id;
      _postData.private = res?.sagent;
      if (Number(res?.isagentchain) === 1 || Number(res?.userType) === 2) {
        _postData.private1 = res?.sagent;
      }
      setPostData(_postData);
      //
      if (Number(res?.userType) === 5 || Number(res?.userType) === 8) {
        selectagent(res?.sagent);
      }
    }
  };

  const selectagent = async (agentid) => {
    let params = {
      userId: user.Id,
      agentid: agentid,
      isedit: 0,
    };
    const data = await ajaxusergroupApi(params);
    if (data.status === "ok") {
      const res = data.response;
      setUserList(res?.user);
      setClientList(res?.client);
      let _userlistbox = res?.user
        .filter((item) => !!item.Sel)
        .map((item) => {
          return { label: item.Name, value: item.Id };
        });
      setUserlistbox(_userlistbox);
      let _clientlistbox = res?.client
        .filter((item) => !!item.Sel)
        .map((item) => {
          return { label: item.Name, value: item.Id };
        });
      setClientlistbox(_clientlistbox);
    }
  };

  const onSave = async () => {
    if (!formRef.current.reportValidity()) {
      return;
    }
    let params = { ...postData };
    params.userlistbox = userlistbox.map((item) => item.value);
    params.clientlistbox = clientlistbox.map((item) => item.value);
    const data = await createUserGroupApi(params);
    if (data.status === "ok") {
      navigate(`/users`);
    }
  };

  return (
    <main className="container py-4 position-relative">
      <div className="d-flex align-items-center">
        <div className="section-title">
          <SlUser className="icon me-2" />
          Create User Group
        </div>
        <div className="ms-auto">
          <Link
            to="/users/newuser"
            className="btn btn-primary d-inline-flex align-items-center"
          >
            <BsPlus />
            New User
          </Link>
          <Link
            to="/users/newgroup"
            className="btn btn-secondary d-inline-flex align-items-center ms-2"
          >
            <BsPlus />
            New User Group
          </Link>
        </div>
      </div>
      <div className="card w-100 mt-4">
        <div className="card-body">
          <div className="row">
            <div className="col col-sm-2 pe-0 border-end border-2">
              <ul className="nav nav-tabs-vertical">
                <li className={`nav-item active`}>User Group Info</li>
              </ul>
            </div>
            <div className="col col-sm-10">
              <div className="tab-content">
                <div className="tab-pane active">
                  <form ref={formRef}>
                    <div className="d-flex align-items-center justify-content-between">
                      <h6 className="tab-tile">User Info</h6>
                    </div>
                    <div className="row mt-2">
                      <div className="col col-sm-4">
                        <div className="form-group mb-2">
                          <label className="mb-2">Name</label>
                          <input
                            type="text"
                            className="form-control"
                            name="Name"
                            value={postData?.Name}
                            onChange={(e) =>
                              setPostData({ ...postData, Name: e.target.value })
                            }
                            required={true}
                          />
                        </div>
                      </div>
                      <div
                        id="agency_assign_container"
                        className={`col-sm-4${
                          Number(userType) === 5 || Number(userType) === 8
                            ? " d-none"
                            : ""
                        }`}
                      >
                        <div className="form-group mb-2">
                          <label className="mb-2">Agency Assignment</label>
                          {Number(isagentchain) === 1 ||
                          Number(userType) === 2 ? (
                            <select
                              className="form-control"
                              name="private1"
                              value={postData?.private1}
                              onChange={(e) =>
                                setPostData({
                                  ...postData,
                                  private1: e.target.value,
                                })
                              }
                              required={
                                !(
                                  Number(userType) === 5 ||
                                  Number(userType) === 8
                                )
                              }
                              disabled={true}
                            >
                              <option value="">N/A</option>
                              {agency.map((item, index) => (
                                <option value={item.Id} key={index}>
                                  {item.Name}
                                </option>
                              ))}
                            </select>
                          ) : (
                            <select
                              className="form-control"
                              name="private"
                              value={postData?.private}
                              onChange={(e) => {
                                setPostData({
                                  ...postData,
                                  private: e.target.value,
                                });
                                selectagent(e.target.value);
                              }}
                              required={
                                !(
                                  Number(userType) === 5 ||
                                  Number(userType) === 8
                                )
                              }
                            >
                              <option value="">N/A</option>
                              {agency.map((item, index) => (
                                <option value={item.Id} key={index}>
                                  {item.Name}
                                </option>
                              ))}
                            </select>
                          )}
                        </div>
                      </div>
                    </div>
                    <hr className="mt-4" />
                    <div className="user-list mt-3" id="user-list">
                      <DualListBox
                        canFilter
                        simpleValue={false}
                        options={
                          userList.map((item) => {
                            return { label: item.Name, value: item.Id };
                          }) || []
                        }
                        selected={userlistbox}
                        onChange={(value) => setUserlistbox(value)}
                        icons={{
                          moveLeft: <RxChevronLeft />,
                          moveAllLeft: [
                            <RxDoubleArrowLeft key={0} />,
                            // <RxDoubleArrowLeft key={1} />,
                          ],
                          moveRight: <RxChevronRight />,
                          moveAllRight: [
                            <RxDoubleArrowRight key={0} />,
                            // <RxDoubleArrowRight key={1} />,
                          ],
                          moveDown: <RxChevronDown />,
                          moveUp: <RxChevronUp />,
                          moveTop: <RxDoubleArrowUp />,
                          moveBottom: <RxDoubleArrowDown />,
                        }}
                      />
                    </div>
                    <hr className="mt-4" />
                    <div className="user-list mt-3" id="client-list">
                      <DualListBox
                        canFilter
                        simpleValue={false}
                        options={
                          clientList.map((item) => {
                            return { label: item.Name, value: item.Id };
                          }) || []
                        }
                        selected={clientlistbox}
                        onChange={(value) => setClientlistbox(value)}
                        icons={{
                          moveLeft: <RxChevronLeft />,
                          moveAllLeft: [
                            <RxDoubleArrowLeft key={0} />,
                            // <RxDoubleArrowLeft key={1} />,
                          ],
                          moveRight: <RxChevronRight />,
                          moveAllRight: [
                            <RxDoubleArrowRight key={0} />,
                            // <RxDoubleArrowRight key={1} />,
                          ],
                          moveDown: <RxChevronDown />,
                          moveUp: <RxChevronUp />,
                          moveTop: <RxDoubleArrowUp />,
                          moveBottom: <RxDoubleArrowDown />,
                        }}
                      />
                    </div>
                    <hr className="mt-5" />
                    <div className="d-flex align-items-center justify-content-center">
                      <button
                        type="button"
                        className="btn btn-primary me-1"
                        onClick={() => onSave()}
                      >
                        Save
                      </button>
                      <button
                        type="button"
                        className="btn btn-outline-secondary ms-1"
                      >
                        Close
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
