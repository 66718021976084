import React, { useContext, useState } from "react";
import Modal from "react-modal";
import { AuthContext } from "../../context";
import ScheduleTimeSelect from "../select/schedule_time_select";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "560px",
    padding: "15px",
    marginTop: "20px",
    marginBottom: "20px",
    overflow: "visible",
  },
};

Modal.setAppElement("#root");

export default function FunnelCallRequiredModal({
  isOpenModal = false,
  afterOpenModal,
  closeModal,
  onSubmit,
}) {
  const { user } = useContext(AuthContext);
  const [postData, setPostData] = useState({
    userId: user.Id,
    Name: "",
    Message: "",
    Delaycategory: "",
    DelaycategoryObj: { value: "", label: "- : -" },
    Delaycount: "",
    Messagetype: 4,
  });
  const [showError, setShowError] = useState(false);

  const onSave = () => {
    if (!postData.Delaycategory || !postData.Delaycount) {
      setShowError(true);
      return;
    }
    onSubmit(postData);
  };

  return (
    <Modal
      isOpen={isOpenModal}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Edit Modal"
    >
      <div className="modal-header">
        <h4 className="d-flex align-items-center">Edit Status</h4>
      </div>
      <div className="modal-body">
        <div className="row mt-4">
          <div className="col-12">
            <label className="mb-2">Delay in Days</label>
          </div>
          <div className="col-6">
            <div className="form-group mb-2">
              <input
                type="number"
                className="form-control"
                value={postData.Delaycount}
                onChange={(e) =>
                  setPostData({ ...postData, Delaycount: e.target.value })
                }
              />
            </div>
          </div>
          <div className="col-6">
            <div className="form-group mb-2">
              <ScheduleTimeSelect
                value={postData?.DelaycategoryObj}
                onChange={(e) => {
                  setPostData({
                    ...postData,
                    DelaycategoryObj: e,
                    Delaycategory: e.value,
                  });
                }}
              />
            </div>
          </div>
          {showError && (!postData.Delaycount || !postData.Delaycategory) && (
            <div className="col-12">
              <span className="text-danger">Please add delay.</span>
            </div>
          )}
        </div>
      </div>
      <div className="modal-footer mt-3">
        <button className="btn btn-outline-secondary me-2" onClick={closeModal}>
          Cancel
        </button>
        <button className="btn btn-primary" onClick={() => onSave()}>
          Save
        </button>
      </div>
    </Modal>
  );
}
