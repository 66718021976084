import React, { useContext, useState } from "react";
import { AuthContext, ReportContext } from "../../context";
import { useEffect } from "react";
import moment from "moment";
import { BsPeople } from "react-icons/bs";
import {
  ReportViewSubmissionModal,
  ActionsOnBoardingModal,
} from "../../components";

export default function OnboardingReportPage() {
  const { user } = useContext(AuthContext);
  const { getOnboardingDataApi, viewsubmissionModalApi, sendonboardingApi } =
    useContext(ReportContext);
  //
  const [customColor, setCustomColor] = useState();
  //
  const [tableData, setTableData] = useState(undefined);
  //
  const [viewSubmissionModal, setViewSubmissionModal] = useState(null);
  const [openOnboardingModal, setOpenOnboardingModal] = useState(false);

  useEffect(() => {
    getOnboardingData();
    console.log(customColor);
  }, []);

  const getOnboardingData = async () => {
    const data = await getOnboardingDataApi({ userId: user.Id });
    if (data?.status === "ok") {
      const res = data.response;
      setCustomColor(res?.custom_color);
      setTableData(res?.info);
    }
  };

  const viewsubmission = async (id) => {
    const data = await viewsubmissionModalApi({ userId: user.Id, id: id });
    if (data.status === "ok") {
      const res = data.response;
      setViewSubmissionModal(res);
    }
  };

  const submitOnboarding = async (email) => {
    if (!email) {
      return;
    }
    const data = await sendonboardingApi({ userId: user.Id, email: email });
    if (data.status === "ok") {
      setOpenOnboardingModal(false);
      getOnboardingData();
    }
  };

  return (
    <main className="container py-4">
      <div className="d-flex align-items-center justify-content-between">
        <div className="section-title">
          <BsPeople className="icon me-2" />
          Onboarding
        </div>
        <button
          className="btn btn-sm btn-primary"
          onClick={() => setOpenOnboardingModal(true)}
        >
          + Onboarding
        </button>
      </div>
      <div id="dashboard-tbl-data" className="card mt-3">
        <table id="messages_report" className="table table-hover">
          <thead>
            <tr>
              <th>Timestamp</th>
              <th>Email</th>
              <th>Status</th>
              <th>User</th>
              <th>Agency</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {!!tableData &&
              tableData.map((item, index) => (
                <tr key={index}>
                  <td>
                    <abbr className="timeago" title={item?.submission_date}>
                      {moment(item?.submission_date).fromNow()}
                    </abbr>
                  </td>
                  <td>{item?.email}</td>
                  {item.hour > 167 ? (
                    <td>Expired</td>
                  ) : Number(item.flag) === 0 ? (
                    <td>Unopened - Expired in {167 - Number(item?.hour)}h</td>
                  ) : Number(item.flag) < 4 ? (
                    <td>Opened - Expired in {167 - Number(item?.hour)}h</td>
                  ) : Number(item.flag) === 4 ? (
                    <td>Submitted</td>
                  ) : null}
                  <td>{item.uname}</td>
                  <td>Dealer Funnel</td>
                  {Number(item.flag) === 4 ? (
                    <td>
                      <a
                        className="btn btn-sm btn-outline-secondary"
                        onClick={() => viewsubmission(item.Id ? item.Id : 0)}
                      >
                        View Submission
                      </a>
                    </td>
                  ) : (
                    <td>
                      <a className="btn btn-sm btn-outline-secondary">
                        View Submission
                      </a>
                    </td>
                  )}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <ReportViewSubmissionModal
        data={viewSubmissionModal}
        isOpenModal={!!viewSubmissionModal}
        closeModal={() => setViewSubmissionModal(null)}
      />
      <ActionsOnBoardingModal
        open={openOnboardingModal}
        handleCloseModal={() => setOpenOnboardingModal(false)}
        handleSubmit={submitOnboarding}
      />
    </main>
  );
}
