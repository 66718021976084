import React, { useContext } from "react";
import helper from "../../utils/helper";
import { AuthContext, ConversationContext } from "../../context";
import { useState } from "react";
import { toast } from "react-toastify";
import { BsRobot } from "react-icons/bs";
import { BiMessageRoundedDetail } from "react-icons/bi";
import EmojiPickerInput from "../ChatInputElements/EmojiPickerInput";
import ContentEditable from "react-contenteditable";

export default function FacebookComment({
  comments,
  sms,
  lead,
  usermention,
  leadname,
}) {
  console.log("✅ comments...", comments);
  const { user } = useContext(AuthContext);
  const { sendfbreplyApi } = useContext(ConversationContext);
  const [body, setBody] = useState("");

  const fb_reply = async (comment_id) => {
    // removeliveactivity();
    let params = {
      userId: user.Id,
      lead: lead,
      comment_id: comment_id,
      body: body,
      path: "",
      giphy: "",
      mention: usermention,
      leadname: leadname,
    };
    if (
      params.body.length === 0 &&
      params.path.length === 0 &&
      params.giphy.length === 0
    ) {
      alert("FB input box is empty");
    } else {
      const data = await sendfbreplyApi(params);
      if (data.status === "ok") {
        setBody("");
        toast.info("FB Message sent");
      }
    }
  };

  return (
    <div id="facebook-comment" className="message facebook-comment">
      {comments?.length > 0 &&
        sms?.length > 0 &&
        comments.map((comment, index) =>
          Number(comment.Messagetype) === 32 ? (
            <>
              {comment.reply_to === "" ? (
                <span
                  className={`pimage${
                    sms[index].AuthorId === 0 && comment.pimage === ""
                      ? " message-bot"
                      : ""
                  }`}
                >
                  {sms[index].AuthorId === 0 && comment.pimage === "" ? (
                    <BsRobot />
                  ) : (
                    <img src={comment.pimage} alt="" />
                  )}
                </span>
              ) : null}
              <div className="message-ct">
                {comment.reply_to === "" ? (
                  <div className="message-wrap">
                    <div className="message-wrap">
                      <div className="sender-meta clearfix">
                        <a href="#" title="" className="sender-name">
                          {comment.aname}
                        </a>
                        <div className="ms-auto">
                          <abbr className="timeago" title={comment.Stime}>
                            {comment.Stime}
                          </abbr>
                          <BiMessageRoundedDetail />
                        </div>
                      </div>
                      <div className="sender-text">
                        <div className="schedule-message">
                          {comment.hidden == 1 ? (
                            <p
                              className="text-decoration-line-through"
                              dangerouslySetInnerHTML={{
                                __html: helper.nl2br(comment.Body),
                              }}
                            ></p>
                          ) : comment.hidden == 2 ? (
                            <p
                              className="text-decoration-line-through text-danger"
                              dangerouslySetInnerHTML={{
                                __html: helper.nl2br(comment.Body),
                              }}
                            ></p>
                          ) : (
                            <p
                              dangerouslySetInnerHTML={{
                                __html: helper.nl2br(comment.Body),
                              }}
                            ></p>
                          )}
                        </div>
                      </div>
                      {comment.hidden != 2 ? (
                        <div
                          id={comment.comment_id}
                          className="facebook_actions clearfix"
                        >
                          <span id="like_count" className="like_count">
                            {comment.likes} Like{comment.likes > 1 ? "s" : ""}
                          </span>
                          <button
                            id={
                              comment.liked == 0
                                ? "facebook_like"
                                : "facebook_unlike"
                            }
                            className="facebook_action"
                          >
                            {comment.liked == 0 ? "Like" : "Unlike"}
                          </button>
                          <button
                            id="facebook_reply"
                            className="facebook_action"
                          >
                            Reply
                          </button>
                          {comment.hidden == 0 ? (
                            <button
                              id={
                                comment.hidden == 0
                                  ? "facebook_hide"
                                  : "facebook_unhide"
                              }
                              className="facebook_action"
                            >
                              {comment.hidden == 0 ? "Delete" : ""}
                            </button>
                          ) : null}
                        </div>
                      ) : null}
                    </div>
                  </div>
                ) : (
                  <div className="message facebook-comment clearfix">
                    <span
                      className={`pimage${
                        sms[index].AuthorId == 0 ? " message-bot" : ""
                      }`}
                    >
                      {sms[index].AuthorId == 0 ? (
                        <BsRobot />
                      ) : (
                        <img src={comment.pimage} alt="" />
                      )}
                    </span>
                    <div className="message-ct">
                      <div className="sender-meta clearfix">
                        <a href="#" title="" className="sender-name">
                          {comment.aname}
                        </a>
                        <div className="ms-auto">
                          <abbr className="timeago" title={comment.Stime}>
                            {comment.Stime}
                          </abbr>
                          <BiMessageRoundedDetail />
                        </div>
                      </div>
                      <div className="sender-text">
                        <div className="schedule-message">
                          {comment.hidden == 1 ? (
                            <p className="text-decoration-line-through"></p>
                          ) : comment.hidden == 2 ? (
                            <p
                              className="text-decoration-line-through text-danger"
                              dangerouslySetInnerHTML={{
                                __html: helper.nl2br(comment.Body),
                              }}
                            ></p>
                          ) : (
                            <p
                              dangerouslySetInnerHTML={{
                                __html: helper.nl2br(comment.Body),
                              }}
                            ></p>
                          )}
                        </div>
                      </div>
                      {comment.hidden != 2 ? (
                        <div
                          id={comment.comment_id}
                          className="facebook_actions clearfix"
                        >
                          <span id="like_count_sub" className="like_count">
                            {comment.likes} Like{comment.likes > 1 ? "s" : ""}
                          </span>
                          <button
                            id={
                              comment.liked == 0
                                ? "facebook_like"
                                : "facebook_unlike"
                            }
                            className="facebook_action"
                          >
                            {comment.liked == 0 ? "Like" : "Unlike"}
                          </button>
                          {comment.hidden == 0 ? (
                            <button
                              id={
                                comment.hidden == 0
                                  ? "facebook_hide"
                                  : "facebook_unhide"
                              }
                              className="facebook_action"
                            >
                              {comment.hidden == 0 ? "Delete" : ""}
                            </button>
                          ) : null}
                        </div>
                      ) : null}
                    </div>
                  </div>
                )}
                <div className="reply d-flex" id="reply">
                  <span
                    className={`pimage${
                      sms[index].AuthorId == 0 ? " message-bot" : ""
                    }`}
                  >
                    {sms[index].AuthorId == 0 ? (
                      <BsRobot />
                    ) : (
                      <img src={comment.pimage} alt="" />
                    )}
                  </span>
                  <div className="w-100 ps-5">
                    <ContentEditable
                      className="facebook_reply_input"
                      tagName="div"
                      html={body}
                      onChange={(e) => setBody(e.target.value)}
                    />
                    <div className="d-flex align-items-center justify-content-between w-100">
                      <EmojiPickerInput
                      // onSelect={(emoji) => setFbMessageInput(current => current + emoji)}
                      />
                      <div className="ms-auto">
                        <button
                          id="facebook_send"
                          className="facebook_send"
                          onClick={() => fb_reply(comment.comment_id)}
                        >
                          Send
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : null,
        )}
    </div>
  );
}
