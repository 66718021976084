import React, { useContext, useEffect, useState } from "react";
import { Box, Button, LinearProgress, Skeleton, Stack } from "@mui/material";
import { AuthContext, SettingContext } from "../../../context";
import { toast } from "react-toastify";
import { PrimaryInput, StandardImage } from "../../../components";
import { Config } from "../../../config";

export default function UserTwilio() {
  const { getTwilio, updateTwilio } = useContext(SettingContext);
  const { user } = useContext(AuthContext);
  const [info, setInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [pageData, setPageData] = useState({
    Sid: "",
    Token: "",
    Agent: "",
    userId: user?.Id,
  });
  const [tryCount, setTryCount] = useState(0);

  useEffect(() => {
    if (user && user.Id) {
      getInfo();
    }
  }, [user]);

  const getInfo = async () => {
    setLoading(true);
    const result = await getTwilio();
    setInfo(result);
    setPageData({
      Sid: result?.info?.Sid,
      Token: result?.info?.Token,
      Agent: result?.info?.Agent,
      userId: user?.Id,
    });
    console.log(info);
    setLoading(false);
  };

  const submit = async () => {
    setTryCount(tryCount + 1);
    if (!pageData.Sid || !pageData.Token) {
      toast.warn("Required the SID and Token fields");
      return false;
    }
    setConfirmLoading(true);
    await updateTwilio(pageData);
    setConfirmLoading(false);
    toast.success("Successfully twilio updated!");
  };

  const skeleton = () => {
    return (
      <Stack direction={"column"} spacing={2} sx={{ width: "100%" }}>
        {[...Array(2)].map((item, index) => {
          return (
            <Skeleton
              data={item}
              key={index}
              variant="rounded"
              width={"80%"}
              height={50}
            />
          );
        })}
      </Stack>
    );
  };

  const renderMain = () => {
    return (
      <Box sx={{ width: "100%" }}>
        <Stack direction={"column"} spacing={2} sx={{ width: "100%" }}>
          <PrimaryInput
            type={"text"}
            value={pageData.Sid}
            label={"Account SID"}
            handleChange={(e) => {
              setPageData({
                ...pageData,
                Sid: e.target.value,
              });
            }}
            error={tryCount > 0}
            sx={{
              width: 300,
            }}
          />
          <PrimaryInput
            type={"text"}
            value={pageData.Token}
            label={"Auth Token"}
            handleChange={(e) => {
              setPageData({
                ...pageData,
                Token: e.target.value,
              });
            }}
            error={tryCount > 0}
            sx={{
              width: 300,
            }}
          />
        </Stack>
        {confirmLoading && (
          <Box
            sx={{
              mt: 2,
            }}
          >
            <LinearProgress />
          </Box>
        )}
        <Stack
          direction={"row"}
          spacing={3}
          alignItems={"center"}
          justifyContent={"center"}
          mt={3}
        >
          <Button
            variant="contained"
            onClick={submit}
            disabled={confirmLoading}
          >
            Save
          </Button>
          <Button variant="outlined" color="inherit" disabled={confirmLoading}>
            Cancel
          </Button>
        </Stack>
      </Box>
    );
  };

  return (
    <Box mt={2}>
      <Stack direction={"row"} alignItems={"start"} spacing={3}>
        <StandardImage
          width={130}
          src={`${Config.assetPath}/images/twilio-mark-red.svg`}
          sx={{ marginTop: 20 }}
        />
        {loading ? skeleton() : renderMain()}
      </Stack>
    </Box>
  );
}
