import React, { useContext, useState } from "react";
import Select from "react-dropdown-select";
import { SharedContext } from "../../context";
import { Box, Checkbox, ListItemText, MenuItem } from "@mui/material";

export default function DealerShipMultiSelect({ onChange }) {
  const [values, setValues] = useState([]);
  const { dealerships } = useContext(SharedContext);

  const handleChange = (_values) => {
    setValues(_values);
  };

  const onDropdownClose = () => {
    const stringValues = values.map((item) => item.Id).toString();
    onChange(stringValues);
  };

  const dropdownRenderer = ({ props, methods }) => {
    return (
      <Box>
        <Box>
          {methods.areAllSelected() ? (
            <MenuItem key={"all-controller"} onClick={methods.clearAll}>
              <ListItemText primary={"unselect all"} />
            </MenuItem>
          ) : (
            <MenuItem key={"all-controller"} onClick={methods.selectAll}>
              <ListItemText primary={"select all"} />
            </MenuItem>
          )}
        </Box>
        <Box>
          {props.options.map((option) => (
            <MenuItem
              key={option[props.valueField]}
              onClick={option.disabled ? null : () => methods.addItem(option)}
              sx={{
                p: 0,
              }}
            >
              <Checkbox checked={values.indexOf(option) !== -1} />
              <ListItemText primary={option[props.labelField]} />
            </MenuItem>
          ))}
        </Box>
      </Box>
    );
  };

  const contentRenderer = ({ props, state }) => {
    return (
      <div>
        {state.values.length > 1
          ? `${state.values.length} of ${props.options.length} Selected`
          : state.values.length === 1
          ? `${state.values[0][props.labelField]}`
          : "All Dealerships"}
      </div>
    );
  };

  return (
    <Box
      sx={{
        width: 300,
      }}
    >
      {dealerships && (
        <Select
          placeholder="All Dealerships"
          options={dealerships}
          labelField="Name"
          valueField="Id"
          multi={true}
          dropdownRenderer={dropdownRenderer}
          contentRenderer={contentRenderer}
          onChange={(values) => handleChange(values)}
          onDropdownClose={() => onDropdownClose()}
        />
      )}
    </Box>
  );
}
