/* eslint-disable max-len */
import Modal from "react-modal";
import React, { useContext, useState, useEffect } from "react";
import { AuthContext, ConversationContext } from "../../context";
import { BiEdit } from "react-icons/bi";
import { BsCheckCircle } from "react-icons/bs";
import { Switch } from "@mui/material";
import ScheduleTimeSelect from "../select/schedule_time_select";
import moment from "moment";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "560px",
    padding: "15px",
    marginTop: "20px",
    marginBottom: "20px",
  },
};

Modal.setAppElement("#root");

export default function ConversationSmsEditApptsModal({
  data,
  isOpenModal = false,
  afterOpenModal,
  closeModal,
}) {
  const { user } = useContext(AuthContext);
  const {
    editApptsApi,
    sendfbafterapprescheduleApi,
    sendsmsafterapprescheduleApi,
  } = useContext(ConversationContext);

  const [smsaptflag, setSmsaptflag] = useState(0);
  const [automaticfollowup, setAutomaticfollowup] = useState(0);

  const [mainData, setMainData] = useState(null);
  const [smsData, setSmsData] = useState(null);

  useEffect(() => {
    if (data) {
      setMainData({
        userId: user.Id,
        apprid: data?.responseId,
        apptsId: data?.Id,
        appleadId: data?.leadid,
        oldstatus: data?.appstatus,
        last_messagetype: data?.last_messagetype,
        isreminder: 0,
        // automaticfollowup: 0,
        // smsaptflag: 0,
        istrigger: 0,
      });
      //
      setSmsData({
        userId: user.Id,
        Sdate: data?.appdate_f,
        Stime: data?.appdate_t ? data?.appdate_t : "",
        StimeObj: data?.appdate_t
          ? { value: data?.appdate_t, label: data?.appdate_t }
          : { value: "", label: "- : -" },
        Status: data?.appstatus,
        sms:
          Number(data?.appstatus) === 5
            ? "Your appointment has been cancelled. Thank you for letting us know! If you'd like to reschedule, please let me know."
            : Number(data?.appstatus) === 4
            ? data?.agentmessage.Reschedulemessage
              ? data?.agentmessage.Reschedulemessage
              : "Your appointment has been rescheduled to [AppDate] @ [AppTime]. We look forward to seeing you then!"
            : "",
        //
        scheduledate: moment(data?.appdate_f)
          .subtract(1, "days")
          .format("YYYY-MM-DD"),
        scheduletime: "",
        scheduletimeobj: { value: "", label: "- : -" },
        schedulesms: data?.agentmessage.Scheduled_reminder
          ? data?.agentmessage.Scheduled_reminder
          : "Appointment Reminder for [AppDate] @ [AppTime] tomorrow for you to come into the dealership. If you need to reschedule just shoot me a text back. Thanks!",
        //
        followup_date: moment(data?.appdate_f)
          .add(1, "days")
          .format("YYYY-MM-DD"),
        followup_time: "",
        followup_time_obj: { value: "", label: "- : -" },
        followup_sms: data?.agentmessage.Automatic_follow_up
          ? data?.agentmessage.Automatic_follow_up
          : "Hey [fname], how did your appointment go? Were you able to find your vehicle?",
      });
    }
  }, [data]);

  const changstatus = (status) => {
    let sms = "";
    if (Number(status) === 5) {
      sms =
        "Your appointment has been cancelled. Thank you for letting us know! If you'd like to reschedule, please let me know.";
    } else if (Number(status) === 4) {
      sms = data?.agentmessage.Reschedulemessage
        ? data?.agentmessage.Reschedulemessage
        : "Your appointment has been rescheduled to [AppDate] @ [AppTime]. We look forward to seeing you then!";
    }
    setSmsData({ ...smsData, Status: status, sms: sms });
  };

  const submiteditappts = async () => {
    let params = {
      userId: user.Id,
      apptsId: mainData.apptsId,
      oldstatus: mainData.oldstatus,
      appleadId: mainData.appleadId,
      rid: mainData.rid,
      Status: smsData.Status,
      Stime: smsData.Stime,
      Sdate: smsData.Sdate,
      flag: 1,
    };
    const data = await editApptsApi(params);
    if (data.status === "ok") {
      let params2 = {
        userId: user.Id,
        sms: smsData.sms,
        lead: mainData.appleadId,
        Status: smsData.Status,
        automaticfollowup: automaticfollowup,
        smsaptflag: smsaptflag,
      };
      if (smsaptflag === 1) {
        params2.scheduledate = smsData.scheduledate;
        params2.scheduletime = smsData.scheduletime;
        params2.schedule_msg = smsData.schedulesms;
      }
      if (automaticfollowup === 1) {
        params2.followupdate = smsData.followup_date;
        params2.followuptime = smsData.followup_time;
        params2.followup_msg = smsData.followup_sms;
      }
      //
      if (Number(smsData.Status) === 4 || Number(smsData.Status) === 5) {
        if (Number(mainData.last_messagetype) === 3) {
          const data2 = await sendfbafterapprescheduleApi(params2);
          if (data2.status === "ok") {
            closeModal();
          }
        } else {
          const data3 = await sendsmsafterapprescheduleApi(params2);
          if (data3.status === "ok") {
            closeModal();
          }
        }
      } else {
        closeModal();
      }
    }
  };

  return (
    <Modal
      isOpen={isOpenModal}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Status"
    >
      <div className="modal-header">
        <h4 className="d-flex align-items-center">
          <BiEdit className="me-1" />
          Edit
        </h4>
      </div>
      <div className="modal-body py-3">
        <h6> Appts Information</h6>
        <div className="row mt-2">
          <div className="col-4">
            <label className="mb-2">Date:</label>
            <input
              type="date"
              className="form-control"
              value={smsData?.Sdate}
              onChange={(e) =>
                setSmsData({
                  ...smsData,
                  Sdate: e.target.value,
                  scheduledate: moment(e.target.value)
                    .subtract(1, "days")
                    .format("YYYY-MM-DD"),
                  followup_date: moment(e.target.value)
                    .add(1, "days")
                    .format("YYYY-MM-DD"),
                })
              }
            />
          </div>
          <div className="col-4">
            <label className="mb-2">Time:</label>
            <ScheduleTimeSelect
              value={smsData?.StimeObj}
              onChange={(e) => {
                setSmsData({
                  ...smsData,
                  StimeObj: e,
                  Stime: e.value,
                  scheduletimeobj: e,
                  scheduletime: e.value,
                  followup_time_obj: e,
                  followup_time: e.value,
                });
              }}
            />
          </div>
          <div className="col-4">
            <label className="mb-2">Status:</label>
            <select
              className="form-select"
              value={smsData?.Status}
              onChange={(e) => changstatus(e.target.value)}
            >
              <option value="1">Pending</option>
              <option value="2">Showed</option>
              <option value="3">No Show</option>
              <option value="4">Rescheduled</option>
              <option value="5">Cancelled</option>
            </select>
          </div>
        </div>
        {(Number(smsData?.Status) === 4 || Number(smsData?.Status) === 5) && (
          <>
            <div className="row mt-2">
              <div className="col-12">
                <label className="mb-2">Automatic Confirmation</label>
                <textarea
                  className="form-textarea"
                  value={smsData?.sms}
                  onChange={(e) =>
                    setSmsData({ ...smsData, sms: e.target.value })
                  }
                ></textarea>
              </div>
            </div>
          </>
        )}
        {Number(smsData?.Status) === 4 && (
          <>
            <hr />
            <div className="">
              <h6 className="d-flex align-items-center">
                <BsCheckCircle className="me-1" />
                Scheduled Reminder
                <Switch
                  className="ms-auto"
                  checked={smsaptflag === 1 ? true : false}
                  onChange={(e) => setSmsaptflag(e.target.checked ? 1 : 0)}
                />
              </h6>
              {smsaptflag === 1 && (
                <>
                  <div className="row mt-2">
                    <div className="col-6">
                      <div className="form-group">
                        <label className="mb-2">Select Date</label>
                        <input
                          type="date"
                          className="form-control"
                          value={smsData?.scheduledate}
                          onChange={(e) =>
                            setSmsData({
                              ...smsData,
                              scheduledate: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label className="mb-2">Select Time</label>
                        <ScheduleTimeSelect
                          value={smsData?.scheduletimeobj}
                          onChange={(e) => {
                            setSmsData({
                              ...smsData,
                              scheduletimeobj: e,
                              scheduletime: e.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-12">
                      <div className="form-group">
                        <textarea
                          className="form-textarea"
                          value={smsData?.schedulesms}
                          onChange={(e) =>
                            setSmsData({
                              ...smsData,
                              schedulesms: e.target.value,
                            })
                          }
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            <hr />
            <div className="">
              <h6 className="d-flex align-items-center">
                <BsCheckCircle className="me-1" />
                Automatic Follow-up
                <Switch
                  className="ms-auto"
                  checked={automaticfollowup === 1 ? true : false}
                  onChange={(e) =>
                    setAutomaticfollowup(e.target.checked ? 1 : 0)
                  }
                />
              </h6>
              {automaticfollowup === 1 && (
                <>
                  <div className="row mt-2">
                    <div className="col-6">
                      <div className="form-group">
                        <label className="mb-2">Select Date</label>
                        <input
                          type="date"
                          className="form-control"
                          value={smsData?.followup_date}
                          onChange={(e) =>
                            setSmsData({
                              ...smsData,
                              followup_date: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label className="mb-2">Select Time</label>
                        <ScheduleTimeSelect
                          value={smsData?.followup_time_obj}
                          onChange={(e) => {
                            setSmsData({
                              ...smsData,
                              followup_time_obj: e,
                              followup_time: e.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-12">
                      <div className="form-group">
                        <textarea
                          className="form-textarea"
                          value={smsData?.followup_sms}
                          onChange={(e) =>
                            setSmsData({
                              ...smsData,
                              followup_sms: e.target.value,
                            })
                          }
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </div>
      <div className="modal-footer mt-3">
        <button
          className="btn btn-primary me-2"
          onClick={() => submiteditappts()}
        >
          Save
        </button>
        <button className="btn btn-outline-secondary" onClick={closeModal}>
          Cancel
        </button>
      </div>
    </Modal>
  );
}
