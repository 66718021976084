import React, { createContext, useContext } from "react";
import { AxiosContext } from "./axios_context";
import config from "../config/config";
import { AuthContext } from "./auth_context";

const SettingContext = createContext(undefined);

const SettingContextProvider = (props) => {
  const { axiosInstance } = useContext(AxiosContext);
  const { user } = useContext(AuthContext);

  const getAgencyDetails = () => {
    const data = {
      userId: user.Id,
    };
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/account/agency_details`,
      data,
    })
      .then((response) => {
        const data = response.data;
        const res = data.response;
        return res;
      })
      .catch((error) => {
        console.log("err: ", error);
        return null;
      });
  };

  const updateAgencyDetails = (data) => {
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/account/update_agency_details`,
      data,
    })
      .then((response) => {
        const data = response.data;
        const res = data.response;
        return res;
      })
      .catch((error) => {
        console.log("err: ", error);
        return null;
      });
  };

  const getSavedReplies = () => {
    const data = {
      userId: user.Id,
    };
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/account/saved_replies`,
      data,
    })
      .then((response) => {
        const data = response.data;
        const res = data.response;
        return res;
      })
      .catch((error) => {
        console.log("err: ", error);
        return null;
      });
  };

  const getManageLabels = () => {
    const data = {
      userId: user.Id,
    };
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/account/manage_labels`,
      data,
    })
      .then((response) => {
        const data = response.data;
        const res = data.response;
        return res;
      })
      .catch((error) => {
        console.log("err: ", error);
        return null;
      });
  };

  const deleteLabelTag = (Id) => {
    const data = {
      Id: Id,
    };
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/account/delete_label_tag`,
      data,
    })
      .then((response) => {
        const data = response.data;
        const res = data.response;
        return res;
      })
      .catch((error) => {
        console.log("err: ", error);
        return null;
      });
  };

  const addLabelTag = (data) => {
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/account/add_label_tag`,
      data,
    })
      .then((response) => {
        const data = response.data;
        const res = data.response;
        return res;
      })
      .catch((error) => {
        console.log("err: ", error);
        return null;
      });
  };

  const getAgentSettings = () => {
    const data = {
      userId: user.Id,
    };
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/account/agent_settings`,
      data,
    })
      .then((response) => {
        const data = response.data;
        const res = data.response;
        return res;
      })
      .catch((error) => {
        console.log("err: ", error);
        return null;
      });
  };

  const updateAgentSettings = (data) => {
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/account/update_agent_settings`,
      data,
    })
      .then((response) => {
        const data = response.data;
        const res = data.response;
        return res;
      })
      .catch((error) => {
        console.log("err: ", error);
        return null;
      });
  };

  const getDefaultMessages = () => {
    const data = {
      userId: user.Id,
    };
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/account/default_messages`,
      data,
    })
      .then((response) => {
        const data = response.data;
        const res = data.response;
        return res;
      })
      .catch((error) => {
        console.log("err: ", error);
        return null;
      });
  };

  const updateDefaultMessages = (data) => {
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/account/update_agent_messages`,
      data,
    })
      .then((response) => {
        const data = response.data;
        const res = data.response;
        return res;
      })
      .catch((error) => {
        console.log("err: ", error);
        return null;
      });
  };

  const getTwilio = () => {
    const data = {
      userId: user.Id,
    };
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/account/twilio`,
      data,
    })
      .then((response) => {
        const data = response.data;
        const res = data.response;
        return res;
      })
      .catch((error) => {
        console.log("err: ", error);
        return null;
      });
  };

  const updateTwilio = (data) => {
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/account/save_twilio`,
      data,
    })
      .then((response) => {
        const data = response.data;
        const res = data.response;
        return res;
      })
      .catch((error) => {
        console.log("err: ", error);
        return null;
      });
  };

  const getProfile = () => {
    const data = {
      userId: user.Id,
    };
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/account/profile`,
      data,
    })
      .then((response) => {
        const data = response.data;
        const res = data.response;
        return res;
      })
      .catch((error) => {
        console.log("err: ", error);
        return null;
      });
  };

  const updateProfile = (data) => {
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/account/update_profile`,
      data,
    })
      .then((response) => {
        const data = response.data;
        const res = data.response;
        return res;
      })
      .catch((error) => {
        console.log("err: ", error);
        return null;
      });
  };

  const pictureUpload = (file, name) => {
    var data = new FormData();
    data.append("name", name);
    data.append("file", file);
    data.append("userId", user?.Id);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/account/picture_upload`,
      data,
    })
      .then((response) => {
        const data = response.data;
        const res = data;
        return res;
      })
      .catch((error) => {
        console.log("err: ", error);
        return null;
      });
  };

  const providerValue = {
    getAgencyDetails,
    updateAgencyDetails,
    getSavedReplies,
    getManageLabels,
    deleteLabelTag,
    addLabelTag,
    getAgentSettings,
    updateAgentSettings,
    getDefaultMessages,
    updateDefaultMessages,
    getTwilio,
    updateTwilio,
    getProfile,
    updateProfile,
    pictureUpload,
  };

  return (
    <SettingContext.Provider value={providerValue}>
      {props.children}
    </SettingContext.Provider>
  );
};

export { SettingContext, SettingContextProvider };
