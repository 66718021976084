import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  LinearProgress,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { AuthContext, SettingContext } from "../../../context";
import { ColorsConfig } from "../../../config";
import { LabelTagPicker, ProjectCircle } from "../../../components";
import { toast } from "react-toastify";
import { FormatHelper } from "../../../utils";

export default function UserLabels() {
  const { getManageLabels, deleteLabelTag, addLabelTag } =
    useContext(SettingContext);
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [showErrorText, setShowErrorText] = useState(false);
  const [labels, setLabels] = useState(null);
  const [labelTag, setLabelTag] = useState({
    Name: "",
    Class: "label label-red",
    Agent: "",
  });

  useEffect(() => {
    if (user && user.Id) {
      getInfo();
    }
  }, [user]);

  const getInfo = async () => {
    setLoading(true);
    const result = await getManageLabels();
    setLabels(result?.info);
    setLabelTag({
      ...labelTag,
      Agent: result?.agent,
    });
    setLoading(false);
  };

  const deleteHandle = async (item) => {
    const items = labels.filter((i) => i.Id !== item?.Id);
    setLabels([...items]);
    await deleteLabelTag(item?.Id);
    toast.error("Deleted label.");
  };

  const handleAdd = async () => {
    if (!labelTag.Name) {
      setShowErrorText(true);
      toast.error("Please input the Name");
      return;
    }
    setConfirmLoading(true);
    const result = await addLabelTag(labelTag);
    setConfirmLoading(false);
    if (result && result.id) {
      const item = {
        Id: result.id,
        Count: 0,
        Name: labelTag.Name,
        Class: labelTag.Class,
        Agent: labelTag.Agent,
      };
      const items = labels;
      items.push(item);
      setLabels([...items]);
      setLabelTag({
        Name: "",
        Class: "label label-red",
        Agent: "",
      });
      toast.success("Successfully, Label added!");
    } else {
      toast.error("Can not add label");
    }
  };

  const skeleton = () => {
    return (
      <Box>
        {[...Array(10)].map((item, index) => {
          return (
            <Stack
              key={index}
              sx={{ mb: 0.5 }}
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"start"}
                spacing={1}
              >
                <Skeleton variant="circular" width={25} height={25} />
                <Skeleton variant={"rounded"} width={120} height={35} />
              </Stack>
              <Skeleton
                item={item}
                variant={"rounded"}
                width={60}
                height={35}
              />
            </Stack>
          );
        })}
      </Box>
    );
  };

  const renderItems = () => {
    return (
      <Box>
        {labels?.map((item, index) => {
          return (
            <Stack
              key={index}
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              sx={{
                mt: 0.5,
                borderBottom: 1,
                borderColor: ColorsConfig.light.primaryBorderColor,
                p: 1,
              }}
            >
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"start"}
                spacing={1}
              >
                <ProjectCircle
                  width={20}
                  color={FormatHelper.getColorFromClass(item?.Class)}
                />
                <Typography variant="body2">{item?.Name}</Typography>
              </Stack>
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"start"}
                spacing={1}
              >
                <Typography variant="body2">{item?.Count}</Typography>
                <DeleteOutlineOutlinedIcon
                  onClick={() => deleteHandle(item)}
                  sx={{ fontSize: 26, cursor: "pointer" }}
                />
              </Stack>
            </Stack>
          );
        })}
      </Box>
    );
  };

  return (
    <Box>
      <Typography variant="caption">
        Use labels to help you describe and organize Lead status. Labels can be
        about anything.
      </Typography>
      {confirmLoading && (
        <Box marginY={2}>
          <LinearProgress />
        </Box>
      )}
      {!loading && (
        <Card
          sx={{
            p: 1,
            mt: 2,
          }}
        >
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              spacing={1.5}
            >
              <LabelTagPicker
                value={labelTag.Class}
                handleChange={(item) => {
                  setLabelTag({
                    ...labelTag,
                    Class: item,
                  });
                }}
              />
              <Box>
                <TextField
                  type="text"
                  size="small"
                  margin="none"
                  variant="outlined"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  placeholder="Name Label..."
                  value={labelTag.Name}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value) {
                      setShowErrorText(false);
                    } else {
                      setShowErrorText(true);
                    }
                    setLabelTag({
                      ...labelTag,
                      Name: value,
                    });
                  }}
                />
                {showErrorText && (
                  <Typography
                    sx={{
                      fontSize: 12,
                      color: ColorsConfig.light.error,
                      mt: 0.5,
                    }}
                  >
                    Required Label Name
                  </Typography>
                )}
              </Box>
            </Stack>
            <Button
              variant="contained"
              color="info"
              sx={{
                textTransform: "none",
              }}
              onClick={handleAdd}
              disabled={confirmLoading}
            >
              Add Label
            </Button>
          </Stack>
        </Card>
      )}
      <Card
        sx={{
          p: 1,
          mt: 2,
          height: loading ? "auto" : "calc(100vh - 400px)",
          overflowY: loading ? "auto" : "scroll",
        }}
      >
        {loading ? skeleton() : renderItems()}
      </Card>
    </Box>
  );
}
