import React, { useContext, useState } from "react";
import Modal from "react-modal";
import { AuthContext, FunnelContext } from "../../context";
import { useEffect } from "react";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "560px",
    padding: "15px",
    marginTop: "20px",
    marginBottom: "20px",
    overflow: "visible",
  },
};

Modal.setAppElement("#root");

export default function FunnelReassignConfirmModal({
  data = null,
  isOpenModal = false,
  afterOpenModal,
  closeModal,
  reloadData,
}) {
  const { user } = useContext(AuthContext);
  const { reassignFunnelApi } = useContext(FunnelContext);
  //
  const [message, setMessage] = useState(undefined);
  const [postData, setPostData] = useState({
    userId: user.Id,
    sendtype: "",
    lead: "",
    oldfunnelid: "",
    newfunnelid: "",
  });
  const [postData2, setPostData2] = useState({
    sdate: "",
    etime: "",
  });

  useEffect(() => {
    if (data) {
      setMessage(data?.message);
      let _postData = { ...postData };
      _postData.userId = user.Id;
      _postData.sendtype = 1;
      _postData.lead = data?.lead;
      _postData.oldfunnelid = data?.oldfunnelid;
      _postData.newfunnelid = data?.funnelid;
      setPostData(_postData);
    }
  }, [data]);

  const assignConfirm = async () => {
    let params = { ...postData };
    if (Number(postData.sendtype) === 2) {
      params.sdate = postData2.sdate;
      params.etime = postData2.etime;
    }
    const data2 = await reassignFunnelApi(params);
    if (data2.status === "ok") {
      reloadData();
    }
  };

  return (
    <Modal
      isOpen={isOpenModal}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Reassign Confirm Modal"
    >
      <div className="modal-header">
        <h4 className="d-flex align-items-center">Re Assign Funnel</h4>
      </div>
      <div className="modal-body">
        <div className="row mt-4">
          <div className="col col-sm-12">
            <p
              className="m-0"
              dangerouslySetInnerHTML={{ __html: message?.Message }}
            ></p>
            {message?.Gif ? (
              <img
                src={`https://media1.giphy.com/media/${message?.Gif}/100_s.gif`}
                alt=""
              />
            ) : message?.Attached ? (
              <img
                src={message?.Attached}
                width="100px"
                height="100px"
                alt=""
              />
            ) : null}
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-12">
            <label className="d-flex align-items-center">
              <input
                type="radio"
                name="optradio"
                className="me-2 position-relative"
                style={{ top: "1px" }}
                value="1"
                onClick={() => setPostData({ ...postData, sendtype: 1 })}
              />
              Send now
            </label>
            <label className="d-flex align-items-center mt-2">
              <input
                type="radio"
                name="optradio"
                className="me-2 position-relative"
                style={{ top: "1px" }}
                value="2"
                onClick={() => setPostData({ ...postData, sendtype: 2 })}
              />
              Schedule
            </label>
          </div>
        </div>
        {Number(postData.sendtype) === 2 && (
          <div className="row mt-2">
            <div className="col-6">
              <input
                type="date"
                className="form-control"
                value={postData2.sdate}
                onChange={(e) =>
                  setPostData2({ ...postData2, sdate: e.target.value })
                }
              />
            </div>
            <div className="col-6">
              <input
                type="time"
                className="form-control"
                step={900}
                value={postData2.etime}
                onChange={(e) =>
                  setPostData2({ ...postData2, etime: e.target.value })
                }
              />
            </div>
          </div>
        )}
      </div>
      <div className="modal-footer mt-3">
        <button className="btn btn-primary" onClick={() => assignConfirm()}>
          {Number(postData.sendtype) === 1 ? "Assign" : "Schedule"}
        </button>
        <button className="btn btn-outline-secondary ms-2" onClick={closeModal}>
          Cancel
        </button>
      </div>
    </Modal>
  );
}
