import React from "react";

export default function StandardImage(props) {
  const { width = 60, src, onClick, sx } = props;

  const style = {
    ...sx,
    width: width,
    cursor: onClick ? "pointer" : "",
  };

  return <img src={src} style={{ ...style }} onClick={onClick} />;
}
