import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import React from "react";

export default function DateFilterPicker({
  selectedDays = 7,
  setSelectedDays,
  customDateRage,
  setCustomDateRange,
}) {
  const handleEvent = (event, picker) => {
    console.log(event, picker);
  };
  const handleCallback = (start, end, label) => {
    console.log(
      label,
      moment(start).format("YYYY-MM-DD"),
      moment(end).format("YYYY-MM-DD"),
    );
    setCustomDateRange({
      start: moment(start).format("YYYY-MM-DD"),
      end: moment(end).format("YYYY-MM-DD"),
    });
  };

  return (
    <div className="date-filter">
      <button
        className={`btn-filter ${selectedDays === 7 ? "active" : ""}`}
        onClick={() => setSelectedDays(7)}
      >
        Last 7 Days
      </button>
      <button
        className={`btn-filter ${selectedDays === 30 ? "active" : ""}`}
        onClick={() => setSelectedDays(30)}
      >
        Last 30 Days
      </button>
      <button
        className={`btn-filter ${selectedDays === 90 ? "active" : ""}`}
        onClick={() => setSelectedDays(90)}
      >
        Last 90 Days
      </button>
      <DateRangePicker
        initialSettings={{
          startDate:
            customDateRage?.start || new Date(moment().subtract(1, "months")),
          endDate: customDateRage?.end || new Date(),
        }}
        maxDate={new Date()}
        onEvent={handleEvent}
        onCallback={handleCallback}
      >
        <button
          className={`btn-filter ${selectedDays === "custom" ? "active" : ""}`}
          onClick={() => setSelectedDays("custom")}
        >
          Custom
        </button>
      </DateRangePicker>
    </div>
  );
}
