import React from "react";
import { Box, Card, Skeleton, Stack, Typography } from "@mui/material";
import { ColorsConfig } from "../../config";

export default function ConversationsInboxFilter(props) {
  const { inboxAnalysis, loading, handleClick } = props;

  const inboxItems = [
    {
      label: "Inbox",
      field: "count",
      color: "#64b5f6",
      bgcolor: "#fff",
      filterParams: ["L8", "t"],
    },
    {
      label: "Awaiting Response",
      field: "iswaiting",
      color: "#fff",
      bgcolor: "#777",
      filterParams: ["L0", "n"],
    },
    {
      label: "Call Required",
      field: "Newlead",
      color: "#fff",
      bgcolor: "#0062b3",
      filterParams: ["L12", "1"],
    },
    {
      label: "Hot",
      field: "hot",
      color: "#fff",
      bgcolor: "#e57373",
      filterParams: ["L1", "1"],
    },
    {
      label: "Warm",
      field: "warm",
      color: "#fff",
      bgcolor: "#efafaf",
      filterParams: ["L4", "4"],
    },
    {
      label: "Cold",
      field: "cold",
      color: "#fff",
      bgcolor: "#64b5f6",
      filterParams: ["L2", "2"],
    },
    {
      label: "Follow Up",
      field: "followup",
      color: "#fff",
      bgcolor: "#7986cb",
      filterParams: ["L5", "5"],
    },
    {
      label: "Sold",
      field: "sold",
      color: "#fff",
      bgcolor: "#b39ddb",
      filterParams: ["L3", "3"],
    },
    {
      label: "Dead",
      field: "dead",
      color: "#fff",
      bgcolor: "#888",
      filterParams: ["L6", "6"],
    },
  ];

  const doneItems = [
    {
      label: "Done",
      field: "done",
      color: "#fff",
      bgcolor: "#333",
      filterParams: ["D", ""],
    },
    {
      label: "Appointments",
      field: "app",
      color: "#fff",
      bgcolor: "#7986CB",
      filterParams: ["A", ""],
    },
    {
      label: "Appointments Showed",
      field: "appshow",
      color: "#fff",
      bgcolor: "#B39DDB",
      filterParams: ["As", ""],
    },
    {
      label: "Scheduled",
      field: "schedule",
      color: "#fff",
      bgcolor: "#64B5F6",
      filterParams: ["Sch", ""],
    },
  ];

  const renderSkeleton = (items) => {
    return (
      <Box>
        {items.map((item, index) => {
          return (
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              key={index}
              sx={{
                mt: 1,
              }}
            >
              <Skeleton item={item} variant="rounded" width={80} height={25} />
              <Skeleton
                item={item}
                key={index}
                variant="rounded"
                width={40}
                height={25}
              />
            </Stack>
          );
        })}
      </Box>
    );
  };

  const renderView = (items) => {
    return (
      <Box>
        {items.map((item, index) => {
          return (
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              key={index}
              sx={{
                mt: 1,
                cursor: "pointer",
              }}
              onClick={() => {
                handleClick(item);
              }}
            >
              <Typography
                sx={{
                  fontSize: 14,
                  color: ColorsConfig.light.primaryLightColor,
                }}
              >
                {item.label}
              </Typography>
              <span
                style={{
                  fontSize: 12,
                  color: item.color,
                  backgroundColor: item.bgcolor,
                  borderRadius: 8,
                  padding: "2px 5px",
                  border: `1px solid ${item.color}`,
                }}
              >
                {inboxAnalysis?.[item.field]}
              </span>
            </Stack>
          );
        })}
      </Box>
    );
  };

  return (
    <Box>
      <Stack spacing={1}>
        <Card sx={{ width: "100%", p: "10px 12px" }}>
          {loading ? renderSkeleton(inboxItems) : renderView(inboxItems)}
        </Card>
        <Card sx={{ width: "100%", p: "10px 12px" }}>
          {loading ? renderSkeleton(doneItems) : renderView(doneItems)}
        </Card>
      </Stack>
    </Box>
  );
}
