import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  LinearProgress,
  Skeleton,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import moment from "moment";
import { AuthContext, SettingContext } from "../../../context";
import { PrimarySelect, PrimaryTimePicker } from "../../../components";
import { Constants } from "../../../config";
import { toast } from "react-toastify";

export default function UserAgentSettings() {
  const { getAgentSettings, updateAgentSettings } = useContext(SettingContext);
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [info, setInfo] = useState(null);
  const [pageData, setPageData] = useState({
    startTime: moment(),
    endTime: moment(),
    autoLeads: false,
    hideLeads: false,
    autoUnassign: "0",
  });

  useEffect(() => {
    if (user && user.Id) {
      getInfo();
    }
  }, [user]);

  useEffect(() => {
    if (info) {
      setPageData({
        startTime: moment(
          `2022-02-22T${info?.Starttime}`,
          "YYYY-MM-DDThh:mm a",
        ),
        endTime: moment(`2022-02-22T${info?.Endtime}`, "YYYY-MM-DDThh:mm a"),
        autoLeads: info?.Autoassign === "1",
        hideLeads: info?.Hideassign === "1",
        autoUnassign: info?.Autounassignlead,
      });
    }
  }, [info]);

  const getInfo = async () => {
    setLoading(true);
    const result = await getAgentSettings();
    setInfo(result?.info);
    setLoading(false);
  };

  const submit = async () => {
    const data = {
      userId: user.Id,
      Autoassign: pageData.autoLeads ? "1" : "0",
      Hideassign: pageData.hideLeads ? "1" : "0",
      Autounassignlead: pageData.autoUnassign,
      Starttime: moment(pageData.startTime).format("hh:mm a"),
      Endtime: moment(pageData.endTime).format("hh:mm a"),
      Id: info?.Id,
    };
    setConfirmLoading(true);
    await updateAgentSettings(data);
    setConfirmLoading(false);
    toast.success("Successfully updated!");
  };

  const skeleton = () => {
    return (
      <Stack direction={"column"} spacing={2}>
        <Stack
          direction={{
            xs: "column",
            md: "row",
          }}
          alignItems={"center"}
          spacing={2}
        >
          <Skeleton variant="rounded" width={100} height={40} />
          <Skeleton variant="rounded" width={200} height={40} />
          <Skeleton variant="rounded" width={200} height={40} />
        </Stack>
        <Stack
          direction={{
            xs: "column",
            md: "row",
          }}
          alignItems={"center"}
          spacing={2}
        >
          <Skeleton variant="rounded" width={100} height={40} />
          <Skeleton variant="rounded" width={100} height={40} />
        </Stack>
        <Stack
          direction={{
            xs: "column",
            md: "row",
          }}
          alignItems={"center"}
          spacing={2}
        >
          <Skeleton variant="rounded" width={100} height={40} />
          <Skeleton variant="rounded" width={100} height={40} />
        </Stack>
        <Stack
          direction={{
            xs: "column",
            md: "row",
          }}
          alignItems={"center"}
          spacing={2}
        >
          <Skeleton variant="rounded" width={100} height={40} />
          <Skeleton variant="rounded" width={200} height={40} />
        </Stack>
      </Stack>
    );
  };

  const renderMain = () => {
    return (
      <Box>
        <Stack direction={"column"} spacing={2}>
          <Stack
            direction={{
              xs: "column",
              md: "row",
            }}
            alignItems={"center"}
            spacing={2}
          >
            <Typography>Agent Hours</Typography>
            <PrimaryTimePicker
              value={pageData.startTime}
              handleChange={(v) => setPageData({ ...pageData, startTime: v })}
            />
            <PrimaryTimePicker
              value={pageData.endTime}
              handleChange={(v) => setPageData({ ...pageData, endTime: v })}
            />
          </Stack>
          <Stack
            direction={{
              xs: "column",
              md: "row",
            }}
            alignItems={"center"}
            spacing={2}
          >
            <Typography>Auto-Assign Leads</Typography>
            <Switch
              checked={pageData.autoLeads}
              onChange={(e) =>
                setPageData({ ...pageData, autoLeads: e.target.checked })
              }
            />
          </Stack>
          <Stack
            direction={{
              xs: "column",
              md: "row",
            }}
            alignItems={"center"}
            spacing={2}
          >
            <Typography>Hide-Assign Leads</Typography>
            <Switch
              checked={pageData.hideLeads}
              onChange={(e) =>
                setPageData({ ...pageData, hideLeads: e.target.checked })
              }
            />
          </Stack>
          <Stack
            direction={{
              xs: "column",
              md: "row",
            }}
            alignItems={"center"}
            spacing={2}
          >
            <Typography>Auto-Unassign Leads</Typography>
            <PrimarySelect
              options={Constants.unassignLeadsTimes}
              value={pageData.autoUnassign}
              handleChange={(e) =>
                setPageData({ ...pageData, autoUnassign: e.target.value })
              }
            />
          </Stack>
        </Stack>
        {confirmLoading && (
          <Box
            sx={{
              mt: 2,
            }}
          >
            <LinearProgress />
          </Box>
        )}
        <Stack
          direction={"row"}
          spacing={3}
          alignItems={"center"}
          justifyContent={"center"}
          mt={3}
        >
          <Button
            variant="contained"
            onClick={submit}
            disabled={confirmLoading}
          >
            Save
          </Button>
          <Button variant="outlined" color="inherit" disabled={confirmLoading}>
            Cancel
          </Button>
        </Stack>
      </Box>
    );
  };

  return <Box mt={2}>{loading ? skeleton() : renderMain()}</Box>;
}
