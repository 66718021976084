import React from "react";
import { CookiesProvider } from "react-cookie";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { AxiosContextProvider } from "./axios_context";
import { AuthContextProvider } from "./auth_context";
import { SharedContextProvider } from "./shared_context";
import { DashboardContextProvider } from "./dashboard_context";
import { AppointmentContextProvider } from "./appointment_context";
import { ConversationContextProvider } from "./conversation_context";
import { VideoContextProvider } from "./video_context";
import { UpdashTwilioContextProvider } from "./updash_twilio_context";
import { ClientContextProvider } from "./client_context";
import { CampaignContextProvider } from "./campaign_context";
import { UserContextProvider } from "./user_context";
import { FunnelContextProvider } from "./funnel_context";
import { BlastContextProvider } from "./blast_context";
import { ReportContextProvider } from "./report_context";
import { OtherContextProvider } from "./other_context";
import { LeadContextProvider } from "./lead_context";
import { SettingContextProvider } from "./setting_context";
import { FirebaseContextProvider } from "./firebase_context";
import { ChatContextProvider } from "./chat_context";

const ContextProvider = ({ children }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <CookiesProvider>
        <AxiosContextProvider>
          <AuthContextProvider>
            <FirebaseContextProvider>
              <SettingContextProvider>
                <OtherContextProvider>
                  <LeadContextProvider>
                    <SharedContextProvider>
                      <DashboardContextProvider>
                        <AppointmentContextProvider>
                          <ConversationContextProvider>
                            <ChatContextProvider>
                              <UpdashTwilioContextProvider>
                                <VideoContextProvider>
                                  <ClientContextProvider>
                                    <CampaignContextProvider>
                                      <UserContextProvider>
                                        <FunnelContextProvider>
                                          <BlastContextProvider>
                                            <ReportContextProvider>
                                              {children}
                                            </ReportContextProvider>
                                          </BlastContextProvider>
                                        </FunnelContextProvider>
                                      </UserContextProvider>
                                    </CampaignContextProvider>
                                  </ClientContextProvider>
                                </VideoContextProvider>
                              </UpdashTwilioContextProvider>
                            </ChatContextProvider>
                          </ConversationContextProvider>
                        </AppointmentContextProvider>
                      </DashboardContextProvider>
                    </SharedContextProvider>
                  </LeadContextProvider>
                </OtherContextProvider>
              </SettingContextProvider>
            </FirebaseContextProvider>
          </AuthContextProvider>
        </AxiosContextProvider>
      </CookiesProvider>
    </LocalizationProvider>
  );
};

export default ContextProvider;
