import Modal from "react-modal";
import React, { useContext, useState, useEffect } from "react";
import { AuthContext, ConversationContext } from "../../context";
import { BsClock } from "react-icons/bs";
import ScheduleTimeSelect from "../select/schedule_time_select";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "560px",
    padding: "15px",
    marginTop: "20px",
    marginBottom: "20px",
  },
};

Modal.setAppElement("#root");

export default function ConversationSetAppointmentLookUpCodeModal({
  data,
  isOpenModal = false,
  afterOpenModal,
  closeModal,
}) {
  const { user } = useContext(AuthContext);
  const { setApptsApi } = useContext(ConversationContext);
  const [tab, setTab] = useState("contact"); // contact, vehicle
  const [submitDisable, setSubmitDisable] = useState(false);
  const [submitButtonLabel, setSubmitButtonLabel] = useState("Save");

  const [mainData, setMainData] = useState(null);
  const [postData, setPostData] = useState(null);

  const [clients, setClients] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [states, setStates] = useState([]);
  const [yearsHtml, setYearsHtml] = useState(null);
  const [modelsHtml, setModelsHtml] = useState(null);
  const [makesHtml, setMakesHtml] = useState(null);

  useEffect(() => {
    if (!!data && isOpenModal) {
      setClients(data?.client);
      setCampaigns(data?.campaign);
      setStates(data?.state);
      setYearsHtml(data?.ymm.year);
      setModelsHtml(data?.ymm.model);
      setMakesHtml(data?.ymm.make);
      //
      setMainData({
        userId: user.Id,
        Barcode: data?.response.Response.Barcode,
        responseId: data?.response.Response.Id,
        campaignId: data?.response.Campaign.Id,
        cmpname: data?.response.Campaign.Name,
        appleadid: data?.leadid,
        Client: data?.response.Campaign.ClientId,
      });
      //
      setPostData({
        userId: user.Id,
        Fname: data?.response.Address.Fname,
        Lname: data?.response.Address.Lname,
        Address: data?.response.Address.Address,
        City: data?.response.Address.City,
        State: data?.response.Address.State,
        Zip: data?.response.Address.Zip,
        Email: data?.response.Address.Email,
        Mphone: data?.response.Phone.type3,
        Hphone: data?.response.Home_phone.type3,
        Sdate: `${data?.cdate.year}-${data?.cdate.month[0]}-${data?.cdate.day}`,
        Stime: `${data?.cdate.h12}:${data?.cdate.min} ${data?.cdate.dv}`,
        StimeObj: {
          value: `${data?.cdate.h12}:${data?.cdate.min} ${data?.cdate.dv}`,
          label: `${data?.cdate.h12}:${data?.cdate.min} ${data?.cdate.dv}`,
        },
        //
        Note: "",
        Mileage: data?.ymm.mileage ? data?.ymm.mileage : "",
        Status: "",
        //
        Year: "",
        Model: "",
        Make: "",
      });
    }
  }, [data, isOpenModal]);

  const onSave = async () => {
    setSubmitDisable(true);
    setSubmitButtonLabel("Creating...");
    //
    const params = {
      userId: user.Id,
      Client: mainData?.Client,
      appleadid: mainData?.appleadid,
      cmpname: mainData?.cmpname,
      campaignId: mainData?.campaignId,
      Barcode: mainData?.Barcode,
      Note: postData?.Note,
      Mileage: postData?.Mileage,
      Status: postData?.Status,
      Stime: postData?.Stime,
      Sdate: postData?.Sdate,
      Hphone: postData?.Hphone,
      Mphone: postData?.Mphone,
      Email: postData?.Email,
      Zip: postData?.Zip,
      City: postData?.City,
      Address: postData?.Address,
      State: postData?.State,
      Lname: postData?.Lname,
      Fname: postData?.Fname,
      Year: postData?.Year,
      Make: postData?.Make,
      Model: postData?.Model,
      isajax: 1,
    };
    //
    const data = await setApptsApi(params);
    if (data.status === "ok") {
      closeModal();
    }
  };

  return (
    <Modal
      isOpen={isOpenModal}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Status"
    >
      <div className="modal-header">
        <h4 className="d-flex align-items-center">
          <BsClock className="me-1" />
          Set Appointment
        </h4>
      </div>
      <div className="modal-body my-3">
        <ul className="modal-tabs nav-tabs">
          <li
            className={`nav-tab ${tab === "contact" ? "active" : ""}`}
            onClick={() => setTab("contact")}
          >
            Contact
          </li>
          <li
            className={`nav-tab ${tab === "vehicle" ? "active" : ""}`}
            onClick={() => setTab("vehicle")}
          >
            Vehicle
          </li>
        </ul>
        <div className="tab-content">
          {tab === "contact" && (
            <>
              <div className="row">
                <div className="col-6">
                  <div className="row">
                    <div className="col-12">
                      <label className="my-2">Select Client</label>
                      <select
                        className="form-select"
                        disabled={true}
                        defaultValue={mainData?.client}
                      >
                        <option value="">Client Name</option>
                        {clients &&
                          clients.map((item, index) => (
                            <option value={item.Id} key={index}>
                              {item.Name}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <label className="my-2">Select Client</label>
                      <select
                        className="form-select"
                        disabled={true}
                        defaultValue={mainData?.campaignId}
                      >
                        <option value="">Select Campaign</option>
                        {campaigns &&
                          campaigns.map((item, index) => (
                            <option
                              value={item.Id}
                              key={index}
                            >{`${item.Job}-${item.Type}`}</option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6 pe-1">
                      <label className="my-2">First Name</label>
                      <input
                        type="text"
                        className="form-control"
                        value={postData?.Fname}
                        onChange={(e) =>
                          setPostData({ ...postData, Fname: e.target.value })
                        }
                      />
                    </div>
                    <div className="col-6 ps-1">
                      <label className="my-2">Last Name</label>
                      <input
                        type="text"
                        className="form-control"
                        value={postData?.Lname}
                        onChange={(e) =>
                          setPostData({ ...postData, Lname: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <label className="my-2">Address</label>
                      <input
                        type="text"
                        className="form-control"
                        value={postData?.Address}
                        onChange={(e) =>
                          setPostData({ ...postData, Address: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4 pe-0">
                      <label className="my-2">City</label>
                      <input
                        type="text"
                        className="form-control"
                        value={postData?.City}
                        onChange={(e) =>
                          setPostData({ ...postData, City: e.target.value })
                        }
                      />
                    </div>
                    <div className="col-4 px-2">
                      <label className="my-2">State</label>
                      <select
                        className="form-select"
                        value={postData?.State}
                        onChange={(e) =>
                          setPostData({ ...postData, State: e.target.value })
                        }
                      >
                        {states &&
                          states.map((item, index) => (
                            <option value={item} key={index}>
                              {item}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="col-4 ps-0">
                      <label className="my-2">Zip</label>
                      <input
                        type="text"
                        className="form-control"
                        value={postData?.Zip}
                        onChange={(e) =>
                          setPostData({ ...postData, Zip: e.target.value })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="row">
                    <div className="col-12">
                      <label className="my-2">Email Address</label>
                      <input
                        type="text"
                        className="form-control"
                        value={postData?.Email}
                        onChange={(e) =>
                          setPostData({ ...postData, Email: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <label className="my-2">Mobile Phone</label>
                      <input
                        type="text"
                        className="form-control"
                        value={postData?.Mphone}
                        onChange={(e) =>
                          setPostData({ ...postData, Mphone: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <label className="my-2">Home Phone</label>
                      <input
                        type="text"
                        className="form-control"
                        value={postData?.Hphone}
                        onChange={(e) =>
                          setPostData({ ...postData, Hphone: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6 pe-1">
                      <label className="my-2">Select Date</label>
                      <input
                        type="date"
                        className="form-control"
                        value={postData?.Sdate}
                        onChange={(e) =>
                          setPostData({ ...postData, Sdate: e.target.value })
                        }
                      />
                    </div>
                    <div className="col-6 ps-1">
                      <label className="my-2">Select Time</label>
                      <ScheduleTimeSelect
                        value={postData?.StimeObj}
                        onChange={(e) =>
                          setPostData({
                            ...postData,
                            StimeObj: e,
                            Stime: e.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <label className="my-2">Status</label>
                      <select
                        className="form-select"
                        value={postData?.Status}
                        onChange={(e) =>
                          setPostData({ ...postData, Status: e.target.value })
                        }
                      >
                        <option value="1">Pending</option>
                        <option value="2">Showed</option>
                        <option value="3">No Show</option>
                        <option value="4">Rescheduled</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <label className="my-2">Note</label>
                  <textarea
                    className="form-textarea"
                    rows={4}
                    value={postData?.Note}
                    onChange={(e) =>
                      setPostData({ ...postData, Note: e.target.value })
                    }
                  ></textarea>
                </div>
              </div>
            </>
          )}
          {/*  */}
          {tab === "vehicle" && (
            <>
              <div className="row">
                <div className="col-6">
                  <div className="row">
                    <div className="col-12">
                      <label className="my-2">Year</label>
                      <select
                        className="form-select"
                        dangerouslySetInnerHTML={{ __html: yearsHtml }}
                        value={postData?.Year}
                        onChange={(e) =>
                          setPostData({ ...postData, Year: e.target.value })
                        }
                      ></select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <label className="my-2">Model</label>
                      <select
                        className="form-select"
                        dangerouslySetInnerHTML={{ __html: modelsHtml }}
                        value={postData?.Model}
                        onChange={(e) =>
                          setPostData({ ...postData, Model: e.target.value })
                        }
                      ></select>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="row">
                    <div className="col-12">
                      <label className="my-2">Make</label>
                      <select
                        className="form-select"
                        dangerouslySetInnerHTML={{ __html: makesHtml }}
                        value={postData?.Make}
                        onChange={(e) =>
                          setPostData({ ...postData, Make: e.target.value })
                        }
                      ></select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <label className="my-2">Mileage</label>
                      <input
                        className="form-control"
                        value={postData?.Mileage}
                        onChange={(e) =>
                          setPostData({ ...postData, Mileage: e.target.value })
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="modal-footer mt-3">
        <button
          className="btn btn-primary me-2"
          disabled={submitDisable}
          onClick={() => onSave()}
        >
          {submitButtonLabel ? submitButtonLabel : "Save"}
        </button>
        <button className="btn btn-outline-secondary" onClick={closeModal}>
          Cancel
        </button>
      </div>
    </Modal>
  );
}
