import React, { useState } from "react";
import {
  Box,
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Skeleton,
  Stack,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddIcon from "@mui/icons-material/Add";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import EditCalendarOutlinedIcon from "@mui/icons-material/EditCalendarOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { ColorsConfig } from "../../config";
import { PrimaryInput, UserTemplate } from "../../components";

export default function UserCategory(props) {
  const { category, isSkeleton = false } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [info, setInfo] = useState(category);

  const handleMenuClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuSelect = (item) => {
    handleMenuClose();
    console.log(item);
  };

  const skeleton = () => {
    return (
      <Box sx={{ minWidth: 250 }}>
        <Skeleton variant="rounded" width="100%" height={50} />
        <Box
          sx={{
            backgroundColor: ColorsConfig.light.badgeBgColor,
            borderRadius: 2,
            p: 1,
            mt: 1,
          }}
        >
          {[...Array(3)].map((item, index) => {
            return (
              <Box key={index} mb={1}>
                <Skeleton variant="rounded" width={"100%"} height={150} />
              </Box>
            );
          })}
        </Box>
      </Box>
    );
  };

  if (isSkeleton) {
    return skeleton();
  }

  return (
    <Box sx={{ minWidth: 250 }}>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        spacing={2}
      >
        <PrimaryInput
          value={info?.Categoryname}
          handleChange={(e) => {
            setInfo({
              ...info,
              Categoryname: e.target.value,
            });
          }}
        />
        <Stack
          direction={"row"}
          spacing={0}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Box
            sx={{
              backgroundColor: ColorsConfig.light.badgeBgColor,
              borderRadius: "50%",
              width: 30,
              height: 30,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: 14,
            }}
          >
            {info?.Template?.length ?? 0}
          </Box>
          <MoreVertIcon
            sx={{ cursor: "pointer", fontSize: 30 }}
            onClick={handleMenuClick}
          />
          <Menu
            sx={{
              mt: "12px",
            }}
            id="basic-menu"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={open}
            onClose={handleMenuClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              onClick={() => {
                handleMenuSelect("edit");
              }}
            >
              <ListItemIcon>
                <AddIcon />
              </ListItemIcon>
              <ListItemText>Add New</ListItemText>
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleMenuSelect("delete");
              }}
            >
              <ListItemIcon>
                <EditCalendarOutlinedIcon />
              </ListItemIcon>
              <ListItemText>Rename Category</ListItemText>
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleMenuSelect("delete");
              }}
            >
              <ListItemIcon>
                <DeleteOutlineOutlinedIcon />
              </ListItemIcon>
              <ListItemText>Delete Category</ListItemText>
            </MenuItem>
          </Menu>
        </Stack>
      </Stack>
      <Box
        sx={{
          backgroundColor: ColorsConfig.light.badgeBgColor,
          borderRadius: 2,
          p: 1,
          mt: 1,
        }}
      >
        {info?.Template?.map((item, index) => {
          return (
            <Box key={index} mb={1}>
              <UserTemplate template={item} />
            </Box>
          );
        })}
        <Button
          variant="text"
          sizer="small"
          startIcon={<AddCircleOutlineOutlinedIcon />}
          fullWidth
        >
          Add New
        </Button>
      </Box>
    </Box>
  );
}
