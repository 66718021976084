import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../../context";
import TablePagination from "../../TablePagination/TablePagination";
import { ButtonGroup } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import UserGroupRemoveModal from "../../modals/user_group_remove_modal";

export default function UserGroupsDataTable(props) {
  const { data = [], reloadData, loading } = props;

  const { user } = useContext(AuthContext);
  const [searchKey, setSearchKey] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const perPage = 25;

  const [removeUserGroupModal, setRemoveUserGroupModal] = useState("");

  useEffect(() => {
    setCurrentPage(1);
    console.log(user);
    console.log(loading);
  }, [data]);

  const datatable = (data) => {
    return data.length > 0
      ? data.filter((el) =>
          el.UserGroup?.toLowerCase().includes(searchKey?.toLowerCase()),
        )
      : [];
  };

  const removeUserGroup = (groupId) => {
    setRemoveUserGroupModal(groupId);
  };

  return (
    <div className="card">
      <div className="card-header">
        <div className="d-flex">
          <input
            type="text"
            className="form-control form-control-fav"
            placeholder="Search"
            onChange={(e) => {
              setSearchKey(e.target.value);
              setCurrentPage(1);
            }}
          />
          <div className="ms-auto"></div>
        </div>
      </div>
      <div className="card-body p-0">
        <table className="table table-hover m-0">
          <thead>
            <tr>
              <th>Name</th>
              <th>Agency</th>
              <th>Number of Users</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {data &&
              datatable(data).map((item, index) =>
                index >= (currentPage - 1) * perPage &&
                index < currentPage * perPage ? (
                  <tr key={index}>
                    <td>{item?.UserGroup}</td>
                    <td>{item?.Agent}</td>
                    <td>{item?.Users}</td>
                    <td>
                      <DropdownButton
                        as={ButtonGroup}
                        align={{ lg: "end" }}
                        variant="default"
                        title={`Actions`}
                      >
                        <Dropdown.Item
                          eventKey="1"
                          href={`/users/editgroup/${item?.Id}`}
                        >
                          Edit Group
                        </Dropdown.Item>
                        <Dropdown.Item
                          eventKey="2"
                          onClick={() => removeUserGroup(item?.Id)}
                        >
                          Delete Group
                        </Dropdown.Item>
                      </DropdownButton>
                    </td>
                  </tr>
                ) : null,
              )}
          </tbody>
        </table>
      </div>
      <div className="card-footer py-4 bg-white">
        <TablePagination
          data={datatable(data)}
          perPage={perPage}
          page={currentPage}
          onChangePage={setCurrentPage}
        />
      </div>
      {/*  */}
      <UserGroupRemoveModal
        id={removeUserGroupModal}
        isOpenModal={!!removeUserGroupModal}
        afterOpenModal={() => {}}
        closeModal={() => setRemoveUserGroupModal("")}
        reloadData={() => {
          setRemoveUserGroupModal("");
          reloadData();
        }}
      />
    </div>
  );
}
