import React from "react";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "560px",
    padding: "15px",
    marginTop: "20px",
    marginBottom: "20px",
  },
};

Modal.setAppElement("#root");

export default function ReportViewSubmissionModal({
  data = null,
  isOpenModal = false,
  afterOpenModal,
  closeModal,
}) {
  return (
    <Modal
      isOpen={isOpenModal}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="View Modal"
    >
      <div className="modal-header">
        <h5>Submission</h5>
      </div>
      <hr />
      <div
        className="modal-body"
        style={{ maxHeight: "70vh", overflow: "auto" }}
      >
        <h6>Dealership Info</h6>
        <p>Name: {data?.client.Name}</p>
        <p>Address: {data?.client.Address}</p>
        <p>City: {data?.client.City}</p>
        <p>State: {data?.client.State}</p>
        <p>Zip: {data?.client.Zip}</p>
        <p>Phone: {data?.client.Phone}</p>
        <p>DealershipContact: {data?.client.DealershipContact}</p>
        <p>ClientInventoryURL: {data?.client.ClientInventoryURL}</p>
        <p>UsedInventoryURL: {data?.client.UsedInventoryURL}</p>
        <p>CreditAppURL: {data?.client.CreditAppURL}</p>
        <p>Logo: {data?.client.Logo}</p>
        <p>Agency: {data?.client.Agency}</p>

        <h6>Office Hours</h6>
        <p>
          Monday: {Number(data?.client.Isopen_d1) == 1 ? "Open" : "Closed"}
          {data?.client.From_d1 != ""
            ? ", From " + data?.client.From_d1 + " To " + data?.client.To_d1
            : ""}
        </p>
        <p>
          Tuesday: {Number(data?.client.Isopen_d2) == 1 ? "Open" : "Closed"}
          {data?.client.From_d2 != ""
            ? ", From " + data?.client.From_d2 + " To " + data?.client.To_d2
            : ""}
        </p>
        <p>
          Wednesday: {Number(data?.client.Isopen_d3) == 1 ? "Open" : "Closed"}
          {data?.client.From_d3 != ""
            ? ", From " + data?.client.From_d3 + " To " + data?.client.To_d3
            : ""}
        </p>
        <p>
          Thursday: {Number(data?.client.Isopen_d4) == 1 ? "Open" : "Closed"}
          {data?.client.From_d4 != ""
            ? ", From " + data?.client.From_d4 + " To " + data?.client.To_d4
            : ""}
        </p>
        <p>
          Friday: {Number(data?.client.Isopen_d5) == 1 ? "Open" : "Closed"}
          {data?.client.From_d5 != ""
            ? ", From " + data?.client.From_d5 + " To " + data?.client.To_d5
            : ""}
        </p>
        <p>
          Saturday: {Number(data?.client.Isopen_d6) == 1 ? "Open" : "Closed"}
          {data?.client.From_d6 != ""
            ? ", From " + data?.client.From_d6 + " To " + data?.client.To_d6
            : ""}
        </p>
        <p>
          Sunday: {Number(data?.client.Isopen_d7) == 1 ? "Open" : "Closed"}
          {data?.client.From_d7 != ""
            ? ", From " + data?.client.From_d7 + " To " + data?.client.To_d7
            : ""}
        </p>

        <h6>CRM Info</h6>
        <p>Provider Name: {data?.client.provider_name}</p>
        <p>ADF Email: {data?.client.adf_email}</p>
        <p>Provider Email: {data?.client.provider_email}</p>

        <h6>User Info</h6>
        <p>Name: {data?.user.Name}</p>
        <p>Email: {data?.user.Email}</p>
        <p>Type: ADF Manager</p>
        <p>Department: {data?.user.Department}</p>
        <p>DefaultPhone: {data?.user.DefaultPhone}</p>
      </div>
      <hr />
      <div className="modal-footer mt-3">
        <button className="btn btn-outline-secondary" onClick={closeModal}>
          Close
        </button>
      </div>
    </Modal>
  );
}
