import React, { useEffect, useState } from "react";
import { Dropdown, SplitButton } from "react-bootstrap";
import helper from "../../../utils/helper";
import config from "../../../config/config";
import {
  BiChevronLeft,
  BiChevronRight,
  BiChevronsLeft,
  BiChevronsRight,
} from "react-icons/bi";
import moment from "moment";

export default function NewBlastLeadsTable({
  rawData,
  onClickPagination,
  splitlead,
  selectedLead,
  selectAll,
  unSelectAll,
  toggleSelect,
  onClickThisPageOnly,
}) {
  const [info, setInfo] = useState([]);
  const [pgConfig, setPgConfig] = useState({
    cpage: 1,
    tpage: 1,
    v1page: 1,
    v2page: 1,
  });

  useEffect(() => {
    if (rawData) {
      setInfo(rawData?.info);
      setPgConfig({
        cpage: Number(rawData?.cpage),
        tpage: Number(rawData?.tpage),
        v1page: Number(rawData?.v1page),
        v2page: Number(rawData?.v2page),
      });
      console.log(">>>selectedLead<<<", selectedLead);
    }
  }, [rawData]);

  return (
    <div className="blastleads-datatable">
      <table className="table table-hover">
        <thead>
          <tr>
            <th>
              <SplitButton
                key={"down"}
                drop={"down"}
                className="table-select-option"
                variant="outline-secondary btn-sm"
                onClick={() => selectAll()}
                title={`All`}
              >
                <Dropdown.Item
                  eventKey="1"
                  onClick={() => onClickThisPageOnly(pgConfig.cpage - 1)}
                >
                  Thia Page Only
                </Dropdown.Item>
                <Dropdown.Item eventKey="2" onClick={() => unSelectAll()}>
                  Unselect All
                </Dropdown.Item>
                <Dropdown.Item eventKey="3">Custom</Dropdown.Item>
              </SplitButton>
            </th>
            <th>Name</th>
            <th>Client</th>
            <th>Campaign</th>
            <th>Lead Created</th>
            <th>Last Incoming</th>
            <th>Last Outgoing</th>
            <th>Status</th>
            <th>Appt</th>
          </tr>
        </thead>
        <tbody>
          {info.map((item, index) => (
            <tr key={index} data-id={item.leadId}>
              <td data-id={splitlead[index]}>
                <input
                  type="checkbox"
                  checked={selectedLead[pgConfig.cpage - 1]
                    ?.split(",")
                    ?.includes(item.leadId)}
                  onChange={() => toggleSelect(pgConfig.cpage - 1, item.leadId)}
                />
              </td>
              <td>
                <div className="d-flex align-items-center">
                  <div className="lead_img_wrap">
                    <a
                      className="lead_img"
                      href={`${config.smsPath}/s/${item.Responsekey}`}
                    >
                      <img
                        src={
                          Number(item.Ismediaimage) === 1
                            ? `${item.Mediaimage}`
                            : `${
                                config.assetPath
                              }/images/material-letter-icons/png/${item.Name.charAt(
                                0,
                              ).toUpperCase()}.png`
                        }
                        width={40}
                        height={40}
                        alt=""
                      />
                    </a>
                    <span className="message-icon">
                      <img
                        src={
                          Number(item.type) === 3
                            ? `${config.assetPath}/images/messenger.webp`
                            : `${config.assetPath}/images/sms.svg`
                        }
                        alt=""
                      />
                    </span>
                  </div>
                  <div className="d-flex flex-column ps-2">
                    <a
                      className="text-decoration-none text-dark"
                      href={`${config.smsPath}/s/${item.Responsekey}`}
                    >
                      {item.Name}
                    </a>
                    <small className="text-secondary">{item.Phone}</small>
                  </div>
                </div>
              </td>
              <td>{item.Client}</td>
              <td>{item.Campaign}</td>
              <td>
                {!!item.FirstContact && (
                  <abbr className="timeago" title={item.FirstContact}>
                    {moment(item.FirstContact).fromNow()}
                  </abbr>
                )}
              </td>
              <td>
                {!!item.Lastincomming && (
                  <abbr className="timeago" title={item.Lastincomming}>
                    {moment(item.Lastincomming).fromNow()}
                  </abbr>
                )}
              </td>
              <td>
                {!!item.Lastoutgoing && (
                  <abbr className="timeago" title={item.Lastoutgoing}>
                    {moment(item.Lastoutgoing).fromNow()}
                  </abbr>
                )}
              </td>
              <td>
                <span
                  className={`label ${helper.getLabelIconClass(item.Label)}`}
                >
                  {helper.getLabelText(item.Label)}
                </span>
              </td>
              <td>{helper.getApptsStatus(item.Appt)}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="table-pagination">
        <div className="">
          Page {pgConfig.cpage} of {pgConfig.tpage}
        </div>
        <div className="pagination">
          <button
            className={`btn-page`}
            disabled={Number(pgConfig.cpage) <= 1}
            onClick={() => onClickPagination(1)}
          >
            <BiChevronsLeft />
          </button>
          <button
            className={`btn-page`}
            disabled={Number(pgConfig.cpage) <= 1}
            onClick={() => onClickPagination(Number(pgConfig.cpage) - 1)}
          >
            <BiChevronLeft />
          </button>
          {[...Array(Number(pgConfig.tpage))].map((item, index) =>
            index + 1 >= Number(pgConfig.v1page) &&
            index + 1 <= Number(pgConfig.v2page) ? (
              <button
                key={index}
                className={`btn-page${
                  Number(Number(pgConfig.cpage)) === index + 1 ? " active" : ""
                }`}
                onClick={() => onClickPagination(index + 1)}
              >
                {index + 1}
              </button>
            ) : null,
          )}
          <button
            className={`btn-page`}
            disabled={Number(pgConfig.cpage) >= Number(pgConfig.tpage)}
            onClick={() => onClickPagination(Number(pgConfig.cpage) + 1)}
          >
            <BiChevronRight />
          </button>
          <button
            className={`btn-page`}
            disabled={Number(pgConfig.cpage) >= Number(pgConfig.tpage)}
            onClick={() => onClickPagination(Number(pgConfig.tpage))}
          >
            <BiChevronsRight />
          </button>
        </div>
      </div>
    </div>
  );
}
