import React, { createContext, useContext } from "react";
import { AxiosContext } from "./axios_context";
import config from "../config/config";

const ReportContext = createContext(undefined);

const ReportContextProvider = (props) => {
  const { axiosInstance, setLoading } = useContext(AxiosContext);

  const getSmsReportApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/reports/reports_smsreport`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
      });
  };

  const getleadbyclientApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/reports/reports_getleadbyclient`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
      });
  };

  const getleadbydealerApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/reports/reports_getleadbydealer`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
      });
  };

  const smsajaxrequestApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/reports/reports_smsajaxrequest`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
      });
  };

  const getReportLeadsApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/reports/reports_leads`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
      });
  };

  const adminsmsreportApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/reports/reports_adminsmsreport`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
      });
  };

  const ajaxsmsreportApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/reports/reports_ajaxsmsreport`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
      });
  };

  const reportLeadSourcesApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/reports/reports_leadsource`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
      });
  };

  const ajaxleadsourceApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/reports/reports_ajaxleadsource`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
      });
  };

  const userstatsApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/reports/reports_userstats`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
      });
  };

  const ajaxuserstatsApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/reports/reports_ajaxuserstats`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
      });
  };

  const getHotLeadsApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/reports/reports_hotleads`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
      });
  };

  const filterHotLeadsApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/reports/reports_filterhotleads`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
      });
  };

  const getOnboardingDataApi = (params) => {
    const data = params;
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/reports/reports_onboarding`,
      data,
    })
      .then((response) => {
        const data = response.data;
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
      });
  };

  const viewsubmissionModalApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/reports/reports_viewsubmissionmodal`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
      });
  };

  const sendonboardingApi = (params) => {
    const data = params;
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/reports/reports_sendonboarding`,
      data,
    })
      .then((response) => {
        const data = response.data;
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
      });
  };

  const providerValue = {
    getSmsReportApi,
    getleadbyclientApi,
    getleadbydealerApi,
    smsajaxrequestApi,
    getReportLeadsApi,
    adminsmsreportApi,
    ajaxsmsreportApi,
    reportLeadSourcesApi,
    ajaxleadsourceApi,
    userstatsApi,
    ajaxuserstatsApi,
    getHotLeadsApi,
    filterHotLeadsApi,
    getOnboardingDataApi,
    viewsubmissionModalApi,
    sendonboardingApi,
  };

  return (
    <ReportContext.Provider value={providerValue}>
      {props.children}
    </ReportContext.Provider>
  );
};

export { ReportContext, ReportContextProvider };
