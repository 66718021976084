import React, { useContext, useEffect, useRef, useState } from "react";
import { AuthContext, SharedContext, UserContext } from "../../../context";
import { Switch } from "@mui/material";
import DualListBox from "react-dual-listbox";
import {
  RxChevronDown,
  RxChevronLeft,
  RxChevronRight,
  RxChevronUp,
  RxDoubleArrowDown,
  RxDoubleArrowLeft,
  RxDoubleArrowRight,
  RxDoubleArrowUp,
} from "react-icons/rx";
import { useNavigate } from "react-router-dom";

export default function UserNewForm() {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const formRef = useRef(undefined);
  const { getNewUserPageDataApi, createUserApi } = useContext(UserContext);
  const { uniquemailcheckApi } = useContext(SharedContext);
  //
  const [userType, setUserType] = useState("");
  const [manager, setManager] = useState([]);
  const [client, setClient] = useState([]);
  const [usergroup, setUsergroup] = useState([]);
  const [executive, setExecutive] = useState([]);
  const [isSuperAdmin, setIsSuperAdmin] = useState(undefined);
  const agency = [];
  const [isagentchain, setIsagentchain] = useState(undefined);
  //
  const [postData, setPostData] = useState({
    userId: user.Id,
    Type: 5,
    Name: "",
    Email: "",
    Password: "",
    Department: "",
    officephone: "",
    mobilephone: "",
    defaultphone: "",
    // Blast: true,
    Isblast: 1,
    manager: "",
    accountexecutive: "",
    // Salesmenbarcode: false,
    Issalesmen: 0,
    // isnewmail: true,
    Ismail: 1,
    UserGroup: "",
    private: "",
    clientlistbox: [],
  });
  const [showEmailError, setShowEmailError] = useState(false);

  useEffect(() => {
    getNewUserPageData();
  }, []);

  const getNewUserPageData = async () => {
    const data = await getNewUserPageDataApi({ userId: user.Id });
    if (data.status === "ok") {
      const res = data.response;
      setUserType(res?.userType);
      setManager(res?.manager);
      setClient(res?.client);
      setUsergroup(res?.user_group);
      setExecutive(res?.executive);
      setIsSuperAdmin(res?.isSuperAdmin);
      setIsagentchain(res?.isagentchain);
      //
      let _postData = { ...postData };
      _postData.userId = user.Id;
      _postData.Type = 5;
      // _postData.manager = res?.manager.filter(item => item.)
      _postData.private = res?.sagent;
      if (Number(res?.isagentchain) === 1 || Number(res?.userType === 2)) {
        _postData.private1 = res?.sagent;
      }
      _postData.clientlistbox = res?.client
        .filter((item) => item.Id === res?.cid)
        .map((item) => {
          return { value: `${item.Id}_${item.Ctype}`, label: item.Name };
        });
      setPostData(_postData);
    }
  };

  const checkusermail = async () => {
    if (!postData.Email) {
      return;
    }
    const data = await uniquemailcheckApi({ email: postData.Email });
    if (data.status === "ok") {
      const res = data.response;
      if (Number(res?.mail) === 0) {
        setShowEmailError(true);
        setPostData({ ...postData, Email: "" });
      }
    }
  };

  const onSave = async () => {
    if (!formRef.current.reportValidity()) {
      return;
    }
    const data = await createUserApi(postData);
    if (data.status === "ok") {
      navigate(`/users`);
    }
  };

  return (
    <form ref={formRef}>
      <div className="d-flex align-items-center justify-content-between">
        <h6 className="tab-tile">User Info</h6>
      </div>
      <div className="row mt-2">
        <div className="col col-sm-4">
          <div className="form-group mb-2">
            <label className="mb-2">Name</label>
            <input
              type="text"
              className="form-control"
              name="Name"
              value={postData?.Name}
              onChange={(e) =>
                setPostData({ ...postData, Name: e.target.value })
              }
              required={true}
            />
          </div>
        </div>
        <div className="col col-sm-4">
          <div className="form-group mb-2">
            <label className="mb-2">Email</label>
            <input
              type="email"
              className="form-control"
              name="Email"
              value={postData?.Email}
              onChange={(e) =>
                setPostData({ ...postData, Email: e.target.value })
              }
              onFocus={() => setShowEmailError(false)}
              onBlur={() => checkusermail()}
              required={true}
            />
            <div
              className={`text-danger mt-1${showEmailError ? "" : " d-none"}`}
            >
              This email address already exists, please choose another email
              address.
            </div>
          </div>
        </div>
        <div className="col col-sm-4">
          <div className="form-group mb-2">
            <label className="mb-2">Password</label>
            <input
              type="password"
              className="form-control"
              name="Password"
              value={postData?.Password}
              onChange={(e) =>
                setPostData({ ...postData, Password: e.target.value })
              }
              required={true}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col col-sm-4">
          <div className="form-group mb-2">
            <label className="mb-2">Title</label>
            <input
              type="text"
              className="form-control"
              name="Department"
              value={postData?.Department}
              onChange={(e) =>
                setPostData({ ...postData, Department: e.target.value })
              }
            />
          </div>
        </div>
        <div
          className={`col col-sm-4${
            Number(postData?.Type) === 7 || Number(postData?.Type) === 8
              ? ""
              : " d-none"
          }`}
        >
          <div id="officephonecontainer" className="form-group mb-2">
            <label className="mb-2">Office Phone</label>
            <input
              type="text"
              className="form-control"
              name="officephone"
              value={postData?.officephone}
              onChange={(e) =>
                setPostData({ ...postData, officephone: e.target.value })
              }
            />
          </div>
        </div>
        <div className="col col-sm-4">
          <div className="form-group mb-2">
            <label className="mb-2">Mobile Phone</label>
            <input
              type="text"
              className="form-control"
              name="mobilephone"
              value={postData?.mobilephone}
              onChange={(e) =>
                setPostData({ ...postData, mobilephone: e.target.value })
              }
            />
          </div>
        </div>
        <div
          id="defaultphonecontainer"
          className={`col col-sm-4${
            Number(postData?.Type) === 7 || Number(postData?.Type) === 8
              ? ""
              : " d-none"
          }`}
        >
          <div className="form-group mb-2">
            <label className="mb-2">Send Calls to</label>
            <select
              className="form-select"
              name="defaultphone"
              value={postData?.defaultphone}
              onChange={(e) =>
                setPostData({ ...postData, defaultphone: e.target.value })
              }
            >
              <option value="3">Mobile Phone</option>
              <option value="2">Office Phone</option>
              <option value="1">Main Line</option>
            </select>
          </div>
        </div>
      </div>
      {Number(postData.Type) === 1 ||
      Number(postData.Type) === 0 ||
      Number(postData.Type) === 5 ||
      Number(postData.Type) === 7 ||
      Number(postData.Type) === 8 ? (
        <></>
      ) : (
        <div id="managerContainer" className={`row`}>
          <div className="col-12">
            <div className="form-group mb-2">
              <label className="mb-2">Account Manager</label>
              <select
                className="form-select"
                name="manager"
                value={postData?.manager}
                onChange={(e) =>
                  setPostData({ ...postData, manager: e.target.value })
                }
                required={true}
              >
                <option value="">Please select</option>
                {manager.map((item, index) => (
                  <option value={item.Id} key={index}>
                    {item.Name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      )}
      <div
        id="accountExecutive"
        className={`row${
          Number(postData.Type) === 1 ||
          Number(postData.Type) === 0 ||
          Number(postData.Type) === 5 ||
          Number(postData.Type) === 7 ||
          Number(postData.Type) === 8
            ? " d-none"
            : ""
        }`}
      >
        <div className="col-12">
          <div className="form-group mb-2">
            <label className="mb-2">Account Executive</label>
            <select
              className="form-select"
              name="accountexecutive"
              value={postData?.accountexecutive}
              onChange={(e) =>
                setPostData({ ...postData, accountexecutive: e.target.value })
              }
            >
              <option value="">Please select</option>
              {executive.map((item, index) => (
                <option value={item.Id} key={index}>
                  {item.Name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div className="row">
        <div
          id="salesmanbarcode_container"
          className={`col-sm-4${
            Number(postData?.Type) === 7 ||
            Number(postData?.Type) === 5 ||
            Number(postData?.Type) === 8
              ? " d-none"
              : ""
          }`}
        >
          <div className="form-group mb-2">
            <label className="w-100 mb-2">Salesmen Barcode</label>
            <Switch
              value={postData?.Issalesmen === 1}
              onChange={(e) => {
                console.log(e);
                setPostData({
                  ...postData,
                  Issalesmen: postData.Issalesmen === 1 ? 0 : 1,
                });
              }}
            />
          </div>
        </div>
        <div
          id="newuser_container"
          className={`col-sm-4${
            Number(postData?.Type) === 7 ||
            Number(postData?.Type) === 5 ||
            Number(postData?.Type) === 8
              ? " d-none"
              : ""
          }`}
        >
          <div className="form-group mb-2">
            <label className="w-100 mb-2">New User Email</label>
            <Switch
              value={postData?.Ismail === 1}
              onChange={(e) => {
                console.log(e);
                setPostData({
                  ...postData,
                  Ismail: postData.Ismail === 1 ? 0 : 1,
                });
              }}
            />
          </div>
        </div>
        <div className="col col-sm-4">
          <div className="form-group mb-2">
            <label className="mb-2">Type</label>
            <select
              className="form-select"
              name="Type"
              value={postData?.Type}
              onChange={(e) =>
                setPostData({ ...postData, Type: e.target.value })
              }
            >
              {isSuperAdmin ? (
                <>
                  <option value="1">Admin</option>
                  <option value="0">Account Executive</option>
                  <option value="2">Manager</option>
                  <option value="4">Match Manager</option>
                  <option value="8">DF Manager</option>
                  <option value="5">Agency Admin</option>
                  <option value="7">Agent</option>
                </>
              ) : Number(userType) < 2 ? (
                <>
                  <option value="2">Manager</option>
                  <option value="4">Match Manager</option>
                  <option value="8">DF Manager</option>
                  <option value="5">Agency Admin</option>
                  <option value="7">Agent</option>
                </>
              ) : (
                <>
                  <option value="6">Manager</option>
                </>
              )}
              <option value="3">Salesman</option>
            </select>
          </div>
        </div>
      </div>
      <div className="row">
        <div id="usergroupcontainer" className="col-sm-4 d-none">
          <div className="form-group mb-2">
            <label className="mb-2">Agency Assignment</label>
            <select
              className="form-select"
              disabled={Number(isagentchain) === 1 || Number(userType === 2)}
              name={
                Number(isagentchain) === 1 || Number(userType === 2)
                  ? "private1"
                  : "private"
              }
              value={postData?.private}
              onChange={(e) =>
                setPostData({ ...postData, private: e.target.value })
              }
            >
              <option value="">N/A</option>
              {agency.map((item, index) => (
                <option value={item.Id} key={index}>
                  {item.Name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div id="usergroupcontainer" className="col-sm-4 d-none">
          <div className="form-group mb-2">
            <label className="mb-2">User Group</label>
            <select
              className="form-select"
              name="UserGroup"
              value={postData?.UserGroup}
              onChange={(e) =>
                setPostData({ ...postData, UserGroup: e.target.value })
              }
            >
              <option value=""></option>
              {usergroup.map((item, index) => (
                <option value={item.Id} key={index}>
                  {item.UserGroup}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div
        className={`client-list${
          Number(postData?.Type) === 7 ||
          Number(postData?.Type) === 8 ||
          Number(postData?.Type) === 2 ||
          Number(postData?.Type) === 3 ||
          Number(postData?.Type) === 6
            ? ""
            : " d-none"
        }`}
        id="client-list"
      >
        <DualListBox
          canFilter
          options={
            client.map((item) => {
              return { label: item.Name, value: `${item.Id}_${item.Ctype}` };
            }) || []
          }
          simpleValue={false}
          selected={postData?.clientlistbox}
          onChange={(value) =>
            setPostData({ ...postData, clientlistbox: value })
          }
          icons={{
            moveLeft: <RxChevronLeft />,
            moveAllLeft: [
              <RxDoubleArrowLeft key={0} />,
              // <RxDoubleArrowLeft key={1} />,
            ],
            moveRight: <RxChevronRight />,
            moveAllRight: [
              <RxDoubleArrowRight key={0} />,
              // <RxDoubleArrowRight key={1} />,
            ],
            moveDown: <RxChevronDown />,
            moveUp: <RxChevronUp />,
            moveTop: <RxDoubleArrowUp />,
            moveBottom: <RxDoubleArrowDown />,
          }}
        />
      </div>
      <hr className="mt-5" />
      <div className="d-flex align-items-center justify-content-center">
        <button
          type="button"
          className="btn btn-primary me-1"
          onClick={() => onSave()}
        >
          Save
        </button>
        <button type="button" className="btn btn-outline-secondary ms-1">
          Close
        </button>
      </div>
    </form>
  );
}
