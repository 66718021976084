import React, { createContext, useContext, useEffect, useState } from "react";
import { AuthContext } from "./auth_context";
import { firebasedb } from "../utils/firebase";
import {
  ref,
  onChildAdded,
  onChildRemoved,
  remove,
  get,
} from "firebase/database";

const FirebaseContext = createContext(undefined);

const FirebaseContextProvider = (props) => {
  const { user } = useContext(AuthContext);

  const conversationRef = ref(firebasedb, "/Conversation/");
  const conversationLockRef = ref(firebasedb, "/Conversation_lock/");
  const leadRef = ref(firebasedb, "/Lead/");
  const newLeadRef = ref(firebasedb, "/New_Lead/");
  const leadRemoveRef = ref(firebasedb, "/Lead_remove/");
  const userAssignRef = ref(firebasedb, "/User_assign/");
  const allRef = ref(firebasedb, "/");

  const [typingLeads, setTypingLeads] = useState([]);

  useEffect(() => {
    if (user && user?.Id) {
      const interval = setInterval(() => {
        listenRef();
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [user]);

  const listenRef = () => {
    console.log("listen the snapshot");
    snapshotConversation();
  };

  const snapshotConversation = () => {
    onChildAdded(conversationRef, function (snapshot) {
      const data = snapshot.val();
      const items = [...typingLeads];
      const filterItems = items.filter((i) => i.leadid === data.leadid);
      if (filterItems.length === 0) {
        items.unshift(data);
        setTypingLeads(items);
      }
    });
    // onChildChanged(conversationRef, function (snapshot) {
    //   console.log("🚀🚀🚀 : Firebase conversation database : changed data =>");
    //   const data = snapshot.val();
    //   console.log(data);
    // });
    // onChildMoved(conversationRef, function (snapshot) {
    //   console.log("🚀🚀🚀 : Firebase conversation database : moved data =>");
    //   const data = snapshot.val();
    //   console.log(data);
    // });
    onChildRemoved(conversationRef, function (snapshot) {
      const data = snapshot.val();
      const items = [...typingLeads];
      const filterItems = items.filter((i) => i.leadid != data.leadid);
      setTypingLeads(filterItems);
    });
  };

  const getConversationLocks = async () => {
    const snapshot = await get(conversationLockRef);
    const items = [];
    snapshot.forEach((childSnapshot) => {
      const data = childSnapshot.val();
      items.push(data);
    });
    return items;
  };

  const removeLiveActivity = (userId) => {
    if (userId) {
      remove(conversationRef, userId);
    }
  };

  const providerValue = {
    allRef,
    leadRef,
    conversationRef,
    conversationLockRef,
    leadRemoveRef,
    newLeadRef,
    userAssignRef,
    typingLeads,
    removeLiveActivity,
    getConversationLocks,
  };

  return (
    <FirebaseContext.Provider value={providerValue}>
      {props.children}
    </FirebaseContext.Provider>
  );
};

export { FirebaseContext, FirebaseContextProvider };
