import FlashOnOutlinedIcon from "@mui/icons-material/FlashOnOutlined";
import React, { useEffect, useState, useRef } from "react";

export default function SmartFieldsInput({ onSelect }) {
  const wrapperRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [activeLItem, setActiveLItem] = useState("");
  const [activeRItem, setActiveRItem] = useState("");

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        // alert("You clicked outside of me!");
        setOpen(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef, setOpen]);

  // useEffect(() => {
  //   loadGifData();
  // }, [open]);

  return (
    <div className="smartfields" ref={wrapperRef}>
      <FlashOnOutlinedIcon
        size={18}
        className="me-1"
        title="Smart Fields"
        onClick={() => setOpen(!open)}
      />
      {open && (
        <div className="smartfields-dropdown shadow-sm rounded">
          <div className="smartfields-header">
            <span>Smart Fields</span>
            <span>{activeRItem ? ` > ${activeRItem}` : ""}</span>
          </div>
          <div className="smartfields-body">
            <div className="row">
              <div className="col-6 pe-1">
                <ul className="left-items">
                  <li
                    className={`left-item${
                      activeLItem === "Lead" ? " fw-bold" : ""
                    }`}
                    onMouseOver={() => setActiveLItem("Lead")}
                    // onMouseOut={() => setActiveLItem('')}
                  >
                    Lead
                  </li>
                  <li
                    className={`left-item${
                      activeLItem === "Dealership" ? " fw-bold" : ""
                    }`}
                    onMouseOver={() => setActiveLItem("Dealership")}
                    // onMouseOut={() => setActiveLItem('')}
                  >
                    Dealership
                  </li>
                  <li
                    className={`left-item${
                      activeLItem === "Vehicle of Interest" ? " fw-bold" : ""
                    }`}
                    onMouseOver={() => setActiveLItem("Vehicle of Interest")}
                    // onMouseOut={() => setActiveLItem('')}
                  >
                    Vehicle of Interest
                  </li>
                  <li
                    className={`left-item${
                      activeLItem === "Current Vehicle" ? " fw-bold" : ""
                    }`}
                    onMouseOver={() => setActiveLItem("Current Vehicle")}
                    // onMouseOut={() => setActiveLItem('')}
                  >
                    Current Vehicle
                  </li>
                </ul>
              </div>
              <div className="col-6 ps-1">
                {activeLItem === "Lead" && (
                  <ul className="right-items">
                    <li
                      className="right-item"
                      onMouseOver={() => setActiveRItem("First Name")}
                      // onMouseOut={() => setActiveRItem('')}
                      onClick={() => onSelect("[fname]")}
                    >
                      First Name
                    </li>
                    <li
                      className="right-item"
                      onMouseOver={() => setActiveRItem("Last Name")}
                      // onMouseOut={() => setActiveRItem('')}
                      onClick={() => onSelect("[lname]")}
                    >
                      Last Name
                    </li>
                    <li
                      className="right-item"
                      onMouseOver={() => setActiveRItem("Full Name")}
                      // onMouseOut={() => setActiveRItem('')}
                      onClick={() => onSelect("[fullname]")}
                    >
                      Full Name
                    </li>
                    <li
                      className="right-item"
                      onMouseOver={() => setActiveRItem("Lead Source")}
                      // onMouseOut={() => setActiveRItem('')}
                      onClick={() => onSelect("[leadsource]")}
                    >
                      Lead Source
                    </li>
                  </ul>
                )}
                {activeLItem === "Dealership" && (
                  <ul className="right-items">
                    <li
                      className="right-item"
                      onMouseOver={() => setActiveRItem("Name")}
                      // onMouseOut={() => setActiveRItem('')}
                      onClick={() => onSelect("[dealershipname]")}
                    >
                      Name
                    </li>
                    <li
                      className="right-item"
                      onMouseOver={() => setActiveRItem("Address")}
                      // onMouseOut={() => setActiveRItem('')}
                      onClick={() => onSelect("[address]")}
                    >
                      Address
                    </li>
                    <li
                      className="right-item"
                      onMouseOver={() => setActiveRItem("Hours")}
                      // onMouseOut={() => setActiveRItem('')}
                      onClick={() => onSelect("[hours]")}
                    >
                      Hours
                    </li>
                    <li
                      className="right-item"
                      onMouseOver={() => setActiveRItem("Contact")}
                      // onMouseOut={() => setActiveRItem('')}
                      onClick={() => onSelect("[appt_contact]")}
                    >
                      Contact
                    </li>
                    <li
                      className="right-item"
                      onMouseOver={() => setActiveRItem("Phone")}
                      // onMouseOut={() => setActiveRItem('')}
                      onClick={() => onSelect("[phonenumber]")}
                    >
                      Phone
                    </li>
                    <li
                      className="right-item"
                      onMouseOver={() => setActiveRItem("Inventory")}
                      // onMouseOut={() => setActiveRItem('')}
                      onClick={() => onSelect("[inventory]")}
                    >
                      Inventory
                    </li>
                    <li
                      className="right-item"
                      onMouseOver={() => setActiveRItem("Website")}
                      // onMouseOut={() => setActiveRItem('')}
                      onClick={() => onSelect("[website]")}
                    >
                      Website
                    </li>
                    <li
                      className="right-item"
                      onMouseOver={() => setActiveRItem("Credit Application")}
                      // onMouseOut={() => setActiveRItem('')}
                      onClick={() => onSelect("[creditapp]")}
                    >
                      Credit Application
                    </li>
                  </ul>
                )}
                {activeLItem === "Vehicle of Interest" && (
                  <ul className="right-items">
                    <li
                      className="right-item"
                      onMouseOver={() => setActiveRItem("Year")}
                      // onMouseOut={() => setActiveRItem('')}
                      onClick={() => onSelect("[interest_year]")}
                    >
                      Year
                    </li>
                    <li
                      className="right-item"
                      onMouseOver={() => setActiveRItem("Make")}
                      // onMouseOut={() => setActiveRItem('')}
                      onClick={() => onSelect("[interest_make]")}
                    >
                      Make
                    </li>
                    <li
                      className="right-item"
                      onMouseOver={() => setActiveRItem("Model")}
                      // onMouseOut={() => setActiveRItem('')}
                      onClick={() => onSelect("[interest_model]")}
                    >
                      Model
                    </li>
                  </ul>
                )}
                {activeLItem === "Current Vehicle" && (
                  <ul className="right-items">
                    <li
                      className="right-item"
                      onMouseOver={() => setActiveRItem("Year")}
                      // onMouseOut={() => setActiveRItem('')}
                      onClick={() => onSelect("[current_year]")}
                    >
                      Year
                    </li>
                    <li
                      className="right-item"
                      onMouseOver={() => setActiveRItem("Make")}
                      // onMouseOut={() => setActiveRItem('')}
                      onClick={() => onSelect("[current_make]")}
                    >
                      Make
                    </li>
                    <li
                      className="right-item"
                      onMouseOver={() => setActiveRItem("Model")}
                      // onMouseOut={() => setActiveRItem('')}
                      onClick={() => onSelect("[current_model]")}
                    >
                      Model
                    </li>
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
