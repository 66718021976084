import React, { useContext, useEffect, useState } from "react";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import "react-calendar/dist/Calendar.css";
import {
  LiaCalendarCheck,
  LiaCalendarTimes,
  LiaUserClockSolid,
} from "react-icons/lia";
import { RxClock, RxCounterClockwiseClock } from "react-icons/rx";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import {
  AppointmentsCalendarView,
  AppointmentsTableView,
  SectionLoader,
} from "../../components";
import { AppointmentContext, AuthContext } from "../../context";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";

export default function AppointmentsPage() {
  const [showLoading, setShowLoading] = useState(false);
  const { user } = useContext(AuthContext);
  const {
    getAppointmentMetaData,
    clientlist,
    assignuserlist,
    leadsourcelist,
    campaignlist,
    getAppointmentAnalysisData,
    analysisData,
    apptReport,
    apptPagination,
    isdealerfunneluser,
    deleteAppointment,
  } = useContext(AppointmentContext);
  const [clientId, setClientId] = useState("");
  const [assignmentId, setAssignmentId] = useState("");
  const [leadsourceId, setLeadsourceId] = useState("");
  const [statusId, setStatusId] = useState("");
  const [campaignId, setCampaignId] = useState("");
  const [sfields, setSfields] = useState(3);
  const [sflag, setSflag] = useState(1);
  const [startDate, setStartDate] = useState(moment().startOf("month"));
  const [endDate, setEndDate] = useState(moment().endOf("month"));
  const [searchKey, setSearchKey] = useState("");
  const [dateLabel, setDateLabel] = useState("This Month");

  useEffect(() => {
    if (user) {
      getAppointmentMetaData({ userId: user.Id });
      setSflag(1);
      setSfields(3);
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      getAppointmentAnalysisDataFirst();
    }
  }, [
    user,
    clientId,
    assignmentId,
    leadsourceId,
    statusId,
    campaignId,
    sfields,
    sflag,
    startDate,
    endDate,
  ]);

  const getAppointmentAnalysisDataFirst = async () => {
    setShowLoading(true);
    const res = await getAppointmentAnalysisData({
      userId: user.Id,
      clientId: clientId,
      assignmentId: assignmentId,
      leadsourceId: leadsourceId,
      statusId: statusId,
      campaignId: campaignId,
      sfields: sfields,
      sflag: sflag,
      page: 1,
      searchKey: searchKey,
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
      pageLoading: false,
    });
    if (res.status === "ok") {
      setShowLoading(false);
    }
  };

  const onChangePage = async (page) => {
    const res = await getAppointmentAnalysisData({
      userId: user.Id,
      clientId: clientId,
      assignmentId: assignmentId,
      leadsourceId: leadsourceId,
      statusId: statusId,
      campaignId: campaignId,
      sfields: sfields,
      sflag: sflag,
      page: page,
      searchKey: searchKey,
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
      pageLoading: true,
    });
    if (res.status === "ok") {
      setShowLoading(false);
    }
  };

  const reloadData = async () => {
    // setShowLoading(true);
    const res = await getAppointmentAnalysisData({
      userId: user.Id,
      clientId: clientId,
      assignmentId: assignmentId,
      leadsourceId: leadsourceId,
      statusId: statusId,
      campaignId: campaignId,
      sfields: sfields,
      sflag: sflag,
      page: apptPagination.cpage,
      searchKey: searchKey,
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
      pageLoading: true,
    });
    if (res.status === "ok") {
      setShowLoading(false);
    }
  };

  const deleteAppts = async (ApptsId) => {
    console.log("== ApptsId ==", ApptsId);
    const res = window.confirm(
      "Are you sure, you want  to move this appointment into the trash?",
    );
    if (res) {
      const data = await deleteAppointment({
        userId: user.Id,
        responseId: ApptsId,
      });
      if (data.status === "ok") {
        reloadData();
      }
    }
  };

  const handleCallback = (start, end) => {
    const startD = moment(start).format("YYYY-MM-DD");
    const endD = moment(end).format("YYYY-MM-DD");
    setStartDate(startD);
    setEndDate(endD);
    if (
      startD === moment().format("YYYY-MM-DD") &&
      endD === moment().format("YYYY-MM-DD")
    ) {
      setDateLabel("Today");
    } else if (
      startD === moment().subtract(1, "days").format("YYYY-MM-DD") &&
      endD === moment().subtract(1, "days").format("YYYY-MM-DD")
    ) {
      setDateLabel("Yesterday");
    } else if (
      startD === moment().subtract(6, "days").format("YYYY-MM-DD") &&
      endD === moment().format("YYYY-MM-DD")
    ) {
      setDateLabel("Last 7 Days");
    } else if (
      startD === moment().subtract(29, "days").format("YYYY-MM-DD") &&
      endD === moment().format("YYYY-MM-DD")
    ) {
      setDateLabel("Last 30 Days");
    } else if (
      startD === moment().startOf("month").format("YYYY-MM-DD") &&
      endD === moment().endOf("month").format("YYYY-MM-DD")
    ) {
      setDateLabel("This Month");
    } else if (
      startD ===
        moment().subtract(1, "month").startOf("month").format("YYYY-MM-DD") &&
      endD === moment().subtract(1, "month").endOf("month").format("YYYY-MM-DD")
    ) {
      setDateLabel("Last Month");
    } else {
      setDateLabel(
        `${moment(start).format("MM/DD/YYYY")} - ${moment(end).format(
          "MM/DD/YYYY",
        )}`,
      );
    }
    // onChangeFilter(searchKey, startD, endD);
  };

  return (
    <main className="container py-4 position-relative">
      <div className="d-flex align-items-center">
        <div className="section-title">
          <CalendarMonthIcon
            color="white"
            fontSize="small"
            sx={{ marginRight: "5px" }}
          />
          Appointments
        </div>
        <DateRangePicker
          initialSettings={{
            startDate: moment(startDate).toDate(),
            endDate: moment(endDate).toDate(),
            ranges: {
              Today: [moment().toDate(), moment().toDate()],
              Yesterday: [
                moment().subtract(1, "days").toDate(),
                moment().subtract(1, "days").toDate(),
              ],
              "Last 7 Days": [
                moment().subtract(6, "days").toDate(),
                moment().toDate(),
              ],
              "Last 30 Days": [
                moment().subtract(29, "days").toDate(),
                moment().toDate(),
              ],
              "This Month": [
                moment().startOf("month").toDate(),
                moment().endOf("month").toDate(),
              ],
              "Last Month": [
                moment().subtract(1, "month").startOf("month").toDate(),
                moment().subtract(1, "month").endOf("month").toDate(),
              ],
            },
          }}
          onCallback={handleCallback}
        >
          <button type="button" className="btn form-daterangepicker ms-auto">
            {dateLabel}
          </button>
        </DateRangePicker>
      </div>
      <div className="row mt-4">
        <div className="col col-12 col-sm-12 col-md">
          <select
            className="form-select mb-2"
            value={clientId}
            onChange={(e) => setClientId(e.target.value)}
          >
            <option value="">Select Clients</option>
            {clientlist &&
              clientlist.map((item, index) => (
                <option value={item.Id} key={index}>
                  {item.Name}
                </option>
              ))}
          </select>
        </div>
        <div className="col col-12 col-sm-12 col-md">
          <select
            className="form-select mb-2"
            value={assignmentId}
            onChange={(e) => setAssignmentId(e.target.value)}
          >
            <option value="">Select Assignments</option>
            {assignuserlist &&
              assignuserlist.map((item, index) => (
                <option value={item.Id} key={index}>
                  {item.Name}
                </option>
              ))}
          </select>
        </div>
        <div className="col col-12 col-sm-12 col-md">
          <select
            className="form-select mb-2"
            value={leadsourceId}
            onChange={(e) => setLeadsourceId(e.target.value)}
          >
            <option value="">All Lead Source</option>
            {leadsourcelist &&
              leadsourcelist.map((item, index) => (
                <option value={item.Id} key={index}>
                  {item.Name}
                </option>
              ))}
          </select>
        </div>
        <div className="col col-12 col-sm-12 col-md">
          <select
            className="form-select mb-2"
            value={statusId}
            onChange={(e) => setStatusId(e.target.value)}
          >
            <option value="">Select Status</option>
            <option value="1">Pending</option>
            <option value="2">Showed</option>
            <option value="3">No Show</option>
            <option value="4">Rescheduled</option>
          </select>
        </div>
        <div className="col col-12 col-sm-12 col-md">
          <select
            className="form-select mb-2"
            value={campaignId}
            onChange={(e) => setCampaignId(e.target.value)}
          >
            <option value="">All Campaigns</option>
            {campaignlist &&
              campaignlist.map((item, index) => (
                <option
                  value={item.Id}
                  key={index}
                >{`${item.Job}-${item.Name}`}</option>
              ))}
          </select>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col col-12 col-sm-12 col-md">
          <div className="h-stats card mb-2">
            <RxClock
              className="icon mx-2"
              style={{ color: "#0051ff", fontSize: "28px" }}
            />
            <div className="d-flex flex-column justify-content-center ms-2">
              <h3>{analysisData?.Pending || 0}</h3>
              <div>Pending</div>
            </div>
          </div>
        </div>
        <div className="col col-12 col-sm-12 col-md">
          <div className="h-stats card mb-2">
            <RxCounterClockwiseClock
              className="icon mx-2"
              style={{ color: "#0051ff", fontSize: "28px" }}
            />
            <div className="d-flex flex-column justify-content-center ms-2">
              <h3>{analysisData?.Rescheduled || 0}</h3>
              <div>Rescheduled</div>
            </div>
          </div>
        </div>
        <div className="col col-12 col-sm-12 col-md">
          <div className="h-stats card mb-2">
            <LiaCalendarCheck
              className="icon mx-2"
              style={{ color: "#0051ff", fontSize: "28px" }}
            />
            <div className="d-flex flex-column justify-content-center ms-2">
              <h3>{analysisData?.Show || 0}</h3>
              <div>Show</div>
            </div>
          </div>
        </div>
        <div className="col col-12 col-sm-12 col-md">
          <div className="h-stats card mb-2">
            <LiaCalendarTimes
              className="icon mx-2"
              style={{ color: "#0051ff", fontSize: "28px" }}
            />
            <div className="d-flex flex-column justify-content-center ms-2">
              <h3>{analysisData?.NoShow || 0}</h3>
              <div>No Show</div>
            </div>
          </div>
        </div>
        <div className="col col-12 col-sm-12 col-md">
          <div className="h-stats card mb-2">
            <LiaUserClockSolid
              className="icon mx-2"
              style={{ color: "#0051ff", fontSize: "28px" }}
            />
            <div className="d-flex flex-column justify-content-center ms-2">
              <h3>{analysisData?.Showp || "0%"}</h3>
              <div>Show Rate</div>
            </div>
          </div>
        </div>
      </div>
      <Tabs defaultActiveKey="Calendar" className="my-4">
        <Tab eventKey="Calendar" title="Calendar View">
          {apptReport && apptReport.length ? (
            <AppointmentsCalendarView
              data={apptReport}
              isdealerfunneluser={isdealerfunneluser}
            />
          ) : showLoading ? (
            <SectionLoader />
          ) : (
            <div className="w-100 text-center p-2 bg-gray">No results</div>
          )}
        </Tab>
        <Tab eventKey="Table" title="Table View">
          {apptReport && apptReport.length ? (
            <AppointmentsTableView
              data={apptReport}
              pagination={apptPagination}
              onChangePage={onChangePage}
              // onChangeFilter={onChangeFilter}
              isdealerfunneluser={isdealerfunneluser}
              clientId={clientId}
              campaignId={campaignId}
              searchKey={searchKey}
              setSearchKey={setSearchKey}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              reloadData={reloadData}
              deleteAppts={deleteAppts}
            />
          ) : showLoading ? (
            <SectionLoader />
          ) : (
            <div className="w-100 text-center p-2 bg-gray">No results</div>
          )}
        </Tab>
      </Tabs>
    </main>
  );
}
