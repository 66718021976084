import React, { useContext, useEffect } from "react";
import { SlUser } from "react-icons/sl";
import { Link, useParams } from "react-router-dom";
import { AuthContext, UserContext } from "../../../context";
import { useState } from "react";
import UserEditForm from "./UserEditForm";
import UserEditFormDFManager from "./UserEditFormDFManager";
import UserEditFormAgency from "./UserEditFormAgency";
import { BsPlus } from "react-icons/bs";

export default function UserEditPage() {
  const { keyid } = useParams();
  const { user } = useContext(AuthContext);
  const { getEditUserDataApi } = useContext(UserContext);
  //
  const [userType, setUserType] = useState(0);
  const [userObj, setUserObj] = useState(undefined);
  const [manager, setManager] = useState([]);
  const [executive, setExecutive] = useState([]);
  const [sagent, setSagent] = useState(null);
  const [usergroup, setUsergroup] = useState([]);
  const [client, setClient] = useState([]);
  const [isagentchain, setIsagentchain] = useState(undefined);
  const [agency, setAgency] = useState([]);
  const [userAuthorizedNo, setUserAuthorizedNo] = useState(undefined);
  const [callerId, setCallerId] = useState([]);
  const [isSuperAdmin, setIsSuperAdmin] = useState(undefined);

  useEffect(() => {
    getEditUserData();
  }, [keyid]);

  const getEditUserData = async () => {
    const data = await getEditUserDataApi({ userId: user.Id, kid: keyid });
    if (data.status === "ok") {
      const res = data.response;
      setUserType(Number(res?.userType));
      setUserObj(res?.user);
      setManager(res?.manager);
      setExecutive(res?.executive);
      setSagent(res?.sagent);
      setUsergroup(res?.user_group);
      setClient(res?.client);
      setIsagentchain(res?.isagentchain);
      setAgency(res?.agency);
      setUserAuthorizedNo(res?.userAuthorizedNo);
      setCallerId(res?.callerId);
      setIsSuperAdmin(res?.isSuperAdmin);
    }
  };

  return (
    <main className="container py-4 position-relative">
      <div className="d-flex align-items-center">
        <div className="section-title">
          <SlUser className="icon me-2" />
          Edit User
        </div>
        <div className="ms-auto">
          <Link
            to="/users/newuser"
            className="btn btn-primary d-inline-flex align-items-center"
          >
            <BsPlus />
            New User
          </Link>
          <Link
            to="/users/newgroup"
            className="btn btn-secondary d-inline-flex align-items-center ms-2"
          >
            <BsPlus />
            New User Group
          </Link>
        </div>
      </div>
      <div className="card w-100 mt-4">
        <div className="card-body">
          <div className="row">
            <div className="col col-sm-2 pe-0 border-end border-2">
              <ul className="nav nav-tabs-vertical">
                <li className={`nav-item active`}>User Info</li>
              </ul>
            </div>
            <div className="col col-sm-10">
              <div className="tab-content">
                <div className="tab-pane active">
                  {userType === 8 ? (
                    <UserEditFormDFManager
                      userdata={userObj}
                      manager={manager}
                      executive={executive}
                      sagent={sagent}
                      usergroup={usergroup}
                      client={client}
                      userType={userType}
                      isagentchain={isagentchain}
                      agency={agency}
                      userAuthorizedNo={userAuthorizedNo}
                      reloadData={() => getEditUserData()}
                    />
                  ) : userType === 5 || userObj?.Type === 7 ? (
                    <UserEditFormAgency
                      userdata={userObj}
                      manager={manager}
                      executive={executive}
                      sagent={sagent}
                      usergroup={usergroup}
                      client={client}
                      userType={userType}
                      isagentchain={isagentchain}
                      agency={agency}
                      userAuthorizedNo={userAuthorizedNo}
                      reloadData={() => getEditUserData()}
                    />
                  ) : (
                    <UserEditForm
                      userdata={userObj}
                      manager={manager}
                      executive={executive}
                      sagent={sagent}
                      usergroup={usergroup}
                      client={client}
                      userType={userType}
                      isagentchain={isagentchain}
                      agency={agency}
                      userAuthorizedNo={userAuthorizedNo}
                      callerId={callerId}
                      isSuperAdmin={isSuperAdmin}
                      reloadData={() => getEditUserData()}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
