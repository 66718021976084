import React, { useContext, useEffect, useState } from "react";
import { Box, Button, LinearProgress, Skeleton, Stack } from "@mui/material";
import { AuthContext, SettingContext } from "../../../context";
import {
  ChatEmojiPickerInput,
  ChatSmartFieldsInput,
  PrimaryTextArea,
} from "../../../components";
import { ColorsConfig, Constants } from "../../../config";
import { toast } from "react-toastify";

export default function UserDefaultMessages() {
  const { getDefaultMessages, updateDefaultMessages } =
    useContext(SettingContext);
  const { user } = useContext(AuthContext);
  const [info, setInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [pageData, setPageData] = useState({
    Automatic_confirmation: Constants.defaultMessages.AutomaticConfirmation,
    Scheduled_reminder: Constants.defaultMessages.ScheduledReminder,
    Automatic_follow_up: Constants.defaultMessages.AutomaticFollowUp,
    Id: "",
    userId: user?.Id,
  });
  const [currentEvent, setCurrentEvent] = useState(null);
  const [currentMessage, setCurrentMessage] = useState(null);
  const items = [
    { name: "Automatic Confirmation", value: "Automatic_confirmation" },
    { name: "Scheduled Reminder", value: "Scheduled_reminder" },
    { name: "Automatic Follow-up", value: "Automatic_follow_up" },
  ];

  useEffect(() => {
    if (user && user.Id) {
      getInfo();
    }
  }, [user]);

  const getInfo = async () => {
    setLoading(true);
    const result = await getDefaultMessages();
    setInfo(result);
    console.log(info);
    setLoading(false);
  };

  const embedMessage = (v) => {
    let position = 0;
    if (pageData?.[currentMessage]?.length > 0) {
      position = pageData?.[currentMessage].length - 1;
    }
    if (currentEvent) {
      position = currentEvent.target.selectionStart;
    }
    setPageData({
      ...pageData,
      [currentMessage]: pageData?.[currentMessage]
        ? `${pageData?.[currentMessage].substring(0, position)}${v}${pageData?.[
            currentMessage
          ].substring(position)}`
        : v,
    });
  };

  const submit = async () => {
    setConfirmLoading(true);
    await updateDefaultMessages(pageData);
    setConfirmLoading(false);
    toast.success("Successfully updated!");
  };

  const skeleton = () => {
    return (
      <Stack direction={"column"} spacing={2}>
        {[...Array(3)].map((item, index) => {
          return (
            <Stack key={index} direction={"column"} spacing={1}>
              <Skeleton variant="rounded" width={120} height={30} />
              <Skeleton variant="rounded" width={"100%"} height={100} />
            </Stack>
          );
        })}
      </Stack>
    );
  };

  const renderMain = () => {
    return (
      <Box>
        <Stack direction={"column"} spacing={2}>
          {items.map((item, index) => {
            return (
              <Box key={index}>
                <PrimaryTextArea
                  label={item.name}
                  value={pageData?.[item.value]}
                  handleChange={(e) =>
                    setPageData({
                      ...pageData,
                      Automatic_confirmation: e.target.value,
                    })
                  }
                  handleBlur={(e) => {
                    setCurrentEvent(e);
                    setCurrentMessage(item.value);
                  }}
                  rows={3}
                />
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"start"}
                  spacing={1}
                  sx={{
                    mt: 1,
                  }}
                  onClick={() => setCurrentMessage(item.value)}
                >
                  <ChatEmojiPickerInput
                    color={ColorsConfig.light.info}
                    handleChange={(v) => embedMessage(v)}
                  />
                  <ChatSmartFieldsInput
                    color={ColorsConfig.light.info}
                    handleChange={(v) => embedMessage(v)}
                  />
                </Stack>
              </Box>
            );
          })}
        </Stack>
        {confirmLoading && (
          <Box
            sx={{
              mt: 2,
            }}
          >
            <LinearProgress />
          </Box>
        )}
        <Stack
          direction={"row"}
          spacing={3}
          alignItems={"center"}
          justifyContent={"center"}
          mt={3}
        >
          <Button
            variant="contained"
            onClick={submit}
            disabled={confirmLoading}
          >
            Save
          </Button>
          <Button variant="outlined" color="inherit" disabled={confirmLoading}>
            Cancel
          </Button>
        </Stack>
      </Box>
    );
  };

  return <Box mt={2}>{loading ? skeleton() : renderMain()}</Box>;
}
