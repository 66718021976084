import Modal from "react-modal";
import React, { useContext, useState, useEffect } from "react";
import { AppointmentContext, AuthContext } from "../../context";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "560px",
    padding: "15px",
    marginTop: "20px",
    marginBottom: "20px",
  },
};

Modal.setAppElement("#root");

export default function AppointmentRescheduleModal({
  openModal = "",
  closeModal,
  reloadData,
}) {
  const { user } = useContext(AuthContext);
  const { getRescheduleModal, updateSchedule } = useContext(AppointmentContext);
  const [postData, setPostData] = useState({
    userId: user.Id,
    rid: "",
    leadId: "",
    Sdate: "",
    Stime: "",
    status: "",
  });

  useEffect(() => {
    if (openModal) {
      const leadId = openModal;
      _getRescheduleModal(leadId);
    }
  }, [openModal]);

  const _getRescheduleModal = async (leadId) => {
    const data = await getRescheduleModal({ leadId: leadId });
    if (data.status === "ok") {
      const res = data.response;
      console.log(">>> reschedule data >>>", res);
      setPostData({
        userId: user.Id,
        rid: res.response.responseId,
        leadId: res.response.lead,
        Sdate: res.schedule.Sdate,
        Stime: res.schedule.Stime,
        status: res.response.Status,
      });
    }
  };

  const onSave = async () => {
    console.log(">>> post data >>>", postData);
    const data = await updateSchedule(postData);
    if (data.status === "ok") {
      reloadData();
    }
  };

  const afterOpenModal = () => {};

  return (
    <Modal
      isOpen={!!openModal}
      onAfterOpen={() => afterOpenModal()}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Edit Modal"
    >
      <div className="modal-header">
        <h4>Reshedule</h4>
      </div>
      <div className="modal-body">
        <form>
          <div className="row">
            <div className="col-4 col-sm-4 col-md-4">
              <div className="form-group mt-3">
                <label className="mb-1">Date:</label>
                <input
                  type="date"
                  className="form-control"
                  value={postData.Sdate}
                  onChange={(e) =>
                    setPostData({ ...postData, Sdate: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="col-4 col-sm-4 col-md-4">
              <div className="form-group mt-3">
                <label className="mb-1">Time:</label>
                <input
                  type="time"
                  className="form-control"
                  value={postData.Stime}
                  onChange={(e) =>
                    setPostData({ ...postData, Stime: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="col-4 col-sm-4 col-md-4">
              <div className="form-group mt-3">
                <label className="mb-1">Status:</label>
                <select
                  className="form-select form-control-fav"
                  value={postData.status}
                  onChange={(e) =>
                    setPostData({ ...postData, status: e.target.value })
                  }
                >
                  <option value="1">Pending</option>
                  <option value="2">Showed</option>
                  <option value="3">No Show</option>
                  <option value="4">Rescheduled</option>
                </select>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="modal-footer mt-3">
        <button className="btn btn-primary me-2" onClick={() => onSave()}>
          Save
        </button>
        <button className="btn btn-outline-secondary" onClick={closeModal}>
          Cancel
        </button>
      </div>
    </Modal>
  );
}
