import React, { createContext, useContext, useState } from "react";
import { AxiosContext } from "./axios_context";
import config from "../config/config";
import { AuthContext } from "./auth_context";

const ChatContext = createContext(undefined);

const ChatContextProvider = (props) => {
  const { axiosInstance } = useContext(AxiosContext);
  const { user, userTimeOffset } = useContext(AuthContext);

  const [activatedLeadId, setActivatedLeadId] = useState(null);
  const [chatData, setChatData] = useState(null);

  const getSmsMessageApi = (params) => {
    const data = params;
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/conversations/conversation_getsmsmessage`,
      data,
    })
      .then((response) => {
        const data = response.data;
        if (data.status === "ok") {
          setChatData(data.response);
        }
        return data;
      })
      .catch((error) => {
        return error;
      });
  };

  const manualOptInApi = (params) => {
    const data = params;

    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/conversations/conversation_manualoptin`,
      data,
    })
      .then((response) => {
        const data = response.data;
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const assignUserApi = (params) => {
    const data = params;

    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/conversations/conversation_assignuser`,
      data,
    })
      .then((response) => {
        const data = response.data;
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const providerValue = {
    activatedLeadId,
    chatData,

    setActivatedLeadId,
    getSmsMessageApi,
    manualOptInApi,
    assignUserApi,
  };

  return (
    <ChatContext.Provider value={providerValue}>
      {props.children}
    </ChatContext.Provider>
  );
};

export { ChatContext, ChatContextProvider };
