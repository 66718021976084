import React from "react";
import { IMaskInput } from "react-imask";
import { Box, Input, InputLabel } from "@mui/material";
import { ColorsConfig } from "../../config";

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="(#00) 000-0000"
      definitions={{
        "#": /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

export default function PhoneInput(props) {
  const { value, handleChange, placeholder, label, sx } = props;

  return (
    <Box>
      {label && (
        <InputLabel
          sx={{
            fontSize: "16px",
            color: ColorsConfig.light.primaryColor,
            mb: 0.5,
          }}
        >
          {label}
        </InputLabel>
      )}
      <Input
        disableUnderline={true}
        fullWidth
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        name="textmask"
        id="formatted-text-mask-input"
        inputComponent={TextMaskCustom}
        sx={{
          ...sx,
          border: `1px solid ${ColorsConfig.light.primaryBorderColor}`,
          borderRadius: 2,
          pl: 2,
          height: 40,
          "& fieldset": { border: "none !important" },
        }}
      />
    </Box>
  );
}
