import Modal from "react-modal";
import { AuthContext, ClientContext } from "../../context";
import React, { useEffect, useState, useContext } from "react";
import { toast } from "react-toastify";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "560px",
    padding: "15px",
    marginTop: "20px",
    marginBottom: "20px",
  },
};

Modal.setAppElement("#root");

export default function ClientImportLeadsFinalModal({
  isOpenModal = false,
  afterOpenModal,
  closeModal,
  reloadData,
}) {
  const { user } = useContext(AuthContext);
  const { getImportLeadsFinalModalDataApi, importleadApi } =
    useContext(ClientContext);
  const [len, setlen] = useState(0);
  const [h1, seth1] = useState([]);
  const [h2, seth2] = useState([]);
  //
  const [map, setmap] = useState([]);

  useEffect(() => {
    loadModalData();
    console.log(user, reloadData);
  }, []);

  const loadModalData = async () => {
    const data = await getImportLeadsFinalModalDataApi();
    if (data.status === "ok") {
      const res = data.response;
      setlen(res?.len);
      seth1(res?.h1);
      seth2(res?.h2);
      //
      let _map = [];
      if (res?.len) {
        _map.push("");
      }
      setmap(_map);
    }
  };

  const onselect = (index, value) => {
    let _map = map;
    _map[index] = value;
    setmap(_map);
  };

  const sendleadmap = async () => {
    let csv = map.join(",");
    let params = {};
    params["map"] = csv;

    const data = await importleadApi(params);
    if (data.status === "ok") {
      const res = data.response;
      let str =
        res["su"] +
        "  records are processing. Please check your email for an upload report shortly.";
      toast.info(str);
      closeModal();
    }
  };

  return (
    <Modal
      isOpen={isOpenModal}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel={"Import Leads"}
    >
      <div className="modal-header flex-column">
        <h4 className="w-100">Import Leads</h4>
        <small className="w-100">Step 2 of 2</small>
      </div>
      <div className="modal-body">
        <p className="mt-3">Select the field you want to map</p>
        <div className="row mt-3">
          <div className="col-12 col-sm-6">
            {!!len &&
              len.map((ln, index) => (
                <>
                  <div className="form-group" key={index}>
                    <label style="mb-2">{`${h1[ln]},${h2[ln]}`}</label>
                    <select
                      className="form-select"
                      name="importmap[]"
                      onChange={(e) => onselect(ln, e.target.value)}
                    >
                      <option value="">Select field</option>
                      <option value="0">First Name</option>
                      <option value="1">Last Name</option>
                      <option value="2">Email</option>
                      <option value="3">Phone</option>
                      <option value="4">Address</option>
                      <option value="5">City</option>
                      <option value="6">State</option>
                      <option value="7">Zip</option>
                      <option value="8">Trade-In Year</option>
                      <option value="9">Trade-In Make</option>
                      <option value="10">Trade-In Model</option>
                      <option value="11">Interested Year</option>
                      <option value="12">Interested Make</option>
                      <option value="13">Interested Model</option>
                      <option value="14">Lease Expiration Date</option>
                      <option value="15">Vin Number</option>
                      <option value="16">Purchase Date</option>
                      <option value="17">Loan Term</option>
                      <option value="18">Interest Rate</option>
                      <option value="19">Monthly Payment</option>
                      <option value="20">Months Remaining on Lease</option>
                    </select>
                  </div>
                  <small id={`map_error_${ln}`} className="text-danger">
                    Please select lead map.
                  </small>
                </>
              ))}
          </div>
        </div>
      </div>
      <div className="modal-footer mt-3">
        <button className="btn btn-outline-secondary me-2" onClick={closeModal}>
          Cancel
        </button>
        <button className="btn btn-primary" onClick={() => sendleadmap()}>
          Import
        </button>
      </div>
    </Modal>
  );
}
