import React, { useContext, useState } from "react";
import Modal from "react-modal";
import { AuthContext, FunnelContext } from "../../context";
import { useEffect } from "react";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "560px",
    padding: "15px",
    marginTop: "20px",
    marginBottom: "20px",
    overflow: "visible",
  },
};

Modal.setAppElement("#root");

export default function FunnelEditStatusModal({
  data = null,
  isOpenModal = false,
  afterOpenModal,
  closeModal,
  reloadData,
}) {
  const { user } = useContext(AuthContext);
  const { updateStatusApi } = useContext(FunnelContext);
  const [postData, setPostData] = useState({
    userId: user.Id,
    name: "",
    status: "",
    messageid: "",
  });

  useEffect(() => {
    if (data) {
      let _postData = { ...postData };
      _postData.userId = user.Id;
      _postData.name = data?.name;
      _postData.status = data?.status;
      _postData.messageid = data?.mid;
      setPostData(_postData);
    }
  }, [data]);

  const onSave = async () => {
    const data = await updateStatusApi(postData);
    if (data.status === "ok") {
      reloadData();
    }
  };

  return (
    <Modal
      isOpen={isOpenModal}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Edit Modal"
    >
      <div className="modal-header">
        <h4 className="d-flex align-items-center">Edit Status</h4>
      </div>
      <div className="modal-body">
        <div className="row">
          <div className="col col-sm-6">
            <div className="form-group mb-2">
              <label className="mb-2">Action Name</label>
              <input
                type="text"
                className="form-control"
                name="message-name"
                value={postData.name}
                onChange={(e) =>
                  setPostData({ ...postData, name: e.target.value })
                }
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="form-group mb-2">
              <label className="mb-2">Status</label>
              <select
                id="edit_status"
                className="form-select"
                value={postData.status}
                onChange={(e) =>
                  setPostData({ ...postData, status: e.target.value })
                }
              >
                <option value="">Select Status</option>
                <option value="1">Hot</option>
                <option value="2">Cold</option>
                <option value="3">Sold</option>
                <option value="4">Warm</option>
                <option value="5">Follow Up</option>
                <option value="6">Dead</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer mt-3">
        <button className="btn btn-outline-secondary me-2" onClick={closeModal}>
          Cancel
        </button>
        <button className="btn btn-primary" onClick={() => onSave()}>
          Save
        </button>
      </div>
    </Modal>
  );
}
