/* eslint-disable max-len */
import { ButtonGroup } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import {
  BsCalendar2,
  BsCheckCircle,
  BsClock,
  BsSlashCircle,
  BsTelephone,
  BsThreeDotsVertical,
  BsTrophy,
} from "react-icons/bs";
import {
  IoFunnelOutline,
  IoMailOutline,
  IoWarningOutline,
} from "react-icons/io5";
import {
  LiaFunnelDollarSolid,
  LiaLanguageSolid,
  LiaPhoneVolumeSolid,
} from "react-icons/lia";
import {
  BiLogoFacebook,
  BiLogoLinkedin,
  BiLogoTwitter,
  BiPaperPlane,
  BiSolidParking,
} from "react-icons/bi";
import config from "../../config/config";
import helper from "../../utils/helper";
import CommonModal from "../modals/common_modal";
import React, { useContext, useState } from "react";
import ConversationSetReminderModal from "../modals/conversation_set_reminder_modal";
import ConversationEmailReportModal from "../modals/conversation_email_report_modal";
import { GrLocation } from "react-icons/gr";
import GoogleMapReact from "google-map-react";
import { HiMapPin } from "react-icons/hi2";
import ConversationSMSOptinModal from "../modals/conversation_sms_optin_modal";
import { AuthContext, ConversationContext, SharedContext } from "../../context";
import { toast } from "react-toastify";
import ConversationChangeLabelModal from "../modals/conversation_change_label_modal";
import ConversationsLeadLabels from "./ConversationsLeadLabels";
import ConversationAssignFunnelModal from "../modals/conversation_assign_funnel_modal";
import ConversationFunnelConfirmModal from "../modals/conversation_funnel_confirm_modal";
import ConversationResumeFunnelConfirmModal from "../modals/conversation_resume_funnel_confirm_modal";
import { firebasedb } from "../../utils/firebase";
import { get, ref, child, update } from "firebase/database";
import ConversationCallPopupModal from "../modals/conversation_call_popup_modal";
import ConversationSetAppointmentLookUpCodeModal from "../modals/conversation_set_appointment_lookup_code_modal";
import ConversationSetAppointmentBarcodeModal from "../modals/conversation_set_appointment_barcode_modal";
import ConversationSmsEditApptsModal from "../modals/conversation_sms_edit_appts_modal";
import ConversationEscalateModal from "../modals/conversation_escalate_modal";
import ConversationAdfSmsEmailReportModal from "../modals/conversation_adf_sms_email_report_modal";

const ReactMapMarker = ({ text }) => (
  <div
    style={{
      position: "relative",
      top: "-32px",
      left: "-16px",
      width: "32px",
      height: "32px",
    }}
    key={text}
  >
    <div
      style={{
        position: "relative",
        background: "#b41412",
        width: "15px",
        height: "15px",
        borderRadius: "50%",
        left: "8px",
        top: "6px",
      }}
    ></div>
    <HiMapPin
      color="red"
      size="32"
      style={{ position: "absolute", top: "0px", left: "0px" }}
    />
  </div>
);

export default function CampaignUserBox({
  leadId,
  filterParams,
  summaryInfo = null,
  optinfo = null,
  profilelink = "",
  mediapicture,
  profilename,
  setProfilename,
  optout,
  isleadsource,
  leadsourceLogo,
  ismedialinks,
  medialinks,
  iswinbig,
  wprize,
  isfunnel,
  reloadData,
}) {
  const { user } = useContext(AuthContext);
  const { getServerTimeApi } = useContext(SharedContext);
  const {
    updateUserInfoApi,
    updateDefaultLanguageApi,
    loadSetAppointmentModalDataApi,
    loadSmsEditApptsModalDataApi,
    loadEscalateModalDataApi,
    setoptoutApi,
    loadAdfSmsEmailReportModalData,
  } = useContext(ConversationContext);
  const { firebasecallApi, labelchangeApi } = useContext(SharedContext);
  const [lastResponseId, setLastResponseId] = useState("");
  const [openSetReminderModal, setOpenSetReminderModal] = useState(false);
  const [openExportAdfModal, setOpenExportAdfModal] = useState(false);
  const [smsOptinInfoId, setSmsOptinInfoId] = useState("");
  const [changeLabelModal, setChangeLabelModal] = useState({
    open: false,
    leadId: "",
    flag: "",
  });
  const [assignFunnelModalId, setAssignFunnelModalId] = useState("");
  const [funnelConfirmModal, setFunnelConfirmModal] = useState(null);
  const [resumeFunnelConfirmModal, setResumeFunnelConfirmModal] =
    useState(null);
  const [callPopupModal, setCallPopupModal] = useState(null);
  const [appointmentModal, setAppointmentModal] = useState(null);
  const [smsEditApptsModal, setSmsEditApptsModal] = useState(null);
  const [escalateModal, setEscalateModal] = useState(null);
  const [adfSmsEmailReportModal, setAdfSmsEmailReportModal] = useState(null);

  const [phoneErr, setPhoneErr] = useState(false);
  const [emailErr, setEmailErr] = useState(false);

  const ajaxemailreport = (rid) => {
    setLastResponseId(rid);
  };

  const setreminderpopup = () => {
    setOpenSetReminderModal(true);
  };

  const smsoptin = (id) => {
    setSmsOptinInfoId(id);
  };

  const exportAdf = () => {
    setOpenExportAdfModal(true);
  };

  const inlineupdate = async (vl, flag) => {
    if (!vl) {
      return;
    }
    if (flag === 3 && !vl) {
      return;
    }
    if (
      flag === 2 &&
      !vl
      //  || !helper.validatePhone(vl)
    ) {
      return;
    }
    if (flag === 1 && (!vl || !helper.validateEmail(vl))) {
      return;
    }
    console.log("hello", vl, flag);
    let params = {
      userId: user.Id,
      lead: leadId,
      vl: vl,
      flag: flag,
    };
    const data = await updateUserInfoApi(params);
    if (data.status === "ok") {
      reloadData();
      if (flag === 4) toast.info("Promax ID saved!");
      if (flag === 3) toast.info("Profile name saved!");
      if (flag === 2) toast.info("Phone saved!");
      if (flag === 1) toast.info("Email saved!");
    }
  };

  const onChangeLanguage = async (lang) => {
    let params = {
      userId: user.Id,
      leadid: leadId,
      ln: lang,
    };
    const data = await updateDefaultLanguageApi(params);
    if (data.status === "ok") {
      reloadData();
      toast.info("Default language saved!");
    }
  };

  const changesmslabelwrapper = (lead, flag1, flag2) => {
    updateleadlock();
    if (flag2 == 0) {
      changesmslabel(lead, flag1);
    } else {
      var usertype = parseInt(user.Type);
      if (usertype == 5 || usertype == 1 || usertype == 8) {
        changesmslabel(lead, "8");
      }
    }
  };

  const changesmslabel = (lead, flag) => {
    setChangeLabelModal({
      open: true,
      leadId: lead,
      flag: flag,
    });
  };

  const setsmsread = () => {
    const filterlabel = getfilterlabel();
    if (filterlabel === 12) {
      unsetnewlead();
    } else {
      updateleadlock();
      var lead = leadId;
      adjustnewsms(lead, 0);
      // $('#timeago_'+lead).removeClass('timeago_mod');
      // $('#timeago_'+lead).prop('title','');
      // $('#timeago_'+lead).text('');
      // $('#timeago_'+lead).timeago_mod('dispose');
      // if ($('#L0menu').hasClass('active'))
      // {
      //     $('#li_'+lead).remove();
      // }
      // var ar_count = parseInt($('#L9count').html());
      // $('#L9count').html(ar_count - 1);
    }
  };

  const getfilterlabel = () => {
    const flabel = filterParams.sfilter;
    if (flabel == "L8") {
      return -1;
    }
    if (flabel == "L0") {
      return 0;
    }
    if (flabel == "L1") {
      return 1;
    }
    if (flabel == "L2") {
      return 2;
    }
    if (flabel == "L3") {
      return 3;
    }
    if (flabel == "L4") {
      return 4;
    }
    if (flabel == "L5") {
      return 5;
    }
    if (flabel == "L6") {
      return 6;
    }
    if (flabel == "A") {
      return 7;
    }
    if (flabel == "As") {
      return 8;
    }
    if (flabel == "Sch") {
      return 9;
    }
    if (flabel == "D") {
      return 10;
    }
    if (flabel == "L12") {
      return 12;
    }
  };

  const unsetnewlead = async () => {};

  const updateleadlock = async () => {
    console.log("update lead lock");
    // const query = ref(firebasedb, "/Conversation_lock/");
    const dbRef = ref(firebasedb);
    get(child(dbRef, "/Conversation_lock/"))
      .then((snapshot) => {
        if (snapshot.exists()) {
          snapshot.forEach(async function (childSnapshot) {
            var childData = childSnapshot.val();
            var Userid = childData["Userid"];
            var loginuser = parseInt(user.Id);
            console.log(
              "=== firebase log >>>",
              childSnapshot.key,
              Userid,
              loginuser,
            );
            if (Userid === loginuser) {
              const data = await getServerTimeApi();
              if (data.status === "ok") {
                const res = data.response;
                const servertime = res.servertime;
                console.log("=== servertime >>>", servertime);
                update(
                  child(dbRef, `/Conversation_lock/${childSnapshot.key}`),
                  {
                    createtime: parseInt(servertime),
                  },
                );
                console.log(
                  "=== servertime >>> End ===",
                  childSnapshot.key,
                  servertime,
                );
              }
            }
          });
        } else {
          console.log("No data available");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const adjustnewsms = async (lead, fl) => {
    console.log(lead, fl);
  };

  const callpopup = async (lead) => {
    updateleadlock();
    setCallPopupModal(lead);
  };

  const newappts = async (responseId) => {
    console.log("== responseId ==", responseId);
    updateleadlock();
    const data = await loadSetAppointmentModalDataApi({
      userId: user.Id,
      responseId: responseId,
    });
    if (data.status === "ok") {
      const res = data.response;
      setAppointmentModal(res);
    }
  };

  const ajaxesmsditappts = async (responseId) => {
    console.log("== responseId ==", responseId);
    updateleadlock();
    const data = await loadSmsEditApptsModalDataApi({
      userId: user.Id,
      leadId: responseId,
    });
    if (data.status === "ok") {
      const res = data.response;
      setSmsEditApptsModal(res);
    }
  };

  const Escalatemodal = async (lead) => {
    const data = await loadEscalateModalDataApi({
      userId: user.Id,
      lead: lead,
    });
    if (data.status === "ok") {
      const res = data.response;
      setEscalateModal(res);
    }
  };

  const setoptout = async (leadId) => {
    const data = await setoptoutApi({ userId: user.Id, lead: leadId });
    if (data.status === "ok") {
      const res = data.response;
      await firebasecallApi({ userId: user.Id, lead: leadId, ctype: 1 });
      //
      const params = {
        userId: user.Id,
        clabel: res.clabel,
        slabel: 6,
        rid: res.rid,
        flag: 7,
        lead: res.lead,
      };
      const data2 = await labelchangeApi(params);
      if (data2.status === "ok") {
        reloadData();
      }
      // deactive_messanger(); 1
    }
    // updateleadlock(); 1
  };

  const adfsmsemailreport = async (leadid) => {
    const data = await loadAdfSmsEmailReportModalData({
      userId: user.Id,
      leadid: leadid,
    });
    if (data.status === "ok") {
      const res = data.response;
      setAdfSmsEmailReportModal(res);
    }
  };

  return (
    <div className="bg-white">
      <h3 className="box-header campaign-title clearfix">
        <a
          target="_blank"
          href={
            summaryInfo?.cmpkey !== ""
              ? `${config.domain}/index.php/campaigns/dashboard?cid=${summaryInfo?.cmpkey}`
              : `${config.domain}/index.php/client/edit/${summaryInfo?.Keyid}`
          }
          rel="noreferrer"
        >
          {summaryInfo?.ClientName}
        </a>
        <DropdownButton
          className="ms-auto"
          as={ButtonGroup}
          align={{ lg: "end" }}
          variant="default"
          title={<BsThreeDotsVertical />}
        >
          <Dropdown.Item eventKey="1">
            Lead ID: {helper.formatePin(summaryInfo?.barcode)}
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item
            eventKey="2"
            target="_blank"
            href={`${config.domain}/index.php/tools/printresponse/${summaryInfo?.responsekey}`}
          >
            Print
          </Dropdown.Item>
          <Dropdown.Item
            eventKey="3"
            onClick={() => ajaxemailreport(summaryInfo?.LastResponseId)}
          >
            Email
          </Dropdown.Item>
          <Dropdown.Item eventKey="4" onClick={() => setreminderpopup()}>
            Set Reminder
          </Dropdown.Item>
          {Number(summaryInfo?.messagetype) === 2 && (
            <Dropdown.Item
              eventKey="5"
              onClick={() => smsoptin(summaryInfo?.id)}
            >
              SMS Opt-in
            </Dropdown.Item>
          )}
          <Dropdown.Item eventKey="6" onClick={() => exportAdf()}>
            Export ADF
          </Dropdown.Item>
        </DropdownButton>
      </h3>
      <div className="box box-user">
        <div className="user-detail">
          <div className="user-image">
            <a href={profilelink} target="_blank" rel="noreferrer">
              <img
                width="50"
                height="50"
                className="rounded-circle"
                src={mediapicture}
                alt=""
              />
            </a>
          </div>
          <div className="user-info">
            <h4 className="title">
              <input
                type="text"
                className="profilename"
                value={profilename}
                onChange={(e) => setProfilename(e.target.value)}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    e.target.blur();
                  }
                }}
                onBlur={(e) => {
                  console.log(e);
                  inlineupdate(profilename, 3);
                }}
              />
              {summaryInfo?.leadsummaryEdit ? (
                summaryInfo?.label < 10 ? (
                  <a
                    onClick={() =>
                      changesmslabelwrapper(summaryInfo?.id, "7", optout)
                    }
                    className="text-decoration-none ms-1"
                  >
                    <span
                      className={`badge rounded-pill ${helper.getLabelIconClass(
                        summaryInfo?.label,
                      )}`}
                    >
                      {helper.getLabelText(summaryInfo?.label)}
                    </span>
                  </a>
                ) : (
                  <a
                    title="tooltip sample text"
                    className="text-decoration-none ms-1"
                  >
                    <span
                      className={`badge rounded-pill ${helper.getLabelIconClass(
                        summaryInfo?.label,
                      )}`}
                    >
                      {helper.getLabelIconKeyword(summaryInfo?.label)}
                    </span>
                  </a>
                )
              ) : (
                <a
                  href="#"
                  title="tooltip sample text"
                  className="text-decoration-none ms-1"
                >
                  <span
                    className={`badge rounded-pill ${helper.getLabelIconClass(
                      summaryInfo?.label,
                    )}`}
                  >
                    {helper.getLabelIconKeyword(summaryInfo?.label)}
                  </span>
                </a>
              )}
            </h4>
            {!profilename && (
              <small className="text-danger">This field canot empty!</small>
            )}
            <p className="d-flex align-items-center">
              <img
                className="leadsource_img me-2"
                src={
                  Number(isleadsource) === 1
                    ? `${leadsourceLogo}?w=512&amp;h=512`
                    : "https://facebookbrand.com/wp-content/uploads/2019/04/f_logo_RGB-Hex-Blue_512.png?w=512&amp;h=512"
                }
                alt=""
              />
              {Number(summaryInfo?.CampaignId) > 0 ? (
                <a
                  className="text-decoration-none"
                  target="_blank"
                  href={`${config.domain}/index.php
                  /campaigns/dashboard?cid=${summaryInfo?.cmpkey}`}
                  rel="noreferrer"
                >{`#${summaryInfo?.cmpjob}-${summaryInfo?.cmptype}`}</a>
              ) : Number(summaryInfo?.CampaignId) === 0 &&
                summaryInfo?.LeadSource !== "" ? (
                <a
                  className="text-decoration-none"
                  target="_blank"
                  href={`${config.domain}/index.php/client/edit/${summaryInfo?.Keyid}`}
                  rel="noreferrer"
                >
                  {summaryInfo?.LeadSource}
                </a>
              ) : (
                <a
                  className="text-decoration-none"
                  target="_blank"
                  href={`${config.domain}/index.php/client/edit/${summaryInfo?.Keyid}`}
                  rel="noreferrer"
                >
                  {summaryInfo?.ClientName}
                </a>
              )}
            </p>
            {Number(ismedialinks) === 1 && (
              <p className="social-icons">
                {Number(medialinks?.facebook[0]) === 1 && (
                  <a
                    target="_blank"
                    className="text-decoration-none me-1"
                    href={medialinks?.facebook[1]}
                    rel="noreferrer"
                  >
                    <BiLogoFacebook />
                  </a>
                )}
                {Number(medialinks?.twitter[0]) === 1 && (
                  <a
                    target="_blank"
                    className="text-decoration-none me-1"
                    href={medialinks?.twitter[1]}
                    rel="noreferrer"
                  >
                    <BiLogoTwitter />
                  </a>
                )}
                {Number(medialinks?.linkedin[0]) === 1 && (
                  <a
                    target="_blank"
                    className="text-decoration-none me-1"
                    href={medialinks?.linkedin[1]}
                    rel="noreferrer"
                  >
                    <BiLogoLinkedin />
                  </a>
                )}
              </p>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-sm-6">
            {summaryInfo?.Address && (
              <div className="row-lead-info">
                <div className="row-lead-info-icon">
                  <GrLocation size={11} />
                </div>
                <span>
                  {summaryInfo?.Address}
                  <br />
                  {`${summaryInfo?.City},${summaryInfo?.State} ${summaryInfo?.Zip}`}
                </span>
              </div>
            )}
            <div className="row-lead-info">
              <div className="row-lead-info-icon">
                <BsTelephone size={11} />
              </div>
              <input
                type="tel"
                placeholder="Enter phone..."
                className="editable w-100"
                required=""
                defaultValue={summaryInfo?.Phone}
                maxLength="14"
                onChange={(e) => setPhoneErr(!e.target.value)}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    e.target.blur();
                  }
                }}
                onBlur={(e) => inlineupdate(e.target.value, 2)}
              />
              {phoneErr && <span className="error">Phone is not valid!</span>}
            </div>
            <div className="row-lead-info">
              <div className="row-lead-info-icon">
                <IoMailOutline size={11} />
              </div>
              <input
                type="email"
                placeholder="Enter email..."
                className="editable w-100"
                required=""
                defaultValue={summaryInfo?.email}
                onChange={(e) => setEmailErr(!e.target.value)}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    e.target.blur();
                  }
                }}
                onBlur={(e) => inlineupdate(e.target.value, 1)}
              />
              {emailErr && <span className="error">Email is not valid!</span>}
            </div>
            {summaryInfo?.Buytime && (
              <div className="row-lead-info">
                <div className="row-lead-info-icon">
                  <BsClock size={11} />
                </div>
                <span>Buytime: {summaryInfo?.Buytime}</span>
              </div>
            )}
            <div className="row-lead-info">
              <div className="row-lead-info-icon">
                <LiaLanguageSolid size={11} />
              </div>
              <select
                className="editable w-100"
                required=""
                value={summaryInfo?.default_language}
                onChange={(e) => onChangeLanguage(e.target.value)}
              >
                <option value="">None</option>
                <option value="en">English</option>
                <option value="es">Spanish</option>
                <option value="fr">French</option>
                <option value="uz">Uzbek</option>
                <option value="de">German</option>
              </select>
            </div>
            {summaryInfo?.PromaxCustID && (
              <div className="row-lead-info">
                <div className="row-lead-info-icon">
                  <BiSolidParking size={11} style={{ opacity: 0.75 }} />
                </div>
                <input
                  type="number"
                  placeholder="Enter Promax ID..."
                  className="editable w-100"
                  required=""
                  defaultValue={summaryInfo?.PromaxCustID}
                  onKeyUp={(e) => {
                    if (e.key === "Enter") {
                      e.target.blur();
                    }
                  }}
                  onBlur={(e) => inlineupdate(e.target.value, 4)}
                />
              </div>
            )}
            {summaryInfo?.funnelname && (
              <div className="row-lead-info">
                <div className="row-lead-info-icon">
                  <IoFunnelOutline size={11} />
                </div>
                <span className="">{summaryInfo?.funnelname}</span>
              </div>
            )}
            {Number(iswinbig) === 1 && (
              <div className="row-lead-info">
                <div className="row-lead-info-icon">
                  <BsTrophy size={11} />
                </div>
                <span className="">{wprize}</span>
              </div>
            )}
          </div>
          <div className="col-12 col-sm-6">
            {summaryInfo?.lat && summaryInfo?.lon && (
              <div style={{ height: "100px", width: "100%" }}>
                <GoogleMapReact
                  bootstrapURLKeys={{ key: config.googleMapKey }}
                  center={{
                    lat: Number(summaryInfo?.lat),
                    lng: Number(summaryInfo?.lon),
                  }}
                  zoom={15}
                >
                  <ReactMapMarker
                    lat={Number(summaryInfo?.lat)}
                    lng={Number(summaryInfo?.lon)}
                    text=""
                  />
                </GoogleMapReact>
              </div>
            )}
          </div>
        </div>
        <div className="labels mt-3">
          <ConversationsLeadLabels leadId={leadId} />
        </div>
        <div className="btn-action">
          {Number(isfunnel) > 0 && (
            <>
              <a
                title="Assign Funnel"
                onClick={() => setAssignFunnelModalId(summaryInfo?.id)}
              >
                <LiaFunnelDollarSolid className="icon" />
              </a>
              <span></span>
            </>
          )}
          <a title="Mark as Responded" onClick={() => setsmsread()}>
            <BsCheckCircle className="icon" />
          </a>
          <span></span>
          {!!summaryInfo?.Phone && (
            <>
              {Number(optinfo?.look) === 0 ? (
                <>
                  <a
                    title="Call"
                    className="callpopup"
                    onClick={() => callpopup(summaryInfo?.id)}
                  >
                    <LiaPhoneVolumeSolid className="icon" />
                  </a>
                  <span></span>
                </>
              ) : (
                <>
                  <a className="callpopup call_optout">
                    <LiaPhoneVolumeSolid className="icon" />
                  </a>
                  <span></span>
                </>
              )}
            </>
          )}
          {Number(summaryInfo?.appts) > 0 ? (
            Number(summaryInfo?.appstatus) === 2 ? (
              <a
                title="Set Appointment"
                onClick={() => newappts(summaryInfo?.LastResponseId)}
              >
                <BsCalendar2 className="icon" />
              </a>
            ) : (
              <a
                title="Edit Appointment"
                onClick={() => ajaxesmsditappts(summaryInfo?.id)}
              >
                <BsCalendar2 className="icon" />
              </a>
            )
          ) : (
            <a
              title="Set Appointment"
              onClick={() => newappts(summaryInfo?.LastResponseId)}
            >
              <BsCalendar2 className="icon" />
            </a>
          )}
          <span></span>
          <a title="Escalate" onClick={() => Escalatemodal(summaryInfo?.id)}>
            <IoWarningOutline className="icon" />
          </a>
          <span></span>
          <a title="Opt-out" onClick={() => setoptout(summaryInfo?.id)}>
            <BsSlashCircle className="icon" />
          </a>
          <span></span>
          <a
            title="Send to CRM"
            onClick={() => adfsmsemailreport(summaryInfo?.id)}
          >
            <BiPaperPlane className="icon" />
          </a>
        </div>
      </div>
      {/* Modal */}
      <ConversationEmailReportModal
        responseId={lastResponseId}
        isOpenModal={!!lastResponseId}
        afterOpenModal={() => {}}
        closeModal={() => setLastResponseId("")}
      />
      <ConversationSMSOptinModal
        infoId={smsOptinInfoId}
        isOpenModal={!!smsOptinInfoId}
        afterOpenModal={() => {}}
        closeModal={() => setSmsOptinInfoId("")}
      />
      <ConversationSetReminderModal
        leadId={leadId}
        isOpenModal={openSetReminderModal}
        afterOpenModal={() => {}}
        closeModal={() => setOpenSetReminderModal(false)}
      />
      <ConversationChangeLabelModal
        leadId={changeLabelModal.leadId}
        flag={changeLabelModal.flag}
        isOpenModal={changeLabelModal.open}
        afterOpenModal={() => {}}
        closeModal={() => {
          reloadData();
          setChangeLabelModal({ open: false, leadId: "", flag: "" });
        }}
      />
      <CommonModal
        modalTitle="Export Adf"
        modalContent="Export Adf..."
        yesButtonLabel="Export"
        noButtonLabel="Cancel"
        isOpenModal={openExportAdfModal}
        afterOpenModal={() => {}}
        closeModal={() => setOpenExportAdfModal(false)}
      />
      {/* --- */}
      <ConversationAssignFunnelModal
        leadId={assignFunnelModalId}
        isOpenModal={!!assignFunnelModalId}
        afterOpenModal={() => {}}
        closeModal={(from, params) => {
          setAssignFunnelModalId("");
          if (from === "assignfunnel" || from === "reassignfunnel") {
            setFunnelConfirmModal(params);
          } else if (from === "resumefunnel") {
            setResumeFunnelConfirmModal(params);
          } else {
            reloadData();
          }
        }}
      />
      <ConversationFunnelConfirmModal
        params={funnelConfirmModal}
        isOpenModal={!!funnelConfirmModal}
        afterOpenModal={() => {}}
        closeModal={() => {
          reloadData();
          setFunnelConfirmModal(null);
        }}
      />
      <ConversationResumeFunnelConfirmModal
        params={resumeFunnelConfirmModal}
        isOpenModal={!!resumeFunnelConfirmModal}
        afterOpenModal={() => {}}
        closeModal={() => {
          reloadData();
          setResumeFunnelConfirmModal(null);
        }}
      />
      <ConversationCallPopupModal
        leadId={callPopupModal}
        unsetnewlead={() => unsetnewlead()}
        isOpenModal={!!callPopupModal}
        afterOpenModal={() => {}}
        closeModal={() => {
          // reloadData();
          setCallPopupModal(null);
        }}
      />
      <ConversationSetAppointmentLookUpCodeModal
        data={appointmentModal}
        isOpenModal={
          !!appointmentModal && Number(appointmentModal?.hasBarcode) === 0
        }
        closeModal={() => {
          setAppointmentModal(null);
        }}
      />
      <ConversationSetAppointmentBarcodeModal
        data={appointmentModal}
        isOpenModal={
          !!appointmentModal && Number(appointmentModal?.hasBarcode) === 1
        }
        closeModal={() => {
          reloadData();
          setAppointmentModal(null);
        }}
      />
      <ConversationSmsEditApptsModal
        data={smsEditApptsModal}
        isOpenModal={!!smsEditApptsModal}
        closeModal={() => {
          reloadData();
          setSmsEditApptsModal(null);
        }}
      />
      <ConversationEscalateModal
        data={escalateModal}
        isOpenModal={!!escalateModal}
        closeModal={() => {
          reloadData();
          setEscalateModal(null);
        }}
      />
      <ConversationAdfSmsEmailReportModal
        data={adfSmsEmailReportModal}
        isOpenModal={!!adfSmsEmailReportModal}
        closeModal={() => {
          reloadData();
          setAdfSmsEmailReportModal(null);
        }}
      />
    </div>
  );
}
