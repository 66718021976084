import React, { useContext, useEffect, useState } from "react";
import { AuthContext, ClientContext } from "../../context";

export default function ClientCTASetupTab({ clientid }) {
  const { user } = useContext(AuthContext);
  const { getCTASetupApi, savectadataApi } = useContext(ClientContext);
  //
  const [postData, setPostData] = useState({
    clientid: clientid,
    bgcolor: "",
    fontcolor: "",
    font: "",
    fontsize: "",
    borderradius: "",
    buttontext: "",
    embed_code: "",
  });
  const [Keyid, setKeyid] = useState("");
  const [embedCode, setEmbedCode] = useState("");

  useEffect(() => {
    getCTASetup(clientid);
  }, [clientid]);

  const getCTASetup = async (cid) => {
    const data = await getCTASetupApi({ userId: user.Id, cid: cid });
    if (data.status === "ok") {
      const res = data.response;
      setPostData({
        ...postData,
        clientid: res?.cid,
        bgcolor: res?.cta?.BackgroundColor,
        fontcolor: res?.cta?.FontColor,
        font: res?.cta?.Font,
        fontsize: res?.cta?.FontSize,
        borderradius: res?.cta?.BorderRadius,
        buttontext: res?.cta?.ButtonText,
      });
      setKeyid(res?.Keyid);
    }
  };

  useEffect(() => {
    let embed = `<div style="display: inline-block; text-decoration: none; 
    line-height: 1; border: none; outline: none; cursor: pointer; 
    transition: opacity 0.2s ease; text-align: center; min-width: 110px; background-color: ${
      postData.bgcolor
    }; color: ${postData.fontcolor}; font-family: ${
      postData.font
    }; font-size: ${postData.fontsize}px; border-radius: ${
      postData.borderradius
    }px;" onmouseover="this.style.opacity='0.9';" 
    onmouseout="this.style.opacity='1'">
    <label for="dfmodal-1" style="line-height: 1; margin: 0; padding: 0;
     font-size: inherit; padding: 10px ${
       Number(postData.fontsize ? postData.fontsize : 0) + 10
     }px; display: inline-block; cursor: pointer;">${
       postData.buttontext
     }</label></div><script 
    src="https://updash.com/assets/
    javascripts/dfcta.min.js"><\\/script><script>DFCTA.create("${Keyid}");<\\/script>`;
    setEmbedCode(embed);
  }, [postData]);

  const savectadata = async () => {
    let params = { ...postData };
    params.embed_code = embedCode;
    const data = await savectadataApi(params);
    if (data.status === "ok") {
      getCTASetup(clientid);
    }
  };

  return (
    <>
      <h6 className="tab-tile">CTA Setup</h6>
      <hr />
      <div className="row">
        <div className="col col-sm-4">
          <p className="w-100 text-center">Customize Your CTA Button</p>
          <table className="table table-borderless">
            <tbody>
              <tr>
                <td>Background Color</td>
                <td className="text-end">
                  <input
                    type="color"
                    className="ms-auto"
                    value={postData.bgcolor}
                    onChange={(e) =>
                      setPostData({ ...postData, bgcolor: e.target.value })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>Font Color</td>
                <td className="text-end">
                  <input
                    type="color"
                    className="ms-auto"
                    value={postData.fontcolor}
                    onChange={(e) =>
                      setPostData({ ...postData, fontcolor: e.target.value })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>Font</td>
                <td>
                  <select
                    className="form-select"
                    value={postData.font}
                    onChange={(e) =>
                      setPostData({ ...postData, font: e.target.value })
                    }
                  >
                    <option value="inherit">Inherit</option>
                    <option value="Arial, sans-serif">Arial</option>
                    <option value="Arial Black, Arial, sans-serif">
                      Arial Black
                    </option>
                    <option value="Century Gothic, Times, serif">
                      Century Gothic
                    </option>
                    <option value="Courier, monospace">Courier</option>
                    <option value="Courier New, monospace">Courier New</option>
                    <option value="Geneva, Tahoma, Verdana, sans-serif">
                      Geneva
                    </option>
                    <option value="Georgia, Times, Times New Roman, serif">
                      Georgia
                    </option>
                    <option value="Helvetica, Arial, sans-serif">
                      Helvetica
                    </option>
                    <option value="Lucida, Geneva, Verdana, sans-serif">
                      Lucida
                    </option>
                    <option value="Open Sans, sans-serif">Open Sans</option>
                    <option value="Tahoma, Verdana, sans-serif">Tahoma</option>
                    <option value="Times, Times New Roman, Baskerville, Georgia, serif">
                      Times
                    </option>
                    <option value="Times New Roman, Times, Georgia, serif">
                      Times New Roman
                    </option>
                    <option value="Verdana, Geneva, sans-serif">Verdana</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td>Font Size</td>
                <td>
                  <input
                    type="number"
                    className="form-control"
                    value={postData.fontsize}
                    onChange={(e) =>
                      setPostData({ ...postData, fontsize: e.target.value })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>Border Radius</td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    value={postData.borderradius}
                    onChange={(e) =>
                      setPostData({ ...postData, borderradius: e.target.value })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>Button Text</td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    value={postData.buttontext}
                    onChange={(e) =>
                      setPostData({ ...postData, buttontext: e.target.value })
                    }
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="col col-sm-4">
          <p className="w-100 text-center">Preview</p>
          <div className="d-flex w-100">
            <button
              className="btn mx-auto px-3"
              style={{
                backgroundColor: postData?.bgcolor,
                color: postData?.fontcolor,
                fontFamily: postData?.font,
                fontSize: `${postData?.fontsize}px`,
                borderRadius: `${postData?.borderradius}px`,
              }}
            >
              {postData?.buttontext}
            </button>
          </div>
        </div>
        <div className="col col-sm-4">
          <p className="w-100 text-center">Embed Code</p>
          <textarea
            className="form-textarea overflow-auto"
            style={{ height: `calc(100% - 60px)` }}
            defaultValue={embedCode}
            readOnly={true}
            onFocus={(e) => e.target.select()}
          ></textarea>
        </div>
      </div>
      <hr />
      <div className="row mt-2">
        <div className="col-12 d-flex">
          <button
            className="btn btn-primary mx-auto"
            onClick={() => savectadata()}
          >
            Save
          </button>
        </div>
      </div>
    </>
  );
}
