import { SlTrophy } from "react-icons/sl";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import React, { useContext, useEffect } from "react";
import { AuthContext, DashboardContext } from "../../context";
import { useState } from "react";
import {
  Avatar,
  Box,
  Card,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import config from "../../config/config";

export default function MonthLeaderBoard({ selectedDealershipIds = "" }) {
  const { user } = useContext(AuthContext);
  const { getMonthLeaderShip, thisMonth, leadershipGroup } =
    useContext(DashboardContext);
  const [showLoading, setShowLoading] = useState(false);
  const tableCells = [
    "#",
    "",
    "User",
    "Leads",
    "Msgs",
    "Calls",
    "Appt",
    "Appt%",
  ];

  useEffect(() => {
    getMonthLeaderShipData();
  }, [selectedDealershipIds]);

  const getMonthLeaderShipData = async () => {
    setShowLoading(true);
    const res = await getMonthLeaderShip({
      userId: user.Id,
      dealershipIds: selectedDealershipIds,
    });
    if (res) {
      setShowLoading(false);
    }
  };

  return (
    <Card
      sx={{
        p: 2,
        pt: 3,
        height: "100%",
      }}
    >
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"center"}
        spacing={1}
      >
        <SlTrophy style={{ fontSize: 20, color: "inherit" }} />
        <Typography variant="body1">{thisMonth} Leaderboard</Typography>
      </Stack>
      <Box mt={2}>
        {showLoading ? (
          <Skeleton variant="rounded" animation="wave" height={315} />
        ) : leadershipGroup && leadershipGroup.length ? (
          <Swiper
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            spaceBetween={50}
            slidesPerView={1}
            pagination={{ clickable: true, dynamicBullets: true }}
            onSlideChange={() => console.log("slide change")}
            onSwiper={(swiper) => console.log(swiper)}
          >
            {leadershipGroup.map((leadership, gindex) => (
              <SwiperSlide key={gindex}>
                <Table>
                  <TableHead>
                    <TableRow
                      sx={{
                        "& th, & td": { pl: 0.5, pt: 1, pb: 1 },
                      }}
                    >
                      {tableCells.map((cell, index) => {
                        return <TableCell key={index}>{cell}</TableCell>;
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {leadership.map((item, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "& th, & td": { pl: 0.5, pt: 0.5, pb: 0.5 },
                        }}
                      >
                        <TableCell sx={{ p: 0 }}>
                          {gindex * 6 + (index + 1)}
                        </TableCell>
                        <TableCell>
                          <Stack direction={"column"}>
                            <Avatar
                              src={
                                item?.image
                                  ? `https://updash.com/resource/profilepicture/${item?.image}`
                                  : `${
                                      config.assetPath
                                    }/images/material-letter-icons/png/${item?.name
                                      .charAt(0)
                                      .toUpperCase()}.png`
                              }
                              sx={{
                                width: 30,
                                height: 30,
                              }}
                            />
                            {item?.lastseen === 1 && (
                              <span className="dot-flashing">last seen</span>
                            )}
                          </Stack>
                        </TableCell>
                        <TableCell>{item?.name}</TableCell>
                        <TableCell>{item?.lead}</TableCell>
                        <TableCell>{item?.sms}</TableCell>
                        <TableCell>{item?.phone}</TableCell>
                        <TableCell>{item?.app}</TableCell>
                        <TableCell>{item?.per}%</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <Box mt={3}></Box>
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          <Typography variant="body2" width={"100%"} textAlign={"center"}>
            No result
          </Typography>
        )}
      </Box>
    </Card>
  );
}
