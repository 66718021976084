import React, { useContext, useEffect, useState } from "react";
import { AuthContext, BlastContext } from "../../../context";
import config from "../../../config/config";
import helper from "../../../utils/helper";
import moment from "moment";
import {
  BiChevronLeft,
  BiChevronRight,
  BiChevronsLeft,
  BiChevronsRight,
} from "react-icons/bi";

export default function BlastLeadsDataTable({ summaryData }) {
  const { user } = useContext(AuthContext);
  const { getBlastLeadsApi } = useContext(BlastContext);
  //
  const [postData, setPostData] = useState({
    userId: user.Id,
    leads: "",
    leads_status: "",
    page: 1,
    sort: 1,
  });
  const [searchKey, setSearchKey] = useState("");
  //
  const [leadinfo, setLeadinfo] = useState([]);
  const [status, setStatus] = useState([]);
  const [pgConfig, setPgConfig] = useState({
    cpage: 1,
    tpage: 1,
    v1page: 1,
    v2page: 1,
  });

  useEffect(() => {
    if (summaryData) {
      let _postData = { ...postData };
      _postData.userId = user.Id;
      _postData.leads = summaryData?.leads;
      _postData.leads_status = summaryData?.leads_status;
      _postData.page = 1;
      _postData.sort = 1;
      setPostData(_postData);
      getBlastLeads(_postData);
    }
  }, [summaryData]);

  const getBlastLeads = async (params) => {
    const data = await getBlastLeadsApi(params);
    if (data.status === "ok") {
      const res = data.response;
      setLeadinfo(res?.leadinfo);
      setStatus(res?.status);
      setPgConfig({
        cpage: Number(res?.cpage),
        tpage: Number(res?.tpage),
        v1page: Number(res?.v1page),
        v2page: Number(res?.v2page),
      });
    }
  };

  const onClickPagination = (page) => {
    let params = { ...postData };
    params.page = page;
    getBlastLeads(params);
  };

  return (
    <div className="blastleads-datatable">
      <div className="table-toolbar">
        <input
          type="text"
          className="form-control"
          placeholder="Search"
          value={searchKey}
          onChange={(e) => setSearchKey(e.target.value)}
        />
      </div>
      <table className="table table-hover">
        <thead>
          <tr>
            <th className="footable-sortable">
              Name <span className="fooicon fooicon-sort-asc"></span>
            </th>
            <th className="footable-sortable">
              Lead Source <span className="fooicon fooicon-sort"></span>
            </th>
            <th className="footable-sortable">
              Lead Created <span className="fooicon fooicon-sort"></span>
            </th>
            <th className="footable-sortable">
              Last Incoming <span className="fooicon fooicon-sort"></span>
            </th>
            <th className="footable-sortable">
              Last Outgoing <span className="fooicon fooicon-sort"></span>
            </th>
            <th className="footable-sortable">
              Status <span className="fooicon fooicon-sort"></span>
            </th>
            <th onClick="sortbyresult()" className="footable-sortable">
              Result <span className="fooicon fooicon-sort"></span>
            </th>
          </tr>
        </thead>
        <tbody>
          {!!leadinfo &&
            leadinfo
              .filter((item) =>
                item.Name.toLowerCase().includes(searchKey.toLowerCase()),
              )
              .map((item, index) => (
                <tr key={index}>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="lead_img_wrap">
                        <a
                          className="lead_img"
                          href={`${config.smsPath}/s/${item.Responsekey}`}
                        >
                          <img
                            src={
                              Number(item.Ismediaimage) === 1
                                ? `${item.Mediaimage}`
                                : `${
                                    config.assetPath
                                  }/images/material-letter-icons/png/${item.Name.charAt(
                                    0,
                                  ).toUpperCase()}.png`
                            }
                            width={40}
                            height={40}
                            alt=""
                          />
                        </a>
                        <span className="message-icon">
                          <img
                            src={
                              Number(item.type) === 3
                                ? `${config.assetPath}/images/messenger.webp`
                                : `${config.assetPath}/images/sms.svg`
                            }
                            alt=""
                          />
                        </span>
                      </div>
                      <div className="d-flex flex-column ps-2">
                        <a
                          className="text-decoration-none text-dark"
                          href={`${config.smsPath}/s/${item.Responsekey}`}
                        >
                          {item.Name}
                        </a>
                        <small className="text-secondary">{item.Phone}</small>
                      </div>
                    </div>
                  </td>
                  <td>{item.Client}</td>
                  <td>
                    {item.FirstContact
                      ? moment(item.FirstContact).fromNow()
                      : ""}
                  </td>
                  <td>
                    {item.Lastincomming
                      ? moment(item.Lastincomming).fromNow()
                      : ""}
                  </td>
                  <td>
                    {item.Lastoutgoing
                      ? moment(item.Lastoutgoing).fromNow()
                      : ""}
                  </td>
                  <td>
                    <span
                      className={`badge rounded-pill ${helper.getLabelIconClass(
                        item.Label,
                      )}`}
                    >
                      {helper.getLabelText(item.Label)}
                    </span>
                  </td>
                  <td>
                    {Number(status[index]) === 4 ? (
                      <span className="badge rounded-pill bg-primary label-unsubscribe">
                        Unsubscribe
                      </span>
                    ) : Number(status[index]) === 3 ? (
                      <span className="badge rounded-pill bg-red label-undelivered">
                        Undelivered
                      </span>
                    ) : Number(status[index]) === 2 ? (
                      <span className="badge rounded-pill bg-dark label-replied">
                        Replied
                      </span>
                    ) : Number(status[index]) === 1 ? (
                      <span className="badge rounded-pill bg-blue label-delivered">
                        Delivered
                      </span>
                    ) : (
                      <span className="badge rounded-pill bg-blue opacity-50 label-pending">
                        Pending
                      </span>
                    )}
                  </td>
                </tr>
              ))}
        </tbody>
      </table>
      <div className="table-pagination">
        <div className="">
          Page {pgConfig.cpage} of {pgConfig.tpage}
        </div>
        <div className="pagination">
          <button
            className={`btn-page`}
            disabled={Number(pgConfig.cpage) <= 1}
            onClick={() => onClickPagination(1)}
          >
            <BiChevronsLeft />
          </button>
          <button
            className={`btn-page`}
            disabled={Number(pgConfig.cpage) <= 1}
            onClick={() => onClickPagination(Number(pgConfig.cpage) - 1)}
          >
            <BiChevronLeft />
          </button>
          {[...Array(Number(pgConfig.tpage))].map((item, index) =>
            index + 1 >= Number(pgConfig.v1page) &&
            index + 1 <= Number(pgConfig.v2page) ? (
              <button
                key={index}
                className={`btn-page${
                  Number(Number(pgConfig.cpage)) === index + 1 ? " active" : ""
                }`}
                onClick={() => onClickPagination(index + 1)}
              >
                {index + 1}
              </button>
            ) : null,
          )}
          <button
            className={`btn-page`}
            disabled={Number(pgConfig.cpage) >= Number(pgConfig.tpage)}
            onClick={() => onClickPagination(Number(pgConfig.cpage) + 1)}
          >
            <BiChevronRight />
          </button>
          <button
            className={`btn-page`}
            disabled={Number(pgConfig.cpage) >= Number(pgConfig.tpage)}
            onClick={() => onClickPagination(Number(pgConfig.tpage))}
          >
            <BiChevronsRight />
          </button>
        </div>
      </div>
    </div>
  );
}
