import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../../context";
import TablePagination from "../../TablePagination/TablePagination";
import { LiaEdit } from "react-icons/lia";
import { IoCloseCircleOutline } from "react-icons/io5";
import config from "../../../config/config";
import ClientRemoveCampaignModal from "../../modals/client_remove_campaign_modal";
import DateFilterPicker from "../../DateFilterPicker/DateFilterPicker";

export default function DFCampaignsActionDataTable({
  usertype,
  isSuperAdmin,
  campaigns,
  selectedDays,
  setSelectedDays,
  customDateRage,
  setCustomDateRange,
  reloadData,
}) {
  const { user } = useContext(AuthContext);
  const [searchKey, setSearchKey] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const perPage = 25;
  const [removeCmpModal, setRemoveCmpModal] = useState("");

  useEffect(() => {
    console.log(user);
  }, []);

  const datatable = (data) => {
    return data.length > 0
      ? data.filter((el) =>
          el.Name?.toLowerCase().includes(searchKey?.toLowerCase()),
        )
      : [];
  };

  const removecampaign = (campaignId) => {
    setRemoveCmpModal(campaignId);
  };

  return (
    <div className="card">
      <div className="card-header">
        <div className="d-flex">
          <input
            type="text"
            className="form-control form-control-fav"
            placeholder="Search"
            onChange={(e) => {
              setSearchKey(e.target.value);
              setCurrentPage(1);
            }}
          />
          <div className="date-filter ms-auto">
            <DateFilterPicker
              selectedDays={selectedDays}
              setSelectedDays={setSelectedDays}
              customDateRage={customDateRage}
              setCustomDateRange={setCustomDateRange}
            />
          </div>
        </div>
      </div>
      <div className="card-body p-0">
        <table className="table table-hover m-0">
          <thead>
            <tr>
              <th>Type</th>
              <th>Job #</th>
              <th>Campaign</th>
              <th>Start Date</th>
              <th>Leads</th>
              <th>Engagement</th>
              <th>Appointments</th>
              <th>Sold </th>
              <th>Last Activity</th>
              {Number(usertype) === 1 ||
                (Number(usertype) === 5 && <th>Actions</th>)}
            </tr>
          </thead>
          <tbody>
            {campaigns &&
              datatable(campaigns).map((item, index) =>
                index >= (currentPage - 1) * perPage &&
                index < currentPage * perPage ? (
                  <tr key={index}>
                    <td>
                      <a
                        className="text-decoration-none"
                        target="_blank"
                        href={`${config.campaignsPath}/dashboard?cid=${item.Keyid}`}
                        rel="noreferrer"
                      >
                        <img
                          width={50}
                          src={
                            item.LogoPath
                              ? `${config.baseUrl}/${item.LogoPath}`
                              : `${config.baseUrl}/assets/images/avatar.png`
                          }
                          alt=""
                        />
                      </a>
                    </td>
                    <td>
                      <a
                        className="text-decoration-none"
                        target="_blank"
                        href={`${config.campaignsPath}/dashboard?cid=${item.Keyid}`}
                        rel="noreferrer"
                      >
                        {item?.Job}
                      </a>
                    </td>
                    <td>
                      <a
                        className="text-decoration-none"
                        target="_blank"
                        href={`${config.campaignsPath}/dashboard?cid=${item.Keyid}`}
                        rel="noreferrer"
                      >
                        {item?.Name}
                      </a>
                    </td>
                    <td>{item?.StartDate}</td>
                    <td>{item?.leads}</td>
                    <td>
                      {item?.leads
                        ? Math.round(
                            (item?.engagement / item?.leads) * 100 * 10,
                          ) / 10
                        : 0}
                      %
                    </td>
                    <td>{item?.appts}</td>
                    <td>{item?.sold}</td>
                    <td>
                      {item?.Lastactivity ? (
                        <abbr className="timeago" title={item.Lastactivity}>
                          {item.Lastactivity}
                        </abbr>
                      ) : (
                        "---"
                      )}
                    </td>
                    {Number(usertype) === 1 ||
                      (Number(usertype) === 5 && (
                        <td>
                          <div className="d-flex align-items-center justify-content-center">
                            <a
                              className="btn btn-sm btn-outline-secondary 
                              d-flex align-items-center justify-content-center p-1"
                              href={`${config.campaign_setting}/edit/${item.Keyid}/2`}
                            >
                              <LiaEdit />
                            </a>
                            <button
                              className="btn btn-sm btn-outline-secondary 
                              d-flex align-items-center justify-content-center p-1"
                              onClick={() => removecampaign(item.Id)}
                            >
                              <IoCloseCircleOutline />
                            </button>
                          </div>
                        </td>
                      ))}
                  </tr>
                ) : null,
              )}
          </tbody>
        </table>
      </div>
      <div className="card-footer py-4 bg-white">
        <TablePagination
          data={datatable(campaigns)}
          perPage={perPage}
          page={currentPage}
          onChangePage={setCurrentPage}
        />
      </div>
      {/*  */}
      <ClientRemoveCampaignModal
        cmpid={removeCmpModal}
        isSuperAdmin={isSuperAdmin}
        isOpenModal={!!removeCmpModal}
        afterOpenModal={() => {}}
        closeModal={() => setRemoveCmpModal("")}
        reloadData={() => {
          setRemoveCmpModal("");
          reloadData();
        }}
      />
    </div>
  );
}
