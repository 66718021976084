import { BsExclamationTriangleFill } from "react-icons/bs";
import { HiOutlineCpuChip } from "react-icons/hi2";
import Modal from "react-modal";
import React from "react";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "560px",
    padding: "15px",
    marginTop: "20px",
    marginBottom: "20px",
  },
};

Modal.setAppElement("#root");

export default function ConversationAIErrorModal({
  isOpenModal = false,
  afterOpenModal,
  closeModal,
}) {
  return (
    <Modal
      isOpen={isOpenModal}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="AI Error"
    >
      <div className="modal-header">
        <h4>
          <HiOutlineCpuChip size={18} className="me-1" />
          AI Error
        </h4>
      </div>
      <div className="modal-body">
        <form>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 mt-3">
              <p className="text-center my-3">
                <BsExclamationTriangleFill size={42} color="#ff0000" />
              </p>
              <p className="text-center">Server Error. Pls try again</p>
            </div>
          </div>
        </form>
      </div>
      <div className="modal-footer mt-3">
        <button className="btn btn-outline-secondary" onClick={closeModal}>
          OK
        </button>
      </div>
    </Modal>
  );
}
