import React from "react";
import { ButtonGroup } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import SplitButton from "react-bootstrap/SplitButton";
import { useEffect } from "react";
import { useContext } from "react";

import { useState } from "react";
import "bootstrap-daterangepicker/daterangepicker.css";
import { Box, Card, Grid, Stack, Typography } from "@mui/material";
import {
  ConversationsMessage1Box,
  ConversationsMessage2Box,
} from "../../components";
import {
  AuthContext,
  ChatContext,
  ConversationContext,
  FirebaseContext,
} from "../../context";
import {
  ConversationsFilters,
  ConversationsLeadsList,
  ConversationsChat,
} from "../../pages";

export default function ConversationsPage() {
  const { user } = useContext(AuthContext);
  const {
    getConversationPreDataApi,
    info,
    filterOption,
    setFilterOption,
    filterParams,
    setFilterParams,
    mention,
    unsetnewleadApi,
    markasreadApi,
    lockedLead,
    setLockedLead,
  } = useContext(ConversationContext);
  const { removeLiveActivity, getConversationLocks } =
    useContext(FirebaseContext);
  const { activatedLeadId, setActivatedLeadId } = useContext(ChatContext);

  const [selectedMsgArr, setSelectedMsgArr] = useState([]);
  const [showSelectionBoard, setShowSelectionBoard] = useState(false);

  const getConversationPreData = async () => {
    getConversationPreDataApi({ userId: user.Id });
  };

  useEffect(() => {
    setFilterOption({
      ...filterOption,
      filter_lead: Number(mention.ismention) === 0 ? 0 : mention.mentionlead,
    });
  }, [mention]);

  const toggleMsgSelection = () => {
    let _selectedMsgArr = [];
    info?.val.map((item) => {
      if (!selectedMsgArr.includes(item.leadId)) {
        _selectedMsgArr.push(item.leadId);
      }
    });
    setSelectedMsgArr(_selectedMsgArr);
  };

  const markAsResponded = async () => {
    let sid = selectedMsgArr.join(",");
    if (filterOption.filtertype === "L12") {
      const res = await unsetnewleadApi({ userId: user.Id, lead: sid });
      if (res.status === "ok") {
        setSelectedMsgArr([]);
        getConversationPreData();
      }
    } else {
      const res = await markasreadApi({ userId: user.Id, lead: sid, flag: 0 });
      if (res.status === "ok") {
        setSelectedMsgArr([]);
        getConversationPreData();
      }
    }
  };

  const getMessageLead = async (lead) => {
    if (await checkLock(lead)) {
      activate(lead);
    }
  };

  const checkLock = async (lead) => {
    let flag = true;
    const leadId = lead?.leadId;
    const conversationsLocks = await getConversationLocks();
    conversationsLocks.forEach((data) => {
      const userId = parseInt(data["Userid"]);
      const session = data["Session"];
      const lockLead = parseInt(data["Lead"]);
      if (leadId == lockLead) {
        flag = false;
        if (user?.Id == userId && session == user?.Sessionid) {
          flag = true;
        }
      }
    });
    if (flag) {
      return true;
    } else {
      setLockedLead(lead);
      /**
       * 🔶🔶🔶🔶🔶
       * show locked lead modal
       */
      return false;
    }
  };

  const activate = (lead) => {
    setShowSelectionBoard(false);
    setSelectedMsgArr([]);

    removeLiveActivity(user?.Sessionid);
    setActivatedLeadId(lead?.leadId);
  };

  const handleSelect = async (value, index) => {
    console.log(value, index);
    if (index === "lead") {
      console.log("set activated id");
      setShowSelectionBoard(false);
      setSelectedMsgArr([]);
      removeLiveActivity(user?.Sessionid);
      setActivatedLeadId(value?.Lead);
    }
  };

  const onNextLead = () => {
    let activatedLeadIndex = info.val.findIndex(
      (x) => x.leadId === activatedLeadId,
    );
    let nextLeadIndex = activatedLeadIndex + 1;
    if (nextLeadIndex > info.val.length) {
      nextLeadIndex = 0;
    }
    let nextLeadId = info.val[nextLeadIndex].leadId;
    getMessageLead(nextLeadId);
  };

  return (
    <Box p={2}>
      <Grid container spacing={1}>
        <Grid item md={5} xs={12}>
          <Grid container spacing={1}>
            <Grid item md={5} xs={12}>
              <ConversationsFilters handleSelect={handleSelect} />
            </Grid>
            <Grid item md={7} xs={12}>
              <ConversationsLeadsList
                selectedMsgArr={selectedMsgArr}
                setSelectedMsgArr={setSelectedMsgArr}
                setShowSelectionBoard={setShowSelectionBoard}
                getMessageLead={getMessageLead}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={7} xs={12}>
          {showSelectionBoard && !activatedLeadId && (
            <Card
              sx={{
                p: "20px 12px",
              }}
            >
              <Box>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"end"}
                >
                  <SplitButton
                    className="select-all-dropdown ms-auto"
                    title={"Select All"}
                    onClick={() => {
                      setSelectedMsgArr(info?.val.map((e) => e.leadId));
                    }}
                  >
                    <Dropdown.Item
                      eventKey="1"
                      onClick={() => toggleMsgSelection()}
                    >
                      Toggle Selection
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey="2"
                      onClick={() => {
                        setSelectedMsgArr([]);
                      }}
                    >
                      Unselect All
                    </Dropdown.Item>
                  </SplitButton>
                  <DropdownButton
                    className="select-action-dropdown ms-2"
                    as={ButtonGroup}
                    title={"Actions"}
                  >
                    <Dropdown.Item
                      eventKey="1"
                      onClick={() => markAsResponded()}
                    >
                      Mark as Responded
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey="2"
                      // onClick={() => exportAll()}
                    >
                      Compose Message
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey="3"
                      // onClick={() => setApptChangeStatusOpenModal(true)}
                    >
                      Change Status
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey="4"
                      // onClick={() => setApptChangeApptStatusOpenModal(true)}
                    >
                      Add Label
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey="4"
                      // onClick={() => setApptChangeApptStatusOpenModal(true)}
                    >
                      Assign Funnel
                    </Dropdown.Item>
                  </DropdownButton>
                </Stack>
                <hr />
                <Typography
                  variant="body2"
                  sx={{
                    pl: 3,
                  }}
                >
                  {selectedMsgArr.length > 0 ? (
                    <>
                      {selectedMsgArr.length} lead(s) selected.
                      <a className="text-blue ms-1">Select all leads?</a>
                    </>
                  ) : (
                    `Please select a lead.`
                  )}
                </Typography>
              </Box>
            </Card>
          )}
          {!showSelectionBoard && activatedLeadId && (
            <Grid container spacing={1}>
              <Grid item md={6} sx={12}>
                <ConversationsMessage1Box
                  leadId={activatedLeadId}
                  filterParams={filterParams}
                  onNextLead={onNextLead}
                />
                {/* <ConversationsChat /> */}
              </Grid>
              <Grid item md={6} sx={12}>
                <ConversationsMessage2Box
                  leadId={activatedLeadId}
                  filterParams={filterParams}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
