import React from "react";

export default function ClientHoursForm({ postData, setPostData, onSave }) {
  return (
    <>
      <h6 className="tab-tile">Hours</h6>
      <hr />
      <div className="row">
        <div className="col col-sm-3">
          <div className="form-group">
            <label className="mb-2">Timezone</label>
            <select
              className="form-select"
              value={postData?.time_zone}
              onChange={(e) =>
                setPostData({ ...postData, time_zone: e.target.value })
              }
            >
              <option value="US/Eastern">Eastern</option>
              <option value="US/Central">Central</option>
              <option value="US/Mountain">Mountain</option>
              <option value="US/Arizona">Arizona</option>
              <option value="US/Pacific">Pacific</option>
              <option value="US/Alaska">Alaska</option>
              <option value="US/Hawaii">Hawaii</option>
            </select>
          </div>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-12">
          <table className="table table-hover">
            <thead>
              <tr>
                <th></th>
                <th>Closed</th>
                <th>From</th>
                <th>To</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Monday</td>
                <td>
                  <input
                    type="checkbox"
                    checked={postData?.Isopen_d1 === "0"}
                    onChange={(e) =>
                      setPostData({
                        ...postData,
                        Isopen_d1: e.target.checked ? "0" : "1",
                      })
                    }
                  />
                </td>
                <td>
                  <input
                    type="time"
                    value={postData?.From_d1}
                    onChange={(e) =>
                      setPostData({ ...postData, From_d1: e.target.value })
                    }
                  />
                </td>
                <td>
                  <input
                    type="time"
                    value={postData?.To_d1}
                    onChange={(e) =>
                      setPostData({ ...postData, To_d1: e.target.value })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>Tuesday</td>
                <td>
                  <input
                    type="checkbox"
                    checked={postData?.Isopen_d2 === "0"}
                    onChange={(e) =>
                      setPostData({
                        ...postData,
                        Isopen_d2: e.target.checked ? "0" : "1",
                      })
                    }
                  />
                </td>
                <td>
                  <input
                    type="time"
                    value={postData?.From_d2}
                    onChange={(e) =>
                      setPostData({ ...postData, From_d2: e.target.value })
                    }
                  />
                </td>
                <td>
                  <input
                    type="time"
                    value={postData?.To_d2}
                    onChange={(e) =>
                      setPostData({ ...postData, To_d2: e.target.value })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>Wednesday</td>
                <td>
                  <input
                    type="checkbox"
                    checked={postData?.Isopen_d3 === "0"}
                    onChange={(e) =>
                      setPostData({
                        ...postData,
                        Isopen_d3: e.target.checked ? "0" : "1",
                      })
                    }
                  />
                </td>
                <td>
                  <input
                    type="time"
                    value={postData?.From_d3}
                    onChange={(e) =>
                      setPostData({ ...postData, From_d3: e.target.value })
                    }
                  />
                </td>
                <td>
                  <input
                    type="time"
                    value={postData?.To_d3}
                    onChange={(e) =>
                      setPostData({ ...postData, To_d3: e.target.value })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>Thursday</td>
                <td>
                  <input
                    type="checkbox"
                    checked={postData?.Isopen_d4 === "0"}
                    onChange={(e) =>
                      setPostData({
                        ...postData,
                        Isopen_d4: e.target.checked ? "0" : "1",
                      })
                    }
                  />
                </td>
                <td>
                  <input
                    type="time"
                    value={postData?.From_d4}
                    onChange={(e) =>
                      setPostData({ ...postData, From_d4: e.target.value })
                    }
                  />
                </td>
                <td>
                  <input
                    type="time"
                    value={postData?.To_d4}
                    onChange={(e) =>
                      setPostData({ ...postData, To_d4: e.target.value })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>Friday</td>
                <td>
                  <input
                    type="checkbox"
                    checked={postData?.Isopen_d5 === "0"}
                    onChange={(e) =>
                      setPostData({
                        ...postData,
                        Isopen_d5: e.target.checked ? "0" : "1",
                      })
                    }
                  />
                </td>
                <td>
                  <input
                    type="time"
                    value={postData?.From_d5}
                    onChange={(e) =>
                      setPostData({ ...postData, From_d5: e.target.value })
                    }
                  />
                </td>
                <td>
                  <input
                    type="time"
                    value={postData?.To_d5}
                    onChange={(e) =>
                      setPostData({ ...postData, To_d5: e.target.value })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>Saturday</td>
                <td>
                  <input
                    type="checkbox"
                    checked={postData?.Isopen_d6 === "0"}
                    onChange={(e) =>
                      setPostData({
                        ...postData,
                        Isopen_d6: e.target.checked ? "0" : "1",
                      })
                    }
                  />
                </td>
                <td>
                  <input
                    type="time"
                    value={postData?.From_d6}
                    onChange={(e) =>
                      setPostData({ ...postData, From_d6: e.target.value })
                    }
                  />
                </td>
                <td>
                  <input
                    type="time"
                    value={postData?.To_d6}
                    onChange={(e) =>
                      setPostData({ ...postData, To_d6: e.target.value })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>Sunday</td>
                <td>
                  <input
                    type="checkbox"
                    checked={postData?.Isopen_d7 === "0"}
                    onChange={(e) =>
                      setPostData({
                        ...postData,
                        Isopen_d7: e.target.checked ? "0" : "1",
                      })
                    }
                  />
                </td>
                <td>
                  <input
                    type="time"
                    value={postData?.From_d7}
                    onChange={(e) =>
                      setPostData({ ...postData, From_d7: e.target.value })
                    }
                  />
                </td>
                <td>
                  <input
                    type="time"
                    value={postData?.To_d7}
                    onChange={(e) =>
                      setPostData({ ...postData, To_d7: e.target.value })
                    }
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {/*  */}
      <hr className="mt-4" />
      <div className="d-flex align-items-center justify-content-center">
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => onSave()}
        >
          Save
        </button>
        <button type="button" className="btn btn-outline-secondary ms-1">
          Cancel
        </button>
      </div>
    </>
  );
}
