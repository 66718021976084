import React from "react";
import { TextField, Box, InputLabel } from "@mui/material";
import { ColorsConfig } from "../../config";

export default function PrimaryTextArea(props) {
  const {
    label,
    placeholder,
    name,
    autoComplete,
    autoFocus = false,
    handleChange,
    handleBlur,
    value,
    required = false,
    rows = 4,
    sx,
  } = props;

  return (
    <Box>
      {label && (
        <InputLabel
          sx={{ fontSize: "16px", color: ColorsConfig.light.primaryColor }}
        >
          {label}
          {required && (
            <span style={{ color: ColorsConfig.light.error }}>*</span>
          )}
        </InputLabel>
      )}
      <TextField
        margin="none"
        fullWidth
        name={name}
        size="small"
        placeholder={placeholder}
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        onChange={handleChange}
        onBlur={(e) => {
          if (handleBlur) {
            handleBlur(e);
          }
        }}
        value={value}
        required={required}
        multiline
        rows={rows}
        sx={{
          ...sx,
          "& fieldset": {
            borderRadius: "4px",
          },
          mt: 0.5,
        }}
      />
    </Box>
  );
}
