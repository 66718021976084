import React, { useState } from "react";
import { Box, Popover, Stack } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Constants } from "../../config";
import { ProjectCircle } from "..";
import { FormatHelper } from "../../utils";

export default function LabelTagPicker(props) {
  const { value = Constants.labelTags[0].value, handleChange } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [color, setColor] = useState(FormatHelper.getColorFromClass(value));

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "label-tag-picker-popover" : undefined;

  const handleClick = (item) => {
    setColor(item.color);
    handleChange(item.value);
    handleClose();
  };

  return (
    <Box>
      <Stack
        sx={{ cursor: "pointer" }}
        direction={"row"}
        alignItems={"center"}
        spacing={0.5}
        onClick={openMenu}
      >
        <ProjectCircle width={20} color={color} />
        <KeyboardArrowDownIcon />
      </Stack>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Stack
          direction={"row"}
          alignItems={"center"}
          spacing={1}
          sx={{ p: 1 }}
        >
          {Constants.labelTags.map((item, index) => {
            return (
              <Box
                key={index}
                sx={{ cursor: "pointer" }}
                onClick={() => handleClick(item)}
              >
                <ProjectCircle color={item.color} width={20} />
              </Box>
            );
          })}
        </Stack>
      </Popover>
    </Box>
  );
}
