import { Box } from "@mui/material";
import React from "react";
import { RotatingLines } from "react-loader-spinner";

// eslint-disable-next-line react/prop-types
export default function Loader({ loading = true }) {
  return (
    <Box
      style={{
        display: loading ? "flex" : "none",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
        background: "rgba(0, 0, 0, 0.45)",
        zIndex: 9999,
      }}
    >
      <RotatingLines
        strokeColor="#4287f5"
        strokeWidth="3"
        animationDuration="0.75"
        width="60"
        visible={true}
      />
    </Box>
  );
}
