import React, { useState } from "react";
import TablePagination from "../../TablePagination/TablePagination";
import { ButtonGroup } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import config from "../../../config/config";
import ClientDeleteModal from "../../modals/client_delete_modal";
import { Link } from "react-router-dom";
import {
  Avatar,
  Box,
  Button,
  Card,
  Chip,
  Menu,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { PrimaryInput } from "../../../components";
import { ColorsConfig } from "../../../config";
import { TimeHelper } from "../../../utils";

export default function ClientsDataTable({
  clients,
  isexecutive,
  isDealerfunnelUser,
}) {
  const [searchKey, setSearchKey] = useState("");
  const [selectedMenuType, setSelectedMenuType] = useState({
    index: 0,
    title: "All Clients",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const perPage = 25;
  const [clientDeleteModal, setClientDeleteModal] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const menuItems = [
    {
      index: 0,
      title: "All Clients",
    },
    {
      index: 3,
      title: "Dealership",
    },
    {
      index: 2,
      title: "Dealer Group",
    },
    {
      index: 1,
      title: "Agency",
    },
  ];
  const tableHeaderCells = [
    { index: 0, title: "" },
    { index: 0, title: "Name" },
    { index: 0, title: "Type" },
    { index: 0, title: "City" },
    { index: 0, title: "State" },
    { index: 0, title: "Campaigns" },
    { index: 1, title: "Ad Account" },
    { index: 1, title: "Page Name" },
    { index: 0, title: "Last Campaign" },
    { index: 0, title: "Action" },
  ];

  const dataTableFilter = (data) => {
    return data.filter(
      (el) =>
        el.Name.toLowerCase().includes(searchKey.toLowerCase()) &&
        (selectedMenuType.index === 0 ||
          Number(el.Ctype) === selectedMenuType.index),
    );
  };

  const removeClient = (clientId) => {
    setClientDeleteModal(clientId);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = (e) => {
    setSelectedMenuType(e);
    setAnchorEl(null);
  };

  return (
    <Card
      sx={{
        p: 2,
        height: "100%",
      }}
    >
      <Stack
        direction={{ xs: "column", md: "row" }}
        alignItems={"center"}
        justifyContent={"space-between"}
        spacing={1}
      >
        <PrimaryInput
          type={"text"}
          placeholder={"Search"}
          value={searchKey}
          handleChange={(e) => {
            setSearchKey(e.target.value);
            setCurrentPage(1);
          }}
          sx={{
            width: { xs: "80%", md: 200 },
            fontSize: 12,
          }}
        />
        <Box>
          <Button
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            variant="contained"
            onClick={handleMenuClick}
            endIcon={<ArrowDropDownIcon />}
          >
            {selectedMenuType.title}
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={() => {
              setAnchorEl(null);
            }}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {menuItems.map((item, index) => {
              return (
                <MenuItem key={index} onClick={() => handleMenuClose(item)}>
                  {item.title}
                </MenuItem>
              );
            })}
          </Menu>
        </Box>
      </Stack>
      <Box mt={1}>
        <Table>
          <TableHead>
            <TableRow
              sx={{
                "& th, & td": { pl: 0.5, pt: 1, pb: 1, textAlign: "start" },
              }}
            >
              {tableHeaderCells.map((item, index) => {
                if (
                  item.index === 0 ||
                  item.index === Number(isDealerfunnelUser)
                ) {
                  return <TableCell key={index}>{item.title}</TableCell>;
                }
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {clients && dataTableFilter(clients).length > 0
              ? dataTableFilter(clients).map((item, index) => {
                  if (
                    index >= (currentPage - 1) * perPage &&
                    index < currentPage * perPage
                  ) {
                    return (
                      <TableRow
                        key={index}
                        sx={{
                          "& th, & td": { pl: 0.5, pt: 1, pb: 1 },
                        }}
                      >
                        <TableCell>
                          {Number(item.ClientType) === 1 ? (
                            <a
                              href={`${config.dealersocket}/edit/${item.Keyid}`}
                            >
                              <Avatar
                                sx={{ width: 24, height: 24 }}
                                src={
                                  item?.Logo
                                    ? `${config.domain}/${item?.Logo}`
                                    : `${config.domain}/assets/images/avatar.png`
                                }
                              />
                            </a>
                          ) : (
                            <Link to={`/clients/edit/${item.Keyid}`}>
                              <Avatar
                                sx={{ width: 24, height: 24 }}
                                src={
                                  item?.Logo
                                    ? `${config.domain}/${item?.Logo}`
                                    : `${config.domain}/assets/images/avatar.png`
                                }
                              />
                            </Link>
                          )}
                        </TableCell>
                        <TableCell>{item.Name}</TableCell>
                        <TableCell>
                          {Number(item.Ctype) === 1 ? (
                            <Chip label="Agency" color="primary" size="small" />
                          ) : Number(item.Ctype) === 2 ? (
                            <Chip
                              label="Dealer Group"
                              size="small"
                              sx={{ color: ColorsConfig.light.secondaryInfo }}
                            />
                          ) : Number(item.Ctype) === 3 ? (
                            <Chip
                              label="Dealership"
                              size="small"
                              sx={{
                                color: ColorsConfig.light.primaryTextColor,
                                backgroundColor:
                                  ColorsConfig.light.secondaryBgColor,
                              }}
                            />
                          ) : null}
                        </TableCell>
                        <TableCell>{item.City}</TableCell>
                        <TableCell>{item.State}</TableCell>
                        <TableCell>{item.Campaignnumber}</TableCell>
                        {Number(isDealerfunnelUser) === 1 && (
                          <TableCell>{item.AdAccount}</TableCell>
                        )}
                        {Number(isDealerfunnelUser) === 1 && (
                          <TableCell>{item.PageName}</TableCell>
                        )}
                        <TableCell>
                          {item.Lastcampaigndate === "0" ||
                          item.Lastcampaigndate === null ? (
                            "N/A"
                          ) : item.Lastcampaigndate === "1" ? (
                            <Chip
                              label="In progress"
                              size="small"
                              color="success"
                              sx={{
                                color: ColorsConfig.light.primaryTextColor,
                              }}
                            />
                          ) : (
                            `${TimeHelper.timeAgo(`${item.Lastcampaigndatearr[0]}-
                            ${item.Lastcampaigndatearr[1]}-${item.Lastcampaigndatearr[2]}`)}`
                          )}
                        </TableCell>
                        <TableCell>
                          {isexecutive ? (
                            <div className="action-box">
                              <a href="<?=$url?>" className="action-btn">
                                <i className="icon-edit" title="Update"></i>
                              </a>
                            </div>
                          ) : (
                            <DropdownButton
                              as={ButtonGroup}
                              align={{ lg: "end" }}
                              variant="default"
                              title={`Actions`}
                            >
                              <Dropdown.Item
                                eventKey="1"
                                href={`${
                                  Number(item.ClientType) === 1
                                    ? config.dealersocket
                                    : "/clients"
                                }/edit/${item.Keyid}`}
                              >
                                Update Client
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="2"
                                onClick={() => removeClient(item.Id)}
                              >
                                Remove Client
                              </Dropdown.Item>
                            </DropdownButton>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  }
                })
              : null}
          </TableBody>
        </Table>
        <Box mt={2}>
          <TablePagination
            data={dataTableFilter(clients)}
            perPage={perPage}
            page={currentPage}
            onChangePage={setCurrentPage}
          />
        </Box>
      </Box>
      <ClientDeleteModal
        isOpenModal={!!clientDeleteModal}
        afterOpenModal={() => {}}
        closeModal={() => setClientDeleteModal("")}
        clientId={clientDeleteModal}
      />
    </Card>
  );
}
