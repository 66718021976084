import React from "react";
import { Autocomplete, Box, TextField } from "@mui/material";

export default function AutoCompleteWithLeadApi(props) {
  const { options, handleChange, handleSelect, placeholder } = props;

  const onInputChange = (event, value, reason) => {
    console.log(event);
    if (reason === "input") {
      handleChange(value);
    }
  };

  const onChange = (event, value, reason) => {
    console.log(event, reason);
    handleSelect(value);
  };

  return (
    <Box>
      <Autocomplete
        id="auto-complete-with-api"
        options={options}
        style={{ width: "100%" }}
        onInputChange={onInputChange}
        onChange={onChange}
        includeInputInList
        filterSelectedOptions
        autoComplete
        filterOptions={(x) => x}
        getOptionLabel={(option) => option.pname}
        noOptionsText={"No leads"}
        renderOption={(props, option) => {
          return (
            <Box component={"li"} key={option.value} {...props}>
              {option.pname} {option.clientName}
              {/* <Stack direction={"row"}>
                <Typography></Typography>
                <Typography></Typography>
              </Stack> */}
            </Box>
          );
        }}
        renderInput={(params) => {
          return <TextField {...params} fullWidth placeholder={placeholder} />;
        }}
        size="small"
      />
    </Box>
  );
}
