import React, { useEffect, useRef } from "react";
import plupload from "plupload";
import helper from "../../utils/helper";
import { useContext } from "react";
import { AxiosContext } from "../../context";
import { IoImageOutline } from "react-icons/io5";

function PlAttachFileUploader({
  url = "/upload",
  browseButtonId = "file-picker",
  extensions = "jpg,gif,png",
  onSuccess,
  onError,
}) {
  const uploaderRef = useRef(null);
  const inputRef = useRef(null);
  const { setLoading } = useContext(AxiosContext);

  useEffect(() => {
    // Initialize uploader on component mount
    initUploader();

    // Cleanup when the component unmounts
    return () => {
      if (uploaderRef.current) {
        uploaderRef.current.destroy();
      }
    };
  }, []);

  const initUploader = () => {
    uploaderRef.current = new plupload.Uploader({
      runtimes: "html5,flash,silverlight,html4",
      browse_button: browseButtonId, // ID of the file input element or a button
      url: url, // Your server endpoint for file upload
      multipart: true,
      multipart_params: {
        // Additional parameters if needed
      },
      filters: {
        // Specify file type filters if needed
        max_file_size: "10mb",
        mime_types: [{ title: "Image files", extensions: extensions }],
      },
    });

    uploaderRef.current.init();

    uploaderRef.current.bind("FilesAdded", (up, files) => {
      // Handle when files are added to the upload queue
      if (helper.imageFileTypeCheck(files[0].name)) {
        setLoading(true);
        startUpload();
      } else {
        alert("File format not accepted. Please try again.");
      }
    });

    uploaderRef.current.bind("UploadProgress", (up, file) => {
      // Handle upload progress
      console.log(up, file);
    });

    uploaderRef.current.bind("FileUploaded", (up, file, response) => {
      // Handle when a file is successfully uploaded
      setLoading(false);
      console.log("File uploaded:", response.response);
      const _response = JSON.parse(response.response);
      onSuccess(up, file, _response);
    });

    uploaderRef.current.bind("Error", (up, err) => {
      // Handle errors during upload
      setLoading(false);
      console.log("File upload error:", err);
      onError(up, err);
    });
  };

  const startUpload = () => {
    if (uploaderRef.current) {
      uploaderRef.current.start();
    }
  };

  return (
    <div>
      <input
        type="file"
        id={browseButtonId}
        className="d-none"
        ref={inputRef}
      />
      <IoImageOutline
        size={18}
        className="me-1"
        title="Attach Image"
        onClick={() => inputRef.current.click()}
      />
    </div>
  );
}

export default PlAttachFileUploader;
