import { Link, useLocation, useNavigate } from "react-router-dom";
import { Logo } from "../../assets/images";
import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "../../context";
import { toast } from "react-toastify";
import { AuthForgotPasswordModal } from "../../components";

export default function LoginPage() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { login, token } = useContext(AuthContext);
  const [credentials, setCredentials] = useState({
    email: "kenny@dealerfunnel.com",
    password: "dealerfunnel",
  });
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (token) {
      navigate(`/dashboard`);
    }
  }, [token]);

  const forgot = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const onSignIn = async () => {
    const fd = new FormData();
    fd.append("email", credentials.email);
    fd.append("password", credentials.password);
    const data = await login(fd);
    const res = data?.response;
    if (data?.status === "ok") {
      navigate(`${state.from ? state.from : "/dashboard"}`);
      toast.success("Successfully Log In!");
    } else if (data?.status === "fail") {
      console.log("err: ", res.error);
      toast.info("Failed log in.");
    } else {
      console.log("res: ", data);
      toast.info("Something went wrong. Try again later.");
    }
  };

  return (
    <main className="auth bg-primary">
      <div className="card auth-form">
        <div
          className="card-header d-flex 
        justify-content-center border-bottom-0 bg-white pb-0"
        >
          <img src={Logo} className="logo-img mt-3" alt="" />
        </div>
        <div className="card-body">
          <div className="form-group mb-2">
            <input
              type="email"
              className="form-control"
              placeholder="Username"
              value={credentials.email}
              onChange={(e) =>
                setCredentials({ ...credentials, email: e.target.value })
              }
            />
          </div>
          <div className="form-group position-relative d-flex align-items-center mb-2">
            <input
              type="password"
              className="form-control"
              placeholder="Password"
              value={credentials.password}
              onChange={(e) =>
                setCredentials({ ...credentials, password: e.target.password })
              }
            />
            <button className="btn btn-forgot" onClick={forgot}>
              Forgot?
            </button>
          </div>
          <button
            type="button"
            className="btn btn-primary w-100 mb-2"
            onClick={() => onSignIn()}
          >
            SIGN IN
          </button>
          <p>
            By signing in you agree to our{" "}
            <Link to={`/terms`}>Terms and Conditions</Link>
          </p>
        </div>
      </div>
      <AuthForgotPasswordModal
        open={openModal}
        handleCloseModal={handleCloseModal}
      />
    </main>
  );
}
