import React, { useContext, useState, useRef } from "react";
import { BiLineChart } from "react-icons/bi";
import { AuthContext, ReportContext } from "../../context";
import { useEffect } from "react";
import { DateFilterMonthPicker2, DropdownMultiSelect } from "../../components";
import moment from "moment";
import { BsDownload } from "react-icons/bs";
import { DownloadTableExcel } from "react-export-table-to-excel";
import helper from "../../utils/helper";

export default function UserStatsReportPage() {
  const { user } = useContext(AuthContext);
  const { userstatsApi, ajaxuserstatsApi } = useContext(ReportContext);
  const tableRef = useRef(null);
  //
  const [customColor, setCustomColor] = useState();
  const [clientList, setClientList] = useState([]);
  //
  const [selectedDate, setSelectedDate] = useState("1");
  const [customDateRage, setCustomDateRange] = useState({
    start: new Date(moment().subtract(1, "months")),
    end: new Date(),
  });
  const [selectedClients, setSelectedClients] = useState([]);
  //
  const [tableData, setTableData] = useState(undefined); // object
  const [total, setTotal] = useState(undefined); // object
  const [search, setSearch] = useState("");

  useEffect(() => {
    getUserStatsPageData();
    console.log(customColor);
  }, []);

  const getUserStatsPageData = async () => {
    const data = await userstatsApi({ userId: user.Id });
    if (data?.status === "ok") {
      const res = data.response;
      setCustomColor(res?.custom_color);
      setClientList(res?.client);
    }
  };

  useEffect(() => {
    if (selectedDate !== "3") {
      ajaxUserStatsData();
    }
  }, [selectedDate]);

  useEffect(() => {
    if (selectedDate === "3") {
      ajaxUserStatsData();
    }
  }, [customDateRage]);

  const ajaxUserStatsData = async () => {
    let _params = {
      userId: user.Id,
      calendertype: selectedDate,
      clientId: selectedClients.map((item) => item.Id),
    };
    if (selectedDate === "3") {
      _params.start = customDateRage.start;
      _params.end = customDateRage.end;
    }
    const data = await ajaxuserstatsApi(_params);
    if (data?.status === "ok") {
      const res = data.response;
      setTableData(res?.info);
      setTotal(res?.total);
    }
  };

  return (
    <main className="container-fluid py-4">
      <div className="d-flex align-items-center justify-content-between">
        <div className="section-title">
          <BiLineChart className="icon me-2" />
          User Stats
        </div>
        <DownloadTableExcel
          filename="MessagesReport"
          sheet="messages"
          currentTableRef={tableRef.current}
        >
          <button className="btn btn-sm btn-primary">
            <div className="d-flex align-items-center">
              <BsDownload className="me-1" />
              Export
            </div>
          </button>
        </DownloadTableExcel>
      </div>
      <div className="row mt-2">
        <div className="col col-sm-10">
          <DateFilterMonthPicker2
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            customDateRage={customDateRage}
            setCustomDateRange={setCustomDateRange}
          />
        </div>
        <div className="col col-sm-2">
          <DropdownMultiSelect
            id="agent_group"
            className="w-100 mb-2"
            options={clientList.map((item) => {
              return { Id: item.Id, Name: item.Name };
            })}
            placeholder={"Select Clients"}
            labelField="Name"
            valueField="Id"
            values={selectedClients}
            onChange={(values) => setSelectedClients(values)}
            onDropdownClose={(values) => {
              console.log(values);
              ajaxUserStatsData();
            }}
          />
        </div>
      </div>
      <div id="dashboard-tbl-data" className="mt-2">
        <div className="sticky-searchbar">
          <input
            type="text"
            className="form-control"
            placeholder="Search"
            style={{ width: "150px" }}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <table
          id="messages_report"
          className="table table-hover"
          ref={tableRef}
        >
          <thead style={{ position: "sticky", top: "49px" }}>
            <tr>
              <th>User</th>
              <th className="text-center">Leads Touched</th>
              <th className="text-center">Messages Sent</th>
              <th className="text-center">AI Button</th>
              <th className="text-center">Outbound Calls</th>
              <th className="text-center">AVG Call Duration</th>
              <th className="text-center">AVG Call Rating</th>
              <th className="text-center">Appointments Set</th>
              <th className="text-center">My Appointments</th>
              <th className="text-center">Appointment Shows</th>
              <th className="text-center">Sold</th>
              <th className="text-center">Carly AI Button</th>
            </tr>
          </thead>
          <tbody>
            {!!tableData &&
              tableData
                .filter((item) =>
                  item.Username.toLowerCase().includes(search.toLowerCase()),
                )
                .map((item, index) => (
                  <tr key={index}>
                    <td>{item?.Username}</td>
                    <td className="text-center">{item?.leadtouched}</td>
                    <td className="text-center">{item?.Message}</td>
                    <td className="text-center">{item?.AIbutton}</td>
                    <td className="text-center">{item?.Phonecall}</td>
                    <td className="text-center">
                      {helper.secondsToHours(item?.Talktime)}
                    </td>
                    <td className="text-center">
                      {helper.number_format(item?.avgCallrating, 1)}
                    </td>
                    <td className="text-center">{item?.Appointment}</td>
                    <td className="text-center">{item?.Myappointment}</td>
                    <td className="text-center">{item?.Appointmentshow}</td>
                    <td className="text-center">{item?.Sold}</td>
                    <td className="text-center">{item?.AIbutton}</td>
                  </tr>
                ))}
          </tbody>
          <tfoot>
            <tr>
              <td></td>
              <td className="text-center">{total?.leadtouched}</td>
              <td className="text-center">{total?.Message}</td>
              <td className="text-center"></td>
              <td className="text-center">{total?.Phonecall}</td>
              <td className="text-center">
                {helper.secondsToHours(total?.Talktime)}
              </td>
              <td className="text-center">{total?.avgCallrating}</td>
              <td className="text-center">{total?.Appointment}</td>
              <td className="text-center">{total?.Myappointment}</td>
              <td className="text-center">{total?.Appointmentshow}</td>
              <td className="text-center">{total?.Sold}</td>
              <td className="text-center"></td>
            </tr>
          </tfoot>
        </table>
      </div>
    </main>
  );
}
