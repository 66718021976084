const colorsConfig = {
  light: {
    primaryBgColor: "rgba(0, 0, 0, 0)",
    secondaryBgColor: "#3c4451",
    primaryColor: "#4e5e6a",
    primaryLightColor: "#8d9ea7",
    primaryBorderColor: "rgba(0, 0, 0, 0.175)",
    primaryTextColor: "white",
    success: "green",
    error: "red",
    info: "#0051ff",
    secondaryInfo: "deepPurple",
    loading: "grey",
    badgeBgColor: "rgba(203,213,225,1)",
  },
  dark: {},
};

export default colorsConfig;
