/* eslint-disable max-len */
import ContentEditable from "react-contenteditable";
import EmojiPickerInput from "../ChatInputElements/EmojiPickerInput";
import SmartFieldsInput from "../ChatInputElements/SmartFieldsInput";
import React, { useEffect, useContext, useState } from "react";
import { AuthContext, ClientContext } from "../../context";

export default function ClientDefaultMessageTab({ clientid }) {
  const { user } = useContext(AuthContext);
  const { getDefaultMessageApi, saveDefaultMessageApi } =
    useContext(ClientContext);
  //
  const [postData, setPostData] = useState({
    Automatic_confirmation: "",
    Schedule_reminder_minute: "",
    Schedule_reminder_lock: 0,
    Scheduled_reminder: "",
    Schedule_reminder2_minute: "",
    Schedule_reminder2_lock: 0,
    Schedule_reminder2: "",
    Automatic_follow_up: "",
    Automatic_confirmation_phone: "",
    Schedule_reminder_phone_minute: "",
    Schedule_reminder_phone_lock: 0,
    Scheduled_reminder_phone: "",
    Schedule_reminder2_phone_minute: "",
    Schedule_reminder2_phone_lock: 0,
    Schedule_reminder2_phone: "",
    Automatic_follow_up_phone: "",
    Reschedulemessage: "",
  });

  useEffect(() => {
    getDefaultMessage(clientid);
  }, [clientid]);

  const getDefaultMessage = async (cid) => {
    const data = await getDefaultMessageApi({ userId: user.Id, cid: cid });
    if (data.status === "ok") {
      const res = data.response;
      setPostData({
        Automatic_confirmation: res?.Automatic_confirmation
          ? res?.Automatic_confirmation
          : "Your appointment has been set for you to come into the dealership [AppDate] @ [AppTime]  with [ApptContactName] at [DealershipName] [DealershipAddress].",
        Schedule_reminder_minute: res?.Schedule_reminder_minute
          ? res?.Schedule_reminder_minute
          : 0,
        Schedule_reminder_lock: res?.Schedule_reminder_lock
          ? res?.Schedule_reminder_lock
          : 0,
        Scheduled_reminder: res?.Scheduled_reminder
          ? res?.Scheduled_reminder
          : "Appointment Reminder for [AppDate] @ [AppTime] tomorrow for you to come into the dealership. If you need to reschedule just shoot me a text back. Thanks!",
        Schedule_reminder2_minute: res?.Schedule_reminder2_minute
          ? res?.Schedule_reminder2_minute
          : 0,
        Schedule_reminder2_lock: res?.Schedule_reminder2_lock
          ? res?.Schedule_reminder2_lock
          : 0,
        Schedule_reminder2: res?.Schedule_reminder2
          ? res?.Schedule_reminder2
          : "Appointment Reminder for [AppDate] @ [AppTime] tomorrow for you to come into the dealership. If you need to reschedule just shoot me a text back. Thanks!",
        Automatic_follow_up: res?.Automatic_follow_up
          ? res?.Automatic_follow_up
          : "Hi [fname]! Just wanted to follow up on your appointment and see how your visit at [dealershipname] went and if you were able to get into a newer vehicle?",
        Automatic_confirmation_phone: res?.Automatic_confirmation_phone
          ? res?.Automatic_confirmation_phone
          : "Hi [fname]! Just wanted to follow up on your appointment and see how your visit at [dealershipname] went and if you were able to get into a newer vehicle?",
        Schedule_reminder_phone_minute: res?.Schedule_reminder_phone_minute
          ? res?.Schedule_reminder_phone_minute
          : 0,
        Schedule_reminder_phone_lock: res?.Schedule_reminder_phone_lock
          ? res?.Schedule_reminder_phone_lock
          : 0,
        Scheduled_reminder_phone: res?.Scheduled_reminder_phone
          ? res?.Scheduled_reminder_phone
          : "Appointment Reminder for [AppDate] @ [AppTime] tomorrow for you to come into the dealership. If you need to reschedule just shoot me a text back. Thanks!",
        Schedule_reminder2_phone_minute: res?.Schedule_reminder2_phone_minute
          ? res?.Schedule_reminder2_phone_minute
          : 0,
        Schedule_reminder2_phone_lock: res?.Schedule_reminder2_phone_lock
          ? res?.Schedule_reminder2_phone_lock
          : 0,
        Schedule_reminder2_phone: res?.Schedule_reminder2_phone
          ? res?.Schedule_reminder2_phone
          : "Appointment Reminder for [AppDate] @ [AppTime] tomorrow for you to come into the dealership. If you need to reschedule just shoot me a text back. Thanks!",
        Automatic_follow_up_phone: res?.Automatic_follow_up_phone
          ? res?.Automatic_follow_up_phone
          : "Hi [fname]! Just wanted to follow up on your appointment and see how your visit at [dealershipname] went and if you were able to get into a newer vehicle?",
        Reschedulemessage: res?.Reschedulemessage
          ? res?.Reschedulemessage
          : "Your appointment has been rescheduled to [AppDate] @ [AppTime]. We look forward to seeing you then!",
      });
    }
  };

  const savedefaultmsg = async () => {
    let params = {};
    params.a = postData.Automatic_confirmation;
    params.b = postData.Scheduled_reminder;
    params.c = postData.Automatic_follow_up;
    params.a1 = postData.Automatic_confirmation_phone;
    params.b1 = postData.Scheduled_reminder_phone;
    params.c1 = postData.Automatic_follow_up_phone;
    params.d = postData.Reschedulemessage;
    params.e = postData.Schedule_reminder_minute;
    params.f = postData.Schedule_reminder_lock;
    params.e1 = postData.Schedule_reminder_phone_minute;
    params.f1 = postData.Schedule_reminder_phone_lock;
    params.s2 = postData.Schedule_reminder2;
    params.s2m = postData.Schedule_reminder2_minute;
    params.s2l = postData.Schedule_reminder2_lock;
    params.sp2 = postData.Schedule_reminder2_phone;
    params.sp2m = postData.Schedule_reminder2_phone_minute;
    params.sp2l = postData.Schedule_reminder2_phone_lock;
    params.clientid = clientid;

    const data = await saveDefaultMessageApi(params);
    if (data.status === "ok") {
      getDefaultMessage(clientid);
    }
  };

  return (
    <>
      <div className="card">
        <div className="card-header">Automatic Confirmation</div>
        <div className="card-body">
          <ContentEditable
            style={{ minHeight: "100px" }}
            className="form-textarea msg-editor"
            tagName="div"
            // placeholder={messageInput ? '' : placeholder}
            html={postData?.Automatic_confirmation}
            onChange={(e) =>
              setPostData({
                ...postData,
                Automatic_confirmation: e.target.value,
              })
            }
          />
          <div className="d-flex align-items-center me-auto p-1">
            <EmojiPickerInput
              onSelect={(emoji) =>
                setPostData({
                  ...postData,
                  Automatic_confirmation:
                    postData.Automatic_confirmation + emoji,
                })
              }
            />
            <SmartFieldsInput
              onSelect={(field) =>
                setPostData({
                  ...postData,
                  Automatic_confirmation:
                    postData.Automatic_confirmation + field,
                })
              }
            />
          </div>
        </div>
      </div>
      <div className="card mt-3 card-blue-weak">
        <div className="card-header">Scheduled Reminder</div>
        <div className="card-body">
          <label className="mb-2">Default Time</label>
          <div className="d-flex mb-2">
            <select
              className="form-select form-control-fav"
              value={postData?.Schedule_reminder_minute}
              onChange={(e) =>
                setPostData({
                  ...postData,
                  Schedule_reminder_minute: e.target.value,
                })
              }
            >
              <option value="0">Select</option>
              <option value="5">5 minutes</option>
              <option value="15">15 minutes</option>
              <option value="30">30 minutes</option>
              <option value="60">1 hour</option>
              <option value="1440">24 hours</option>
            </select>
            <div className="d-flex align-items-center ms-2">
              <input
                type="checkbox"
                className="me-1"
                checked={postData?.Schedule_reminder_lock}
                onChange={(e) =>
                  setPostData({
                    ...postData,
                    Schedule_reminder_lock: e.target.checked,
                  })
                }
              />
              Lock Time
            </div>
          </div>
          <ContentEditable
            style={{ minHeight: "100px" }}
            className="form-textarea msg-editor"
            tagName="div"
            // placeholder={messageInput ? '' : placeholder}
            html={postData?.Scheduled_reminder}
            onChange={(e) =>
              setPostData({ ...postData, Scheduled_reminder: e.target.value })
            }
          />
          <div className="d-flex align-items-center me-auto p-1">
            <EmojiPickerInput
              onSelect={(emoji) =>
                setPostData({
                  ...postData,
                  Scheduled_reminder: postData.Scheduled_reminder + emoji,
                })
              }
            />
            <SmartFieldsInput
              onSelect={(field) =>
                setPostData({
                  ...postData,
                  Scheduled_reminder: postData.Scheduled_reminder + field,
                })
              }
            />
          </div>
        </div>
      </div>
      <div className="card mt-3 card-blue-strong">
        <div className="card-header">Scheduled Reminder 2</div>
        <div className="card-body">
          <label className="mb-2">Default Time</label>
          <div className="d-flex mb-2">
            <select
              className="form-select form-control-fav"
              value={postData?.Schedule_reminder2_minute}
              onChange={(e) =>
                setPostData({
                  ...postData,
                  Schedule_reminder2_minute: e.target.value,
                })
              }
            >
              <option value="0">Select</option>
              <option value="5">5 minutes</option>
              <option value="15">15 minutes</option>
              <option value="30">30 minutes</option>
              <option value="60">1 hour</option>
              <option value="1440">24 hours</option>
            </select>
            <div className="d-flex align-items-center ms-2">
              <input
                type="checkbox"
                className="me-1"
                checked={postData?.Schedule_reminder2_lock}
                onChange={(e) =>
                  setPostData({
                    ...postData,
                    Schedule_reminder2_lock: e.target.checked,
                  })
                }
              />
              Lock Time
            </div>
          </div>
          <ContentEditable
            style={{ minHeight: "100px" }}
            className="form-textarea msg-editor"
            tagName="div"
            // placeholder={messageInput ? '' : placeholder}
            html={postData?.Schedule_reminder2}
            onChange={(e) =>
              setPostData({ ...postData, Schedule_reminder2: e.target.value })
            }
          />
          <div className="d-flex align-items-center me-auto p-1">
            <EmojiPickerInput
              onSelect={(emoji) =>
                setPostData({
                  ...postData,
                  Schedule_reminder2: postData.Schedule_reminder2 + emoji,
                })
              }
            />
            <SmartFieldsInput
              onSelect={(field) =>
                setPostData({
                  ...postData,
                  Schedule_reminder2: postData.Schedule_reminder2 + field,
                })
              }
            />
          </div>
        </div>
      </div>
      <div className="card mt-3 card-success">
        <div className="card-header">Automatic Follow-up</div>
        <div className="card-body">
          <ContentEditable
            style={{ minHeight: "100px" }}
            className="form-textarea msg-editor"
            tagName="div"
            // placeholder={messageInput ? '' : placeholder}
            html={postData?.Automatic_follow_up}
            onChange={(e) =>
              setPostData({ ...postData, Automatic_follow_up: e.target.value })
            }
          />
          <div className="d-flex align-items-center me-auto p-1">
            <EmojiPickerInput
              onSelect={(emoji) =>
                setPostData({
                  ...postData,
                  Automatic_follow_up: postData.Automatic_follow_up + emoji,
                })
              }
            />
            <SmartFieldsInput
              onSelect={(field) =>
                setPostData({
                  ...postData,
                  Automatic_follow_up: postData.Automatic_follow_up + field,
                })
              }
            />
          </div>
        </div>
      </div>
      <div className="card mt-3 card-info">
        <div className="card-header">Automatic Confirmation (in Phone)</div>
        <div className="card-body">
          <ContentEditable
            style={{ minHeight: "100px" }}
            className="form-textarea msg-editor"
            tagName="div"
            // placeholder={messageInput ? '' : placeholder}
            html={postData?.Automatic_confirmation_phone}
            onChange={(e) =>
              setPostData({
                ...postData,
                Automatic_confirmation_phone: e.target.value,
              })
            }
          />
          <div className="d-flex align-items-center me-auto p-1">
            <EmojiPickerInput
              onSelect={(emoji) =>
                setPostData({
                  ...postData,
                  Automatic_confirmation_phone:
                    postData.Automatic_confirmation_phone + emoji,
                })
              }
            />
            <SmartFieldsInput
              onSelect={(field) =>
                setPostData({
                  ...postData,
                  Automatic_confirmation_phone:
                    postData.Automatic_confirmation_phone + field,
                })
              }
            />
          </div>
        </div>
      </div>
      <div className="card mt-3 card-warning">
        <div className="card-header">Scheduled Reminder (in Phone)</div>
        <div className="card-body">
          <label className="mb-2">Default Time</label>
          <div className="d-flex mb-2">
            <select
              className="form-select form-control-fav"
              value={postData?.Schedule_reminder_phone_minute}
              onChange={(e) =>
                setPostData({
                  ...postData,
                  Schedule_reminder_phone_minute: e.target.value,
                })
              }
            >
              <option value="0">Select</option>
              <option value="5">5 minutes</option>
              <option value="15">15 minutes</option>
              <option value="30">30 minutes</option>
              <option value="60">1 hour</option>
              <option value="1440">24 hours</option>
            </select>
            <div className="d-flex align-items-center ms-2">
              <input
                type="checkbox"
                className="me-1"
                checked={postData?.Schedule_reminder_phone_lock}
                onChange={(e) =>
                  setPostData({
                    ...postData,
                    Schedule_reminder_phone_lock: e.target.checked,
                  })
                }
              />
              Lock Time
            </div>
          </div>
          <ContentEditable
            style={{ minHeight: "100px" }}
            className="form-textarea msg-editor"
            tagName="div"
            // placeholder={messageInput ? '' : placeholder}
            html={postData?.Scheduled_reminder_phone}
            onChange={(e) =>
              setPostData({
                ...postData,
                Scheduled_reminder_phone: e.target.value,
              })
            }
          />
          <div className="d-flex align-items-center me-auto p-1">
            <EmojiPickerInput
              onSelect={(emoji) =>
                setPostData({
                  ...postData,
                  Scheduled_reminder_phone:
                    postData.Scheduled_reminder_phone + emoji,
                })
              }
            />
            <SmartFieldsInput
              onSelect={(field) =>
                setPostData({
                  ...postData,
                  Scheduled_reminder_phone:
                    postData.Scheduled_reminder_phone + field,
                })
              }
            />
          </div>
        </div>
      </div>
      <div className="card mt-3 card-secondary">
        <div className="card-header">Scheduled Reminder for Phone 2</div>
        <div className="card-body">
          <label className="mb-2">Default Time</label>
          <div className="d-flex mb-2">
            <select
              className="form-select form-control-fav"
              value={postData?.Schedule_reminder2_phone_minute}
              onChange={(e) =>
                setPostData({
                  ...postData,
                  Schedule_reminder2_phone_minute: e.target.value,
                })
              }
            >
              <option value="0">Select</option>
              <option value="5">5 minutes</option>
              <option value="15">15 minutes</option>
              <option value="30">30 minutes</option>
              <option value="60">1 hour</option>
              <option value="1440">24 hours</option>
            </select>
            <div className="d-flex align-items-center ms-2">
              <input
                type="checkbox"
                className="me-1"
                checked={postData?.Schedule_reminder2_phone_lock}
                onChange={(e) =>
                  setPostData({
                    ...postData,
                    Schedule_reminder2_phone_lock: e.target.checked,
                  })
                }
              />
              Lock Time
            </div>
          </div>
          <ContentEditable
            style={{ minHeight: "100px" }}
            className="form-textarea msg-editor"
            tagName="div"
            // placeholder={messageInput ? '' : placeholder}
            html={postData?.Schedule_reminder2_phone}
            onChange={(e) =>
              setPostData({
                ...postData,
                Schedule_reminder2_phone: e.target.value,
              })
            }
          />
          <div className="d-flex align-items-center me-auto p-1">
            <EmojiPickerInput
              onSelect={(emoji) =>
                setPostData({
                  ...postData,
                  Schedule_reminder2_phone:
                    postData.Schedule_reminder2_phone + emoji,
                })
              }
            />
            <SmartFieldsInput
              onSelect={(field) =>
                setPostData({
                  ...postData,
                  Schedule_reminder2_phone:
                    postData.Schedule_reminder2_phone + field,
                })
              }
            />
          </div>
        </div>
      </div>
      <div className="card mt-3 card-danger">
        <div className="card-header">Automatic Follow-up (in Phone)</div>
        <div className="card-body">
          <ContentEditable
            style={{ minHeight: "100px" }}
            className="form-textarea msg-editor"
            tagName="div"
            // placeholder={messageInput ? '' : placeholder}
            html={postData?.Automatic_follow_up_phone}
            onChange={(e) =>
              setPostData({
                ...postData,
                Automatic_follow_up_phone: e.target.value,
              })
            }
          />
          <div className="d-flex align-items-center me-auto p-1">
            <SmartFieldsInput
              onSelect={(field) =>
                setPostData({
                  ...postData,
                  Automatic_follow_up_phone:
                    postData.Automatic_follow_up_phone + field,
                })
              }
            />
          </div>
        </div>
      </div>
      <div className="card mt-3 card-primary">
        <div className="card-header">Reschedule message</div>
        <div className="card-body">
          <ContentEditable
            style={{ minHeight: "100px" }}
            className="form-textarea msg-editor"
            tagName="div"
            // placeholder={messageInput ? '' : placeholder}
            html={postData?.Reschedulemessage}
            onChange={(e) =>
              setPostData({ ...postData, Reschedulemessage: e.target.value })
            }
          />
          <div className="d-flex align-items-center me-auto p-1">
            <SmartFieldsInput
              onSelect={(field) =>
                setPostData({
                  ...postData,
                  Reschedulemessage: postData.Reschedulemessage + field,
                })
              }
            />
          </div>
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-12 d-flex">
          <button
            className="btn btn-primary ms-auto"
            onClick={() => savedefaultmsg()}
          >
            Save
          </button>
          <button className="btn btn-outline-secondary ms-2 me-auto">
            Cancel
          </button>
        </div>
      </div>
    </>
  );
}
