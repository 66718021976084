import React, { useContext, useEffect, useState } from "react";
import {
  BsPlus,
  BsPlusCircle,
  BsThreeDotsVertical,
  BsTrash,
} from "react-icons/bs";
import { IoClose } from "react-icons/io5";
import { AuthContext, ClientContext, UserContext } from "../../context";
import { ButtonGroup } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { BiEdit } from "react-icons/bi";
import ClientSavedRepliesModal from "../modals/client_saved_replies_modal";
import ClientSavedRepliesEditModal from "../modals/client_saved_replies_edit_modal";

export default function ClientSavedRepliesTab({ clientid }) {
  const { user } = useContext(AuthContext);
  const { getSavedRepliesApi, createcategoryApi } = useContext(ClientContext);
  const { editcategoryApi, deletecategoryApi } = useContext(UserContext);
  const [newForm, setNewForm] = useState(false);
  const [categories, setCategories] = useState([]);
  const [agency, setAgency] = useState("");
  const [newCategory, setNewCategory] = useState({
    userId: "",
    categoryname: "",
    agency: "",
  });
  const [newCategoryError, setNewCategoryError] = useState(false);
  const [openSavedRepliesModal, setOpenSavedRepliesModal] = useState("");
  const [openSavedRepliesEditModal, setOpenSavedRepliesEditModal] =
    useState(null);

  useEffect(() => {
    getSavedReplies(clientid);
  }, [clientid]);

  const getSavedReplies = async (cid) => {
    const data = await getSavedRepliesApi({ userId: user.Id, cid: cid });
    if (data.status === "ok") {
      const res = data.response;
      setCategories(res?.category);
      setNewCategory({
        ...newCategory,
        userId: user.Id,
        agency: res?.agency,
      });
      setAgency(res?.agency);
    }
  };

  const addNewCategory = async () => {
    if (!newCategory.categoryname) {
      setNewCategoryError(true);
      return;
    }
    setNewCategoryError(false);
    const data = await createcategoryApi(newCategory);
    if (data.status === "ok") {
      getSavedReplies(clientid);
    }
  };

  const onCategoryChange = (e, index, key) => {
    let _categories = [...categories];
    _categories[index][key] = e.target.value;
    setCategories(_categories);
  };

  const onKeyUpCategoryName = async (e, index) => {
    if (e.keyCode === 13) {
      let params = {};
      params.id = categories[index].CategoryId;
      params.name = categories[index].Categoryname;
      params.userId = user.Id;
      const data = await editcategoryApi(params);
      if (data.status === "ok") {
        getSavedReplies(clientid);
      }
    }
  };

  const onBlurCategoryName = async (index) => {
    let params = {};
    params.id = categories[index].CategoryId;
    params.name = categories[index].Categoryname;
    params.userId = user.Id;
    const data = await editcategoryApi(params);
    if (data.status === "ok") {
      getSavedReplies(clientid);
    }
  };

  const deletecategory = async (CategoryId) => {
    let params = {};
    params.id = CategoryId;
    params.userId = user.Id;
    const data = await deletecategoryApi(params);
    if (data.status === "ok") {
      getSavedReplies(clientid);
    }
  };

  return (
    <div className="overflow-auto">
      <div className="saved-replies">
        {categories &&
          categories.map((item, index) => (
            <div className="sr-category" key={index}>
              <div className="top">
                <div className="category-title">
                  <input
                    type="text"
                    className="form-control"
                    id={`categoryname_${index}`}
                    value={item.Categoryname}
                    onChange={(e) => onCategoryChange(e, index, "Categoryname")}
                    onKeyUp={(e) => onKeyUpCategoryName(e, index)}
                    onBlur={() => onBlurCategoryName(index)}
                  />
                </div>
                <div className="total">{item.Template.length}</div>
                <div className="action">
                  <DropdownButton
                    as={ButtonGroup}
                    align={{ lg: "end" }}
                    variant="default caret-none"
                    title={<BsThreeDotsVertical />}
                  >
                    <Dropdown.Item
                      eventKey="1"
                      className="d-flex align-items-center"
                      onClick={() => setOpenSavedRepliesModal(item.CategoryId)}
                    >
                      <BsPlus className="me-1" />
                      Add New
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey="2"
                      className="d-flex align-items-center"
                      onClick={() =>
                        document.getElementById(`categoryname_${index}`).focus()
                      }
                    >
                      <BiEdit className="me-1" />
                      Rename Category
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey="4"
                      className="d-flex align-items-center"
                      onClick={() => deletecategory(item.CategoryId)}
                    >
                      <BsTrash className="me-1" />
                      Delete Category
                    </Dropdown.Item>
                  </DropdownButton>
                </div>
              </div>
              <div className="bottom">
                <div className="replies">
                  {item.Template.map((tmp, tindex) => (
                    <a
                      data-id={tmp.Id}
                      className="reply"
                      key={tindex}
                      onClick={() => setOpenSavedRepliesEditModal(tmp)}
                    >
                      <div className="reply-title">
                        <strong>{tmp.Title}</strong>
                        <BsTrash
                          className="ms-1 delete-reply cursor-pointer"
                          onClick={() => deletecategory(item.CategoryId)}
                        />
                      </div>
                      <p>{tmp.Desciption}</p>
                    </a>
                  ))}
                </div>
                <div className="new-reply">
                  <button
                    className="btn btn-secondary d-flex align-items-center w-100 px-3"
                    onClick={() => setOpenSavedRepliesModal(item.CategoryId)}
                  >
                    <BsPlusCircle /> &nbsp; Add new
                  </button>
                </div>
              </div>
            </div>
          ))}
        <div className="sr-category sr-category-new">
          <div className="bottom">
            {newForm && (
              <div className="new-category-input-wrap">
                <input
                  type="text"
                  className={`form-control mb-2${
                    newCategoryError ? " error" : ""
                  }`}
                  value={newCategory.categoryname}
                  onChange={(e) => {
                    if (e.target.value.length) {
                      setNewCategoryError(false);
                    }
                    setNewCategory({
                      ...newCategory,
                      categoryname: e.target.value,
                    });
                  }}
                />
              </div>
            )}
            <div className="new-category">
              {!newForm ? (
                <button
                  className="btn btn-secondary d-flex align-items-center w-100 px-3"
                  onClick={() => setNewForm(true)}
                >
                  <BsPlusCircle /> &nbsp; Add new category
                </button>
              ) : (
                <div className="new-category-action">
                  <button
                    className="btn btn-sm btn-secondary"
                    onClick={() => addNewCategory()}
                  >
                    Add category
                  </button>
                  <IoClose
                    className="m-2 text-secondary"
                    size={20}
                    onClick={() => setNewForm(false)}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      <ClientSavedRepliesModal
        agency={agency}
        categoryId={openSavedRepliesModal}
        isOpenModal={!!openSavedRepliesModal}
        afterOpenModal={() => {}}
        closeModal={() => setOpenSavedRepliesModal("")}
        reloadData={() => {
          setOpenSavedRepliesModal("");
        }}
      />
      <ClientSavedRepliesEditModal
        reply={openSavedRepliesEditModal}
        isOpenModal={!!openSavedRepliesEditModal}
        afterOpenModal={() => {}}
        closeModal={() => setOpenSavedRepliesEditModal(null)}
        reloadData={() => {
          setOpenSavedRepliesEditModal(null);
        }}
      />
    </div>
  );
}
