import React, { useContext } from "react";
import Modal from "react-modal";
import { AuthContext, ClientContext, ConversationContext } from "../../context";
import { useEffect } from "react";
import { useState } from "react";
import { BsExclamationCircle, BsPlusCircle } from "react-icons/bs";
import ConversationManageLabelsModal from "./conversation_manage_labels_modal";
import { LiaTimesSolid } from "react-icons/lia";
import PlFileUploader from "../Uploader/PlFileUploader";
import config from "../../config/config";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "560px",
    padding: "15px",
    marginTop: "20px",
    marginBottom: "20px",
  },
};

Modal.setAppElement("#root");

export default function ClientImportLeadsModal({
  cid = "",
  isOpenModal = false,
  afterOpenModal,
  closeModal,
  onOpenFinalModal,
}) {
  const { user } = useContext(AuthContext);
  const { getImportLeadsModalDataApi, leadimportsessionApi } =
    useContext(ClientContext);
  const { leadTagAutoCompleteApi } = useContext(ConversationContext);
  const [leadsources, setLeadsources] = useState([]);
  const [showNewLsGroup, setShowNewLsGroup] = useState(false);
  const [showAddLabelForm, setShowAddLabelForm] = useState(false);
  const [openManageLabelsModal, setOpenManageLabelsModal] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [autocompleteLabels, setAutocompleteLabels] = useState([]);
  //
  const [leadlabel, setLeadlabel] = useState(null);
  const [leadsource, setLeadsource] = useState("");
  const [newleadsource, setNewleadsource] = useState("");
  const [listname, setListname] = useState("");
  const [uploadFilename, setUploadFilename] = useState("");
  const [filename, setFilename] = useState("");
  const [agreecheck, setAgreecheck] = useState(false);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (cid) {
      loadModalData(cid);
      console.log(setAgreecheck);
    }
  }, [cid]);

  const loadModalData = async (cid) => {
    const data = await getImportLeadsModalDataApi({
      userId: user.Id,
      cid: cid,
    });
    if (data.status === "ok") {
      const res = data.response;
      setLeadsources(res?.leadsource);
    }
  };

  const handleLeadsourceSelectChange = (e) => {
    setLeadsource(e.target.value);
    if (e.target.value === "new") {
      setShowNewLsGroup(true);
    } else {
      setShowNewLsGroup(false);
    }
  };

  const onSearch = async () => {
    const data = await leadTagAutoCompleteApi({
      userId: user.Id,
      term: searchKey,
    });
    if (data.status === "ok") {
      const res = Object.values(data.response);
      setAutocompleteLabels(res);
    }
  };

  const addNewLabelTag = async (label) => {
    setSearchKey("");
    setAutocompleteLabels([]);
    setLeadlabel(label);
  };

  const onNext = async () => {
    if (!cid) {
      return;
    }
    if (!checkvalidation()) {
      setShowError(true);
      return;
    }
    let params = {};
    params.clientid = cid;
    params.leadsource = leadsource;
    params.new_leadsource = newleadsource;
    params.listname = listname;
    params.Label = leadlabel.id;

    const data = leadimportsessionApi(params);
    if (data.status === "ok") {
      onOpenFinalModal();
    }
  };

  const checkvalidation = () => {
    if (!uploadFilename) {
      return false;
    }
    if (!listname) {
      return false;
    }
    if (!leadsource) {
      return false;
    }
    if (leadsource === "new" && !newleadsource) {
      return false;
    }
    if (!agreecheck) {
      return false;
    }
    return true;
  };

  return (
    <Modal
      isOpen={isOpenModal}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel={"Import Leads"}
    >
      <div className="modal-header flex-column">
        <h4 className="w-100">Import Leads</h4>
        <small className="w-100">Step 1 of 2</small>
      </div>
      <div className="modal-body">
        <div className="row mt-4">
          <div className="d-flex align-items-center">
            <PlFileUploader
              buttonText={"Choose File"}
              url={`${config.clientPath}/uploadimportedlead/${cid}`}
              browseButtonId={`importingFile`}
              extensions={"csv,xls,xlsx"}
              onSuccess={(up, file, response) => {
                setUploadFilename(response.filename);
                setFilename(file.name);
              }}
              onError={() => {}}
            />
            <label className="ms-2 fst-italic">{filename}</label>
          </div>
          {showError && !uploadFilename && (
            <small className="text-danger w-100">Please choose file!</small>
          )}
          <div className="mt-2">
            <div className="">
              <BsExclamationCircle className="me-2" />
              File requirements
            </div>
            <p className="text-gray">
              Leads must be in a .CSV, .XLS, or .XLSX file. Applicable fields
              include: mobile number, first name, last name, email, address,
              city, state, and zip code.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <label className="mb-2">List Name</label>
              <input
                type="text"
                className="form-control"
                value={listname}
                onChange={(e) => setListname(e.target.value)}
              />
              {showError && !listname && (
                <small className="text-danger w-100">
                  Please name your list.
                </small>
              )}
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12">
            <div className="form-group">
              <label className="mb-2">Add leads to</label>
              <select
                className="form-control"
                value={leadsource}
                onChange={(e) => handleLeadsourceSelectChange(e)}
              >
                <option value="">Please Select Lead Source</option>
                <option value="new">+ New Lead Source +</option>
                {leadsources.length > 0 &&
                  leadsources.map((ls, index) => (
                    <option key={index} value={ls.Id}>
                      {ls.Name}
                    </option>
                  ))}
              </select>
              {showError && !leadsource && (
                <small className="text-danger w-100">
                  Please select lead source.
                </small>
              )}
            </div>
          </div>
        </div>
        {showNewLsGroup && (
          <div className="row mt-3">
            <div className="col-12">
              <div className="form-group">
                <label className="mb-2">New Lead Source Name</label>
                <input
                  type="text"
                  className="form-control"
                  value={newleadsource}
                  onChange={(e) => setNewleadsource(e.target.value)}
                />
                {showError && !newleadsource && (
                  <small className="text-danger w-100">
                    Please name your new lead source.
                  </small>
                )}
              </div>
            </div>
          </div>
        )}
        <div className="row mt-3">
          <div className="col-12">
            <div className="labels-list mb-2">
              {leadlabel && (
                <label className="status">
                  <span>
                    <span className={`label ${leadlabel?.value}`}>
                      <LiaTimesSolid
                        className="icon-delete"
                        onClick={() => setLeadlabel(null)}
                      />
                    </span>
                    {leadlabel?.label}
                  </span>
                </label>
              )}
            </div>
            <div className="d-flex align-items-center">
              <button
                className={`btn btn-sm d-flex align-items-center ${
                  showAddLabelForm ? "btn-primary" : "btn-outline-secondary"
                }`}
                onClick={() => setShowAddLabelForm(!showAddLabelForm)}
              >
                <BsPlusCircle className="me-2" />
                Add Label
              </button>
              {showAddLabelForm && (
                <a
                  className="text-primary text-decoration-none cursor-pointer ms-auto"
                  onClick={() => setOpenManageLabelsModal(true)}
                >
                  Manage Labels
                </a>
              )}
            </div>
            <div
              className={`autocomplete-label-form mt-2${
                showAddLabelForm ? "" : " d-none"
              }`}
            >
              <input
                type="text"
                className="form-control w-100"
                placeholder="Add label..."
                value={searchKey}
                onChange={(e) => setSearchKey(e.target.value)}
                onKeyUp={(e) => {
                  if (e.key === "Enter" && searchKey) {
                    onSearch();
                  }
                }}
              />
              {autocompleteLabels.length > 0 && (
                <ul className="autocomplete-labels">
                  {autocompleteLabels.map((item, index) => (
                    <li
                      className="autocomplete-label"
                      key={index}
                      onClick={() => addNewLabelTag(item)}
                    >
                      <span className={`bubble ${item?.value}`}></span>
                      {item?.label}
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <ConversationManageLabelsModal
              isOpenModal={openManageLabelsModal}
              afterOpenModal={() => {}}
              closeModal={() => setOpenManageLabelsModal(false)}
            />
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12">
            <input
              type="checkbox"
              className="position-relative me-2"
              style={{ top: "2px" }}
            />
            I agree to the{" "}
            <a className="" href="#">
              Terms and Conditions
            </a>
            , which require that all my leads have given prior express written
            consent to receive promotional messages sent via an autodialer and
            that their agreement was not conditioned on any purchase.
          </div>
          <div className="col-12">
            {showError && !agreecheck && (
              <small className="text-danger w-100">
                Please select lead source.
              </small>
            )}
          </div>
        </div>
      </div>
      <div className="modal-footer mt-3">
        <button className="btn btn-outline-secondary me-2" onClick={closeModal}>
          Cancel
        </button>
        <button className="btn btn-primary" onClick={() => onNext()}>
          Next
        </button>
      </div>
    </Modal>
  );
}
