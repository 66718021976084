import React from "react";
import { RotatingLines } from "react-loader-spinner";

export default function TextLoader({ text = "" }) {
  return (
    <div
      style={{
        display: text ? "flex" : "none",
        flexDirection: "column",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
        background: "rgba(0, 0, 0, 0.65)",
        zIndex: 9999,
      }}
    >
      <RotatingLines
        strokeColor="grey"
        strokeWidth="5"
        animationDuration="0.75"
        width="96"
        visible={!!text}
      />
      <h5 className="text-white opacity-50">{text}</h5>
    </div>
  );
}
