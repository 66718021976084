import React, { useContext, useState } from "react";
import {
  Autocomplete,
  Avatar,
  Box,
  Card,
  Chip,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import CachedOutlinedIcon from "@mui/icons-material/CachedOutlined";
import QuestionAnswerOutlinedIcon from "@mui/icons-material/QuestionAnswerOutlined";
import moment from "moment";
import { AuthContext, ConversationContext } from "../../context";
import { ColorsConfig } from "../../config";
import DateRangePicker from "react-bootstrap-daterangepicker";

export default function ConversationsInputAndDateFilter(props) {
  const { filterParams, handleCallback, handleSelect } = props;
  const { user } = useContext(AuthContext);
  const { smsAutoCompleteApi } = useContext(ConversationContext);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const onInputChange = async (event, value, reason) => {
    console.log(event);
    if (reason === "input") {
      if (value && value.length > 3) {
        setLoading(true);
        const result = await smsAutoCompleteApi({
          userId: user.Id,
          term: value,
        });
        setLoading(false);
        setOptions(result.response ? Object.values(result.response) : []);
      } else {
        setOptions([]);
      }
    }
  };

  const onChange = (event, value, reason) => {
    console.log(event);
    if (reason === "selectOption") {
      if (value) {
        if (Number(value?.id) === 0) {
          if (Number(value.status === 2)) {
            handleSelect(value, "campaign");
          } else if (Number(value.status === 1)) {
            handleSelect(value, "campaign");
          } else {
            handleSelect(value, "campaign");
          }
        } else if (Number(value.id) === 1) {
          handleSelect(value, "campaign");
        } else if (Number(value.id) === 2) {
          handleSelect(value, "lead");
        }
      } else {
        setOptions([]);
      }
    }
  };

  const getChip = (item) => {
    if (Number(item.id) === 0) {
      if (Number(item.status === 2)) {
        return [
          <Chip
            label={"Active"}
            sx={{ bgcolor: ColorsConfig.light.error }}
            key={0}
          />,
          <Typography
            sx={{ fontSize: "12px" }}
            key={1}
          >{`${item.job}${item.drop} - 
          ${item.type} - ${item.response} - ${item.hdate}`}</Typography>,
        ];
      } else if (Number(item.status === 1)) {
        return [
          <Chip
            label={"Upcoming"}
            sx={{ bgcolor: ColorsConfig.light.secondaryInfo }}
            key={0}
          />,
          <Typography
            sx={{ fontSize: "12px" }}
            key={1}
          >{`${item.job}${item.drop} - 
          ${item.type} - ${item.response} - ${item.hdate}`}</Typography>,
        ];
      } else {
        return [
          <Chip
            label={"Completed"}
            sx={{ bgcolor: ColorsConfig.light.info }}
            key={0}
          />,
          <Typography
            sx={{ fontSize: "12px" }}
            key={1}
          >{`${item.job}${item.drop} - 
          ${item.type} - ${item.response} - ${item.hdate}`}</Typography>,
        ];
      }
    } else if (Number(item.id) === 1) {
      return [
        null,
        <Typography
          sx={{ fontSize: "12px" }}
          key={1}
        >{`${item.city}, ${item.state}`}</Typography>,
      ];
    } else if (Number(item.id) === 2) {
      return [
        null,
        <Typography sx={{ fontSize: "12px" }} key={1}>
          {item.campaign}
        </Typography>,
      ];
    }
  };

  const renderOption = (option) => {
    return (
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"center"}
        spacing={2}
      >
        <Box>
          <Avatar src={option.avatar} sx={{ width: 40, height: 40 }} />
        </Box>
        <Box>
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <Typography variant="body1" sx={{ color: ColorsConfig.light.info }}>
              {option.name}
            </Typography>
            {getChip(option)[0]}
          </Stack>
          {getChip(option)[1]}
        </Box>
      </Stack>
    );
  };

  return (
    <Card sx={{ width: "100%", p: "10px 12px" }}>
      <Stack direction={"row"} alignItems={"center"} spacing={1}>
        <QuestionAnswerOutlinedIcon color="white" fontSize="small" />
        <Typography variant="body2">Conversations</Typography>
      </Stack>
      <DateRangePicker
        initialSettings={{
          startDate: moment(filterParams.startdate).toDate(),
          endDate: moment(filterParams.enddate).toDate(),
          ranges: {
            Today: [moment().toDate(), moment().toDate()],
            Yesterday: [
              moment().subtract(1, "days").toDate(),
              moment().subtract(1, "days").toDate(),
            ],
            "Last 7 Days": [
              moment().subtract(6, "days").toDate(),
              moment().toDate(),
            ],
            "Last 30 Days": [
              moment().subtract(29, "days").toDate(),
              moment().toDate(),
            ],
            "This Month": [
              moment().startOf("month").toDate(),
              moment().endOf("month").toDate(),
            ],
            "Last Month": [
              moment().subtract(1, "month").startOf("month").toDate(),
              moment().subtract(1, "month").endOf("month").toDate(),
            ],
          },
        }}
        onCallback={handleCallback}
      >
        <button type="button" className="btn form-daterangepicker w-100 mt-3">
          {filterParams.dateLabel}
        </button>
      </DateRangePicker>
      <Autocomplete
        id="auto-complete-with-autocomplete-api"
        options={options}
        style={{ width: "100%", marginTop: "10px" }}
        onInputChange={onInputChange}
        onChange={onChange}
        includeInputInList
        filterSelectedOptions
        autoComplete
        filterOptions={(x) => x}
        getOptionLabel={(option) => option.name}
        noOptionsText={""}
        clearIcon={true}
        renderOption={(props, option) => {
          return (
            <Box
              component={"li"}
              key={option.id}
              {...props}
              sx={{
                borderBottom: `1px solid ${ColorsConfig.light.primaryBorderColor}`,
              }}
            >
              {renderOption(option)}
            </Box>
          );
        }}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              size="small"
              fullWidth
              placeholder={"Search..."}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <InputAdornment position="end">
                    {loading && <CachedOutlinedIcon />}
                  </InputAdornment>
                ),
                padding: "0px 0px",
              }}
              sx={{
                p: "0px 0px",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: ColorsConfig.light.primaryBorderColor,
                  },
                  "&:hover fieldset": {
                    borderColor: ColorsConfig.light.primaryBorderColor,
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: ColorsConfig.light.primaryBorderColor,
                  },
                },
              }}
            />
          );
        }}
        fullWidth
      />
    </Card>
  );
}
