import React, { useState, useEffect } from "react";
import { Box, Button, Divider, Modal, Stack, Typography } from "@mui/material";
import { PrimaryInput } from "..";
import { helper } from "../../utils";

export default function ActionsOnboardingModal(props) {
  const { open, handleCloseModal, handleSubmit } = props;
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: {
      xs: "90%",
      md: "35%",
    },
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 2,
    p: 2,
  };
  const [value, setValue] = useState(null);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    setValue(null);
  }, []);

  const handleChange = (e) => {
    const v = e.target.value;
    setValue(v);
    if (v) {
      setShowError(false);
    } else {
      setShowError(true);
    }
  };

  const submit = async () => {
    if (helper.validateEmail(value)) {
      handleSubmit(value);
    } else {
      setShowError(true);
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="actions_onboarding_modal"
    >
      <Box sx={style}>
        <Stack
          direction={"row"}
          spacing={1}
          justifyContent={"start"}
          alignItems={"center"}
        >
          <Typography variant="h6">Onboarding</Typography>
        </Stack>
        <Divider />
        <Box
          sx={{
            p: 3,
          }}
        >
          <PrimaryInput
            type={"email"}
            value={value}
            handleChange={handleChange}
            placeholder="Input Email Address"
          />
          {showError && (
            <Typography variant="caption" color="red" sx={{ ml: 1 }}>
              Required Email Address
            </Typography>
          )}
        </Box>
        <Divider />
        <Stack
          direction={"row"}
          justifyContent={"end"}
          alignItems={"center"}
          spacing={2}
          mt={4}
        >
          <Button variant="contained" color="info" onClick={submit}>
            Submit
          </Button>
          <Button color="inherit" onClick={handleCloseModal}>
            Cancel
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
}
