import Modal from "react-modal";
import React, { useContext, useEffect } from "react";
import { AuthContext, ConversationContext } from "../../context";
import { useState } from "react";
import { BsFillStarFill, BsPencil, BsTrash } from "react-icons/bs";
import SmartFieldsInput from "../ChatInputElements/SmartFieldsInput";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "560px",
    padding: "15px",
    marginTop: "20px",
    marginBottom: "20px",
    maxHeight: "100vh",
  },
};

Modal.setAppElement("#root");

export default function ConversationSavedRepliesModal({
  clientid = "",
  isOpenModal = false,
  afterOpenModal,
  closeModal,
  onSelect,
}) {
  const { user } = useContext(AuthContext);
  const {
    getSavedRepliesApi,
    deleteReplyTemplateApi,
    saveEditReplyTemplateApi,
    addReplyTemplateApi,
  } = useContext(ConversationContext);
  const [template, setTemplate] = useState({});
  const [filterKey, setFilterKey] = useState("");
  const [openedKey, setOpenedKey] = useState("");
  const [editReplyForm, setEditReplyForm] = useState({
    replyId: "",
    replyName: "",
    replyText: "",
  });
  const [showNewReplyForm, setShowNewReplyForm] = useState(false);
  const [newReplyForm, setNewReplyForm] = useState({
    replyName: "",
    replyText: "",
  });

  useEffect(() => {
    getSavedRepliesData(clientid);
  }, [clientid]);

  const getSavedRepliesData = async (_clientid) => {
    const data = await getSavedRepliesApi({
      userId: user.Id,
      clientid: _clientid,
    });
    if (data.status === "ok") {
      const res = data.response;
      setTemplate(res?.template);
    }
  };

  const onAdd = async () => {
    let params = {
      userId: user.Id,
      title: newReplyForm.replyName,
      description: newReplyForm.replyText,
    };
    const data = await addReplyTemplateApi(params);
    if (data.status === "ok") {
      setNewReplyForm({ replyName: "", replyText: "" });
      setShowNewReplyForm(false);
      getSavedRepliesData(clientid);
    }
  };

  const deletereplytemplate = async (itemId) => {
    const data = await deleteReplyTemplateApi({
      userId: user.Id,
      templateid: itemId,
    });
    if (data.status === "ok") {
      getSavedRepliesData(clientid);
    }
  };

  const onSaveEdit = async () => {
    let params = {
      userId: user.Id,
      id: editReplyForm.replyId,
      title: editReplyForm.replyName,
      description: editReplyForm.replyText,
    };
    const data = await saveEditReplyTemplateApi(params);
    if (data.status === "ok") {
      getSavedRepliesData(clientid);
    }
  };

  return (
    <Modal
      isOpen={isOpenModal}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Saved Replies"
    >
      <div className="modal-header">
        <h4>
          Saved Replies
          <button
            type="button"
            className={`btn btn-sm btn-primary ms-2${
              showNewReplyForm ? " d-none" : ""
            }`}
            onClick={() => setShowNewReplyForm(true)}
          >
            + Add Reply
          </button>
        </h4>
      </div>
      <div className="modal-body my-3">
        <input
          type="text"
          className="form-control"
          placeholder="Search by title or content..."
          onChange={(e) => setFilterKey(e.target.value)}
        />
        <>
          {showNewReplyForm && (
            <div className="editreplyform">
              <div className="shadow rounded p-3">
                <div className="row">
                  <div className="col-12">
                    <label className="mb-2 fw-bold">Reply name</label>
                    <input
                      type="text"
                      className="form-control"
                      value={newReplyForm.replyName}
                      onChange={(e) =>
                        setNewReplyForm({
                          ...newReplyForm,
                          replyName: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-12">
                    <label className="mb-2 fw-bold">Reply text</label>
                    <textarea
                      className="form-textarea mb-2"
                      value={newReplyForm.replyText}
                      onChange={(e) =>
                        setNewReplyForm({
                          ...newReplyForm,
                          replyText: e.target.value,
                        })
                      }
                    ></textarea>
                    <SmartFieldsInput
                      onSelect={(field) =>
                        setNewReplyForm({
                          ...newReplyForm,
                          replyText: newReplyForm.replyText + field,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-end">
                  <button
                    type="button"
                    className="btn-form ms-auto"
                    onClick={() => {
                      setNewReplyForm({
                        replyId: "",
                        replyName: "",
                        replyText: "",
                      });
                      setShowNewReplyForm(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn-form ms-2"
                    disabled={
                      !(
                        newReplyForm.replyId &&
                        newReplyForm.replyName &&
                        newReplyForm.replyText
                      )
                    }
                    onClick={() => onAdd()}
                  >
                    Add template
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
        <ul className="savedreplies">
          {!!template &&
            Object.keys(template)
              .filter((el) =>
                el?.toLowerCase().includes(filterKey?.toLowerCase()),
              )
              .map((key, index) => (
                <li className="savedreply-item" key={index}>
                  <span
                    className="savedreply-key"
                    onClick={() => setOpenedKey(openedKey === key ? "" : key)}
                  >
                    {key}
                  </span>
                  {openedKey === key && (
                    <div className="">
                      {template[key].map((item, index) => (
                        <div key={index}>
                          <div
                            id={`template_element_${item.Id}`}
                            className={`st-template${
                              item.isStar ? " featured" : ""
                            }`}
                          >
                            {Number(item.Isagency) === 1 ? (
                              Number(user.Type) === 5 ? (
                                <a className="featured-template" id={item.Id}>
                                  <BsFillStarFill />
                                </a>
                              ) : null
                            ) : (
                              <a className="featured-template" id={item.Id}>
                                <BsFillStarFill />
                              </a>
                            )}
                            <div
                              className="st-template-body"
                              role="button"
                              tabIndex="0"
                              onClick={() => onSelect(item.Desciption)}
                            >
                              <strong className="title">{item.Title}</strong>
                              <div className="description">
                                {item.Desciption}
                              </div>
                            </div>
                            {Number(item.Isagency) === 1 ? (
                              Number(user.Type) === 5 ? (
                                <div className="st-template-actions">
                                  <button
                                    type="button"
                                    className="st-button"
                                    onClick={() =>
                                      setEditReplyForm({
                                        replyId: item.Id,
                                        replyName: item.Title,
                                        replyText: item.Desciption,
                                      })
                                    }
                                  >
                                    <BsPencil />
                                  </button>
                                  <button
                                    type="button"
                                    className="st-button st-button-delete"
                                    onClick={() => deletereplytemplate(item.Id)}
                                  >
                                    <BsTrash />
                                  </button>
                                </div>
                              ) : null
                            ) : (
                              <div className="st-template-actions">
                                <button
                                  type="button"
                                  className="st-button"
                                  onClick={() =>
                                    setEditReplyForm({
                                      replyId: item.Id,
                                      replyName: item.Title,
                                      replyText: item.Desciption,
                                    })
                                  }
                                >
                                  <BsPencil />
                                </button>
                                <button
                                  type="button"
                                  className="st-button st-button-delete"
                                  onClick={() => deletereplytemplate(item.Id)}
                                >
                                  <BsTrash />
                                </button>
                              </div>
                            )}
                          </div>
                          <div className="clearfix"></div>
                          {editReplyForm.replyId === item.Id && (
                            <div className="editreplyform">
                              <div className="shadow rounded p-3">
                                <div className="row">
                                  <div className="col-12">
                                    <label className="mb-2 fw-bold">
                                      Reply name
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={editReplyForm.replyName}
                                      onChange={(e) =>
                                        setEditReplyForm({
                                          ...editReplyForm,
                                          replyName: e.target.value,
                                        })
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="row mt-3">
                                  <div className="col-12">
                                    <label className="mb-2 fw-bold">
                                      Reply text
                                    </label>
                                    <textarea
                                      className="form-textarea mb-2"
                                      value={editReplyForm.replyText}
                                      onChange={(e) =>
                                        setEditReplyForm({
                                          ...editReplyForm,
                                          replyText: e.target.value,
                                        })
                                      }
                                    ></textarea>
                                    <SmartFieldsInput
                                      onSelect={(field) =>
                                        setEditReplyForm({
                                          ...editReplyForm,
                                          replyText:
                                            editReplyForm.replyText + field,
                                        })
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-end">
                                  <button
                                    type="button"
                                    className="btn-form ms-auto"
                                    onClick={() =>
                                      setEditReplyForm({
                                        replyId: "",
                                        replyName: "",
                                        replyText: "",
                                      })
                                    }
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    type="button"
                                    className="btn-form ms-2"
                                    disabled={
                                      !(
                                        editReplyForm.replyId &&
                                        editReplyForm.replyName &&
                                        editReplyForm.replyText
                                      )
                                    }
                                    onClick={() => onSaveEdit()}
                                  >
                                    Save
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                </li>
              ))}
        </ul>
      </div>
    </Modal>
  );
}
