import { Switch } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { BsTrash3 } from "react-icons/bs";
import { AuthContext, ClientContext } from "../../context";
import { TagsInput } from "react-tag-input-component";
import helper from "../../utils/helper";
import DropdownMultiSelect from "../select/dropdown_multi_select";

export default function ClientAlertsTab({ clientid }) {
  const { user } = useContext(AuthContext);
  const { getAlertsApi, setactionApi } = useContext(ClientContext);
  const userNotifications = [
    { value: 1, label: "Leads" },
    { value: 2, label: "Appointments" },
    { value: 3, label: "Escalations" },
    { value: 4, label: "Finance Applications" },
  ];
  //
  const [postData, setPostData] = useState({
    campaign_email: [],
    adf_email: [],
    service_email: [],
    provider_name: "",
    provider_email: "",
    crm_convo: [],
    crm_convo_arr: [],
    uptype: "",
    crmtype: "",
    crmprovider: "",
    webhook: "",
    //
    Starttime: "",
    Endtime: "",
    Autoassign: 0,
    Hideassign: 0,
    Hideunassigned: 0,
    Autounassignlead: "",
  });
  const [Twilionumber, setTwilionumber] = useState("");
  const [assignuser, setAssignuser] = useState([]);

  useEffect(() => {
    getAlerts(clientid);
  }, [clientid]);

  const getAlerts = async (cid) => {
    const data = await getAlertsApi({ userId: user.Id, cid: cid });
    if (data.status === "ok") {
      const res = data.response;
      //
      let _postData = { ...postData };
      _postData.campaign_email = res?.client?.campaign_email
        ? res?.client?.campaign_email?.split(",")
        : [];
      _postData.adf_email = res?.client?.adf_email
        ? res?.client?.adf_email?.split(",")
        : [];
      _postData.service_email = res?.client?.service_email
        ? res?.client?.service_email?.split(",")
        : [];
      _postData.provider_name = res?.client.provider_name;
      _postData.provider_email = res?.client.provider_email;
      let crm_convo_arr = [];
      if (Number(res?.client.crm_convo_appt) === 1) {
        crm_convo_arr.push({ value: 1, label: "Appointments" });
      }
      if (Number(res?.client.crm_convo_manual) === 1) {
        crm_convo_arr.push({ value: 2, label: "Manual" });
      }
      _postData.crm_convo_arr = crm_convo_arr;
      _postData.crm_convo = crm_convo_arr.map((item) => item.value);
      _postData.uptype = res?.client.uptype;
      _postData.crmtype = res?.client.crmtype;
      _postData.crmprovider = res?.client.crmprovider;
      _postData.webhook = res?.client.webhook;
      //
      _postData.Starttime = res?.client.Starttime;
      _postData.Endtime = res?.client.Endtime;
      _postData.Autoassign = 0;
      _postData.Hideassign = 0;
      _postData.Hideunassigned = 0;
      _postData.Autounassignlead = res?.client.Autounassignlead;
      setPostData(_postData);
      setTwilionumber(res?.client.Twilionumber);
      setAssignuser(res?.assignuser);
    }
  };

  const onChangeUserNotification = (index, values) => {
    let _assignuser = [...assignuser];
    _assignuser[index].action = values.map((item) => item.value);
    setAssignuser(_assignuser);
  };

  const onSelectAction = async (item, values) => {
    let sid = item.Sid;
    let isavailable = 1;
    if (values.length === 0) {
      isavailable = 0;
    }
    let params = {};
    params.id = sid;
    params.isavailable = isavailable;
    params.action = values.map((el) => el.value);
    const data = await setactionApi(params);
    if (data.status === "ok") {
      getAlerts(clientid);
    }
  };

  const deleteattacheduser = (sid) => {
    console.log(sid);
  };

  const onSave = () => {
    console.log(">>>", postData);
  };

  return (
    <>
      <h6 className="tab-tile">Alerts</h6>
      <div className="row mt-2">
        <div className="col col-sm-6">
          <div className="form-group">
            <label className="mb-2">
              Escalations and Appointment Notifications
            </label>
            <TagsInput
              style={{ minHeight: "100px" }}
              classNames="form-textarea"
              value={postData.campaign_email}
              onChange={(tags) =>
                setPostData({ ...postData, campaign_email: tags })
              }
              beforeAddValidate={(tag, tags) =>
                !tags.includes(tag) && helper.validateEmail(tag)
              }
              name="campaign_email"
              placeHolder="add email"
            />
          </div>
        </div>
        <div className="col col-sm-6">
          <div className="form-group">
            <label className="mb-2">ADF Email</label>
            <TagsInput
              style={{ minHeight: "100px" }}
              classNames="form-textarea"
              value={postData.adf_email}
              onChange={(tags) => setPostData({ ...postData, adf_email: tags })}
              beforeAddValidate={(tag, tags) =>
                !tags.includes(tag) && helper.validateEmail(tag)
              }
              name="adf_email"
              placeHolder="add email"
            />
          </div>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col col-sm-6">
          <div className="form-group">
            <label className="mb-2">Serivce Contact Email</label>
            <TagsInput
              style={{ minHeight: "100px" }}
              classNames="form-textarea"
              value={postData.service_email}
              onChange={(tags) =>
                setPostData({ ...postData, service_email: tags })
              }
              beforeAddValidate={(tag, tags) =>
                !tags.includes(tag) && helper.validateEmail(tag)
              }
              name="service_email"
              placeHolder="add email"
            />
          </div>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col col-sm-6">
          <div className="form-group">
            <label className="mb-2">Provider Name</label>
            <input
              type="text"
              className="form-control"
              value={postData.provider_name}
              onChange={(e) =>
                setPostData({ ...postData, provider_name: e.target.value })
              }
            />
          </div>
        </div>
        <div className="col col-sm-6">
          <div className="form-group">
            <label className="mb-2">Provider Email</label>
            <input
              type="text"
              className="form-control"
              value={postData.provider_email}
              onChange={(e) =>
                setPostData({ ...postData, provider_email: e.target.value })
              }
            />
          </div>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col col-sm-3">
          <div className="form-group">
            <label className="mb-2">When to push Conversation</label>
            <DropdownMultiSelect
              className=""
              options={[
                { value: 1, label: "Appointments" },
                { value: 2, label: "Manual" },
              ]}
              placeholder={"Please Select"}
              labelField="label"
              valueField="value"
              values={postData.crm_convo_arr}
              onChange={(values) =>
                setPostData({
                  ...postData,
                  crm_convo_arr: values,
                  crm_convo: values.map((item) => item.value),
                })
              }
              onDropdownClose={(values) => {
                console.log(values);
              }}
            />
          </div>
        </div>
        <div className="col col-sm-3">
          <div className="form-group">
            <label className="mb-2">Uptype</label>
            <select
              className="form-select"
              value={postData.uptype}
              onChange={(e) =>
                setPostData({ ...postData, uptype: e.target.value })
              }
            >
              <option value="">Select</option>
              <option value="campaign">Campaign</option>
              <option value="internet">Internet</option>
            </select>
          </div>
        </div>
        <div className="col col-sm-3">
          <div className="form-group">
            <label className="mb-2">CRM Type</label>
            <select
              className="form-select"
              value={postData.crmtype}
              onChange={(e) =>
                setPostData({ ...postData, crmtype: e.target.value })
              }
            >
              <option value="">Standard ADF</option>
              <option value="eLead">eLead</option>
            </select>
          </div>
        </div>
        <div className="col col-sm-3">
          <div className="form-group">
            <label className="mb-2">CRM Provider</label>
            <input
              type="text"
              className="form-control"
              value={postData.crmprovider}
              onChange={(e) =>
                setPostData({ ...postData, crmprovider: e.target.value })
              }
            />
          </div>
        </div>
      </div>
      <div className="row my-4">
        <div className="col col-sm-12">
          <div className="form-group">
            <label className="mb-2">Webhook</label>
            <input
              type="text"
              className="form-control"
              value={postData.webhook}
              onChange={(e) =>
                setPostData({ ...postData, webhook: e.target.value })
              }
            />
          </div>
        </div>
      </div>
      <hr />
      {!Twilionumber ? (
        <div className="">
          <h6 className="w-100">SMS Notifications</h6>
          <div className="alert alert-warning">
            Please add a phone number in Lead Sources to access SMS
            Notifications
          </div>
        </div>
      ) : (
        <>
          <div className="d-flex align-items-center">
            <h6>SMS Notifications</h6>
            <button type="button" className="btn btn-sm btn-primary ms-auto">
              + Add New
            </button>
          </div>
          <div className="row mt-3 mb-5">
            <div className="col-12">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Phone</th>
                    <th>User Type</th>
                    <th>Notifications</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {assignuser &&
                    assignuser.map((item, index) => (
                      <tr key={index}>
                        <td>
                          <a>{item.Name}</a>
                        </td>
                        <td>{item.Mobile}</td>
                        <td>
                          {Number(item.Type) === 2 ? "Manager" : "Agency Admin"}
                        </td>
                        <td>
                          <DropdownMultiSelect
                            className="my-2"
                            options={userNotifications}
                            placeholder={"Please Select"}
                            labelField="label"
                            valueField="value"
                            values={userNotifications.filter((el) =>
                              item?.action?.includes(el.value),
                            )}
                            onChange={(values) =>
                              onChangeUserNotification(index, values)
                            }
                            onDropdownClose={(values) => {
                              onSelectAction(item, values);
                            }}
                          />
                        </td>
                        <td>
                          <button
                            className="btn btn-sm btn-outline-secondary 
                            d-flex align-items-center justify-content-center p-1"
                            onClick={() => deleteattacheduser(item.Sid)}
                          >
                            <BsTrash3 />
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
      <hr />
      <h6>Client Setting</h6>
      <div className="row mt-2">
        <div className="col-12 col-sm-10 col-md-8 col-lg-6">
          <div className="d-flex align-items-center">
            <label className="w-100">Agent Hours</label>
            <input
              type="time"
              className="form-control ms-2"
              value={postData.Starttime}
              onChange={(e) =>
                setPostData({ ...postData, Starttime: e.target.value })
              }
            />
            <input
              type="time"
              className="form-control ms-2"
              value={postData.Endtime}
              onChange={(e) =>
                setPostData({ ...postData, Endtime: e.target.value })
              }
            />
          </div>
          <div className="form-group mt-3">
            <label className="w-100">Auto-Assign Leads</label>
            <Switch
              value={postData.Autoassign}
              onChange={(e) =>
                setPostData({ ...postData, Autoassign: e.target.checked })
              }
            />
          </div>
          <div className="form-group mt-3">
            <label className="w-100">Hide-Assign Leads</label>
            <Switch
              value={postData.Hideunassigned}
              onChange={(e) =>
                setPostData({ ...postData, Hideunassigned: e.target.checked })
              }
            />
          </div>
          <div className="form-group mt-3">
            <label className="w-100">Hide-Unassigned Leads</label>
            <Switch
              value={postData.Hideunassigned}
              onChange={(e) =>
                setPostData({ ...postData, Hideunassigned: e.target.checked })
              }
            />
          </div>
          <div className="form-group mt-3">
            <label className="w-100">Auto-Unassign Leads</label>
            <select
              className="form-select"
              value={postData.Autounassignlead}
              onChange={(e) =>
                setPostData({ ...postData, Autounassignlead: e.target.value })
              }
            >
              <option value="0">Never</option>
              <option value="1">1 minute</option>
              <option value="2">2 minutes</option>
              <option value="3">3 minutes</option>
              <option value="5">5 minutes</option>
              <option value="60">1 hour</option>
            </select>
          </div>
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-12 d-flex">
          <button
            type="button"
            className="btn btn-primary ms-auto"
            onClick={() => onSave()}
          >
            Save
          </button>
          <button
            type="button"
            className="btn btn-outline-secondary ms-1 me-auto"
          >
            Cancel
          </button>
        </div>
      </div>
    </>
  );
}
