import Modal from "react-modal";
import React from "react";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "560px",
    padding: "15px",
    marginTop: "20px",
    marginBottom: "20px",
  },
};

Modal.setAppElement("#root");

export default function CommonModal({
  modalTitle = "",
  modalContent = "",
  yesButtonLabel = "Export",
  noButtonLabel = "Cancel",
  isOpenModal = false,
  afterOpenModal,
  closeModal,
}) {
  return (
    <Modal
      isOpen={isOpenModal}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel={modalTitle}
    >
      <div className="modal-header">
        <h4>{modalTitle}</h4>
      </div>
      <div className="modal-body">
        <form>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 mt-3">
              <p>{modalContent}</p>
            </div>
          </div>
        </form>
      </div>
      <div className="modal-footer mt-3">
        <button className="btn btn-primary me-2" onClick={closeModal}>
          {yesButtonLabel}
        </button>
        <button className="btn btn-outline-secondary" onClick={closeModal}>
          {noButtonLabel}
        </button>
      </div>
    </Modal>
  );
}
