import React, { useContext, useEffect, useState } from "react";
import { BsPlusCircle } from "react-icons/bs";
import { AuthContext, ConversationContext } from "../../context";
import { LiaTimesSolid } from "react-icons/lia";
import ConversationManageLabelsModal from "../modals/conversation_manage_labels_modal";

export default function ConversationsLeadLabels({ leadId }) {
  const { user } = useContext(AuthContext);
  const { loadLabelsApi, deleteTagApi, leadTagAutoCompleteApi, addLeadTagApi } =
    useContext(ConversationContext);
  const [infoLabels, setInfoLabels] = useState([]);
  const [showAddLabelForm, setShowAddLabelForm] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [autocompleteLabels, setAutocompleteLabels] = useState([]);
  const [openManageLabelsModal, setOpenManageLabelsModal] = useState(false);

  useEffect(() => {
    if (leadId) {
      loadLabels(leadId);
    }
  }, [leadId]);

  const loadLabels = async (_leadId) => {
    const data = await loadLabelsApi({ userId: user.Id, lead: _leadId });
    if (data.status === "ok") {
      const res = data.response;
      setInfoLabels(res?.info);
    }
  };

  const deleteTag = async (tagId) => {
    const data = await deleteTagApi({
      userId: user.Id,
      leadid: leadId,
      tagid: tagId,
    });
    if (data.status === "ok") {
      loadLabels(leadId);
    }
  };

  const onSearch = async () => {
    const data = await leadTagAutoCompleteApi({
      userId: user.Id,
      term: searchKey,
    });
    if (data.status === "ok") {
      const res = Object.values(data.response);
      setAutocompleteLabels(res);
    }
  };

  const addNewLabelTag = async (label) => {
    setSearchKey("");
    setAutocompleteLabels([]);
    const data = await addLeadTagApi({
      userId: user.Id,
      leadid: leadId,
      tagid: label.id,
    });
    if (data.status === "ok") {
      loadLabels(leadId);
    }
  };

  return (
    <>
      <div className="labels-list mb-2">
        {infoLabels.length > 0 &&
          infoLabels.map((item, index) => (
            <label className="status" key={index}>
              <span>
                <span className={`label ${item?.Class}`}>
                  <LiaTimesSolid
                    className="icon-delete"
                    onClick={() => deleteTag(item?.Tagid)}
                  />
                </span>
                {item?.Name}
              </span>
            </label>
          ))}
      </div>
      <div className="d-flex align-items-center">
        <button
          className={`btn btn-sm d-flex align-items-center ${
            showAddLabelForm ? "btn-primary" : "btn-outline-secondary"
          }`}
          onClick={() => setShowAddLabelForm(!showAddLabelForm)}
        >
          <BsPlusCircle className="me-2" />
          Add Label
        </button>
        {showAddLabelForm && (
          <a
            className="text-primary text-decoration-none cursor-pointer ms-auto"
            onClick={() => setOpenManageLabelsModal(true)}
          >
            Manage Labels
          </a>
        )}
      </div>
      <div
        className={`autocomplete-label-form mt-2${
          showAddLabelForm ? "" : " d-none"
        }`}
      >
        <input
          type="text"
          className="form-control w-100"
          placeholder="Add label..."
          value={searchKey}
          onChange={(e) => setSearchKey(e.target.value)}
          onKeyUp={(e) => {
            if (e.key === "Enter" && searchKey) {
              onSearch();
            }
          }}
        />
        {autocompleteLabels.length > 0 && (
          <ul className="autocomplete-labels">
            {autocompleteLabels.map((item, index) => (
              <li
                className="autocomplete-label"
                key={index}
                onClick={() => addNewLabelTag(item)}
              >
                <span className={`bubble ${item?.value}`}></span>
                {item?.label}
              </li>
            ))}
          </ul>
        )}
      </div>
      <ConversationManageLabelsModal
        isOpenModal={openManageLabelsModal}
        afterOpenModal={() => {}}
        closeModal={() => setOpenManageLabelsModal(false)}
      />
    </>
  );
}
