import React, { useState } from "react";
import { Box, Button, Divider, Modal, Stack, Typography } from "@mui/material";
import { PrimaryInput, PrimaryTextArea } from "..";

export default function UserTemplateEditModal(props) {
  const { open, handleCloseModal, template, handleUpdate } = props;
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: {
      xs: "90%",
      md: "35%",
    },
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 2,
    p: 2,
  };
  const [showError, setShowError] = useState(false);
  const [info, setInfo] = useState(template);

  const update = async () => {
    handleUpdate();
  };

  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="user_template_edit_modal"
    >
      <Box sx={style}>
        <Stack
          direction={"row"}
          spacing={1}
          justifyContent={"start"}
          alignItems={"center"}
        >
          <Typography variant="h6">Edit Saved Replies</Typography>
        </Stack>
        <Divider />
        <Box mt={2}>
          <PrimaryInput
            value={info?.Title}
            handleChange={(e) => {
              setInfo({
                ...info,
                Title: e.target.value,
              });
            }}
            placeholder="Title"
            type="text"
            label="Reply name"
            sx={{
              width: 200,
            }}
          />
          {showError && (
            <Typography variant="caption" color="red" sx={{ ml: 1 }}>
              Required Title
            </Typography>
          )}
          <Box mt={2} mb={2}>
            <PrimaryTextArea
              placeholder={"Description"}
              value={info?.Desciption}
              rows={2}
              label={"Reply text"}
              handleChange={(e) => {
                const value = e.target.value;
                if (value) {
                  setShowError(false);
                  setInfo({
                    ...info,
                    Desciption: e.target.value,
                  });
                } else {
                  setShowError(true);
                }
              }}
            />
          </Box>
        </Box>
        <Divider />
        <Stack
          direction={"row"}
          justifyContent={"end"}
          alignItems={"center"}
          spacing={2}
          mt={2}
        >
          <Button variant="contained" color="info" onClick={update}>
            Update
          </Button>
          <Button color="inherit" onClick={handleCloseModal}>
            Cancel
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
}
