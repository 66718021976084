import { LiaCommentDollarSolid } from "react-icons/lia";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext, DashboardContext } from "../../context";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import DateFilterPicker from "../DateFilterPicker/DateFilterPicker";
import config from "../../config/config";
import moment from "moment";
import {
  Avatar,
  Box,
  Card,
  LinearProgress,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { ColorsConfig } from "../../config";
import { FormatHelper } from "../../utils";

export default function LeadSourcesSection({ selectedDealershipIds = "" }) {
  const [selectedDays, setSelectedDays] = useState(7);
  const [customDateRage, setCustomDateRange] = useState({
    start: new Date(moment().subtract(1, "months")),
    end: new Date(),
  });
  const [showLoading, setShowLoading] = useState(false);
  const { user } = useContext(AuthContext);
  const { getLeadSource, leadSourceTotal, leadSourceGroup } =
    useContext(DashboardContext);

  useEffect(() => {
    if (selectedDays !== "custom") {
      getLeadSourceData();
    }
  }, [selectedDealershipIds, selectedDays]);

  useEffect(() => {
    if (selectedDays === "custom") {
      getLeadSourceData();
    }
  }, [customDateRage]);

  const getLeadSourceData = async () => {
    setShowLoading(true);
    const res = await getLeadSource({
      userId: user.Id,
      days: selectedDays,
      startDate: customDateRage.start,
      endDate: customDateRage.end,
      dealershipIds: selectedDealershipIds,
    });
    if (res) {
      setShowLoading(false);
    }
  };

  return (
    <Card
      sx={{
        p: 2,
        pt: 3,
        height: "100%",
      }}
    >
      <Stack
        direction={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        spacing={2}
      >
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"center"}
          spacing={1}
        >
          <LiaCommentDollarSolid style={{ fontSize: 26, color: "inherit" }} />
          <Typography variant="body1">Lead Sources</Typography>
        </Stack>
        <DateFilterPicker
          selectedDays={selectedDays}
          setSelectedDays={setSelectedDays}
          customDateRage={customDateRage}
          setCustomDateRange={setCustomDateRange}
        />
      </Stack>
      <Box mt={2}>
        {showLoading ? (
          <Skeleton variant="rounded" animation="wave" height={250} />
        ) : leadSourceGroup && leadSourceGroup.length ? (
          <Swiper
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            spaceBetween={50}
            slidesPerView={1}
            pagination={{ clickable: true, dynamicBullets: true }}
            onSlideChange={() => console.log("slide change")}
            onSwiper={(swiper) => console.log(swiper)}
          >
            {leadSourceGroup.map((lead, gindex) => (
              <SwiperSlide key={gindex}>
                {lead.map((item, index) => (
                  <Stack
                    key={index}
                    direction={"column"}
                    alignItems={"space-between"}
                    justifyContent={"center"}
                    spacing={0.5}
                    mb={2}
                  >
                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                    >
                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        spacing={0.5}
                      >
                        <Avatar
                          sx={{
                            width: 22,
                            height: 22,
                          }}
                          src={`${
                            item.Logo
                              ? item.Logo
                              : `${
                                  config.assetPath
                                }//images/material-letter-icons/png/${item.Name.charAt(
                                  0,
                                ).toUpperCase()}.png`
                          }`}
                        />
                        <Link
                          to={`${config.reportPath}/agencyleadsource/${item.linkid}`}
                          target="_blank"
                          style={{
                            textDecoration: "none",
                          }}
                        >
                          <Typography
                            sx={{
                              color: ColorsConfig.light.info,
                              fontSize: 13,
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              // goToAgencyLeadSource(
                              //   `${config.reportPath}/agencyleadsource/${item.linkid}`,
                              // );
                            }}
                          >
                            {item.Name}
                          </Typography>
                        </Link>
                      </Stack>
                      <Typography variant="body2">
                        {FormatHelper.numberEN_USFormat(item.ct)} Leads
                      </Typography>
                    </Stack>
                    <LinearProgress
                      variant="determinate"
                      value={Math.round(
                        (Number(item.ct) / leadSourceTotal) * 100,
                      )}
                      sx={{
                        height: 6,
                        borderRadius: 4,
                      }}
                    />
                  </Stack>
                ))}
                <Box mt={5}></Box>
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          <Typography variant="body1" width={"100%"} textAlign={"center"}>
            No result
          </Typography>
        )}
      </Box>
    </Card>
  );
}
