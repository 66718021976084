import { HiOutlineUserCircle } from "react-icons/hi2";
import { IoCarSportOutline } from "react-icons/io5";
import { SlBubble, SlBubbles, SlClock } from "react-icons/sl";
import ApexLineChart from "../ApexCharts/ApexLineChart";
import { AuthContext, DashboardContext } from "../../context";
import React, { useContext, useEffect, useState } from "react";
import DateFilterPicker from "../DateFilterPicker/DateFilterPicker";
import moment from "moment";
import { Box, Card, Skeleton, Stack, Typography } from "@mui/material";
import { ColorsConfig } from "../../config";
import { FormatHelper } from "../../utils";

export default function AgentResponseSection({ selectedDealershipIds = "" }) {
  const [selectedDays, setSelectedDays] = useState(7);
  const [customDateRage, setCustomDateRange] = useState({
    start: new Date(moment().subtract(1, "months")),
    end: new Date(),
  });
  const [showLoading, setShowLoading] = useState(false);
  const { user } = useContext(AuthContext);
  const { getAgentResponse, agentResponse, dailyAgentResponse } =
    useContext(DashboardContext);

  const items = [
    {
      component: (
        <HiOutlineUserCircle style={{ fontSize: 28, color: "lightblue" }} />
      ),
      index: "Lead",
      title: "LEADS",
    },
    {
      component: <SlBubble style={{ fontSize: 28, color: "purple" }} />,
      index: "Message",
      title: "MESSAGES",
    },
    {
      component: <SlBubbles style={{ fontSize: 28, color: "orange" }} />,
      index: "Engagment",
      title: "ENGAGEMENT",
    },
    {
      component: <SlClock style={{ fontSize: 28, color: "green" }} />,
      index: "Appointment",
      title: "APPOINTMENTS",
    },
    {
      component: <IoCarSportOutline style={{ fontSize: 28, color: "red" }} />,
      index: "Sold",
      title: "SOLD",
    },
  ];

  useEffect(() => {
    if (selectedDays !== "custom") {
      getAgentResponseData();
    }
  }, [selectedDealershipIds, selectedDays]);

  useEffect(() => {
    if (selectedDays === "custom") {
      getAgentResponseData();
    }
  }, [customDateRage]);

  const getAgentResponseData = async () => {
    setShowLoading(true);
    const res = await getAgentResponse({
      userId: user.Id,
      days: selectedDays,
      startDate: customDateRage.start,
      endDate: customDateRage.end,
      dealershipIds: selectedDealershipIds,
    });
    if (res) {
      setShowLoading(false);
    }
  };

  const formattedSeries = (data) => {
    const _series = [
      {
        name: "Leads",
        data: [],
        color: "#2196f3",
      },
      {
        name: "Messages",
        data: [],
        color: "#af9fd7",
      },
      {
        name: "Appointments",
        data: [],
        color: "#00aa9d",
      },
      {
        name: "Sold",
        data: [],
        color: "#ff0000",
      },
    ];
    for (let i = 0; i < data.length; i++) {
      _series[0].data.push(data[i].Lead);
      _series[1].data.push(data[i].Message);
      _series[2].data.push(data[i].Appointment);
      _series[3].data.push(data[i].Sold);
    }

    return _series;
  };

  return (
    <Card
      sx={{
        p: 2,
        pt: 3,
        height: "100%",
      }}
    >
      <Box>
        <Stack direction={"row"} justifyContent={"end"}>
          <DateFilterPicker
            selectedDays={selectedDays}
            setSelectedDays={setSelectedDays}
            customDateRage={customDateRage}
            setCustomDateRange={setCustomDateRange}
          />
        </Stack>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-around"}
          mt={2}
        >
          {items.map((item, index) => {
            if (showLoading || agentResponse?.Lead == null) {
              return (
                <Stack
                  direction={"column"}
                  alignItems={"center"}
                  spacing={0}
                  key={index}
                >
                  <Skeleton
                    animation={"wave"}
                    variant={"circular"}
                    width={35}
                    height={35}
                  />
                  <Skeleton animation="wave" variant="text" width={60} />
                  <Skeleton animation="wave" variant="text" width={60} />
                </Stack>
              );
            } else {
              return (
                <Stack
                  direction={"column"}
                  alignItems={"center"}
                  spacing={0}
                  key={index}
                >
                  {item.component}
                  <Typography variant="h6">
                    {FormatHelper.numberEN_USFormat(
                      agentResponse?.[item.index],
                    )}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 10,
                      color: ColorsConfig.light.primaryLightColor,
                    }}
                  >
                    {item.title}
                  </Typography>
                </Stack>
              );
            }
          })}
        </Stack>
      </Box>
      <Box mt={2}>
        {showLoading ? (
          <Skeleton variant="rounded" animation="wave" height={200} />
        ) : dailyAgentResponse.length ? (
          <ApexLineChart
            labels={
              dailyAgentResponse
                ? dailyAgentResponse.map((item) => item.display)
                : []
            }
            series={formattedSeries(dailyAgentResponse)}
          />
        ) : (
          <Typography variant="body2" width={"100%"} textAlign={"center"}>
            No results
          </Typography>
        )}
      </Box>
    </Card>
  );
}
