import React from "react";
import Chart from "react-apexcharts";

export default function ApexAreaChart({ labels, series }) {
  const options = ({ categories }) => {
    return {
      chart: {
        id: "basic-bar",
        type: "area",
        toolbar: {
          show: false,
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          stops: [0, 90, 100],
        },
      },
      xaxis: {
        categories: categories ? categories : [],
      },
      stroke: {
        curve: "smooth",
        width: 2,
      },
    };
  };

  return (
    <div className="apex-chart-wrapper">
      <Chart
        options={options({ categories: labels })}
        series={series ? series : []}
        type="line"
        width="100%"
        height="200"
      />
    </div>
  );
}
