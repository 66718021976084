import { Link, useNavigate } from "react-router-dom";
import { SlBriefcase } from "react-icons/sl";
import React, { useEffect, useState } from "react";
import { BsPlus } from "react-icons/bs";
import ClientInfoForm from "./ClientNew/ClientInfoForm";
import ClientHoursForm from "./ClientNew/ClientHoursForm";
import { useContext } from "react";
import { AuthContext, ClientContext } from "../../context";

export default function ClientNewPage() {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("Client Info");
  //
  const { user } = useContext(AuthContext);
  const { getNewClientPageDataApi, createNewClientApi } =
    useContext(ClientContext);
  //
  const [isDealerfunnelUser, setIsDealerFunnelUser] = useState("");
  const [isagentchain, setIsagentchain] = useState("");
  const [agent, setAgent] = useState([]);
  const [dealer, setDealer] = useState([]);
  const [usertype, setUsertype] = useState("");
  const [state, setState] = useState([]);
  const [analystics, setAnalystics] = useState([]);
  const [manager, setManager] = useState([]);
  const [executive, setExecutive] = useState([]);
  //
  const [postData, setPostData] = useState({
    userId: user.Id,
    // Client Info
    Name: "",
    Ctype: "",
    Agency: "",
    Group: "",
    Logo: "",
    Mlogo: "",
    color: "",
    DealershipContact: "",
    DealershipWebsite: "",
    Phone: "",
    ClientInventoryURL: "",
    CreditAppURL: "",
    Address: "",
    City: "",
    State: "",
    Zip: "",
    Viewid: "",
    Fcid: "",
    sales_field: "",
    AccountManager: "",
    AccountExecutive: "",
    AreaCode: "",
    AccountManager2: "",
    pllogo: "",
    pcolor: "",
    loginurl: "",
    pcopyright: "",
    padf: "",
    pemail: "",
    campaign_email: "",
    adf_email: "",
    provider_name: "",
    provider_email: "",
    crm_convo: [],
    crm_convo_arr: [],
    // Hours
    time_zone: "",
    Isopen_d1: 1,
    Isopen_d2: 1,
    Isopen_d3: 1,
    Isopen_d4: 1,
    Isopen_d5: 1,
    Isopen_d6: 1,
    Isopen_d7: 1,
    From_d1: "",
    From_d2: "",
    From_d3: "",
    From_d4: "",
    From_d5: "",
    From_d6: "",
    From_d7: "",
    To_d1: "",
    To_d2: "",
    To_d3: "",
    To_d4: "",
    To_d5: "",
    To_d6: "",
    To_d7: "",
    //
    Issms: 0,
    isprivate: 0,
    ManualMatch: 0,
    // Agency: '',
    adf_flag: 0,
    cmpoverview_flag: 0,
    sendweblead_flag: 0,
  });

  useEffect(() => {
    getNewClientPageData();
  }, []);

  const getNewClientPageData = async () => {
    const data = await getNewClientPageDataApi({ userId: user.Id });
    if (data.status === "ok") {
      const res = data.response;
      setIsDealerFunnelUser(res?.is_dealerfunnel_user);
      setIsagentchain(res?.isagentchain);
      setAgent(res?.agent);
      setDealer(res?.dealer);
      setUsertype(res?.usertype);
      setState(res?.state);
      setAnalystics(res?.analystics);
      setManager(res?.manager);
      setExecutive(res?.executive);
      //
      let _postData = { ...postData };
      if (Number(res?.is_dealerfunnel_user) === 0) {
        _postData.Agency = res?.sagent;
      } else if (Number(res?.is_dealerfunnel_user) === 1) {
        _postData.Agency = res?.sagent;
      }
      if (Number(res?.isagentchain) === 1) {
        _postData.Agency1 = res?.sagent;
        _postData.Agency = res?.sagent;
      }
      _postData.provider_name = res?.provider_info?.provider_name;
      _postData.provider_email = res?.provider_info?.provider_email;
      setPostData(_postData);
    }
  };

  const onSave = async () => {
    const data = await createNewClientApi(postData);
    if (data.status === "ok") {
      navigate("/clients");
    }
  };

  return (
    <main className="container py-4 position-relative">
      <div className="d-flex align-items-center">
        <div className="section-title">
          <SlBriefcase className="icon me-2" />
          New Client
        </div>
        <div className="ms-auto">
          <Link
            to="/clients/newclient"
            className="btn btn-primary d-inline-flex align-items-center"
          >
            <BsPlus />
            New Client
          </Link>
        </div>
      </div>
      <div className="card w-100 mt-4 overflow-visible">
        <div className="card-body">
          <div className="row">
            <div className="col col-sm-2 pe-0 border-end border-2">
              <ul className="nav nav-tabs-vertical">
                <li
                  className={`nav-item${
                    activeTab === "Client Info" ? " active" : ""
                  }`}
                  onClick={() => setActiveTab("Client Info")}
                >
                  Client Info
                </li>
                <li
                  className={`nav-item${
                    activeTab === "Hours" ? " active" : ""
                  }`}
                  onClick={() => setActiveTab("Hours")}
                >
                  Hours
                </li>
              </ul>
            </div>
            <div className="col col-sm-10">
              <div className="tab-content">
                <div className="tab-pane active">
                  {activeTab === "Client Info" && (
                    <ClientInfoForm
                      isDealerfunnelUser={isDealerfunnelUser}
                      isagentchain={isagentchain}
                      agent={agent}
                      dealer={dealer}
                      usertype={usertype}
                      state={state}
                      analystics={analystics}
                      manager={manager}
                      executive={executive}
                      //
                      postData={postData}
                      setPostData={setPostData}
                      onSave={onSave}
                    />
                  )}
                  {activeTab === "Hours" && (
                    <ClientHoursForm
                      postData={postData}
                      setPostData={setPostData}
                      onSave={onSave}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
