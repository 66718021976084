import { BiArrowBack, BiMessageRounded, BiSolidEdit } from "react-icons/bi";
import { Link, useParams } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { BsCheckLg, BsTag, BsTelephone, BsTrash } from "react-icons/bs";
import { Switch } from "@mui/material";
import { IoSaveOutline } from "react-icons/io5";
import { AuthContext, FunnelContext } from "../../context";
import React, { useContext, useEffect, useState } from "react";
import {
  DropdownMultiSelect,
  FunnelCallRequiredModal,
  FunnelChangeStatusModal,
  FunnelDeleteMessageModal,
  FunnelEditDelayModal,
  FunnelNewLabelModal,
  FunnelNewMessageModal,
} from "../../components";
import helper from "../../utils/helper";
import { useNavigate } from "react-router-dom";
import moment from "moment";

export default function FunnelCopyPage() {
  const { fid } = useParams();
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const { copyFunnelPageDataApi, createNewFunnelApi } =
    useContext(FunnelContext);
  //
  const [client, setClient] = useState([]);
  const [message, setMessage] = useState([]);
  const [len, setLen] = useState(0);
  //
  const [selectedClients, setSelectedClients] = useState([]);
  const [postData, setPostData] = useState({
    userId: user.Id,
    funnel_create_message_form_count: 0,
    funnel_create_message_form_funnel_name: "",
    funnel_create_message_form_funnel_client: "",
    funnel_create_message_form_funnel_exclude_sunday: 1,
    //
    funnel_create_message_form_body: [],
    funnel_create_message_form_delaytime: [],
    funnel_create_message_form_title: [],
    funnel_create_message_form_gifkey: [],
    funnel_create_message_form_attached: [],
    funnel_create_message_form_delaycount: [],
    funnel_create_message_form_container_status: [],
    funnel_create_message_form_type: [],
    edited_at: [],
  });
  const [showError, setShowError] = useState(false);
  // new actions
  const [newMsgModal, setNewMsgModal] = useState(undefined);
  const [deleteMsgModal, setDeleteMsgModal] = useState("");
  const [editDelayModal, setEditDelayModal] = useState(undefined);
  const [newLabelModal, setNewLabelModal] = useState(undefined);
  const [changeStatusModal, setChangeStatusModal] = useState(false);
  const [callRequiredModal, setCallRequiredModal] = useState(false);

  useEffect(() => {
    if (fid) {
      getCopyFunnelPageData();
      console.log(message);
      console.log(len);
    }
  }, [fid]);

  const getCopyFunnelPageData = async () => {
    const data = await copyFunnelPageDataApi({ userId: user.Id, id: fid });
    if (data.status === "ok") {
      const res = data.response;
      setClient(res?.client);
      setMessage(res?.message);
      setLen(res?.len);
      //
      let _postData = { ...postData };
      // if (res?.len) {
      //   _postData.funnel_create_message_form_count = res?.len;
      // }
      if (res?.message) {
        let msg = res?.message;
        for (let i = 0; i < msg.length; i++) {
          _postData.funnel_create_message_form_count += 1;
          _postData.funnel_create_message_form_title.push(msg[i].Name);
          _postData.funnel_create_message_form_body.push(msg[i].Message);
          _postData.funnel_create_message_form_delaytime.push(
            msg[i].Delaycategory,
          );
          _postData.funnel_create_message_form_delaycount.push(
            msg[i].Delaycount,
          );
          _postData.funnel_create_message_form_gifkey.push(msg[i].Gif);
          _postData.funnel_create_message_form_attached.push(msg[i].Attached);
          _postData.funnel_create_message_form_type.push(msg[i].Messagetype);
          _postData.funnel_create_message_form_container_status.push(1);
          _postData.edited_at.push(moment().format("MMM  DD, YYYY h:mm a"));
        }
      }
      setPostData(_postData);
    }
  };

  const onSave = async () => {
    if (
      !postData.funnel_create_message_form_funnel_name ||
      !postData.funnel_create_message_form_funnel_client ||
      postData.funnel_create_message_form_count === 0
    ) {
      setShowError(true);
      return;
    }
    let params = { ...postData };
    params.userId = user.Id;
    const data = await createNewFunnelApi(params);
    if (data.status === "ok") {
      navigate(`/funnels`);
    }
  };

  const createNewMsg = (params) => {
    let _postData = { ...postData };
    if (
      params.index === "" ||
      params.index === undefined ||
      params.index === null
    ) {
      _postData.funnel_create_message_form_count += 1;
      _postData.funnel_create_message_form_title.push(params.Name);
      _postData.funnel_create_message_form_body.push(params.Message);
      _postData.funnel_create_message_form_delaytime.push(params.Delaycategory);
      _postData.funnel_create_message_form_delaycount.push(params.Delaycount);
      _postData.funnel_create_message_form_gifkey.push(params.Gif);
      _postData.funnel_create_message_form_attached.push(params.Attached);
      _postData.funnel_create_message_form_type.push(params.Messagetype);
      _postData.funnel_create_message_form_container_status.push(1);
      _postData.edited_at.push(moment().format("MMM  DD, YYYY h:mm a"));
    } else {
      _postData.funnel_create_message_form_title[params.index] = params.Name;
      _postData.funnel_create_message_form_body[params.index] = params.Message;
      _postData.funnel_create_message_form_delaytime[params.index] =
        params.Delaycategory;
      _postData.funnel_create_message_form_delaycount[params.index] =
        params.Delaycount;
      _postData.funnel_create_message_form_gifkey[params.index] = params.Gif;
      _postData.funnel_create_message_form_attached[params.index] =
        params.Attached;
      _postData.funnel_create_message_form_type[params.index] =
        params.Messagetype;
      _postData.edited_at[params.index] = moment().format(
        "MMM  DD, YYYY h:mm a",
      );
    }
    setPostData(_postData);
    setNewMsgModal(undefined);
  };

  const getlabelList = (msg) => {
    let lst = msg.split(",");
    let str = "";
    for (let i = 0; i < lst.length; i++) {
      let sp = lst[i].split("#");
      str += `<label class="status"><span><span class="${sp[1]}">
      <i class="fa-light fa-xmark" aria-hidden="true"></i></span>${sp[0]}</span></label>`;
    }
    return str;
  };

  const deleteMsg = (index) => {
    let _postData = { ...postData };
    _postData.funnel_create_message_form_count -= 1;
    _postData.funnel_create_message_form_title =
      _postData.funnel_create_message_form_title.filter((el, i) => i !== index);
    _postData.funnel_create_message_form_body =
      _postData.funnel_create_message_form_body.filter((el, i) => i !== index);
    _postData.funnel_create_message_form_delaytime =
      _postData.funnel_create_message_form_delaytime.filter(
        (el, i) => i !== index,
      );
    _postData.funnel_create_message_form_delaycount =
      _postData.funnel_create_message_form_delaycount.filter(
        (el, i) => i !== index,
      );
    _postData.funnel_create_message_form_gifkey =
      _postData.funnel_create_message_form_gifkey.filter(
        (el, i) => i !== index,
      );
    _postData.funnel_create_message_form_attached =
      _postData.funnel_create_message_form_attached.filter(
        (el, i) => i !== index,
      );
    _postData.funnel_create_message_form_type =
      _postData.funnel_create_message_form_type.filter((el, i) => i !== index);
    _postData.funnel_create_message_form_container_status =
      _postData.funnel_create_message_form_container_status.filter(
        (el, i) => i !== index,
      );
    setPostData(_postData);
    setDeleteMsgModal("");
  };

  const editmessage = (index) => {
    setNewMsgModal({
      userId: user.Id,
      Name: postData.funnel_create_message_form_title[index],
      Message: postData.funnel_create_message_form_body[index],
      Attached: postData.funnel_create_message_form_attached[index],
      Gif: postData.funnel_create_message_form_gifkey[index],
      Delaycategory: postData.funnel_create_message_form_delaytime[index],
      DelaycategoryObj: postData.funnel_create_message_form_delaytime[index]
        ? {
            value: postData.funnel_create_message_form_delaytime[index],
            label: postData.funnel_create_message_form_delaytime[index],
          }
        : { value: "", label: "- : -" },
      Delaycount: postData.funnel_create_message_form_delaycount[index],
      Messagetype: 1,
      index: index,
    });
  };

  const editdelay = (index) => {
    setEditDelayModal({
      userId: user.Id,
      message: postData.funnel_create_message_form_body[index],
      delaycategory: postData.funnel_create_message_form_delaytime[index],
      delaycategoryObj: postData.funnel_create_message_form_delaytime[index]
        ? {
            value: postData.funnel_create_message_form_delaytime[index],
            label: postData.funnel_create_message_form_delaytime[index],
          }
        : { value: "", label: "- : -" },
      delaytime: postData.funnel_create_message_form_delaycount[index],
      index: index,
    });
  };

  const saveDelayChange = (params) => {
    if (
      params.index !== "" ||
      params.index !== undefined ||
      params.index !== null
    ) {
      let _postData = { ...postData };
      _postData.funnel_create_message_form_delaytime[params.index] =
        params.delaycategory;
      _postData.funnel_create_message_form_delaycount[params.index] =
        params.delaytime;
      setPostData(_postData);
      setEditDelayModal(undefined);
    }
  };

  const createNewLabel = (params) => {
    let _postData = { ...postData };
    if (
      params.index === "" ||
      params.index === undefined ||
      params.index === null
    ) {
      _postData.funnel_create_message_form_count += 1;
      _postData.funnel_create_message_form_title.push(params.Name);
      _postData.funnel_create_message_form_body.push(params.Message);
      _postData.funnel_create_message_form_delaytime.push(params.Delaycategory);
      _postData.funnel_create_message_form_delaycount.push(params.Delaycount);
      _postData.funnel_create_message_form_type.push(params.Messagetype);
      _postData.funnel_create_message_form_container_status.push(1);
      _postData.edited_at.push(moment().format("MMM  DD, YYYY h:mm a"));
    } else {
      _postData.funnel_create_message_form_title[params.index] = params.Name;
      _postData.funnel_create_message_form_body[params.index] = params.Message;
      _postData.funnel_create_message_form_delaytime[params.index] =
        params.Delaycategory;
      _postData.funnel_create_message_form_delaycount[params.index] =
        params.Delaycount;
      _postData.funnel_create_message_form_type[params.index] =
        params.Messagetype;
      _postData.edited_at[params.index] = moment().format(
        "MMM  DD, YYYY h:mm a",
      );
    }
    setPostData(_postData);
    setNewLabelModal(undefined);
  };

  const editlabel = (index) => {
    setNewLabelModal({
      userId: user.Id,
      Name: postData.funnel_create_message_form_title[index],
      Message: postData.funnel_create_message_form_body[index],
      Delaycategory: postData.funnel_create_message_form_delaytime[index],
      DelaycategoryObj: postData.funnel_create_message_form_delaytime[index]
        ? {
            value: postData.funnel_create_message_form_delaytime[index],
            label: postData.funnel_create_message_form_delaytime[index],
          }
        : { value: "", label: "- : -" },
      Delaycount: postData.funnel_create_message_form_delaycount[index],
      Messagetype: postData.funnel_create_message_form_type[index],
      index: index,
    });
  };

  const saveChangeStatus = (params) => {
    let _postData = { ...postData };
    if (
      params.index === "" ||
      params.index === undefined ||
      params.index === null
    ) {
      _postData.funnel_create_message_form_count += 1;
      _postData.funnel_create_message_form_title.push(params.Name);
      _postData.funnel_create_message_form_body.push(params.Message);
      _postData.funnel_create_message_form_delaytime.push(params.Delaycategory);
      _postData.funnel_create_message_form_delaycount.push(params.Delaycount);
      _postData.funnel_create_message_form_type.push(params.Messagetype);
      _postData.funnel_create_message_form_container_status.push(1);
      _postData.edited_at.push(moment().format("MMM  DD, YYYY h:mm a"));
    } else {
      _postData.funnel_create_message_form_title[params.index] = params.Name;
      _postData.funnel_create_message_form_body[params.index] = params.Message;
      _postData.funnel_create_message_form_delaytime[params.index] =
        params.Delaycategory;
      _postData.funnel_create_message_form_delaycount[params.index] =
        params.Delaycount;
      _postData.funnel_create_message_form_type[params.index] =
        params.Messagetype;
      _postData.edited_at[params.index] = moment().format(
        "MMM  DD, YYYY h:mm a",
      );
    }
    setPostData(_postData);
    setChangeStatusModal(undefined);
  };

  const editstatus = (index) => {
    setChangeStatusModal({
      userId: user.Id,
      Name: postData.funnel_create_message_form_title[index],
      Message: postData.funnel_create_message_form_body[index],
      Delaycategory: postData.funnel_create_message_form_delaytime[index],
      DelaycategoryObj: postData.funnel_create_message_form_delaytime[index]
        ? {
            value: postData.funnel_create_message_form_delaytime[index],
            label: postData.funnel_create_message_form_delaytime[index],
          }
        : { value: "", label: "- : -" },
      Delaycount: postData.funnel_create_message_form_delaycount[index],
      Messagetype: postData.funnel_create_message_form_type[index],
      index: index,
    });
  };

  const onCreateCallRequired = (params) => {
    let _postData = { ...postData };
    _postData.funnel_create_message_form_count += 1;
    _postData.funnel_create_message_form_title.push(params.Name);
    _postData.funnel_create_message_form_body.push(params.Message);
    _postData.funnel_create_message_form_delaytime.push(params.Delaycategory);
    _postData.funnel_create_message_form_delaycount.push(params.Delaycount);
    _postData.funnel_create_message_form_type.push(params.Messagetype);
    _postData.funnel_create_message_form_container_status.push(1);
    _postData.edited_at.push(moment().format("MMM  DD, YYYY h:mm a"));
    setPostData(_postData);
    setCallRequiredModal(false);
  };

  return (
    <main id="funnels-page" className="container py-4 position-relative">
      <div className="bg-white p-3">
        <div className="d-flex align-items-center justify-content-between">
          <div className="section-title">
            <Link to={`/funnels`}>
              <BiArrowBack className="icon me-2" />
            </Link>
            New Funnel
          </div>
          <DropdownButton
            variant="btn btn-sm btn-primary ms-auto"
            title="New Action"
          >
            <Dropdown.Item
              eventKey="1"
              onClick={() =>
                setNewMsgModal({
                  userId: user.Id,
                  Name: "",
                  Message: "",
                  Attached: "",
                  Gif: "",
                  Delaycategory: "",
                  DelaycategoryObj: { value: "", label: "- : -" },
                  Delaycount: "",
                  Messagetype: 1,
                })
              }
            >
              <BiMessageRounded />
              New Message
            </Dropdown.Item>
            <Dropdown.Item
              eventKey="2"
              onClick={() =>
                setNewLabelModal({
                  userId: user.Id,
                  Name: "",
                  Message: "",
                  Delaycategory: "",
                  DelaycategoryObj: { value: "", label: "- : -" },
                  Delaycount: "",
                  Messagetype: 3,
                })
              }
            >
              <BsTag />
              New Label
            </Dropdown.Item>
            <Dropdown.Item
              eventKey="3"
              onClick={() =>
                setChangeStatusModal({
                  userId: user.Id,
                  Name: "",
                  Message: "",
                  Delaycategory: "",
                  DelaycategoryObj: { value: "", label: "- : -" },
                  Delaycount: "",
                  Messagetype: 2,
                  index: "",
                })
              }
            >
              <BsCheckLg />
              Change Status
            </Dropdown.Item>
            {/* <Dropdown.Item eventKey="4"
              onClick={() => setCallRequiredModal(true)}
            >
              <BsTelephone />
              Call Required
            </Dropdown.Item> */}
          </DropdownButton>
        </div>
        <div className="card overflow-visible mt-3">
          <div className="card-body">
            <div className="row">
              <div className="col col-sm-6">
                <label className="mb-2">Funnel Name</label>
              </div>
            </div>
            <div className="row">
              <div className="col col-sm-6">
                <input
                  type="text"
                  className="form-control h-100 mb-2"
                  value={postData?.funnel_create_message_form_funnel_name}
                  onChange={(e) =>
                    setPostData({
                      ...postData,
                      funnel_create_message_form_funnel_name: e.target.value,
                    })
                  }
                />
                {showError &&
                  !postData.funnel_create_message_form_funnel_name && (
                    <span className="text-danger">
                      Funnel name is required.
                    </span>
                  )}
              </div>
              <div className="col col-sm-6">
                <DropdownMultiSelect
                  className="w-100"
                  options={client.filter((item) => !!item.Name)}
                  placeholder={"Select Clients"}
                  labelField="Name"
                  valueField="Id"
                  values={selectedClients}
                  onChange={(values) => setSelectedClients(values)}
                  onDropdownClose={(values) => {
                    console.log(values);
                    setPostData({
                      ...postData,
                      funnel_create_message_form_funnel_client: selectedClients
                        .map((item) => item.Id)
                        .toString(),
                    });
                  }}
                />
                {showError &&
                  !postData.funnel_create_message_form_funnel_client && (
                    <span className="text-danger">Please select a client.</span>
                  )}
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="d-flex align-items-center">
                  <label>Exclude Sunday</label>
                  <Switch
                    checked={
                      postData?.funnel_create_message_form_funnel_exclude_sunday ===
                      1
                        ? true
                        : false
                    }
                    onChange={() =>
                      setPostData({
                        ...postData,
                        funnel_create_message_form_funnel_exclude_sunday:
                          postData.funnel_create_message_form_funnel_exclude_sunday ===
                          1
                            ? 0
                            : 1,
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <h5 className="mt-5">Timeline</h5>
            {showError && !postData.funnel_create_message_form_count && (
              <span className="text-danger">Please add a message.</span>
            )}
            <div className="funnel-details px-3">
              {[...Array(postData.funnel_create_message_form_count)]?.map(
                (item, index) => (
                  <div className="campaign-item" key={index}>
                    {index === 0 ? (
                      <p>
                        <span id={`change_delay_${index}`}>
                          Scheduled by user when assigning lead to funnel
                        </span>
                      </p>
                    ) : (
                      <p>
                        <span id={`change_delay_${index}`}>
                          {
                            postData.funnel_create_message_form_delaycount[
                              index
                            ]
                          }{" "}
                          days after lead submitted at{" "}
                          {postData.funnel_create_message_form_delaytime[index]}
                        </span>
                        <a
                          className="text-primary text-decoration-none cursor-pointer ms-1"
                          onClick={() => editdelay(index)}
                        >
                          Change Delay
                        </a>
                      </p>
                    )}
                    <div
                      id={`message_container_${index}`}
                      className="campaign-inner"
                    >
                      <div className="campaign-head d-flex">
                        <div className="user-detail me-auto">
                          <h6 className="campaign-title">
                            {Number(
                              postData.funnel_create_message_form_type[index],
                            ) === 1 ? (
                              <BiMessageRounded />
                            ) : Number(
                                postData.funnel_create_message_form_type[index],
                              ) === 2 ? (
                              <BsCheckLg />
                            ) : Number(
                                postData.funnel_create_message_form_type[index],
                              ) === 3 ? (
                              <BsTag />
                            ) : Number(
                                postData.funnel_create_message_form_type[index],
                              ) === 4 ? (
                              <>
                                <BsTelephone /> Call Required
                              </>
                            ) : (
                              <></>
                            )}
                            {postData.funnel_create_message_form_title[index]}
                          </h6>
                          {Number(
                            postData.funnel_create_message_form_type[index],
                          ) === 1 ? (
                            <div className="message">
                              {postData.funnel_create_message_form_body[index]}
                              {postData.funnel_create_message_form_gifkey[
                                index
                              ] !== "" ? (
                                <img
                                  src={`https://media1.giphy.com/media/
                                  ${postData.funnel_create_message_form_gifkey[index]}
                                  /100_s.gif`}
                                  width="100px"
                                  height="100px"
                                  alt=""
                                />
                              ) : postData.funnel_create_message_form_attached[
                                  index
                                ] ? (
                                <img
                                  src={
                                    postData
                                      .funnel_create_message_form_attached[
                                      index
                                    ]
                                  }
                                  width="100px"
                                  height="100px"
                                  alt=""
                                />
                              ) : (
                                <></>
                              )}
                            </div>
                          ) : Number(
                              postData.funnel_create_message_form_type[index],
                            ) === 2 ? (
                            <div
                              id={`message_status_container_${index}`}
                              className="message_status"
                            >
                              <span
                                className={`label ${helper.getLabelIconClass(
                                  postData.funnel_create_message_form_body[
                                    index
                                  ],
                                )}`}
                              >
                                {helper.getLabelText(
                                  postData.funnel_create_message_form_body[
                                    index
                                  ],
                                )}
                              </span>
                            </div>
                          ) : Number(
                              postData.funnel_create_message_form_type[index],
                            ) === 3 ? (
                            <div
                              className="message_labels"
                              id={`message_label_container_${index}`}
                            >
                              <div
                                id="labels_list1"
                                dangerouslySetInnerHTML={{
                                  __html: getlabelList(
                                    postData.funnel_create_message_form_body[
                                      index
                                    ],
                                  ),
                                }}
                              ></div>
                            </div>
                          ) : (
                            <></>
                          )}
                          <div className="clearfix"></div>
                          <div className="campaign-meta">
                            <div className="meta">
                              <strong>Edited</strong>
                              {` on ${postData.edited_at[index]} by you`}
                            </div>
                          </div>
                        </div>
                        <div className="ms-auto">
                          <div className="btn-group">
                            {Number(
                              postData.funnel_create_message_form_type[index],
                            ) === 1 ? (
                              <a
                                className="btn btn-mini"
                                onClick={() => editmessage(index)}
                              >
                                <BiSolidEdit />
                              </a>
                            ) : Number(
                                postData.funnel_create_message_form_type[index],
                              ) === 2 ? (
                              <a
                                className="btn btn-mini"
                                onClick={() => editstatus(index)}
                              >
                                <BiSolidEdit />
                              </a>
                            ) : Number(
                                postData.funnel_create_message_form_type[index],
                              ) === 3 ? (
                              <a
                                className="btn btn-mini"
                                onClick={() => editlabel(index)}
                              >
                                <BiSolidEdit />
                              </a>
                            ) : null}
                            <a
                              className="btn btn-mini"
                              onClick={() => setDeleteMsgModal(index)}
                            >
                              <BsTrash />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ),
              )}
            </div>
            <div className="row mt-5">
              <div className="col-12 d-flex">
                <button
                  className="btn btn-sm btn-primary d-inline-flex align-items-center ms-auto"
                  onClick={() => onSave()}
                >
                  <IoSaveOutline className="me-1" />
                  Save Funnel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* New Actions */}
      <FunnelNewMessageModal
        initialData={newMsgModal}
        isOpenModal={!!newMsgModal}
        afterOpenModal={() => {}}
        closeModal={() => setNewMsgModal(undefined)}
        onSubmit={(params) => createNewMsg(params)}
        hideDelayInput={postData.funnel_create_message_form_count === 0}
      />
      <FunnelDeleteMessageModal
        msgId={deleteMsgModal}
        isOpenModal={
          deleteMsgModal !== "" &&
          deleteMsgModal !== undefined &&
          deleteMsgModal !== null
        }
        afterOpenModal={() => {}}
        closeModal={() => setDeleteMsgModal("")}
        onDelete={(msgId) => deleteMsg(msgId)}
      />
      <FunnelEditDelayModal
        initialData={editDelayModal}
        isOpenModal={!!editDelayModal}
        afterOpenModal={() => {}}
        closeModal={() => setEditDelayModal(undefined)}
        onSubmit={(params) => saveDelayChange(params)}
      />
      <FunnelNewLabelModal
        initialData={newLabelModal}
        isOpenModal={!!newLabelModal}
        afterOpenModal={() => {}}
        closeModal={() => setNewLabelModal(undefined)}
        onSubmit={(params) => createNewLabel(params)}
      />
      <FunnelChangeStatusModal
        initialData={changeStatusModal}
        isOpenModal={!!changeStatusModal}
        afterOpenModal={() => {}}
        closeModal={() => setChangeStatusModal(false)}
        onSubmit={(params) => saveChangeStatus(params)}
      />
      <FunnelCallRequiredModal
        isOpenModal={!!callRequiredModal}
        afterOpenModal={() => {}}
        closeModal={() => setCallRequiredModal(false)}
        onSubmit={(params) => onCreateCallRequired(params)}
      />
      {/*  */}
    </main>
  );
}
