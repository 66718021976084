import React, { useState, useEffect } from "react";
import TablePagination from "../../TablePagination/TablePagination";
import config from "../../../config/config";
import { ButtonGroup } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

export default function CampaignsActionDataTable({ Campaign = [] }) {
  const [searchKey, setSearchKey] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const perPage = 25;

  const [leads, setLeads] = useState([]);

  useEffect(() => {
    console.log(setLeads, setSearchKey);
  }, []);

  const datatable = (data) => {
    return data.length > 0
      ? data.filter((el) =>
          el.Name?.toLowerCase().includes(searchKey?.toLowerCase()),
        )
      : [];
  };

  return (
    <div className="card overflow-visible">
      <div className="card-body p-0">
        <table className="table table-hover m-0">
          <thead>
            <tr>
              <th>Type</th>
              <th>Job #</th>
              <th>Campaign</th>
              <th>Status</th>
              <th>In-Home Date</th>
              <th>Response</th>
              <th>Rate</th>
              <th>Qty </th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {Campaign &&
              datatable(Campaign).map((item, index) =>
                index >= (currentPage - 1) * perPage &&
                index < currentPage * perPage ? (
                  <tr key={index}>
                    <td>
                      <a
                        className="text-decoration-none"
                        href={`${config.campaignsPath}/dashboard?cid=${item.Keyid}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          width={50}
                          src={
                            item?.LogoPath
                              ? item?.LogoPath
                              : `${config.baseUrl}/assets/images/avatar.png`
                          }
                          alt=""
                        />
                      </a>
                    </td>
                    <td>
                      <a
                        className="text-decoration-none"
                        href={`${config.campaignsPath}/dashboard?cid=${item.Keyid}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {item?.Job}
                      </a>
                    </td>
                    <td>
                      <a
                        className="text-decoration-none"
                        href={`${config.campaignsPath}/dashboard?cid=${item.Keyid}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {item?.Name}
                      </a>
                      <br />
                      <small className="fw-bold">{item?.Type}</small>
                    </td>
                    <td>
                      {Number(item.Status) === 0 ? (
                        <span className="badge rounded-pill bg-primary">
                          Completed
                        </span>
                      ) : Number(item.Status) === 1 ? (
                        <span className="badge rounded-pill bg-warning">
                          Upcoming
                        </span>
                      ) : Number(item.Status) === 2 ? (
                        <span className="badge rounded-pill bg-warning">
                          Active
                        </span>
                      ) : null}
                    </td>
                    <td>{item.HomeDate.type3}</td>
                    <td>{item.Response.Response}</td>
                    <td>{item.Response.ResponseRate}</td>
                    <td>{item.Qty}</td>
                    <td>
                      <DropdownButton
                        as={ButtonGroup}
                        align={{ lg: "end" }}
                        variant="default"
                        title={`Actions`}
                      >
                        <Dropdown.Item
                          eventKey="1"
                          target="_blank"
                          href={item.PiecePath}
                        >
                          Show Mail Piece
                        </Dropdown.Item>
                        <Dropdown.Item
                          eventKey="2"
                          target="_blank"
                          href={`${config.campaignsPath}/dashboard?cid=${item.Keyid}`}
                        >
                          View Dashboard
                        </Dropdown.Item>
                        {Number(item.Qty) > 0 && (
                          <Dropdown.Item
                            eventKey="3"
                            href={`${config.exportPath}/mailinglist/${item.Keyid}`}
                          >
                            Export Mailing List
                          </Dropdown.Item>
                        )}
                        <Dropdown.Item
                          eventKey="4"
                          href={`${config.exportPath}/all/${item.Keyid}`}
                        >
                          Export Leads
                        </Dropdown.Item>
                        <Dropdown.Item
                          eventKey="5"
                          href={`${config.campaign_setting}/edit/${item.Keyid}`}
                        >
                          Edit Campaign
                        </Dropdown.Item>
                      </DropdownButton>
                    </td>
                  </tr>
                ) : null,
              )}
          </tbody>
        </table>
      </div>
      <div className="card-footer py-4 bg-white">
        <TablePagination
          data={datatable(leads)}
          perPage={perPage}
          page={currentPage}
          onChangePage={setCurrentPage}
        />
      </div>
    </div>
  );
}
