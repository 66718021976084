/* eslint-disable no-useless-escape */
function getLabeIcon(labelType) {
  if (!labelType) {
    return "";
  }
  labelType = labelType.toString();
  if (labelType === "1") {
    return `<span class="badge rounded-pill bg-red">Hot</span>`;
  }
  if (labelType === "2") {
    return `<span class="badge rounded-pill bg-blue">Cold</span>`;
  }
  if (labelType === "3") {
    return `<span class="badge rounded-pill bg-green">Sold</span>`;
  }
  if (labelType === "4") {
    return `<span class="badge rounded-pill bg-orange">Warm</span>`;
  }
  if (labelType === "5") {
    return `<span class="badge rounded-pill bg-purple">Follow Up</span>`;
  }
  if (labelType === "6") {
    return `<span class="badge rounded-pill bg-black">Dead</span>`;
  }
  if (labelType === "7") {
    return `<span class="badge rounded-pill bg-gray-1">Appointments</span>`;
  }
  if (labelType === "8") {
    return `<span class="badge rounded-pill bg-gray-2">web leads</span>`;
  }
  if (labelType === "9") {
    return `<span class="badge rounded-pill bg-gray-3">phone calls</span>`;
  }
  if (labelType === "10") {
    return `<span class="badge rounded-pill bg-gray-4">visits</span>`;
  }
}

function getApptsStatus(status) {
  if (!status) {
    return "";
  }
  status = status.toString();
  if (status === "1") {
    return `<span class="badge rounded-pill bg-blue">Pending</span>`;
  } else if (status === "2") {
    return `<span class="badge rounded-pill bg-green">Showed</span>`;
  } else if (status === "3") {
    return `<span class="badge rounded-pill bg-black">No Show</span>`;
  } else if (status === "4") {
    return `<span class="badge rounded-pill bg-orange">Rescheduled</span>`;
  }
}

function getLabelIconClass(labelType) {
  if (!labelType) {
    return "";
  }
  labelType = labelType.toString();
  if (labelType === "1") {
    return "bg-red-hot label-hot";
  }
  if (labelType === "2") {
    return "bg-blue-cool label-cold";
  }
  if (labelType === "3") {
    return "bg-purple-light label-sold";
  }
  if (labelType === "4") {
    return "bg-red-warm label-warm";
  }
  if (labelType === "5") {
    return "bg-purple label-follow-up";
  }
  if (labelType === "6") {
    return "bg-gray-3 label-dead";
  }
  if (labelType === "7") {
    return "bg-purple label-appt";
  }
  if (labelType === "8") {
    return "bg-blue-cool label-lead";
  }
  if (labelType === "9") {
    return "bg-dark label-call";
  }
  if (labelType === "10") {
    return "bg-secondary label-visit";
  }
  if (labelType === "11") {
    return "bg-purple label-sold";
  }
  if (labelType === "12") {
    return "bg-dark label-test";
  }
}

function getLabelText(labelType) {
  if (!labelType) {
    return "";
  }
  labelType = labelType.toString();
  if (labelType === "0") {
    return "Any";
  }
  if (labelType === "1") {
    return "Hot";
  }
  if (labelType === "2") {
    return "Cold";
  }
  if (labelType === "3") {
    return "Sold";
  }
  if (labelType === "4") {
    return "Warm";
  }
  if (labelType === "5") {
    return "Follow Up";
  }
  if (labelType === "6") {
    return "Dead";
  }
  if (labelType === "12") {
    return "Test";
  }
  if (labelType === "11") {
    return "Other";
  }
}

function getLabelIconKeyword(labelType) {
  if (!labelType) {
    return "";
  }
  labelType = labelType.toString();
  if (labelType === "1") {
    return "Hot";
  }
  if (labelType === "2") {
    return "Cold";
  }
  if (labelType === "3") {
    return "Sold";
  }
  if (labelType === "4") {
    return "Warm";
  }
  if (labelType === "5") {
    return "Follow Up";
  }
  if (labelType === "6") {
    return "Dead";
  }
  if (labelType === "7") {
    return "appointments";
  }
  if (labelType === "8") {
    return "web leads";
  }
  if (labelType === "9") {
    return "phone calls";
  }
  if (labelType === "10") {
    return "visits";
  }
  if (labelType === "11") {
    return "Other";
  }
}

function convertTo24HoursFormat(time) {
  if (time) {
    let tts = time.split(" ");
    let pt = tts[0];
    let ap = tts[1];
    let h = pt.split(":")[0];
    let i = pt.split(":")[1];
    if (ap?.toUpperCase() === "PM") {
      if (h < 12) {
        h += 12;
      }
    }

    return `${h}:${i}`;
  }

  return time;
}

function getColorCodeForCalender(id) {
  let _id = Number(id);
  if (_id === 1) {
    return "#74B4F1";
  }
  if (_id === 2) {
    return "#90C48A";
  }
  if (_id === 3) {
    return "#90A4AE";
  }
  if (_id === 4) {
    return "#E4AE66";
  }
}

function formatePin(dl) {
  if (dl?.length === 9) {
    let str = `${dl[0]}${dl[1]}${dl[2]}-${dl[3]}${dl[4]}${dl[5]}-${dl[6]}${dl[7]}${dl[8]}`;
    return str;
  }
  return dl;
}

function validatePhone(phone) {
  let regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
  return regex.test(phone);
}

function validateEmail(email) {
  let regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  return regex.test(email);
}

function loginTypecheck(str) {
  const user = JSON.parse(localStorage.getItem("user"));
  let type = Number(user?.Type);
  let etype = Number(user?.Etype);
  if (str === "ADMIN" && Number(type) === 1 && Number(etype) === 0) {
    return true;
  }
  if (str === "ADMIN" && Number(type) === 5) {
    return true;
  }
  if (str === "EXICUTIVE" && Number(type) === 1 && Number(etype) === 1) {
    return true;
  }
  if (str === "MANAGER" && Number(type) === 2 && Number(etype) === 0) {
    return true;
  }
  if (str === "MANAGER" && Number(type) === 2 && Number(etype) === 3) {
    return true;
  }
  if (str === "MANUAL" && Number(type) === 2 && Number(etype) === 1) {
    return true;
  }
  if (str === "SALASEMAN") {
    return true;
  }
  return false;
}

function getnumberformate(str) {
  if (str.length >= 9) {
    return (
      "(" +
      str[0] +
      str[1] +
      str[2] +
      ") " +
      str[3] +
      str[4] +
      str[5] +
      "-" +
      str[6] +
      str[7] +
      str[8] +
      str[9]
    );
  }
  return str;
}

function nl2br(str, is_xhtml) {
  if (typeof str === "undefined" || str === null) {
    return "";
  }
  let breakTag =
    is_xhtml || typeof is_xhtml === "undefined" ? "<br />" : "<br>";
  return (str + "").replace(
    /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
    "$1" + breakTag + "$2",
  );
}

function filterphonenumber(_number) {
  let number = _number
    .replace("+", "")
    .replace(" ", "")
    .replace("(", "")
    .replace(")", "")
    .replace("-", "");
  if (number.length > 10) {
    let len = number.length;
    let num = "";
    for (let i = len - 10; i < len; i++) {
      num += number[i];
    }
    number = num;
  }

  let obj = {};
  // if(preg_match('/^\d{10}$/',number)) {
  if (number.match("/^d{10}$/")) {
    obj.Look = true;
    obj.Phone = number;
  } else {
    obj.Look = false;
  }
  return obj;
}

function makeUrltoLink(string) {
  // The Regular Expression filter
  let flag = false;
  let reg_pattern =
    "/(((http|https|ftp|ftps)://)|(www.))[a-zA-Z0-9-.]+.[a-zA-Z]{2,3}(:[0-9]+)?(/S*)?/";
  if (string.match(reg_pattern) === true) {
    flag = true;
  }
  let obj = {};
  obj.flag = flag;
  obj.msg = string.replace(reg_pattern, '<a href="$0" target="_blank">$0</a>');
  return obj;
}

function imageFileTypeCheck(files) {
  let res = files.split(".");
  let len = res.length;
  let ext = res[len - 1].toLowerCase();
  if (ext == "jpg" || ext == "png" || ext == "gif" || ext == "bmp") {
    return true;
  } else {
    return false;
  }
}

function phoneformate(number, type = 0) {
  if (!number) {
    return "";
  }
  if (number?.length < 10) {
    return number;
  }
  let n = number.replace("+", "");
  if (n.length == 11) {
    number =
      n[1] + n[2] + n[3] + n[4] + n[5] + n[6] + n[7] + n[8] + n[9] + n[10];
  }
  if (type == 0) {
    return number;
  }
  let fpart = number[0] + number[1] + number[2];
  let spart = number[3] + number[4] + number[5];
  let lpart = number[6] + number[7] + number[8] + number[9];
  if (type == 1) {
    return fpart + " " + spart + " " + lpart;
  }
  if (type == 2) {
    return fpart + "-" + spart + "-" + lpart;
  }
  if (type == 3) {
    return "(" + fpart + ") " + spart + "-" + lpart;
  }
}

function percentage(a, total) {
  if (total == 0 || a == 0) {
    return 0;
  }
  let num = number_format((a * 100) / total);
  if (num == 0) num = number_format((a * 100) / total, 3, ".", ",");
  return num;
}

function number_format(number, decimals, dec_point, thousands_sep) {
  // Strip all characters but numerical ones.
  number = (number + "").replace(/[^0-9+\-Ee.]/g, "");
  let n = !isFinite(+number) ? 0 : +number,
    prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
    sep = typeof thousands_sep === "undefined" ? "," : thousands_sep,
    dec = typeof dec_point === "undefined" ? "." : dec_point,
    s = "",
    toFixedFix = function (n, prec) {
      let k = Math.pow(10, prec);
      return "" + Math.round(n * k) / k;
    };
  // Fix for IE parseFloat(0.55).toFixed(0) = 0;
  s = (prec ? toFixedFix(n, prec) : "" + Math.round(n)).split(".");
  if (s[0].length > 3) {
    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
  }
  if ((s[1] || "").length < prec) {
    s[1] = s[1] || "";
    s[1] += new Array(prec - s[1].length + 1).join("0");
  }
  return s.join(dec);
}

function secondsToHours(ss) {
  let hours = Math.floor(ss / 3600); // Calculate the number of whole hours
  let minutes = Math.floor((ss % 3600) / 60); // Calculate the number of minutes
  let seconds = Math.floor(ss % 60); // Calculate the remaining seconds
  let formattedTime = `${hours > 9 ? hours : "0" + hours}:${
    minutes > 9 ? minutes : "0" + minutes
  }:${seconds > 9 ? seconds : "0" + seconds}`; // Format the time as HH:MM:SS

  return formattedTime;
}

export default {
  getLabeIcon,
  getApptsStatus,
  convertTo24HoursFormat,
  getColorCodeForCalender,
  getLabelIconClass,
  getLabelText,
  getLabelIconKeyword,
  formatePin,
  validatePhone,
  validateEmail,
  loginTypecheck,
  getnumberformate,
  nl2br,
  filterphonenumber,
  makeUrltoLink,
  imageFileTypeCheck,
  phoneformate,
  percentage,
  number_format,
  secondsToHours,
};
