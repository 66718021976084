import React, { useState } from "react";
import Modal from "react-modal";
import { FileUploader } from "react-drag-drop-files";
import { BsDownload } from "react-icons/bs";
import { useContext } from "react";
import { AuthContext, VideoContext } from "../../context";
import axios from "axios";
import AWS from "aws-sdk";
import { Buffer } from "buffer";
import { useEffect } from "react";
import { createFFmpeg, fetchFile } from "@ffmpeg/ffmpeg";
import config from "../../config/config";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "560px",
    padding: "15px",
    marginTop: "20px",
    marginBottom: "20px",
  },
};

Modal.setAppElement("#root");

const fileTypes = ["MP4", "AVI"];

const DragDropButton = ({ label = "" }) => {
  return (
    <div className="drag-wrapper">
      <BsDownload className="icon" />
      <p className="mt-4">
        {label ? (
          label
        ) : (
          <>
            <strong>Choose a file</strong> or drag it here.
          </>
        )}
      </p>
    </div>
  );
};

const ffmpeg = createFFmpeg({ log: true });

export default function VideoUploadModal({
  dealerships = [],
  isOpenModal = false,
  afterOpenModal,
  closeModal,
  reloadData,
}) {
  const { user } = useContext(AuthContext);
  const { saveVideo } = useContext(VideoContext);
  const [file, setFile] = useState(null);
  const [videoPreview, setVideoPreview] = useState("");
  const [gifImage, setGifImage] = useState(null);
  const [pngImage, setPngImage] = useState(null);
  const [postData, setPostData] = useState({
    userId: user.Id,
    Dealership: "",
    Title: "",
    Category: "",
    VideoType: "",
    Video_url: "",
    Thumnail_url: "",
    Gif_url: "",
    Duration: "",
    Create_date: "",
  });
  const [progressPercentage, setProgressPercentage] = useState(0);
  const [progressLabel, setProgressLabel] = useState("");

  useEffect(() => {
    loadFFmpeg();
  }, []);

  const loadFFmpeg = async () => {
    await ffmpeg.load();
  };

  const handleChange = (file) => {
    setFile(file);
    console.log("== file ==", file);
    const reader = new FileReader();
    // const selectedFile = e.target.files[0];
    if (file) {
      reader.readAsDataURL(file);
    }
    // As the File loaded then set the stage as per the file type
    reader.onload = (readerEvent) => {
      //
      const media = new Audio(reader.result);
      let duration = 0;
      media.onloadedmetadata = function () {
        duration = media.duration;
        setPostData({ ...postData, Duration: media.duration });
      };
      //
      if (file.type.includes("image")) {
        // setImagePreview(readerEvent.target.result);
      } else if (file.type.includes("video")) {
        setVideoPreview(readerEvent.target.result);
        //
        const sampleVideoData = new Uint8Array(readerEvent.target.result);
        convertVideo2Gif(sampleVideoData, duration, file);
      }
    };
  };

  const convertVideo2Gif = async (sourceBuffer, duration, _file) => {
    ffmpeg.FS(
      "writeFile",
      "input.mp4",
      await fetchFile(_file),
      // new Uint8Array(sourceBuffer, 0, sourceBuffer.byteLength)
    );

    ffmpeg.setProgress(({ ratio }) => {
      if (ratio !== 0) {
        // button.innerText = 'Converting...';
      }
    });

    let gifLength = "20";

    if (duration < 1) {
      gifLength = "3";
    } else {
      if (duration > 20) {
        gifLength = "20";
      } else {
        gifLength = Math.floor(duration).toString();
      }
    }

    await ffmpeg.run(
      "-i",
      "input.mp4",
      "-t",
      gifLength,
      "-ss",
      "0",
      "-f",
      "gif",
      "output.gif",
    );
    //Retrieve the result:
    const gifOutput = ffmpeg.FS("readFile", "output.gif");
    const data = await base64Arraybuffer(gifOutput);
    console.log("=== out put ===", data);
    // $("#renderImage").attr("src", `data:image/gif;base64, ${data}`);
    // $("#renderImage").show();
    // gifImage = data;
    setGifImage(data);

    await ffmpeg.run(
      "-i",
      "input.mp4",
      "-ss",
      "4",
      "-vf",
      "scale=640:-1",
      "-vframes",
      "1",
      "output.jpg",
    );
    //Retrieve the result:
    const imgOutput = ffmpeg.FS("readFile", "output.jpg");
    const imgData = await base64Arraybuffer(imgOutput);
    // pngImage = imgData;
    setPngImage(imgData);
    // canProcess = true;
    // $('#upload-form').hideLoading();
  };

  const base64Arraybuffer = async (data) => {
    // Use a FileReader to generate a base64 data URI
    const base64url = await new Promise((r) => {
      const reader = new FileReader();
      reader.onload = () => r(reader.result);
      reader.readAsDataURL(new Blob([data]));
    });

    /*
    The result looks like 
    "data:application/octet-stream;base64,<your base64 data>", 
    so we split off the beginning:
    */
    return base64url.split(",", 2)[1];
  };

  const onSave = async () => {
    // set aws s3 bucket
    const s3BaseName = "https://dealerfunnel-videos.s3.amazonaws.com/";
    const bucket = new AWS.S3(config.awsConfig);

    // let ffmpeg = null;
    // let videoFile = null;
    // let gifImage = gifImage;
    // let pngImage = pngImage;
    // let canProcess = false;
    // let duration = 0;
    // let waitLoadingTime = 3000;

    // == upload video
    let videoUrl = await new Promise((r) => {
      setProgressLabel(`Video Uploading...`);
      uploadFileToS3(file, bucket).on(
        "httpUploadProgress",
        function (progress) {
          setProgressPercentage(
            Math.round((progress.loaded / progress.total) * 100),
          );
          console.log(
            "=== process ===",
            Math.round((progress.loaded / progress.total) * 100),
          );
          if (progress.loaded >= 1) {
            r(`${s3BaseName}${progress.key}`);
          }
        },
      );
    });
    if (videoUrl === false) {
      alert("Connection error with video uploading. Try again later.");
      setProgressLabel("");
      return;
    }
    let bibtyLink = await getBitlyLink(videoUrl);
    if (bibtyLink) {
      videoUrl = bibtyLink;
      setPostData({ ...postData, Video_url: videoUrl });
    }
    console.log("==== upload tes ===", videoUrl);
    // == upload gif
    let gifUrl = await new Promise((r) => {
      setProgressLabel(`Gif File Uploading...`);
      uploadFileToS3(gifImage, bucket, false).on(
        "httpUploadProgress",
        function (progress) {
          setProgressPercentage(
            Math.round((progress.loaded / progress.total) * 100),
          );
          if (progress.loaded >= 1) {
            r(`${s3BaseName}${progress.key}`);
          }
        },
      );
    });
    if (gifUrl == false) {
      alert("Connection error with gif uploading. Try again later.");
      setProgressLabel("");
      return;
    }
    bibtyLink = await getBitlyLink(gifUrl);
    if (bibtyLink) {
      gifUrl = bibtyLink;
      setPostData({ ...postData, Gif_url: gifUrl });
    }

    // == upload png
    let pngUrl = await new Promise((r) => {
      setProgressLabel(`Image Uploading...`);
      uploadFileToS3(pngImage, bucket, false, false).on(
        "httpUploadProgress",
        function (progress) {
          setProgressPercentage(
            Math.round((progress.loaded / progress.total) * 100),
          );
          if (progress.loaded >= 1) {
            r(`${s3BaseName}${progress.key}`);
          }
        },
      );
    });
    if (pngUrl == false) {
      alert("Connection error with image uploading. Try again later.");
      setProgressLabel("");
      return;
    }
    bibtyLink = await getBitlyLink(pngUrl);
    if (bibtyLink) {
      pngUrl = bibtyLink;
      setPostData({ ...postData, Thumnail_url: pngUrl });
    }

    const now = new Date();
    const offsetMs = now.getTimezoneOffset() * 60 * 1000;
    const dateLocal = new Date(now.getTime() - offsetMs);
    const createDateStr = dateLocal
      .toISOString()
      .slice(0, 19)
      .replace(/-/g, "/")
      .replace("T", " ");
    setPostData({ ...postData, Create_date: createDateStr });

    // update db
    let _postData = {
      userId: user.Id,
      UserId: user.Id,
      Userid: user.Id,
      Dealership: postData.Dealership,
      Title: postData.Title,
      Category: postData.Category,
      VideoType: postData.VideoType,
      Video_url: videoUrl,
      Thumnail_url: pngUrl,
      Gif_url: gifUrl,
      Duration: postData.Duration,
      Create_date: createDateStr,
    };
    console.log(">>> _postData >>>", _postData);
    if (
      _postData.Title &&
      _postData.Video_url &&
      _postData.Gif_url &&
      _postData.Thumnail_url
    ) {
      const res = await saveVideo(postData);
      if (res.status === "ok") {
        reloadData();
      }
    }
    return;
  };

  const uploadFileToS3 = (file, bucket, isFile = true, isGif = true) => {
    let type = "";
    let name = "";
    let body = "";
    if (isFile) {
      type = file.type;
      name = "web/" + Date.now().toString() + ".mp4";
      body = file;
    } else {
      const ext = isGif ? ".gif" : ".png";
      type = isGif ? "image/gif" : "image/png";
      name = "web/" + Date.now().toString() + ext;
      body = Buffer(file, "base64");
    }
    const params = {
      Bucket: "dealerfunnel-videos",
      Key: name,
      Body: body,
      ContentType: type,
      ACL: "public-read",
    };
    return bucket.upload(params, function (err, data) {
      if (err) {
        // $('.progress-container').hide();
        // $('.progress-bar').css('width', '0%');
        // $('.progress-text').text('');
        return false;
      } else {
        // $('.progress-container').hide();
        // $('.progress-bar').css('width', '0%');
        // $('.progress-text').text('');
        // $('#video_file').val('');
        // $('#video_url').val(data.Location);
        return data.Location;
      }
    });
  };

  const getBitlyLink = async (url) => {
    const accessToken = "15e93ecd3d04ede9a2db50e06b3b8f9c3c99cf83";
    // var params = {
    //   "long_url" : url
    // };
    // const _result = await axios({
    //   url: "https://api-ssl.bitly.com/v4/shorten",
    //   cache: false,
    //   dataType: "json",
    //   method: "POST",
    //   contentType: "application/json",
    //   beforeSend: function (xhr) {
    //     xhr.setRequestHeader("Authorization", "Bearer " + accessToken);
    //   },
    //   data: JSON.stringify(params)
    // });

    const result = await axios.post(
      "https://api-ssl.bitly.com/v4/shorten",
      {
        long_url: url,
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      },
    );
    return result?.link;
  };

  return (
    <Modal
      isOpen={isOpenModal}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <div className="modal-header">
        <h4>Upload Video</h4>
      </div>
      <div className="modal-body">
        <form>
          <div className="row">
            <div className="col-12">
              <div className="form-group mt-3">
                <label className="mb-1">Title</label>
                <input
                  type="text"
                  className="form-control"
                  value={postData.Title}
                  onChange={(e) =>
                    setPostData({ ...postData, Title: e.target.value })
                  }
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6">
              <div className="form-group mt-3">
                <label className="mb-1">Category</label>
                <select
                  className="form-select form-control-fav"
                  value={postData.Category}
                  onChange={(e) =>
                    setPostData({ ...postData, Category: e.target.value })
                  }
                >
                  <option value="0">All Categories</option>
                  <option value="1">Personal Videos</option>
                  <option value="2">Sales</option>
                  <option value="3">Service</option>
                  <option value="4">Marketing</option>
                  <option value="5">Finance</option>
                </select>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6">
              <div className="form-group mt-3">
                <label className="mb-1">Video Type</label>
                <select
                  className="form-select form-control-fav"
                  value={postData.VideoType}
                  onChange={(e) =>
                    setPostData({ ...postData, VideoType: e.target.value })
                  }
                >
                  <option value="1">My Videos</option>
                  <option value="2">Shared Videos</option>
                </select>
              </div>
              {Number(postData.VideoType) === 2 ? (
                <div className="form-group mt-3">
                  <label className="mb-1">Dealership</label>
                  <select
                    className="form-select form-control-fav"
                    value={postData.Dealership}
                    onChange={(e) =>
                      setPostData({ ...postData, Dealership: e.target.value })
                    }
                  >
                    {dealerships &&
                      dealerships.map((item, index) => (
                        <option value={item.Id} key={index}>
                          {item.Name}
                        </option>
                      ))}
                  </select>
                </div>
              ) : null}
            </div>
          </div>
          <div className="row mt-3">
            {!!videoPreview && (
              <div className="col-6">
                <video src={videoPreview} controls className="w-100">
                  <source
                    src={videoPreview}
                    // type={previewUrl.type}
                  />
                </video>
              </div>
            )}
            {!!gifImage && (
              <div className="col-6">
                <img
                  src={`data:image/gif;base64, ${gifImage}`}
                  className="w-100"
                  alt=""
                />
              </div>
            )}
          </div>
          <FileUploader
            handleChange={handleChange}
            name="file"
            types={fileTypes}
            classes="w-100 mt-3"
            // eslint-disable-next-line react/no-children-prop
            children={<DragDropButton label={file ? file?.name : ""} />}
          />
          <div className="upload-progress-label">{progressLabel}</div>
          {progressLabel && (
            <div className="upload-progress-bar">
              <div
                className="upload-progress-status"
                style={{ width: `${progressPercentage}%` }}
              ></div>
            </div>
          )}
        </form>
      </div>
      <div className="modal-footer mt-3">
        <button className="btn btn-primary me-2" onClick={() => onSave()}>
          Save
        </button>
        <button className="btn btn-outline-secondary" onClick={closeModal}>
          Cancel
        </button>
      </div>
    </Modal>
  );
}
