import React, { useContext, useEffect, useState } from "react";
import { Box, Stack } from "@mui/material";
import { AuthContext, SettingContext } from "../../../context";
import { UserCategory } from "../../../components";

export default function UserSavedReplies() {
  const { getSavedReplies } = useContext(SettingContext);
  const { user } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState(null);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    console.log(info);
    if (user && user.Id) {
      getInfo();
    }
  }, [user]);

  const getInfo = async () => {
    setLoading(true);
    const result = await getSavedReplies();
    setInfo(result);
    setCategories(result.category);
    setLoading(false);
  };

  const skeleton = () => {
    return (
      <Stack
        direction={{
          xs: "column",
          md: "row",
        }}
        alignItems={"start"}
        justifyContent={"start"}
        sx={{
          width: "100%",
        }}
      >
        {[...Array(4)].map((category, index) => {
          return (
            <Box key={index} sx={{ p: 1 }}>
              <UserCategory category={category} isSkeleton={true} />
            </Box>
          );
        })}
      </Stack>
    );
  };

  if (loading) {
    return skeleton();
  }

  return (
    <Box>
      <Stack
        direction={{
          xs: "column",
          md: "row",
        }}
        alignItems={"start"}
        justifyContent={"start"}
        sx={{
          overflowX: "scroll",
          maxWidth: 1200,
          width: "100%",
        }}
      >
        {categories.map((category, index) => {
          return (
            <Box key={index} sx={{ p: 1 }}>
              <UserCategory category={category} />
            </Box>
          );
        })}
      </Stack>
    </Box>
  );
}
