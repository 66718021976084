import React, { createContext, useContext, useState } from "react";
import { AxiosContext } from "./axios_context";
import config from "../config/config";

const DashboardContext = createContext(undefined);

const DashboardContextProvider = (props) => {
  const { axiosInstance, setLoading } = useContext(AxiosContext);
  const [agentResponse, setAgentResponse] = useState(null);
  const [dailyAgentResponse, setDailyAgentResponse] = useState([]);
  const [leadSource, setLeadSource] = useState([]);
  const [leadSourceTotal, setLeadSourceTotal] = useState(0);
  const [leadSourceGroup, setLeadSourceGroup] = useState([]);
  const [agentPerformance, setAgentPerformance] = useState(null);
  const [thisMonth, setThisMonth] = useState("");
  const [monthLeaderShip, setMonthLeaderShip] = useState([]);
  const [leadershipGroup, setLeadershipGroup] = useState([]);
  const [agentMessages, setAgentMessages] = useState(null);
  const [agentFunnel, setAgentFunnel] = useState(null);
  const [campaignDetails, setCampaignDetails] = useState([]);

  const getAgentResponse = ({
    userId,
    days,
    startDate,
    endDate,
    dealershipIds = "",
  }) => {
    const data = {
      userId: userId,
      days: days,
      dealershipIds: dealershipIds,
    };

    if (days === "custom") {
      data.startDate = startDate;
      data.endDate = endDate;
    }
    // setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/dashboard/agent_response`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        // console.log('= agent response = ', data);
        const res = data.response;
        if (data.status === "ok") {
          if (res?.response) {
            setAgentResponse(res.response);
            setDailyAgentResponse(res.dailyreports);
          }
        }
        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
        return error;
      });
  };

  const getLeadSource = ({
    userId,
    dealershipIds = "",
    days,
    startDate,
    endDate,
  }) => {
    const data = {
      userId: userId,
      dealershipIds: dealershipIds,
      days: days,
    };
    if (days === "custom") {
      data.startDate = startDate;
      data.endDate = endDate;
    }
    // setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/dashboard/getleadsourcelist`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        // console.log('= getleadsourcelist = ', data);
        const res = data.response;
        if (data.status === "ok") {
          if (res?.topleadsource) {
            setLeadSource(res?.topleadsource);
            const _leadsGroup = generateArrayGroupByUnit(res?.topleadsource, 6);
            setLeadSourceGroup(_leadsGroup);
          }
          if (res?.totalleadsource) {
            setLeadSourceTotal(res?.totalleadsource);
          }
        }
        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
        return error;
      });
  };
  // private func
  function generateArrayGroupByUnit(list, unit) {
    var _arrayGroup = [];
    var unitGroup = [];
    for (var i = 0; i < list.length; i++) {
      unitGroup.push(list[i]);
      if (unitGroup.length === unit) {
        _arrayGroup.push(unitGroup);
        unitGroup = [];
      }
    }
    return _arrayGroup;
  }

  const getAgentPerformance = ({
    userId,
    dealershipIds = "",
    days,
    startDate,
    endDate,
  }) => {
    const data = {
      userId: userId,
      dealershipIds: dealershipIds,
      days: days,
    };
    if (days === "custom") {
      data.startDate = startDate;
      data.endDate = endDate;
    }
    // setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/dashboard/agentperformance`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("= agentperformance = ", data);
        const res = data.response;
        if (data.status === "ok") {
          setAgentPerformance(res);
        }
        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
        return error;
      });
  };

  const getMonthLeaderShip = ({ userId, dealershipIds = "" }) => {
    const data = {
      userId: userId,
      dealershipIds: dealershipIds,
    };
    // setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/dashboard/monthleadership`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("= monthleadership = ", data);
        const res = data.response;
        if (data.status === "ok") {
          if (res?.thismonth) {
            setThisMonth(res.thismonth);
          }
          if (res?.leadership) {
            setMonthLeaderShip(res.leadership);
            const _leadershipGroup = generateArrayGroupByUnit(
              res.leadership,
              6,
            );
            setLeadershipGroup(_leadershipGroup);
          }
        }
        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
        return error;
      });
  };

  const getAgentMessageDetails = ({
    userId,
    dealershipIds = "",
    flag,
    daterange,
  }) => {
    const data = {
      userId: userId,
      dealershipIds: dealershipIds,
      flag: flag,
      daterange: daterange,
    };
    // setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/dashboard/agentmessagedetails`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("= agentmessagedetails = ", data);
        const res = data.response;
        if (data.status === "ok") {
          setAgentMessages(res);
        }
        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
        return error;
      });
  };

  const getAgentFunnel = ({ userId, dealershipIds = "" }) => {
    const data = {
      userId: userId,
      dealershipIds: dealershipIds,
    };
    // setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/dashboard/agentfunnel`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("= agentfunnel = ", data);
        const res = data.response;
        if (data.status === "ok") {
          setAgentFunnel(res);
        }
        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
        return error;
      });
  };

  const getCampaignDetails = ({
    userId,
    dealershipIds = "",
    days,
    startDate,
    endDate,
  }) => {
    const data = {
      userId: userId,
      dealershipIds: dealershipIds,
      days: days,
    };
    if (days === "custom") {
      data.startDate = startDate;
      data.endDate = endDate;
    }
    // setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/dashboard/campaigndetails`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("= campaigndetails = ", data);
        const res = data.response;
        if (data.status === "ok") {
          setCampaignDetails(Object.values(res));
        }
        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
        return error;
      });
  };

  const providerValue = {
    getAgentResponse,
    agentResponse,
    dailyAgentResponse,
    getLeadSource,
    leadSource,
    leadSourceTotal,
    leadSourceGroup,
    getAgentPerformance,
    agentPerformance,
    getMonthLeaderShip,
    thisMonth,
    monthLeaderShip,
    leadershipGroup,
    getAgentMessageDetails,
    agentMessages,
    getAgentFunnel,
    agentFunnel,
    getCampaignDetails,
    campaignDetails,
  };

  return (
    <DashboardContext.Provider value={providerValue}>
      {props.children}
    </DashboardContext.Provider>
  );
};

export { DashboardContext, DashboardContextProvider };
