import React, { useState, useEffect } from "react";
import TablePagination from "../../TablePagination/TablePagination";
import { ButtonGroup } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import config from "../../../config/config";
import moment from "moment";
import UserRemoveModal from "../../modals/user_remove_modal";

export default function UsersDataTable(props) {
  const {
    data = [],
    userTypeList = [],
    isExecutive = false,
    reloadData,
    loading,
  } = props;
  const [searchKey, setSearchKey] = useState("");
  const [filterUtype, setFilterUtype] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const perPage = 25;

  const [removeUserModal, setRemoveUserModal] = useState("");

  useEffect(() => {
    setCurrentPage(1);
    console.log(loading);
  }, [data]);

  const datatable = (data) => {
    return data.length > 0
      ? data.filter(
          (el) =>
            el.Name?.toLowerCase().includes(searchKey?.toLowerCase()) &&
            (filterUtype === 0 || Number(el.Type) === filterUtype),
        )
      : [];
  };

  const onSelectFilterUtype = (item) => {
    if (item === "Admin") {
      setFilterUtype(1);
    } else if (item === "Manager") {
      setFilterUtype(2);
    } else if (item === "Agency Admin") {
      setFilterUtype(5);
    } else if (item === "Agent") {
      setFilterUtype(7);
    } else if (item === "DF Manager") {
      setFilterUtype(8);
    } else if (item === "Salesman") {
      setFilterUtype(3);
    } else {
      setFilterUtype(0);
    }
  };

  const removeUser = (uId) => {
    setRemoveUserModal(uId);
  };

  return (
    <div className="card overflow-visible">
      <div className="card-header">
        <div className="d-flex">
          <input
            type="text"
            className="form-control form-control-fav"
            placeholder="Search"
            onChange={(e) => {
              setSearchKey(e.target.value);
              setCurrentPage(1);
            }}
          />
          <div className="ms-auto">
            <DropdownButton
              as={ButtonGroup}
              align={{ lg: "end" }}
              variant="default"
              title={
                filterUtype === 0
                  ? "All Users"
                  : filterUtype === 1
                  ? "Admin"
                  : filterUtype === 2
                  ? "Manager"
                  : filterUtype === 3
                  ? "Salesman"
                  : filterUtype === 5
                  ? "Agency Admin"
                  : filterUtype === 7
                  ? "Agent"
                  : filterUtype === 8
                  ? "DF Manager"
                  : null
              }
            >
              <Dropdown.Item eventKey="0" onClick={() => setFilterUtype(0)}>
                All Users
              </Dropdown.Item>
              {userTypeList.map((item, index) => (
                <Dropdown.Item
                  eventKey={index + 1}
                  key={index}
                  onClick={() => onSelectFilterUtype(item)}
                >
                  {item}
                </Dropdown.Item>
              ))}
            </DropdownButton>
          </div>
        </div>
      </div>
      <div className="card-body p-0">
        <table className="table table-hover m-0">
          <thead>
            <tr>
              <th></th>
              <th>Name</th>
              <th>Email</th>
              <th>Type</th>
              <th>Mobile Phone</th>
              <th>Last Seen</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {data &&
              datatable(data).map((item, index) =>
                index >= (currentPage - 1) * perPage &&
                index < currentPage * perPage ? (
                  <tr key={index}>
                    <td>
                      <img
                        width={40}
                        src={
                          item?.Image !== "avatar.png"
                            ? `${config.baseUrl}/resource/profilepicture/${item.Image}`
                            : `${config.domain}/assets/images/
                              material-letter-icons/png/${item?.Name.toUpperCase().charAt(
                                0,
                              )}.png`
                        }
                        alt=""
                      />
                    </td>
                    <td>
                      <a className="text-decoration-none" href={`/`}>
                        {item?.Name}
                      </a>
                    </td>
                    <td>{item?.Email}</td>
                    <td>
                      {Number(item?.Type) === 1 && Number(item?.Etype) === 1 ? (
                        <span className="badge rounded-pill label accountExecutive-label">
                          Account Executive
                        </span>
                      ) : Number(item?.Type) === 1 ? (
                        <span className="badge rounded-pill label admin-label">
                          Admin
                        </span>
                      ) : Number(item?.Type) === 2 &&
                        Number(item?.Etype) === 1 ? (
                        <span className="badge rounded-pill label matchManager-label">
                          Match Manager
                        </span>
                      ) : Number(item?.Type) === 2 ? (
                        <span className="badge rounded-pill label manager-label">
                          Manager
                        </span>
                      ) : Number(item?.Type) === 8 ? (
                        <span className="badge rounded-pill label manager-label">
                          DF Manager
                        </span>
                      ) : Number(item?.Type) === 3 ? (
                        <span className="badge rounded-pill label salesman-label">
                          Salesman
                        </span>
                      ) : Number(item?.Type) === 5 ? (
                        <span className="badge rounded-pill label agencyAdmin-label">
                          Agency Admin
                        </span>
                      ) : Number(item?.Type) === 7 ? (
                        <span className="badge rounded-pill label agent-label">
                          Agent
                        </span>
                      ) : null}
                    </td>
                    <td>{item?.Mobile}</td>
                    <td>
                      {item?.LastseenFlag === 1 ? (
                        <span className="active">Active</span>
                      ) : item?.LastseenFlag === 0 ? (
                        "N/A"
                      ) : (
                        <abbr className="timeago" title={item?.Lastseen}>
                          {moment(item?.Lastseen).fromNow()}
                        </abbr>
                      )}
                    </td>
                    <td>
                      <DropdownButton
                        as={ButtonGroup}
                        align={{ lg: "end" }}
                        variant="default"
                        title={`Actions`}
                      >
                        <Dropdown.Item
                          eventKey="1"
                          href={`/${"users/edituser"}/${item.Key}`}
                        >
                          Edit User
                        </Dropdown.Item>
                        {!isExecutive && (
                          <Dropdown.Item
                            eventKey="2"
                            onClick={() => removeUser(item.Id)}
                          >
                            Delete User
                          </Dropdown.Item>
                        )}
                        {Number(item?.Type) > 1 && (
                          <Dropdown.Item
                            eventKey="3"
                            href={`${config.usersPath}/userview/${item.Id}`}
                          >
                            User View
                          </Dropdown.Item>
                        )}
                      </DropdownButton>
                    </td>
                  </tr>
                ) : null,
              )}
          </tbody>
        </table>
      </div>
      <div className="card-footer py-4 bg-white">
        <TablePagination
          data={datatable(data)}
          perPage={perPage}
          page={currentPage}
          onChangePage={setCurrentPage}
        />
      </div>
      {/*  */}
      <UserRemoveModal
        cmpid={removeUserModal}
        isOpenModal={!!removeUserModal}
        afterOpenModal={() => {}}
        closeModal={() => setRemoveUserModal("")}
        reloadData={() => {
          setRemoveUserModal("");
          reloadData();
        }}
      />
    </div>
  );
}
