import React, { useContext, useState } from "react";
import Modal from "react-modal";
import { AuthContext, FunnelContext } from "../../context";
import { useEffect } from "react";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "560px",
    padding: "15px",
    marginTop: "20px",
    marginBottom: "20px",
    overflow: "visible",
  },
};

Modal.setAppElement("#root");

export default function FunnelReassignModal({
  data = null,
  isOpenModal = false,
  afterOpenModal,
  closeModal,
  onReassignConfirm,
}) {
  const { user } = useContext(AuthContext);
  const { reassignFunnelConfirmModalApi } = useContext(FunnelContext);
  //
  const [funnel, setFunnel] = useState([]);
  const [postData, setPostData] = useState({
    userId: user.Id,
    lead: "",
    flag: "",
    oldfunnelid: "",
    newfunnelid: "",
  });
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (data) {
      setFunnel(data?.funnel);
      let _postData = { ...postData };
      _postData.userId = user.Id;
      _postData.lead = data?.lead;
      _postData.flag = data?.flag;
      _postData.oldfunnelid = data?.assign;
      _postData.newfunnelid = data?.assign;
      setPostData(_postData);
    }
  }, [data]);

  const reassign = async () => {
    if (!postData.newfunnelid) {
      setShowError(true);
      return;
    }
    const data = await reassignFunnelConfirmModalApi(postData);
    if (data.status === "ok") {
      const res = data.response;
      onReassignConfirm(res);
    }
  };

  return (
    <Modal
      isOpen={isOpenModal}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Reassign Modal"
    >
      <div className="modal-header">
        <h4 className="d-flex align-items-center">Re-assign</h4>
      </div>
      <div className="modal-body">
        <div className="row mt-4">
          <div className="col col-sm-12">
            <div className="form-group mb-2">
              <select
                id="reassign_funnel_list_id"
                className="form-control"
                value={postData.newfunnelid}
                onChange={(e) =>
                  setPostData({ ...postData, newfunnelid: e.target.value })
                }
              >
                <option value="">Select Funnel</option>
                {funnel.map((item, index) => (
                  <option value={item.Id} key={index}>
                    {item.Name}
                  </option>
                ))}
              </select>
              {showError && !postData.newfunnelid && (
                <span className="text-danger">Please select a funnel!</span>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer mt-3">
        <button className="btn btn-outline-secondary me-2" onClick={closeModal}>
          Cancel
        </button>
        <button className="btn btn-primary" onClick={() => reassign()}>
          Reassign
        </button>
      </div>
    </Modal>
  );
}
