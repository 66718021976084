import React, { useState } from "react";
import { Pagination, Stack, Typography } from "@mui/material";

export default function TablePagination({
  data,
  perPage = 25,
  page = 1,
  onChangePage,
}) {
  const totalPage = Math.ceil(data.length / perPage);
  const [currentPage, setCurrentPage] = useState(page);
  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      justifyContent={"space-between"}
      spacing={1}
    >
      <Typography variant="body2">
        Page {page} of {totalPage} | {data.length} Campaigns
      </Typography>
      <Pagination
        count={totalPage}
        color="primary"
        shape="rounded"
        size="large"
        showFirstButton
        showLastButton
        // boundaryCount={2}
        page={currentPage}
        onChange={(e, v) => {
          setCurrentPage(v);
          onChangePage(v);
        }}
      />
    </Stack>
  );
}
