import moment from "moment";
import helper from "../../utils/helper";
import { useContext } from "react";
import { AuthContext, ConversationContext } from "../../context";
import React from "react";

export default function ScheduleMessage({ item, reloadData }) {
  const { user } = useContext(AuthContext);
  const { sendScheduleNowApi, scheduledeleteApi, scheduleCsv, setScheduleCsv } =
    useContext(ConversationContext);

  const schedule_message_send_now = async (itemLeadId, itemId) => {
    let params = {
      userId: user.Id,
      lead: itemLeadId,
      smsId: itemId,
      schedulecsv: scheduleCsv,
    };
    const data = await sendScheduleNowApi(params);
    if (data.status === "ok") {
      const res = data.response;
      setScheduleCsv(res?.sch_csv);
      reloadData();
    }
  };

  const schedule_message_delete = async (itemLeadId, itemId) => {
    let params = {
      userId: user.Id,
      lead: itemLeadId,
      smsId: itemId,
      schedulecsv: scheduleCsv,
    };
    const data = await scheduledeleteApi(params);
    if (data.status === "ok") {
      const res = data.response;
      setScheduleCsv(res?.sch_csv);
      reloadData();
    }
  };

  return (
    <div id="schedule-message" className="message">
      <div className="message-ct">
        <div className="sender-text">
          <div className="schedule-message">
            <strong>
              <span>{item.schedule_name}</span> <i className="icon-clock"></i>
              Scheduled for{" "}
              {moment(new Date(item.kschedule * 1000)).format("MMMM")}{" "}
              {moment(new Date(item.kschedule * 1000)).format("Do")}{" "}
              {moment(new Date(item.kschedule * 1000)).format("h:mm A")}
            </strong>
            <p
              dangerouslySetInnerHTML={{ __html: helper.nl2br(item.Body) }}
            ></p>
            {item.giphy ? (
              <div className="sender-img">
                <img
                  src={`https://i.giphy.com/media/${item.giphy}/giphy.gif`}
                />
              </div>
            ) : null}
            {item.Medias
              ? item.Medias.split(",").map((md, mi) => (
                  <div className="sender-img" key={mi}>
                    <a data-fancybox="gallery" href={md}>
                      <img width="100" height="100" src={md} className="mt-2" />
                    </a>
                  </div>
                ))
              : item.media
              ? item.media.split(",").map((md, mi) => (
                  <div className="sender-img" key={mi}>
                    <a data-fancybox="gallery" href={md}>
                      <img width="100" height="100" src={md} />
                    </a>
                  </div>
                ))
              : null}
          </div>
        </div>
        <div className="sender-meta clearfix">
          <span
            className={`pimage${item.AuthorId === 0 ? " message-bot" : ""}`}
          >
            {item.AuthorId === 0 ? (
              <i className="fa-thin fa-message-bot"></i>
            ) : (
              <img src={item.pimage} alt="" />
            )}
          </span>
          <a href="#" title="" className="sender-name">
            {item.aname}
          </a>
        </div>
        <div className="schedule-actions">
          <button
            onClick={() => schedule_message_send_now(item.LeadId, item.Id)}
            id="schedule_sendnow"
            className="schedule_action"
          >
            Send now
          </button>
          <button
            data-toggle="modal"
            data-target="#scheduleModaleditime_<?=$sms[$i]['Id']?>"
            id="schedule_changetime"
            className="schedule_action"
          >
            Change time
          </button>
          <button
            onClick={() => schedule_message_delete(item.LeadId, item.Id)}
            id="schedule_delete"
            className="schedule_action"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
}
