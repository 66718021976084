import React from "react";
import {
  Container,
  Divider,
  Typography,
  Stack,
  useScrollTrigger,
  Box,
  Fade,
  Fab,
} from "@mui/material";
import { Link } from "react-router-dom";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { TimeHelper } from "../../utils";

function ScrollTop(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      "#back-to-top-anchor",
    );

    if (anchor) {
      anchor.scrollIntoView({
        block: "center",
      });
    }
  };

  return (
    <Fade in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: "fixed", bottom: 16, right: 16 }}
      >
        {children}
      </Box>
    </Fade>
  );
}

export default function Footer(props) {
  return (
    <Container sx={{ mt: 3, mb: 3 }} maxWidth="xl">
      <Divider />
      <Typography variant="subtitle1" sx={{ pt: 3 }} textAlign={"center"}>
        Copyright © {TimeHelper.getCurrentYear()} Dealer Funnel, LLC, all
        rights reserved.
      </Typography>
      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        spacing={2}
        justifyContent="center"
        alignItems="center"
        sx={{ mt: 2 }}
      >
        <Link to="/privacy" style={{ color: "inherit" }}>
          <Typography variant="subtitle2">Privacy Policy</Typography>
        </Link>
        <Link to="/terms" style={{ color: "inherit" }}>
          <Typography variant="subtitle2">Terms</Typography>
        </Link>
      </Stack>
      <ScrollTop {...props}>
        <Fab size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </Container>
  );
}
