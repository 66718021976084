import Modal from "react-modal";
import React, { useContext, useState, useEffect } from "react";
import {
  AuthContext,
  ConversationContext,
  UpdashTwilioContext,
} from "../../context";
import helper from "../../utils/helper";
import { toast } from "react-toastify";
import config from "../../config/config";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "560px",
    padding: "15px",
    marginTop: "20px",
    marginBottom: "20px",
  },
};

Modal.setAppElement("#root");

export default function ConversationCallPopupModal({
  leadId,
  unsetnewlead,
  isOpenModal = false,
  afterOpenModal,
  closeModal,
}) {
  const { user } = useContext(AuthContext);
  const { loadCallPopupModalApi } = useContext(ConversationContext);
  const { makeOutboundCallApi } = useContext(UpdashTwilioContext);
  const [lead, setLead] = useState(null);
  const [address, setAddress] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [uimage, setUimage] = useState("");
  const [pimage, setPimage] = useState("");
  const [isusernumber, setIsusernumber] = useState("");
  const [twiliophone, setTwiliophone] = useState("");

  useEffect(() => {
    if (leadId) {
      loadCallPopupModal(leadId);
    }
  }, [leadId]);

  const loadCallPopupModal = async (_leadId) => {
    console.log("<<<>>>>", _leadId);
    const data = await loadCallPopupModalApi({
      userId: user.Id,
      lead: _leadId,
    });
    if (data.status === "ok") {
      const res = data.response;
      setLead(res?.lead);
      setAddress(res?.address);
      setUserInfo(res?.user);
      setUimage(res?.uimage);
      setPimage(res?.pimage);
      setIsusernumber(res?.Isusernumber);
      setTwiliophone(res?.twiliophone);
    }
  };

  const onCall = async () => {
    let params = {
      userid: user?.Id,
      lead: leadId,
      twiliophone: twiliophone,
      phone: userInfo?.Mobile,
      leadphone: address?.Phone,
    };
    const res = await makeOutboundCallApi(params);
    if (res) {
      console.log("== make twilio call ==", res);
      if (Number(lead?.Newlead) === 1) {
        unsetnewlead();
      }
      toast.info("Connecting.");
      closeModal();
    }
  };

  return (
    <Modal
      isOpen={isOpenModal}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Status"
    >
      {!!lead && (
        <>
          <div className="modal-header">
            <h4 className="d-flex align-items-center">
              {Number(isusernumber) === 1 ? "Outbound Call" : "Error"}
            </h4>
          </div>
          <div className="modal-body">
            {Number(isusernumber) === 1 ? (
              <form className="callpopup-form my-3">
                <div className="row">
                  <div className="col-sm-4">
                    <div className="profile-picture">
                      <img
                        src={uimage}
                        alt={userInfo?.Name}
                        className="w-100"
                      />
                    </div>
                    <div className="infor">
                      <h4>{userInfo?.Name}</h4>
                      <div>{helper.getnumberformate(userInfo?.Mobile)}</div>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <img src={`${config.assetPath}/images/call.gif`} alt="" />
                  </div>
                  <div className="col-sm-4">
                    <div className="profile-picture">
                      <img src={pimage} alt="Travin Harris" className="w-100" />
                    </div>
                    <div className="infor">
                      <h4>{lead?.pname}</h4>
                      <div>{helper.getnumberformate(address?.Phone)}</div>
                    </div>
                  </div>
                </div>
              </form>
            ) : (
              <div className="alert alert-danger my-3">
                Please add a phone number in your user settings.
              </div>
            )}
            <form></form>
          </div>
        </>
      )}
      <div className="modal-footer mt-3">
        <button className="btn btn-outline-secondary" onClick={closeModal}>
          {Number(isusernumber) === 1 ? "Cancel" : "Close"}
        </button>
        {Number(isusernumber) === 1 && (
          <button className="btn btn-primary ms-2" onClick={() => onCall()}>
            Call
          </button>
        )}
      </div>
    </Modal>
  );
}
