import React, { createContext, useContext, useState } from "react";
import { AxiosContext } from "./axios_context";
import config from "../config/config";

const ClientContext = createContext(undefined);

const ClientContextProvider = (props) => {
  const { axiosInstance, setLoading } = useContext(AxiosContext);
  const [clients, setClients] = useState([]);

  const getClientsDataApi = (params) => {
    const data = params;
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/clients/clients_data`,
      data,
    })
      .then((response) => {
        const data = response.data;
        console.log("=== clients_data ===", data);
        const res = data.response;
        if (data.status === "ok") {
          setClients(res?.client);
        }
        return data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return error;
      });
  };

  const deleteClientApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/clients/clients_data`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("=== clients_data ===", data);
        const res = data.response;
        if (data.status === "ok") {
          setClients(res?.client);
        }
        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
        return error;
      });
  };

  const getClientDetailApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/clients/clients_detailbykeyid`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("=== clients_detailbykeyid ===", data);
        const res = data.response;
        if (data.status === "ok") {
          setClients(res?.client);
        }
        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
        return error;
      });
  };

  const updateClientInfoApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/clients/clients_update`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("=== clients_update ===", data);
        const res = data.response;
        if (data.status === "ok") {
          setClients(res?.client);
        }
        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
        return error;
      });
  };

  const getDealerfunnelByClientIdApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/clients/clients_dealerfunnelbyclientid`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("=== clients_dealerfunnelbyclientid ===", data);
        const res = data.response;
        if (data.status === "ok") {
          setClients(res?.client);
        }
        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
        return error;
      });
  };

  const getUsersByClientIdApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/clients/clients_getusers`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("=== clients_getusers ===", data);
        const res = data.response;
        if (data.status === "ok") {
          setClients(res?.client);
        }
        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
        return error;
      });
  };

  const getleadsourcesApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/clients/clients_getleadsources`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("=== clients_getleadsources ===", data);
        const res = data.response;
        if (data.status === "ok") {
          setClients(res?.client);
        }
        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
        return error;
      });
  };

  const toggleIncomingadfApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/clients/clients_incomingadf`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("=== clients_incomingadf ===", data);
        const res = data.response;
        if (data.status === "ok") {
          setClients(res?.client);
        }
        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
        return error;
      });
  };

  const toggleFunnelsApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/clients/clients_incomingadf`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("=== clients_incomingadf ===", data);
        const res = data.response;
        if (data.status === "ok") {
          setClients(res?.client);
        }
        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
        return error;
      });
  };

  const toggleDoubleOptInApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/clients/clients_doubleoptin`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("=== clients_doubleoptin ===", data);
        const res = data.response;
        if (data.status === "ok") {
          setClients(res?.client);
        }
        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
        return error;
      });
  };

  const updateSmsForwardApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/clients/clients_updatesmsforward`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("=== clients_updatesmsforward ===", data);
        const res = data.response;
        if (data.status === "ok") {
          setClients(res?.client);
        }
        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
        return error;
      });
  };

  const getLeadsourceModalDataApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/clients/clients_leadsource`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("=== clients_leadsource ===", data);
        const res = data.response;
        if (data.status === "ok") {
          setClients(res?.client);
        }
        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
        return error;
      });
  };

  const saveLeadSourceApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/clients/clients_saveleadsource`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("=== clients_saveleadsource ===", data);
        const res = data.response;
        if (data.status === "ok") {
          setClients(res?.client);
        }
        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
        return error;
      });
  };

  const getImportLeadsModalDataApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/clients/clients_importleads`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("=== clients_importleads ===", data);

        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
        return error;
      });
  };

  const leadimportsessionApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/clients/clients_leadimportsession`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("=== clients_leadimportsession ===", data);

        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
        return error;
      });
  };

  const getImportLeadsFinalModalDataApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/clients/clients_importleadsfinal`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("=== clients_importleadsfinal ===", data);

        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
        return error;
      });
  };

  const importleadApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/clients/clients_importlead`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("=== clients_importlead ===", data);
        const res = data.response;
        if (data.status === "ok") {
          setClients(res?.client);
        }
        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
        return error;
      });
  };

  const deletesmsnumberApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/clients/clients_deletesmsnumber`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("=== clients_deletesmsnumber ===", data);

        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
        return error;
      });
  };

  const getSearchSmsPhoneModalDataApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/clients/clients_serchsmsphone`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("=== clients_serchsmsphone ===", data);

        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
        return error;
      });
  };

  const searchSmsNumberApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/clients/clients_searchsmsnumber`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("=== clients_searchsmsnumber ===", data);

        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
        return error;
      });
  };

  const buysmsnumberApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/clients/clients_buysmsnumber`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("=== clients_buysmsnumber ===", data);

        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
        return error;
      });
  };

  const removeLeadSourceApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/clients/clients_removeleadsource`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("=== clients_removeleadsource ===", data);

        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
        return error;
      });
  };

  const getAlertsApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/clients/clients_getalerts`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("=== clients_getalerts ===", data);

        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
        return error;
      });
  };

  const setactionApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/clients/clients_setaction`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("=== clients_setaction ===", data);

        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
        return error;
      });
  };

  const getSavedRepliesApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/clients/clients_savedreplies`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("=== clients_savedreplies ===", data);

        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
        return error;
      });
  };

  const createcategoryApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/clients/clients_createcategory`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("=== clients_createcategory ===", data);

        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
        return error;
      });
  };

  const getChatboxApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/clients/clients_getchatbox`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("=== clients_getchatbox ===", data);

        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
        return error;
      });
  };

  const setsmswidgetApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/clients/clients_setsmswidget`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("=== clients_setsmswidget ===", data);

        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
        return error;
      });
  };

  const getAiSetupApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/clients/clients_getaisetup`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("=== clients_getaisetup ===", data);

        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
        return error;
      });
  };

  const setAiApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/clients/clients_setai`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("=== clients_setai ===", data);

        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
        return error;
      });
  };

  const getCTASetupApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/clients/clients_getctasetup`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("=== clients_getctasetup ===", data);

        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
        return error;
      });
  };

  const savectadataApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/clients/clients_savectadata`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("=== clients_savectadata ===", data);

        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
        return error;
      });
  };

  const getDefaultMessageApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/clients/clients_defaultmessage`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("=== clients_defaultmessage ===", data);

        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
        return error;
      });
  };

  const saveDefaultMessageApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/clients/clients_savedefaultmessage`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("=== clients_savedefaultmessage ===", data);

        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
        return error;
      });
  };

  const getNewClientPageDataApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/clients/clients_newclient`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("=== clients_createclient ===", data);

        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
        return error;
      });
  };

  const createNewClientApi = (params) => {
    const data = params;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/clients/clients_createclient`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("=== clients_createclient ===", data);

        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
        return error;
      });
  };

  const providerValue = {
    getClientsDataApi,
    clients,
    deleteClientApi,
    getClientDetailApi,
    updateClientInfoApi,
    getDealerfunnelByClientIdApi,
    getUsersByClientIdApi,
    getleadsourcesApi,
    toggleIncomingadfApi,
    toggleFunnelsApi,
    toggleDoubleOptInApi,
    updateSmsForwardApi,
    getLeadsourceModalDataApi,
    saveLeadSourceApi,
    getImportLeadsModalDataApi,
    leadimportsessionApi,
    getImportLeadsFinalModalDataApi,
    importleadApi,
    deletesmsnumberApi,
    getSearchSmsPhoneModalDataApi,
    searchSmsNumberApi,
    buysmsnumberApi,
    removeLeadSourceApi,
    getAlertsApi,
    setactionApi,
    getSavedRepliesApi,
    createcategoryApi,
    getChatboxApi,
    setsmswidgetApi,
    getAiSetupApi,
    setAiApi,
    getCTASetupApi,
    savectadataApi,
    getDefaultMessageApi,
    saveDefaultMessageApi,
    getNewClientPageDataApi,
    createNewClientApi,
  };

  return (
    <ClientContext.Provider value={providerValue}>
      {props.children}
    </ClientContext.Provider>
  );
};

export { ClientContext, ClientContextProvider };
