import React, { useState, useContext } from "react";
import { Logo } from "../../assets/images";
import "./header.scss";
import Dropdown from "react-bootstrap/Dropdown";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SettingsIcon from "@mui/icons-material/Settings";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import LogoutIcon from "@mui/icons-material/Logout";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  SlGrid,
  SlBriefcase,
  SlUser,
  SlClock,
  SlBubbles,
  SlCamrecorder,
  SlRocket,
  SlChart,
} from "react-icons/sl";
import { LiaFunnelDollarSolid } from "react-icons/lia";
import { AuthContext, ReportContext } from "../../context";
import { BsActivity, BsPeople } from "react-icons/bs";
import { BiLineChart, BiMessageRoundedDetail } from "react-icons/bi";
import { HiOutlineCurrencyDollar } from "react-icons/hi2";
import {
  Typography,
  Box,
  AppBar,
  Toolbar,
  IconButton,
  MenuItem,
  Menu,
  Container,
  Button,
  Stack,
  Avatar,
  FormControlLabel,
  Switch,
  Divider,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import MoreIcon from "@mui/icons-material/MoreVert";
import {
  StandardImage,
  ActionsPhoneLookupModal,
  ActionsOnBoardingModal,
  ActionsMergeLeadModal,
  AutoCompleteWithAutocompleteApi,
} from "..";
import { ColorsConfig } from "../../config";

export default function Header() {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, logout } = useContext(AuthContext);
  const { sendonboardingApi } = useContext(ReportContext);

  const [anchorActionEl, setAnchorActionEl] = useState(null);
  const isActionOpen = Boolean(anchorActionEl);
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const [openPhoneModal, setOpenPhoneModal] = useState(false);
  const [openOnboardingModal, setOpenOnboardingModal] = useState(false);
  const [openMergeLeadModal, setOpenMergeLeadModal] = useState(false);

  const handleCloseModal = () => {
    setOpenPhoneModal(false);
    setOpenOnboardingModal(false);
    setOpenMergeLeadModal(false);
  };

  const handleOnboarding = async (value) => {
    const data = await sendonboardingApi({ userId: user.Id, email: value });
    if (data.status === "ok") {
      handleCloseModal();
      navigate("/reports/onboarding");
    }
  };

  const handleActionOpen = (event) => {
    setAnchorActionEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setAnchorActionEl(null);
    handleMobileMenuClose();
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const [modeChecked, setModeChecked] = useState(false);

  const handleModeChange = (event) => {
    setModeChecked(event.target.checked);
  };

  const actionId = "user-action-id";
  const renderActions = (
    <Menu
      anchorEl={anchorActionEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      id={actionId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isActionOpen}
      onClose={handleMenuClose}
    >
      <MenuItem
        onClick={() => {
          navigate("/clients/newclient");
          handleMenuClose();
        }}
        key={"action-client"}
      >
        <Stack direction={"row"} justifyContent="start" alignItems="center">
          <AddCircleOutlineIcon fontSize="26" sx={{ mr: 1 }} />
          <Typography>Client</Typography>
        </Stack>
      </MenuItem>
      <MenuItem
        onClick={() => {
          navigate("/users/newuser");
          handleMenuClose();
        }}
        key={"action-user"}
      >
        <Stack direction={"row"} justifyContent="start" alignItems="center">
          <AddCircleOutlineIcon fontSize="26" sx={{ mr: 1 }} />
          <Typography>User</Typography>
        </Stack>
      </MenuItem>
      <MenuItem
        onClick={() => {
          navigate("/users/newgroup");
          handleMenuClose();
        }}
        key={"action-user-group"}
      >
        <Stack direction={"row"} justifyContent="start" alignItems="center">
          <AddCircleOutlineIcon fontSize="26" sx={{ mr: 1 }} />
          <Typography>User Group</Typography>
        </Stack>
      </MenuItem>
      {/* <MenuItem
        onClick={() => {
          navigate("/users/new-lead");
          handleMenuClose();
        }}
        key={"action-lead"}
      >
        <Stack direction={"row"} justifyContent="start" alignItems="center">
          <AddCircleOutlineIcon fontSize="26" sx={{ mr: 1 }} />
          <Typography>Lead</Typography>
        </Stack>
      </MenuItem> */}
      <MenuItem
        onClick={() => {
          setOpenPhoneModal(true);
          handleMenuClose();
        }}
        key={"action-phone-lookup"}
      >
        <Stack direction={"row"} justifyContent="start" alignItems="center">
          <SearchIcon fontSize="26" sx={{ mr: 1 }} />
          <Typography>Phone Lookup</Typography>
        </Stack>
      </MenuItem>
      <MenuItem
        onClick={() => {
          setOpenMergeLeadModal(true);
          handleMenuClose();
        }}
        key={"action-merge-lead"}
      >
        <Stack direction={"row"} justifyContent="start" alignItems="center">
          <AnalyticsIcon fontSize="26" sx={{ mr: 1 }} />
          <Typography>Merge Leads</Typography>
        </Stack>
      </MenuItem>
      <MenuItem
        onClick={() => {
          setOpenOnboardingModal(true);
          handleMenuClose();
        }}
        key={"action-onboarding"}
      >
        <Stack direction={"row"} justifyContent="start" alignItems="center">
          <AddCircleOutlineIcon fontSize="26" sx={{ mr: 1 }} />
          <Typography>Onboarding</Typography>
        </Stack>
      </MenuItem>
    </Menu>
  );

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem
        onClick={() => {
          navigate("/users/profile");
          handleMenuClose();
        }}
        key="account-setting"
      >
        <Stack direction={"row"} justifyContent="start" alignItems="center">
          <SettingsIcon fontSize="26" sx={{ mr: 1 }} />
          <Typography>Settings</Typography>
        </Stack>
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleMenuClose();
        }}
        key="account-mode"
      >
        <FormControlLabel
          control={
            <Switch
              color="default"
              checked={modeChecked}
              onChange={handleModeChange}
            />
          }
          label="Dark Mode"
        />
      </MenuItem>
      <Divider />
      <MenuItem
        onClick={() => {
          handleMenuClose();
          logout();
        }}
        key="account-sign-out"
      >
        <Stack direction={"row"} justifyContent="start" alignItems="center">
          <LogoutIcon fontSize="26" sx={{ mr: 1 }} />
          <Typography>Sign Out</Typography>
        </Stack>
      </MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleActionOpen} key="mobile-actions">
        <Stack direction={"row"} justifyContent="start" alignItems="center">
          <SettingsIcon fontSize="26" sx={{ mr: 1 }} />
          <Typography>Actions</Typography>
        </Stack>
      </MenuItem>
      <MenuItem
        onClick={() => {
          navigate("/users/profile");
          handleMenuClose();
        }}
        key="mobile-setting"
      >
        <Stack direction={"row"} justifyContent="start" alignItems="center">
          <SettingsIcon fontSize="26" sx={{ mr: 1 }} />
          <Typography>Settings</Typography>
        </Stack>
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleMenuClose();
        }}
        key="mobile-mode"
      >
        <FormControlLabel
          control={
            <Switch
              color="default"
              checked={modeChecked}
              onChange={handleModeChange}
            />
          }
          label="Dark Mode"
        />
      </MenuItem>
      <Divider />
      <MenuItem
        onClick={() => {
          handleMenuClose();
          logout();
        }}
        key="mobile-sign-out"
      >
        <Stack direction={"row"} justifyContent="start" alignItems="center">
          <LogoutIcon fontSize="26" sx={{ mr: 1 }} />
          <Typography>Sign Out</Typography>
        </Stack>
      </MenuItem>
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1 }} id="back-to-top-anchor">
      <Container maxWidth="xl">
        <AppBar
          position="static"
          sx={{
            backgroundColor: "inherit",
            boxShadow: "none",
          }}
        >
          <Toolbar>
            <Stack
              direction={"row"}
              spacing={2}
              justifyContent="start"
              alignItems="center"
            >
              <StandardImage
                src={Logo}
                width="135px"
                onClick={() => {
                  navigate("/dashboard");
                }}
              />
              <Button
                sx={{ display: { xs: "none", md: "flex" } }}
                id="basic-button"
                aria-controls={actionId}
                aria-haspopup="true"
                onClick={handleActionOpen}
              >
                <Typography
                  variant="body2"
                  color={ColorsConfig.light.primaryColor}
                >
                  ··· Actions
                </Typography>
              </Button>
              <Box sx={{ width: "35ch", display: { xs: "none", md: "flex" } }}>
                <AutoCompleteWithAutocompleteApi />
              </Box>
            </Stack>
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              <IconButton
                size="large"
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <Stack
                  direction={"row"}
                  justifyContent="start"
                  alignItems="center"
                  spacing={1}
                >
                  <Typography color={ColorsConfig.light.primaryColor}>
                    {user?.Name}
                  </Typography>
                  <Avatar src={user?.Image} alt="U" />
                </Stack>
              </IconButton>
            </Box>
            <Box sx={{ display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color={ColorsConfig.light.primaryColor}
              >
                <MoreIcon />
              </IconButton>
              <IconButton
                color={ColorsConfig.light.primaryColor}
                aria-label="menu"
                sx={{ ml: 0 }}
              >
                <MenuIcon />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
      </Container>
      <div className="navbar d-flex align-items-center bg-dark text-white p-0">
        <div className="container h-100">
          <ul className="header-navs h-100 d-flex p-0 m-0">
            <li
              className={`nav-item ${
                location.pathname === "/dashboard" ? "active" : ""
              }`}
            >
              <Link to={`/dashboard`} className="nav-link">
                <SlGrid className="me-2" />
                Dashboard
              </Link>
            </li>
            <li
              className={`nav-item ${
                location.pathname === "/clients" ? "active" : ""
              }`}
            >
              <Link to={`/clients`} className="nav-link">
                <SlBriefcase className="me-2" />
                Clients
              </Link>
            </li>
            <li
              className={`nav-item ${
                location.pathname === "/users" ? "active" : ""
              }`}
            >
              <Link to={`/users`} className="nav-link">
                <SlUser className="me-2" />
                Users
              </Link>
            </li>
            <li
              className={`nav-item ${
                location.pathname === "/appointments" ? "active" : ""
              }`}
            >
              <Link to={`/appointments`} className="nav-link">
                <SlClock className="me-2" />
                Appointments
              </Link>
            </li>
            <li
              className={`nav-item ${
                location.pathname === "/conversations" ? "active" : ""
              }`}
            >
              <Link to={`/conversations`} className="nav-link">
                <SlBubbles className="me-2" />
                Conversations
              </Link>
            </li>
            <li
              className={`nav-item ${
                location.pathname === "/videos" ? "active" : ""
              }`}
            >
              <Link to={`/videos`} className="nav-link">
                <SlCamrecorder className="me-2" />
                Videos
              </Link>
            </li>
            <li
              className={`nav-item ${
                location.pathname === "/funnels" ? "active" : ""
              }`}
            >
              <Link to={`/funnels`} className="nav-link">
                <LiaFunnelDollarSolid className="me-2" />
                Funnels
              </Link>
            </li>
            <li
              className={`nav-item ${
                location.pathname === "/blasts" ? "active" : ""
              }`}
            >
              <Link to={`/blasts`} className="nav-link">
                <SlRocket className="me-2" />
                Blasts
              </Link>
            </li>
            <Dropdown
              className={`nav-item ${
                location.pathname.includes("reports") ? "active" : ""
              }`}
            >
              <Dropdown.Toggle variant="btn-nav nav-link w-100 h-100">
                <SlChart className="nav-icon me-2" />
                Reports
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  className="nav-dropdown-item"
                  href={`/reports/smsreport`}
                >
                  <BsActivity className="me-2" />
                  Activity
                </Dropdown.Item>
                <Dropdown.Item
                  className="nav-dropdown-item"
                  href={`/reports/admin_smsreport`}
                >
                  <BiMessageRoundedDetail className="me-2" />
                  Messages
                </Dropdown.Item>
                <Dropdown.Item
                  className="nav-dropdown-item"
                  href={`/reports/leadsource`}
                >
                  <HiOutlineCurrencyDollar className="me-2" />
                  Lead Sources
                </Dropdown.Item>
                <Dropdown.Item
                  className="nav-dropdown-item"
                  href={`/reports/user_stats`}
                >
                  <BiLineChart className="me-2" />
                  User Stats
                </Dropdown.Item>
                <Dropdown.Item
                  className="nav-dropdown-item"
                  href={`/reports/hot_leads`}
                >
                  <BiLineChart className="me-2" />
                  Hot Leads
                </Dropdown.Item>
                <Dropdown.Item
                  className="nav-dropdown-item"
                  href={`/reports/onboarding`}
                >
                  <BsPeople className="me-2" />
                  Onboarding
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </ul>
        </div>
      </div>
      {renderMobileMenu}
      {renderMenu}
      {renderActions}
      <ActionsPhoneLookupModal
        open={openPhoneModal}
        handleCloseModal={handleCloseModal}
      />
      <ActionsOnBoardingModal
        open={openOnboardingModal}
        handleCloseModal={handleCloseModal}
        handleSubmit={handleOnboarding}
      />
      <ActionsMergeLeadModal
        open={openMergeLeadModal}
        handleCloseModal={handleCloseModal}
        handleSubmit={handleOnboarding}
      />
    </Box>
  );
}
