import React, { useContext, useEffect, useState } from "react";
import { Box, Card, Skeleton, Stack, Typography } from "@mui/material";
import { ConversationContext } from "../../context";
import { ColorsConfig } from "../../config";
import { DefaultButton, MultiSelect, PrimaryButton } from "../../components";

export default function ConversationsMainFilter(props) {
  const { filterParams, handleCallBack, loading } = props;
  const {
    clients,
    assignusers,
    usertags,
    campaigns,
    leadsources,
    saveFilterParams,
  } = useContext(ConversationContext);
  const [clientsList, setClientsList] = useState([]);
  const [campaignsList, setCampaignsList] = useState([]);
  const [userTagsList, setUserTagsList] = useState([]);
  const [assignUsersList, setAssignUsersList] = useState([]);
  const [leadSourcesList, setLeadSourcesList] = useState([]);
  const [selectedClients, setSelectedClients] = useState(
    filterParams.clientArr,
  );
  const [selectedCampaigns, setSelectedCampaigns] = useState(
    filterParams.campaignArr,
  );
  const [selectedUserTags, setSelectedUserTags] = useState(filterParams.tagArr);
  const [selectedAssignUsers, setSelectedAssignUsers] = useState(
    filterParams.assigneduserArr,
  );
  const [selectedLeadSources, setSelectedLeadSources] = useState(
    filterParams.leadsourceArr,
  );

  useEffect(() => {
    let items = [];
    clients?.map((item) => {
      items.push({ value: item["Id"], name: item["Name"] });
    });
    setClientsList(items);
    items = [];
    campaigns?.map((item) => {
      items.push({ value: item["Id"], name: `${item["Job"]} ${item["Name"]}` });
    });
    setCampaignsList(items);
    items = [];
    usertags?.map((item) => {
      items.push({ value: item["Id"], name: `${item["Name"]}` });
    });
    setUserTagsList(items);
    items = [];
    assignusers?.map((item) => {
      items.push({ value: item["Id"], name: `${item["Name"]}` });
    });
    setAssignUsersList(items);
    items = [];
    leadsources?.map((item) => {
      items.push({ value: item["Id"], name: `${item["Name"]}` });
    });
    setLeadSourcesList(items);
  }, [clients]);

  const onChange = (field, value, saveFilter = false) => {
    const data = {
      clientArr: selectedClients.map((a) => a.value),
      campaignArr: selectedCampaigns.map((a) => a.value),
      tagArr: selectedUserTags.map((a) => a.value),
      assigneduserArr: selectedAssignUsers.map((a) => a.value),
      leadsourceArr: selectedLeadSources.map((a) => a.value),
      saveFilter: saveFilter,
    };
    if (field) {
      handleCallBack({
        ...data,
        [field]: value.map((a) => a.value),
      });
    } else {
      handleCallBack({
        ...data,
      });
    }
  };

  const renderSkeleton = () => {
    return (
      <Box>
        <Stack
          direction={"column"}
          alignItems={"start"}
          justifyContent={"start"}
          spacing={2}
          sx={{
            width: "100%",
            mt: 1,
          }}
        >
          {[...Array(5)].map((item, index) => {
            return (
              <Skeleton
                key={index}
                item={item}
                variant={"rounded"}
                width={"100%"}
                height={40}
              />
            );
          })}
        </Stack>
      </Box>
    );
  };

  const renderView = () => {
    return (
      <Box>
        <Stack
          direction={"column"}
          alignItems={"start"}
          justifyContent={"start"}
          spacing={2}
          sx={{
            width: "100%",
            mt: 1,
          }}
        >
          <MultiSelect
            options={clientsList}
            values={selectedClients}
            handleChange={(e) => {
              setSelectedClients(e);
              onChange("clientArr", e);
            }}
            placeholder={"Select Clients"}
            isSearch={false}
            fullWidth={true}
          />
          <MultiSelect
            options={campaignsList}
            values={selectedCampaigns}
            handleChange={(e) => {
              setSelectedCampaigns(e);
              onChange("campaignArr", e);
            }}
            placeholder={"Select Campaigns"}
            isSearch={false}
            fullWidth={true}
          />
          <MultiSelect
            options={userTagsList}
            values={selectedUserTags}
            handleChange={(e) => {
              setSelectedUserTags(e);
              onChange("tagArr", e);
            }}
            placeholder={"Select Labels"}
            isSearch={false}
            fullWidth={true}
          />
          <MultiSelect
            options={assignUsersList}
            values={selectedAssignUsers}
            handleChange={(e) => {
              setSelectedAssignUsers(e);
              onChange("assigneduserArr", e);
            }}
            placeholder={"Select Assignments"}
            isSearch={false}
            fullWidth={true}
          />
          <MultiSelect
            options={leadSourcesList}
            values={selectedLeadSources}
            handleChange={(e) => {
              setSelectedLeadSources(e);
              onChange("leadsourceArr", e);
            }}
            placeholder={"Select Leadsource"}
            isSearch={false}
            fullWidth={true}
          />
        </Stack>
        <Stack direction={"row"} alignItems={"center"} spacing={2} mt={2}>
          <PrimaryButton
            label={"Save Filter"}
            handleClick={() => {
              onChange(null, null, true);
            }}
          />
          <DefaultButton
            label={"Reset"}
            handleClick={() => {
              handleCallBack();
            }}
          />
        </Stack>
      </Box>
    );
  };

  return (
    <Card sx={{ width: "100%", p: "10px 12px", overflow: "visible" }}>
      <Typography
        sx={{
          fontSize: 14,
          color: ColorsConfig.light.secondaryInfo,
        }}
      >
        Filter
      </Typography>
      {!loading && saveFilterParams ? renderView() : renderSkeleton()}
    </Card>
  );
}
