import {
  BsCalendar3,
  BsCheck2All,
  BsCheckLg,
  BsPlusLg,
  BsReplyAll,
  BsThreeDotsVertical,
} from "react-icons/bs";
import { SlRocket } from "react-icons/sl";
import { Link } from "react-router-dom";
import {
  BlastLeadsDataTable,
  BlastReScheduleMessageModal,
  BlastsCalendarView,
  DropdownMultiSelect,
} from "../../components";
import { useContext, useState } from "react";
import { AuthContext, BlastContext } from "../../context";
import React, { useEffect } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import { BiCopy } from "react-icons/bi";
import { IoBanOutline, IoCloseCircleOutline } from "react-icons/io5";
import Dropdown from "react-bootstrap/Dropdown";

export default function BlastsPage() {
  const { user } = useContext(AuthContext);
  const {
    getBlastsPageDataApi,
    blastFilterApi,
    getBlastSummaryApi,
    getBlastsCalendarDataApi,
    cancelBlastApi,
  } = useContext(BlastContext);
  //
  const [clientlist, setClientlist] = useState([]);
  const [info, setInfo] = useState([]);
  const [preData, setPreData] = useState({
    blast_scmp: "",
    csvclient: "",
    blast_clt: "",
    firstblast: "",
  });
  const [filterParams, setFilterParams] = useState({
    userId: user.Id,
    status: "0",
    startdate: moment().format("YYYY-MM-DD"),
    enddate: moment().format("YYYY-MM-DD"),
    datelabel: `${moment().format("MMM D, YYYY")} - ${moment().format(
      "MMM D, YYYY",
    )}`,
    client: "",
  });
  const [customColor, setCustomColor] = useState("");
  const [clientParams, setClientParams] = useState({
    client: "",
    clientArr: [],
  });
  //
  const [viewMode, setViewMode] = useState("list"); // list | calendar
  const [selectedBlast, setSelectedBlast] = useState("");
  //
  const [summaryData, setSummaryData] = useState(undefined);
  //
  const [calendar, setCalendar] = useState(undefined);
  //
  const [reScheduleModal, setReScheduleModal] = useState(false);

  useEffect(() => {
    getBlastsPageData();
    console.log(customColor);
  }, []);

  const getBlastsPageData = async () => {
    const data = await getBlastsPageDataApi({ userId: user.Id });
    if (data.status === "ok") {
      const res = data.response;
      setClientlist(res?.client);
      if (res?.info && res?.info.length > 0) {
        setInfo(res?.info);
        setSelectedBlast(res?.info[0].Id);
      }
      //
      let _preData = { ...preData };
      _preData.blast_scmp = res?.blast_scmp;
      _preData.csvclient = res?.csvclient;
      _preData.blast_clt = res?.blast_clt;
      _preData.firstblast = res?.firstblast;
      setPreData(_preData);
      //
      let _filterParams = { ...filterParams };
      _filterParams.userId = user.Id;
      if (res?.startdate && res?.enddate) {
        _filterParams.startdate = moment(res?.startdate).format("YYYY-MM-DD");
        _filterParams.enddate = moment(res?.enddate).format("YYYY-MM-DD");
        _filterParams.datelabel = `${moment(res?.startdate).format(
          "MMM D, YYYY",
        )} - ${moment(res?.enddate).format("MMM D, YYYY")}`;
      }
      setFilterParams(_filterParams);
      //
      setCustomColor(res?.custom_color);
    }
  };

  const handleCallback = (start, end) => {
    const startD = moment(start).format("YYYY-MM-DD");
    const endD = moment(end).format("YYYY-MM-DD");
    if (
      startD === moment().format("YYYY-MM-DD") &&
      endD === moment().format("YYYY-MM-DD")
    ) {
      blastFilter({
        ...filterParams,
        datelabel: "Today",
        startdate: moment(startD).format("YYYY-MM-DD"),
        enddate: moment(endD).format("YYYY-MM-DD"),
      });
    } else if (
      startD === moment().subtract(1, "days").format("YYYY-MM-DD") &&
      endD === moment().subtract(1, "days").format("YYYY-MM-DD")
    ) {
      blastFilter({
        ...filterParams,
        datelabel: "Yesterday",
        startdate: moment(startD).format("YYYY-MM-DD"),
        enddate: moment(endD).format("YYYY-MM-DD"),
      });
    } else if (
      startD === moment().subtract(6, "days").format("YYYY-MM-DD") &&
      endD === moment().format("YYYY-MM-DD")
    ) {
      blastFilter({
        ...filterParams,
        datelabel: "Last 7 Days",
        startdate: moment(startD).format("YYYY-MM-DD"),
        enddate: moment(endD).format("YYYY-MM-DD"),
      });
    } else if (
      startD === moment().subtract(29, "days").format("YYYY-MM-DD") &&
      endD === moment().format("YYYY-MM-DD")
    ) {
      blastFilter({
        ...filterParams,
        datelabel: "Last 30 Days",
        startdate: moment(startD).format("YYYY-MM-DD"),
        enddate: moment(endD).format("YYYY-MM-DD"),
      });
    } else if (
      startD === moment().startOf("month").format("YYYY-MM-DD") &&
      endD === moment().endOf("month").format("YYYY-MM-DD")
    ) {
      blastFilter({
        ...filterParams,
        datelabel: "This Month",
        startdate: moment(startD).format("YYYY-MM-DD"),
        enddate: moment(endD).format("YYYY-MM-DD"),
      });
    } else if (
      startD ===
        moment().subtract(1, "month").startOf("month").format("YYYY-MM-DD") &&
      endD === moment().subtract(1, "month").endOf("month").format("YYYY-MM-DD")
    ) {
      blastFilter({
        ...filterParams,
        datelabel: "Last Month",
        startdate: moment(startD).format("YYYY-MM-DD"),
        enddate: moment(endD).format("YYYY-MM-DD"),
      });
    } else {
      blastFilter({
        ...filterParams,
        datelabel: `${moment(start).format("MM/DD/YYYY")} - ${moment(
          end,
        ).format("MM/DD/YYYY")}`,
        startdate: moment(startD).format("YYYY-MM-DD"),
        enddate: moment(endD).format("YYYY-MM-DD"),
      });
    }
  };

  const blastFilter = async (fParams) => {
    setFilterParams(fParams);
    let params = { ...fParams };
    if (!params.client) {
      params.client = preData.blast_clt;
      params.frm = 2;
    } else {
      params.client = params.client.split(",");
      params.frm = 1;
    }
    const data = await blastFilterApi(params);
    if (data.status === "ok") {
      const res = data.response;
      setInfo(res?.info);
    }
  };

  useEffect(() => {
    if (selectedBlast) {
      getBlastSummary();
    }
  }, [selectedBlast]);

  const getBlastSummary = async () => {
    const data = await getBlastSummaryApi({
      userId: user.Id,
      id: selectedBlast,
    });
    if (data.status === "ok") {
      const res = data.response;
      setSummaryData(res);
    }
  };

  const onClickViewMode = (mode) => {
    setViewMode(mode);
    if (mode === "calendar") {
      setSummaryData(undefined);
      getBlastsCalendarData();
    } else {
      getBlastsPageData();
    }
  };

  const getBlastsCalendarData = async () => {
    const data = await getBlastsCalendarDataApi({ userId: user.Id });
    if (data.status === "ok") {
      const res = data.response;
      setCustomColor(res?.custom_color);
      setCalendar(res?.calender);
    }
  };

  const cancelBlast = async () => {
    const data = await cancelBlastApi({
      userId: user.Id,
      blast_id: selectedBlast,
    });
    if (data.status === "ok") {
      getBlastSummary();
    }
  };

  return (
    <main id="funnels-page" className="container py-4 position-relative">
      <div className="d-flex align-items-center">
        <div className="section-title">
          <SlRocket className="icon me-2" />
          Blasts
        </div>
      </div>
      {/*  */}
      <div className="row mt-4">
        <div className="col col-sm-4">
          <div className="col-wrap bg-white">
            <div className="px-3 pt-3">
              <div className="row">
                <div className="col-6 col-sm-4 col-md-3">
                  <Link
                    className="btn btn-sm btn-primary d-inline-flex align-items-center"
                    to={`/blasts/new`}
                  >
                    <BsPlusLg className="me-1" />
                    New
                  </Link>
                </div>
                <div className="col-6 col-sm-4 col-md-3 ms-auto">
                  <select
                    className="form-control"
                    value={filterParams.status}
                    onChange={(e) =>
                      blastFilter({ ...filterParams, status: e.target.value })
                    }
                  >
                    <option value="0">All</option>
                    <option value="1">Sent</option>
                    <option value="2">Scheduled</option>
                  </select>
                </div>
              </div>
            </div>
            <hr />
            <div className="filter px-3 pb-3">
              <DropdownMultiSelect
                className="w-100"
                options={clientlist.filter((item) => !!item.Name)}
                placeholder={"Select Clients"}
                labelField="Name"
                valueField="Id"
                values={clientParams.clientArr}
                onChange={(values) =>
                  setClientParams({
                    ...clientParams,
                    clientArr: values,
                    client: values.map((item) => item.Id).toString(),
                  })
                }
                onDropdownClose={(values) =>
                  blastFilter({ ...filterParams, client: values })
                }
              />
              <DateRangePicker
                initialSettings={{
                  startDate: moment(filterParams.startdate).toDate(),
                  endDate: moment(filterParams.enddate).toDate(),
                  ranges: {
                    Today: [moment().toDate(), moment().toDate()],
                    Yesterday: [
                      moment().subtract(1, "days").toDate(),
                      moment().subtract(1, "days").toDate(),
                    ],
                    "Last 7 Days": [
                      moment().subtract(6, "days").toDate(),
                      moment().toDate(),
                    ],
                    "Last 30 Days": [
                      moment().subtract(29, "days").toDate(),
                      moment().toDate(),
                    ],
                    "This Month": [
                      moment().startOf("month").toDate(),
                      moment().endOf("month").toDate(),
                    ],
                    "Last Month": [
                      moment().subtract(1, "month").startOf("month").toDate(),
                      moment().subtract(1, "month").endOf("month").toDate(),
                    ],
                  },
                }}
                onCallback={handleCallback}
              >
                <button
                  type="button"
                  className="btn form-daterangepicker w-100 mt-3"
                >
                  {filterParams.datelabel}
                </button>
              </DateRangePicker>
            </div>
            <div className="blast-bottom">
              <ul className="blast-tabs">
                <li
                  className={`tab-item${viewMode === "list" ? " active" : ""}`}
                  onClick={() => onClickViewMode("list")}
                >
                  List
                </li>
                <li
                  className={`tab-item${
                    viewMode === "calendar" ? " active" : ""
                  }`}
                  onClick={() => onClickViewMode("calendar")}
                >
                  Calendar
                </li>
              </ul>
              <div className="tab-content">
                <ul className="blast-list">
                  {info && info.length > 0 ? (
                    info.map((item, index) => (
                      <li
                        className={`blast-list-item${
                          selectedBlast === item.Id ? " active" : ""
                        }`}
                        key={index}
                        onClick={() => setSelectedBlast(item.Id)}
                      >
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="blast-title">
                            {item.Name}
                            <span className="client-name">
                              {item.clientname}
                            </span>
                          </div>
                          <div className="blast-status">
                            {Number(item.SendStatus) === 1 ? (
                              <>
                                <BsCheckLg className="me-1" />
                                {item.dateshow_f}
                              </>
                            ) : Number(item.SendStatus) === 2 ? (
                              <>
                                <BsCalendar3 className="me-1" />
                                {item.dateshow_f}
                              </>
                            ) : Number(item.SendStatus) === 4 ? (
                              <>
                                <IoCloseCircleOutline className="me-1" />
                                Canceled
                              </>
                            ) : null}
                          </div>
                        </div>
                        <div className="blast-content">{item.Message}</div>
                        <div className="blast-analytic">
                          <span>Total Sent: {item.Sent}</span> |{" "}
                          <span>Unsubscribes: {item.Unsubscribes}</span> |{" "}
                          <span>Total Replied: {item.Replied}</span>
                        </div>
                      </li>
                    ))
                  ) : (
                    <li className="blast-list-item w-100 p-3 d-flex align-items-center">
                      <span className="fw-bold">No Element</span>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="col col-sm-8 bg-white py-3">
          {summaryData ? (
            <div className="blasts">
              {Number(summaryData?.status.SendStatus) === 0 ||
              Number(summaryData?.status.SendStatus) === 3 ? (
                <div className="msg msg-processing">
                  <div id="circularG" className="me-2">
                    <div id="circularG_1" className="circularG"></div>
                    <div id="circularG_2" className="circularG"></div>
                    <div id="circularG_3" className="circularG"></div>
                    <div id="circularG_4" className="circularG"></div>
                    <div id="circularG_5" className="circularG"></div>
                    <div id="circularG_6" className="circularG"></div>
                    <div id="circularG_7" className="circularG"></div>
                    <div id="circularG_8" className="circularG"></div>
                  </div>
                  Processing
                </div>
              ) : Number(summaryData?.status.SendStatus) === 1 ? (
                <div className="msg">
                  <BsCheckLg className="me-2" />
                  Sent on Oct 9, 2:51 pm
                </div>
              ) : Number(summaryData?.status.SendStatus) === 2 ? (
                <div
                  id="canceltoggle_1"
                  className="msg msg-scheduled d-flex align-items-center"
                >
                  <BsCalendar3 className="me-2" />
                  <span id="blast_schedule_html">
                    Scheduled on {summaryData?.status.mkscheduledate_f}
                  </span>
                  <div className="dropdown ms-auto">
                    <Dropdown className="blast-schedule-dropdown">
                      <Dropdown.Toggle variant="">
                        <BsThreeDotsVertical color="#FFFFFF" />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => setReScheduleModal(true)}>
                          Edit
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => cancelBlast()}>
                          Cancel
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              ) : Number(summaryData?.status.SendStatus) === 4 ? (
                <div id="canceltoggle_2" className="msg msg-canceled">
                  <IoCloseCircleOutline className="me-2" />
                  <span id="blast_schedule_html">Canceled</span>
                </div>
              ) : null}
              <div className="blasts-heading">
                <h5 className="heading-title">{summaryData?.info.Name}</h5>
                <Link to={`/blasts/copyblast/${summaryData?.info.Id}`}>
                  <BiCopy />
                </Link>
              </div>
              <div className="blasts-content">
                <h6>
                  Sent to {summaryData?.status.sent} /{" "}
                  {summaryData?.status.total} Leads by
                  <img
                    className="leadsby_img mx-1"
                    width={18}
                    height={18}
                    src={summaryData?.profile}
                  />
                  {summaryData?.username}
                  <span>{summaryData?.info.filter}</span>
                </h6>
                <h6>Message</h6>
                <div className="message">
                  {summaryData?.info.Message}
                  {!!summaryData?.info.Media && (
                    <img
                      src={summaryData?.info.Media}
                      width="100px"
                      height="100px"
                    />
                  )}
                </div>
                <div className="row mt-3">
                  <div className="col-sm-7">
                    <h6>Analytics</h6>
                    <div className="row">
                      <div className="col-sm-4">
                        <div className="analytic-box">
                          <BsCheck2All className="box-icon" />
                          <div className="box-text">
                            <h6>{summaryData?.info.Sent}</h6>
                            <span>Total Sent</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="analytic-box">
                          <IoBanOutline className="box-icon" />
                          <div className="box-text">
                            <h6>{summaryData?.info.Unsubscribes}</h6>
                            <span>Unsubscribes</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="analytic-box">
                          <BsReplyAll className="box-icon" />
                          <div className="box-text">
                            <h6>{summaryData?.info.Replied}</h6>
                            <span>Total Replied</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="analytic-chart">
                      <h6>Delivery rate: {summaryData?.info.p1}%</h6>
                      <div className="progress">
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{ width: `${summaryData?.info.p1}%` }}
                        >
                          <span className="sr-only">
                            {summaryData?.info.p1}%
                          </span>
                        </div>
                      </div>
                      <div className="row analytic-chart-bottom">
                        <div className="col-sm-8 text-primary">Undelivered</div>
                        <div className="col-sm-4 text-end">
                          {summaryData?.info.undeleivery}{" "}
                          <span className="text-primary">
                            {summaryData?.info.p2}%
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {Number(summaryData?.info.IsaffirmativeResponse) === 1 && (
                    <div className="col-sm-5">
                      <label htmlFor="chart-response">
                        Affirmative vs Negative
                      </label>
                      <div
                        id="chart-response"
                        style={{
                          width: "100%",
                          height: "150px",
                          margin: "0 auto",
                        }}
                      ></div>
                    </div>
                  )}
                </div>
                <div id="ajaxsummaryleadscontainer">
                  <h6>Leads</h6>
                  <BlastLeadsDataTable summaryData={summaryData} />
                </div>
              </div>
            </div>
          ) : viewMode === "calendar" ? (
            <BlastsCalendarView
              data={calendar}
              onClickEvent={(blastId) => setSelectedBlast(blastId)}
            />
          ) : null}
        </div>
      </div>
      {/*  */}
      <BlastReScheduleMessageModal
        summaryData={summaryData}
        openModal={reScheduleModal}
        closeModal={() => setReScheduleModal(false)}
        reloadData={() => getBlastSummary()}
      />
    </main>
  );
}
