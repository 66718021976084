import Modal from "react-modal";
import React from "react";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "560px",
    padding: "15px",
    marginTop: "20px",
    marginBottom: "20px",
  },
};

Modal.setAppElement("#root");

export default function BlastConfirmSendModal({
  openModal = "",
  closeModal,
  onSend,
}) {
  const afterOpenModal = () => {};

  return (
    <Modal
      isOpen={!!openModal}
      onAfterOpen={() => afterOpenModal()}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Confirm Send Modal"
    >
      <div className="modal-header">
        <h4>Confirm Send</h4>
      </div>
      <div className="modal-body">
        <form>
          <div className="row mt-3">
            <div className="col-12">
              <div className="text-center">
                <h6>
                  This message could take up to{" "}
                  <strong id="sendtime">55:55</strong> to deploy.
                </h6>
                <p className="mb-4">Are you sure you want to continue?</p>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="modal-footer mt-4">
        <button className="btn btn-outline-secondary" onClick={closeModal}>
          Cancel
        </button>
        <button className="btn btn-primary ms-2" onClick={() => onSend()}>
          Send Now
        </button>
      </div>
    </Modal>
  );
}
