import React, { useContext, useState } from "react";
import Modal from "react-modal";
import { AuthContext } from "../../context";
import { useEffect } from "react";
import ScheduleTimeSelect from "../select/schedule_time_select";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "560px",
    padding: "15px",
    marginTop: "20px",
    marginBottom: "20px",
    overflow: "visible",
  },
};

Modal.setAppElement("#root");

export default function FunnelEditDelayModal({
  initialData = undefined,
  data = null,
  isOpenModal = false,
  afterOpenModal,
  closeModal,
  onSubmit,
}) {
  const { user } = useContext(AuthContext);
  const [postData, setPostData] = useState({
    userId: user.Id,
    message: "",
    delaycategory: "",
    delaycategoryObj: { value: "", label: "- : -" },
    delaytime: "",
    funnelid: "",
    index: "",
  });
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (data) {
      let _postData = { ...postData };
      _postData.userId = user.Id;
      _postData.message = data?.info.Id;
      _postData.funnelid = data?.info.Funnelid;
      _postData.delaytime = data?.info.Delaycount;
      if (data?.info.Delaycategory) {
        _postData.delaycategory = data?.info.Delaycategory;
        _postData.delaycategoryObj = {
          value: data?.info.Delaycategory,
          label: data?.info.Delaycategory,
        };
      }
      setPostData(_postData);
    }
  }, [data]);

  useEffect(() => {
    if (initialData) {
      let _postData = { ...postData };
      _postData.userId = user.Id;
      _postData.message = initialData?.message;
      _postData.delaytime = initialData?.delaytime;
      _postData.delaycategory = initialData?.delaycategory;
      _postData.delaycategoryObj = initialData?.delaycategoryObj;
      _postData.index = initialData?.index;
      setPostData(_postData);
    }
  }, [initialData]);

  const onSave = async () => {
    if (!postData.delaycategory || !postData.delaytime) {
      setShowError(true);
      return;
    }
    onSubmit(postData);
  };

  return (
    <Modal
      isOpen={isOpenModal}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Edit Modal"
    >
      <div className="modal-header">
        <h4 className="d-flex align-items-center">Edit Delay</h4>
      </div>
      <div className="modal-body">
        <div className="row mt-4">
          <div className="col-6">
            <div className="form-group mb-2">
              <input
                type="number"
                className="form-control"
                value={postData.delaytime}
                onChange={(e) =>
                  setPostData({ ...postData, delaytime: e.target.value })
                }
              />
            </div>
          </div>
          <div className="col-6">
            <div className="form-group mb-2">
              <ScheduleTimeSelect
                value={postData?.delaycategoryObj}
                onChange={(e) => {
                  setPostData({
                    ...postData,
                    delaycategoryObj: e,
                    delaycategory: e.value,
                  });
                }}
              />
            </div>
          </div>
          {showError && (!postData.delaytime || !postData.delaycategory) && (
            <div className="col-12">
              <span className="text-danger">Please add delay.</span>
            </div>
          )}
        </div>
      </div>
      <div className="modal-footer mt-3">
        <button className="btn btn-outline-secondary me-2" onClick={closeModal}>
          Cancel
        </button>
        <button className="btn btn-primary" onClick={() => onSave()}>
          Save Delay
        </button>
      </div>
    </Modal>
  );
}
