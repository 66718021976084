import React, { useContext } from "react";
import Modal from "react-modal";
import { AuthContext, CampaignContext } from "../../context";
import { BsQuestionCircle } from "react-icons/bs";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "560px",
    padding: "15px",
    marginTop: "20px",
    marginBottom: "20px",
  },
};

Modal.setAppElement("#root");

export default function ClientRemoveCampaignModal({
  cmpid = "",
  isSuperAdmin,
  isOpenModal = false,
  afterOpenModal,
  closeModal,
  reloadData,
}) {
  const { user } = useContext(AuthContext);
  const { deleteCampaignApi } = useContext(CampaignContext);

  const onDelete = async () => {
    if (!cmpid) {
      return;
    }
    if (!isSuperAdmin) {
      return;
    }
    const res = await deleteCampaignApi({ userId: user.Id, cmpid: cmpid });
    if (res.status === "ok") {
      reloadData();
    }
  };

  return (
    <Modal
      isOpen={isOpenModal}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel={"Remove Confirm"}
    >
      <div className="modal-header"></div>
      {isSuperAdmin ? (
        <>
          <div className="modal-body">
            <div className="d-flex flex-column align-items-center justify-content-center">
              <BsQuestionCircle size={52} color="#e57373" className="mt-3" />
              <h5 className="mt-3">Are you sure?</h5>
              <p>Are you sure you want to delete this Campaign?</p>
            </div>
          </div>
          <div className="modal-footer mt-3">
            <button
              className="btn btn-outline-secondary me-2"
              onClick={closeModal}
            >
              Cancel
            </button>
            <button className="btn btn-primary" onClick={() => onDelete()}>
              OK
            </button>
          </div>
        </>
      ) : (
        <>
          <div className="modal-body">
            <div className="d-flex flex-column align-items-center justify-content-center pt-4">
              <p>Please contact administration to remove your campaign.</p>
            </div>
          </div>
          <div className="modal-footer mt-3">
            <button className="btn btn-primary" onClick={closeModal}>
              OK
            </button>
          </div>
        </>
      )}
    </Modal>
  );
}
