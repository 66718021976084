import React from "react";

export default function ClientPhoneRegistrationTab() {
  return (
    <>
      <h6 className="tab-tile">Phone Registration</h6>
      <div className="row mt-4">
        <div className="col col-sm-12">
          <div className="form-group">
            <label className="mb-2">Legal Business Name</label>
            <input type="text" className="form-control" />
          </div>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col col-sm-12">
          <div className="form-group">
            <label className="mb-2">Business Fein</label>
            <input type="text" className="form-control" />
          </div>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col col-sm-12">
          <div className="d-flex align-items-center">
            <input type="checkbox" className="me-2" />
            My Registered Business Address Is Different Than My Dealership
            Address
          </div>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col col-sm-6">
          <div className="form-group">
            <label className="mb-2">Authorized Rep</label>
            <input type="text" className="form-control" />
          </div>
        </div>
        <div className="col col-sm-6 mt-auto">
          <div className="form-group">
            <input type="text" className="form-control" />
          </div>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col col-sm-12">
          <div className="form-group">
            <label className="mb-2">Email Address</label>
            <input type="text" className="form-control" />
          </div>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col col-sm-12">
          <div className="form-group">
            <label className="mb-2">Phone Number</label>
            <input type="text" className="form-control" />
          </div>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col col-sm-12">
          <div className="form-group">
            <label className="mb-2">Business Title</label>
            <input type="text" className="form-control" />
          </div>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col col-sm-12">
          <div className="form-group">
            <label className="mb-2">Business Type</label>
            <select className="form-select">
              <option>select</option>
            </select>
          </div>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col col-sm-12">
          <div className="form-group">
            <label className="mb-2">Job Position</label>
            <select className="form-select">
              <option>select</option>
            </select>
          </div>
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-12 d-flex">
          <button className="btn btn-primary ms-auto">Submit to Twilio</button>
          <button className="btn btn-primary ms-2 me-auto">Cancel</button>
        </div>
      </div>
    </>
  );
}
