import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { HiGif } from "react-icons/hi2";

export default function GifPickerInput({ onSelect }) {
  const wrapperRef = useRef(null);
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        // alert("You clicked outside of me!");
        setOpen(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef, setOpen]);

  useEffect(() => {
    loadGifData();
  }, [open]);

  const loadGifData = async () => {
    let url = `https://api.giphy.com/v1/gifs/
    trending?api_key=L63O2YqRMgK4KE7IeEQuD5hiZ6uUYtqS&limit=15&rating=G`;
    const result = await axios.get(url);
    console.log(">>> gif data >>>", result.data.data);
    if (result.status === 200) {
      const gifs = result.data.data;
      setData(gifs);
    }
  };

  const searchGif = async (keyward) => {
    let url = `https://api.giphy.com/v1/gifs/
    search?q=${keyward}&api_key=L63O2YqRMgK4KE7IeEQuD5hiZ6uUYtqS&limit=15`;
    const result = await axios.get(url);
    if (result.status === 200) {
      const gifs = result.data.data;
      setData(gifs);
    }
  };

  const onSelectGif = (gif, giphykey, giphysize) => {
    console.log(">>> selected gif >>>", gif);
    console.log(">>> selected giphykey >>>", giphykey);
    console.log(">>> selected giphysize >>>", giphysize);
    let gifHtml = `<img src="${gif}" alt="" />`;
    onSelect(gifHtml, giphykey, giphysize);
  };

  return (
    <div className="gifpicker" ref={wrapperRef}>
      <HiGif size={18} className="me-1" onClick={() => setOpen(!open)} />
      {open && (
        <div className="gif-dropdown p-2">
          <div className="row mx-0">
            <div className="col-12 p-1">
              <input
                type="text"
                className="form-control"
                onKeyUp={(e) => searchGif(e.target.value)}
              />
            </div>
          </div>
          <div className="row mx-0">
            {data &&
              data.map((item, index) => (
                <div className="col-4 p-1" key={index}>
                  <img
                    className="w-100 h-100"
                    src={item.images.preview_gif.url}
                    alt=""
                    key={index}
                    onClick={() =>
                      onSelectGif(
                        item.images.preview_gif.url,
                        item.id,
                        item.images.original.size,
                      )
                    }
                  />
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
}
