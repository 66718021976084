import React, { useState } from "react";
import {
  AgentPerformanceSection,
  FunnelsMetricsBoard,
  AgentResponseSection,
  LeadSourcesSection,
  MessagesMetricsBoard,
  MonthLeaderBoard,
  DealerShipMultiSelect,
} from "../../components";
import { Container, Grid, Stack, Typography } from "@mui/material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { ColorsConfig } from "../../config";

export default function DashboardPage() {
  const [selectedDealershipIds, setSelectedDealershipIds] = useState("");

  return (
    <Container maxWidth="xl" sx={{ mt: 2 }}>
      <Stack
        direction={{ xs: "column", md: "row" }}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Stack
          direction={"row"}
          justifyContent={"start"}
          alignItems={"center"}
          spacing={0.5}
        >
          <DashboardIcon
            sx={{
              fontSize: "26",
              color: `${ColorsConfig.light.primaryColor}`,
            }}
          />
          <Typography variant="body1">Dashboard</Typography>
        </Stack>
        <DealerShipMultiSelect
          onChange={(value) => setSelectedDealershipIds(value)}
        />
      </Stack>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12} md={7}>
          <AgentResponseSection selectedDealershipIds={selectedDealershipIds} />
        </Grid>
        <Grid item xs={12} md={5}>
          <LeadSourcesSection selectedDealershipIds={selectedDealershipIds} />
        </Grid>
        <Grid item xs={12} md={5}>
          <AgentPerformanceSection
            selectedDealershipIds={selectedDealershipIds}
          />
        </Grid>
        <Grid item xs={12} md={7}>
          <MonthLeaderBoard selectedDealershipIds={selectedDealershipIds} />
        </Grid>
        <Grid item xs={12} md={7}>
          <MessagesMetricsBoard selectedDealershipIds={selectedDealershipIds} />
        </Grid>
        <Grid item xs={12} md={5}>
          <FunnelsMetricsBoard selectedDealershipIds={selectedDealershipIds} />
        </Grid>
      </Grid>
    </Container>
  );
}
