import Modal from "react-modal";
import React, { useContext, useState } from "react";
import { AuthContext, BlastContext } from "../../context";
import ScheduleTimeSelect from "../select/schedule_time_select";
import { BsExclamationTriangleFill } from "react-icons/bs";
import { Switch } from "@mui/material";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "560px",
    padding: "15px",
    marginTop: "20px",
    marginBottom: "20px",
  },
};

Modal.setAppElement("#root");

export default function BlastScheduleMessageModal({
  openModal = "",
  closeModal,
  onScheduleMessage,
  issplit,
  leadcount,
}) {
  const { user } = useContext(AuthContext);
  const { checkvaliddatetimeApi } = useContext(BlastContext);
  const [postData, setPostData] = useState({
    userId: user.Id,
    date: "",
    time_obj: { value: "", label: "- : -" },
    time: "",
    exclude_sunday: 0,
    daily_drops: 0,
    per_day: "",
  });
  const [showError, setShowError] = useState(false);
  const [showError2, setShowError2] = useState(false);

  const onSubmit = async () => {
    console.log(issplit, leadcount);
    const data = await checkvaliddatetimeApi(postData);
    if (data.status === "ok") {
      const res = data.response;
      if (res.valid) {
        setShowError(false);
        let perdaylead = parseInt(postData.per_day);
        let issplit = parseInt(postData.issplit);
        let leadcount = parseInt(postData.leadcount);
        if (issplit === 1 && (perdaylead === 0 || perdaylead > leadcount)) {
          setShowError2(true);
        } else {
          setShowError2(false);
          onScheduleMessage(postData);
        }
      } else {
        setShowError(true);
      }
    }
  };

  const afterOpenModal = () => {};

  return (
    <Modal
      isOpen={!!openModal}
      onAfterOpen={() => afterOpenModal()}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Schedule Message Modal"
    >
      <div className="modal-header">
        <h4>Schedule Message</h4>
      </div>
      <div className="modal-body">
        <form>
          <div className="row mt-3">
            <div className="col-12">
              <div className="">
                <BsExclamationTriangleFill className="me-1" color="#ff9800" />
                Your message will be sent according to US/Eastern Time Zone.
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-6">
              <div className="form-group mt-3">
                <input
                  type="date"
                  className="form-control"
                  value={postData.date}
                  onChange={(e) =>
                    setPostData({ ...postData, date: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group mt-3">
                <ScheduleTimeSelect
                  value={postData?.time_obj}
                  onChange={(e) => {
                    setPostData({
                      ...postData,
                      time_obj: e,
                      time: e.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>
          {showError && (
            <div className="text-danger">Valid date/time required</div>
          )}
          <div className="row mt-2">
            <div className="col-12">
              <div className="form-group">
                <label className="mb-2 w-100">Exclude Sunday</label>
                <Switch
                  checked={postData.exclude_sunday === 1 ? true : false}
                  onChange={() =>
                    setPostData({
                      ...postData,
                      exclude_sunday: postData.exclude_sunday === 1 ? 0 : 1,
                    })
                  }
                />
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-12">
              <div className="form-group">
                <label className="mb-2 w-100">Daily Drops</label>
                <Switch
                  checked={postData.daily_drops === 1 ? true : false}
                  onChange={() =>
                    setPostData({
                      ...postData,
                      daily_drops: postData.daily_drops === 1 ? 0 : 1,
                    })
                  }
                />
              </div>
            </div>
          </div>
          {postData.daily_drops === 1 && (
            <div className="row mt-2">
              <div className="col-12 col-sm-6">
                <div className="form-group">
                  <label className="mb-2 w-100">Per Day</label>
                  <input
                    type="number"
                    className="form-control"
                    value={postData.per_day}
                    onChange={(e) =>
                      setPostData({ ...postData, per_day: e.target.value })
                    }
                  />
                </div>
              </div>
            </div>
          )}
          {showError2 && (
            <div className="text-danger">Valid Per day lead required</div>
          )}
        </form>
      </div>
      <div className="modal-footer mt-4">
        <button className="btn btn-outline-secondary" onClick={closeModal}>
          Cancel
        </button>
        <button className="btn btn-primary ms-2" onClick={() => onSubmit()}>
          Schedule Message
        </button>
      </div>
    </Modal>
  );
}
