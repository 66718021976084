import React, { useState, useContext, useEffect } from "react";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { PhoneInput } from "..";
import { OtherContext } from "../../context";
import { ColorsConfig } from "../../config";

export default function ActionsPhoneLookupModal(props) {
  const { open, handleCloseModal } = props;
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: {
      xs: "90%",
      md: "35%",
    },
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 2,
    p: 2,
  };
  const { getPhoneLookInfo } = useContext(OtherContext);
  const [value, setValue] = useState(null);
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [count, setCount] = useState(0);

  useEffect(() => {
    setValue(null);
    setCount(0);
    setItems([]);
  }, []);

  const handleChange = (e) => {
    const v = e.target.value;
    setValue(v);
    if (v) {
      setShowError(false);
    } else {
      setShowError(true);
    }
  };

  const handleSearch = async () => {
    setItems([]);
    setCount(0);
    if (value == null) {
      setShowError(true);
    } else {
      setShowError(false);
      setLoading(true);
      const result = await getPhoneLookInfo(value);
      setLoading(false);
      setCount(1);
      setItems(result.leads);
    }
  };

  const clickItem = (item) => {
    console.log(item);
  };

  const renderItem = (item, index) => {
    return (
      <Box
        key={index}
        sx={{
          cursor: "pointer",
        }}
        onClick={() => clickItem(item)}
      >
        <Stack
          direction={"row"}
          justifyContent={"start"}
          alignItems={"center"}
          spacing={1}
          sx={{
            borderBottom: `1px solid ${ColorsConfig.light.primaryColor}`,
            pt: 0.5,
            pb: 0.5,
            pl: 1,
          }}
        >
          <Avatar src={item?.Mediaimage} sx={{ width: 36, height: 36 }} />
          <Box>
            <Typography variant="subtitle2">{item?.pname}</Typography>
            <Typography variant="caption">{item?.client_name}</Typography>
          </Box>
        </Stack>
      </Box>
    );
  };

  const renderItems = () => {
    return (
      <Box sx={{ m: 1, mt: 3, overflowY: "scroll", maxHeight: 350 }}>
        {items?.length > 0 ? (
          <Box>{items.map((item, index) => renderItem(item, index))}</Box>
        ) : (
          <Typography variant="body2">No Found</Typography>
        )}
      </Box>
    );
  };

  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="actions_phone_lookup_modal"
    >
      <Box sx={style}>
        <Stack
          direction={"row"}
          spacing={1}
          justifyContent={"start"}
          alignItems={"center"}
        >
          <SearchIcon fontSize="medium" color="inherit" />
          <Typography variant="h6">Enter Phone</Typography>
        </Stack>
        <Divider />
        <Box
          sx={{
            p: 3,
          }}
        >
          <PhoneInput
            value={value}
            handleChange={handleChange}
            placeholder="Input Phone Number"
          />
          {showError && (
            <Typography variant="caption" color="red" sx={{ ml: 1 }}>
              Required Phone Number
            </Typography>
          )}
          {count > 0 && renderItems()}
        </Box>
        <Divider />
        <Stack
          direction={"row"}
          justifyContent={"end"}
          alignItems={"center"}
          spacing={2}
          mt={4}
        >
          <Button
            variant="contained"
            color="info"
            onClick={handleSearch}
            disabled={loading}
          >
            Search
          </Button>
          <Button color="inherit" onClick={handleCloseModal}>
            Cancel
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
}
