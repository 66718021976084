import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { LiaCopy } from "react-icons/lia";
import { AuthContext, ClientContext } from "../../context";
import config from "../../config/config";
import PlFileUploader from "../Uploader/PlFileUploader";

export default function ClientSMSWidgetTab({ clientid }) {
  const { user } = useContext(AuthContext);
  const { getChatboxApi, setsmswidgetApi } = useContext(ClientContext);
  //
  const [Twilionumber, setTwilionumber] = useState("");
  const [keyid, setKeyid] = useState("");
  const [widget, setWidget] = useState(null);
  const [postData, setPostData] = useState({
    Agent: "",
    Image: "",
    Q1: "",
    Q2: "",
    Q3: "",
    Q4: "",
    A1: "",
    A2: "",
    A3: "",
    A4: "",
    A5: "",
    Color: "",
    position: "",
    Client: "",
  });
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    getChatbox(clientid);
  }, [clientid]);

  const getChatbox = async (cid) => {
    const data = await getChatboxApi({ userId: user.Id, cid: cid });
    if (data.status === "ok") {
      const res = data.response;
      setTwilionumber(res?.client?.Twilionumber);
      setKeyid(res?.keyid);
      setWidget(res?.widget);
      setPostData({
        Agent: res?.widget?.Agent,
        Image: res?.widget?.Image,
        Q1: res?.widget?.Q1,
        Q2: res?.widget?.Q2,
        Q3: res?.widget?.Q3,
        Q4: res?.widget?.Q4,
        A1: res?.widget?.A1,
        A2: res?.widget?.A2,
        A3: res?.widget?.A3,
        A4: res?.widget?.A4,
        A5: res?.widget?.A5,
        Color: res?.widget?.Color,
        position: res?.widget?.position,
        Client: res?.cid,
      });
    }
  };

  const onSave = async () => {
    if (
      !postData.Agent ||
      !postData.Image ||
      !postData.Q1 ||
      !postData.Q2 ||
      !postData.Q3 ||
      !postData.Q4 ||
      !postData.A1 ||
      !postData.A2 ||
      !postData.A3 ||
      !postData.A4 ||
      !postData.A5 ||
      !postData.Color ||
      !postData.position ||
      !postData.Client
    ) {
      setShowError(true);
    } else {
      setShowError(false);
      const data = await setsmswidgetApi(postData);
      if (data.status === "ok") {
        getChatbox(clientid);
      }
    }
  };

  return (
    <>
      <h6 className="tab-tile">SMS Widget</h6>
      {!Twilionumber ? (
        <div className="alert alert-warning w-100">
          Please add phone number in Lead Sources to access this section
        </div>
      ) : (
        <>
          <div className="row mt-2">
            <div className="col col-sm-6">
              <div className="form-group">
                <label className="mb-2">Agent Name</label>
                <input
                  type="text"
                  className="form-control"
                  value={postData.Agent}
                  onChange={(e) =>
                    setPostData({ ...postData, Agent: e.target.value })
                  }
                />
                {showError && !postData.Agent && (
                  <small className="text-danger">Agent Name is required!</small>
                )}
              </div>
            </div>
            <div className="col col-sm-6">
              <div className="form-group">
                <label className="mb-2">Agent Image</label>
                {postData.Image && (
                  <img
                    id="agentimage"
                    src={`${config.baseUrl}/${postData.Image}`}
                    width="80px"
                    height="80px"
                  />
                )}
                <PlFileUploader
                  url={`${config.ajaxPath}/imageupload/1`}
                  browseButtonId={`Logo_logo`}
                  extensions={"jpg,gif,png"}
                  onSuccess={(up, file, response) =>
                    setPostData({ ...postData, Image: response.filename })
                  }
                  onError={() => {}}
                />
                {showError && !postData.Image && (
                  <small className="text-danger">
                    Agent Image is required!
                  </small>
                )}
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col col-sm-6">
              <div className="form-group">
                <label className="mb-2">Primary Color</label>
                <div className="colorpicker">
                  <input
                    type="color"
                    className="me-1"
                    value={postData?.Color}
                    onChange={(e) =>
                      setPostData({ ...postData, Color: e.target.value })
                    }
                  />
                  {postData?.Color}
                </div>
                {showError && !postData.Color && (
                  <small className="text-danger">
                    Primary Color is required!
                  </small>
                )}
              </div>
            </div>
            <div className="col col-sm-6">
              <div className="form-group">
                <label className="mb-2">Position</label>
                <select
                  className="form-select"
                  value={postData?.position}
                  onChange={(e) =>
                    setPostData({ ...postData, position: e.target.value })
                  }
                >
                  <option value="right">Right</option>
                  <option value="left">Left</option>
                </select>
              </div>
            </div>
            <div className="col col-sm-6">
              <div className="form-group">
                <label className="mb-2">Question 1</label>
                <input
                  type="text"
                  className="form-control"
                  value={postData?.Q1}
                  onChange={(e) =>
                    setPostData({ ...postData, Q1: e.target.value })
                  }
                />
                {showError && !postData.Q1 && (
                  <small className="text-danger">Question 1 is required!</small>
                )}
              </div>
            </div>
            <div className="col col-sm-6">
              <div className="form-group">
                <label className="mb-2">Response 1</label>
                <input
                  type="text"
                  className="form-control"
                  value={postData?.A1}
                  onChange={(e) =>
                    setPostData({ ...postData, A1: e.target.value })
                  }
                />
                {showError && !postData.A1 && (
                  <small className="text-danger">Response 1 is required!</small>
                )}
              </div>
            </div>
            <div className="col col-sm-6">
              <div className="form-group">
                <label className="mb-2">Question 2</label>
                <input
                  type="text"
                  className="form-control"
                  value={postData?.Q2}
                  onChange={(e) =>
                    setPostData({ ...postData, Q2: e.target.value })
                  }
                />
                {showError && !postData.Q2 && (
                  <small className="text-danger">Question 2 is required!</small>
                )}
              </div>
            </div>
            <div className="col col-sm-6">
              <div className="form-group">
                <label className="mb-2">Response 2</label>
                <input
                  type="text"
                  className="form-control"
                  value={postData?.A2}
                  onChange={(e) =>
                    setPostData({ ...postData, A2: e.target.value })
                  }
                />
                {showError && !postData.A2 && (
                  <small className="text-danger">Response 2 is required!</small>
                )}
              </div>
            </div>
            <div className="col col-sm-6">
              <div className="form-group">
                <label className="mb-2">Question 3</label>
                <input
                  type="text"
                  className="form-control"
                  value={postData?.Q3}
                  onChange={(e) =>
                    setPostData({ ...postData, Q3: e.target.value })
                  }
                />
                {showError && !postData.Q3 && (
                  <small className="text-danger">Question 3 is required!</small>
                )}
              </div>
            </div>
            <div className="col col-sm-6">
              <div className="form-group">
                <label className="mb-2">Response 3</label>
                <input
                  type="text"
                  className="form-control"
                  value={postData?.A3}
                  onChange={(e) =>
                    setPostData({ ...postData, A3: e.target.value })
                  }
                />
                {showError && !postData.A3 && (
                  <small className="text-danger">Response 3 is required!</small>
                )}
              </div>
            </div>
            <div className="col col-sm-6">
              <div className="form-group">
                <label className="mb-2">Question 4</label>
                <input
                  type="text"
                  className="form-control"
                  value={postData?.Q4}
                  onChange={(e) =>
                    setPostData({ ...postData, Q4: e.target.value })
                  }
                />
                {showError && !postData.Q4 && (
                  <small className="text-danger">Question 4 is required!</small>
                )}
              </div>
            </div>
            <div className="col col-sm-6">
              <div className="form-group">
                <label className="mb-2">Response 4</label>
                <input
                  type="text"
                  className="form-control"
                  value={postData?.A4}
                  onChange={(e) =>
                    setPostData({ ...postData, A4: e.target.value })
                  }
                />
                {showError && !postData.A4 && (
                  <small className="text-danger">Response 4 is required!</small>
                )}
              </div>
            </div>
            <div className="col col-sm-12">
              <div className="form-group">
                <label className="mb-2">General Answer</label>
                <input
                  type="text"
                  className="form-control"
                  value={postData?.A5}
                  onChange={(e) =>
                    setPostData({ ...postData, A5: e.target.value })
                  }
                />
                {showError && !postData.A5 && (
                  <small className="text-danger">
                    General Answer is required!
                  </small>
                )}
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12 d-flex">
              <button
                type="button"
                className="btn btn-primary mx-auto"
                onClick={() => onSave()}
              >
                Save
              </button>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12">
              <div className="form-group">
                <label className="mb-2">Embed Code</label>
                <pre>
                  <div className="d-flex">
                    <button
                      type="button"
                      className="btn btn-sm btn-outline-secondary ms-auto"
                    >
                      <LiaCopy />
                      Copy
                    </button>
                  </div>
                  <code>
                    {`<script src="${
                      config.assetPath
                    }/javascripts/widget.js"></script><script>DFChatbox.create("${keyid}","${
                      widget?.Color ? widget?.Color : ""
                    }","${
                      widget?.position ? widget?.position : ""
                    }");</script>`}
                  </code>
                </pre>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
