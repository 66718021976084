import { Switch } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { BsEnvelopeOpen } from "react-icons/bs";
import {
  RxChevronDown,
  RxChevronLeft,
  RxChevronRight,
  RxChevronUp,
  RxDoubleArrowDown,
  RxDoubleArrowLeft,
  RxDoubleArrowRight,
  RxDoubleArrowUp,
} from "react-icons/rx";
import helper from "../../../utils/helper";
import "react-dual-listbox/lib/react-dual-listbox.css";
import DualListBox from "react-dual-listbox";
import { AuthContext, UserContext } from "../../../context";

export default function UserEditFormAgency({
  userdata,
  manager,
  executive,
  sagent,
  usergroup,
  client,
  userType,
  agency,
  isagentchain,
  userAuthorizedNo,
  reloadData,
}) {
  const { user } = useContext(AuthContext);
  const { updateUserApi, sendnewuseremailApi } = useContext(UserContext);

  const [postData, setPostData] = useState({
    authUserId: user.Id,
    Type: "",
    Name: "",
    Email: "",
    Password: "",
    Department: "",
    officephone: "",
    mobilephone: "",
    defaultphone: "",
    Blast: false,
    manager: "",
    accountexecutive: "",
    Salesmenbarcode: false,
    private: "",
    agencies: [],
    // PrivateLabel: '',
    UserGroup: "",
    super_agent: false,
    NotificationtimeToggle: false,
    Notificationtime: "",
    clientlistbox: [],
    Issalesmen: 0,
    userId: "",
    Isblast: 0,
    // SuperAgent: '',
    Appointmentnotification: 0,
  });

  useEffect(() => {
    if (userdata) {
      let _postData = { ...postData };
      _postData.authUserId = user.Id;
      _postData.Type =
        Number(userdata?.Type) === 3
          ? 3
          : Number(userdata?.Type) === 5
          ? 5
          : Number(userdata?.Etype) === 3
          ? 6
          : Number(userdata?.Type) === 7
          ? 7
          : Number(userdata?.Type) === 8
          ? 8
          : "";
      _postData.Name = userdata?.Name;
      _postData.Email = userdata?.Email;
      _postData.Password = "";
      _postData.Department = userdata?.Department;
      _postData.officephone = userdata?.Office?.type1;
      _postData.mobilephone = userdata?.Mobile?.type1;
      _postData.defaultphone = !userdata?.DefaultPhone
        ? 1
        : Number(userdata?.DefaultPhone);
      _postData.Blast = false;
      _postData.manager = userdata?.ManagerId;
      _postData.accountexecutive = userdata?.accountexecutive;
      _postData.Salesmenbarcode = false;
      _postData.private = sagent;
      _postData.agencies = [];
      if (Number(isagentchain) === 1) {
        _postData.PrivateLabel = sagent;
      }
      _postData.UserGroup = userdata?.UserGroup;
      _postData.super_agent = false;
      _postData.NotificationtimeToggle = false;
      _postData.Notificationtime = userdata?.Notificationtime;
      _postData.clientlistbox = client
        ?.filter((el) => !!el.Sel)
        .map((item) => {
          return { value: `${item.Id}_${item.Ctype}`, label: item.Name };
        });
      _postData.Issalesmen = 0;
      _postData.userId = userdata?.Id;
      _postData.Isblast = 0;
      if (Number(userType) === 5) {
        _postData.SuperAgent = 0;
      }
      _postData.Appointmentnotification = 0;
      //
      setPostData(_postData);
    }
  }, [userdata]);

  const sendnewuseremail = async () => {
    const data = await sendnewuseremailApi({ userId: userdata?.Id });
    if (data.status === "ok") {
      alert("Email sent");
    }
  };

  const onSave = async () => {
    let params = { ...postData };
    params.clientlistbox = params.clientlistbox.map((item) => item.value);
    const data = await updateUserApi(params);
    if (data.status === "ok") {
      reloadData();
    }
  };

  return (
    <>
      <div className="d-flex align-items-center justify-content-between">
        <h6 className="tab-tile">User Info</h6>
        <button
          className="btn btn-sm btn-primary d-flex align-items-center"
          onClick={() => sendnewuseremail()}
        >
          <BsEnvelopeOpen className="me-1" />
          Send New User Email
        </button>
      </div>
      <div className="row mt-2">
        <div className="col-12 col-sm-4">
          <div className="form-group">
            <label className="mb-2">Type</label>
            <select
              className="form-select"
              value={postData?.Type}
              onChange={(e) =>
                setPostData({ ...postData, Type: e.target.value })
              }
            >
              <option value="5">Agency Admin</option>
              <option value="6">Manager</option>
              <option value="3">Salesman</option>
              <option value="7">Agent</option>
              <option value="8">DF Manager</option>
            </select>
          </div>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-12 col-sm-4">
          <div className="form-group">
            <label className="mb-2">Name</label>
            <input
              type="text"
              className="form-control"
              value={postData?.Name}
              onChange={(e) =>
                setPostData({ ...postData, Name: e.target.value })
              }
            />
          </div>
        </div>
        <div className="col-12 col-sm-4">
          <div className="form-group">
            <label className="mb-2">Email</label>
            <input
              type="text"
              className="form-control"
              disabled={!helper.loginTypecheck("ADMIN")}
              value={postData?.Email}
              onChange={(e) =>
                setPostData({ ...postData, Email: e.target.value })
              }
            />
          </div>
        </div>
        <div className="col-12 col-sm-4">
          <div className="form-group">
            <label className="mb-2">Password</label>
            <input
              type="text"
              className="form-control"
              value={postData?.Password}
              onChange={(e) =>
                setPostData({ ...postData, Password: e.target.value })
              }
            />
          </div>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-12 col-sm-4">
          <div className="form-group mb-2">
            <label className="mb-2">Department</label>
            <input
              type="text"
              className="form-control"
              value={postData?.Department}
              onChange={(e) =>
                setPostData({ ...postData, Department: e.target.value })
              }
            />
          </div>
        </div>
        <div className="col-12 col-sm-4">
          <div className="form-group mb-2">
            <label className="mb-2">Office Phone</label>
            <input
              type="text"
              className="form-control"
              value={postData?.officephone}
              onChange={(e) =>
                setPostData({ ...postData, officephone: e.target.value })
              }
            />
          </div>
        </div>
        <div className="col-12 col-sm-4">
          <div className="form-group mb-2">
            <label className="mb-2">Mobile Phone</label>
            <input
              type="text"
              className="form-control"
              value={postData?.mobilephone}
              onChange={(e) =>
                setPostData({ ...postData, mobilephone: e.target.value })
              }
            />
          </div>
        </div>
        <div className="col-12 col-sm-4">
          <div className="form-group mb-2">
            <label className="mb-2">Send Calls to</label>
            <select
              className="form-select"
              value={postData?.defaultphone}
              onChange={(e) =>
                setPostData({ ...postData, defaultphone: e.target.value })
              }
            >
              <option value="3">Mobile Phone</option>
              <option value="2">Office Phone</option>
              <option value="1">Main Line</option>
            </select>
          </div>
        </div>
        <div className="col-12 col-sm-4">
          <div className="form-group mb-2">
            <label className="mb-2 w-100">Blast</label>
            <div className="d-flex">
              <Switch
                value={postData?.Blast}
                onChange={() =>
                  setPostData({ ...postData, Blast: !postData.Blast })
                }
              />
            </div>
          </div>
        </div>
      </div>
      {!(Number(userdata?.Type) === 7 || Number(userdata?.Type) === 5) && (
        <div className="row">
          <div className="col-sm-4">
            <div className="form-group">
              <label className="mb-2">Account Manager</label>
              <select
                id="accountmanager"
                className="form-select"
                name="manager"
                value={postData?.manager}
                onChange={(e) =>
                  setPostData({ ...postData, manager: e.target.value })
                }
              >
                <option value="">Please select</option>
                {manager.map((item, index) => (
                  <option value={item.Id} key={index}>
                    {item.Name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      )}
      {!(Number(userdata?.Type) === 7 || Number(userdata?.Type) === 5) && (
        <div className="row mt-2">
          <div className="col-sm-4">
            <div className="form-group">
              <label className="mb-2">Account Executive</label>
              <select
                id="accountexecutive"
                className="form-select"
                name="accountexecutive"
                value={postData?.accountexecutive}
                onChange={(e) =>
                  setPostData({ ...postData, accountexecutive: e.target.value })
                }
              >
                <option value="">Please select</option>
                {executive.map((item, index) => (
                  <option value={item.Id} key={index}>
                    {item.Name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      )}
      <div className="row mt-2">
        {!(Number(userdata?.Type) === 7 || Number(userdata?.Type) === 5) && (
          <div className="col col-sm-4">
            <div className="form-group mb-2">
              <label className="mb-2 w-100">Salesmen Barcode</label>
              <Switch
                value={postData?.Salesmenbarcode}
                onChange={() =>
                  setPostData({
                    ...postData,
                    Salesmenbarcode: !postData?.Salesmenbarcode,
                  })
                }
              />
            </div>
          </div>
        )}
        {(Number(userdata?.Type) === 7 || Number(userdata?.Type) === 1) && (
          <div className="col col-sm-4">
            <div className="form-group mb-2">
              <label className="mb-2">Working Agencies</label>
              <select
                id="agencies"
                className="form-select"
                name="agencies"
                value={postData?.agencies}
                onChange={(e) =>
                  setPostData({ ...postData, agencies: e.target.value })
                }
              >
                <option value="">Please select</option>
                {agency.map((item, index) => (
                  <option value={item.Id} key={index}>
                    {item.Name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}
      </div>
      {Number(userdata?.Type) === 7 && (
        <div className="row">
          <div className="col-sm-4">
            <div className="form-group mb-2">
              <label className="mb-2">User Group</label>
              <select
                id="UserGroup"
                className="form-select"
                name="UserGroup"
                value={postData?.UserGroup}
                onChange={(e) =>
                  setPostData({ ...postData, UserGroup: e.target.value })
                }
              >
                <option value=""></option>
                {usergroup.map((item, index) => (
                  <option value={item.Id} key={index}>
                    {item.UserGroup}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {Number(userdata?.Type) === 5 && (
            <div className="col-sm-4">
              <div className="form-group mb-2">
                <label className="mb-2 w-100">Super Agent</label>
                <Switch
                  value={postData?.super_agent}
                  onChange={() =>
                    setPostData({
                      ...postData,
                      super_agent: !postData?.Salesmenbarcode,
                    })
                  }
                />
              </div>
            </div>
          )}
        </div>
      )}
      <div className="row">
        <div className="col-sm-4">
          <div className="form-group mb-2">
            <label className="mb-2 w-100">Appointment notification</label>
            <Switch
              value={postData?.Notificationtime}
              onChange={() =>
                setPostData({
                  ...postData,
                  Notificationtime: !postData?.Salesmenbarcode,
                })
              }
            />
            <select
              id="Notificationtime"
              className="form-select mt-2"
              name="Notificationtime"
              value={postData?.Notificationtime}
              onChange={(e) =>
                setPostData({ ...postData, Notificationtime: e.target.value })
              }
            >
              <option value="5">5 Minute</option>
              <option value="10">10 Minute</option>
              <option value="30">30 Minute</option>
              <option value="60">60 Minute</option>
            </select>
          </div>
        </div>
      </div>
      <hr />
      {Number(userAuthorizedNo) === 2 ? (
        <div className="client-list">
          <span>Authorized Clients List</span>
          <ul>
            {client.map((item, index) =>
              item.Sel ? <li key={index}>{item.Name}</li> : null,
            )}
          </ul>
        </div>
      ) : Number(userType) < 2 ||
        Number(userType) === 5 ||
        Number(userType) === 7 ? (
        <div className="client-list" id="client-list">
          <DualListBox
            canFilter
            options={
              client.map((item) => {
                return { label: item.Name, value: `${item.Id}_${item.Ctype}` };
              }) || []
            }
            simpleValue={false}
            selected={postData?.clientlistbox}
            onChange={(value) =>
              setPostData({ ...postData, clientlistbox: value })
            }
            icons={{
              moveLeft: <RxChevronLeft />,
              moveAllLeft: [
                <RxDoubleArrowLeft key={0} />,
                // <RxDoubleArrowLeft key={1} />,
              ],
              moveRight: <RxChevronRight />,
              moveAllRight: [
                <RxDoubleArrowRight key={0} />,
                // <RxDoubleArrowRight key={1} />,
              ],
              moveDown: <RxChevronDown />,
              moveUp: <RxChevronUp />,
              moveTop: <RxDoubleArrowUp />,
              moveBottom: <RxDoubleArrowDown />,
            }}
          />
        </div>
      ) : null}
      <hr className="mt-5" />
      <div className="row">
        <div className="col-12 d-flex align-items-center">
          <button
            className="btn btn-primary ms-auto me-1"
            onClick={() => onSave()}
          >
            Save
          </button>
          <button className="btn btn-outline-secondary ms-1 me-auto">
            Cancel
          </button>
        </div>
      </div>
    </>
  );
}
