import Modal from "react-modal";
import React, { useState, useEffect, useContext } from "react";
import { AuthContext, SharedContext, VideoContext } from "../../context";
import CopyToClipboard from "react-copy-to-clipboard";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "560px",
    padding: "15px",
    marginTop: "20px",
    marginBottom: "20px",
  },
};

Modal.setAppElement("#root");

export default function ConversationSendVideoModal({
  isOpenModal = false,
  afterOpenModal,
  closeModal,
  onCopyLink,
}) {
  const { dealerships } = useContext(SharedContext);
  const { user } = useContext(AuthContext);
  const { videos, getVideos, searchVideos } = useContext(VideoContext);
  const [videoType, setVideoType] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [selectedDealershipId, setSelectedDealershipId] = useState("All");
  const [searchKey, setSearchKey] = useState("");
  const [clipboardState, setClipboardState] = useState({
    value: "",
    copied: false,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const perPage = 9;

  useEffect(() => {
    getVideos({
      userId: user?.Id,
      selectedDealershipId: videoType === 2 ? selectedDealershipId : "",
      category: selectedCategory,
      type: videoType,
    });
    setCurrentPage(1);
  }, [user, videoType, selectedCategory]);

  const orderByLatest = (a, b) => {
    if (a.Create_date > b.Create_date) {
      return -1;
    }
    if (a.Create_date < b.Create_date) {
      return 1;
    }
    return 0;
  };

  const onCopy = (item) => {
    console.log("???", item);
    setClipboardState({ value: item?.Video_url, copied: true });
    onCopyLink(item?.Video_url, item?.Gif_url);
  };

  return (
    <Modal
      id="videosendmodal"
      isOpen={isOpenModal}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Select Video"
    >
      <div className="modal-header">
        <h4>Select Video</h4>
      </div>
      <div className="modal-body py-3">
        <ul className="nav nav-tabs">
          <li
            className={`nav-tab${videoType === 1 ? " active" : ""}`}
            onClick={() => setVideoType(1)}
          >
            My Videos
          </li>
          <li
            className={`nav-tab${videoType === 2 ? " active" : ""}`}
            onClick={() => setVideoType(2)}
          >
            Shared Videos
          </li>
        </ul>
        <div className="toolbar">
          <div className="row mt-2 pb-2">
            <div className="col-12 col-sm-6">
              <div className="form-control-wrapper w-100">
                <input
                  type="text"
                  className="form-control pe-5"
                  placeholder="Search"
                  value={searchKey}
                  onChange={(e) => setSearchKey(e.target.value)}
                />
                <button
                  className="btn right-button"
                  onClick={() =>
                    searchVideos({
                      userId: user?.Id,
                      searchKey: searchKey,
                      category: selectedCategory,
                      type: videoType,
                    })
                  }
                >
                  <SearchOutlinedIcon fontSize="small" />
                </button>
              </div>
            </div>
            <div className="col-12 col-sm-6">
              <select
                className="form-select w-100"
                onChange={(e) => setSelectedCategory(e.target.value)}
              >
                <option value="0">All</option>
                {/* <option value="0">No Category</option> */}
                <option value="1">Personal Videos</option>
                <option value="2">Sales</option>
                <option value="3">Service</option>
                <option value="4">Marketing</option>
                <option value="5">Finance</option>
              </select>
            </div>
          </div>
          {dealerships && videoType === 2 && (
            <div className="row pb-2">
              <div className="col-12 col-sm-6 ms-auto">
                <select
                  className="form-select w-100 ms-auto"
                  onChange={(e) => setSelectedDealershipId(e.target.value)}
                >
                  <option value={"All"}>All Dealerships</option>
                  {dealerships.map((item, index) => (
                    <option value={item.Id} key={index}>
                      {item.Name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          )}
        </div>
        <div className="videos">
          <div className="row w-100">
            {videos &&
              videos.sort(orderByLatest).map((item, index) =>
                index >= (currentPage - 1) * perPage &&
                index < currentPage * perPage ? (
                  <div className="col col-sm-4" key={index}>
                    <div className="video-card d-flex flex-column align-items-center">
                      <div className="video-title">{item?.Title}</div>
                      <div className="date-created">{item?.Create_date}</div>
                      <small className="">Sales</small>
                      <video
                        className="video"
                        poster={item.Thumnail_url}
                        controls
                      >
                        <source src={item.Video_url} type="video/mp4" />
                      </video>
                      <div className="actions mt-2">
                        <CopyToClipboard
                          text={item?.Video_url}
                          onCopy={() => onCopy(item)}
                        >
                          <button className="btn btn-sm btn-outline-secondary m-1">
                            {clipboardState.copied &&
                            clipboardState.value === item?.Video_url
                              ? "Copied"
                              : "Copy Link"}
                          </button>
                        </CopyToClipboard>
                      </div>
                    </div>
                  </div>
                ) : null,
              )}
          </div>
        </div>
        <div className="pt-3">
          {videos && (
            <div className="table-pagination w-100">
              <div className="per-page me-auto">
                Page {currentPage} of {Math.ceil(videos.length / perPage)} |{" "}
                {videos.length} Campaigns
              </div>
              <div className="pagination">
                <button
                  className={`btn-page ${currentPage === 1 ? "disabled" : ""}`}
                  onClick={() => {
                    if (currentPage > 1) setCurrentPage(currentPage - 1);
                  }}
                >
                  <GrFormPrevious />
                </button>
                {currentPage > 2 ? (
                  <button className={`btn-page`}>...</button>
                ) : null}
                {[...Array(Math.ceil(videos.length / perPage))].map((e, i) =>
                  i + 1 > currentPage - 2 && i + 1 < currentPage + 2 ? (
                    <button
                      className={`btn-page ${
                        currentPage === i + 1 ? "active" : ""
                      }`}
                      key={i}
                      onClick={() => setCurrentPage(i + 1)}
                    >
                      {i + 1}
                    </button>
                  ) : null,
                )}
                {currentPage < Math.ceil(videos.length / perPage) - 1 ? (
                  <button className={`btn-page`}>...</button>
                ) : null}
                <button
                  className={`btn-page ${
                    currentPage === Math.ceil(videos.length / perPage)
                      ? "disabled"
                      : ""
                  }`}
                  onClick={() => {
                    if (currentPage < Math.ceil(videos.length / perPage))
                      setCurrentPage(currentPage + 1);
                  }}
                >
                  <GrFormNext />
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="modal-footer mt-3">
        <button className="btn btn-outline-secondary me-2" onClick={closeModal}>
          Cancel
        </button>
        <button className="btn btn-primary" onClick={closeModal}>
          Confirm
        </button>
      </div>
    </Modal>
  );
}
