import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import StickyNote2OutlinedIcon from "@mui/icons-material/StickyNote2Outlined";
import Dropdown from "react-bootstrap/Dropdown";
import SplitButton from "react-bootstrap/SplitButton";
import DropdownButton from "react-bootstrap/DropdownButton";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import APITablePagination from "../TablePagination/APITablePagination";
import { useState } from "react";
import config from "../../config/config";
import helper from "../../utils/helper";
import AppointmentRescheduleModal from "../modals/appointment_reschedule_modal";
import AppointmentAssignOwnerModal from "../modals/appointment_assign_owner_modal";
import React, { useContext } from "react";
import { AppointmentContext, AuthContext } from "../../context";
import AppointmentChangeStatusModal from "../modals/appointment_change_status_modal";
import AppointmentChangeApptStatusModal from "../modals/appointment_change_appt_status_modal";

export default function AppointmentsTableView({
  data = [],
  pagination = {},
  onChangePage,
  isdealerfunneluser = "",
  searchKey,
  setSearchKey,
  reloadData,
  deleteAppts,
  startDate,

  endDate,

  clientId,
  campaignId,
}) {
  // const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const { makeTwillioCall } = useContext(AppointmentContext);
  // const [dateLabel, setDateLabel] = useState('This Month');
  //
  const [apptRescheduleOpenModal, setApptRescheduleOpenModal] = useState(""); // leadId
  const [apptAssignOwnerOpenModal, setApptAssignOwnerOpenModal] = useState(""); // ApptsId
  const [apptChangeStatusOpenModal, setApptChangeStatusOpenModal] =
    useState(false); //
  const [apptChangeApptStatusOpenModal, setApptChangeApptStatusOpenModal] =
    useState(false); //
  const [selectedLeadIdArr, setSelectedLeadIdArr] = useState([]);
  const [selectedLabelArr, setSelectedLabelArr] = useState([]);
  const [selectedApptStatusArr, setSelectedApptStatusArr] = useState([]);

  const _maketwilliocall = async (LeadId) => {
    const data = await makeTwillioCall({ userId: user.Id, leadId: LeadId });
    if (data.status === "ok") {
      const res = data.response.scalar;
      let str = "";
      if (res === 2) {
        str = "User have no Default Phone setting";
      } else if (res === 3) {
        str = "User have no CallerId setting";
      } else if (res === 4) {
        str = "User have no Default Phone and  CallerId setting";
      } else if (res === 5) {
        str = "Lead have no Phone number";
      } else if (res === 1) {
        str = "Call Make";
      }
      if (str) {
        alert(str);
      }
    }
  };

  const toggleCheck = (item) => {
    if (!selectedLeadIdArr.includes(item.LeadId)) {
      setSelectedLeadIdArr((current) => [...current, item.LeadId]);
      setSelectedLabelArr((current) => [...current, item.Label]);
      setSelectedApptStatusArr((current) => [...current, item.ApptsStatus]);
    } else {
      let index = selectedLeadIdArr.indexOf(item.LeadId);
      setSelectedLeadIdArr((current) =>
        current.filter((e) => e !== item.LeadId),
      );
      setSelectedLabelArr((current) => current.filter((e, i) => i !== index));
      setSelectedApptStatusArr((current) =>
        current.filter((e, i) => i !== index),
      );
    }
  };

  const toggleSelection = () => {
    let _selectedLeadIdArr = [];
    let _selectedLabelArr = [];
    let _selectedApptStatusArr = [];
    data.map((item) => {
      if (!selectedLeadIdArr.includes(item.LeadId)) {
        _selectedLeadIdArr.push(item.LeadId);
        _selectedLabelArr.push(item.Label);
        _selectedApptStatusArr.push(item.ApptsStatus);
      }
    });
    setSelectedLeadIdArr(_selectedLeadIdArr);
    setSelectedLabelArr(_selectedLabelArr);
    setSelectedApptStatusArr(_selectedApptStatusArr);
  };

  const exportSelection = () => {
    if (selectedLeadIdArr && selectedLeadIdArr.length) {
      window.location.href = `${
        config.apptsReportPath
      }/exportbyid/${selectedLeadIdArr.join("N")}`;
    } else {
      alert("You didn't select any record");
    }
  };

  const exportAll = () => {
    window.location.href = `${config.apptsReportPath}/appexportall`;
  };

  return (
    <div className="card">
      <div className="card-header">
        <div className="table-view-header">
          <div className="form-control-wrapper form-control-fav appt-search">
            <input
              type="text"
              className="form-control h-100 pe-5"
              placeholder="Search"
              value={searchKey}
              onChange={(e) => setSearchKey(e.target.value)}
            />
            <button className="btn right-button" onClick={() => reloadData()}>
              <SearchOutlinedIcon fontSize="small" />
            </button>
          </div>
          <div className="d-flex w-100">
            <SplitButton
              className="select-all-dropdown"
              title={"Select All"}
              onClick={() => {
                setSelectedLeadIdArr(data.map((e) => e.LeadId));
                setSelectedLabelArr(data.map((e) => e.Label));
              }}
            >
              <Dropdown.Item eventKey="1" onClick={() => toggleSelection()}>
                Toggle Selection
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="2"
                onClick={() => {
                  setSelectedLeadIdArr([]);
                  setSelectedLabelArr([]);
                }}
              >
                Unselect All
              </Dropdown.Item>
            </SplitButton>
            <DropdownButton
              className="select-action-dropdown ms-2"
              as={ButtonGroup}
              title={"Actions"}
            >
              <Dropdown.Item eventKey="1" onClick={() => exportSelection()}>
                Export Selection
              </Dropdown.Item>
              <Dropdown.Item eventKey="2" onClick={() => exportAll()}>
                Export All
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="3"
                onClick={() => setApptChangeStatusOpenModal(true)}
              >
                Change Status
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="4"
                onClick={() => setApptChangeApptStatusOpenModal(true)}
              >
                Change Appts Status
              </Dropdown.Item>
            </DropdownButton>
          </div>
        </div>
      </div>
      <div className="card-body p-0">
        <table className="table table-hover m-0">
          <thead>
            <tr>
              <th></th>
              <th></th>
              <th>Name</th>
              <th>Lead Source</th>
              <th>DateTime</th>
              <th>Status</th>
              <th>Appt Status</th>
              <th>Agent</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <td>
                  <input
                    type="checkbox"
                    checked={selectedLeadIdArr.includes(item.LeadId)}
                    onChange={(e) => {
                      console.log(e);
                      toggleCheck(item);
                    }}
                  />
                </td>
                <td>
                  <img
                    className="rounded-circle"
                    alt=""
                    width="40"
                    height="40"
                    src={
                      item.Ismediaimage !== 1
                        ? `${
                            config.domain
                          }/assets/images/material-letter-icons/png/${item.Name.charAt(
                            0,
                          ).toUpperCase()}.png`
                        : `${item.Mediaimage}`
                    }
                  />
                </td>
                <td>
                  {isdealerfunneluser === 1 ? (
                    <a
                      className="text-decoration-none"
                      target="_blank"
                      href={`${config.smsPath}/s/${item.Keyid}`}
                      rel="noreferrer"
                    >
                      {item.Name}
                    </a>
                  ) : (
                    <a
                      className="text-decoration-none"
                      target="_blank"
                      href={`${config.campaignsPath}/
                      fapp?cmp=${item.CMPKey}&lead=${item.LeadId}&barcode=${item.Barcode}`}
                      rel="noreferrer"
                    >
                      {item.Name}
                    </a>
                  )}
                </td>
                <td>
                  {item.Isleadsource === 1 ? (
                    <a
                      className="text-decoration-none"
                      target="_blank"
                      href={``}
                      rel="noreferrer"
                    >
                      {item.Leadsourcename}
                    </a>
                  ) : (
                    <a
                      className="text-decoration-none"
                      target="_blank"
                      href={`${config.campaignsPath}/dashboard?cid=${item.CMPKey}`}
                      rel="noreferrer"
                    >
                      {item.CMPName}
                    </a>
                  )}
                </td>
                <td>{`${item.Apptime.day} @ ${item.Stime}`}</td>
                <td>
                  <a
                    className="text-decoration-none"
                    // onclick="changelabelfrommodal(<?=$xreport[$i]['LeadId']?>,2)"
                  >
                    <span
                      id={`table_${item.LeadId}`}
                      dangerouslySetInnerHTML={{
                        __html: helper.getLabeIcon(item.Label),
                      }}
                    ></span>
                  </a>
                </td>
                <td>
                  <div className="d-flex">
                    <a
                      className="text-decoration-none d-flex align-items-center"
                      // onclick="changeappts_status(<?=$xreport[$i]['LeadId']?>)"
                    >
                      <span
                        id={`appts_${item.LeadId}`}
                        dangerouslySetInnerHTML={{
                          __html: helper.getApptsStatus(item.ApptsStatus),
                        }}
                      ></span>
                    </a>
                    {item.Issms === 1 ? (
                      <span className="label label-orange">
                        <a
                        // href="//<?=domain_type_1()?>/index.php/leadsummary/s?rid="
                        >
                          <i className="fa-solid fa-trophy fa-lg"></i>
                        </a>
                      </span>
                    ) : null}
                    <a
                      className="text-decoration-none d-flex align-items-center"
                      title={
                        item.Notecount > 0
                          ? item.notes.map((note) => (
                              <>
                                {note.Notes} <hr />
                              </>
                            ))
                          : ""
                      }
                      data-toggle="tooltip"
                      data-html="true"
                      data-placement="right"
                    >
                      <span
                        className="badge rounded-pill bg-gray-2 d-flex align-items-center ms-1"
                        id={`notecount_${item.Id}`}
                      >
                        <StickyNote2OutlinedIcon
                          className="me-1"
                          style={{ fontSize: "13px" }}
                        />
                        {item.Notecount}
                      </span>
                    </a>
                  </div>
                </td>
                <td>{item.Agent}</td>
                <td>
                  <DropdownButton
                    className="appt-select-action"
                    as={ButtonGroup}
                    title={"Actions"}
                  >
                    <Dropdown.Item
                      eventKey="1"
                      onClick={() => setApptRescheduleOpenModal(item.LeadId)}
                    >
                      Reschedule
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey="2"
                      onClick={() => setApptAssignOwnerOpenModal(item.ApptsId)}
                    >
                      Assign Owner
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey="3"
                      onClick={() => _maketwilliocall(item.LeadId)}
                    >
                      Call
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey="4"
                      // href={`/tools/printresponse/${item.Keyid}`}
                      // target="_blank"
                      // rel="noopener noreferrer"
                      // onClick={() => navigate(`/tools/printresponse/${item.Keyid}`)}
                      onClick={() =>
                        window.open(
                          `${config.toolPath}/printresponse/${item.Keyid}`,
                        )
                      }
                    >
                      Print
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey="5"
                      onClick={() => deleteAppts(item.ApptsId)}
                    >
                      Delete
                    </Dropdown.Item>
                  </DropdownButton>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="card-footer py-4 bg-white">
        <APITablePagination
          pagination={pagination}
          onChangePage={onChangePage}
        />
      </div>
      {/* Modals */}
      <AppointmentRescheduleModal
        openModal={apptRescheduleOpenModal}
        closeModal={() => setApptRescheduleOpenModal("")}
        reloadData={() => {
          reloadData();
          setApptRescheduleOpenModal("");
        }}
      />
      <AppointmentAssignOwnerModal
        openModal={apptAssignOwnerOpenModal}
        closeModal={() => setApptAssignOwnerOpenModal("")}
        reloadData={() => {
          reloadData();
          setApptAssignOwnerOpenModal("");
        }}
      />
      <AppointmentChangeStatusModal
        openModal={apptChangeStatusOpenModal}
        selectedLeadIds={selectedLeadIdArr}
        selectedLabels={selectedLabelArr}
        closeModal={() => setApptChangeStatusOpenModal(false)}
        reloadData={() => {
          reloadData();
          setApptChangeStatusOpenModal(false);
          setSelectedLeadIdArr([]);
          setSelectedLabelArr([]);
          setSelectedApptStatusArr([]);
        }}
      />
      <AppointmentChangeApptStatusModal
        openModal={apptChangeApptStatusOpenModal}
        selectedLeadIds={selectedLeadIdArr}
        selectedApptStatuses={selectedApptStatusArr}
        closeModal={() => setApptChangeApptStatusOpenModal(false)}
        reloadData={() => {
          reloadData();
          setApptChangeApptStatusOpenModal(false);
          setSelectedLeadIdArr([]);
          setSelectedLabelArr([]);
          setSelectedApptStatusArr([]);
        }}
        clientId={clientId}
        campaignId={campaignId}
        startDate={startDate}
        endDate={endDate}
      />
    </div>
  );
}
