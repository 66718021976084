import React, { useContext, useState, useRef } from "react";
import { AuthContext, ReportContext } from "../../context";
import { useEffect } from "react";
import { DateFilterTypePicker, DropdownMultiSelect } from "../../components";
import moment from "moment";
import { BsDownload } from "react-icons/bs";
import { HiOutlineCurrencyDollar } from "react-icons/hi2";
import helper from "../../utils/helper";
import config from "../../config/config";

export default function ReportLeadSourcesPage() {
  const { user } = useContext(AuthContext);
  const { reportLeadSourcesApi, ajaxleadsourceApi } = useContext(ReportContext);
  const tableRef = useRef(null);
  //
  const [customColor, setCustomColor] = useState();
  const [clientdetails, setClientdetails] = useState([]);
  const [clt, setClt] = useState("");
  //
  const [selectedDate, setSelectedDate] = useState(1);
  const [customDateRage, setCustomDateRange] = useState({
    start: new Date(moment().subtract(1, "months")),
    end: new Date(),
  });
  const [selectedClientdetails, setSelectedClientdetails] = useState([]);
  //
  const [tableData, setTableData] = useState(undefined); // object
  const [total, setTotal] = useState(undefined); // object
  const [search, setSearch] = useState("");

  useEffect(() => {
    getReportLeadSourcesData();
    console.log(customColor);
  }, []);

  const getReportLeadSourcesData = async () => {
    const data = await reportLeadSourcesApi({ userId: user.Id });
    if (data?.status === "ok") {
      const res = data.response;
      setCustomColor(res?.custom_color);
      setClientdetails(res?.clientdetails);
      setClt(res?.clt);
    }
  };

  useEffect(() => {
    ajaxLeadSourceData();
  }, [clt]);

  useEffect(() => {
    if (selectedDate !== 5) {
      ajaxLeadSourceData();
    }
  }, [selectedDate]);

  useEffect(() => {
    if (selectedDate === 5) {
      ajaxLeadSourceData();
    }
  }, [customDateRage]);

  const ajaxLeadSourceData = async () => {
    let _clt = selectedClientdetails
      ? selectedClientdetails.map((item) => item.Id)
      : [];
    let _params = {
      userId: user.Id,
      daterange: selectedDate,
    };
    if (selectedDate === 5) {
      _params.startdate = customDateRage.start;
      _params.enddate = customDateRage.end;
    }
    if (_clt.length > 0) {
      _params["clt"] = _clt;
      _params["clt_sel"] = _clt;
    } else {
      _params["clt"] = clt.split(",");
      _params["clt_sel"] = [];
    }
    const data = await ajaxleadsourceApi(_params);
    if (data?.status === "ok") {
      const res = data.response;
      setTableData(res?.info);
      setTotal(res?.total);
    }
  };

  return (
    <main className="container py-4">
      <div className="d-flex align-items-center">
        <div className="section-title">
          <HiOutlineCurrencyDollar className="icon me-2" />
          Lead Sources
        </div>
      </div>
      <div className="row mt-2">
        <div className="col col-sm-8 py-1">
          <DateFilterTypePicker
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            customDateRage={customDateRage}
            setCustomDateRange={setCustomDateRange}
          />
        </div>
        <div className="col col-sm-2 ms-auto">
          <DropdownMultiSelect
            id="agent_group"
            className="w-100 mb-2"
            options={clientdetails.map((item) => {
              return { Id: item.Id, Name: item.Name };
            })}
            placeholder={"Select Clients"}
            labelField="Name"
            valueField="Id"
            values={selectedClientdetails}
            onChange={(values) => setSelectedClientdetails(values)}
            onDropdownClose={(values) => {
              console.log(values);
              ajaxLeadSourceData();
            }}
          />
        </div>
      </div>
      <div id="dashboard-tbl-data" className="card mt-2">
        <div className="card-header d-flex align-items-center justify-content-between p-2">
          <input
            type="text"
            className="form-control"
            style={{ width: "150px" }}
            placeholder="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <a
            className="btn btn-sm btn-primary"
            href={`${config.exportPath}/leadreport`}
            target="_blank"
            rel="noreferrer"
          >
            <div className="d-flex align-items-center">
              <BsDownload className="me-1" />
              Export
            </div>
          </a>
        </div>
        <div className="card-body p-0">
          <table
            id="messages_report"
            className="table table-hover"
            ref={tableRef}
          >
            <thead>
              <tr>
                <th width="40">&nbsp;</th>
                <th>Lead Source</th>
                <th>Leads</th>
                <th>Engagement</th>
                <th>Appt</th>
                <th>Appt %</th>
                <th>Shows</th>
                <th>Show %</th>
                <th>Opt Outs</th>
                <th>Sold</th>
                <th>Last Lead</th>
              </tr>
            </thead>
            <tbody>
              {!!tableData &&
                tableData
                  .filter((item) =>
                    item.Name.toLowerCase().includes(search.toLowerCase()),
                  )
                  .map((item, index) =>
                    item.count.lead > 0 ? (
                      <tr key={index}>
                        <td>
                          <img
                            className="leadsource_img"
                            src={item.Logo}
                            width={30}
                            height={30}
                          />
                        </td>
                        <td>
                          <a
                            className="text-decoration-none"
                            href={`${config.baseUrl}/reports/sourcelist?c=
                            ${clt}&s=${item.LeadSourceId}&d
                            =${selectedDate}&sd=${customDateRage.start}
                            &ed=${customDateRage.end}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {item.Name}
                          </a>
                        </td>
                        <td>{helper.number_format(item.count.lead)}</td>
                        <td>
                          {helper.percentage(
                            item.count.engagement,
                            item.count.lead,
                          )}
                          %
                        </td>
                        <td>{item.count.app}</td>
                        <td>
                          {helper.percentage(item.count.app, item.count.lead)}%
                        </td>
                        <td>{item.count.apps}</td>
                        <td>
                          {helper.percentage(item.count.apps, item.count.lead)}%
                        </td>
                        <td>{helper.number_format(item.count.optedout)}</td>
                        <td>{item.count.sold}</td>
                        <td>
                          <abbr
                            style={{ fontSize: "11px" }}
                            className="timeago"
                            title={item.count.lastlead}
                          >
                            {moment(item.count.lastlead).fromNow()}
                          </abbr>
                        </td>
                      </tr>
                    ) : null,
                  )}
            </tbody>
            <tfoot>
              <tr>
                <td></td>
                <td></td>
                <td>{helper.number_format(total?.Leads)}</td>
                <td>{helper.percentage(total?.Engagement, total?.Leads)}%</td>
                <td>{helper.number_format(total?.Appt)}</td>
                <td>{helper.percentage(total?.Appt, total?.Leads)}%</td>
                <td>{helper.number_format(total?.Shows)}</td>
                <td>{helper.percentage(total?.Shows, total?.Leads)}%</td>
                <td>{helper.number_format(total?.OptOuts)}</td>
                <td>{helper.number_format(total?.Sold)}</td>
                <td></td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </main>
  );
}
