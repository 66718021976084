import React, { useContext, useState, useRef } from "react";
import { AuthContext, ReportContext } from "../../context";
import { useEffect } from "react";
import { DateFilterMonthPicker2, DropdownMultiSelect } from "../../components";
import moment from "moment";
import { BsDownload } from "react-icons/bs";
import helper from "../../utils/helper";
import config from "../../config/config";
import { BiLineChart } from "react-icons/bi";

export default function HotLeadsReportPage() {
  const { user } = useContext(AuthContext);
  const { getHotLeadsApi, filterHotLeadsApi } = useContext(ReportContext);
  const tableRef = useRef(null);
  //
  const [customColor, setCustomColor] = useState();
  const [client, setClient] = useState([]);
  //
  const [selectedDate, setSelectedDate] = useState("1");
  const [customDateRage, setCustomDateRange] = useState({
    start: new Date(moment().subtract(30, "days")),
    end: new Date(),
  });
  const [selectedClients, setSelectedClients] = useState([]);
  //
  const [tableData, setTableData] = useState(undefined); // object
  const [total, setTotal] = useState(undefined); // object

  useEffect(() => {
    getHotLeadsData();
    console.log(customColor);
  }, []);

  const getHotLeadsData = async () => {
    const data = await getHotLeadsApi({ userId: user.Id });
    if (data?.status === "ok") {
      const res = data.response;
      setCustomColor(res?.custom_color);
      setClient(res?.client);
    }
  };

  useEffect(() => {
    if (selectedDate !== "3") {
      // filterHotLeads();
    }
  }, [selectedDate]);

  useEffect(() => {
    if (selectedDate === "3") {
      // filterHotLeads();
    }
  }, [customDateRage]);

  const filterHotLeads = async () => {
    let _params = {
      userId: user.Id,
      filterbydate: selectedDate,
      clientId: selectedClients.map((item) => item.Id),
    };
    if (selectedDate === "3") {
      _params.start_date = customDateRage.start;
      _params.end_date = customDateRage.end;
    }
    const data = await filterHotLeadsApi(_params);
    if (data?.status === "ok") {
      const res = data.response;
      setTableData(res?.info);
      setTotal(res?.total);
    }
  };

  return (
    <main className="container py-4">
      <div className="d-flex align-items-center justify-content-between">
        <div className="section-title">
          <BiLineChart className="icon me-2" />
          Hot Leads
        </div>
        <a
          className="btn btn-sm btn-primary"
          target="_blank"
          href={`${config.exportPath}/hot_leads_export`}
          rel="noreferrer"
        >
          <div className="d-flex align-items-center">
            <BsDownload className="me-1" />
            Export
          </div>
        </a>
      </div>
      <div className="row mt-2">
        <div className="col col-sm-10 py-1">
          <DateFilterMonthPicker2
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            customDateRage={customDateRage}
            setCustomDateRange={setCustomDateRange}
          />
        </div>
        <div className="col col-sm-2 ms-auto">
          <DropdownMultiSelect
            id="agent_group"
            className="w-100 mb-2"
            options={client.map((item) => {
              return { Id: item.Id, Name: item.Name };
            })}
            placeholder={"Select Clients"}
            labelField="Name"
            valueField="Id"
            values={selectedClients}
            onChange={(values) => setSelectedClients(values)}
            onDropdownClose={(values) => {
              console.log(values);
              filterHotLeads();
            }}
          />
        </div>
      </div>
      <div id="dashboard-tbl-data" className="card mt-2">
        <div className="card-body p-0">
          <table
            id="messages_report"
            className="table table-hover"
            ref={tableRef}
          >
            <thead>
              <tr>
                <th>User</th>
                <th>Hot Leads Sent</th>
                <th>Hot Leads Received</th>
                <th>Hot Leads Sold</th>
                <th>Hot Lead Sold Percent</th>
                <th className="text-center">Total Sold</th>
              </tr>
            </thead>
            <tbody>
              {!!tableData &&
                tableData.map((item, index) => (
                  <tr key={index}>
                    <td>{item?.Name}</td>
                    <td>{helper.number_format(item?.s1)}</td>
                    <td>{helper.number_format(item?.s2)}</td>
                    <td>{helper.number_format(item?.s3)}</td>
                    <td></td>
                    <td className="text-center">
                      {helper.number_format(item?.s4)}
                    </td>
                  </tr>
                ))}
            </tbody>
            <tfoot>
              <tr>
                <td></td>
                <td>{helper.number_format(total?.s1)}</td>
                <td>{helper.number_format(total?.s2)}</td>
                <td>{helper.number_format(total?.s3)}</td>
                <td></td>
                <td className="text-center">
                  {helper.number_format(total?.s4)}
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </main>
  );
}
