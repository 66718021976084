import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import React from "react";

export default function DateFilterMonthPicker({
  selectedDate = "this_month",
  setSelectedDate,
  customDateRage,
  setCustomDateRange,
}) {
  const handleEvent = (event, picker) => {
    console.log(picker.startDate, event);
  };
  const handleCallback = (start, end, label) => {
    console.log(
      label,
      moment(start).format("YYYY-MM-DD"),
      moment(end).format("YYYY-MM-DD"),
    );
    setCustomDateRange({
      start: moment(start).format("YYYY-MM-DD"),
      end: moment(end).format("YYYY-MM-DD"),
    });
  };

  return (
    <div className="date-filter">
      <button
        className={`btn-filter ${
          selectedDate === "this_month" ? "active" : ""
        }`}
        onClick={() => setSelectedDate("this_month")}
      >
        This Month
      </button>
      <button
        className={`btn-filter ${
          selectedDate === "last_month" ? "active" : ""
        }`}
        onClick={() => setSelectedDate("last_month")}
      >
        Last Month
      </button>
      <DateRangePicker
        initialSettings={{
          startDate:
            customDateRage?.start || new Date(moment().subtract(1, "months")),
          endDate: customDateRage?.end || new Date(),
        }}
        maxDate={new Date()}
        onEvent={handleEvent}
        onCallback={handleCallback}
      >
        <button
          className={`btn-filter ${selectedDate === "custom" ? "active" : ""}`}
          onClick={() => setSelectedDate("custom")}
        >
          Custom
        </button>
      </DateRangePicker>
    </div>
  );
}
