import Modal from "react-modal";
import React, { useContext, useState } from "react";
import { AppointmentContext, AuthContext } from "../../context";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "560px",
    padding: "15px",
    marginTop: "20px",
    marginBottom: "20px",
  },
};

Modal.setAppElement("#root");

export default function AppointmentChangeStatusModal({
  openModal = false,
  selectedLeadIds = [],
  selectedLabels = [],
  closeModal,
  reloadData,
}) {
  const { user } = useContext(AuthContext);
  const { updateMultipleStatus } = useContext(AppointmentContext);
  const [status, setStatus] = useState("");

  const onSave = async () => {
    console.log(
      ">>> post data = >>>",
      getMarkedIdOnLabels(selectedLeadIds, selectedLabels),
    );
    if (!(selectedLeadIds.length && selectedLabels.length && status)) {
      return;
    }
    const data = await updateMultipleStatus({
      userId: user.Id,
      slabel: status,
      appid: getMarkedIdOnLabels(selectedLeadIds, selectedLabels),
    });
    if (data.status === "ok") {
      reloadData();
    }
  };

  const getMarkedIdOnLabels = (_selectedLeadIds, _selectedLabels) => {
    let lst = _selectedLeadIds;
    let arr = [];
    for (let i = 0; i < lst.length; i++) {
      let label = _selectedLabels[i];
      arr[i] = lst[i] + "P" + label;
    }
    let s = arr.join("N");
    return s;
  };

  const afterOpenModal = () => {};

  return (
    <Modal
      isOpen={openModal}
      onAfterOpen={() => afterOpenModal()}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Edit Modal"
    >
      <div className="modal-header">
        <h4>Status</h4>
      </div>
      <div className="modal-body">
        <form>
          <div className="row">
            <div className="col-12">
              <div className="form-group mt-3">
                <label>Change Status</label>
                <select
                  id="slabel"
                  name="slabel"
                  className="form-select"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <option value="">- Select Status -</option>
                  <option value="1">Hot</option>
                  <option value="4">Warm</option>
                  <option value="2">Cold</option>
                  <option value="3">Sold</option>
                  <option value="5">Follow Up</option>
                  <option value="6">Dead</option>
                </select>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="modal-footer mt-3">
        <button className="btn btn-primary me-2" onClick={() => onSave()}>
          Save
        </button>
        <button className="btn btn-outline-secondary" onClick={closeModal}>
          Cancel
        </button>
      </div>
    </Modal>
  );
}
