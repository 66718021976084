import React, { createContext, useContext, useState } from "react";
import config from "../config/config";
import { AxiosContext } from "./axios_context";

const AppointmentContext = createContext(undefined);

const AppointmentContextProvider = (props) => {
  const { axiosInstance, setLoading } = useContext(AxiosContext);
  const [clientlist, setClientlist] = useState([]);
  const [assignuserlist, setAssignuserlist] = useState([]);
  const [leadsourcelist, setLeadsourcelist] = useState([]);
  const [campaignlist, setCampaignlist] = useState([]);
  const [analysisData, setAnalysisData] = useState(null);
  const [apptReport, setApptReport] = useState([]);
  const [apptPagination, setApptPagination] = useState(null);
  const [isdealerfunneluser, setIsdealerfunneluser] = useState(null);

  const getAppointmentMetaData = ({ userId }) => {
    const data = {
      userId: userId,
    };
    // setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/appointments/appointments_metadata`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("=== appointments_metadata ===", data);
        const res = data.response;
        if (data.status === "ok") {
          setClientlist(res.clientlist);
          setAssignuserlist(res.assignuserlist);
          setLeadsourcelist(res.leadsourcelist);
          setCampaignlist(res.campaignlist);
        }
        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
        return error;
      });
  };

  const getAppointmentAnalysisData = ({
    userId,
    clientId = "",
    assignmentId = "",
    leadsourceId = "",
    statusId = "",
    campaignId = "",
    sfields,
    sflag,
    page = 1,
    searchKey = "",
    startDate = "",
    endDate = "",
    pageLoading = false,
  }) => {
    const data = {
      userId: userId,
      clientId: clientId,
      assignmentId: assignmentId,
      leadsourceId: leadsourceId,
      statusId: statusId,
      campaignId: campaignId,
      sfields: sfields,
      sflag: sflag,
      page: page,
      searchKey: searchKey,
      startDate: startDate,
      endDate,
    };
    if (pageLoading) {
      setLoading(true);
    }
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/appointments/appointments_analysisreport`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("=== appointments_analysisreport ===", data);
        const res = data.response;
        if (data.status === "ok") {
          setAnalysisData(res.analysis);
          setApptReport(res.apptreport);
          setApptPagination(res.pagination);
          setIsdealerfunneluser(res.isdealerfunneluser);
        }
        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
        return error;
      });
  };

  const getRescheduleModal = ({ leadId }) => {
    const data = {
      leadId: leadId,
    };
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/appointments/appointments_reschedule_modal`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("=== appointments_reschedule_modal ===", data);

        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
        return error;
      });
  };

  const updateSchedule = (postData) => {
    const data = postData;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/appointments/appointments_reschedule_update`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("=== appointments_reschedule_update ===", data);

        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
        return error;
      });
  };

  const getApptAssignOwnerModal = ({ responseId }) => {
    const data = {
      responseId: responseId,
    };
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/appointments/appointments_assignowner_modal`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("=== appointments_assignowner_modal ===", data);

        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
        return error;
      });
  };

  const assignOwner = (postData) => {
    const data = postData;
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/appointments/appointments_assignowner_assign`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("=== appointments_assignowner_assign ===", data);

        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
        return error;
      });
  };

  const makeTwillioCall = ({ userId, leadId }) => {
    const data = {
      userId: userId,
      leadId: leadId,
    };
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/appointments/maketwilliocall`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("=== maketwilliocall ===", data);

        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
        return error;
      });
  };

  const deleteAppointment = ({ userId, responseId }) => {
    const data = {
      userId: userId,
      responseId: responseId,
    };
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/appointments/appointments_delete`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("=== appointments_delete ===", data);

        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
        return error;
      });
  };

  const updateMultipleStatus = ({ userId, slabel, appid }) => {
    const data = {
      userId: userId,
      slabel: slabel,
      appid: appid,
    };
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/appointments/appointments_multiplestatus_update`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("=== appointments_multiplestatus_update ===", data);

        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
        return error;
      });
  };

  const updateMultipleApptStatus = ({ userId, appid, status }) => {
    const data = {
      userId: userId,
      appid: appid,
      status: status,
    };
    setLoading(true);
    return axiosInstance({
      method: "POST",
      url: `${config.reactApi}/appointments/appointments_multipleapptstatus_update`,
      data,
    })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        console.log("=== appointments_multipleapptstatus_update ===", data);

        return data;
      })
      .catch((error) => {
        setLoading(false);
        console.log("err: ", error);
        return error;
      });
  };

  const providerValue = {
    getAppointmentMetaData,
    clientlist,
    assignuserlist,
    leadsourcelist,
    campaignlist,
    getAppointmentAnalysisData,
    analysisData,
    apptReport,
    apptPagination,
    isdealerfunneluser,
    getRescheduleModal,
    updateSchedule,
    getApptAssignOwnerModal,
    assignOwner,
    makeTwillioCall,
    deleteAppointment,
    updateMultipleStatus,
    updateMultipleApptStatus,
  };

  return (
    <AppointmentContext.Provider value={providerValue}>
      {props.children}
    </AppointmentContext.Provider>
  );
};

export { AppointmentContext, AppointmentContextProvider };
