import React, { useContext, useEffect, useState } from "react";
import "react-calendar/dist/Calendar.css";
import { SlBriefcase } from "react-icons/sl";
import { ClientManagerDataTable, ClientsDataTable } from "../../components";
import { AuthContext, ClientContext } from "../../context";
import { Link } from "react-router-dom";
import { BsPlus } from "react-icons/bs";
import {
  Box,
  Button,
  Card,
  Container,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

export default function ClientsPage() {
  const { user } = useContext(AuthContext);
  const { getClientsDataApi, clients } = useContext(ClientContext);
  const [isDealerfunnelUser, setIsDealerfunnelUser] = useState(undefined);
  const [isexecutive, setIsexecutive] = useState(undefined);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getClientsData();
  }, []);

  const getClientsData = async () => {
    setLoading(true);
    const data = await getClientsDataApi({ userId: user.Id });
    setLoading(false);
    if (data.status === "ok") {
      const res = data.response;
      setIsDealerfunnelUser(res?.is_dealerfunnel_user);
      setIsexecutive(res?.isexecutive);
    }
  };

  const loadingTable = () => {
    return (
      <Card
        sx={{
          p: 2,
          height: "100%",
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              {[...Array(9)].map((item, index) => {
                return (
                  <TableCell key={index}>
                    <Skeleton variant="text" sx={{ fontSize: "3rem" }} />
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {[...Array(9)].map((item, index) => {
              return (
                <TableRow key={index}>
                  {[...Array(9)].map((item, index) => {
                    return (
                      <TableCell key={index}>
                        <Skeleton variant="text" sx={{ fontSize: "2rem" }} />
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Card>
    );
  };

  return (
    <Container maxWidth="xl" sx={{ mt: 2 }}>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Stack
          direction="row"
          alignItems={"center"}
          justifyContent={"center"}
          spacing={1}
        >
          <SlBriefcase style={{ fontSize: 18 }} />
          <Typography>Clients</Typography>
        </Stack>
        <Link to="/clients/newclient">
          <Button
            variant="contained"
            startIcon={<BsPlus style={{ fontSize: 18 }} />}
            size="small"
            sx={{ textTransform: "none" }}
          >
            New Client
          </Button>
        </Link>
      </Stack>
      <Box mt={2}>
        {loading ? (
          loadingTable()
        ) : Number(user.Type) === 1 || Number(user.Type) === 5 ? (
          <ClientsDataTable
            clients={clients}
            isDealerfunnelUser={isDealerfunnelUser}
            isexecutive={isexecutive}
          />
        ) : (
          <ClientManagerDataTable
            clients={clients}
            isDealerfunnelUser={isDealerfunnelUser}
            isexecutive={isexecutive}
          />
        )}
      </Box>
    </Container>
  );
}
