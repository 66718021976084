const moment = require("moment");

function getCurrentYear() {
  return new Date().getFullYear();
}

function timeAgo(dateTime) {
  if (dateTime == null) return null;
  return moment(dateTime).fromNow();
}

export default {
  getCurrentYear,
  timeAgo,
};
