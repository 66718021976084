import React from "react";
import { Box } from "@mui/material";
import { RotatingLines } from "react-loader-spinner";
import { ColorsConfig } from "../../config";

export default function PartLoader() {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        position: "fixed",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        zIndex: "999",
      }}
    >
      <RotatingLines
        strokeColor={ColorsConfig.light.loading}
        strokeWidth="5"
        animationDuration="0.75"
        width="40"
        visible={true}
      />
    </Box>
  );
}
