import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import React from "react";

const localizer = momentLocalizer(moment);

export default function BlastsCalendarView({ data = [], onClickEvent }) {
  const formattedEvents = (data) => {
    let _events = [];
    for (let i = 0; i < data.length; i++) {
      _events.push({
        allDay: false,
        end: new Date(`${data[i].Date}T00:00:00`),
        start: new Date(`${data[i].Date}T23:59:59`),
        title: (
          <div
            className="event-content"
            style={{ backgroundColor: data[i].Color }}
            onClick={() => onClickEvent(data[i].Id)}
          >
            <div className="event-title">{data[i].Bname}</div>
            <div className="event-description">{data[i].Cname}</div>
          </div>
        ),
      });
    }
    console.log(">> events >>", _events);
    return _events;
  };

  return (
    <div className="blasts-calendar">
      <Calendar
        localizer={localizer}
        events={formattedEvents(data)}
        startAccessor="start"
        endAccessor="end"
        showAllEvents={true}
        views={["month", "week", "day"]}
        // style={{ minHeight: 600, height: 'auto' }}
      />
    </div>
  );
}
