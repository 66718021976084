import React, { useState, useEffect, useContext } from "react";
import { SlUser } from "react-icons/sl";
import { UserGroupsDataTable, UsersDataTable } from "../../components";
import { AuthContext, UserContext } from "../../context";
import { Link } from "react-router-dom";
import { BsPlus } from "react-icons/bs";
import { Card, Skeleton, Stack } from "@mui/material";

export default function UsersPage() {
  const [tab, setTab] = useState("user");
  const { user } = useContext(AuthContext);
  const { getUsersDataApi } = useContext(UserContext);

  const [users, setUsers] = useState([]);
  const [userGroups, setUserGroups] = useState([]);
  const [userTypeList, setUserTypeList] = useState([]);
  const [isExecutive, setIsExecutive] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getUsersData();
  }, []);

  const getUsersData = async () => {
    setLoading(true);
    const data = await getUsersDataApi({ userId: user.Id });
    setLoading(false);
    if (data.status === "ok") {
      const res = data.response;
      setUsers(res?.user);
      setUserGroups(res?.usergroup);
      setUserTypeList(res?.usertypelist);
      setIsExecutive(res?.isExecutive);
    }
  };

  const skeleton = () => {
    return (
      <Card
        sx={{
          p: 2,
        }}
      >
        <Stack>
          {[...Array(10)].map((item, index) => {
            return (
              <Stack
                key={index}
                item={item}
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                mb={2}
              >
                {[...Array(6)].map((d, i) => {
                  return <Skeleton key={i} item={d} height={40} width={100} />;
                })}
              </Stack>
            );
          })}
        </Stack>
      </Card>
    );
  };

  return (
    <main className="container py-4 position-relative">
      <div className="d-flex align-items-center">
        <div className="section-title">
          <SlUser className="icon me-2" />
          Users
        </div>
        <div className="ms-auto">
          <Link
            to="/users/newuser"
            className="btn btn-primary d-inline-flex align-items-center"
          >
            <BsPlus />
            New User
          </Link>
          <Link
            to="/users/newgroup"
            className="btn btn-secondary d-inline-flex align-items-center ms-2"
          >
            <BsPlus />
            New User Group
          </Link>
        </div>
      </div>
      <div className="card-tabs mt-4 d-flex align-items-center justify-content-between mx-1">
        <div className="">
          <button
            className={`btn-tab ${tab === "user" ? "active" : ""}`}
            onClick={() => setTab("user")}
          >
            Users
          </button>
          <button
            className={`btn-tab ${tab === "group" ? "active" : ""}`}
            onClick={() => setTab("group")}
          >
            User Groups
          </button>
        </div>
      </div>
      {loading && skeleton()}
      {tab === "user" ? (
        <UsersDataTable
          data={users}
          userTypeList={userTypeList}
          isExecutive={isExecutive}
          reloadData={() => getUsersData()}
          loading={loading}
        />
      ) : (
        <UserGroupsDataTable
          data={userGroups}
          reloadData={() => getUsersData()}
          loading={loading}
        />
      )}
    </main>
  );
}
