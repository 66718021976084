import React, { useContext, useEffect, useState } from "react";
import { Box, Card, CircularProgress, Stack } from "@mui/material";
import {
  AuthContext,
  ChatContext,
  ConversationContext,
  FirebaseContext,
} from "../../../context";
import { ConversationsListItem } from "../../../components";
import { onChildAdded, onChildRemoved } from "firebase/database";
import { LeadHelper } from "../../../utils";

export default function ConversationsLeadsList(props) {
  const {
    selectedMsgArr,
    setSelectedMsgArr,
    setShowSelectionBoard,
    getMessageLead,
  } = props;
  const { user, userTimeOffset } = useContext(AuthContext);
  const {
    info,
    conversationsLoading,
    sClient,
    preConversationData,
    filterOption,
    filterParams,
    ajaxInfo,
  } = useContext(ConversationContext);
  const { leadRef, newLeadRef, conversationLockRef, typingLeads } =
    useContext(FirebaseContext);
  const { activatedLeadId, setActivatedLeadId } = useContext(ChatContext);

  const [leads, setLeads] = useState(null);
  const [lockLeads, setLockLeads] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (info) {
      setLeads(info?.val);
    }
  }, [info]);

  useEffect(() => {
    if (leads) {
      const interval = setInterval(() => {
        firebaseLeads();
        conversationLock();
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [leads]);

  useEffect(() => {}, [leads, lockLeads]);

  const firebaseLeads = () => {
    onChildAdded(newLeadRef, function (snapshot) {
      // console.log("🚀🚀🚀 : Firebase new lead database : added data =>");
      const data = LeadHelper.getLeadData(snapshot.val(), userTimeOffset);
      if (leads) {
        if (LeadHelper.isNotClientAuthorized(data.client_id, user, sClient)) {
          return;
        }
        if (LeadHelper.getFilterLabel(filterOption.filter_label) === 12) {
          processFirebaseData(data);
        }
      }
    });
    onChildAdded(leadRef, function (snapshot) {
      // console.log("🚀🚀🚀 : Firebase lead database : added data =>");
      const snapData = snapshot.val();
      if (leads && snapData.createtime > ajaxInfo?.lastupdate) {
        const data = LeadHelper.getLeadData(snapData, userTimeOffset);
        if (
          LeadHelper.filterSMSLiveLead(
            data,
            filterParams,
            filterOption,
            user,
            preConversationData,
          )
        ) {
          processFirebaseData(data);
        } else {
          processFirebaseData(data, false);
        }
      }
    });
  };

  const conversationLock = () => {
    onChildAdded(conversationLockRef, function (snapshot) {
      const data = snapshot.val();
      const dataFilter = lockLeads.filter((i) => i.Lead === data.Lead);
      if (dataFilter.length === 0) {
        const items = [...lockLeads];
        items.unshift(data);
        setLockLeads(items);
      }
    });
    onChildRemoved(conversationLockRef, function (snapshot) {
      const data = snapshot.val();
      const items = [...lockLeads];
      const dataFilter = items.filter((i) => i.Lead != data.Lead);
      setLockLeads(dataFilter);
    });
  };

  const processFirebaseData = (newLead, action = true) => {
    if (leads?.length > 0) {
      if (Number(leads.slice(-1)[0].leadId) === Number(newLead.leadId)) {
        return false;
      }
    }
    let items = [...leads];
    items = items.filter(
      (item) => Number(item.leadId) != Number(newLead.leadId),
    );
    if (action) {
      // console.log("✅✅✅ process firebase data");
      items.unshift(newLead);
      setLeads(items);
    } else {
      // console.log("⛔️⛔️⛔️ process firebase data");
      setLeads(items);
    }
  };

  const isLock = (item) => {
    const lockFilters = lockLeads.filter((i) => i?.Lead == item?.leadId);
    return lockFilters.length > 0;
  };

  const isTyping = (item) => {
    const typingFilters = typingLeads.filter((i) => i?.leadid == item?.leadId);
    return typingFilters.length > 0;
  };

  const isActivated = (item) => {
    return item?.leadId == activatedLeadId;
  };

  const getMessenger = (item) => {
    getMessageLead(item);
  };

  const handleSelect = (item) => {
    setShowSelectionBoard(true);
    setActivatedLeadId(null);
    if (selectedMsgArr.includes(item.leadId)) {
      setSelectedMsgArr((current) => current.filter((e) => e !== item.leadId));
    } else {
      setSelectedMsgArr((current) => [...current, item.leadId]);
    }
  };

  const getNextInfo = async () => {
    setLoading(true);
    console.log("get the next lead information");
  };

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      console.log("is scroll bottom");
      getNextInfo();
    }
  };

  const skeleton = () => {
    return (
      <Box>
        {[...Array(10)].map((item, index) => {
          return (
            <ConversationsListItem key={index} loading={true} item={item} />
          );
        })}
      </Box>
    );
  };

  const renderView = (data) => {
    return (
      <Box>
        {data.map((item, index) => {
          return (
            <ConversationsListItem
              key={index}
              loading={false}
              item={item}
              isSelected={selectedMsgArr.includes(item.leadId)}
              isLock={isLock(item)}
              isTyping={isTyping(item)}
              isActivated={isActivated(item)}
              handleClick={getMessenger}
              handleSelect={handleSelect}
              itemIndex={index}
            />
          );
        })}
      </Box>
    );
  };

  return (
    <Card
      sx={{
        width: "100%",
        height: "calc(100vh - 150px)",
        overflowY: "scroll",
      }}
      onScroll={handleScroll}
    >
      {conversationsLoading || !leads ? skeleton() : renderView(leads)}
      {loading && (
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"center"}
          sx={{
            p: 1,
          }}
        >
          <CircularProgress />
        </Stack>
      )}
    </Card>
  );
}
